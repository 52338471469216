import { privacy } from "api/main";
import { useQuery } from "react-query";

const usePrivacy = () => {
  const { data, isLoading, isSuccess, isError } = useQuery("privacy", privacy);

  return {
    data: data?.data,
    isLoading,
    isSuccess,
    isError,
  };
};

export default usePrivacy;
