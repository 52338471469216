import Box from "component/atoms/box";
import Input from "component/atoms/input";
import RowTable from "component/atoms/rowSideTable";
import RowTableData from "component/atoms/rowTableData";
import RowTableHead from "component/atoms/rowTableHead";
import Text from "component/atoms/text";
import React, { useState, useEffect } from "react";
import ImageInput from "../../imageInput";
import AdminRadio from "component/molecules/admin/adminRadio";
import AdminCheckbox from "component/molecules/adminCheckbox";
import AdminButton from "component/molecules/admin/button/adminButton";
import AdminAlertModal from "../../modal/alertModal";
import Select from "component/atoms/select";
import Option from "component/atoms/option";
import { useUpdateUserDetail, useUserDetail } from "hook/api/admin/userDetail";
import convertImage from "utils/convertImage";
import { useForm } from "react-hook-form";
import { member } from "api/mypage";
import useEditStore from "store/useEditStore";

const UserBasicDetail = (props) => {
  const url = new URL(window.location.href);
  const pathSegments = url.pathname.split("/");
  const memberId = pathSegments[pathSegments.length - 1];

  const { data: data } = useUserDetail(memberId);

  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [grade, setGrade] = useState("");

  const [status, setStatus] = useState("");

  const [profileImage, setProfileImage] = useState(data?.memberProfile);
  const [introduce, setIntroduce] = useState("");
  const [instagram, setInstagram] = useState("");
  const [youtube, setYoutube] = useState("");
  const [soundCloud, setSoundCloud] = useState("");
  const [etc, setEtc] = useState("");
  const [loading, setLoading] = useState(true);
  const { updateEdit, clearEdit } = useEditStore();
  // profileImage 값이 변경되면 handleProfile 함수 실행
  const { profile } = props;

  const { register, handleSubmit, watch, setValue } = useForm({
    defaultValues: {
      ...profile,
      memberProfile: profile?.memberProfile,
    },
  });

  const { memberProfile } = watch();

  const handleProfile = (file) => {
    setValue("memberProfile", file);
    setProfileImage(file);
  };

  // 저장 알림 모달창 상태 관리
  const [visible, setVisible] = useState(false);

  // 회원 등급 select
  const [userType, setUserType] = useState("일반회원");

  const handleTypeChange = (e) => {
    const selectedUserType = e.target.value;
    setUserType(selectedUserType);

    setGrade(
      selectedUserType === "일반회원"
        ? "1"
        : selectedUserType === "아티스트"
        ? "3"
        : "2"
    );
  };

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSuccess = () => {
    setVisible(true);
  };

  const handleError = () => {
    alert("회원 정보 수정에 실패하였습니다.");
  };

  const { mutate } = useUpdateUserDetail(memberId, handleSuccess, handleError);

  // 회원 정보 저장 버튼 클릭 시
  const handleInfoSave = () => {
    // FormData 객체 생성
    const formData = new FormData();

    if (name === "") {
      alert("이름을 입력해주세요.");
      return;
    }
    if (status === "") {
      alert("회원 상태를 입력해주세요.");
      return;
    }

    // 필드 값들을 FormData에 추가
    formData.append("memberPassword", password);
    formData.append("memberName", name);
    formData.append("authority", grade);
    formData.append("userState", status);

    // 파일 또는 이미지를 다룰 때는 Blob 또는 File 객체를 사용
    if (profileImage !== null) {
      formData.append("memberProfile", memberProfile);
    }

    formData.append("memberIntro", introduce);
    formData.append("instagramLink", instagram);
    formData.append("youtubeLink", youtube);
    formData.append("soundCloudLink", soundCloud);
    formData.append("otherSocialLink", etc);

    // FormData 객체 전달

    mutate(formData);
  };
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    if (data) {
      setPassword(data.memberPassword);
      setName(data.memberName);
      setGrade(
        data?.authority === "ROLE_USER"
          ? "1"
          : data?.authority === "ROLE_ARTIST"
          ? "3"
          : "2"
      );
      setUserType(
        data?.authority === "ROLE_USER"
          ? "일반회원"
          : data?.authority === "ROLE_ARTIST"
          ? "아티스트"
          : "관리자"
      );

      setStatus(data?.userState);
      setProfileImage(data?.memberProfile);
      setIntroduce(data?.memberIntro ?? "");
      setInstagram(data?.instagramLink ?? "");
      setYoutube(data?.youtubeLink ?? "");
      setSoundCloud(data?.soundCloudLink ?? "");
      setEtc(data?.otherSocialLink ?? "");
      setLoading(false);
    }
  }, [data]);

  useEffect(() => {
    if (name) {
      setIsDone(true);
    } else {
      setIsDone(false);
    }
  });
  return (
    !loading && (
      <>
        <AdminAlertModal
          title={"기본 정보 수정"}
          text="기본 정보 수정이 완료되었습니다."
          btn_text="확인"
          visible={visible}
          toggle={() => {
            setVisible(false);
          }}
        />
        <Box
          size={{
            width: "100%",
            height: "auto",
          }}
          margin={{
            top: "25px",
          }}
          other={{
            overflowX: "hidden",
          }}
        >
          <Text theme={"adminTitle"}>기본 정보 수정</Text>
          <RowTable>
            <Box
              theme="adminListBox"
              padding={{
                left: "21px",
              }}
            >
              <Text
                typo={{
                  size: "12px",
                  weight: "700",
                  color: "pink03",
                }}
              >
                * 은 필수 입력 항목입니다.
              </Text>
            </Box>
            <Box
              display={{
                width: "100%",
                height: "52px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="48px">
                이메일&nbsp;
                <Text
                  typo={{
                    size: "13px",
                    weight: "700",
                    color: "pink03",
                  }}
                >
                  *
                </Text>
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                }}
              >
                <Text
                  size={{
                    width: "100%",
                    height: "47px",
                  }}
                  display={{
                    display: "flex",
                    align: "center",
                    justify: "flex-start",
                  }}
                  typo={{
                    size: "13px",
                    color: "black02",
                    align: "left",
                  }}
                >
                  {data?.memberEmail}
                </Text>
              </RowTableData>
            </Box>
            <Box
              display={{
                width: "100%",
                height: "52px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="48px">
                비밀번호&nbsp;
                <Text
                  typo={{
                    size: "13px",
                    weight: "700",
                    color: "pink03",
                  }}
                >
                  *
                </Text>
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                }}
              >
                <Input
                  theme={"adminInput"}
                  type="password"
                  size={{
                    width: "240px",
                    height: "32px",
                  }}
                  margin={{
                    top: "8px",
                  }}
                  readOnly={data?.socialProvider !== null ? true : false}
                  background={{
                    color:
                      data?.socialProvider !== null ? "#F3F3F3" : "white01",
                  }}
                  placeholder={
                    data?.socialProvider !== null
                      ? "소셜 로그인 계정입니다."
                      : ""
                  }
                  typo={{
                    size: "12px",
                  }}
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </RowTableData>
            </Box>
            <Box
              display={{
                width: "100%",
                height: "52px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="48px">
                이름&nbsp;
                <Text
                  typo={{
                    size: "13px",
                    weight: "700",
                    color: "pink03",
                  }}
                >
                  *
                </Text>
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                }}
              >
                <Input
                  theme={"adminInput"}
                  size={{
                    width: "240px",
                    height: "32px",
                  }}
                  margin={{
                    top: "8px",
                  }}
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </RowTableData>
            </Box>
            <Box
              display={{
                width: "100%",
                height: "52px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="48px">
                회원등급&nbsp;
                {/* <Text
                typo={{
                  size: "13px",
                  weight: "700",
                  color: "pink03",
                }}
              >
                *
              </Text> */}
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                }}
              >
                <Select
                  value={userType}
                  onChange={handleTypeChange}
                  theme={"adminInput"}
                  size={{
                    width: "240px",
                    height: "32px",
                  }}
                  margin={{
                    top: "8px",
                  }}
                  padding={{
                    left: "10px",
                  }}
                  border={{
                    all: "1px",
                    style: "solid",
                    color: "gray10",
                    radius: "3px",
                  }}
                  typo={{
                    size: "13px",
                    weight: "400",
                    color: "black02",
                  }}
                >
                  <Option>관리자</Option>
                  <Option>아티스트</Option>
                  <Option>일반회원</Option>
                </Select>
              </RowTableData>
            </Box>
            <Box
              display={{
                width: "100%",
                height: "52px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="48px">
                상태 변경&nbsp;
                <Text
                  typo={{
                    size: "13px",
                    weight: "700",
                    color: "pink03",
                  }}
                >
                  *
                </Text>
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                }}
              >
                <Box
                  display={{
                    display: "flex",
                  }}
                  margin={{
                    top: "12px",
                  }}
                >
                  <AdminRadio
                    id="normal"
                    name="user_status"
                    value="0"
                    htmlFor="normal"
                    label="정상"
                    checked={status === 0}
                    onClick={() => setStatus(0)}
                  />
                  <AdminRadio
                    id="block"
                    name="user_status"
                    value="1"
                    htmlFor="block"
                    label="차단"
                    checked={status === 1}
                    onClick={() => setStatus(1)}
                  />
                  <AdminRadio
                    id="drop_out"
                    name="user_status"
                    value="2"
                    htmlFor="drop_out"
                    label="탈퇴"
                    checked={status === 2}
                    onClick={() => setStatus(2)}
                  />
                </Box>
              </RowTableData>
            </Box>
            <Box
              display={{
                width: "100%",
                height: "304px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="289px">
                <Box
                  display={{
                    display: "flex",
                  }}
                  size={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  프로필 이미지
                </Box>
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                  height: "289px",
                }}
              >
                <ImageInput
                  text1="- 권장 이미지 : 1080px*1080px / 10mb 이하 / jpg , png 파일"
                  image={convertImage(profileImage)}
                  onChange={handleProfile}
                />
              </RowTableData>
            </Box>
            <Box
              display={{
                width: "100%",
                height: "52px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="224px">
                <Box
                  size={{
                    height: "100%",
                  }}
                >
                  자기소개
                </Box>
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                }}
              >
                <Input
                  as={"textarea"}
                  theme={"adminInput"}
                  display={{
                    display: "flex",
                    align: "flex-start",
                    justify: "flex-start",
                  }}
                  size={{
                    width: "839px",
                    height: "200px",
                  }}
                  margin={{
                    top: "12px",
                  }}
                  value={introduce}
                  onChange={(e) => setIntroduce(e.target.value)}
                />
              </RowTableData>
            </Box>
            <Box
              display={{
                width: "100%",
                height: "52px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="48px">
                인스타그램
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                }}
              >
                <Input
                  theme={"adminInput"}
                  size={{
                    width: "839px",
                    height: "32px",
                  }}
                  margin={{
                    top: "8px",
                  }}
                  value={instagram}
                  onChange={(e) => setInstagram(e.target.value)}
                />
              </RowTableData>
            </Box>
            <Box
              display={{
                width: "100%",
                height: "52px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="48px">
                유튜브
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                }}
              >
                <Input
                  theme={"adminInput"}
                  size={{
                    width: "839px",
                    height: "32px",
                  }}
                  margin={{
                    top: "8px",
                  }}
                  value={youtube}
                  onChange={(e) => setYoutube(e.target.value)}
                />
              </RowTableData>
            </Box>
            <Box
              display={{
                width: "100%",
                height: "52px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="48px">
                사운드 클라우드
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                }}
              >
                <Input
                  theme={"adminInput"}
                  size={{
                    width: "839px",
                    height: "32px",
                  }}
                  margin={{
                    top: "8px",
                  }}
                  value={soundCloud}
                  onChange={(e) => setSoundCloud(e.target.value)}
                />
              </RowTableData>
            </Box>
            <Box
              display={{
                width: "100%",
                height: "52px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="48px">
                기타
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                }}
              >
                <Input
                  theme={"adminInput"}
                  size={{
                    width: "839px",
                    height: "32px",
                  }}
                  margin={{
                    top: "8px",
                  }}
                  value={etc}
                  onChange={(e) => setEtc(e.target.value)}
                />
              </RowTableData>
            </Box>
          </RowTable>
          <Box
            size={{
              width: "100%",
              height: "auto",
            }}
            display={{
              display: "flex",
            }}
            margin={{
              top: "24px",
              left: "484px",
            }}
          >
            <AdminButton
              category="button"
              theme={isDone ? ["mainButton"] : ["grayColor"]}
              margin={{
                left: "10px",
                bottom: "25px",
              }}
              size={{
                width: "104px",
                height: "40px",
              }}
              padding={{
                top: "12px",
                bottom: "12px",
              }}
              onClick={handleInfoSave}
            >
              저장
            </AdminButton>
          </Box>
        </Box>
      </>
    )
  );
};

export default UserBasicDetail;
