import Box from "component/atoms/box";
import Text from "component/atoms/text";
import Image from "component/atoms/image";
import Line from "component/atoms/line";
import CommonButton from "component/molecules/common/commonButton";
import fundingPercent from "utils/fundingPercent";
import dayjs from "dayjs";
import useThumbnailSlider from "hook/util/useThumbnailSlider";
import numberWithCommas from "utils/numberWithCommas";
import useToggle from "hook/util/useToggle";
import GoalModal from "../modal/goal";
import SupportModal from "../modal/support";
import convertImage from "utils/convertImage";
import useSignedStore from "store/useSignedStore";
import useUrl from "hook/util/useUrl";
import useModalStore from "store/useModalStore";

const Funding = (props) => {
  const { funding, isCreator, isEnd } = props;

  const [current, prev] = useThumbnailSlider(
    funding?.cheeringParticipantsProfiles
  );
  const { changeLink } = useUrl();
  const { setModal } = useModalStore();
  const { user } = useSignedStore();

  const { toggle: goalToggle, handleToggle: handleGoalToggle } = useToggle();
  const { toggle: supportToggle, handleToggle: handleSupportToggle } =
    useToggle();

  const handleSubmit = () => {
    if (isCreator) {
      handleGoalToggle();
    } else {
      if (!funding?.cheeringDday) return;
      if (!user) {
        changeLink("/login");
        setModal({
          title: "좋아요",
          content: "로그인이 필요합니다.",
        });
        return;
      }

      handleSupportToggle();
    }
  };

  if (!funding && !isCreator) return null;

  return (
    funding?.cheeringStatus !== "응원대기" && (
      <Box
        display={{
          display: "flex",
          direction: "column",
          justify: "space-between",
        }}
        size={{
          width: "100%",
          height: "100%",
          minHeight: "238px",
        }}
        border={{
          all: "1px",
        }}
        padding={{
          all: "20px 24px",
        }}
        longMobile={{
          size: {
            width: "100%",
            minHeight: "210px",
          },
          padding: {
            all: "10px",
          },
        }}
      >
        <Box
          display={{
            display: "flex",
            justify: "space-between",
            align: "flex-start",
          }}
        >
          <Text
            typo={{
              size: "20px",
              weight: "700",
            }}
            longMobile={{
              typo: {
                size: "16px",
              },
            }}
          >
            응원 정보
          </Text>

          {funding && (
            <Box
              size={{
                width: "95px",
                height: "100%",
              }}
              position={{
                type: "relative",
              }}
            >
              {funding?.cheeringParticipantsProfiles.length <= 4 &&
                funding?.cheeringParticipantsProfiles.map((thumbnail, idx) => (
                  <Image
                    animation={"fadeSlideIn"}
                    position={{
                      type: "absolute",
                      left: `${63 - idx * 21}px`,
                      top: "0",
                    }}
                    size={{
                      width: "27px",
                      height: "27px",
                    }}
                    border={{
                      radius: "50%",
                    }}
                    term={`${0.9 + idx * 0.3}`}
                    key={`${idx}-prev`}
                    src={convertImage(thumbnail)}
                  />
                ))}
              {funding?.cheeringParticipantsProfiles.length > 4 && (
                <>
                  {prev.map((thumbnail, idx) => {
                    return (
                      <Image
                        animation={"fadeSlideOut"}
                        position={{
                          type: "absolute",
                          left: `${63 - idx * 21}px`,
                          top: "0",
                        }}
                        size={{
                          width: "27px",
                          height: "27px",
                        }}
                        border={{
                          radius: "50%",
                        }}
                        term={`${0.9 + (idx - 1) * 0.3}`}
                        key={`${idx}-prev`}
                        src={convertImage(thumbnail)}
                      />
                    );
                  })}
                  {current.map((thumbnail, idx) => {
                    return (
                      <Image
                        animation={"fadeSlideIn"}
                        position={{
                          type: "absolute",
                          left: `${63 - idx * 21}px`,
                          top: "0",
                        }}
                        size={{
                          width: "27px",
                          height: "27px",
                        }}
                        border={{
                          radius: "50%",
                        }}
                        term={`${0.9 + idx * 0.3}`}
                        key={`${idx}-prev`}
                        src={convertImage(thumbnail)}
                      />
                    );
                  })}
                </>
              )}
            </Box>
          )}
        </Box>

        <Box
          display={{
            display: "none",
          }}
          longMobile={{
            display: {
              display: "block",
            },
          }}
        >
          <Line
            direction="row"
            size={"100%"}
            background={{ color: "gray02" }}
            margin={{ all: "12px 0 10px" }}
            longMobile={{
              margin: {
                all: "7px 0 5px",
              },
            }}
          />
        </Box>

        <Box
          display={{
            display: "flex",
            gap: "30px",
          }}
          size={{
            width: "100% ",
          }}
          longMobile={{
            display: {
              gap: "20px",
            },
          }}
        >
          <Box>
            <Text
              typo={{
                size: "14px",
                weight: "700",
              }}
            >
              남은 시간
            </Text>
            <Text
              typo={{
                size: "14px",
                weight: "700",
                color: "pink02",
              }}
            >
              {funding?.cheeringDday ?? "0"}
              {/* {diffDate(funding?.cheeringStart, funding?.cheeringEnd)} */}
              <Text
                as={"span"}
                margin={{
                  left: "5px",
                }}
                typo={{
                  weight: "400",
                  color: "black01",
                }}
              >
                일
              </Text>
            </Text>
          </Box>
          <Box>
            <Text
              typo={{
                size: "14px",
                weight: "700",
              }}
            >
              참여자
            </Text>
            <Text
              typo={{
                size: "14px",
                weight: "700",
                color: "pink02",
              }}
            >
              {numberWithCommas(funding?.cheeringParticipants)}
              <Text
                as={"span"}
                margin={{
                  left: "5px",
                }}
                typo={{
                  weight: "400",
                  color: "black01",
                }}
              >
                참여
              </Text>
            </Text>
          </Box>
          <Box
            longMobile={{
              display: {
                display: "none",
              },
            }}
          >
            <Text
              typo={{
                size: "14px",
                weight: "700",
              }}
            >
              응원 금액
            </Text>
            <Text
              typo={{
                size: "14px",
                weight: "700",
                color: "pink02",
              }}
            >
              {funding?.cheeringAmount}
              <Text
                as={"span"}
                margin={{
                  left: "5px",
                }}
                typo={{
                  weight: "400",
                  color: "black01",
                }}
              >
                달성
              </Text>
            </Text>
          </Box>

          {funding?.targetAmount && (
            <Box>
              <Text
                typo={{
                  size: "14px",
                  weight: "700",
                }}
              >
                목표 금액
              </Text>
              <Text
                typo={{
                  size: "14px",
                  weight: "700",
                  color: "pink02",
                }}
              >
                {funding?.targetAmount}
                <Text
                  as={"span"}
                  margin={{
                    left: "5px",
                  }}
                  typo={{
                    weight: "400",
                    color: "black01",
                  }}
                >
                  목표
                </Text>
              </Text>
            </Box>
          )}
        </Box>

        <Box
          size={{
            width: "100%",
          }}
          display={{
            display: "flex",
          }}
          tablet={{
            display: {
              wrap: "wrap",
              direction: "column",
            },
            margin: {
              all: "12px 0",
            },
          }}
        >
          <Box
            display={{
              display: "flex",
              justify: "space-between",
            }}
            tablet={{
              size: {
                width: "100%",
              },
              margin: {
                bottom: "8px",
              },
            }}
          >
            <Text
              typo={{
                size: "14px",
                weight: "700",
              }}
              margin={{
                right: "6px",
              }}
            >
              {funding?.cheeringPercent ?? 0}%
            </Text>

            <Text
              display={{
                display: "none",
              }}
              typo={{
                size: "14px",
                weight: "700",
                color: "pink02",
              }}
              tablet={{
                display: {
                  display: "block",
                },
              }}
            >
              {funding?.cheeringAmount}
              <Text
                as={"span"}
                margin={{
                  left: "5px",
                }}
                typo={{
                  weight: "400",
                  color: "black01",
                }}
              >
                달성
              </Text>
            </Text>
          </Box>

          <Box
            position={{
              type: "relative",
            }}
            size={{
              width: "100%",
            }}
            display={{
              display: "flex",
              flexDirection: "row",
              align: "center",
            }}
          >
            <Box
              size={{
                width: `${Math.min(funding?.cheeringPercent ?? 0, 100)}%`,
                height: "5px",
              }}
              background={{
                color: "pink02",
              }}
              border={{
                all: "1px",
              }}
            />
            <Box
              size={{
                width: `${100 - Math.min(funding?.cheeringPercent ?? 0, 100)}%`,
                height: "5px",
              }}
              background={{
                color: "gray01",
              }}
              border={{
                all: "1px",
              }}
            />
            <Box
              position={{
                type: "absolute",
                left: `calc(${Math.min(
                  funding?.cheeringPercent ?? 0,
                  100
                )}% - 5px)`,
                isCenterY: true,
              }}
              size={{
                width: "10px",
                height: "10px",
              }}
              background={{
                color: "pink02",
              }}
              border={{
                all: "1px",
                radius: "50%",
              }}
            />
          </Box>
        </Box>

        <Box>
          <CommonButton
            category={"button"}
            isActive={funding?.cheeringDday || isCreator}
            isDisabled={isEnd || (!funding?.cheeringDday && !isCreator)}
            size={{
              width: "100%",
              height: "52px",
            }}
            onClick={() => !isEnd && handleSubmit()}
          >
            {isCreator
              ? "응원하기 목표 설정하기"
              : funding?.cheeringDday
              ? "응원하기"
              : funding?.cheeringStatus}
          </CommonButton>
        </Box>

        <GoalModal
          toggle={goalToggle}
          handleToggle={handleGoalToggle}
          funding={funding}
        />
        <SupportModal
          toggle={supportToggle}
          handleToggle={handleSupportToggle}
          funding={funding}
          key={supportToggle}
        />
      </Box>
    )
  );
};

export default Funding;
