import { request } from "api/mypage";
import { useQuery } from "react-query";

const useRequestList = ({ state = "", page }) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["requestList", state, page],
    () => request(state, page)
  );

  const list = data?.data;
  const maxPage = data?.totalPages;

  return {
    list,
    maxPage,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useRequestList;
