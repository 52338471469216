import { API_VERSION, SERVER_URL } from "api/config";
import ADMIN from "mock/datas/admin/auth";
import { rest } from "msw";

const loginHandler = rest.post(
  `${SERVER_URL}/api/${API_VERSION}/admin/auth/login`,
  (req, res, ctx) => {
    const { email, password } = req.body;

    const user = ADMIN.find(
      (user) => user.userId === email && user.password === password
    );

    if (!user) {
      return res(
        ctx.status(400),
        ctx.json({
          message:
            "가입된 회원아이디가 아니거나 비밀번호가 틀립니다.\n비밀번호는 대소문자를 구분합니다.",
        })
      );
    } else {
      return res(
        ctx.status(200),
        ctx.json({
          message: "로그인에 성공하였습니다.",
          data: user,
        })
      );
    }
  }
);

const adminAuth = [loginHandler];
export default adminAuth;
