import Box from "component/atoms/box";
import Image from "component/atoms/image";
import Text from "component/atoms/text";
import CommonButton from "component/molecules/common/commonButton";
import { useRequestLink, useNewsLetterLink } from "hook/api/main/requestLink";

const ContentBox = (props) => {
  const { img, title, contents, link } = props;
  const { data } = useRequestLink();
  const { data: newsLetterData } = useNewsLetterLink();
  const isContact = title === "CONTACT US";

  return (
    <Box
      display={{
        display: "flex",
      }}
      size={{
        width: "calc(50% - 22px)",
      }}
      other={{
        boxShadow: "4px 4px",
      }}
      border={{
        all: "1px",
        style: "solid",
        color: "black01",
      }}
      pc={{
        size: {
          width: "calc(50% - 12px)",
        },
      }}
      tablet={{
        size: {
          width: "calc(50% - 12px)",
        },
      }}
      longMobile={{
        size: {
          width: "100%",
        },
        margin: {
          bottom: "12px",
        },
      }}
    >
      <Box
        size={{
          width: "103px",
        }}
        border={{
          right: "1px",
        }}
      >
        <Box
          size={{
            width: "100%",
            height: "64px",
          }}
          display={{
            display: "flex",
            justify: "center",
            align: "center",
          }}
          border={{
            bottom: "1px",
          }}
        >
          <Image
            size={{
              width: "56px",
              height: "56px",
            }}
            src={img}
          />
        </Box>
        <Box
          size={{
            width: "100%",
            height: "71px",
          }}
          background={{
            color: "pink01",
          }}
        >
          <Text
            typo={{
              size: "14px",
              weight: "900",
              space: "2px",
              align: "center",
            }}
            padding={{
              all: "14.5px 0",
            }}
          >
            {title}
          </Text>
        </Box>
      </Box>
      <Box
        display={{
          display: "flex",
          justify: "space-between",
          align: "center",
        }}
        size={{
          width: "calc(100% - 104px)",
        }}
        padding={{
          all: "43px 40px 40px",
        }}
        pc={{
          display: {
            direction: "column",
            align: "flex-start",
          },
          padding: {
            all: "20px 40px 13px",
          },
        }}
        tablet={{
          padding: {
            all: "20px 15px 13px",
          },
        }}
      >
        <Box
          pc={{
            margin: {
              bottom: "10px",
            },
          }}
        >
          {contents.map((content) => (
            <Text
              typo={{
                size: "21px",
                weight: "300",
                lineHeight: "1.24",
              }}
              tablet={{
                typo: {
                  size: "16px",
                },
              }}
              key={content}
            >
              {content}
            </Text>
          ))}
        </Box>
        <CommonButton
          category={"link"}
          size={{
            width: "105px",
            height: "40px",
          }}
          link={isContact ? data?.content : newsLetterData?.content}
          target="_blank"
        >
          {link.value}
        </CommonButton>
      </Box>
    </Box>
  );
};

export default ContentBox;
