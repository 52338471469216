import { edit } from "api/mypage";
import { ErrorContent } from "component/organisms/common/globalModal";
import useMyInfo from "hook/api/mypage/myInfo";
import usePattern from "hook/util/usePattern";
import useUrl from "hook/util/useUrl";
import { useEffect } from "react";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";
import checkRequired from "utils/checkRequired";

const required = {
  name: true,
  intro: true,
  email: true,
  password: false,
  passwordConfirm: false,
  profile: false,
  instagramLink: false,
  youtubeLink: false,
  soundCloudLink: false,
  otherSocialLink: false,
};

const defaultValues = {
  name: "",
  intro: "",
  email: "123",
  password: "",
  passwordConfirm: "",
  profile: "",
  instagramLink: "",
  youtubeLink: "",
  soundCloudLink: "",
  otherSocialLink: "",
  artistName: "",
  memberPhone: "",
  artistIntro: "",
};

const useUpdateProfileForm = () => {
  const form = useForm({ defaultValues });
  const { watch, handleSubmit, reset } = form;
  const pattern = usePattern();
  const { changeQuery } = useUrl();
  const queryClient = useQueryClient();
  const { setModal } = useModalStore();

  const { data, isSuccess } = useMyInfo();
  const editMutation = useMutation(edit, {
    onSuccess: () => {
      changeQuery({}, ["category"]);
      queryClient.invalidateQueries("member");
    },
    onError: ({ response: { data } }) => {
      setModal({
        title: "내정보 수정",
        content: <ErrorContent message={data.message} />,
      });
    },
  });

  const isActive = useMemo(() => {
    const { password, passwordConfirm } = watch();

    return (
      checkRequired(required, watch()) &&
      (password
        ? pattern.password.validate(password) && password === passwordConfirm
        : true)
    );
  }, [watch()]);

  const submit = (data) => {
    if (isActive) {
      const formData = new FormData();

      Object.entries(data).map(([key, value]) => {
        if (value) {
          formData.append(key, value);
        } else if (key.includes("Link")) {
          formData.append(key, "");
        }
      });

      if (typeof data.profile === "string") {
        formData.delete("profile");
      }

      editMutation.mutate(formData);
    }
  };

  useEffect(() => {
    if (isSuccess) {
      reset({ ...defaultValues, ...data, thumbnail: data?.profile });
    }
  }, [data, isSuccess]);

  return {
    form,
    isActive,
    //
    submit: handleSubmit(submit),
  };
};

export default useUpdateProfileForm;
