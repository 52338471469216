import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  getNewsLetterLink,
  getRequestLink,
  updateNewsLetterLink,
  updateRequestLink,
} from "api/admin/setup/requestLink";
import { useNavigate } from "react-router-dom";

export const useAdminRequestLink = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery("adminRequestLink", getRequestLink, {
    onError: (error) => {
      if (error.response && error.response.status === 403) {
        alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
        navigate("/admin/login");
      }
    },
  });
  return { data: data?.data, isLoading };
};

export const useAdminNewsLetterLink = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    "adminNewsLetterLink",
    getNewsLetterLink,
    {
      onError: (error) => {
        if (error.response && error.response.status === 403) {
          alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
          navigate("/admin/login");
        }
      },
    }
  );
  return { data: data?.data, isLoading };
};

export const useUpdateAdminRequestLink = (
  onSuccessCallback,
  onErrorCallback
) => {
  const queryClient = useQueryClient();
  const { mutate, data, isLoading } = useMutation(updateRequestLink, {
    onSuccess: () => {
      if (onSuccessCallback && typeof onSuccessCallback === "function") {
        onSuccessCallback();
      }
      queryClient.invalidateQueries("adminRequestLink");
    },
    onError: (error) => {
      if (onErrorCallback && typeof onErrorCallback === "function") {
        onErrorCallback(error);
      }
    },
  });

  return {
    mutate,
    data,
    isLoading,
  };
};

export const useUpdateAdminNewsLetterLink = (
  onSuccessCallback,
  onErrorCallback
) => {
  const queryClient = useQueryClient();
  const { mutate, data, isLoading } = useMutation(updateNewsLetterLink, {
    onSuccess: () => {
      if (onSuccessCallback && typeof onSuccessCallback === "function") {
        onSuccessCallback();
      }
      queryClient.invalidateQueries("adminNewsLetterLink");
    },
    onError: (error) => {
      if (onErrorCallback && typeof onErrorCallback === "function") {
        onErrorCallback(error);
      }
    },
  });

  return {
    mutate,
    data,
    isLoading,
  };
};
