import { latest } from "api/story";
import { useQuery } from "react-query";

const useLatestStory = ({ storyId, boardId, page }) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["latest", storyId, boardId, page],
    () => latest({ storyId, boardId, page })
  );

  return { data, isLoading, isError, isSuccess };
};

export default useLatestStory;
