import { goal, updateGoal } from "api/story";
import Text from "component/atoms/text";
import { ErrorContent } from "component/organisms/common/globalModal";
import dayjs from "dayjs";
import useToggle from "hook/util/useToggle";
import useUrl from "hook/util/useUrl";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";
import checkRequired from "utils/checkRequired";

const required = {
  price: true,
  funding_start: true,
  funding_end: true,
};

const SuccessContent = () => {
  return (
    <Text
      typo={{
        size: "18px",
        weight: "700",
        align: "center",
      }}
    >
      응원하기 목표가 설정 완료되었습니다.
    </Text>
  );
};

const useGoalForm = (handleToggle, funding) => {
  const form = useForm({
    defaultValues: {
      price: funding?.targetAmount.replaceAll(",", "") ?? "",
      funding_start: funding?.cheeringStart ?? dayjs().format("YYYY-MM-DD"),
      funding_end: funding?.cheeringEnd ?? dayjs().format("YYYY-MM-DD"),
    },
  });
  const { watch, handleSubmit } = form;
  const {
    params: { id },
  } = useUrl();
  const { setModal } = useModalStore();
  const queryClient = useQueryClient();
  const goalMutation = useMutation(funding ? updateGoal : goal, {
    onSuccess: () => {
      handleToggle();
      setModal({
        title: "응원하기 목표 설정하기",
        content: <SuccessContent />,
      });
      queryClient.invalidateQueries(["story", id]);
    },
    onError: () => {
      setModal({
        title: "응원하기 목표 설정하기",
        content: <ErrorContent />,
      });
    },
  });

  const isActive = useMemo(() => {
    const { price } = watch();

    const requiredValue = checkRequired(required, watch());

    if (price <= 0) {
      return false;
    }

    return requiredValue;
  }, [watch()]);

  const onSubmit = (data) => {
    isActive &&
      goalMutation.mutate({
        data: {
          cheeringStart: data.funding_start,
          cheeringEnd: data.funding_end,
          targetAmount: data.price,
        },
        id: funding?.cheerId ?? id,
      });
  };

  return {
    form,
    isActive,
    onSubmit: handleSubmit(onSubmit),
  };
};

export default useGoalForm;
