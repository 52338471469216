import Box from "component/atoms/box";
import CommonButton from "component/molecules/common/commonButton";
import History from "../history";
import useUrl from "hook/util/useUrl";
import Story from "../story";
import Portfolio from "../portfolio";
import Request from "../request";
import Create from "../create";
import Support from "../support";
import Coin from "../coin";
import Like from "../like";
import Edit from "../edit";
import { useEffect, useMemo } from "react";
import useModalStore from "store/useModalStore";

const CATEGORY = [
  {
    title: "창안한 이야기 리스트",
    query: "story",
    you: false,
    artist: false,
  },
  { title: "포트폴리오", query: "portfolio", you: false, artist: true },
  { title: "매칭 신청 리스트", query: "request", you: true, artist: true },
  {
    title: "창작한 콘텐츠 리스트",
    query: "create",
    you: false,
    artist: false,
  },
  { title: "응원 내역", query: "support", you: true, artist: false },
  { title: "무무 코인", query: "coin", you: true, artist: false },
  { title: "좋아요 리스트", query: "like", you: true, artist: false },
];

const NONE_CATEGORY = [
  {
    title: "프로필 수정",
    query: "edit",
  },
];

const CONTENT_COMPONENTS = {
  story: Story,
  portfolio: Portfolio,
  request: Request,
  create: Create,
  support: Support,
  coin: Coin,
  like: Like,
  edit: Edit,
};

const Content = (props) => {
  const { isArtist, isYou, memberId } = props;
  const {
    query: { category, detail },
    changeLink,
    changeQuery,
  } = useUrl();
  const { setModal } = useModalStore();

  const currentCategory =
    CATEGORY.find((item) => item.query === category) ??
    NONE_CATEGORY.find((item) => item.query === category) ??
    CATEGORY[0];

  const ContentComponent = CONTENT_COMPONENTS[currentCategory.query];

  const categoryList = useMemo(
    () =>
      CATEGORY.filter(
        ({ artist, you }) => !((artist && !isArtist) || (you && !isYou))
      ),
    [category]
  );

  useEffect(() => {
    if (category) {
      const target = document.getElementById(category);
      if (target) {
        const yOffset = -110;

        const y =
          target.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [category]);

  useEffect(() => {
    if (
      category &&
      !categoryList.find((item) => item.query === category) &&
      !NONE_CATEGORY.find((item) => item.query === category)
    ) {
      setModal({
        title: "프로필",
        content: "잘못된 접근입니다.",
      });
      changeLink("/mypage");
    }
  }, [categoryList, category]);

  return (
    <>
      <Box
        display={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "20px 40px",
        }}
        margin={{
          top: "70px",
        }}
        tablet={{
          display: {
            gridTemplateColumns: "repeat(3, 1fr)",
          },
        }}
        longMobile={{
          display: {
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "5px 8px",
          },
          margin: {
            top: "50px",
          },
        }}
      >
        {categoryList.map(({ title, query }) => (
          <CommonButton
            category={"button"}
            size={{
              width: "100%",
              height: "60px",
            }}
            typo={{
              size: "18px",
            }}
            longMobile={{
              size: {
                width: "100%",
                height: "44px",
              },
              typo: { size: "14px" },
            }}
            isActive={currentCategory.query === query}
            key={title}
            onClick={() =>
              changeQuery({ category: query, page: 1, sort: "" }, ["detail"])
            }
          >
            {title}
          </CommonButton>
        ))}
      </Box>

      <Box
        margin={{
          top: "70px",
        }}
        longMobile={{
          margin: {
            top: "40px",
          },
        }}
        id={currentCategory.query}
      >
        {detail ? (
          <History />
        ) : (
          <ContentComponent
            title={currentCategory.title}
            memberId={memberId}
            isYou={isYou}
            isArtist={isArtist}
          />
        )}
      </Box>
    </>
  );
};

export default Content;
