import Box from "component/atoms/box";
import Text from "component/atoms/text";
import CommonButton from "component/molecules/common/commonButton";
import InputField from "component/organisms/common/inputField";
import useFindForm from "hook/api/auth/find";
import { FormProvider } from "react-hook-form";

const Find = () => {
  const {
    form,
    isActive,
    //
    handleSubmit,
  } = useFindForm();

  return (
    <>
      {/* <div
        style={{
          width: "100%",
          padding: "100px 0",
          color: "#F4F5F5",
        }}
      >
        <div
          style={{
            margin: "0 auto",
            width: "630px",
            border: "1px solid #000",
            background: "white",
          }}
        >
          <div
            style={{
              padding: "30px 0",
              borderBottom: "1px solid black",
              textAlign: "center",
            }}
          >
            <img
              src={"/asset/img/common/logo.png"}
              style={{
                width: "120px",
                height: "30px",
              }}
            />
          </div>

          <div
            style={{
              padding: "52px 0 70px",
              color: "black",
            }}
          >
            <p
              style={{
                textAlign: "center",
                fontSize: "24px",
                fontWeight: "700",
                color: "#ff84bf",
                marginBottom: "20px",
              }}
            >
              임시 비밀번호 안내
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                lineHeight: "26px",
              }}
            >
              안녕하세요. 고객님
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                lineHeight: "26px",
              }}
            >
              요청주신 임시 비밀번호 전달드립니다.
            </p>
            <p
              style={{
                textAlign: "center",
                fontSize: "18px",
                lineHeight: "26px",
                fontWeight: "700",
              }}
            >
              로그인 시 해당 비밀번호로 로그인해 주세요
            </p>

            <p
              style={{
                textAlign: "center",
              }}
            >
              <span
                style={{
                  margin: "20px auto 0",
                  padding: "18px 20px",
                  color: "#ffffff",
                  fontSize: "20px",
                  fontWeight: "700",
                  background: "#ff84bf",
                  border: "1px solid #000",
                  display: "inline-block",
                }}
              >
                임시 비밀번호 : {}
              </span>
            </p>
          </div>

          <div
            style={{
              borderTop: "1px solid black",
              padding: "30px 40px",
              color: "#777",
              fontSize: "12px",
              background: "#ffe4f6",
            }}
          >
            <p>
              본 메일은 발신 전용이며 회신 시 답장을 받을 수 없어요. 문의사항은
              아래 주소로 연락주세요.
            </p>
            <p>이메일 : hello@moomoost.com</p>
            <br />
            <p>© moomoost. All Rights Reserved.</p>
          </div>
        </div>
      </div> */}
      <FormProvider {...form}>
        <Box
          as={"section"}
          size={{
            height: "calc(100vh - (98px + 190px))",
          }}
          display={{
            display: "flex",
            direction: "column",
            justify: "center",
            align: "center",
          }}
          mobile={{
            size: {
              height: "calc(100vh - (76px + 276px))",
            },
            padding: {
              all: "0px 10px",
            },
          }}
        >
          <Text
            typo={{
              size: "28px",
              weight: "700",
              align: "center",
            }}
            longMobile={{
              typo: {
                size: "20px",
              },
            }}
          >
            비밀번호 찾기
          </Text>
          <Text
            margin={{
              top: "10px",
              bottom: "54px",
            }}
            typo={{
              size: "14px",
              weight: "400",
              color: "black",
              lineHeight: "normal",
              align: "center",
            }}
          >
            회원가입 시 등록하신 이메일 주소를 입력해 주세요.
            <br />
            헤당 이메일로 비밀번호 정보를 보내드립니다.
          </Text>

          <InputField
            as={"input"}
            type={"email"}
            title={"이메일"}
            name={"email"}
            size={{
              width: "580px",
              height: "50px",
            }}
            longMobile={{
              size: {
                width: "100%",
              },
            }}
            placeholder={"E-mail 주소"}
          />

          <Box
            display={{
              display: "flex",
              justify: "center",
            }}
            size={{
              width: "100%",
            }}
            margin={{
              top: "40px",
            }}
          >
            <CommonButton
              category={"button"}
              size={{
                width: "580px",
                height: "50px",
              }}
              mobile={{
                size: {
                  width: "100%",
                },
              }}
              isDisabled={!isActive}
              isActive={isActive}
              onClick={handleSubmit}
            >
              인증메일 보내기
            </CommonButton>
          </Box>
        </Box>
      </FormProvider>
    </>
  );
};

export default Find;
