import Box from "component/atoms/box";
import Text from "component/atoms/text";
import Api from "component/molecules/common/api";
import usePrivacy from "hook/api/main/privacy";
import useTermsUse from "hook/api/main/termsUse";
import useUrl from "hook/util/useUrl";

const info = {
  use: {
    title: "이용약관",
    api: useTermsUse,
  },
  privacy: {
    title: "개인정보처리방침",
    api: usePrivacy,
  },
};

const Terms = () => {
  const {
    query: { category },
  } = useUrl();

  const currentInfo = info[category];

  return (
    <Api api={currentInfo.api}>
      {(data) => (
        <Box
          theme={"basic"}
          display={{
            display: "flex",
            justify: "center",
            wrap: "wrap",
          }}
          margin={{
            all: "170px auto 100px",
          }}
          longMobile={{
            margin: {
              all: "80px 0 70px",
            },
          }}
        >
          <Text
            typo={{
              size: "28px",
              weight: "700",
            }}
            margin={{
              bottom: "58px",
            }}
            longMobile={{
              typo: {
                size: "20px",
              },
              margin: {
                bottom: "50px",
              },
            }}
          >
            {currentInfo.title}
          </Text>

          <Box
            size={{
              width: "100%",
            }}
            padding={{
              all: "30px",
            }}
            border={{
              all: "1px",
            }}
            longMobile={{
              padding: {
                all: "12px",
              },
            }}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: data?.content,
              }}
              className="editor-content"
            />
          </Box>
        </Box>
      )}
    </Api>
  );
};

export default Terms;
