import React from "react";
import { RowTableDataWrapper } from "./rowTableData.style";

const RowTableData = (props) => {
  const { children, size, padding, isLastChild, nowrap } = props;
  return (
    <RowTableDataWrapper
      $size={size}
      $padding={padding}
      isLastChild={isLastChild}
      nowrap={nowrap}
    >
      {children}
    </RowTableDataWrapper>
  );
};

RowTableData.propTypes = {
  ...RowTableDataWrapper.propTypes,
};

export default RowTableData;
