import { API_VERSION, SERVER_URL } from "api/config";
import { rest } from "msw";

const portfolioRequired = {
  name: true,
  link: true,
  portfolio_start: true,
  portfolio_end: true,
};

const portfolioCreateHandler = rest.post(
  `${SERVER_URL}/api/${API_VERSION}/portfolio/create`,
  (req, res, ctx) => {
    const body = req.body;

    Object.entries(portfolioRequired).forEach(([key, value]) => {
      if (value && !body[key]) {
        return res(
          ctx.status(400),
          ctx.json({
            message: `이야기  작성에 실패했습니다.\n잠시 후 다시 시도해주세요.`,
          })
        );
      }
    });

    return res(
      ctx.status(200),
      ctx.json({
        message: "포트폴리오가 등록되었습니다.",
      })
    );
  }
);

const portfolioDeleteHandler = rest.post(
  `${SERVER_URL}/api/${API_VERSION}/portfolio/:id/delete`,
  (req, res, ctx) => {
    const { id } = req.params;

    return res(
      ctx.status(200),
      ctx.json({
        message: `${id}번 포트폴리오가 삭제되었습니다.`,
      })
    );
  }
);

const portfolio = [portfolioCreateHandler, portfolioDeleteHandler];

export default portfolio;
