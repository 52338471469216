import Box from "component/atoms/box";
import Input from "component/atoms/input";
import AdminRadio from "component/molecules/admin/adminRadio";
import RowTable from "component/atoms/rowSideTable";
import RowTableData from "component/atoms/rowTableData";
import RowTableHead from "component/atoms/rowTableHead";
import Text from "component/atoms/text";
import React, { useState, useEffect } from "react";
import AdminDropdown from "component/molecules/admin/adminDropdown";
import AdminButton from "component/molecules/admin/button/adminButton";
import Image from "component/atoms/image";
import TableData from "component/atoms/tableData";
import TableHead from "component/atoms/tableHead";
import Table from "component/atoms/table";
import AdminCheckbox from "component/molecules/adminCheckbox";
import CalendarInput from "component/molecules/common/calendarInput";
import useToggle from "hook/util/useToggle";
import AdminInputModal from "../../modal/InputModal";
import AdminAlertModal from "../../modal/alertModal";
import Link from "component/atoms/link";
import {
  useAdminStoryList,
  useDeleteStory,
} from "hook/api/admin/story/storyManage";
import dayjs from "dayjs";
import AdminPagination from "component/molecules/admin/pagination";
import usePopup from "hook/util/usePopup";
import { getCurrentDateTime } from "utils/getCurrentDateTime";

const StoryManagement = () => {
  const category = ["전체", "창안자 이름", "제목"];
  const listCategory = [
    { label: "10개씩 보기", value: 10 },
    { label: "20개씩 보기", value: 20 },
    { label: "30개씩 보기", value: 30 },
    { label: "50개씩 보기", value: 50 },
  ];

  const labels = listCategory.map((category) => category.label);

  // 검색 분류의 초기값을 '전체'로 설정
  const [selected, setSelected] = useState(category[0]);
  // 검색어
  const [inputValue, setInputValue] = useState("");

  // 검색 분류 변경 시 상태 변경
  const changeSelected = (category) => {
    setSelected(category);
    setInputValue("");
  };

  // 인풋창의 입력값을 카테고리에 따라 관리
  const handleInputChange = (e) => {
    e.preventDefault();
    setInputValue(e.target.value);
  };

  // 10개씩 보기 선택하면 value = 10 을 pageSize로 설정
  const changeSelectedList = (selectedLabel) => {
    const category = listCategory.find((cat) => cat.label === selectedLabel);
    if (category) {
      setPageSize(category.value);
      setCurrentPage(1);
    }
  };
  const currentDateTime = getCurrentDateTime();
  // 라디오 버튼을 클릭하거나 검색 분류를 설정하면 해당 값으로 상태 변경
  const [storyStatus, setStoryStatus] = useState("전체");
  const [cheeringState, setCheeringState] = useState("전체");
  const [classification, setClassification] = useState("전체");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  // 검색 기간 설정 필요
  const [pageSize, setPageSize] = useState(10);

  // 검색 버튼을 클릭하면 선택된 값에 따라 검색이 됨
  // 초기 값은 전체 게시물, 10개씩 보기
  const [search, setSearch] = useState({
    storyStatus: "전체",
    cheeringState: "전체",
    classification: "전체",
    keyword: "",
    storyTitle: "",
    memberName: "",
    startDate: "",
    endDate: "",
  });

  // 페이지네이션
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const {
    data: storyList,
    totalItems: total,
    totalPages: totalPages,
  } = useAdminStoryList(
    search.storyStatus,
    search.cheeringState,
    search.classification,
    search.keyword,
    search.storyTitle,
    search.memberName,
    search.startDate,
    search.endDate,
    pageSize,
    currentPage
  );

  // 검색 버튼을 클릭하면 검색 필터를 적용
  const handleSearch = () => {
    const newSearch = {
      storyStatus: storyStatus,
      cheeringState: cheeringState,
      classification: classification,
      startDate: startDate,
      endDate:
        startDate !== "" && endDate === ""
          ? dayjs(currentDateTime).format("YYYY-MM-DD")
          : endDate,
    };

    if (
      startDate === "" &&
      dayjs(newSearch.endDate).isAfter(dayjs(currentDateTime))
    ) {
      newSearch.startDate = dayjs(currentDateTime).format("YYYY-MM-DD");
    }
    if (
      startDate === "" &&
      dayjs(currentDateTime).isAfter(dayjs(newSearch.endDate))
    ) {
      alert("시작일이 종료일보다 늦습니다. 검색 기간을 다시 설정해주세요.");
      return;
    }
    if (dayjs(newSearch.startDate).isAfter(dayjs(newSearch.endDate))) {
      alert("시작일이 종료일보다 늦습니다. 검색 기간을 다시 설정해주세요.");
      return;
    }

    // 선택된 카테고리에 따라 입력값을 다른 변수에 할당
    if (selected === "제목") {
      newSearch.storyTitle = inputValue;
    } else if (selected === "창안자 이름") {
      newSearch.memberName = inputValue;
    } else if (selected === "전체") {
      newSearch.keyword = inputValue;
    }

    setSearch(newSearch);
    setCurrentPage(1);
  };

  // 새 창으로 열기!
  const { handleOpenPopup } = usePopup({
    width: "1350px",
    height: window.innerHeight,
  });

  const openNewWindow = (storyId) => {
    handleOpenPopup(
      "/admin/manage/story/detail/" +
        storyId +
        "?menu=storyDetail&category=storyEdit"
    );
  };

  // 체크박스를 선택하여 일괄 승인
  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    setSelectedUser({});
  }, [currentPage]);

  // 전체 선택 또는 해제 처리
  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setSelectedUser((prevSelectedUser) => {
      const newSelectedUser = {};
      if (checked) {
        storyList.forEach((data) => {
          newSelectedUser[data.storyId] = true;
        });
      }
      return newSelectedUser;
    });
  };

  // 개별 선택 처리
  // const handleSelect = (e, storyId) => {
  //   const checked = e.target.checked;

  //   setSelectedUser((prevSelectedUser) => {
  //     const newSelectedUser = { ...prevSelectedUser };
  //     newSelectedUser[storyId] = checked;
  //     return newSelectedUser;
  //   });
  //   console.log(selectedUser, "22");
  //   console.log(storyId, "33");
  //   console.log(checked, "44");
  // };
  const handleSelect = (e, storyId) => {
    const checked = e.target.checked;

    setSelectedUser((prevSelectedUser) => {
      return { ...prevSelectedUser, [storyId]: checked };
    });
  };

  // 차단 성공, 실패 모달
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSuccess = () => {
    setSuccess(true);
  };

  const handleError = () => {
    setError(true);
  };

  const selectedUserIds = Object.entries(selectedUser)
    .filter(([_, checked]) => checked)
    .map(([storyId]) => storyId);

  const { mutate: deleteStory } = useDeleteStory(handleSuccess, handleError);

  const handleDelete = () => {
    if (selectedUserIds.length === 0) {
      alert("삭제할 이야기를 선택해주세요.");
      return;
    } else {
      window.confirm("선택한 이야기를 삭제하시겠습니까?") &&
        deleteStory({ storyId: selectedUserIds });
      setSelectedUser({});
    }
  };

  return (
    <>
      <Box
        size={{
          width: "100%",
          height: "auto",
        }}
        margin={{
          top: "25px",
        }}
        other={{
          overflowX: "hidden",
        }}
      >
        <AdminAlertModal
          title={"이야기 관리"}
          text={
            success
              ? "이야기 삭제에 성공하였습니다."
              : "이야기 삭제에 실패하였습니다."
          }
          btn_text="확인"
          visible={success || error}
          toggle={() => {
            setSuccess(false) || setError(false);
          }}
          onClick={() => {
            setSuccess(false) || setError(false);
          }}
        />
        <Text theme={"adminTitle"}>이야기 조회</Text>
        <RowTable>
          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="160px" height="48px" isLastChild={true}>
              진행 상태 분류
            </RowTableHead>

            <RowTableData
              size={{
                width: "calc(100% - 160px)",
                height: "48px",
              }}
              isLastChild={true}
            >
              <Box
                size={{
                  width: "100%",
                  height: "100%",
                }}
                display={{
                  display: "flex",
                  justify: "flex-start",
                  align: "center",
                }}
                margin={{
                  left: "8px",
                }}
              >
                <AdminRadio
                  id="status_all"
                  name="status_category"
                  value="status_all"
                  htmlFor="status_all"
                  label="전체"
                  checked={storyStatus === "전체"}
                  onClick={() => setStoryStatus("전체")}
                />
                <AdminRadio
                  id="status_waiting"
                  name="status_category"
                  value="status_waiting"
                  htmlFor="status_waiting"
                  label="창안중"
                  onClick={() => setStoryStatus("창안중")}
                />
                <AdminRadio
                  id="status_ongoing"
                  name="status_category"
                  value="status_ongoing"
                  htmlFor="status_ongoing"
                  label="창작중"
                  onClick={() => setStoryStatus("창작중")}
                />
                <AdminRadio
                  id="status_holding"
                  name="status_category"
                  value="status_holding"
                  htmlFor="status_holding"
                  label="심사중"
                  onClick={() => setStoryStatus("심사중")}
                />
                <AdminRadio
                  id="status_complete"
                  name="status_category"
                  value="status_complete"
                  htmlFor="status_complete"
                  label="창작완료"
                  onClick={() => setStoryStatus("창작완료")}
                />
                <AdminRadio
                  id="status_published"
                  name="status_category"
                  value="status_published"
                  htmlFor="status_published"
                  label="발매완료"
                  onClick={() => setStoryStatus("발매완료")}
                />
              </Box>
            </RowTableData>
          </Box>
          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="160px" height="48px">
              응원 상태 분류
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 160px)",
                height: "48px",
              }}
            >
              <Box
                size={{
                  width: "100%",
                  height: "100%",
                }}
                display={{
                  display: "flex",
                  justify: "flex-start",
                  align: "center",
                }}
                margin={{
                  left: "8px",
                }}
              >
                <AdminRadio
                  id="sound_all"
                  name="sound_source_status"
                  value="sound_all"
                  htmlFor="sound_all"
                  label="전체"
                  checked={cheeringState === "전체"}
                  onClick={() => setCheeringState("전체")}
                />
                <AdminRadio
                  id="sound_waiting"
                  name="sound_source_status"
                  value="sound_waiting"
                  htmlFor="sound_waiting"
                  label="응원대기"
                  onClick={() => setCheeringState("응원대기")}
                />
                <AdminRadio
                  id="sound_ongoing"
                  name="sound_source_status"
                  value="sound_ongoing"
                  htmlFor="sound_ongoing"
                  label="응원중"
                  onClick={() => setCheeringState("응원중")}
                />
                <AdminRadio
                  id="sound_success"
                  name="sound_source_status"
                  value="sound_success"
                  htmlFor="sound_success"
                  label="응원완료"
                  onClick={() => setCheeringState("응원완료")}
                />
                <AdminRadio
                  id="sound_fail"
                  name="sound_source_status"
                  value="sound_fail"
                  htmlFor="sound_fail"
                  label="응원실패"
                  onClick={() => setCheeringState("응원실패")}
                />
              </Box>
            </RowTableData>
          </Box>
          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="160px" height="48px">
              이야기 분류
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 160px)",
                height: "48px",
              }}
            >
              <Box
                size={{
                  width: "100%",
                  height: "100%",
                }}
                display={{
                  display: "flex",
                  justify: "flex-start",
                  align: "center",
                }}
                margin={{
                  left: "8px",
                }}
              >
                <AdminRadio
                  id="story_all"
                  name="story_category"
                  value="story_all"
                  htmlFor="story_all"
                  label="전체"
                  checked={classification === "전체"}
                  onClick={() => setClassification("전체")}
                />
                <AdminRadio
                  id="story_story"
                  name="story_category"
                  value="story_story"
                  htmlFor="story_story"
                  label="이야기"
                  onClick={() => setClassification("이야기")}
                />
                <AdminRadio
                  id="story_exhibition"
                  name="story_category"
                  value="story_exhibition"
                  htmlFor="story_exhibition"
                  label="기획전"
                  onClick={() => setClassification("기획전")}
                />
                <AdminRadio
                  id="story_private"
                  name="story_category"
                  value="story_private"
                  htmlFor="story_private"
                  label="비공개"
                  onClick={() => setClassification("비공개")}
                />
                <AdminRadio
                  id="story_holding"
                  name="story_category"
                  value="story_holding"
                  htmlFor="story_holding"
                  label="보류"
                  onClick={() => setClassification("보류")}
                />
              </Box>
            </RowTableData>
          </Box>
          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="160px" height="48px">
              작성 기간
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 160px)",
                height: "48px",
              }}
            >
              <Box
                size={{
                  width: "100%",
                  height: "100%",
                }}
                display={{
                  display: "flex",
                  justify: "flex-start",
                  align: "center",
                }}
              >
                <CalendarInput
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                />
                <Text
                  margin={{
                    left: "10px",
                    right: "10px",
                  }}
                >
                  ~
                </Text>
                <CalendarInput
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                />
              </Box>
            </RowTableData>
          </Box>

          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="160px" height="48px">
              검색 분류
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 160px)",
              }}
              padding={{
                top: "8px",
                left: "8px",
              }}
            >
              <AdminDropdown
                size={{
                  width: "104px",
                  height: "32px",
                }}
                menuSize={{
                  width: "104px",
                  height: "auto",
                }}
                category={category}
                onSelect={changeSelected}
              />
              <Input
                theme={"adminInput"}
                size={{
                  width: "500px",
                  height: "32px",
                }}
                value={inputValue}
                onChange={handleInputChange}
              />
              {/* <Input as="radio" theme={"adminRadio"} name="search" value="id" /> */}
            </RowTableData>
          </Box>
        </RowTable>
        <AdminButton
          category="button"
          theme="mainButton"
          margin={{
            top: "24px",
            left: "456px",
          }}
          onClick={handleSearch}
        >
          검색
        </AdminButton>

        {/* 회원 목록 */}
        <Box
          size={{
            width: "100%",
            height: "auto",
          }}
          margin={{
            top: "40px",
          }}
        >
          <Text theme={"adminTitle"}>이야기 목록 </Text>
          <Box
            size={{
              width: "1024px",
              height: "auto",
            }}
            border={{
              top: "1px",
              left: "1px",
              right: "1px",
              type: "solid",
              color: "gray10",
              radius: "3px 3px 0 0",
            }}
          >
            {/* 목록 상단 인원 수, 차단 버튼 */}
            <Box
              theme="adminListBox"
              size={{
                height: "44px",
              }}
              padding={{
                left: "16px",
              }}
            >
              <Text
                as="span"
                typo={{
                  size: "13px",
                  weight: "500",
                  color: "black02",
                }}
              >
                총{" "}
                <Text
                  as="span"
                  typo={{
                    color: "blue01",
                  }}
                >
                  {total}
                </Text>{" "}
                개
              </Text>
              <AdminDropdown
                margin={{
                  right: "10px",
                }}
                size={{
                  width: "110px",
                  height: "28px",
                }}
                menuSize={{
                  width: "110px",
                  height: "auto",
                }}
                category={labels}
                onSelect={changeSelectedList}
              />
            </Box>
            <Box
              theme="adminListBox"
              size={{
                height: "44px",
              }}
              padding={{
                left: "8px",
              }}
              border={{
                top: "1px",
                style: "solid",
                color: "gray10",
              }}
            >
              <AdminButton
                category="button"
                theme="listOptionButton"
                size={{
                  width: "53px",
                  height: "28px",
                }}
                padding={{
                  left: "6px",
                  right: "8px",
                }}
                onClick={handleDelete}
              >
                <Image src="/asset/img/admin/button/red_X.svg" /> 삭제
              </AdminButton>
            </Box>
          </Box>
          {/* 회원 목록 리스트 */}
          <Table isAttached={true} margin={{ bottom: "40px" }}>
            <thead>
              <tr>
                <TableHead width="42px" padding="8px 0">
                  <AdminCheckbox
                    margin={{
                      left: "13px",
                    }}
                    value="all"
                    readOnly={storyList?.length === 0}
                    checked={
                      Object.keys(selectedUser).length === storyList?.length &&
                      Object.values(selectedUser).every((checked) => checked)
                    }
                    onClick={handleSelectAll}
                  />
                </TableHead>
                <TableHead width="92px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    NO.
                  </Text>
                </TableHead>

                <TableHead width="350px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    제목
                  </Text>
                </TableHead>

                <TableHead width="135px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    창안자 이름
                  </Text>
                </TableHead>

                <TableHead width="91px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    이야기 형태
                  </Text>
                </TableHead>

                <TableHead width="77px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    진행 상태
                  </Text>
                </TableHead>
                <TableHead width="75px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    응원 상태
                  </Text>
                </TableHead>
                <TableHead
                  width="calc(1024px - 42px - 92px - 350px - 135px - 91px - 77px - 75px)"
                  isLastChild
                >
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    작성일
                  </Text>
                </TableHead>
              </tr>
            </thead>
            <tbody>
              {storyList?.map((data, index) => (
                <tr key={data.storyId}>
                  <TableData size={{ height: "46px" }}>
                    <AdminCheckbox
                      margin={{
                        left: "13px",
                      }}
                      checked={selectedUser[data.storyId]}
                      onClick={(e) => handleSelect(e, data.storyId)}
                      value={data.storyId}
                    />
                  </TableData>
                  <TableData>
                    {total - (currentPage - 1) * pageSize - index}
                  </TableData>
                  <TableData>
                    <Box
                      display={{
                        display: "flex",
                        align: "center",
                      }}
                      size={{
                        width: "100%",
                        height: "100%",
                      }}
                      padding={{
                        left: "18px",
                        right: "8px",
                      }}
                      typo={{
                        align: "left",
                        lineClamp: 2,
                        textDecoration: "underline #000000",
                      }}
                      other={{
                        cursor: "pointer",
                      }}
                      onClick={() => openNewWindow(data.storyId)}
                    >
                      {data.storyOpen === 1 && (
                        <Image
                          src="/asset/img/admin/lock.svg"
                          margin={{
                            left: "-15px",
                            top: "1px",
                          }}
                          position={{
                            type: "absolute",
                          }}
                        />
                      )}
                      {data.storyTitle}
                    </Box>
                  </TableData>
                  <TableData>
                    <Text
                      size={{
                        width: "165px",
                      }}
                      typo={{
                        lineClamp: "1",
                        wordBreak: "break-all",
                      }}
                      padding={{
                        left: "8px",
                        right: "8px",
                      }}
                    >
                      {data.memberName}
                    </Text>
                  </TableData>
                  <TableData>
                    {data.moomoostPick === false ? "이야기" : "기획전"}
                  </TableData>
                  <TableData>{data.storyStatus}</TableData>
                  <TableData>
                    {data.cheeringStatus ? data.cheeringStatus : "-"}
                  </TableData>
                  <TableData isLastChild>
                    {dayjs(data.createdAt).format("YYYY-MM-DD HH:mm")}
                  </TableData>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
        {total > 0 && (
          <AdminPagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </Box>
    </>
  );
};

export default StoryManagement;
