import Control from "style/utils/core/control";
import padding from "style/utils/styles/padding";
import size from "style/utils/styles/size";
import styled from "styled-components";

const [propTypes, generateStyle] = Control([size, padding]);

export const RowTableDataWrapper = styled.td`
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  align-content: flex-start;

  padding: ${(props) => props.padding};
  font-size: 13px;
  font-weight: 400;
  color: ${({ theme }) => theme.colors.black02};

  padding-left: 10px;
  // overflow: hidden;
  border-top: ${({ isLastChild, theme }) =>
    isLastChild ? "none" : `1px solid ${theme.colors.gray10}`};

  ${(props) => generateStyle(props)}
`;

RowTableDataWrapper.propTypes = propTypes;
