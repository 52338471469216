import dayjs from "dayjs";
import { BiChevronRight } from "react-icons/bi";
import Box from "component/atoms/box";
import Image from "component/atoms/image";
import Link from "component/atoms/link";
import Text from "component/atoms/text";
import CommonButton from "component/molecules/common/commonButton";
import Modal from "component/molecules/common/modal";
import StoryInfo from "component/molecules/common/storyInfo";
import useDeletePortfolioForm from "hook/api/portfolio/delete";
import convertImage from "utils/convertImage";

const StoryPortfolio = (props) => {
  const {
    portfolioId,
    musicName,
    musicLink,
    releaseDate,
    musicCover,
    //
    isYou,
    //
    handleEdit,
  } = props;
  const { toggle, handleToggle, onSubmit } =
    useDeletePortfolioForm(portfolioId);

  return (
    <>
      <Box
        position={{
          type: "relative",
        }}
        display={{
          display: "flex",
          direction: "column",
          gap: "12px",
        }}
        tablet={{
          display: {
            direction: "column",
            wrap: "wrap",
            gap: "0px",
          },
        }}
      >
        <Box
          position={{
            type: "absolute",
            isCenterX: true,
            top: "-125px",
            zIndex: "-1",
          }}
          size={{
            width: "250px",
            height: "250px",
          }}
          pc={{
            size: {
              width: "170px",
              height: "170px",
            },
            position: {
              top: "-85px",
            },
          }}
          longMobile={{
            size: {
              width: "148px",
              height: "148px",
            },
            position: {
              top: "-74px",
            },
          }}
        >
          <Image
            size={{
              width: "100%",
              height: "100%",
            }}
            src={"/asset/img/mypage/mypage_portfolio.svg"}
          />
          <Image
            position={{
              type: "absolute",
              isCenter: true,
            }}
            border={{
              radius: "50%",
            }}
            size={{
              width: "130px",
              height: "130px",
            }}
            pc={{
              size: {
                width: "90px",
                height: "90px",
              },
            }}
            longMobile={{
              size: {
                width: "78px",
                height: "78px",
              },
            }}
            src={
              musicCover
                ? convertImage(musicCover)
                : "/asset/img/mypage/default.svg"
            }
          />
        </Box>

        <StoryInfo
          infoList={[
            <Text
              typo={{
                size: "18px",
                weight: "700",
                align: "center",
              }}
              margin={{
                top: "10px",
                bottom: "14px",
              }}
              longMobile={{
                typo: {
                  size: "14px",
                },
                margin: {
                  top: "5px",
                  bottom: "9px",
                },
              }}
            >
              {musicName}
            </Text>,
            <Box
              display={{
                display: "flex",
                align: "center",
                justify: "space-between",
              }}
              padding={{
                all: "8px 10px",
              }}
              longMobile={{
                padding: {
                  all: "5px 0",
                },
              }}
            >
              <Text
                typo={{
                  size: "12px",
                  weight: "700",
                }}
                longMobile={{
                  typo: {
                    size: "10px",
                  },
                }}
              >
                {dayjs(releaseDate).format("YYYY")}
              </Text>
              <Image
                src={"/asset/img/mypage/musicWave.svg"}
                size={{
                  width: "110px",
                  height: "40px",
                }}
                longMobile={{
                  size: {
                    width: "68px",
                    height: "24px",
                  },
                }}
              />
              <Text
                typo={{
                  size: "12px",
                  weight: "700",
                }}
                longMobile={{
                  typo: {
                    size: "10px",
                  },
                }}
              >
                {dayjs(releaseDate).format("MM:DD")}
              </Text>
            </Box>,
            <Box
              display={{
                display: "flex",
                align: "center",
                justify: isYou ? "space-between" : "center",
              }}
              longMobile={{
                display: {
                  justify: "center",
                },
              }}
            >
              {isYou && (
                <CommonButton
                  category={"button"}
                  size={{
                    width: "46px",
                    height: "28px",
                  }}
                  longMobile={{
                    display: {
                      display: "none",
                    },
                  }}
                  onClick={() =>
                    handleEdit({
                      portfolioId,
                      musicName,
                      musicLink,
                      releaseDate,
                      musicCover,
                    })
                  }
                >
                  수정
                </CommonButton>
              )}
              <Link
                display={{
                  display: "flex",
                  align: "center",
                }}
                size={{
                  height: "28px",
                }}
                typo={{
                  size: "12px",
                }}
                longMobile={{
                  typo: {
                    size: "10px",
                    align: "center",
                  },
                }}
                link={musicLink}
                target={"_blank"}
              >
                감상하러 가기 <BiChevronRight size={15} />
              </Link>
              {isYou && (
                <CommonButton
                  category={"button"}
                  size={{
                    width: "46px",
                    height: "28px",
                  }}
                  longMobile={{
                    display: {
                      display: "none",
                    },
                  }}
                  onClick={handleToggle}
                >
                  삭제
                </CommonButton>
              )}
            </Box>,
          ]}
        />

        <Box
          display={{
            display: "none",
          }}
          margin={{
            top: "5px",
          }}
          longMobile={{
            display: {
              display: "flex",
              align: "center",
              justify: "center",
              gap: "5px",
            },
          }}
        >
          {isYou && (
            <CommonButton
              category={"button"}
              size={{
                width: "50%",
                height: "28px",
              }}
              border={{
                all: "2px solid #000000",
              }}
              onClick={() =>
                handleEdit({
                  portfolioId,
                  musicName,
                  musicLink,
                  releaseDate,
                  musicCover,
                })
              }
            >
              수정
            </CommonButton>
          )}
          {isYou && (
            <CommonButton
              category={"button"}
              size={{
                width: "50%",
                height: "28px",
              }}
              border={{
                all: "2px solid #000000",
              }}
              onClick={handleToggle}
            >
              삭제
            </CommonButton>
          )}
        </Box>
      </Box>

      <Modal title={"포트폴리오"} visible={toggle} onClose={handleToggle}>
        <Text
          margin={{
            all: "60px 0",
          }}
          typo={{
            size: "18px",
            weight: "700",
            align: "center",
          }}
          tablet={{
            typo: {
              size: "14px",
            },
          }}
        >
          포트폴리오를 삭제하시겠습니까?
        </Text>
        <Box
          display={{
            display: "flex",
            justify: "space-between",
            align: "center",
            gap: "10px",
          }}
        >
          <CommonButton
            category={"button"}
            size={{
              width: "50%",
              height: "64px",
            }}
            typo={{
              size: "18px",
              weight: "700",
              align: "center",
            }}
            tablet={{
              size: {
                width: "50%",
                height: "50px",
              },
              typo: {
                size: "16px",
              },
            }}
            onClick={handleToggle}
          >
            취소
          </CommonButton>
          <CommonButton
            category={"button"}
            size={{
              width: "50%",
              height: "64px",
            }}
            typo={{
              size: "18px",
              weight: "700",
              align: "center",
            }}
            tablet={{
              size: {
                width: "50%",
                height: "50px",
              },
              typo: {
                size: "16px",
              },
            }}
            isActive
            onClick={onSubmit}
          >
            삭제
          </CommonButton>
        </Box>
      </Modal>
    </>
  );
};

export default StoryPortfolio;
