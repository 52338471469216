import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

// need FormProvider to be in the tree
const useReset = (name, isReset) => {
  const { setValue } = useFormContext();

  useEffect(() => {
    if (isReset) {
      setValue(name, "");
    }
  }, [name, isReset, setValue]);
};

export default useReset;
