import Box from "component/atoms/box";
import CommonButton from "component/molecules/common/commonButton";
import Agreement from "component/organisms/signup/agreement";
import ArtistInfo from "component/organisms/signup/artistInfo";
import BasicInfo from "component/organisms/signup/basicInfo";
import useSignupForm from "hook/api/auth/signup";
import { FormProvider } from "react-hook-form";

const SignUp = () => {
  const {
    form,
    isActive,
    isPassword,
    isPasswordConfirm,
    //
    required,
    artistRequired,
    //
    handleSubmit,
  } = useSignupForm();

  const { isArtist } = form.watch();

  return (
    <>
      <FormProvider {...form}>
        <Box
          as={"form"}
          display={{
            display: "flex",
            direction: "column",
            justify: "center",
            align: "center",
          }}
          size={{
            width: "580px",
          }}
          margin={{
            all: "64px auto 100px",
          }}
          longMobile={{
            size: {
              width: "100%",
            },
            padding: {
              all: "0 16px",
            },
            margin: {
              top: "80px",
              bottom: "70px",
            },
          }}
        >
          <BasicInfo
            required={required}
            isPassword={isPassword}
            isPasswordConfirm={isPasswordConfirm}
          />

          {isArtist && <ArtistInfo required={artistRequired} />}

          <Agreement required={required} />

          <CommonButton
            category={"button"}
            type={"button"}
            size={{
              width: "100%",
              height: "50px",
            }}
            isDisabled={!isActive}
            isActive={isActive}
            onClick={handleSubmit}
          >
            회원가입
          </CommonButton>
        </Box>
      </FormProvider>
    </>
  );
};

export default SignUp;
