import { cheeringCancel } from "api/mypage";
import { ErrorContent } from "component/organisms/common/globalModal";
import useUrl from "hook/util/useUrl";
import { useMutation } from "react-query";
import useModalStore from "store/useModalStore";

const useCheeringCancel = (handleToggle) => {
  const { changeLink } = useUrl();
  const { setModal } = useModalStore();
  const cheeringCancelMutation = useMutation(cheeringCancel, {
    onSuccess: () => {
      changeLink("/mypage?category=support");
    },
    onError: ({ response: { data } }) => {
      setModal({
        title: "응원 취소",
        content: <ErrorContent message={data.message} />,
      });
      handleToggle();
    },
  });

  const submit = (id) => {
    cheeringCancelMutation.mutate(id);
  };

  return submit;
};

export default useCheeringCancel;
