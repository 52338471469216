import { signup } from "api/auth";
import Text from "component/atoms/text";
import { ErrorContent } from "component/organisms/common/globalModal";
import dayjs from "dayjs";
import usePattern from "hook/util/usePattern";
import useUrl from "hook/util/useUrl";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import useModalStore from "store/useModalStore";
import checkRequired from "utils/checkRequired";

const required = {
  name: true,
  intro: true,
  email: true,
  sendMail: false,
  check: true,
  code: true,
  password: true,
  passwordConfirm: true,
  profile: false,
  isArtist: false,
  isPrivacy: true,
  isMarketing: false,
};

const artistRequired = {
  firstMusic: true,
  firstLink: true,
  firstMusicCover: false,
  firstReleaseDate: true,
};

const SuccessContent = () => {
  return (
    <Text
      typo={{
        size: "18px",
        weight: "700",
      }}
    >
      회원가입이 완료되었습니다.
    </Text>
  );
};

const useSignupForm = () => {
  const form = useForm({
    defaultValues: {
      name: "",
      intro: "",
      email: "",
      password: "",
      passwordConfirm: "",
      profile: "",
      instagram_link: "",
      youtube_link: "",
      soundcloud_link: "",
      other_link: "",
      check: "",
      code: "",
      sendMail: false,
      //
      firstMusic: "",
      firstLink: "",
      firstMusicCover: "",
      firstReleaseDate: dayjs().format("YYYY-MM-DD"),
      //
      secondMusic: "",
      secondLink: "",
      secondMusicCover: "",
      secondReleaseDate: dayjs().format("YYYY-MM-DD"),
      //
      artist_name: "",
      artist_intro: "",
      phone: "",
      //
      isArtist: false,
      isPrivacy: [],
      isMarketing: [],
    },
  });

  const { watch, handleSubmit } = form;
  const { setModal } = useModalStore();
  const { isArtist } = watch();
  const { changeLink } = useUrl();
  const pattern = usePattern();

  const signupMutation = useMutation(signup, {
    onSuccess: () => {
      changeLink("/login");
      setModal({
        title: "회원가입",
        content: <SuccessContent />,
      });
    },
    onError: ({ response: { data } }) => {
      setModal({
        title: "회원가입",
        content: <ErrorContent message={data.message} />,
      });
    },
  });

  const checkSignUpRequired = () => {
    const data = watch();

    let check = checkRequired(required, data);

    if (isArtist[0]) {
      check = check && checkRequired(artistRequired, data);
    }

    return check;
  };

  const { password, passwordConfirm, email, isPrivacy, isMarketing } = watch();

  const isActive = useMemo(() => {
    return (
      checkSignUpRequired() &&
      pattern.password.validate(password) &&
      password === passwordConfirm &&
      pattern.email.value.test(email)
    );
  }, [password, passwordConfirm, email, isPrivacy, isMarketing]);

  const isPassword = useMemo(
    () => !password || pattern.password.validate(password),
    [password]
  );

  const isPasswordConfirm = useMemo(
    () => !passwordConfirm || password === passwordConfirm,
    [password, passwordConfirm]
  );

  const submit = (data) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      let val = value;

      if (Array.isArray(value)) {
        val = value[0];
      }

      formData.append(key, val);
    });

    formData.append("isArtist", isArtist ? 1 : 0);
    formData.append("isPrivacy", 1);
    formData.append("isMarketing", data.isMarketing[0] ? 1 : 0);

    isActive && signupMutation.mutate(formData);
  };

  return {
    form,
    isActive,
    isPassword,
    isPasswordConfirm,
    //
    required,
    artistRequired,
    //
    handleSubmit: handleSubmit(submit),
  };
};

export default useSignupForm;
