import { mainBanner } from "api/banner";
import { useQuery } from "react-query";

const useMainBannerList = ({ device }) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["mainBanner", device],
    () => mainBanner(device)
  );

  return {
    data: data?.data,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useMainBannerList;
