import Box from "component/atoms/box";
import Link from "component/atoms/link";
import Text from "component/atoms/text";
import CommonButton from "component/molecules/common/commonButton";
import Modal from "component/molecules/common/modal";
import StoryInfo from "component/molecules/common/storyInfo";
import Thumbnail from "component/molecules/common/thumbnail";
import ProjectTitle from "component/molecules/main/projectTitle";
import ProjectUser from "component/molecules/main/projectUser";
import useRequestCancel from "hook/api/mypage/requestCancel";
import useToggle from "hook/util/useToggle";
import convertImage from "utils/convertImage";

const StoryRequest = (props) => {
  const { story, matchData, cheeringData } = props;

  const { toggle, handleToggle } = useToggle();
  const requestCancel = useRequestCancel(handleToggle);

  const handleCancel = (e) => {
    e.preventDefault();
    handleToggle();
  };

  return (
    <>
      <Link
        link={`/story/${story.storyId}`}
        target={"_blank"}
        display={{
          display: "flex",
          direction: "column",
          gap: "12px",
        }}
        other={{
          opacity: matchData.isCancel ? "0.3" : "1",
        }}
      >
        <Thumbnail
          src={convertImage(story.storyThumbnail)}
          status={story.storyStatus}
          cheeringData={cheeringData}
          isPick={story.moomoostPick}
        />

        <StoryInfo
          infoList={[
            <ProjectTitle title={story.storyTitle} />,
            <ProjectUser member={story.member} />,
            <Box
              display={{
                display: "flex",
                align: "center",
                justify: "space-between",
              }}
              size={{
                width: "100%",
              }}
            >
              <Text
                typo={{
                  size: "14px",
                  weight: "700",
                  color: matchData.isCancel ? "black01" : "pink02",
                }}
                longMobile={{
                  typo: {
                    size: "10px",
                  },
                }}
              >
                {matchData.isCancel
                  ? matchData.type === "artist"
                    ? "아티스트 요청 취소"
                    : "멤버 요청 취소"
                  : matchData.type === "artist"
                  ? "아티스트 요청"
                  : "멤버 요청"}
              </Text>
              {!matchData.isCancel && (
                <CommonButton
                  category={"button"}
                  size={{
                    width: "60px",
                    height: "23px",
                  }}
                  typo={{
                    size: "12px",
                    weight: "500",
                  }}
                  longMobile={{
                    size: {
                      width: "50px",
                    },
                    padding: {
                      all: "4px",
                    },
                    typo: {
                      size: "10px",
                    },
                  }}
                  onClick={handleCancel}
                >
                  요청 취소
                </CommonButton>
              )}
            </Box>,
          ]}
        />
      </Link>

      <Modal title={"창작 요청"} visible={toggle} onClose={handleToggle}>
        <Text
          margin={{
            all: "60px 0",
          }}
          typo={{
            size: "18px",
            weight: "700",
            align: "center",
          }}
        >
          창작 요청을 취소하시겠습니까?
        </Text>
        <Box
          display={{
            display: "flex",
            justify: "space-between",
            align: "center",
            gap: "10px",
          }}
        >
          <CommonButton
            category={"button"}
            size={{
              width: "50%",
              height: "64px",
            }}
            typo={{
              size: "18px",
              weight: "700",
              align: "center",
            }}
            onClick={handleToggle}
          >
            취소
          </CommonButton>
          <CommonButton
            category={"button"}
            size={{
              width: "50%",
              height: "64px",
            }}
            typo={{
              size: "18px",
              weight: "700",
              align: "center",
            }}
            isActive
            onClick={() => requestCancel(matchData.matchId, matchData.type)}
          >
            요청 취소
          </CommonButton>
        </Box>
      </Modal>
    </>
  );
};

export default StoryRequest;
