import React from "react";
import Text from "component/atoms/text";
import Image from "component/atoms/image";
import Box from "component/atoms/box";
import convertImage from "utils/convertImage";

const HeaderButton = (props) => {
  const { src, title, arrow, onClick, isActive, onMouseLeave, onMouseEnter } =
    props;
  return (
    <Box
      display={{
        display: "flex",
        justify: "space-between",
        align: "center",
      }}
      size={{
        width: "100%",
        height: "50px",
      }}
      padding={{
        all: "0 20px 0 30px",
      }}
      hover={{
        background: { color: "pink03" },
        typo: {
          color: "white01",
        },
      }}
      typo={{
        size: "14px",
        weight: "400",
        color: isActive ? "white01" : "black01",
      }}
      background={{ color: isActive ? "pink03" : "transparent" }}
      onClick={onClick}
      other={{
        cursor: "pointer",
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      <Image src={src} alt={title} />
      <Text
        size={{ width: "100%", height: "100%" }}
        display={{
          display: "flex",
          align: "center",
          justify: "flex-start",
        }}
        margin={{
          left: "9px",
        }}
        typo={{
          size: "14px",
          weight: "400",
          color: isActive ? "white01" : "black01",
        }}
        hover={{
          typo: {
            color: "white01",
          },
        }}
      >
        {title}
      </Text>
      <Image src={arrow} alt={`${title}로 가기`} />
    </Box>
  );
};

export default HeaderButton;
