import { GET } from "api/config";

export const special = async () => await GET("/main/special");

export const story = async () => await GET("/main/latest");

export const footer = async () => await GET("/main/footer");

export const privacy = async () => await GET("/main/privacy");

export const terms = async () => await GET("/main/termsjoin");

export const termsUse = async () => await GET("/main/termsuse");

export const marketing = async () => await GET("/main/marketing");

export const termsStory = async () => await GET(`/main/termsstory`);

export const popup = async () => await GET("/main/popup");

export const requestLink = async () => await GET("/main/request/link");

export const newsLetterLink = async () => await GET("/main/newsletter/link");
