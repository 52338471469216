import { deleteStory } from "api/story";
import Text from "component/atoms/text";
import { ErrorContent } from "component/organisms/common/globalModal";
import useUrl from "hook/util/useUrl";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";

const SuccessContent = () => {
  return (
    <Text
      typo={{
        size: "18px",
        weight: "700",
        align: "center",
      }}
    >
      이야기를 삭제하였습니다.
    </Text>
  );
};

const useDeleteStory = () => {
  const {
    params: { id },
    changeLink,
  } = useUrl();
  const { setModal } = useModalStore();
  const queryClient = useQueryClient();
  const deleteStoryMutation = useMutation(deleteStory, {
    onSuccess: () => {
      changeLink("/story");
      queryClient.invalidateQueries("story");

      setModal({
        title: "이야기 삭제",
        content: <SuccessContent />,
      });
    },
    onError: () => {
      setModal({
        title: "이야기 삭제",
        content: <ErrorContent />,
      });
    },
  });

  const handleDeleteStory = () => {
    deleteStoryMutation.mutate(id);
  };

  return handleDeleteStory;
};

export default useDeleteStory;
