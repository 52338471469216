import { useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  getAdminMarketing,
  updateAdminMarketing,
} from "api/admin/setup/marketing";
import { useNavigate } from "react-router-dom";

export const useAdminMarketing = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery("adminMarketing", getAdminMarketing, {
    onError: (error) => {
      if (error.response && error.response.status === 403) {
        alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
        navigate("/admin/login");
      }
    },
  });
  return { data: data?.data, isLoading };
};

export const useUpdateAdminMarketing = (onSuccessCallback, onErrorCallback) => {
  const queryClient = useQueryClient();
  const { mutate, data, isLoading } = useMutation(updateAdminMarketing, {
    onSuccess: () => {
      if (onSuccessCallback && typeof onSuccessCallback === "function") {
        onSuccessCallback();
      }
      queryClient.invalidateQueries("adminMarketing");
    },
    onError: (error) => {
      if (onErrorCallback && typeof onErrorCallback === "function") {
        onErrorCallback(error);
      }
    },
  });

  return {
    mutate,
    data,
    isLoading,
  };
};
