import Box from "component/atoms/box";
import Button from "component/atoms/button";
import Image from "component/atoms/image";
import Input from "component/atoms/input";
import Line from "component/atoms/line";
import Link from "component/atoms/link";
import Text from "component/atoms/text";
import CommonButton from "component/molecules/common/commonButton";
import useDeleteReply from "hook/api/story/deleteReply";
import useUpdateReplyForm from "hook/api/story/updateReply";
import useToggle from "hook/util/useToggle";
import { useEffect, useRef } from "react";
import { FiMoreVertical } from "react-icons/fi";
import useMobileStore from "store/useDeviceStore";
import convertDate from "utils/convertDate";
import convertImage from "utils/convertImage";

const Reply = (props) => {
  const { member, commentId, userComment, createdAt, type, isWrited } = props;
  const { longMobile, mobile } = useMobileStore();
  const {
    toggle: isMore,
    toggleOff: toggleOffMore,
    toggleOn: toggleOnMore,
  } = useToggle();
  const { toggle: isUpdate, handleToggle: toggleUpdate } = useToggle();
  const {
    toggle: isComment,
    handleToggle: toggleIsComment,
    toggleOff,
    toggleOn,
  } = useToggle();
  const contentRef = useRef();

  const { update, submit } = useUpdateReplyForm(userComment, toggleUpdate);
  const drop = useDeleteReply();

  useEffect(() => {
    if (mobile || longMobile) {
      toggleOff();
    } else {
      toggleOn();
    }
  }, [mobile, longMobile, toggleOff, toggleOn]);

  useEffect(() => {
    const currentHeight = contentRef.current?.clientHeight;

    if (currentHeight > 60) {
      toggleOnMore();
    }
  }, []);

  return (
    <Box
      position={{
        type: "relative",
      }}
      typo={{
        size: "0",
      }}
      key={commentId}
    >
      <Link
        display={{
          display: "flex",
          align: "center",
          justify: "flex-start",
        }}
        link={`/mypage/${member.memberId}`}
      >
        <Image
          size={{
            width: "30px",
            height: "30px",
          }}
          margin={{
            right: "5px",
          }}
          src={
            type === "normal"
              ? convertImage(member.memberProfile)
              : `/asset/img/story/${type}.png`
          }
        />

        <Text
          typo={{
            size: "14px",
            weight: "700",
          }}
        >
          {member.memberName}
        </Text>
      </Link>
      <Box
        margin={{
          top: "10px",
          bottom: "5px",
        }}
      >
        {isUpdate ? (
          <Input
            as={"textarea"}
            size={{
              width: "100%",
              height: "104px",
            }}
            {...update}
          />
        ) : (
          <>
            <Text
              ref={contentRef}
              typo={{
                size: "14px",
                weight: "400",
                whiteSpace: "pre-wrap",
                lineClamp: isMore ? 3 : false,
                wordBreak: "break-all",
              }}
            >
              {userComment}
            </Text>
            {isMore && (
              <Button
                background={{
                  color: "transparent",
                }}
                typo={{
                  size: "14px",
                  weight: "400",
                  color: "gray04",
                }}
                onClick={toggleOffMore}
              >
                자세히보기
              </Button>
            )}
            <Text
              typo={{
                size: "12px",
                weight: "400",
                color: "gray04",
              }}
            >
              {convertDate(createdAt)}
            </Text>
          </>
        )}
      </Box>

      <Line
        direction={"row"}
        size={"100%"}
        margin={{ all: "18px 0" }}
        background={{ color: "gray02" }}
      />

      {isWrited && (
        <Box
          display={{
            display: "flex",
            gap: "5px",
          }}
          position={{
            type: "absolute",
            top: "-5px",
            right: "0px",
          }}
          longMobile={{
            display: {
              direction: "column",
              gap: "0px",
            },
            position: {
              top: "16px",
            },
          }}
        >
          <Button
            display={{
              display: "none",
            }}
            background={{
              color: "transparent",
            }}
            longMobile={{
              display: {
                display: "flex",
                justify: "flex-end",
              },
            }}
            onClick={toggleIsComment}
          >
            <FiMoreVertical size={15} />
          </Button>
          {isComment &&
            (isUpdate ? (
              <>
                <CommonButton
                  category="button"
                  size={{
                    width: "46px",
                    height: "40px",
                  }}
                  typo={{
                    size: "14px",
                  }}
                  longMobile={{
                    border: {
                      top: "1px",
                      left: "1px",
                      right: "1px",
                    },
                    size: {
                      width: "43px",
                      height: "31px",
                    },
                    typo: {
                      size: "12px",
                    },
                  }}
                  onClick={(e) => submit(e, commentId)}
                >
                  저장
                </CommonButton>
                <CommonButton
                  category="button"
                  size={{
                    width: "46px",
                    height: "40px",
                  }}
                  typo={{
                    size: "14px",
                  }}
                  longMobile={{
                    size: {
                      width: "43px",
                      height: "31px",
                    },
                    typo: {
                      size: "12px",
                    },
                  }}
                  onClick={toggleUpdate}
                >
                  취소
                </CommonButton>
              </>
            ) : (
              <>
                <CommonButton
                  category="button"
                  size={{
                    width: "46px",
                    height: "40px",
                  }}
                  typo={{
                    size: "14px",
                  }}
                  longMobile={{
                    border: {
                      top: "1px",
                      left: "1px",
                      right: "1px",
                    },
                    size: {
                      width: "43px",
                      height: "31px",
                    },
                    typo: {
                      size: "12px",
                    },
                  }}
                  onClick={toggleUpdate}
                >
                  수정
                </CommonButton>
                <CommonButton
                  category="button"
                  size={{
                    width: "46px",
                    height: "40px",
                  }}
                  typo={{
                    size: "14px",
                  }}
                  longMobile={{
                    size: {
                      width: "43px",
                      height: "31px",
                    },
                    typo: {
                      size: "12px",
                    },
                  }}
                  onClick={() => drop(commentId)}
                >
                  삭제
                </CommonButton>
              </>
            ))}
        </Box>
      )}
    </Box>
  );
};

export default Reply;
