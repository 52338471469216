import Box from "component/atoms/box";
import Text from "component/atoms/text";
import React, { useEffect, useState } from "react";
import AdminButton from "component/molecules/admin/button/adminButton";
import Image from "component/atoms/image";
import TableData from "component/atoms/tableData";
import TableHead from "component/atoms/tableHead";
import Table from "component/atoms/table";
import AdminCheckbox from "component/molecules/adminCheckbox";
import AdminAlertModal from "../../modal/alertModal";
import RowTableHead from "component/atoms/rowTableHead";
import RowTableData from "component/atoms/rowTableData";
import RowTable from "component/atoms/rowSideTable";
import Input from "component/atoms/input";
import AdminRadio from "component/molecules/admin/adminRadio";
import ImageInput from "../../imageInput";
import dayjs from "dayjs";
import {
  useAdminCreateMainBanner,
  useAdminCreateMobileMainBanner,
  useAdminDeleteMainBanner,
  useAdminDeleteMobileMainBanner,
  useAdminMainBanner,
  useAdminMobileMainBanner,
  useAdminUpdateMainBanner,
  useAdminUpdateMobileMainBanner,
  useMainBannerOrder,
} from "hook/api/admin/banner";
import convertImage from "utils/convertImage";
import { useForm } from "react-hook-form";
import { DragDropContext, Droppable, Draggable } from "@hello-pangea/dnd";
import useEditStore from "store/useEditStore";

const MainBannerManage = (props) => {
  const baseUrl = process.env.REACT_APP_SERVER_URL;

  // pc || mobile 버튼 상태 관리
  const [selected, setSelected] = useState("pc");
  // 순서 변경 하다가 페이지 이동 혹은 카테고리 클릭 시 변경 사항 저장 안내
  const { updateEdit, clearEdit } = useEditStore();
  // pc || mobile 버튼 클릭 시
  const handleSelect = (button) => {
    if (
      orderedList.length > 0 &&
      !window.confirm("변경 사항을 저장하지 않고 이동하시겠습니까?")
    ) {
      return;
    }

    clearEdit();
    setSelected(button);
    setOrderedList([]);
    setSelectedUser({});
    setEdit(false);

    if (selected === "pc") {
      refetchBanner();
    } else {
      refetchMobileBanner();
    }
  };

  // pc || mobile 버튼 클릭 시 테마 전환
  const getButtonTheme = (button) => {
    if (selected === button) {
      return ["subButton"];
    } else {
      return ["subButton", "whiteColor400"];
    }
  };

  // 저장 알림 모달창 상태 관리
  const [visible, setVisible] = useState(false);
  const [visible2, setVisible2] = useState(false);
  // 수정 페이지에서 저장 버튼 클릭 시
  const [save, setSave] = useState(false);
  // 순서변경 버튼 상태 관리
  const [edit, setEdit] = useState(false);
  // 배너 수정 상태 관리
  const [bannerEdit, setBannerEdit] = useState(false);

  // 순서 변경 저장 버튼 클릭 시
  const handleVisible = () => {
    setVisible(true);
  };

  const handleVisible2 = () => {
    setVisible2(false);
  };

  // imageInput
  const { image } = props;
  const { watch, setValue } = useForm({
    defaultValues: {
      ...image,
      popupThumbnail: image?.popupThumbnail,
    },
  });

  const { thumbnail } = watch();

  const handleProfile = (file) => {
    setValue("thumbnail", file);
    setBannerImage(file);
    setEditingBanner({ ...editingBanner, bannerImage: file });
  };
  // 수정할 data
  const [editingBanner, setEditingBanner] = useState(null);
  // 수정 모드
  const [isEditing, setIsEditing] = useState(false);
  const [title, setTitle] = useState("");
  const [bannerImage, setBannerImage] = useState("");
  const [linkUse, setLinkUse] = useState(
    editingBanner ? editingBanner?.linkUse : 0
  );
  const [bannerLink, setBannerLink] = useState(
    editingBanner ? editingBanner?.bannerLink : ""
  );
  const [newWindow, setNewWindow] = useState(0);

  const handleEditClick = (data) => {
    setEditingBanner(data);
    setIsEditing(true);
    setBannerEdit(!bannerEdit);
  };

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);

  const handleSuccess = () => {
    setSuccess(true);
  };

  const handleError = () => {
    setError(true);
  };

  const handleSuccessDelete = () => {
    setSuccessDelete(true);
  };

  const handleErrorDelete = () => {
    setSuccessDelete(false);
  };

  const { mutate: createBanner } = useAdminCreateMainBanner(
    handleSuccess,
    handleError
  );

  const { mutate: updateBanner } = useAdminUpdateMainBanner(
    selected === "pc" ? editingBanner?.pbannerId : editingBanner?.mbannerId,
    handleSuccess,
    handleError
  );

  const { mutate: deleteBanner } = useAdminDeleteMainBanner(
    handleSuccessDelete,
    handleErrorDelete
  );

  const { mutate: createMobileBanner } = useAdminCreateMobileMainBanner(
    handleSuccess,
    handleError
  );

  const { mutate: updateMobileBanner } = useAdminUpdateMobileMainBanner(
    selected === "pc" ? editingBanner?.pbannerId : editingBanner?.mbannerId,
    handleSuccess,
    handleError
  );

  const { mutate: deleteMobileBanner } = useAdminDeleteMobileMainBanner(
    handleSuccessDelete,
    handleErrorDelete
  );

  // 배너 등록 저장 버튼 클릭 시
  const handleBannerSave = () => {
    const formData = new FormData();

    if (isEditing && editingBanner) {
      if (!editingBanner.title) {
        alert("제목을 입력해주세요.");
        return;
      }
      if (!editingBanner.bannerImage) {
        alert("배너 이미지를 등록해주세요.");
        return;
      }
      if (linkUse === "") {
        alert("링크 사용 여부를 선택해주세요.");
        return;
      }
      if (linkUse === 1 && !bannerLink) {
        alert("배너 링크를 입력해주세요.");
        return;
      }
    } else {
      if (!title) {
        alert("제목을 입력해주세요.");
        return;
      }
      if (!bannerImage) {
        alert("배너 이미지를 등록해주세요.");
        return;
      }
      if (linkUse === "") {
        alert("링크 사용 여부를 선택해주세요.");
        return;
      }
      if (linkUse === 1 && !bannerLink) {
        alert("배너 링크를 입력해주세요.");
        return;
      }
    }

    formData.append("title", isEditing ? editingBanner.title : title);

    if (bannerImage) {
      formData.append("bannerImage", bannerImage);
    } else {
      formData.append(
        "bannerImage",
        editingBanner.bannerImage ? editingBanner.bannerImage : ""
      );
    }
    formData.append("linkUse", isEditing ? editingBanner.linkUse : linkUse);
    formData.append(
      "bannerLink",
      isEditing ? editingBanner.bannerLink : bannerLink
    );
    formData.append(
      "newWindows",
      isEditing ? editingBanner.newWindows : newWindow
    );

    // API 호출
    if (isEditing) {
      if (selected === "pc") {
        updateBanner(formData);
      } else {
        updateMobileBanner(formData);
      }
    } else {
      if (selected === "pc") {
        createBanner(formData);
      } else {
        createMobileBanner(formData);
      }
    }

    setSave(true);
    setIsEditing(false);
    setBannerEdit(!bannerEdit);
  };

  const handleFieldChange = (e) => {
    if (isEditing && editingBanner) {
      setEditingBanner({ ...editingBanner, [e.target.name]: e.target.value });
      setBannerLink(e.target.value);
    } else {
      setBannerLink(e.target.value);
    }

    if (linkUse === 0) {
      setBannerLink("");
      setNewWindow(0);
    }
  };

  // linkUse 변경 핸들링
  const handleLinkUseChange = (newValue) => {
    if (isEditing && editingBanner) {
      setEditingBanner({ ...editingBanner, linkUse: newValue });
      setLinkUse(newValue);
    } else {
      setLinkUse(newValue);
    }
    if (newValue === 0) {
      setLinkUse(0);
      setBannerLink("");
      setNewWindow(0);
      setEditingBanner({
        ...editingBanner,
        linkUse: 0,
        bannerLink: "",
        newWindows: 0,
      });
    }
  };

  // newWindows 변경 핸들링
  const handleNewWindowsChange = (e) => {
    const newValue = e.target.checked ? 1 : 0;
    if (isEditing) {
      setEditingBanner({ ...editingBanner, newWindows: newValue });
    } else {
      setNewWindow(newValue);
    }
  };

  const handleEdit = () => {
    setEdit(!edit);
    setIsEditing(false);
  };

  const handleDelete = (bannerId) => {
    if (bannerId.length === 0) {
      alert("삭제할 배너를 선택해주세요.");
      return;
    }
    if (selected === "pc") {
      window.confirm("선택된 배너를 삭제하시겠습니까?") &&
        deleteBanner({ bannerId: bannerId });
    } else {
      window.confirm("선택된 배너를 삭제하시겠습니까?") &&
        deleteMobileBanner({ bannerId: bannerId });
    }
  };

  const resetForm = () => {
    setIsEditing(false);
    setEditingBanner(null);
    setTitle("");
    setBannerImage("");
    setLinkUse(0);
    setBannerLink("");
    setNewWindow(0);
  };

  const handleBannerEdit = (data) => {
    if (data >= 5) {
      alert("메인 배너 최대 등록 개수는 5개입니다.");
      return;
    } else {
      resetForm();
      setBannerEdit(!bannerEdit);
    }
  };
  // 체크박스를 선택하여 일괄 승인
  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    setSelectedUser({});
  }, []);

  // 전체 선택 또는 해제 처리
  const handleSelectAll = (e, data) => {
    const checked = e.target.checked;
    setSelectedUser((prevSelectedUser) => {
      const newSelectedUser = {};
      if (checked) {
        data?.forEach((data) => {
          selected === "pc"
            ? (newSelectedUser[data.pbannerId] = true)
            : (newSelectedUser[data.mbannerId] = true);
        });
      }
      return newSelectedUser;
    });
  };

  // 개별 선택 처리
  const handleSelectBox = (e, bannerId) => {
    const checked = e.target.checked;
    setSelectedUser((prevSelectedUser) => {
      const newSelectedUser = { ...prevSelectedUser };
      newSelectedUser[bannerId] = checked;
      return newSelectedUser;
    });
  };

  const selectedUserIds = Object.entries(selectedUser)
    .filter(([_, checked]) => checked)
    .map(([bannerId]) => bannerId);

  const handleAllDelete = () => {
    if (selectedUserIds.length === 0) {
      alert("삭제할 배너를 선택해주세요.");
      return;
    }
    if (selected === "pc") {
      window.confirm("선택된 배너를 삭제하시겠습니까?") &&
        deleteBanner({ bannerId: selectedUserIds }) &&
        setSelectedUser({});
    } else {
      window.confirm("선택된 배너를 삭제하시겠습니까?") &&
        deleteMobileBanner({ bannerId: selectedUserIds }) &&
        setSelectedUser({});
    }
  };

  const { mutate: changeOrder } = useMainBannerOrder(
    handleVisible,
    handleVisible2
  );

  const {
    data: bannerData,
    totalData: totalData,
    refetch: refetchBanner,
  } = useAdminMainBanner();
  const {
    data: mobileBannerData,
    totalData: mobileTotalData,
    refetch: refetchMobileBanner,
  } = useAdminMobileMainBanner();

  const [orderedList, setOrderedList] = useState([]);
  const onDragEnd = ({ draggableId, destination, source }) => {
    if (!destination) return;
    if (destination.index === source.index) return;

    const list = selected === "pc" ? bannerData : mobileBannerData;

    // 인덱스를 사용하여 이동할 항목 찾기
    const item = list[source.index];
    if (!item) return;

    // 리스트에서 항목을 삭제하고 새 위치에 삽입
    list.splice(source.index, 1);
    list.splice(destination.index, 0, item);

    setOrderedList(list);
    updateEdit();
  };

  const handleSaveOrder = () => {
    const list = selected === "pc" ? bannerData : mobileBannerData;
    const orderedList = list.map((data) =>
      selected === "pc" ? data.pbannerId : data.mbannerId
    );

    if (selected === "pc") {
      changeOrder({ data: { bannerIds: orderedList }, device: "pc" });
    } else {
      changeOrder({ data: { mbannerIds: orderedList }, device: "mobile" });
    }
    setEdit(!edit);
    setOrderedList([]);
    clearEdit();
  };

  const handleEditCancel = () => {
    if (orderedList.length > 0) {
      if (window.confirm("순서 변경을 취소하시겠습니까?")) {
        setEdit(!edit);
        setOrderedList([]);
        setSelectedUser({});
        clearEdit();

        if (selected === "pc") {
          refetchBanner();
        } else {
          refetchMobileBanner();
        }
      }
    } else {
      setEdit(!edit);
    }
  };

  useEffect(() => {
    if (editingBanner && isEditing) {
      setTitle(editingBanner.title);

      setBannerImage(editingBanner.bannerImage);
      setLinkUse(editingBanner.linkUse);
      setBannerLink(editingBanner.bannerLink);
      setNewWindow(editingBanner.newWindows);
    }
  }, [editingBanner]);

  // 저장 버튼 비활성화
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    // title과 bannerImage가 유효해야 하며,
    // linkUse가 "1"일 경우에만 bannerLink도 유효해야 함
    if (editingBanner && isEditing) {
      if (
        editingBanner.title &&
        editingBanner.bannerImage &&
        (linkUse === 0 ||
          (linkUse === 1 && bannerLink) ||
          editingBanner.linkUse === 0 ||
          (editingBanner.linkUse === 1 && bannerLink))
      ) {
        setIsDone(true);
      } else {
        setIsDone(false);
      }
    } else if (!isEditing) {
      if (
        title &&
        bannerImage &&
        (linkUse === 0 || (linkUse === 1 && bannerLink))
      ) {
        setIsDone(true);
      } else {
        setIsDone(false);
      }
    }
  }, [title, bannerImage, linkUse, bannerLink]);

  return (
    <>
      <AdminAlertModal
        title={"메인 배너 순서 변경"}
        text={
          visible
            ? "메인 배너 순서 변경에 성공하였습니다."
            : "메인 배너 순서 변경에 실패하였습니다."
        }
        btn_text="확인"
        visible={visible || visible2}
        toggle={() => {
          setVisible(false);
          setVisible2(false);
        }}
      />
      <AdminAlertModal
        title={successDelete ? "메인 배너 삭제" : "메인 배너 등록"}
        text={
          successDelete
            ? "메인 배너 삭제가 완료되었습니다."
            : save
            ? "메인 배너 등록이 완료되었습니다."
            : "메인 배너 목록 수정이 완료되었습니다."
        }
        btn_text="확인"
        visible={save || success || successDelete}
        toggle={() => {
          setSave(false);
          setSuccess(false);
          setSuccessDelete(false);
        }}
      />
      {!bannerEdit ? (
        selected === "pc" ? (
          <Box
            size={{
              width: "100%",
              height: "auto",
            }}
            margin={{
              top: "25px",
            }}
            other={{
              overflowX: "hidden",
            }}
          >
            <Text theme={"adminTitle"}>메인 배너 그룹 목록</Text>
            <Box
              display={{
                display: "flex",
              }}
            >
              <AdminButton
                category="button"
                theme={getButtonTheme("pc")}
                onClick={() => handleSelect("pc")}
              >
                메인 배너 (PC)
              </AdminButton>
              <AdminButton
                category="button"
                theme={getButtonTheme("mobile")}
                onClick={() => handleSelect("mobile")}
                margin={{
                  left: "10px",
                }}
              >
                메인 배너 (m)
              </AdminButton>
            </Box>
            {/* 회원 목록 */}
            <Box
              size={{
                width: "100%",
                height: "auto",
              }}
              margin={{
                top: "40px",
              }}
            >
              <Box
                display={{
                  display: "flex",
                }}
              >
                <Text theme={"adminTitle"}>배너 목록</Text>
                <Text
                  typo={{
                    size: "13px",
                    color: "gray08",
                    weight: "400",
                  }}
                  margin={{
                    left: "862px",
                    top: "3px",
                  }}
                >
                  최대 등록 개수 : 5
                </Text>
              </Box>
              <Box
                size={{
                  width: "1024px",
                  height: "auto",
                }}
                border={{
                  top: "1px",
                  left: "1px",
                  right: "1px",
                  type: "solid",
                  color: "gray10",
                  radius: "3px 3px 0 0",
                }}
              >
                <Box
                  theme="adminListBox"
                  size={{
                    height: "44px",
                  }}
                  padding={{
                    left: "8px",
                  }}
                >
                  <AdminButton
                    category="button"
                    theme="listOptionButton"
                    size={{
                      width: "53px",
                      height: "28px",
                    }}
                    padding={{
                      left: "6px",
                      right: "6px",
                    }}
                    onClick={handleAllDelete}
                  >
                    <Image
                      src="/asset/img/admin/button/red_X.svg"
                      margin={{
                        right: "2px",
                      }}
                    />
                    삭제
                  </AdminButton>
                  <AdminButton
                    category="button"
                    size={{
                      width: "67px",
                      height: "28px",
                    }}
                    padding={{
                      left: "6px",
                      right: "6px",
                    }}
                    typo={{
                      size: "12px",
                      weight: "400",
                      color: "white01",
                    }}
                    margin={{
                      right: "12px",
                    }}
                    background={{
                      color: totalData >= 5 ? "gray10" : "pink03",
                    }}
                    onClick={() => handleBannerEdit(totalData)}
                  >
                    배너 등록
                  </AdminButton>
                </Box>
              </Box>

              <DragDropContext onDragEnd={onDragEnd}>
                <Table isAttached={true} margin={{ bottom: "40px" }}>
                  <thead>
                    <tr>
                      <TableHead width="42px" padding="8px 0">
                        <AdminCheckbox
                          margin={{
                            left: "13px",
                          }}
                          readOnly={totalData === 0}
                          checked={
                            Object.keys(selectedUser).length === totalData &&
                            Object.values(selectedUser).every(
                              (checked) => checked
                            )
                          }
                          onClick={(e) => handleSelectAll(e, bannerData)}
                        />
                      </TableHead>
                      <TableHead width="55px">
                        <Text
                          typo={{
                            color: "black02",
                          }}
                        >
                          NO.
                        </Text>
                      </TableHead>

                      <TableHead width="283px">
                        <Text
                          typo={{
                            color: "black02",
                          }}
                        >
                          배너 이미지
                        </Text>
                      </TableHead>

                      <TableHead width="299px">
                        <Text
                          typo={{
                            color: "black02",
                          }}
                        >
                          제목
                        </Text>
                      </TableHead>

                      <TableHead width="150px">
                        <Text
                          typo={{
                            color: "black02",
                          }}
                        >
                          등록일시
                        </Text>
                      </TableHead>
                      <TableHead
                        width="calc(1024px - 42px - 55px - 283px - 299px - 150px)"
                        isLastChild
                      >
                        <Text
                          typo={{
                            color: "black02",
                          }}
                        >
                          관리
                        </Text>
                      </TableHead>
                    </tr>
                  </thead>

                  <Droppable droppableId="droppable-1">
                    {(provided, snapshot) => (
                      <tbody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {bannerData?.map((data, index) => (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}
                            isDragDisabled={!edit}
                          >
                            {(provided, snapshot) => (
                              <tr
                                key={
                                  selected === "pc"
                                    ? data.pbannerId
                                    : data.mbannerId
                                }
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                {...provided.placeholder}
                              >
                                <TableData
                                  size={{
                                    height: "106px",
                                    width: "42px",
                                  }}
                                >
                                  <AdminCheckbox
                                    margin={{
                                      left: "13px",
                                    }}
                                    checked={
                                      selected === "pc"
                                        ? selectedUser[data.pbannerId]
                                        : selectedUser[data.mbannerId]
                                    }
                                    onClick={(e) =>
                                      selected === "pc"
                                        ? handleSelectBox(e, data.pbannerId)
                                        : handleSelectBox(e, data.mbannerId)
                                    }
                                    value={
                                      selected === "pc"
                                        ? data.pbannerId
                                        : data.mbannerId
                                    }
                                  />
                                </TableData>
                                <TableData
                                  size={{
                                    width: "55px",
                                  }}
                                >
                                  {index + 1}
                                </TableData>
                                <TableData
                                  size={{
                                    width: "283px",
                                  }}
                                >
                                  <Image
                                    size={{
                                      width:
                                        selected === "pc" ? "241px" : "89px",
                                      height: "89px",
                                    }}
                                    src={`${baseUrl}/${data.bannerImage}`}
                                    margin={{
                                      top: "5px",
                                    }}
                                  />
                                </TableData>
                                <TableData
                                  size={{
                                    width: "299px",
                                  }}
                                >
                                  <Text
                                    typo={{
                                      lineClamp: 2,
                                      wordBreak: "break-all",
                                    }}
                                    padding={{
                                      left: "8px",
                                      right: "8px",
                                    }}
                                  >
                                    {data.title}
                                  </Text>
                                </TableData>
                                <TableData
                                  size={{
                                    width: "150px",
                                  }}
                                >
                                  {dayjs(data.createDate).format(
                                    "YYYY-MM-DD HH:mm"
                                  )}
                                </TableData>
                                <TableData
                                  isLastChild
                                  size={{
                                    width:
                                      "calc(1024px - 42px - 55px - 283px - 299px - 150px)",
                                  }}
                                >
                                  <Box
                                    display={{
                                      display: "flex",
                                    }}
                                  >
                                    <AdminButton
                                      category="button"
                                      theme="whiteColor"
                                      size={{
                                        width: "72px",
                                        height: "30px",
                                      }}
                                      typo={{
                                        weight: "400",
                                      }}
                                      margin={{
                                        left: "20px",
                                      }}
                                      onClick={() => handleEditClick(data)}
                                    >
                                      수정
                                    </AdminButton>
                                    <AdminButton
                                      category="button"
                                      theme="whiteColor"
                                      size={{
                                        width: "72px",
                                        height: "30px",
                                      }}
                                      typo={{
                                        weight: "400",
                                      }}
                                      margin={{
                                        left: "10px",
                                        right: "20px",
                                      }}
                                      onClick={() =>
                                        handleDelete(
                                          selected === "pc"
                                            ? [data.pbannerId]
                                            : [data.mbannerId]
                                        )
                                      }
                                    >
                                      삭제
                                    </AdminButton>
                                  </Box>
                                </TableData>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </Table>
              </DragDropContext>
            </Box>
            <Box theme="adminRight" margin={{ top: "-25px", bottom: "25px" }}>
              {!edit && (
                <AdminButton
                  category="button"
                  theme={["subButton", "whiteColor"]}
                  onClick={handleEdit}
                >
                  순서변경
                </AdminButton>
              )}

              {edit && (
                <Box
                  display={{
                    display: "flex",
                  }}
                >
                  <AdminButton
                    category="button"
                    theme={["mainButton", "grayColor"]}
                    margin={{
                      right: "10px",
                    }}
                    onClick={handleEditCancel}
                  >
                    취소
                  </AdminButton>
                  <AdminButton
                    category="button"
                    theme={["mainButton"]}
                    onClick={handleSaveOrder}
                  >
                    저장
                  </AdminButton>
                </Box>
              )}
            </Box>
          </Box>
        ) : (
          <Box
            size={{
              width: "100%",
              height: "auto",
            }}
            margin={{
              top: "25px",
            }}
          >
            <Text theme={"adminTitle"}>메인 배너 그룹 목록</Text>
            <Box
              display={{
                display: "flex",
              }}
            >
              <AdminButton
                category="button"
                theme={getButtonTheme("pc")}
                onClick={() => handleSelect("pc")}
              >
                메인 배너 (PC)
              </AdminButton>
              <AdminButton
                category="button"
                theme={getButtonTheme("mobile")}
                onClick={() => handleSelect("mobile")}
                margin={{
                  left: "10px",
                }}
              >
                메인 배너 (m)
              </AdminButton>
            </Box>
            {/* 회원 목록 */}
            <Box
              size={{
                width: "100%",
                height: "auto",
              }}
              margin={{
                top: "40px",
              }}
            >
              <Box
                display={{
                  display: "flex",
                }}
              >
                <Text theme={"adminTitle"}>배너 목록</Text>
                <Text
                  typo={{
                    size: "13px",
                    color: "gray08",
                    weight: "400",
                  }}
                  margin={{
                    left: "862px",
                    top: "3px",
                  }}
                >
                  최대 등록 개수 : 5
                </Text>
              </Box>
              <Box
                size={{
                  width: "1024px",
                  height: "auto",
                }}
                border={{
                  top: "1px",
                  left: "1px",
                  right: "1px",
                  type: "solid",
                  color: "gray10",
                  radius: "3px 3px 0 0",
                }}
              >
                <Box
                  theme="adminListBox"
                  size={{
                    height: "44px",
                  }}
                  padding={{
                    left: "8px",
                  }}
                >
                  <AdminButton
                    category="button"
                    theme="listOptionButton"
                    size={{
                      width: "53px",
                      height: "28px",
                    }}
                    padding={{
                      left: "6px",
                      right: "6px",
                    }}
                    onClick={handleAllDelete}
                  >
                    <Image
                      src="/asset/img/admin/button/red_X.svg"
                      margin={{
                        right: "2px",
                      }}
                    />
                    삭제
                  </AdminButton>
                  <AdminButton
                    category="button"
                    size={{
                      width: "67px",
                      height: "28px",
                    }}
                    padding={{
                      left: "6px",
                      right: "6px",
                    }}
                    typo={{
                      size: "12px",
                      weight: "400",
                      color: "white01",
                    }}
                    margin={{
                      right: "12px",
                    }}
                    background={{
                      color: mobileTotalData >= 5 ? "gray10" : "pink03",
                    }}
                    onClick={() => handleBannerEdit(mobileTotalData)}
                  >
                    배너 등록
                  </AdminButton>
                </Box>
              </Box>
              {/* 회원 목록 리스트 */}
              <DragDropContext onDragEnd={onDragEnd}>
                <Table isAttached={true} margin={{ bottom: "40px" }}>
                  <thead>
                    <tr>
                      <TableHead width="42px" padding="8px 0">
                        <AdminCheckbox
                          margin={{
                            left: "13px",
                          }}
                          readOnly={mobileTotalData === 0}
                          checked={
                            Object.keys(selectedUser).length ===
                              mobileTotalData &&
                            Object.values(selectedUser).every(
                              (checked) => checked
                            )
                          }
                          onClick={(e) => handleSelectAll(e, mobileBannerData)}
                        />
                      </TableHead>
                      <TableHead width="55px">
                        <Text
                          typo={{
                            color: "black02",
                          }}
                        >
                          NO.
                        </Text>
                      </TableHead>

                      <TableHead width="283px">
                        <Text
                          typo={{
                            color: "black02",
                          }}
                        >
                          배너 이미지
                        </Text>
                      </TableHead>

                      <TableHead width="299px">
                        <Text
                          typo={{
                            color: "black02",
                          }}
                        >
                          제목
                        </Text>
                      </TableHead>

                      <TableHead width="150px">
                        <Text
                          typo={{
                            color: "black02",
                          }}
                        >
                          등록일시
                        </Text>
                      </TableHead>
                      <TableHead
                        width="calc(1024px - 42px - 55px - 283px - 299px - 150px)"
                        isLastChild
                      >
                        <Text
                          typo={{
                            color: "black02",
                          }}
                        >
                          관리
                        </Text>
                      </TableHead>
                    </tr>
                  </thead>
                  <Droppable droppableId="droppable-2">
                    {(provided, snapshot) => (
                      <tbody
                        ref={provided.innerRef}
                        {...provided.droppableProps}
                      >
                        {mobileBannerData?.map((data, index) => (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}
                            isDragDisabled={!edit}
                          >
                            {(provided, snapshot) => (
                              <tr
                                key={
                                  selected === "pc"
                                    ? data.pbannerId
                                    : data.mbannerId
                                }
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <TableData
                                  size={{ height: "106px", width: "42px" }}
                                >
                                  <AdminCheckbox
                                    margin={{
                                      left: "13px",
                                    }}
                                    checked={
                                      selected === "pc"
                                        ? selectedUser[data.pbannerId]
                                        : selectedUser[data.mbannerId]
                                    }
                                    onClick={(e) =>
                                      selected === "pc"
                                        ? handleSelectBox(e, data.pbannerId)
                                        : handleSelectBox(e, data.mbannerId)
                                    }
                                    value={
                                      selected === "pc"
                                        ? data.pbannerId
                                        : data.mbannerId
                                    }
                                  />
                                </TableData>
                                <TableData
                                  size={{
                                    width: "55px",
                                  }}
                                >
                                  {index + 1}
                                </TableData>
                                <TableData
                                  size={{
                                    width: "283px",
                                  }}
                                >
                                  <Image
                                    size={{
                                      width:
                                        selected === "pc" ? "241px" : "89px",
                                      height: "89px",
                                    }}
                                    src={`${baseUrl}/${data.bannerImage}`}
                                    margin={{
                                      top: "5px",
                                    }}
                                  />
                                </TableData>
                                <TableData
                                  size={{
                                    width: "299px",
                                  }}
                                >
                                  <Text
                                    typo={{
                                      lineClamp: 2,
                                      wordBreak: "break-all",
                                    }}
                                    padding={{
                                      left: "8px",
                                      right: "8px",
                                    }}
                                  >
                                    {data.title}
                                  </Text>
                                </TableData>
                                <TableData
                                  size={{
                                    width: "150px",
                                  }}
                                >
                                  {dayjs(data.createDate).format(
                                    "YYYY-MM-DD HH:mm"
                                  )}
                                </TableData>
                                <TableData
                                  size={{
                                    width:
                                      "calc(1024px - 42px - 55px - 283px - 299px - 150px)",
                                  }}
                                  isLastChild
                                >
                                  <Box
                                    display={{
                                      display: "flex",
                                    }}
                                  >
                                    <AdminButton
                                      category="button"
                                      theme="whiteColor"
                                      size={{
                                        width: "72px",
                                        height: "30px",
                                      }}
                                      typo={{
                                        weight: "400",
                                      }}
                                      margin={{
                                        left: "20px",
                                      }}
                                      onClick={() => handleEditClick(data)}
                                    >
                                      수정
                                    </AdminButton>
                                    <AdminButton
                                      category="button"
                                      theme="whiteColor"
                                      size={{
                                        width: "72px",
                                        height: "30px",
                                      }}
                                      typo={{
                                        weight: "400",
                                      }}
                                      margin={{
                                        left: "10px",
                                        right: "20px",
                                      }}
                                      onClick={() =>
                                        handleDelete(
                                          selected === "pc"
                                            ? [data.pbannerId]
                                            : [data.mbannerId]
                                        )
                                      }
                                    >
                                      삭제
                                    </AdminButton>
                                  </Box>
                                </TableData>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </tbody>
                    )}
                  </Droppable>
                </Table>
              </DragDropContext>
            </Box>
            <Box theme="adminRight" margin={{ top: "-25px", bottom: "25px" }}>
              {!edit && (
                <AdminButton
                  category="button"
                  theme={["subButton", "whiteColor"]}
                  onClick={handleEdit}
                >
                  순서변경
                </AdminButton>
              )}

              {edit && (
                <Box
                  display={{
                    display: "flex",
                  }}
                >
                  <AdminButton
                    category="button"
                    theme={["mainButton", "grayColor"]}
                    margin={{
                      right: "10px",
                    }}
                    onClick={handleEditCancel}
                  >
                    취소
                  </AdminButton>
                  <AdminButton
                    category="button"
                    theme={["mainButton"]}
                    onClick={handleSaveOrder}
                  >
                    저장
                  </AdminButton>
                </Box>
              )}
            </Box>
          </Box>
        )
      ) : (
        <Box
          size={{
            width: "100%",
            height: "auto",
          }}
          margin={{
            top: "25px",
          }}
          other={{
            overflowX: "hidden",
          }}
        >
          {selected === "pc" ? (
            <Text theme={"adminTitle"}>메인 배너(PC) 등록</Text>
          ) : (
            <Text theme={"adminTitle"}>메인 배너(m) 등록</Text>
          )}

          <RowTable>
            <Box
              theme="adminListBox"
              padding={{
                left: "21px",
              }}
            >
              <Text
                typo={{
                  size: "12px",
                  weight: "700",
                  color: "pink03",
                }}
              >
                * 은 필수 입력 항목입니다.
              </Text>
            </Box>

            <Box
              display={{
                width: "100%",
                height: "52px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="48px">
                제목&nbsp;
                <Text
                  typo={{
                    size: "13px",
                    weight: "700",
                    color: "pink03",
                  }}
                >
                  *
                </Text>
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                }}
              >
                <Input
                  theme={"adminInput"}
                  size={{
                    width: "395px",
                    height: "32px",
                  }}
                  margin={{
                    top: "8px",
                  }}
                  name="title"
                  value={isEditing ? editingBanner.title : title}
                  onChange={(e) =>
                    isEditing ? handleFieldChange(e) : setTitle(e.target.value)
                  }
                />
              </RowTableData>
            </Box>
            <Box
              display={{
                width: "100%",
                height: "304px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="304px">
                <Box
                  display={{
                    display: "flex",
                  }}
                  size={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  이미지 등록&nbsp;
                  <Text
                    typo={{
                      size: "13px",
                      weight: "700",
                      color: "pink03",
                    }}
                  >
                    *
                  </Text>
                </Box>
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                  height: "304px",
                }}
              >
                <ImageInput
                  text1={
                    selected === "pc"
                      ? "- 권장 이미지 : 1200px*440px / 10mb 이하 / jpg , png 파일"
                      : "- 권장 이미지 : 750px*750px / 10mb 이하 / jpg , png 파일"
                  }
                  text2="- 1장의 이미지만 등록 가능합니다."
                  image={
                    isEditing
                      ? convertImage(editingBanner.bannerImage)
                      : convertImage(bannerImage)
                  }
                  onChange={handleProfile}
                />
              </RowTableData>
            </Box>
            <Box
              display={{
                width: "100%",
                height: "249px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="105px">
                <Box
                  display={{
                    display: "flex",
                  }}
                  size={{
                    width: "100%",
                    height: "100%",
                  }}
                >
                  링크&nbsp;
                  <Text
                    typo={{
                      size: "13px",
                      weight: "700",
                      color: "pink03",
                    }}
                  >
                    *
                  </Text>
                </Box>
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                  height: "105px",
                }}
                padding={{
                  top: "8px",
                  left: "10px",
                  bottom: "10px",
                }}
              >
                <Box>
                  <Box
                    display={{
                      display: "flex",
                    }}
                  >
                    <AdminRadio
                      id="used"
                      htmlFor="used"
                      label="링크 사용"
                      name="linkUse"
                      value={linkUse}
                      checked={linkUse === 1}
                      onClick={() => handleLinkUseChange(1)}
                    />

                    <AdminRadio
                      id="unused"
                      name="linkUse"
                      value={linkUse}
                      checked={linkUse === 0}
                      htmlFor="unused"
                      label="사용 안함"
                      onClick={() => handleLinkUseChange(0)}
                    />
                  </Box>
                  <Input
                    theme={"adminInput"}
                    size={{
                      width: "395px",
                      height: "32px",
                    }}
                    margin={{
                      top: "8px",
                    }}
                    typo={{
                      size: "12px",
                    }}
                    background={{
                      color: linkUse === 1 ? "white" : "#F3F3F3",
                    }}
                    placeholder={
                      linkUse === 1
                        ? "배너 클릭 시 이동할 링크를 입력해주세요."
                        : "링크 미설정 상태입니다."
                    }
                    readOnly={linkUse === 0}
                    name="bannerLink"
                    value={isEditing ? editingBanner?.bannerLink : bannerLink}
                    onChange={(e) =>
                      isEditing
                        ? handleFieldChange(e)
                        : setBannerLink(e.target.value)
                    }
                  />
                  <Box
                    size={{
                      width: "90px",
                      height: "auto",
                    }}
                    display={{
                      display: "flex",
                      align: "center",
                      justify: "flex-start",
                    }}
                    margin={{
                      top: "9px",
                    }}
                  >
                    <AdminCheckbox
                      name="newWindows"
                      value={isEditing ? editingBanner.newWindows : newWindow}
                      checked={
                        isEditing
                          ? editingBanner.newWindows === 1
                          : newWindow === 1
                      }
                      onClick={handleNewWindowsChange}
                      readOnly={linkUse === 0}
                    >
                      <Text
                        typo={{
                          size: "12px",
                          weight: "400",
                          color: "black02",
                        }}
                        margin={{
                          left: "3px",
                        }}
                      >
                        새창에서 열기
                      </Text>
                    </AdminCheckbox>
                  </Box>
                </Box>
              </RowTableData>
            </Box>
          </RowTable>
          <Box
            size={{
              width: "100%",
              height: "auto",
            }}
            display={{
              display: "flex",
            }}
            margin={{
              top: "24px",
              left: "402px",
            }}
          >
            <AdminButton
              category="button"
              theme={["mainButton", "grayColor"]}
              onClick={handleBannerEdit}
            >
              취소
            </AdminButton>
            <AdminButton
              category="button"
              theme={isDone ? ["mainButton"] : ["grayColor"]}
              margin={{
                left: "10px",
              }}
              size={{
                width: "104px",
                height: "40px",
              }}
              padding={{
                top: "12px",
                bottom: "12px",
              }}
              onClick={handleBannerSave}
            >
              저장
            </AdminButton>
          </Box>
        </Box>
      )}
    </>
  );
};

export default MainBannerManage;
