import React, { useState } from "react";
import Image from "component/atoms/image";
import Box from "component/atoms/box";
import Input from "component/atoms/input";
import Text from "component/atoms/text";
import Button from "component/atoms/button";
import useAdminLogin from "hook/api/admin/auth/login";
import AdminAlertModal from "component/organisms/admin/modal/alertModal";
import { useAdminFind } from "hook/api/admin/auth/find";
import useUrl from "hook/util/useUrl";
import { useEffect } from "react";
import { useAuth } from "context";

const AdminLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { changeLink } = useUrl();

  const [recoveryEmail, setRecoveryEmail] = useState("");
  // input modal
  const [visible, setVisible] = useState(false);
  // confirm modal
  const [confirmVisible, setConfirmVisible] = useState(false);

  const handleFindPWClick = () => {
    setVisible(true);
  };

  const handleSuccess = () => {
    setConfirmVisible(true);
  };

  const handleError = () => {
    alert("이메일을 다시 확인해주세요.");
  };

  const { mutate: findPW } = useAdminFind(handleSuccess, handleError);

  const { mutate: login } = useAdminLogin(
    email,
    password,
    handleSuccess,
    handleError
  );

  const { authData } = useAuth();

  const handlePWRecovery = () => {
    findPW(recoveryEmail);
    setVisible(false);
  };

  const handleLogin = () => {
    login({ email, password });
  };

  const isActive = email && password;

  return (
    <Box
      size={{
        width: "100%",
        height: "100%",
      }}
      position={{
        top: "0",
        left: "0",
        type: "absolute",
      }}
      display={{
        display: "flex",
        justify: "center",
        align: "center",
        direction: "column",
      }}
      other={{
        overflow: "hidden",
      }}
    >
      <AdminAlertModal
        title={"비밀번호 찾기"}
        text={
          <>
            <Text>등록하신 이메일로 임시 비밀번호가 발송되었습니다.</Text>
            <Text
              margin={{
                bottom: "14px",
              }}
            >
              이메일을 확인해 주세요.
            </Text>
          </>
        }
        btn_text="확인"
        visible={confirmVisible}
        toggle={() => {
          setConfirmVisible(false);
        }}
        onClick={() => {
          setConfirmVisible(false);
        }}
      />

      <AdminAlertModal
        title={"비밀번호 찾기"}
        text="임시 비밀번호를 발급받기 위해 계정 정보를 입력해주세요."
        btn_text="임시 비밀번호 발급"
        visible={visible}
        login={true}
        toggle={() => {
          setVisible(false);
        }}
        onClick={handlePWRecovery}
      >
        <Text
          typo={{
            size: "12px",
            weight: "700",
            color: "black02",
          }}
        >
          E-mail
        </Text>
        <Input
          theme="adminInput"
          type={"email"}
          name={"email"}
          size={{
            width: "362px",
            height: "32px",
          }}
          margin={{
            top: "5px",
            bottom: "20px",
          }}
          onChange={(e) => setRecoveryEmail(e.target.value)}
        />
      </AdminAlertModal>

      <Image src={"/asset/img/admin/login/logo.svg"} />
      <Text
        size={{
          width: "580px",
        }}
        typo={{
          size: "18px",
          weight: "700",
          color: "black01",
        }}
        margin={{
          top: "36px",
          bottom: "10px",
        }}
      >
        이메일
      </Text>
      <Input
        theme={"adminLogin"}
        size={{
          width: "580px",
          height: "50px",
        }}
        onChange={(e) => setEmail(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter" && isActive) {
            handleLogin();
          }
        }}
      />
      <Text
        size={{
          width: "580px",
        }}
        typo={{
          size: "18px",
          weight: "700",
          color: "black01",
        }}
        margin={{
          top: "20px",
          bottom: "10px",
        }}
      >
        비밀번호
      </Text>
      <Input
        theme={"adminLogin"}
        type={"password"}
        size={{
          width: "580px",
          height: "50px",
        }}
        margin={{
          bottom: "40px",
        }}
        onChange={(e) => setPassword(e.target.value)}
        onKeyPress={(e) => {
          if (e.key === "Enter" && isActive) {
            handleLogin();
          }
        }}
      />
      <Button
        size={{
          width: "580px",
          height: "50px",
        }}
        background={{
          color: isActive ? "pink02" : "gray07",
        }}
        border={{
          all: "1px",
          style: "solid",
          color: isActive ? "black01" : "gray02",
        }}
        typo={{
          size: "18px",
          weight: "700",
          color: isActive ? "black01" : "gray02",
        }}
        onClick={handleLogin}
      >
        로그인
      </Button>
      <Button
        typo={{
          size: "12px",
          weight: "400",
          color: "gray08",
        }}
        margin={{
          top: "30px",
        }}
        onClick={handleFindPWClick}
      >
        비밀번호 찾기
      </Button>
    </Box>
  );
};

export default AdminLogin;
