import Box from "component/atoms/box";
import Text from "component/atoms/text";

const LineTitle = (props) => {
  const {
    direction,
    typo,
    mobileTypo,
    margin,
    display,
    longMobile,
    isNeedLine = false,
    //
    title,
    children,
  } = props;

  const isAdminPage = window.location.pathname.includes("/admin");
  const borderStyle =
    isAdminPage && !isNeedLine
      ? {}
      : { bottom: "1px", style: "solid", color: "black" };

  return (
    <Box
      display={{
        display: "flex",
        direction,
        wrap: "wrap",
        justify: "space-between",
        align: "center",
        ...display,
      }}
      size={{
        width: "100%",
      }}
      border={borderStyle}
      margin={{
        bottom: "30px",
        ...margin,
      }}
      padding={{
        bottom: "12px",
      }}
      position={{
        type: "relative",
      }}
      longMobile={longMobile}
    >
      <Text
        display={{
          display: "flex",
          align: "center",
        }}
        typo={{
          size: "24px",
          weight: "700",
          lineHeight: "normal",
          ...typo,
        }}
        longMobile={{
          typo: {
            size: "20px",
            ...mobileTypo,
          },
        }}
      >
        {title}
      </Text>
      {children}
    </Box>
  );
};

export default LineTitle;
