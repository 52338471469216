import Box from "component/atoms/box";
import Input from "component/atoms/input";
import RowTable from "component/atoms/rowSideTable";
import RowTableData from "component/atoms/rowTableData";
import RowTableHead from "component/atoms/rowTableHead";
import Text from "component/atoms/text";
import React, { useState } from "react";
import AdminDropdown from "component/molecules/admin/adminDropdown";
import AdminButton from "component/molecules/admin/button/adminButton";
import Image from "component/atoms/image";
import TableData from "component/atoms/tableData";
import TableHead from "component/atoms/tableHead";
import Table from "component/atoms/table";
import AdminCheckbox from "component/molecules/adminCheckbox";
import CalendarInput from "component/molecules/common/calendarInput";
import useToggle from "hook/util/useToggle";
import {
  useDeletePortfolio,
  useUserPortfolio,
} from "hook/api/admin/userDetail";
import dayjs from "dayjs";
import AdminPagination from "component/molecules/admin/pagination";
import { today } from "api/admin/dashboard";
import { useGetToday } from "hook/api/admin/dashboard";
import { useEffect } from "react";
import AdminAlertModal from "../../modal/alertModal";
import Link from "component/atoms/link";
import { getCurrentDateTime } from "utils/getCurrentDateTime";

const UserPortfolio = () => {
  const url = new URL(window.location.href);
  const pathSegments = url.pathname.split("/");
  const memberId = pathSegments[pathSegments.length - 1];

  const category = ["전체", "곡명", "링크"];
  const listCategory = [
    { label: "10개씩 보기", value: 10 },
    { label: "20개씩 보기", value: 20 },
    { label: "30개씩 보기", value: 30 },
    { label: "50개씩 보기", value: 50 },
  ];

  const labels = listCategory.map((category) => category.label);

  // 검색 분류의 초기값을 '전체'로 설정
  const [selected, setSelected] = useState(category[0]);
  // 검색어
  const [inputValue, setInputValue] = useState("");

  // 검색 분류 변경 시 상태 변경
  const changeSelected = (category) => {
    setSelected(category);
  };

  // 인풋창의 입력값을 카테고리에 따라 관리
  const handleInputChange = (e) => {
    e.preventDefault();
    setInputValue(e.target.value);
  };

  // 10개씩 보기 선택하면 value = 10 을 pageSize로 설정
  const changeSelectedList = (selectedLabel) => {
    const category = listCategory.find((cat) => cat.label === selectedLabel);
    if (category) {
      setPageSize(category.value);
      setCurrentPage(1);
    }
  };

  const { data: today } = useGetToday();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [musicName, setMusicName] = useState("");
  const [musicLink, setMusicLink] = useState("");
  const [keyword, setKeyword] = useState("");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [search, setSearch] = useState({
    startDate: "",
    endDate: "",
    musicName: "",
    musicLink: "",
    keyword: "",
  });

  const {
    data: data,
    totalItems,
    totalPages,
  } = useUserPortfolio(
    memberId,
    search.musicName,
    search.musicLink,
    search.startDate,
    search.endDate,
    search.keyword,
    pageSize,
    currentPage
  );

  const currentDateTime = getCurrentDateTime();

  const handleSearch = () => {
    let newStartDate = startDate;
    let newEndDate =
      startDate !== "" && endDate === ""
        ? dayjs(currentDateTime).format("YYYY-MM-DD")
        : endDate;

    if (startDate === "" && dayjs(newEndDate).isAfter(dayjs(currentDateTime))) {
      newStartDate = dayjs(currentDateTime).format("YYYY-MM-DD");
    }
    if (startDate === "" && dayjs(currentDateTime).isAfter(dayjs(newEndDate))) {
      alert("시작일이 종료일보다 늦습니다. 검색 기간을 다시 설정해주세요.");
      return;
    }
    // 로컬 변수를 사용한 날짜 비교
    if (dayjs(newStartDate).isAfter(dayjs(newEndDate))) {
      alert("시작일이 종료일보다 늦습니다. 검색 기간을 다시 설정해주세요.");
      return;
    }

    // 상태 업데이트
    setSearch({
      startDate: newStartDate,
      endDate: newEndDate,
      keyword: inputValue,
    });

    // 로컬 변수를 사용한 날짜 비교
    if (dayjs(newStartDate).isAfter(dayjs(newEndDate))) {
      alert("시작일이 종료일보다 늦습니다. 검색 기간을 다시 설정해주세요.");
      return;
    }
    setCurrentPage(1);
  };

  const { toggle, handleToggle } = useToggle();

  // 알림 모달창 상태 관리
  const [visible, setVisible] = useState(false);
  // 인풋 모달의 취소/저장 버튼 클릭 시
  const handleCancle = () => {
    handleToggle();
  };

  const handleSave = () => {
    setVisible(true);
    handleToggle();
  };

  // 이야기 제목 클릭 시 이야기 관리 상세 페이지로 이동
  const goToStoryDetail = (storyId) => {
    window.open("/admin/manage/story/detail/" + storyId, "_blank");
  };

  // 체크박스를 선택하여 회원 차단
  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    setSelectedUser({});
  }, [currentPage]);

  // 전체 선택 또는 해제 처리
  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setSelectedUser((prevSelectedUser) => {
      const newSelectedUser = {};
      if (checked) {
        data.forEach((data) => {
          newSelectedUser[data.portfolioId] = true;
        });
      }
      return newSelectedUser;
    });
  };

  // 개별 선택 처리
  const handleSelect = (e, portfolioId) => {
    const checked = e.target.checked;
    setSelectedUser((prevSelectedUser) => {
      const newSelectedUser = { ...prevSelectedUser };
      newSelectedUser[portfolioId] = checked;
      return newSelectedUser;
    });
  };

  // 차단 성공, 실패 모달
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSuccess = () => {
    setSuccess(true);
  };

  const handleError = () => {
    setError(true);
  };

  const selectedUserIds = Object.entries(selectedUser)
    .filter(([_, checked]) => checked)
    .map(([portfolioId]) => portfolioId);

  const { mutate: deletePortfolio } = useDeletePortfolio(
    handleSuccess,
    handleError
  );

  const handleDelete = () => {
    if (selectedUserIds.length === 0) {
      alert("삭제할 포트폴리오를 선택해주세요.");
      return;
    } else {
      window.confirm("선택한 포트폴리오를 삭제하시겠습니까?") &&
        deletePortfolio({ portfolioIds: selectedUserIds });
      setSelectedUser({});
    }
  };

  return (
    <>
      <Box
        size={{
          width: "100%",
          height: "auto",
        }}
        margin={{
          top: "25px",
        }}
        other={{
          overflowX: "hidden",
        }}
      >
        <AdminAlertModal
          title={"회원 관리"}
          text={
            success
              ? "포트폴리오 삭제에 성공하였습니다."
              : "포트폴리오 삭제에 실패하였습니다."
          }
          btn_text="확인"
          visible={success || error}
          toggle={() => {
            setSuccess(false) || setError(false);
          }}
          onClick={() => {
            setSuccess(false) || setError(false);
          }}
        />
        <Text theme={"adminTitle"}>포트폴리오 조회</Text>
        <RowTable>
          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="160px" height="48px" isLastChild>
              등록 기간
            </RowTableHead>
            <RowTableData
              isLastChild
              size={{
                width: "calc(100% - 160px)",
                height: "48px",
              }}
            >
              <Box
                size={{
                  width: "100%",
                  height: "100%",
                }}
                display={{
                  display: "flex",
                  justify: "flex-start",
                  align: "center",
                }}
              >
                <CalendarInput
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                />
                <Text
                  margin={{
                    left: "10px",
                    right: "10px",
                  }}
                >
                  ~
                </Text>
                <CalendarInput
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                />
              </Box>
            </RowTableData>
          </Box>

          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="160px" height="48px">
              검색 분류
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 160px)",
              }}
              padding={{
                top: "8px",
                left: "8px",
              }}
            >
              <AdminDropdown
                size={{
                  width: "104px",
                  height: "32px",
                }}
                menuSize={{
                  width: "104px",
                  height: "auto",
                }}
                category={category}
                onSelect={changeSelected}
              />
              <Input
                theme={"adminInput"}
                size={{
                  width: "500px",
                  height: "32px",
                }}
                value={inputValue}
                onChange={handleInputChange}
              />
            </RowTableData>
          </Box>
        </RowTable>
        <AdminButton
          category="button"
          theme="mainButton"
          margin={{
            top: "24px",
            left: "456px",
          }}
          onClick={handleSearch}
        >
          검색
        </AdminButton>

        {/* 회원 목록 */}
        <Box
          size={{
            width: "100%",
            height: "auto",
          }}
          margin={{
            top: "40px",
          }}
        >
          <Text theme={"adminTitle"}>포트폴리오 관리</Text>
          <Box
            size={{
              width: "1024px",
              height: "auto",
            }}
            border={{
              top: "1px",
              left: "1px",
              right: "1px",
              type: "solid",
              color: "gray10",
              radius: "3px 3px 0 0",
            }}
          >
            {/* 목록 상단 인원 수, 차단 버튼 */}
            <Box
              theme="adminListBox"
              size={{
                height: "44px",
              }}
              padding={{
                left: "16px",
              }}
            >
              <Text
                as="span"
                typo={{
                  size: "13px",
                  weight: "500",
                  color: "black02",
                }}
              >
                총{" "}
                <Text
                  as="span"
                  typo={{
                    color: "blue01",
                  }}
                >
                  {totalItems}
                </Text>{" "}
                곡
              </Text>
              <AdminDropdown
                margin={{
                  right: "10px",
                }}
                size={{
                  width: "110px",
                  height: "28px",
                }}
                menuSize={{
                  width: "110px",
                  height: "auto",
                }}
                category={labels}
                onSelect={changeSelectedList}
              />
            </Box>
            <Box
              theme="adminListBox"
              size={{
                height: "44px",
              }}
              padding={{
                left: "8px",
              }}
              border={{
                top: "1px",
                style: "solid",
                color: "gray10",
              }}
            >
              <AdminButton
                category="button"
                theme="listOptionButton"
                size={{
                  width: "53px",
                  height: "28px",
                }}
                padding={{
                  left: "6px",
                  right: "6px",
                }}
                onClick={handleDelete}
              >
                <Image
                  src="/asset/img/admin/button/red_X.svg"
                  margin={{
                    right: "2px",
                  }}
                />
                삭제
              </AdminButton>
            </Box>
          </Box>
          {/* 회원 목록 리스트 */}
          <Table isAttached={true} margin={{ bottom: "40px" }}>
            <thead>
              <tr>
                <TableHead width="42px" padding="8px 0">
                  <AdminCheckbox
                    margin={{
                      left: "13px",
                    }}
                    value="all"
                    readOnly={data?.length === 0}
                    checked={
                      Object.keys(selectedUser).length === data?.length &&
                      Object.values(selectedUser).every((checked) => checked)
                    }
                    onClick={handleSelectAll}
                  />
                </TableHead>

                <TableHead width="92px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    NO.
                  </Text>
                </TableHead>

                <TableHead width="364px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    곡명
                  </Text>
                </TableHead>

                <TableHead width="364px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    링크
                  </Text>
                </TableHead>

                <TableHead
                  width="calc(1024px - 42px - 92px - 364px - 364px)"
                  isLastChild
                >
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    등록일
                  </Text>
                </TableHead>
              </tr>
            </thead>
            <tbody>
              {data?.map((data, index) => (
                <tr key={data.portfolioId}>
                  <TableData size={{ height: "46px" }}>
                    <AdminCheckbox
                      margin={{
                        left: "13px",
                      }}
                      checked={selectedUser[data.portfolioId]}
                      onClick={(e) => handleSelect(e, data.portfolioId)}
                      value={data.portfolioId}
                    />
                  </TableData>
                  <TableData>
                    {totalItems - (currentPage - 1) * pageSize - index}
                  </TableData>
                  <TableData>
                    <Text
                      size={{
                        width: "364px",
                      }}
                      typo={{
                        align: "left",
                        lineClamp: 2,
                        wordBreak: "break-all",
                      }}
                      padding={{
                        left: "14px",
                        right: "14px",
                      }}
                    >
                      {data.musicName}
                    </Text>
                  </TableData>
                  <TableData
                    padding={{
                      all: "7px",
                    }}
                  >
                    <Link
                      link={
                        data.musicLink.startsWith("http")
                          ? data.musicLink
                          : `http://${data.musicLink}`
                      }
                      target="_blank"
                    >
                      <Text
                        size={{
                          width: "364px",
                        }}
                        typo={{
                          lineClamp: 1,
                          textDecoration: "underline",
                          wordBreak: "break-all",
                        }}
                      >
                        {data.musicLink}
                      </Text>
                    </Link>
                  </TableData>
                  <TableData isLastChild>
                    {dayjs(data.createdAt).format("YYYY-MM-DD HH:mm")}
                  </TableData>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
        {totalItems > 0 && (
          <AdminPagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </Box>
    </>
  );
};

export default UserPortfolio;
