import { footerBanner } from "api/banner";
import { useQuery } from "react-query";

const useMiddleBannerList = ({ device }) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["middleBanner", device],
    () => footerBanner(device)
  );

  return {
    data: data?.data,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useMiddleBannerList;
