import Box from "component/atoms/box";
import Input from "component/atoms/input";
import RowTable from "component/atoms/rowSideTable";
import RowTableData from "component/atoms/rowTableData";
import RowTableHead from "component/atoms/rowTableHead";
import Text from "component/atoms/text";
import React, { useState, useEffect } from "react";
import AdminDropdown from "component/molecules/admin/adminDropdown";
import AdminButton from "component/molecules/admin/button/adminButton";
import Image from "component/atoms/image";
import TableData from "component/atoms/tableData";
import TableHead from "component/atoms/tableHead";
import Table from "component/atoms/table";
import AdminCheckbox from "component/molecules/adminCheckbox";
import CalendarInput from "component/molecules/common/calendarInput";
import useToggle from "hook/util/useToggle";
import {
  useCancleCheering,
  useGiveCoin,
  useRevokeCoin,
  useUserCheering,
  useUserCoin,
} from "hook/api/admin/userDetail";
import dayjs from "dayjs";
import AdminPagination from "component/molecules/admin/pagination";
import AdminAlertModal from "../../modal/alertModal";
import { getCurrentDateTime } from "utils/getCurrentDateTime";
import AdminRadio from "component/molecules/admin/adminRadio";
import CoinModal from "../../modal/coinModal";
import { set } from "react-hook-form";

const UserCheeringDetail = () => {
  const url = new URL(window.location.href);
  const pathSegments = url.pathname.split("/");
  const memberId = pathSegments[pathSegments.length - 1];

  const category = ["전체", "창안자 이름", "제목"];
  const listCategory = [
    { label: "10개씩 보기", value: 10 },
    { label: "20개씩 보기", value: 20 },
    { label: "30개씩 보기", value: 30 },
    { label: "50개씩 보기", value: 50 },
  ];

  const labels = listCategory.map((category) => category.label);

  // 검색 분류의 초기값을 '전체'로 설정
  const [selected, setSelected] = useState(category[0]);
  // 검색어
  const [inputValue, setInputValue] = useState("");

  // 검색 분류 변경 시 상태 변경
  const changeSelected = (category) => {
    setSelected(category);
    setInputValue("");
  };

  // 인풋창의 입력값을 카테고리에 따라 관리
  const handleInputChange = (e) => {
    e.preventDefault();
    setInputValue(e.target.value);
  };

  // 10개씩 보기 선택하면 value = 10 을 pageSize로 설정
  const changeSelectedList = (selectedLabel) => {
    const category = listCategory.find((cat) => cat.label === selectedLabel);
    if (category) {
      setPageSize(category.value);
      setCurrentPage(1);
    }
  };

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [keyword, setKeyword] = useState("");
  const [coinCategory, setCoinCategory] = useState("전체");
  const [coinStatus, setCoinStatus] = useState("0");
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const [search, setSearch] = useState({
    startDate: "",
    endDate: "",
    keyword: "",
    coinCategory: "전체",
    coinStatus: "0",
  });

  // 보유 코인 내역
  const { coinSum: coin } = useUserCoin(memberId);

  // 응원 조회
  const {
    data: data,
    totalItems: total,
    totalPages: totalPages,
  } = useUserCheering(
    memberId,
    search.keyword,
    search.startDate,
    search.endDate,
    search.coinCategory,
    search.coinStatus,
    currentPage,
    pageSize
  );

  const currentDateTime = getCurrentDateTime();

  const handleSearch = () => {
    // setSearch를 호출한 직후 startDate, endDate를 비교하면 상태 변경이 아직 이루어지지 않아서 로컬 변수를 사용해서 비교
    let newStartDate = startDate;
    let newEndDate =
      startDate !== "" && endDate === ""
        ? dayjs(currentDateTime).format("YYYY-MM-DD")
        : endDate;

    if (startDate === "" && dayjs(newEndDate).isAfter(dayjs(currentDateTime))) {
      newStartDate = dayjs(currentDateTime).format("YYYY-MM-DD");
    }
    if (startDate === "" && dayjs(currentDateTime).isAfter(dayjs(newEndDate))) {
      alert("시작일이 종료일보다 늦습니다. 검색 기간을 다시 설정해주세요.");
      return;
    }
    // 로컬 변수를 사용한 날짜 비교
    if (dayjs(newStartDate).isAfter(dayjs(newEndDate))) {
      alert("시작일이 종료일보다 늦습니다. 검색 기간을 다시 설정해주세요.");
      return;
    }
    // 상태 업데이트
    setSearch({
      startDate: newStartDate,
      endDate: newEndDate,
      keyword: inputValue,
      coinCategory: coinCategory,
      coinStatus: coinStatus,
    });

    setCurrentPage(1);
  };

  const { toggle, handleToggle } = useToggle();

  // 코인 지급/회수 모달창 상태 관리
  const { toggle: toggleInput, handleToggle: handleToggleInput } = useToggle();
  const [inputCategory, setInputCategory] = useState("");
  const [coinId, setCoinId] = useState(null);
  const [amountData, setAmountData] = useState("");
  const [reasonData, setReasonData] = useState("");
  const handleToggleInputCategory = (category) => {
    setInputCategory(category);

    handleToggleInput();
  };

  const [inputData, setInputData] = useState("");
  const [coinAmount, setCoinAmount] = useState("");
  const handleInput = (e) => {
    setCoinAmount(e.target.value);
  };

  const handleReasonInput = (e) => {
    setInputData(e.target.value);
  };

  const handleSuccessGive = () => {
    alert("코인 지급에 성공하였습니다.");
  };

  const handleErrorGive = () => {
    alert("코인 지급에 실패하였습니다.");
  };

  const handleSuccessRevoke = () => {
    alert("코인 회수에 성공하였습니다.");
  };

  const handleErrorRevoke = () => {
    alert("코인 회수에 실패하였습니다.");
  };

  const { mutate: giveCoin } = useGiveCoin(handleSuccessGive, handleErrorGive);
  const handleGiveCoin = () => {
    if (coinAmount === "") {
      alert("지급할 코인을 입력해주세요.");
      return;
    }
    if (inputData === "") {
      alert("지급 사유를 입력해주세요.");
      return;
    }
    window.confirm("해당 회원에게 코인을 지급하시겠습니까?") &&
      giveCoin({
        memberId: memberId,
        coinsToAward: coinAmount,
        coinReason: inputData,
      });

    handleToggleInput();
    setInputData("");
    setCoinAmount("");
  };

  const { mutate: revokeCoin } = useRevokeCoin(
    handleSuccessRevoke,
    handleErrorRevoke
  );
  const handleRevokeCoin = () => {
    if (coinAmount === "") {
      alert("회수할 코인을 입력해주세요.");
      return;
    }
    if (inputData === "") {
      alert("회수 사유를 입력해주세요.");
      return;
    }
    window.confirm("해당 회원의 코인을 회수하시겠습니까?") &&
      revokeCoin({
        memberId: memberId,
        coinsToAward: coinAmount,
        coinReason: inputData,
      });

    handleToggleInput();
    setInputData("");
    setCoinAmount("");
  };

  const handleReasonClick = (
    category,
    storyId,
    coinsToAward,
    coinReason,
    coinId
  ) => {
    if (category === "지급" || category === "회수") {
      setCoinId(coinId);
      setAmountData(coinsToAward);
      setReasonData(coinReason);
      handleToggleInputCategory(category);
    } else if (category === "적립") {
      return;
    } else {
      goToStoryDetail(storyId);
    }
  };

  const handleCloseModal = () => {
    handleToggleInput();
    setCoinAmount("");
    setInputData("");
    setCoinId(null);
    setAmountData("");
    setReasonData("");
  };

  // 알림 모달창 상태 관리
  const [visible, setVisible] = useState(false);
  // 인풋 모달의 취소/저장 버튼 클릭 시
  const handleCancle = () => {
    handleToggle();
  };

  const handleSave = () => {
    setVisible(true);
    handleToggle();
  };

  // 이야기 제목 클릭 시 이야기 관리 상세 페이지로 이동
  const goToStoryDetail = (storyId) => {
    window.open(
      `/admin/manage/story/detail/${storyId}?menu=storyDetail&category=storyEdit`,
      "_blank"
    );
  };

  // 체크박스를 선택하여 회원 차단
  const [selectedUser, setSelectedUser] = useState({});

  // 전체 데이터의 processed가 true일 때 전체 체크박스도 readOnly
  const isAllReadOnly = data?.every((item) => item.processed !== false);

  useEffect(() => {
    setSelectedUser({});
  }, [currentPage]);

  // 전체 선택 또는 해제 처리
  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setSelectedUser((prevSelectedUser) => {
      const newSelectedUser = {};
      if (checked) {
        data.forEach((item) => {
          newSelectedUser[item.coinId] = item.processed === false;
        });
      }
      return newSelectedUser;
    });
  };

  // 개별 선택 처리
  const handleSelect = (e, coinId) => {
    const checked = e.target.checked;
    setSelectedUser((prevSelectedUser) => {
      const newSelectedUser = { ...prevSelectedUser };
      // 데이터 배열에서 현재 coinId와 일치하는 항목 찾기
      const currentItem = data.find((item) => item.coinId === coinId);

      // currentItem이 존재하고 processed가 false인 경우에만 상태 업데이트
      if (currentItem && currentItem.processed === false) {
        newSelectedUser[coinId] = checked;
      }

      return newSelectedUser;
    });
  };

  // 차단 성공, 실패 모달
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSuccess = () => {
    setSuccess(true);
  };

  const handleError = () => {
    setError(true);
  };

  const selectedUserIds = Object.entries(selectedUser)
    .filter(([_, checked]) => checked)
    .map(([coinId]) => coinId);

  const { mutate: cancleCheering } = useCancleCheering(
    handleSuccess,
    handleError
  );

  const handleCancleCheering = () => {
    if (selectedUserIds.length === 0) {
      alert("취소할 응원을 선택해주세요.");
      return;
    } else {
      window.confirm("선택한 응원을 취소하시겠습니까?") &&
        cancleCheering({ coinIds: selectedUserIds });
      setSelectedUser({});
    }
  };

  return (
    <>
      <CoinModal
        toggle={toggleInput}
        handleToggle={handleCloseModal}
        cancle={handleCloseModal}
        save={inputCategory === "지급" ? handleGiveCoin : handleRevokeCoin}
        coinReason={reasonData ? reasonData : inputData}
        coinValue={amountData ? amountData : coinAmount}
        onChange={handleInput}
        onChangeInput={handleReasonInput}
        category={inputCategory}
        value={inputData}
        data={coinId}
      />
      <Box
        size={{
          width: "100%",
          height: "auto",
        }}
        margin={{
          top: "25px",
        }}
        other={{
          overflowX: "hidden",
        }}
      >
        <AdminAlertModal
          title={"회원 관리"}
          text={
            success
              ? "응원 취소에 성공하였습니다."
              : "응원 취소에 실패하였습니다."
          }
          btn_text="확인"
          visible={success || error}
          toggle={() => {
            setSuccess(false) || setError(false);
          }}
          onClick={() => {
            setSuccess(false) || setError(false);
          }}
        />
        <Text theme={"adminTitle"}>코인내역</Text>
        <RowTable
          margin={{
            bottom: "55px",
          }}
        >
          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="119px" height="48px" isLastChild={true}>
              보유 코인
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 119px)",
                height: "48px",
              }}
              padding={{
                all: "0px",
              }}
              nowrap
              isLastChild={true}
            >
              <Text margin={{ top: "15px", left: "18px" }}>{coin} Coin</Text>
            </RowTableData>
            <Box
              display={{
                display: "flex",
              }}
              margin={{
                top: "10px",
              }}
            >
              <AdminButton
                category="button"
                theme={["listButton", "whiteColor"]}
                typo={{
                  weight: "700",
                  color: "black02",
                }}
                onClick={handleToggleInputCategory.bind(this, "지급")}
              >
                코인 지급
              </AdminButton>
              <AdminButton
                category="button"
                theme={["listButton", "whiteColor"]}
                typo={{
                  weight: "700",
                  color: "black02",
                }}
                margin={{
                  left: "6px",
                  right: "9px",
                }}
                onClick={handleToggleInputCategory.bind(this, "회수")}
              >
                코인 회수
              </AdminButton>
            </Box>
          </Box>
        </RowTable>

        <Text theme={"adminTitle"}>코인 조회</Text>
        <RowTable>
          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="119px" height="48px" isLastChild>
              기간
            </RowTableHead>
            <RowTableData
              isLastChild
              size={{
                width: "calc(100% - 119px)",
                height: "48px",
              }}
            >
              <Box
                size={{
                  width: "100%",
                  height: "100%",
                }}
                display={{
                  display: "flex",
                  justify: "flex-start",
                  align: "center",
                }}
              >
                <CalendarInput
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                />
                <Text
                  margin={{
                    left: "10px",
                    right: "10px",
                  }}
                >
                  ~
                </Text>
                <CalendarInput
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                />
              </Box>
            </RowTableData>
          </Box>

          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="119px" height="48px">
              분류
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 119px)",
                height: "48px",
              }}
            >
              <Box
                size={{
                  width: "100%",
                  height: "100%",
                }}
                display={{
                  display: "flex",
                  justify: "flex-start",
                  align: "center",
                }}
              >
                <AdminRadio
                  id="category-all"
                  name="category"
                  value="category-all"
                  htmlFor="category-all"
                  label="전체"
                  checked={coinCategory === "전체"}
                  onClick={() => setCoinCategory("전체")}
                />
                <AdminRadio
                  id="used"
                  name="category"
                  value="used"
                  htmlFor="used"
                  label="사용"
                  checked={coinCategory === "사용"}
                  onClick={() => setCoinCategory("사용")}
                />
                <AdminRadio
                  id="give"
                  name="category"
                  value="give"
                  htmlFor="give"
                  label="지급"
                  checked={coinCategory === "지급"}
                  onClick={() => setCoinCategory("지급")}
                />
                <AdminRadio
                  id="revoke"
                  name="category"
                  value="revoke"
                  htmlFor="revoke"
                  label="회수"
                  checked={coinCategory === "회수"}
                  onClick={() => setCoinCategory("회수")}
                />
                <AdminRadio
                  id="add"
                  name="category"
                  value="add"
                  htmlFor="add"
                  label="적립"
                  checked={coinCategory === "적립"}
                  onClick={() => setCoinCategory("적립")}
                />
              </Box>
            </RowTableData>
          </Box>

          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="119px" height="48px">
              상태
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 119px)",
                height: "48px",
              }}
            >
              <Box
                size={{
                  width: "100%",
                  height: "100%",
                }}
                display={{
                  display: "flex",
                  justify: "flex-start",
                  align: "center",
                }}
              >
                <AdminRadio
                  id="all"
                  name="status_category"
                  value="all"
                  htmlFor="all"
                  label="전체"
                  checked={coinStatus === "0"}
                  onClick={() => setCoinStatus("0")}
                  // checked={cheeringState === "전체"}
                  // onClick={() => setCheeringState("전체")}
                />
                <AdminRadio
                  id="accept"
                  name="status_category"
                  value="accept"
                  htmlFor="accept"
                  label="승인"
                  checked={coinStatus === "1"}
                  onClick={() => setCoinStatus("1")}
                  // onClick={() => setCheeringState("응원대기")}
                />
                <AdminRadio
                  id="cancle"
                  name="status_category"
                  value="cancle"
                  htmlFor="cancle"
                  label="취소"
                  checked={coinStatus === "2"}
                  onClick={() => setCoinStatus("2")}
                  // onClick={() => setCheeringState("응원중")}
                />
              </Box>
            </RowTableData>
          </Box>

          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="119px" height="48px">
              검색 분류
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 119px)",
              }}
              padding={{
                top: "8px",
                left: "8px",
              }}
            >
              <Input
                theme={"adminInput"}
                size={{
                  width: "104px",
                  height: "32px",
                }}
                margin={{
                  right: "4px",
                }}
                background={{
                  color: "#F3F3F3",
                }}
                typo={{
                  size: "12px",
                }}
                placeholder="사유"
                readOnly
              />
              <Input
                theme={"adminInput"}
                size={{
                  width: "500px",
                  height: "32px",
                }}
                value={inputValue}
                onChange={handleInputChange}
              />
              {/* <Input as="radio" theme={"adminRadio"} name="search" value="id" /> */}
            </RowTableData>
          </Box>
        </RowTable>
        <AdminButton
          category="button"
          theme="mainButton"
          margin={{
            top: "24px",
            left: "456px",
          }}
          onClick={handleSearch}
        >
          검색
        </AdminButton>

        {/* 회원 목록 */}
        <Box
          size={{
            width: "100%",
            height: "auto",
          }}
          margin={{
            top: "40px",
          }}
        >
          <Text theme={"adminTitle"}>코인 목록 </Text>
          <Box
            size={{
              width: "1024px",
              height: "auto",
            }}
            border={{
              top: "1px",
              left: "1px",
              right: "1px",
              type: "solid",
              color: "gray10",
              radius: "3px 3px 0 0",
            }}
          >
            {/* 목록 상단 인원 수, 차단 버튼 */}
            <Box
              theme="adminListBox"
              size={{
                height: "44px",
              }}
              padding={{
                left: "16px",
              }}
            >
              <Text
                as="span"
                typo={{
                  size: "13px",
                  weight: "500",
                  color: "black02",
                }}
              >
                총{" "}
                <Text
                  as="span"
                  typo={{
                    color: "blue01",
                  }}
                >
                  {total}
                </Text>{" "}
                개
              </Text>
              <AdminDropdown
                margin={{
                  right: "10px",
                }}
                size={{
                  width: "110px",
                  height: "28px",
                }}
                menuSize={{
                  width: "110px",
                  height: "auto",
                }}
                category={labels}
                onSelect={changeSelectedList}
              />
            </Box>
            <Box
              theme="adminListBox"
              size={{
                height: "44px",
              }}
              padding={{
                left: "8px",
              }}
              border={{
                top: "1px",
                style: "solid",
                color: "gray10",
              }}
            >
              <AdminButton
                category="button"
                theme="listOptionButton"
                size={{
                  width: "53px",
                  height: "28px",
                }}
                padding={{
                  left: "6px",
                  right: "6px",
                }}
                onClick={handleCancleCheering}
              >
                <Image
                  src="/asset/img/admin/button/red_X.svg"
                  margin={{
                    right: "2px",
                  }}
                />
                취소
              </AdminButton>
            </Box>
          </Box>
          {/* 회원 목록 리스트 */}
          <Table isAttached={true} margin={{ bottom: "40px" }}>
            <thead>
              <tr>
                <TableHead width="42px" padding="8px 0">
                  <AdminCheckbox
                    margin={{
                      left: "13px",
                    }}
                    value="all"
                    checked={data
                      ?.filter((item) => item.processed === false)
                      .every((item) => selectedUser[item.coinId])}
                    onClick={handleSelectAll}
                    readOnly={isAllReadOnly}
                  />
                </TableHead>

                <TableHead width="92px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    NO.
                  </Text>
                </TableHead>

                <TableHead width="362px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    사유
                  </Text>
                </TableHead>

                <TableHead width="122px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    코인
                  </Text>
                </TableHead>

                <TableHead width="122px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    분류
                  </Text>
                </TableHead>

                <TableHead width="122px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    상태
                  </Text>
                </TableHead>

                <TableHead
                  width="calc(1024px - 42px - 92px - 362px - 122px - 122px - 122px)"
                  isLastChild
                >
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    일시
                  </Text>
                </TableHead>
              </tr>
            </thead>
            <tbody>
              {data?.map((data, index) => (
                <tr key={data.coinId}>
                  <TableData size={{ height: "46px" }}>
                    <AdminCheckbox
                      margin={{
                        left: "13px",
                      }}
                      checked={selectedUser[data.coinId]}
                      onClick={(e) => handleSelect(e, data.coinId)}
                      value={data.coinId}
                      readOnly={data?.processed !== false ? true : false}
                    />
                  </TableData>
                  <TableData>
                    {total - (currentPage - 1) * pageSize - index}
                  </TableData>
                  <TableData>
                    <Box
                      display={{
                        display: "inline-block",
                      }}
                      size={{
                        width: "100%",
                        height: "100%",
                      }}
                      padding={{
                        left: "14px",
                        right: "14px",
                      }}
                      typo={{
                        align: "left",
                        lineClamp: 2,
                        textDecoration:
                          data?.coinCategory !== "적립" && "underline",
                      }}
                      onClick={handleReasonClick.bind(
                        this,
                        data?.coinCategory,
                        data?.story?.storyId,
                        data?.coinValue,
                        data?.coinReason,
                        data?.coinId
                      )}
                      other={{
                        cursor: data?.coinCategory !== "적립" && "pointer",
                      }}
                    >
                      {data.story.storyTitle && data.coinCategory !== "적립"
                        ? data.story.storyTitle
                        : data.coinReason}
                    </Box>
                  </TableData>
                  <TableData
                    padding={{
                      all: "7px",
                    }}
                  >
                    <Text
                      typo={{
                        lineClamp: 1,
                      }}
                    >
                      {data.coinValue} Coin
                    </Text>
                  </TableData>

                  <TableData>{data.coinCategory}</TableData>

                  <TableData>
                    {data.processed === false
                      ? "승인"
                      : data.processed === true
                      ? "취소"
                      : "-"}
                  </TableData>

                  <TableData isLastChild>
                    {dayjs(data.createdAt).format("YYYY-MM-DD HH:mm")}
                  </TableData>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
        {total > 0 && (
          <AdminPagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </Box>
    </>
  );
};

export default UserCheeringDetail;
