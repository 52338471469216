import { updateReply } from "api/story";
import { ErrorContent } from "component/organisms/common/globalModal";
import useInput from "hook/util/useInput";
import useUrl from "hook/util/useUrl";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";

const useUpdateReplyForm = (reply, toggleUpdate) => {
  const update = useInput(reply);
  const queryClient = useQueryClient();
  const { setModal } = useModalStore();

  const updateMutation = useMutation(updateReply, {
    onSuccess: () => {
      queryClient.invalidateQueries("replyList");
      toggleUpdate();
    },
    onError: ({ response: { data } }) => {
      setModal({
        title: "댓글 수정",
        content: <ErrorContent message={data.message} />,
      });
    },
  });

  const submit = (e, commentId) => {
    e.preventDefault();

    updateMutation.mutate({
      commentId,
      data: {
        userComment: update.value,
      },
    });
  };

  return {
    update,
    submit,
  };
};

export default useUpdateReplyForm;
