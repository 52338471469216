import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { ThemeProvider } from "styled-components";
import GlobalStyles from "style/global";
import { Router } from "./router";
import theme from "style/theme";
import worker from "mock/worker";
import GlobalModal from "component/organisms/common/globalModal";
import { ReactQueryDevtools } from "react-query/devtools";

if (
  process.env.NODE_ENV === "development" &&
  process.env.REACT_APP_DEV === "LOCAL"
) {
  worker.start();
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyles />
        <RouterProvider router={Router} />
        <GlobalModal />
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
