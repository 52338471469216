import styled from "styled-components";

export const RadioInput = styled.input`
  // 라디오 버튼을 숨기고 사용자 정의 스타일을 적용하기 위해 opacity를 0으로 설정
  opacity: 0;
  position: absolute;

  & + label {
    position: relative;
    padding-left: 20px;
    cursor: pointer;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 14px;
      height: 14px;
      border-radius: 50%;
      border: 1px solid ${(props) => props.theme.colors.black01};
      background-color: #fff;
    }

    &:after {
      content: "";
      position: absolute;
      width: 10px;
      height: 10px;
      left: 3px;
      top: 3px;
      border-radius: 50%;
      background: ${(props) => props.theme.colors.pink03};
      transform: scale(0);
      transition: transform 0.1s ease-in-out;
    }
  }

  // 라디오 버튼이 선택됐을 때의 스타일
  &:checked + label:after {
    transform: scale(1); // 분홍색 원을 표시합니다.
  }
`;

export const RadioLabel = styled.label`
  font-size: 13px;
  font-weight: 400;
  color: ${(props) => props.theme.colors.black02};
`;
