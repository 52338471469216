import { checkCode } from "api/auth";
import { ErrorContent } from "component/organisms/common/globalModal";
import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import useModalStore from "store/useModalStore";

const useCheckCode = () => {
  const { watch, getValues, setValue } = useFormContext();
  const { setModal } = useModalStore();
  const checkCodeMutation = useMutation(checkCode, {
    onSuccess: () => {
      setValue("check", true);
    },
    onError: ({ response: { data } }) => {
      setModal({
        title: "이메일 인증",
        content: <ErrorContent message={data.message} />,
      });
    },
  });

  const isActive = watch("code");

  const submit = () => {
    const email = getValues("email");
    const code = getValues("code");

    checkCodeMutation.mutate({ email, code });
  };

  return {
    isActive,
    //
    submit,
  };
};

export default useCheckCode;
