import Control from "style/utils/core/control";
import margin from "style/utils/styles/margin";
import styled from "styled-components";
import border from "style/utils/styles/border";

const [propTypes, generateStyle] = Control([margin, border]);

export const TableWrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  height: auto;

  ${(props) => generateStyle(props)}
`;

TableWrapper.propTypes = propTypes;
