import React from "react";
import { RadioInput, RadioLabel } from "./adminRadio.style";
import Box from "component/atoms/box";

const AdminRadio = (props) => {
  const { id, name, value, htmlFor, label, checked, onClick, onChange } = props;
  return (
    <Box
      size={{
        width: "auto",
        height: "auto",
      }}
      display={{
        display: "flex",
        align: "center",
        justify: "flex-start",
      }}
      margin={{
        right: "20px",
      }}
      padding={{
        top: "2px",
      }}
    >
      <RadioInput
        type="radio"
        id={id}
        name={name}
        value={value}
        checked={checked}
        onClick={onClick}
        onChange={onChange}
      />
      <RadioLabel htmlFor={htmlFor}>{label}</RadioLabel>
    </Box>
  );
};

export default AdminRadio;
