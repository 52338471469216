import Box from "component/atoms/box";
import Image from "component/atoms/image";
import LineBreak from "component/atoms/lineBreak";
import Text from "component/atoms/text";
import CommonButton from "component/molecules/common/commonButton";

const NotFound = () => {
  return (
    <Box
      display={{
        display: "flex",
        align: "center",
        justify: "center",
        direction: "column",
      }}
      size={{
        width: "100%",
      }}
      margin={{
        all: "240px 0",
      }}
      longMobile={{
        margin: {
          all: "150px 0",
        },
      }}
    >
      <Image
        src={"/asset/img/common/error.svg"}
        longMobile={{
          size: {
            width: "60px",
            height: "60px",
          },
        }}
      />

      <Text
        typo={{
          size: "25px",
          weight: "700",
        }}
        margin={{
          all: "30px 0 10px",
        }}
        longMobile={{
          typo: {
            size: "16px",
          },
          margin: {
            all: "10px 0",
          },
        }}
      >
        요청하신 페이지를 찾을 수 없습니다.
      </Text>
      <Text
        typo={{
          size: "22px",
          lineHeight: "35px",
          align: "center",
          color: "gray03",
        }}
        margin={{
          bottom: "30px",
        }}
        longMobile={{
          typo: {
            size: "14px",
            wordBreak: "keep-all",
          },
          margin: {
            bottom: "20px",
          },
          padding: {
            all: "0 16px",
          },
        }}
      >
        잘못된 접근이거나 요청하신 페이지를 찾을 수 없습니다.
        <LineBreak />
        입력하신 페이지의 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.
      </Text>

      <CommonButton
        category={"link"}
        isActive
        size={{
          width: "270px",
          height: "40px",
        }}
        link={"/"}
      >
        홈으로
      </CommonButton>
    </Box>
  );
};

export default NotFound;
