import { artistRequest } from "api/mypage";
import Text from "component/atoms/text";
import dayjs from "dayjs";
import useToggle from "hook/util/useToggle";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";
import checkRequired from "utils/checkRequired";

const required = {
  name: true,
  link: true,
  portfolio_music: true,
};

const SuccessContent = () => {
  return (
    <Text
      typo={{
        size: "18px",
        weight: "700",
      }}
    >
      아티스트 신청을 하였습니다.
    </Text>
  );
};

const useArtistForm = () => {
  const form = useForm({
    defaultValues: {
      name: "",
      link: "",
      portfolio_music: dayjs().format("YYYY-MM-DD"),
    },
  });
  const { toggle, handleToggle } = useToggle();
  const { watch, handleSubmit } = form;
  const { setModal } = useModalStore();

  const queryClient = useQueryClient();

  const artistRequestMutation = useMutation(artistRequest, {
    onSuccess: () => {
      handleToggle();
      queryClient.invalidateQueries("member");

      setModal({
        title: "아티스트 신청",
        content: <SuccessContent />,
      });
    },
    onError: () => {},
  });

  const isActive = useMemo(() => checkRequired(required, watch()), [watch()]);

  const submit = (data) => {
    const formData = new FormData();

    formData.append("firstMusic", data.name);
    formData.append("firstLink", data.link);
    formData.append("firstMusicCover", data.cover);
    formData.append("firstReleaseDate", data.portfolio_music);

    isActive && artistRequestMutation.mutate(formData);
  };

  return {
    form,
    isActive,
    toggle,
    //
    handleToggle,
    submit: handleSubmit(submit),
  };
};

export default useArtistForm;
