import React from "react";
import { SideNavWrapper } from "./sideNav.style";
import Image from "component/atoms/image";
import Button from "component/atoms/button";
import Text from "component/atoms/text";
import HeaderMenu from "component/organisms/admin/headerMenu";
import Box from "component/atoms/box";
import useUrl from "hook/util/useUrl";
import useLogout from "hook/api/auth/logout";
import useCheckEdit from "hook/util/useCheckEdit";

const SideNav = () => {
  const location = useUrl();
  const { changeLink } = useUrl();
  const isDetailPage = location.pathname.includes("detail");
  const logout = useLogout();

  const handleLogout = () => {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      logout();
    }
  };

  const checkEdit = useCheckEdit();
  return (
    <SideNavWrapper>
      <Box
        other={{
          cursor: "pointer",
        }}
      >
        <Image
          src={"/asset/img/admin/layout/admin_header_logo.svg"}
          margin={{
            top: "50px",
            bottom: "50px",
          }}
          onClick={() => checkEdit(changeLink)("/admin/dashboard")}
        />
      </Box>

      <HeaderMenu
        position={{
          type: "relative",
        }}
      />

      {!isDetailPage && (
        <Box
          size={{
            width: "100%",
            height: "auto",
          }}
          display={{
            display: "flex",
            align: "center",
          }}
          position={{
            type: "relative",
            top: "168px",
            left: "36px",
          }}
          padding={{
            bottom: "50px",
          }}
        >
          <Image src={"/asset/img/admin/layout/logout.svg"} color="black" />
          <Button
            typo={{
              size: "14px",
              weight: "700",
              color: "black01",
            }}
            margin={{
              left: "10px",
            }}
            onClick={handleLogout}
          >
            로그아웃
          </Button>
        </Box>
      )}
    </SideNavWrapper>
  );
};

export default SideNav;
