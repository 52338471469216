import Box from "component/atoms/box";
import Text from "component/atoms/text";
import React from "react";

const BottomMenu = (props) => {
  const { visible, onClick, children } = props;
  return (
    <Box
      display={{
        display: visible ? "flex" : "none",
        direction: "column",
      }}
      size={{
        width: "100%",
        height: "auto",
      }}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};

export default BottomMenu;
