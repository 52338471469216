import Box from "component/atoms/box";

const NoticeIcons = () => {
  return (
    <Box>
      <Box
        position={{
          type: "absolute",
          top: "10px",
          left: "10px",
        }}
        size={{
          width: "10px",
          height: "10px",
        }}
        border={{
          all: "1px",
          radius: "50%",
        }}
        background={{
          color: "pink02",
        }}
        longMobile={{
          position: {
            top: "5px",
            left: "5px",
          },
        }}
      />
      <Box
        position={{
          type: "absolute",
          top: "10px",
          right: "10px",
        }}
        size={{
          width: "10px",
          height: "10px",
        }}
        border={{
          all: "1px",
          radius: "50%",
        }}
        background={{
          color: "pink02",
        }}
        longMobile={{
          position: {
            top: "5px",
            right: "5px",
          },
        }}
      />
      <Box
        position={{
          type: "absolute",
          bottom: "10px",
          left: "10px",
        }}
        size={{
          width: "10px",
          height: "10px",
        }}
        border={{
          all: "1px",
          radius: "50%",
        }}
        background={{
          color: "pink02",
        }}
        longMobile={{
          display: {
            display: "none",
          },
        }}
      />
      <Box
        position={{
          type: "absolute",
          bottom: "10px",
          right: "10px",
        }}
        size={{
          width: "10px",
          height: "10px",
        }}
        border={{
          all: "1px",
          radius: "50%",
        }}
        background={{
          color: "pink02",
        }}
        longMobile={{
          display: {
            display: "none",
          },
        }}
      />
    </Box>
  );
};

export default NoticeIcons;
