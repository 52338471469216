import { select, selectAssist } from "api/story";
import Box from "component/atoms/box";
import Text from "component/atoms/text";
import { ErrorContent } from "component/organisms/common/globalModal";
import useUrl from "hook/util/useUrl";
import { useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";

const SuccessContent = ({ assist }) => {
  return (
    <Box>
      {assist ? (
        <Text
          typo={{
            size: "18px",
            weight: "700",
          }}
          longMobile={{
            typo: {
              size: "14px",
              weight: "700",
            },
          }}
        >
          멤버 매칭 확정이 완료되었습니다.
        </Text>
      ) : (
        <>
          <Text
            typo={{
              size: "18px",
              weight: "700",
            }}
            longMobile={{
              typo: {
                size: "14px",
                weight: "700",
              },
            }}
          >
            매칭이 확정되었습니다.
          </Text>
          <Text
            typo={{
              size: "14px",
              color: "gray04",
            }}
            longMobile={{
              typo: {
                size: "12px",
                color: "gray04",
              },
            }}
          >
            창작물이 완료되기 전까지, 콘텐츠 정보를 등록해주세요.
          </Text>
        </>
      )}
    </Box>
  );
};

const useSelectStoryForm = () => {
  const [selectMessage, setSelectMessage] = useState(0);
  const {
    query: { assist },
    params,
    changeLink,
  } = useUrl();
  const queryClient = useQueryClient();
  const { setModal } = useModalStore();

  const selectMutation = useMutation(assist ? selectAssist : select, {
    onSuccess: ({ message }) => {
      setModal({
        title: assist ? "멤버 매칭" : "아티스트 매칭하기",
        content: <SuccessContent assist={assist} />,
      });
      queryClient.invalidateQueries(["story", params.id]);
      changeLink(`/story/${params.id}`);
    },
    onError: (error) => {
      setModal({
        title: assist ? "멤버 매칭" : "아티스트 매칭하기",
        content: <ErrorContent />,
      });
    },
  });

  const submit = (id) => {
    selectMutation.mutate(id);
  };

  return {
    selectMessage,
    setSelectMessage,
    handleSubmit: submit,
  };
};

export default useSelectStoryForm;
