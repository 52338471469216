import React from "react";
import { TableWrapper } from "./rowTable.style";
const RowTable = (props) => {
  const { children, margin, size } = props;

  return (
    <TableWrapper $size={size} $margin={margin}>
      {children}
    </TableWrapper>
  );
};

export default RowTable;
