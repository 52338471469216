import { GET, POST, PUT } from "api/config";

export const member = async (id) => await GET(`/mypage/${id}/info`);

export const myStory = async (id, state, page) =>
  await GET(`/mypage/${id}?state=${state}&page=${page}`);

export const creation = async (id, state, page) =>
  await GET(`/mypage/creation/${id}?state=${state}&page=${page}`);

export const moomoocoin = async (state, page) =>
  await GET(`/mypage/moomoocoin?state=${state}&page=${page}`);

export const likeList = async (state, page) =>
  await GET(`/mypage/likes?state=${state}&page=${page}`);

export const cheering = async (state, page) =>
  await GET(`/mypage/mycheering?state=${state}&page=${page}`);

export const request = async (state, page) =>
  await GET(`/mypage/request?state=${state}&page=${page}`);

export const requestCancel = async ({ id, type }) =>
  await POST(`/mypage/request/${id}/${type}`);

export const myInfo = async () => await GET(`/mypage/myinfo`);

export const edit = async (data) => await PUT(`/mypage/myinfo`, data);

export const artistRequest = async (data) =>
  await PUT(`/mypage/app/artist`, data);

export const portfolio = async (id) => await GET(`/mypage/portfolio/${id}`);

export const cheeringDetail = async (id) =>
  await GET(`/mypage/mycheering/${id}`);

export const cheeringCancel = async (id) =>
  await POST(`/mypage/mycheering/${id}`);
