import { remove } from "api/portfolio";
import Text from "component/atoms/text";
import { ErrorContent } from "component/organisms/common/globalModal";
import useToggle from "hook/util/useToggle";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";

const SuccessContent = () => {
  return (
    <Text
      typo={{
        size: "18px",
        weight: "700",
        align: "center",
      }}
      tablet={{
        typo: {
          size: "14px",
        },
      }}
    >
      포트폴리오 삭제가 완료되었습니다.
    </Text>
  );
};

const useDeletePortfolioForm = (id) => {
  const { toggle, handleToggle } = useToggle();
  const { setModal } = useModalStore();
  const queryClient = useQueryClient();
  const deleteMutation = useMutation(remove, {
    onSuccess: () => {
      handleToggle();
      queryClient.invalidateQueries("portfolio");
      setModal({
        title: "포트폴리오",
        content: <SuccessContent />,
      });
    },
    onError: () => {
      setModal({
        title: "포트폴리오",
        content: <ErrorContent />,
      });
    },
  });

  const onSubmit = () => {
    deleteMutation.mutate(id);
  };

  return {
    toggle,
    handleToggle,
    onSubmit,
  };
};

export default useDeletePortfolioForm;
