import Box from "component/atoms/box";
import Button from "component/atoms/button";
import CommonButton from "component/molecules/common/commonButton";
import LabelText from "component/molecules/common/labelText";
import { MdOutlineImage } from "react-icons/md";
import { useFormContext } from "react-hook-form";
import { useEffect, useRef, useState } from "react";
import Input from "component/atoms/input";
import Image from "component/atoms/image";
import useModalStore from "store/useModalStore";
import Text from "component/atoms/text";
import convertImage from "utils/convertImage";

const accept = "image/png,image/jpeg,image/jpg";

const ErrorContent = () => {
  return <Text>{accept} 형식의 파일만 가능합니다.</Text>;
};

const ThumbnailField = (props) => {
  const {
    title,
    required,
    info,
    desc,
    //
    typo,
    size,
    thumbnail,
    //
    name,
    mobileLayout,
    isReset,
  } = props;
  const fileRef = useRef();
  const [thumbnailImage, setThumbnailImage] = useState(thumbnail);
  const { setValue, watch } = useFormContext();
  const { setModal } = useModalStore();

  const image = watch(name);

  const handleFileClick = () => {
    fileRef.current.click();
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const isAccept = accept.split(",").some((type) => {
      return file.type === type;
    });

    if (isAccept && file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setThumbnailImage(reader.result);
      };

      reader.readAsDataURL(file);

      setValue(name, file);
    } else {
      setModal({
        title: "이미지 업로드",
        content: <ErrorContent />,
      });
    }
  };

  useEffect(() => {
    if (thumbnail) {
      setThumbnailImage(thumbnail);
    } else if (!image) {
      setThumbnailImage(null);
    } else if (image) {
      setThumbnailImage(convertImage(image));
    }
  }, [thumbnail, image]);

  return (
    <>
      <LabelText title={title} required={required} info={info} typo={typo} />

      <Input
        as={"input"}
        type={"file"}
        accept={accept}
        hidden={true}
        ref={fileRef}
        onChange={handleFileChange}
      />
      <Box
        display={{
          display: mobileLayout ? "flex" : "block",
        }}
        mobile={{
          display: {
            display: "flex",
          },
        }}
      >
        <Button
          type={"button"}
          size={{ ...size, aspectRatio: "1/1" }}
          border={{
            all: "1px",
          }}
          margin={{
            bottom: mobileLayout ? "0px" : "5px",
            right: mobileLayout ? "7px" : "0px",
          }}
          background={{
            color: "white",
          }}
          typo={{
            size: "0",
          }}
          longMobile={{
            size: {
              width: "170px",
              aspectRatio: "1/1",
            },
            margin: {
              right: "7px",
            },
          }}
          onClick={handleFileClick}
        >
          {thumbnailImage ? (
            <Image
              size={{
                width: "100%",
                height: "100%",
              }}
              src={thumbnailImage}
              alt="thumbnail"
            />
          ) : (
            <MdOutlineImage size={64} color="#D9D9D9" />
          )}
        </Button>
        <Box
          display={{
            display: "flex",
            direction: mobileLayout ? "column-reverse" : "column",
          }}
          size={{
            width: mobileLayout ? "calc(50% - 7px)" : "auto",
          }}
          longMobile={{
            display: {
              display: "flex",
              direction: "column-reverse",
            },
            size: {
              width: "calc(50% - 7px)",
            },
          }}
        >
          <CommonButton
            category={"button"}
            type={"button"}
            isActive
            size={{
              width: size.width,
              height: "40px",
              maxWidth: "100%",
            }}
            longMobile={{
              size: {
                width: "200px",
              },
            }}
            onClick={handleFileClick}
          >
            이미지 업로드
          </CommonButton>
          {desc}
        </Box>
      </Box>
    </>
  );
};

export default ThumbnailField;
