import Line from "component/atoms/line";
import LineBreak from "component/atoms/lineBreak";
import Loading from "component/atoms/loading";
import Text from "component/atoms/text";
import CommonButton from "component/molecules/common/commonButton";
import Modal from "component/molecules/common/modal";
import FileField from "component/organisms/common/fileField";
import InputField from "component/organisms/common/inputField";
import useFinishedForm from "hook/api/story/finished";
import { FormProvider } from "react-hook-form";

const FinishModal = (props) => {
  const { toggle, handleToggle } = props;
  const { form, isActive, isLoading, onSubmit } = useFinishedForm(handleToggle);
  return (
    <Modal
      title={"창작 완료 신청하기"}
      info={
        <Text
          size={{
            width: "100%",
          }}
          typo={{
            size: "12px",
          }}
          margin={{
            top: "10px",
          }}
        >
          즐거운 창작을 완료하셨나요?
          <LineBreak />
          창작을 완료하기 위해 심사를 요청해주세요!
          <LineBreak />
          영업일 기준 7일 이내 심사가 완료됩니다.
        </Text>
      }
      visible={toggle}
      onClose={handleToggle}
    >
      <FormProvider {...form}>
        <InputField
          as={"input"}
          type={"text"}
          title={"곡명"}
          name={"musicName"}
          typo={{
            size: "14px",
          }}
          size={{
            width: "100%",
            height: "38px",
          }}
          placeholder={"곡명을 입력해주세요."}
        />

        <Line
          direction="row"
          size={"100%"}
          background={{
            color: "gray02",
          }}
          margin={{ all: "32px 0 20px" }}
          longMobile={{
            margin: {
              all: "15px 0 5px",
            },
          }}
        />

        <FileField
          title={"음원 등록하기"}
          typo={{
            size: "14px",
          }}
          name={"musicFile"}
          info={
            <Text
              as={"span"}
              size={{
                width: "100%",
              }}
              typo={{
                size: "12px",
                weight: "400",
                color: "gray04",
              }}
            >
              음원 파일을 등록해주세요.{" "}
              <LineBreak
                display={{
                  display: "none",
                }}
                longMobile={{
                  display: {
                    display: "block",
                  },
                }}
              />
              음원 파일은 심사 용도 외에 사용되지 않습니다.
            </Text>
          }
        />

        <Line
          direction="row"
          size={"100%"}
          background={{
            color: "gray02",
          }}
          margin={{ all: "20px 0 25px" }}
          longMobile={{
            margin: {
              all: "15px 0 10px",
            },
          }}
        />

        <CommonButton
          category={"button"}
          size={{
            width: "100%",
            height: "63px",
          }}
          isActive={isActive}
          isDisabled={!isActive}
          typo={{
            size: "18px",
          }}
          longMobile={{
            size: {
              width: "100%",
              height: "50px",
            },
            typo: {
              size: "14px",
            },
          }}
          onClick={onSubmit}
        >
          {isLoading ? <Loading /> : "심사 요청하기"}
        </CommonButton>
      </FormProvider>
    </Modal>
  );
};

export default FinishModal;
