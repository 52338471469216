import Box from "component/atoms/box";
import Image from "component/atoms/image";
import Text from "component/atoms/text";

const ServiceItem = (props) => {
  const { img, title, subTitle, description, mobileDescription } = props;
  return (
    <Box
      display={{
        display: "flex",
        direction: "column",
      }}
      other={{
        boxShadow: "4px 4px",
      }}
      border={{
        all: "1px",
        style: "solid",
        color: "black01",
      }}
    >
      <Box
        size={{
          height: "63px",
        }}
        display={{
          display: "flex",
        }}
      >
        <Box
          display={{
            display: "flex",
            justify: "center",
            align: "center",
          }}
          size={{
            width: "72px",
          }}
          margin={{
            all: "0 auto",
          }}
          border={{
            right: "1px",
            style: "solid",
            color: "black01",
          }}
        >
          <Image
            size={{
              width: "56px",
              height: "auto",
            }}
            src={img}
            alt={title}
          />
        </Box>

        <Box
          display={{
            display: "flex",
            direction: "column",
            justify: "center",
            align: "center",
          }}
          size={{
            width: "calc(100% - 72px)",
            height: "100%",
          }}
          background={{
            color: "pink01",
          }}
        >
          <Text
            typo={{
              size: "14px",
              weight: "900",
              space: "2px",
            }}
            mobile={{
              typo: {
                size: "12px",
              },
            }}
          >
            {title}
          </Text>
          <Text
            typo={{
              size: "19px",
              weight: "700",
            }}
            mobile={{
              typo: {
                size: "16px",
              },
            }}
          >
            {subTitle}
          </Text>
        </Box>
      </Box>
      <Box
        size={{
          width: "100%",
        }}
        display={{
          display: "flex",
          direction: "column",
        }}
        border={{
          top: "1px",
          style: "solid",
          color: "black01",
        }}
        padding={{
          all: "16px 20px",
        }}
      >
        {description.map((item, index) => (
          <Text
            key={index}
            typo={{
              size: "15px",
              weight: "400",
              lineHeight: "1.6",
              align: "center",
            }}
            mobile={{
              display: {
                display: "none",
              },

              typo: {
                size: "12px",
                lineHeight: "1.5",
                align: index % 2 === 0 ? "right" : "left",
              },
            }}
          >
            {item}
          </Text>
        ))}
        {mobileDescription.map((item, index) => (
          <Text
            key={index}
            display={{
              display: "none",
            }}
            typo={{
              size: "15px",
              weight: "400",
              lineHeight: "1.6",
              align: "center",
            }}
            mobile={{
              display: {
                display: "inline",
              },

              typo: {
                size: "12px",
                lineHeight: "1.5",
                align: "center",
              },
            }}
          >
            {item}
          </Text>
        ))}
      </Box>
    </Box>
  );
};

export default ServiceItem;
