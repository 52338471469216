import Box from "component/atoms/box";
import Button from "component/atoms/button";
import Api from "component/molecules/common/api";
import CheckboxField from "component/organisms/common/checkboxField";
import LineTitle from "component/organisms/common/lineTitle";
import useMarketing from "hook/api/main/marketing";
import useTerms from "hook/api/main/terms";
import useToggle from "hook/util/useToggle";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

const Agreement = (props) => {
  const { toggle, handleToggle } = useToggle();

  const { toggle: privacyToggle, handleToggle: handlePrivacyToggle } =
    useToggle();
  const { toggle: marketingToggle, handleToggle: handleMarketingToggle } =
    useToggle();

  return (
    <Box
      size={{
        width: "100%",
      }}
      margin={{
        bottom: "50px",
      }}
    >
      <LineTitle
        title={"이용 약관"}
        typo={{
          size: "24px",
          weight: "700",
        }}
      >
        <Button
          display={{
            display: "none",
          }}
          longMobile={{
            display: {
              display: "flex",
            },
          }}
          type={"button"}
          onClick={handleToggle}
        >
          {!toggle ? <BsChevronUp /> : <BsChevronDown />}
        </Button>
      </LineTitle>

      <Box
        position={{
          type: "relative",
        }}
        display={{
          display: "block",
        }}
        longMobile={{
          display: {
            display: toggle ? "none" : "block",
          },
        }}
      >
        <CheckboxField
          title={"[필수] 개인정보 이용 동의"}
          name={"isPrivacy"}
          optionList={[
            [
              {
                text: "동의합니다.",
                value: true,
              },
            ],
          ]}
          info={
            <>
              <Button
                display={{
                  display: "flex",
                }}
                longMobile={{
                  display: {
                    display: "none",
                  },
                }}
                type={"button"}
                onClick={handlePrivacyToggle}
              >
                {privacyToggle ? <BsChevronUp /> : <BsChevronDown />}
              </Button>
              <Box
                display={{
                  display: !privacyToggle ? "none" : "block",
                }}
                size={{
                  width: "100%",
                  height: "153px",
                }}
                border={{
                  all: "1px",
                }}
                padding={{
                  all: "20px",
                }}
                margin={{
                  top: "10px",
                }}
                other={{ overflowY: "auto" }}
                longMobile={{
                  display: { display: "block" },
                  padding: { all: "12px" },
                }}
              >
                <Api api={useTerms}>
                  {(data) => (
                    <div
                      dangerouslySetInnerHTML={{ __html: data?.content }}
                      className="editor-content"
                    />
                  )}
                </Api>
              </Box>
            </>
          }
        />
      </Box>

      <Box
        display={{
          display: "block",
        }}
        margin={{
          top: "20px",
        }}
        longMobile={{
          display: {
            display: toggle ? "none" : "block",
          },
        }}
      >
        <Box
          margin={{
            bottom: "24px",
          }}
          position={{
            type: "relative",
          }}
        >
          <CheckboxField
            title={"[선택] 마케팅 수신 동의"}
            name={"isMarketing"}
            optionList={[
              [
                {
                  text: "동의합니다.",
                  value: true,
                },
              ],
            ]}
            info={
              <>
                <Button
                  display={{
                    display: "flex",
                  }}
                  longMobile={{
                    display: {
                      display: "none",
                    },
                  }}
                  type={"button"}
                  onClick={handleMarketingToggle}
                >
                  {marketingToggle ? <BsChevronUp /> : <BsChevronDown />}
                </Button>
                <Box
                  display={{
                    display: !marketingToggle ? "none" : "block",
                  }}
                  size={{
                    width: "100%",
                    height: "153px",
                  }}
                  border={{
                    all: "1px",
                  }}
                  padding={{
                    all: "20px",
                  }}
                  margin={{
                    top: "10px",
                  }}
                  other={{ overflowY: "auto" }}
                  longMobile={{
                    display: { display: "block" },
                    padding: { all: "12px" },
                  }}
                >
                  <Api api={useMarketing}>
                    {(data) => (
                      <div
                        dangerouslySetInnerHTML={{ __html: data?.content }}
                        className="editor-content"
                      />
                    )}
                  </Api>
                </Box>
              </>
            }
          />
        </Box>
      </Box>
    </Box>
  );
};

export default Agreement;
