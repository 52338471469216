import React from "react";
import { TableDataWrapper } from "./tableData.style";

const TableData = (props) => {
  const {
    children,
    size,
    padding,
    isLastChild,
    onClick,
    other,
    border,
    isDrop,
    onMouseDown,
    onMouseUp,
    onMouseMove,
  } = props;
  return (
    <TableDataWrapper
      onMouseDown={onMouseDown}
      onMouseUp={onMouseUp}
      onMouseMove={onMouseMove}
      onMouseOut={onMouseMove}
      $size={size}
      $padding={padding}
      isLastChild={isLastChild}
      onClick={onClick}
      $other={other}
      $border={border}
      isDrop={isDrop}
    >
      {children}
    </TableDataWrapper>
  );
};

TableData.propTypes = {
  ...TableDataWrapper.propTypes,
};

export default TableData;
