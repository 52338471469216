const ADMIN = [
  {
    id: 1,
    userId: "admin",
    name: "관리자",
    email: "admin",
    password: "admin",
    token: "1234",
  },
];

export default ADMIN;
