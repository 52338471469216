import Box from "component/atoms/box";
import List from "component/molecules/common/list";
import LineTitle from "component/organisms/common/lineTitle";
import useStoryList from "hook/api/story/list";
import useUrl from "hook/util/useUrl";
import StoryPortfolio from "../storyPortfolio";
import CommonButton from "component/molecules/common/commonButton";
import useToggle from "hook/util/useToggle";
import Modal from "component/molecules/common/modal";
import useCreatePortfolioForm from "hook/api/portfolio/create";
import PortfolioForm from "component/organisms/common/portfolioForm";
import Blank from "component/molecules/common/blank";
import usePortfolio from "hook/api/mypage/portfolio";
import { useState } from "react";
import useUpdatePortfolioForm from "hook/api/portfolio/update";

const Portfolio = (props) => {
  const { title, memberId, isYou } = props;
  const {
    query: { page },
  } = useUrl();

  const { toggle, handleToggle } = useToggle();
  const [isCreate, setIsCreate] = useState(false);
  const [selectData, setSelectData] = useState(null);
  const {
    form: createForm,
    isActive: isCreateActive,
    onSubmit: onCreateSubmit,
  } = useCreatePortfolioForm(handleToggle);
  const {
    form: editForm,
    isActive: isEditActive,
    onSubmit: onEditSubmit,
  } = useUpdatePortfolioForm(handleToggle, selectData);

  const handleCreate = () => {
    setIsCreate(true);
    handleToggle();
  };

  const handleEdit = (data) => {
    setIsCreate(false);
    handleToggle();
    setSelectData(data);
  };

  return (
    <>
      <LineTitle
        typo={{
          size: "18px",
        }}
        title={title}
      >
        {isYou && (
          <CommonButton
            category={"button"}
            size={{
              width: "140px",
              height: "40px",
            }}
            typo={{
              size: "14px",
              weight: "700",
            }}
            onClick={handleCreate}
          >
            포트폴리오 등록하기
          </CommonButton>
        )}
      </LineTitle>

      <List
        api={usePortfolio}
        params={{
          id: memberId,
          page,
          limit: 16,
        }}
      >
        {(list) =>
          list && (
            <>
              {list.length === 0 && <Blank title={"조회 결과가 없습니다."} />}
              {list.length !== 0 && (
                <Box
                  display={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: "147px 40px",
                  }}
                  margin={{
                    top: "147px",
                  }}
                  tablet={{
                    display: {
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: "146px 20px",
                    },
                    margin: {
                      top: "146px",
                    },
                  }}
                  longMobile={{
                    display: {
                      gap: "83px 8px",
                    },
                  }}
                >
                  {list.map((item) => (
                    <StoryPortfolio
                      key={item.portfolioId}
                      isYou={isYou}
                      {...item}
                      handleEdit={handleEdit}
                    />
                  ))}
                </Box>
              )}
            </>
          )
        }
      </List>

      <Modal
        title={`포트폴리오 ${isCreate ? "등록" : "수정"}하기`}
        visible={toggle}
        onClose={handleToggle}
      >
        <PortfolioForm form={isCreate ? createForm : editForm} />

        <CommonButton
          category={"button"}
          size={{
            width: "100%",
            height: "64px",
          }}
          typo={{
            size: "18px",
            weight: "700",
          }}
          // 반대로 바꿔야함
          isActive={isCreate ? isCreateActive : isEditActive}
          isDisabled={isCreate ? !isCreateActive : !isEditActive}
          onClick={isCreate ? onCreateSubmit : onEditSubmit}
        >
          포트폴리오 {isCreate ? "등록" : "수정"}하기
        </CommonButton>
      </Modal>
    </>
  );
};

export default Portfolio;
