import { find } from "api/auth";
import Text from "component/atoms/text";
import { ErrorContent } from "component/organisms/common/globalModal";
import usePattern from "hook/util/usePattern";
import useUrl from "hook/util/useUrl";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import useModalStore from "store/useModalStore";
import checkRequired from "utils/checkRequired";

const required = {
  email: true,
};

const SuccessContent = () => {
  return (
    <Text
      typo={{
        size: "18px",
        weight: "700",
        align: "center",
      }}
    >
      입력하신 메일로 임시 비밀번호가 발급되었습니다.
    </Text>
  );
};

const useFindForm = () => {
  const form = useForm({
    defaultValues: {
      email: "",
    },
  });
  const { watch, handleSubmit, getValues, reset } = form;
  const pattern = usePattern();
  const { setModal } = useModalStore();
  const { changeLink } = useUrl();

  const findMutation = useMutation(find, {
    onSuccess: ({ message }) => {
      const email = getValues("email");

      // setInfo(`${email} ${message}`);
      setModal({
        title: "아이디 / 비밀번호 찾기",
        content: <SuccessContent />,
      });
      changeLink("/login");
    },
    onError: ({
      response: {
        data: { message },
      },
    }) => {
      setModal({
        title: "아이디 / 비밀번호 찾기",
        content: <ErrorContent message={message} />,
      });
      reset();
    },
  });

  const checkFindRequired = () => {
    const data = watch();
    const check = checkRequired(required, data);

    return check;
  };

  const isActive = useMemo(() => {
    const { email } = watch();

    return checkFindRequired() && pattern.email.value.test(email);
  }, [watch()]);

  const submit = (data) => {
    isActive && findMutation.mutate(data);
  };

  return {
    form,
    isActive,
    //
    handleSubmit: handleSubmit(submit),
  };
};

export default useFindForm;
