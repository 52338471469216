import { useMutation, useQueryClient } from "react-query";
import useUrl from "hook/util/useUrl";
import { storyInfo, storyInfoUpdate } from "api/story";
import { useForm } from "react-hook-form";
import { useMemo } from "react";
import checkRequired from "utils/checkRequired";
import useModalStore from "store/useModalStore";
import Text from "component/atoms/text";
import { ErrorContent } from "component/organisms/common/globalModal";

const required = {
  musicName: true,
  musicIntro: true,
  teamName: true,
};

const SuccessContent = () => {
  return (
    <Text
      typo={{
        size: "18px",
        weight: "700",
        align: "center",
      }}
    >
      필수 양식 작성이 완료되었습니다.
    </Text>
  );
};

const useInfoUpdate = (toggleOff, isUpdate) => {
  const {
    params: { id },
  } = useUrl();
  const form = useForm({
    defaultValues: {
      musicName: "",
      musicIntro: "",
      teamName: "",
    },
  });
  const { watch, handleSubmit, reset } = form;

  const { setModal } = useModalStore();
  const queryClient = useQueryClient();
  const storyInfoMutation = useMutation(
    isUpdate ? storyInfoUpdate : storyInfo,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(["story", id]);
        toggleOff();
        reset();
        setModal({
          title: "필수 양식 작성하기",
          content: <SuccessContent />,
        });
      },
      onError: () => {
        setModal({
          title: "필수 양식 작성하기",
          content: <ErrorContent />,
        });
      },
    }
  );

  const isActive = useMemo(() => checkRequired(required, watch()), [watch()]);

  const submit = (data) => {
    isActive &&
      storyInfoMutation.mutate({
        id,
        data: {
          musicName: data.musicName,
          musicIntro: data.musicIntro,
          teamName: data.teamName,
        },
      });
  };

  return {
    form,
    isActive,
    handleSubmit: handleSubmit(submit),
  };
};

export default useInfoUpdate;
