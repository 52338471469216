import Box from "component/atoms/box";
import Button from "component/atoms/button";
import Image from "component/atoms/image";
import Line from "component/atoms/line";
import Link from "component/atoms/link";
import Text from "component/atoms/text";
import CommonButton from "component/molecules/common/commonButton";
import useToggle from "hook/util/useToggle";
import useUrl from "hook/util/useUrl";
import { useEffect, useRef } from "react";
import useDeviceStore from "store/useDeviceStore";
import convertImage from "utils/convertImage";

function addHttpsUsingRegex(url) {
  if (/^www\./.test(url)) {
    return "https://" + url;
  }
  return url;
}

const Profile = (props) => {
  const {
    memberName,
    memberIntro,
    instagramLink,
    soundCloudLink,
    otherSocialLink,
    youtubeLink,
    memberProfile,
    artistReg,
    isYou,
    isArtist,
  } = props;
  const infoRef = useRef();
  const { toggle, handleToggle } = useToggle();
  const { pc } = useDeviceStore();
  const { changeQuery } = useUrl();

  const height = pc ? 70 : 54;

  useEffect(() => {
    if (!toggle) {
      const clientHeight = infoRef.current.clientHeight;

      if (clientHeight > height) {
        handleToggle(true);
      }
    }
  }, [height]);

  return (
    <Box
      typo={{
        size: "0",
      }}
    >
      <Box
        size={{
          width: "100%",
          height: "200px",
        }}
        position={{
          type: "absolute",
          top: "83px",
          left: "0",
        }}
        background={{
          image: "/asset/img/mypage/banner_pc.svg",
        }}
        mobile={{
          size: {
            width: "100%",
            height: "150px",
          },
          background: {
            image: "/asset/img/mypage/banner_m.svg",
          },
          position: {
            top: "41px",
          },
        }}
      />

      <Box
        display={{
          display: "flex",
          justify: "space-between",
        }}
        position={{
          type: "relative",
        }}
        padding={{
          top: "65px",
        }}
        mobile={{
          padding: {
            top: "75px",
          },
        }}
      >
        <Box
          display={{
            display: "flex",
            align: "center",
            justify: "space-between",
          }}
          size={{
            width: "100%",
          }}
          mobile={{
            display: {
              justify: "center",
            },
          }}
        >
          <Box
            display={{
              display: "flex",
              wrap: "wrap",
              gap: "10px",
            }}
            size={{
              width: "20%",
            }}
            mypage={{
              margin: {
                top: "10px",
              },
            }}
            longMobile={{
              margin: {
                top: "75px",
              },
            }}
            mobile={{
              display: {
                display: "none",
              },
              margin: {
                top: "0",
              },
            }}
          >
            {isArtist && (
              <CommonButton
                category={"button"}
                size={{
                  width: "72px",
                  height: "28px",
                }}
                typo={{
                  size: "14px",
                }}
                isActive
              >
                아티스트
              </CommonButton>
            )}

            <CommonButton
              category={"button"}
              size={{
                width: "60px",
                height: "28px",
              }}
              typo={{
                size: "14px",
              }}
              isActive
            >
              창안자
            </CommonButton>

            {isYou && artistReg && (
              <CommonButton
                category={"button"}
                size={{
                  width: "127px",
                  height: "28px",
                }}
                typo={{
                  size: "14px",
                }}
                isActive
              >
                아티스트 신청대기
              </CommonButton>
            )}
          </Box>
          <Box
            size={{
              width: "580px",
            }}
            display={{
              display: "flex",
              direction: "column",
              align: "center",
            }}
          >
            <Image
              border={{
                all: "3px",
                radius: "50%",
              }}
              background={{
                color: "white01",
              }}
              size={{
                width: "270px",
                height: "270px",
              }}
              mobile={{
                size: {
                  width: "140px",
                  height: "140px",
                },
              }}
              src={convertImage(memberProfile)}
            />

            <Text
              typo={{
                size: "24px",
                weight: "700",
              }}
              margin={{
                top: "20px",
                bottom: "28px",
              }}
              mobile={{
                typo: {
                  size: "16px",
                },
                margin: {
                  top: "15px",
                  bottom: "10px",
                },
              }}
            >
              {memberName}
              <Text
                as={"span"}
                typo={{
                  size: "18px",
                  color: "gray04",
                }}
                margin={{
                  left: "5px",
                }}
                mobile={{
                  typo: {
                    size: "14px",
                  },
                }}
              >
                님
              </Text>
            </Text>

            <Box
              display={{
                display: "none",
                gap: "10px",
              }}
              mobile={{
                display: {
                  display: "flex",
                  wrap: "wrap",
                },
                margin: {
                  bottom: "15px",
                },
              }}
            >
              {isArtist && (
                <CommonButton
                  category={"button"}
                  size={{
                    width: "57px",
                    height: "23px",
                  }}
                  typo={{
                    size: "10px",
                  }}
                  isActive
                >
                  아티스트
                </CommonButton>
              )}
              <CommonButton
                category={"button"}
                size={{
                  width: "48px",
                  height: "23px",
                }}
                typo={{
                  size: "10px",
                }}
                isActive
              >
                창안자
              </CommonButton>
              {isYou && artistReg && (
                <CommonButton
                  category={"button"}
                  size={{
                    width: "127px",
                    height: "23px",
                  }}
                  typo={{
                    size: "10px",
                  }}
                  isActive
                >
                  아티스트 신청대기
                </CommonButton>
              )}
              {isYou && (
                <CommonButton
                  category={"button"}
                  size={{
                    width: "74px",
                    height: "23px",
                  }}
                  typo={{
                    size: "10px",
                  }}
                  onClick={() => changeQuery({ category: "edit" }, ["detail"])}
                >
                  <Image
                    src={"/asset/img/mypage/pen.png"}
                    size={{
                      width: "12px",
                      height: "12px",
                    }}
                    margin={{
                      right: "10px",
                    }}
                  />
                  수정하기
                </CommonButton>
              )}
            </Box>

            <Text
              size={{
                width: "100%",
              }}
              typo={{
                size: "14px",
                color: "gray04",
                wordBreak: "break-all",
                whiteSpace: "pre-line",
                align: "center",
                lineClamp: toggle ? (pc ? 4 : 3) : false,
              }}
              ref={infoRef}
            >
              {memberIntro}
            </Text>

            {toggle && (
              <Button
                typo={{
                  size: "14px",
                  weight: "400",
                  color: "gray02",
                }}
                background={{
                  color: "transparent",
                }}
                onClick={handleToggle}
              >
                자세히보기
              </Button>
            )}

            <Box
              display={{
                display: "flex",
                gap: "20px",
              }}
              margin={{
                top: "30px",
              }}
              mobile={{
                margin: {
                  top: "6px",
                },
              }}
            >
              {soundCloudLink && (
                <Link
                  target={"_blank"}
                  link={addHttpsUsingRegex(soundCloudLink)}
                >
                  <Image
                    src={"/asset/img/mypage/sound.svg"}
                    size={{
                      width: "50px",
                      height: "50px",
                    }}
                  />
                </Link>
              )}
              {instagramLink && (
                <Link
                  target={"_blank"}
                  link={addHttpsUsingRegex(instagramLink)}
                >
                  <Image
                    src={"/asset/img/mypage/insta.svg"}
                    size={{
                      width: "50px",
                      height: "50px",
                    }}
                  />
                </Link>
              )}
              {youtubeLink && (
                <Link target={"_blank"} link={addHttpsUsingRegex(youtubeLink)}>
                  <Image
                    src={"/asset/img/mypage/youtube.svg"}
                    size={{
                      width: "50px",
                      height: "50px",
                    }}
                  />
                </Link>
              )}
              {otherSocialLink && (
                <Link
                  target={"_blank"}
                  link={addHttpsUsingRegex(otherSocialLink)}
                >
                  <Image
                    src={"/asset/img/mypage/etc.svg"}
                    size={{
                      width: "50px",
                      height: "50px",
                    }}
                  />
                </Link>
              )}
            </Box>
          </Box>
          <Box
            display={{
              display: "flex",
              justify: "flex-end",
            }}
            size={{
              width: "20%",
            }}
            mobile={{
              display: {
                display: "none",
              },
              margin: {
                top: "0",
              },
            }}
            mypage={{
              margin: {
                top: "-27px",
              },
            }}
            longMobile={{
              margin: {
                top: "36px",
              },
            }}
          >
            {isYou && (
              <CommonButton
                category={"button"}
                size={{
                  width: "94px",
                  height: "28px",
                }}
                typo={{
                  size: "14px",
                }}
                onClick={() => changeQuery({ category: "edit" }, ["detail"])}
              >
                <Image
                  src={"/asset/img/mypage/pen.png"}
                  size={{
                    width: "12px",
                    height: "12px",
                  }}
                  margin={{
                    right: "10px",
                  }}
                />
                수정하기
              </CommonButton>
            )}
          </Box>
        </Box>
      </Box>

      <Line
        direction="row"
        size={"100%"}
        margin={{
          top: "20px",
        }}
        background={{
          color: "black01",
        }}
      />
    </Box>
  );
};

export default Profile;
