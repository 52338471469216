import { useQuery, useQueryClient, useMutation } from "react-query";
import { approve, getGradeList, pending } from "api/admin/user/gradeManage";
import { useNavigate } from "react-router-dom";

export const useAdminGradeList = (
  state,
  startDate,
  endDate,
  total,
  memberEmail,
  memberName,
  portfolioName,
  pageSize,
  currentPage
) => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    [
      "adminGradeList",
      state,
      startDate,
      endDate,
      total,
      memberEmail,
      memberName,
      portfolioName,
      pageSize,
      currentPage,
    ],
    () =>
      getGradeList(
        state,
        startDate,
        endDate,
        total,
        memberEmail,
        memberName,
        portfolioName,
        pageSize,
        currentPage
      ),
    {
      onError: (error) => {
        if (error.response && error.response.status === 403) {
          alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
          navigate("/admin/login");
        }
      },
    }
  );
  return {
    data: data?.data,
    totalItems: data?.totalItems,
    totalPages: data?.totalPages,
    isLoading,
  };
};

// 등업 승인
export const useAdminGradeApprove = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const { mutate, data } = useMutation(({ memberId }) => approve(memberId), {
    onSuccess: () => {
      queryClient.invalidateQueries("adminGradeList");
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
    },
    onError: (error) => {
      if (onError && typeof onError === "function") {
        onError(error);
      }
    },
  });
  return { mutate, data };
};

// 등업 보류
export const useAdminGradePending = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const { mutate, data } = useMutation(
    ({ memberId, rejectReason }) => pending(memberId, rejectReason),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("adminGradeList");
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess();
        }
      },
      onError: (error) => {
        if (onError && typeof onError === "function") {
          onError(error);
        }
      },
    }
  );
  return { mutate, data };
};
