import Box from "component/atoms/box";
import Blank from "component/molecules/common/blank";
import List from "component/molecules/common/list";
import SortFilterSelect from "component/molecules/detail/sortFilterSelect";
import LineTitle from "component/organisms/common/lineTitle";
import StoryCard from "component/organisms/common/storyCard";
import useMyStory from "hook/api/mypage/myStory";
import useStoryList from "hook/api/story/list";
import useUrl from "hook/util/useUrl";
import useSignedStore from "store/useSignedStore";

const option = [
  {
    label: "전체",
    value: "전체",
  },
  {
    label: "창안중",
    value: "창안중",
  },
  {
    label: "창작중",
    value: "창작중",
  },
  {
    label: "창작완료",
    value: "창작완료",
  },
  // {
  //   label: "후원중",
  //   value: "후원중",
  // },
  {
    label: "후원성공",
    value: "후원성공",
  },
  {
    label: "발매성공",
    value: "발매성공",
  },
];
const Story = (props) => {
  const { title } = props;
  const {
    params: { id },
    query: { page = 1, sort = "all" },
  } = useUrl();
  const { user } = useSignedStore();

  const memberId = id ?? user?.memberId;

  return (
    <>
      <LineTitle
        typo={{
          size: "18px",
        }}
        title={title}
      >
        <SortFilterSelect name="sort" options={option} />
      </LineTitle>

      <List
        api={useMyStory}
        params={{
          page,
          id: memberId,
          state: sort,
        }}
      >
        {(list) =>
          list && (
            <>
              {list.length === 0 && <Blank title={"조회 결과가 없습니다."} />}
              {list.length !== 0 && (
                <Box theme={"storyGrid"}>
                  {list.map((item) => (
                    <StoryCard {...item} key={item.id} />
                  ))}
                </Box>
              )}
            </>
          )
        }
      </List>
    </>
  );
};

export default Story;
