import PropTypes from "prop-types";

import Select from "component/atoms/select";
import Option from "component/atoms/option";
import useUrl from "hook/util/useUrl";
import { useEffect, useState } from "react";

const SortFilterSelect = (props) => {
  const { name, options } = props;
  const [select, setSelect] = useState(options[0].value);
  const { query, changeQuery } = useUrl();
  const currentSort = query[name];

  const handleChangeSort = (e) => {
    const value = e.target.value;
    setSelect(value);
    changeQuery({
      [name]: value,
      page: 1,
    });
  };

  useEffect(() => {
    if (currentSort) {
      setSelect(currentSort);
    }
  }, [currentSort]);

  return (
    <Select
      name={name}
      size={{
        width: "130px",
        height: "40px",
      }}
      padding={{
        all: "0 12px",
      }}
      margin={{
        left: "20px",
      }}
      border={{
        all: "1px",
        style: "solid",
        color: "black",
      }}
      typo={{
        size: "12px",
      }}
      longMobile={{
        size: {
          width: "80px",
          height: "35px",
        },
      }}
      onChange={handleChangeSort}
      value={select}
    >
      {options.map((option) => (
        <Option key={option.value} value={option.value}>
          {option.label}
        </Option>
      ))}
    </Select>
  );
};

SortFilterSelect.propTypes = {
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
};

export default SortFilterSelect;
