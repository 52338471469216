import { myInfo } from "api/mypage";
import { useQuery } from "react-query";

const useMyInfo = () => {
  const { data, isLoading, isError, isSuccess } = useQuery("myInfo", myInfo);

  return {
    data: data?.data,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useMyInfo;
