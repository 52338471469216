import Box from "component/atoms/box";
import Button from "component/atoms/button";
import Text from "component/atoms/text";
import LineTitle from "component/organisms/common/lineTitle";
import { GrClose } from "react-icons/gr";

const AdminModal = (props) => {
  const {
    children,
    title,
    width,
    info,
    visible,
    clearPadding,
    isActive,
    isLogin,
    isCoin,
    //
    onClose,
  } = props;

  return (
    <Box
      position={{
        type: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        zIndex: "999",
      }}
      display={{
        display: visible ? "flex" : "none",
        justify: "center",
        align: "center",
      }}
      background={{
        color: "rgba(0, 0, 0, 0.3)",
      }}
      mobile={{
        padding: {
          all: "30px",
        },
      }}
      onClick={onClose}
    >
      <Box
        animation={"modalPopOpen"}
        position={{
          type: "relative",
        }}
        size={{
          minWidth: width ?? "418px",
        }}
        padding={{
          all: clearPadding ? "0px" : "28px",
        }}
        background={{
          color: isActive ? "pink02" : "white01",
        }}
        border={{
          radius: "10px",
        }}
        other={{
          boxShadow: "4px 4px 37px 0px rgba(0, 0, 0, 0.25)",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Button
          position={{
            type: "absolute",
            right: "15px",
            top: "15px",
          }}
          background={{
            color: "transparent",
          }}
          typo={{
            size: "0",
          }}
          mobile={{
            position: {
              top: "5px",
            },
          }}
          onClick={onClose}
        >
          <GrClose size={16} />
        </Button>
        <Box
          display={{
            display: "flex",
            direction: "column",
          }}
        >
          <LineTitle
            size={{
              width: isLogin ? "100%" : "auto",
            }}
            typo={{
              size: "16px",
              weight: "700",
            }}
            margin={{
              top: "10px",
              bottom: "-25px",
              left: "2px",
            }}
            title={title}
          />
          <Text
            margin={{
              top: isLogin ? "0px" : "9px",
              bottom: isCoin ? "4px" : "14px",
              left: "2px",
            }}
          >
            {info}
          </Text>
        </Box>
        {children}
      </Box>
    </Box>
  );
};

export default AdminModal;
