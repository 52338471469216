import React from "react";
import Box from "component/atoms/box";

const HoverBox = (props) => {
  const { children, isHover } = props;
  return (
    <Box
      size={{
        width: "389px",
        height: "60px",
      }}
      display={{
        display: isHover ? "block" : "none",
        justify: "center",
        align: "center",
      }}
      position={{
        type: "absolute",
      }}
      margin={{
        left: "55px",
        top: "85px",
      }}
      padding={{ top: "13px", left: "14px" }}
      background={{
        color: "white01",
      }}
      border={{
        all: "1px",
        type: "solid",
        color: "gray02",
      }}
      other={{
        boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.15)",
      }}
    >
      {children}
    </Box>
  );
};

export default HoverBox;
