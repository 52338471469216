import { requestCancel } from "api/mypage";
import Text from "component/atoms/text";
import { ErrorContent } from "component/organisms/common/globalModal";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";

const SuccessContent = () => {
  return (
    <Text
      typo={{
        size: "18px",
        weight: "700",
        align: "center",
      }}
    >
      창작 요청이 취소되었습니다.
    </Text>
  );
};

const useRequestCancel = (toggle) => {
  const { setModal } = useModalStore();
  const queryClient = useQueryClient();
  const cancelMutation = useMutation(requestCancel, {
    onSuccess: () => {
      toggle();
      queryClient.invalidateQueries("requestList");
      setModal({
        title: "창작 요청",
        content: <SuccessContent />,
      });
    },
    onError: () => {
      setModal({
        title: "창작 요청",
        content: <ErrorContent />,
      });
    },
  });

  const submit = (id, type) => {
    cancelMutation.mutate({ id, type });
  };

  return submit;
};

export default useRequestCancel;
