import { useState } from "react";

const useInput = (defaultValues = "") => {
  const [value, setValue] = useState(defaultValues);

  const onChange = (e) => {
    setValue(e.target.value);
  };

  const reset = () => {
    setValue("");
  };

  return {
    value,
    setValue,
    onChange,
    reset,
  };
};

export default useInput;
