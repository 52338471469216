import { terms } from "api/main";
import { useQuery } from "react-query";

const useTerms = () => {
  const { data, isLoading, isSuccess, isError } = useQuery("terms", terms);

  return {
    data: data?.data,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useTerms;
