import Box from "component/atoms/box";
import Input from "component/atoms/input";
import RowTable from "component/atoms/rowSideTable";
import RowTableData from "component/atoms/rowTableData";
import RowTableHead from "component/atoms/rowTableHead";
import Text from "component/atoms/text";
import React, { useState, useEffect } from "react";
import ImageInput from "../../imageInput";
import AdminRadio from "component/molecules/admin/adminRadio";
import AdminCheckbox from "component/molecules/adminCheckbox";
import AdminButton from "component/molecules/admin/button/adminButton";
import { useAdminStoryUpdate } from "hook/api/admin/story/storyManage";
import { useForm } from "react-hook-form";
import convertImage from "utils/convertImage";
import AdminAlertModal from "../../modal/alertModal";
import useEditStore from "store/useEditStore";
import dayjs from "dayjs";
import ReactAudioPlayer from "react-audio-player";
import { useRef } from "react";

// 카테고리 배열
const categoryList = [
  "#사랑",
  "#우정",
  "#학교",
  "#가족",
  "#직장",
  "#웨딩",
  "#학문",
  "#환경",
  "#동식물",
  "#건강",
  "#음악",
  "#음식",
  "#꿈",
  "#가치관",
  "#일상",
  "#단체",
  "#BGM",
  "#여행",
  "#관계",
  "#기타",
];
// 분위기 배열
const moodList = [
  "잔잔한",
  "리드미컬",
  "신나는",
  "강렬한",
  "빠른",
  "무거운",
  "어두운",
  "재미있는",
  "힙한비트",
  "몽환적인",
  "춤추는",
  "나른한",
  "클래식한",
  "밝은",
  "느린",
  "부드러운",
  "편안한",
  "귀여운",
  "슬픈",
  "기타",
];
// 성격 배열
const characterList = [
  "조용한",
  "이성적인",
  "혼자좋아",
  "신중한",
  "세심한",
  "부지런한",
  "쿨한",
  "까칠한",
  "활발한",
  "이상적인",
  "사람좋아",
  "자유로운",
  "대범한",
  "여유로운",
  "웃긴",
  "기타",
];

const baseURL = process.env.REACT_APP_SERVER_URL;

const StoryDetailEdit = (props) => {
  const { data } = props;

  const { updateEdit, clearEdit } = useEditStore();
  const { register, watch, handleSubmit, setValue, getValues } = useForm({
    defaultValues: {
      ...data,
      pickOk: data.displayOptions?.pickOk,
      tourOk: data.displayOptions?.tourOk,
      deleteThumbnail: false,
    },
  });

  const [save, setSave] = useState(false);
  const handleClear = () => {
    clearEdit();
    setSave(true);
  };
  const adminStoryUpdate = useAdminStoryUpdate(data.storyId, handleClear);

  const {
    displayOptions,
    moomoostPick,
    storyCategory,
    storyMood,
    storyOpen,
    storyPersonality,
    storyStatus,
    storyThumbnail,
    creation,
    pickOk,
    tourOk,
  } = watch();

  const storyTitle = watch("storyTitle");
  const storySummary = watch("storySummary");
  const personaLike = watch("personaLike");
  const personaDislike = watch("personaDislike");
  const personaName = watch("personaName");

  // 저장 알림 모달창 상태 관리
  const [visible, setVisible] = useState(false);

  // 순서변경 버튼 상태 관리
  const [edit, setEdit] = useState(false);

  // 순서 변경 저장 버튼 클릭 시
  const handleSave = () => {
    setVisible(true);
    setEdit(!edit);
  };

  // 배너 등록 저장 버튼 클릭 시
  const handleStorySave = () => {
    if (isDone === false) {
      alert("필수 입력 항목을 모두 입력해주세요.");
      return;
    }

    if (window.confirm("스토리를 수정하시겠습니까?")) {
      handleSubmit(adminStoryUpdate)();
    }
  };

  const handleEdit = () => {
    setValue("storyThumbnail", "");
    setValue("deleteThumbnail", true);
    updateEdit();
  };

  const handleMoomoostPick = (value) => () => {
    setValue("moomoostPick", value);

    if (!value) {
      setValue("pickOk", false);
    }
    updateEdit();
  };

  const handleDisplayOptions = (name) => () => {
    const value = !getValues(name);
    const storyOpen = getValues("storyOpen");

    if ((storyOpen === 0 && value === true) || value === false) {
      setValue(name, value);
      updateEdit();
    } else {
      alert("비공개 중인 이야기는 메인페이지에 노출할 수 없습니다.");
    }
  };

  const handleStoryOpen = (e) => {
    if (pickOk === true || tourOk === true) {
      if (e.target.value === "1") {
        alert("진열 중인 이야기는 비공개로 변경할 수 없습니다.");
        return;
      }
    }
    setValue("storyOpen", Number(e.target.value));
    updateEdit();
  };

  const handleStoryCategory = (value) => () => {
    setValue("storyCategory[0]", value);
    updateEdit();
  };

  const handleStoryMood = (value) => () => {
    const mood = [...storyMood];

    if (mood.includes(value)) {
      const index = mood.indexOf(value);
      mood.splice(index, 1);
    } else {
      mood.push(value);
    }

    if (mood.length > 2) {
      mood.shift();
    }

    setValue("storyMood", mood);
    updateEdit();
  };

  const handleStoryPersonality = (value) => () => {
    const personality = [...storyPersonality];

    if (personality.includes(value)) {
      const index = personality.indexOf(value);
      personality.splice(index, 1);
    } else {
      personality.push(value);
    }

    setValue("storyPersonality", personality);
    updateEdit();
  };

  const handleStoryStatus = (e) => {
    setValue("storyStatus", e.target.value);
    updateEdit();
  };

  const handleThumbnail = (file) => {
    setValue("storyThumbnail", file);
    updateEdit();
  };

  // 저장 버튼 비활성화
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    // 필수 입력값들의 유효성 검사
    const isTitleValid = storyTitle.trim().length > 0;
    const isSummaryValid = storySummary.trim().length > 0;
    const isLikesValid = personaLike.trim().length > 0;
    const isDislikesValid = personaDislike.trim().length > 0;
    const isCategoryValid = storyCategory.length > 0;
    const isMoodValid = storyMood.length > 0;
    const isPersonalityValid = storyPersonality.length > 0;
    const isPersonaNameValid = personaName.trim().length > 0;

    // 모든 조건이 true이면 isDone을 true로 설정
    setIsDone(
      isTitleValid &&
        isSummaryValid &&
        isLikesValid &&
        isDislikesValid &&
        isCategoryValid &&
        isMoodValid &&
        isPersonalityValid &&
        isPersonaNameValid
    );
  }, [
    storyTitle,
    storySummary,
    personaLike,
    personaDislike,
    storyCategory,
    storyMood,
    storyPersonality,
    personaName,
  ]);

  return (
    <Box
      size={{
        width: "100%",
        height: "auto",
      }}
      margin={{
        top: "25px",
      }}
      other={{
        overflowX: "hidden",
      }}
    >
      <Text theme={"adminTitle"}>이야기 수정</Text>
      <RowTable>
        <Box
          theme="adminListBox"
          padding={{
            left: "21px",
          }}
        >
          <Text
            typo={{
              size: "12px",
              weight: "700",
              color: "pink03",
            }}
          >
            * 은 필수 입력 항목입니다.
          </Text>
        </Box>

        <Box
          display={{
            width: "100%",
            height: "88px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="88px">
            <Box
              display={{
                display: "flex",
              }}
              size={{
                width: "100%",
                height: "100%",
              }}
            >
              분류 옵션
            </Box>
          </RowTableHead>

          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
          >
            <RowTable
              size={{
                width: "395px",
                height: "72px",
              }}
              margin={{ top: "8px" }}
            >
              <Box
                display={{
                  width: "395px",
                  height: "72px",
                  display: "flex",
                  direction: "row",
                  align: "flex-start",
                  justify: "flex-start",
                }}
              >
                <RowTableHead width="198px" height="36px" isLastChild>
                  기획전 등록
                </RowTableHead>
                <RowTableData
                  size={{
                    width: "calc(395px - 198px)",
                  }}
                  isLastChild
                >
                  <AdminCheckbox
                    margin={{
                      top: "10px",
                      right: "5px",
                    }}
                    onClick={handleMoomoostPick(true)}
                    checked={moomoostPick}
                  >
                    <Text
                      typo={{
                        size: "13px",
                        weight: "400",
                        color: "black02",
                      }}
                      margin={{
                        top: "8px",
                        left: "3px",
                      }}
                    >
                      등록함
                    </Text>
                  </AdminCheckbox>
                </RowTableData>
              </Box>
              <Box
                display={{
                  width: "395px",
                  height: "72px",
                  display: "flex",
                  direction: "row",
                  align: "flex-start",
                  justify: "flex-start",
                }}
              >
                <RowTableHead width="198px" height="33px">
                  이야기 등록
                </RowTableHead>
                <RowTableData
                  size={{
                    width: "calc(395px - 198px)",
                  }}
                >
                  <AdminCheckbox
                    margin={{
                      top: "10px",
                      right: "5px",
                    }}
                    onClick={handleMoomoostPick(false)}
                    checked={!moomoostPick}
                  >
                    <Text
                      typo={{
                        size: "13px",
                        weight: "400",
                        color: "black02",
                      }}
                      margin={{
                        top: "8px",
                        left: "3px",
                      }}
                    >
                      등록함
                    </Text>
                  </AdminCheckbox>
                </RowTableData>
              </Box>
            </RowTable>
          </RowTableData>
        </Box>
        <Box
          display={{
            width: "100%",
            height: "88px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="88px">
            <Box
              display={{
                display: "flex",
              }}
              size={{
                width: "100%",
                height: "100%",
              }}
            >
              진열 옵션
            </Box>
          </RowTableHead>

          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
          >
            <RowTable
              size={{
                width: "395px",
                height: "72px",
              }}
              margin={{ top: "8px" }}
            >
              <Box
                display={{
                  width: "395px",
                  height: "72px",
                  display: "flex",
                  direction: "row",
                  align: "flex-start",
                  justify: "flex-start",
                }}
              >
                <RowTableHead width="198px" height="36px" isLastChild>
                  메인페이지 - 기획전
                </RowTableHead>
                <RowTableData
                  size={{
                    width: "calc(395px - 198px)",
                  }}
                  isLastChild
                >
                  <AdminCheckbox
                    margin={{
                      top: "10px",
                      right: "5px",
                    }}
                    readOnly={!moomoostPick}
                    onClick={handleDisplayOptions("pickOk")}
                    checked={pickOk}
                  >
                    <Text
                      typo={{
                        size: "13px",
                        weight: "400",
                        color: "black02",
                      }}
                      margin={{
                        top: "8px",
                        left: "3px",
                      }}
                    >
                      진열함
                    </Text>
                  </AdminCheckbox>
                </RowTableData>
              </Box>
              <Box
                display={{
                  width: "395px",
                  height: "72px",
                  display: "flex",
                  direction: "row",
                  align: "flex-start",
                  justify: "flex-start",
                }}
              >
                <RowTableHead width="198px" height="34px">
                  메인페이지 - 이야기둘러보기
                </RowTableHead>
                <RowTableData
                  size={{
                    width: "calc(395px - 198px)",
                  }}
                >
                  <AdminCheckbox
                    margin={{
                      top: "10px",
                      right: "5px",
                    }}
                    onClick={handleDisplayOptions("tourOk")}
                    checked={tourOk}
                  >
                    <Text
                      typo={{
                        size: "13px",
                        weight: "400",
                        color: "black02",
                      }}
                      margin={{
                        top: "8px",
                        left: "3px",
                      }}
                    >
                      진열함
                    </Text>
                  </AdminCheckbox>
                </RowTableData>
              </Box>
            </RowTable>
          </RowTableData>
        </Box>
        <Box
          display={{
            width: "100%",
            height: "48px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="48px">
            공개여부
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
              height: "48px",
            }}
          >
            <Box
              display={{
                display: "flex",
              }}
              margin={{
                top: "13px",
              }}
            >
              <AdminRadio
                id="public"
                name="public_status"
                value={0}
                htmlFor="public"
                label="공개"
                onChange={handleStoryOpen}
                checked={storyOpen === 0}
              />
              <AdminRadio
                id="private"
                name="public_status"
                value={1}
                htmlFor="private"
                label="비공개"
                onChange={handleStoryOpen}
                checked={storyOpen === 1}
              />
            </Box>
          </RowTableData>
        </Box>
        {/* 제목 */}
        <Box
          display={{
            width: "100%",
            height: "48px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="48px">
            제목&nbsp;
            <Text
              typo={{
                size: "13px",
                weight: "700",
                color: "pink03",
              }}
            >
              *
            </Text>
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
            padding={{
              top: "8px",
              left: "8px",
            }}
          >
            <Input
              theme={"adminInput"}
              size={{
                width: "395px",
                height: "32px",
              }}
              {...register("storyTitle")}
              maxLength={40}
            />
            <Text
              margin={{ top: "14px", left: "5px" }}
              typo={{
                size: "13px",
                weight: "400",
                lineHeight: "normal",
                color: "gray04",
              }}
            >
              ({storyTitle.length}/40)
            </Text>
            {/* <Input as="radio" theme={"adminRadio"} name="search" value="id" /> */}
          </RowTableData>
        </Box>
        {/* 한줄요약 */}
        <Box
          display={{
            width: "100%",
            height: "48px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="48px">
            한줄요약&nbsp;
            <Text
              typo={{
                size: "13px",
                weight: "700",
                color: "pink03",
              }}
            >
              *
            </Text>
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
            padding={{
              top: "8px",
              left: "8px",
            }}
          >
            <Input
              theme={"adminInput"}
              size={{
                width: "771px",
                height: "32px",
              }}
              {...register("storySummary")}
              maxLength={45}
            />
            <Text
              margin={{ top: "14px", left: "5px" }}
              typo={{
                size: "13px",
                weight: "400",
                lineHeight: "normal",
                color: "gray04",
              }}
            >
              ({storySummary.length}/45)
            </Text>
          </RowTableData>
        </Box>

        {/* 카테고리 */}
        <Box
          display={{
            width: "100%",
            height: "58px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="58px">
            <Box>
              <Box
                display={{
                  display: "flex",
                }}
              >
                카테고리&nbsp;
                <Text
                  typo={{
                    size: "13px",
                    weight: "700",
                    color: "pink03",
                  }}
                >
                  *
                </Text>
              </Box>
              <Text
                typo={{
                  size: "12px",
                  weight: "400",
                  color: "pink03",
                }}
                margin={{
                  right: "6px",
                }}
              >
                (최대 1개)
              </Text>
            </Box>
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
            padding={{
              left: "8px",
              right: "70px",
            }}
          >
            {categoryList.map((item, idx) => (
              <AdminCheckbox
                margin={{
                  top: "8px",
                }}
                onClick={handleStoryCategory(item)}
                checked={storyCategory?.includes(item)}
              >
                <Text
                  typo={{
                    size: "13px",
                    weight: "400",
                    color: "black02",
                  }}
                  margin={{
                    top: "8px",
                    left: "5px",
                    right: "10px",
                  }}
                >
                  {item}
                </Text>
              </AdminCheckbox>
            ))}
          </RowTableData>
        </Box>
        {/* 분위기 */}
        <Box
          display={{
            width: "100%",
            height: "58px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="58px">
            <Box>
              <Box
                display={{
                  display: "flex",
                }}
              >
                분위기&nbsp;
                <Text
                  typo={{
                    size: "13px",
                    weight: "700",
                    color: "pink03",
                  }}
                >
                  *
                </Text>
              </Box>
              <Text
                typo={{
                  size: "12px",
                  weight: "400",
                  color: "pink03",
                }}
                margin={{
                  right: "6px",
                }}
              >
                (최대 2개)
              </Text>
            </Box>
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
            padding={{
              left: "8px",
              right: "30px",
            }}
          >
            {moodList.map((item, idx) => (
              <AdminCheckbox
                margin={{
                  top: "8px",
                }}
                onClick={handleStoryMood(item)}
                checked={storyMood?.includes(item)}
              >
                <Text
                  typo={{
                    size: "13px",
                    weight: "400",
                    color: "black02",
                  }}
                  margin={{
                    top: "8px",
                    left: "5px",
                    right: "10px",
                  }}
                >
                  {item}
                </Text>
              </AdminCheckbox>
            ))}
          </RowTableData>
        </Box>
        {/* 성격 */}
        <Box
          display={{
            width: "100%",
            height: "58px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="58px">
            성격&nbsp;
            <Text
              typo={{
                size: "13px",
                weight: "700",
                color: "pink03",
              }}
            >
              *
            </Text>
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
            padding={{
              left: "8px",
              right: "80px",
            }}
          >
            {characterList.map((item, idx) => (
              <AdminCheckbox
                margin={{
                  top: "8px",
                }}
                onClick={handleStoryPersonality(item)}
                checked={storyPersonality?.includes(item)}
              >
                <Text
                  typo={{
                    size: "13px",
                    weight: "400",
                    color: "black02",
                  }}
                  margin={{
                    top: "8px",
                    left: "5px",
                    right: "10px",
                  }}
                >
                  {item}
                </Text>
              </AdminCheckbox>
            ))}
          </RowTableData>
        </Box>
        {/* 좋아하는 것 */}
        <Box
          display={{
            width: "100%",
            height: "48px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="48px">
            좋아하는 것&nbsp;
            <Text
              typo={{
                size: "13px",
                weight: "700",
                color: "pink03",
              }}
            >
              *
            </Text>
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
            padding={{
              top: "8px",
              left: "8px",
            }}
          >
            <Input
              theme={"adminInput"}
              size={{
                width: "395px",
                height: "32px",
              }}
              maxLength={65}
              {...register("personaLike")}
            />
            <Text
              margin={{ top: "14px", left: "5px" }}
              typo={{
                size: "13px",
                weight: "400",
                lineHeight: "normal",
                color: "gray04",
              }}
            >
              ({personaLike.length}/65)
            </Text>
          </RowTableData>
        </Box>
        {/* 싫어하는 것 */}
        <Box
          display={{
            width: "100%",
            height: "48px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="48px">
            싫어하는 것&nbsp;
            <Text
              typo={{
                size: "13px",
                weight: "700",
                color: "pink03",
              }}
            >
              *
            </Text>
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
            padding={{
              top: "8px",
              left: "8px",
            }}
          >
            <Input
              theme={"adminInput"}
              size={{
                width: "395px",
                height: "32px",
              }}
              maxLength={65}
              {...register("personaDislike")}
            />
            <Text
              margin={{ top: "14px", left: "5px" }}
              typo={{
                size: "13px",
                weight: "400",
                lineHeight: "normal",
                color: "gray04",
              }}
            >
              ({personaDislike.length}/65)
            </Text>
            {/* <Input as="radio" theme={"adminRadio"} name="search" value="id" /> */}
          </RowTableData>
        </Box>
        {/* 페르소나 이름 */}
        <Box
          display={{
            width: "100%",
            height: "48px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="48px">
            페르소나 이름&nbsp;
            <Text
              typo={{
                size: "13px",
                weight: "700",
                color: "pink03",
              }}
            >
              *
            </Text>
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
            padding={{
              top: "8px",
              left: "8px",
            }}
          >
            <Input
              theme={"adminInput"}
              size={{
                width: "395px",
                height: "32px",
              }}
              maxLength={65}
              {...register("personaName")}
            />
            <Text
              margin={{ top: "14px", left: "5px" }}
              typo={{
                size: "13px",
                weight: "400",
                lineHeight: "normal",
                color: "gray04",
              }}
            >
              ({personaName.length}/65)
            </Text>
          </RowTableData>
        </Box>
        <Box
          display={{
            width: "100%",
            height: "304px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="285px">
            <Box
              display={{
                display: "flex",
              }}
              size={{
                width: "100%",
                height: "100%",
              }}
            >
              썸네일 등록
            </Box>
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
              height: "285px",
            }}
          >
            <ImageInput
              text1="- 권장 이미지 : 1080px*1080px / 10mb 이하 / jpg , png 파일"
              image={convertImage(storyThumbnail, true)}
              onChange={handleThumbnail}
            />
            <AdminButton
              category="button"
              theme={["grayColor"]}
              size={{
                width: "59px",
                height: "28px",
              }}
              border={{
                all: "1px",
                type: "solid",
                color: "gray04",
                radius: "3px",
              }}
              position={{
                type: "relative",
                top: "220px",
                right: "173px",
              }}
              onClick={handleEdit}
            >
              삭제
            </AdminButton>
          </RowTableData>
        </Box>
        <Box
          display={{
            width: "100%",
            height: "249px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="124px">
            <Box
              display={{
                display: "flex",
              }}
              size={{
                width: "100%",
                height: "100%",
              }}
              typo={{
                color: "pink03",
              }}
            >
              창작 완료
            </Box>
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
              height: "124px",
            }}
            padding={{
              left: "10px",
              bottom: "10px",
            }}
          >
            <RowTable
              size={{
                width: "833px",
                height: "108px",
              }}
              margin={{ top: "8px" }}
            >
              <Box
                display={{
                  width: "833px",
                  height: "108px",
                  display: "flex",
                  direction: "row",
                  align: "flex-start",
                  justify: "flex-start",
                }}
              >
                <RowTableHead width="198px" height="36px" isLastChild>
                  제목
                </RowTableHead>
                <RowTableData
                  size={{
                    width: "calc(833px - 198px)",
                  }}
                  isLastChild
                >
                  {creation.musicName ? (
                    <Text
                      typo={{
                        size: "13px",
                        weight: "400",
                        color: "black02",
                        lineClamp: 1,
                        wordBreak: "break-all",
                      }}
                      margin={{
                        top: "9px",
                        left: "3px",
                        right: "10px",
                      }}
                    >
                      {creation.musicName}
                    </Text>
                  ) : (
                    <Text
                      margin={{
                        top: "8px",
                      }}
                    >
                      -
                    </Text>
                  )}
                </RowTableData>
              </Box>
              <Box
                display={{
                  width: "833px",
                  height: "72px",
                  display: "flex",
                  direction: "row",
                  align: "flex-start",
                  justify: "flex-start",
                }}
              >
                <RowTableHead width="198px" height="36px">
                  창작 완료 일시
                </RowTableHead>
                <RowTableData
                  size={{
                    width: "calc(833px - 198px)",
                  }}
                >
                  {creation.createdAt ? (
                    <Text
                      typo={{
                        size: "13px",
                        weight: "400",
                        color: "black02",
                      }}
                      margin={{
                        top: "9px",
                        left: "3px",
                      }}
                    >
                      {dayjs(creation.createdAt).format("YYYY-MM-DD")}
                    </Text>
                  ) : (
                    <Text
                      margin={{
                        top: "8px",
                      }}
                    >
                      -
                    </Text>
                  )}
                </RowTableData>
              </Box>
              <Box
                display={{
                  width: "833px",
                  height: "72px",
                  display: "flex",
                  direction: "row",
                  align: "flex-start",
                  justify: "flex-start",
                }}
              >
                <RowTableHead width="198px" height="33px">
                  제출 음원
                </RowTableHead>
                <RowTableData
                  size={{
                    width: "calc(833px - 198px)",
                  }}
                >
                  {creation.musicFile ? (
                    <ReactAudioPlayer
                      style={{
                        width: "212px",
                        height: "25px",
                        marginTop: "4px",
                      }}
                      controls
                      src={`${baseURL}/${creation.musicFile}`}
                    >
                      재생이 불가능하거나 지원하지 않는 브라우저입니다.
                    </ReactAudioPlayer>
                  ) : (
                    <Text
                      margin={{
                        top: "8px",
                      }}
                    >
                      -
                    </Text>
                  )}
                </RowTableData>
              </Box>
            </RowTable>
          </RowTableData>
        </Box>
        <Box
          display={{
            width: "100%",
            height: "48px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="48px">
            <Text
              typo={{
                color: "pink03",
              }}
            >
              진행 상태 *
            </Text>
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
            padding={{
              top: "13px",
              left: "8px",
            }}
          >
            <Box
              size={{
                width: "100%",
                height: "100%",
              }}
              display={{
                display: "flex",
                justify: "flex-start",
                align: "center",
              }}
              margin={{
                left: "8px",
              }}
            >
              <AdminRadio
                id="waiting"
                name="status_category"
                value="창안중"
                htmlFor="waiting"
                label="창안중"
                checked={storyStatus === "창안중"}
                onClick={handleStoryStatus}
              />
              <AdminRadio
                id="ongoing"
                name="status_category"
                value="창작중"
                htmlFor="ongoing"
                label="창작중"
                checked={storyStatus === "창작중"}
                onClick={handleStoryStatus}
              />
              <AdminRadio
                id="complete"
                name="status_category"
                value="창작완료"
                htmlFor="complete"
                label="창작완료"
                checked={storyStatus === "창작완료"}
                onClick={handleStoryStatus}
              />
              <AdminRadio
                id="publish"
                name="status_category"
                value="발매완료"
                htmlFor="publish"
                label="발매완료"
                checked={storyStatus === "발매완료"}
                onClick={handleStoryStatus}
              />
              <AdminRadio
                id="holding"
                name="status_category"
                value="보류"
                htmlFor="holding"
                label="보류"
                checked={storyStatus === "보류"}
                onClick={handleStoryStatus}
              />
            </Box>
          </RowTableData>
        </Box>
      </RowTable>
      <Box
        size={{
          width: "100%",
          height: "auto",
        }}
        display={{
          display: "flex",
        }}
        margin={{
          top: "24px",
          left: "402px",
          bottom: "25px",
        }}
      >
        <AdminButton
          category="button"
          theme={["mainButton", "whiteColor"]}
          onClick={() => {
            window.open(`/story/${data.storyId}`, "_blank");
          }}
        >
          글보기
        </AdminButton>
        <AdminButton
          category="button"
          theme={isDone ? ["mainButton"] : ["grayColor"]}
          margin={{
            left: "10px",
          }}
          size={{
            width: "104px",
            height: "40px",
          }}
          padding={{
            top: "12px",
            bottom: "12px",
          }}
          onClick={handleStorySave}
        >
          저장
        </AdminButton>
      </Box>

      <AdminAlertModal
        title={"이야기 수정"}
        text="이야기 수정이 완료되었습니다."
        btn_text="확인"
        visible={save}
        toggle={() => {
          setSave(false);
        }}
      />
    </Box>
  );
};

export default StoryDetailEdit;
