import Box from "component/atoms/box";
import Text from "component/atoms/text";
import CalendarInput from "component/molecules/common/calendarInput";
import LabelText from "component/molecules/common/labelText";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

const convertCalendarName = (name) => {
  return [`${name}_start`, `${name}_end`];
};

const SingleCalendarField = (props) => {
  const {
    title,
    typo,
    required,
    info,
    size,
    //
    name,
  } = props;
  const { setValue, watch } = useFormContext();

  const handleChange = (name) => (date) => {
    setValue(name, date);
  };

  const range = watch(name);

  return (
    <>
      {(title || info || required) && (
        <LabelText title={title} required={required} info={info} typo={typo} />
      )}
      <Box
        display={{
          display: "flex",
          justify: "flex-start",
          align: "center",
        }}
        size={size}
      >
        <CalendarInput
          size={{
            width: "100%",
            height: "30px",
          }}
          value={range}
          onChange={handleChange(name)}
        />
      </Box>
    </>
  );
};

export default SingleCalendarField;
