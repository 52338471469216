import InputStyle from "./input.style";
import PropsType from "prop-types";
import { forwardRef } from "react";

const themeStyle = {
  adminLogin: {
    border: {
      all: "1px",
      style: "solid",
      color: "black01",
    },
  },

  adminInput: {
    border: {
      all: "1px",
      style: "solid",
      color: "gray10",
      radius: "3px",
    },
  },
};

const themeMerge = (theme) => {
  if (typeof theme === "string") {
    return themeStyle[theme];
  } else if (Array.isArray(theme)) {
    return theme.reduce((acc, cur) => {
      const updateTheme = themeStyle[cur];

      Object.entries(updateTheme).forEach(([key, value]) => {
        if (acc[key] === undefined) {
          acc[key] = { ...value };
        } else {
          acc[key] = { ...acc[key], ...value };
        }
      });

      return {
        ...acc,
      };
    }, {});
  }
};

const styleMerge = (style, theme) => {
  const updateStyle = { ...style };
  if (theme) {
    Object.entries(theme).forEach(([key, value]) => {
      const currentStyle = updateStyle[key] ?? {};
      updateStyle[key] = {
        ...value,
        ...currentStyle,
      };
    });
  }

  return updateStyle;
};

const Input = forwardRef((props, ref) => {
  const {
    as,
    type,
    hidden,
    readOnly,
    checked,
    accept,
    //
    theme,
    //
    maxLength,
    placeholder,
    name,
    value,
    onChange,
    onClick,
    onKeyPress,
  } = props;

  const currentTheme = themeMerge(theme);

  const {
    size,
    display,
    margin,
    padding,
    border,
    background,
    typo,
    other,
    longMobile,
    pc,
    tablet,
    mobile,
  } = styleMerge(props, currentTheme);

  return (
    <InputStyle
      ref={ref}
      //
      as={as}
      type={type}
      hidden={hidden}
      readOnly={readOnly}
      checked={checked}
      accept={accept}
      maxLength={maxLength}
      //
      $size={size}
      $display={display}
      $margin={margin}
      $padding={padding}
      $border={border}
      $background={background}
      $typography={typo}
      $other={other}
      $pc={pc}
      $tablet={tablet}
      $longMobile={longMobile}
      $mobile={mobile}
      //
      name={name}
      value={value}
      onChange={onChange}
      onClick={onClick}
      onKeyPress={onKeyPress}
      placeholder={placeholder}
    />
  );
});

export default Input;

Input.propTypes = {
  as: PropsType.oneOf(["input", "textarea", "radio", "checkbox"]),
  type: PropsType.oneOf(["text", "password", "file", "number", "email", "tel"]),
  hidden: PropsType.bool,
  readOnly: PropsType.bool,

  $width: PropsType.string,
  $height: PropsType.string,

  $margin: PropsType.string,
  $padding: PropsType.string,

  $border: PropsType.string,
  $borderRadius: PropsType.string,

  $fontSize: PropsType.string,
  $fontWeight: PropsType.string,

  $color: PropsType.string,
  $backgroundColor: PropsType.string,

  $placeholder: PropsType.string,

  autoComplete: PropsType.string,
  value: PropsType.string,
  onChange: PropsType.func,
};
