import React, { useState } from "react";
import AdminModal from "component/molecules/admin/adminModal";
import AdminButton from "component/molecules/admin/button/adminButton";
import Text from "component/atoms/text";
import Box from "component/atoms/box";
import Input from "component/atoms/input";
import useToggle from "hook/util/useToggle";

const AdminAlertModal = (props) => {
  const { title, text, children, btn_text, toggle, visible, login, onClick } =
    props;

  const handleClose = () => {
    toggle();
  };

  const handleButtonClick = () => {
    if (onClick) {
      onClick();
    } else {
      handleClose();
    }
  };

  return (
    <AdminModal
      visible={visible}
      onClose={handleClose}
      title={title}
      isLogin={login ? true : false}
      info={
        <Text
          size={{
            width: login ? "362px" : "100%",
          }}
          typo={{
            size: "14px",
            weight: "400",
            color: "black01",
          }}
          margin={{
            top: "14px",
          }}
        >
          {text}
        </Text>
      }
    >
      {children}
      <AdminButton
        category="button"
        size={{
          width: "362px",
          height: "40px",
        }}
        onClick={handleButtonClick}
      >
        {btn_text}
      </AdminButton>
    </AdminModal>
  );
};

export default AdminAlertModal;
