import { portfolio } from "api/mypage";
import { useQuery } from "react-query";

const usePortfolio = ({ id, page }) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["portfolio", id, page],
    () => portfolio(id, page)
  );

  const list = data?.data;
  const maxPage = data?.totalPages;

  return {
    list,
    maxPage,
    isLoading,
    isError,
    isSuccess,
  };
};

export default usePortfolio;
