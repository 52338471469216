import { useQuery } from "react-query";
import {
  newMember,
  artist,
  completion,
  latest,
  today,
} from "api/admin/dashboard";
import { useNavigate } from "react-router-dom";

export const useAdminNewMember = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery("adminNewMember", newMember, {
    onError: (error) => {
      if (error.response && error.response.status === 403) {
        alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
        navigate("/admin/login");
      }
    },
  });

  return { data, isLoading };
};

export const useAdminArtist = () => {
  const { data, isLoading } = useQuery("adminArtist", artist);
  return { data, isLoading };
};

export const useAdminCompletion = () => {
  const { data, isLoading } = useQuery("adminCompletion", completion);
  return { data, isLoading };
};

export const useAdminLatest = () => {
  const { data, isLoading } = useQuery("adminLatest", latest);
  return { data: data?.data, totalItems: data?.totalItems, isLoading };
};

export const useGetToday = () => {
  const { data, isLoading } = useQuery("adminToday", today);
  return { data, isLoading };
};
