import React, { useState } from "react";
import Box from "component/atoms/box";
import Image from "component/atoms/image";
import Text from "component/atoms/text";
import Link from "component/atoms/link";
import useUrl from "hook/util/useUrl";
import AdminButton from "component/molecules/admin/button/adminButton";
import ProfileHoverBox from "component/organisms/admin/profileHoverBox";
import { useEffect } from "react";

const AdminHeader = (props) => {
  const location = useUrl();
  const { link } = props;

  // 프로필 호버 시 로그아웃 버튼 보이기
  const [isOpen, setIsOpen] = useState(false);

  const handleProfileClick = (e) => {
    e.stopPropagation();

    setIsOpen(!isOpen);
  };

  const handleOuterClick = () => {
    setIsOpen(false);
  };

  const getPath = [
    {
      path: "/admin/setup",
      title: "환경설정",
    },
    {
      path: "/admin/manage/user",
      title: "회원 관리",
    },
    {
      path: "/admin/manage/story",
      title: "이야기 관리",
    },
    {
      path: "/admin/manage/banner",
      title: "배너・팝업 관리",
    },
    {
      path: "/admin/dashboard",
      title: "대시보드",
    },
    {
      path: "/admin/manage/user/detail",
      title: "회원 관리",
    },
    {
      path: "/admin/manage/story/detail",
      title: "이야기 관리",
    },
  ];

  const settingTitle = getPath.find(({ path }) =>
    location.pathname.startsWith(path)
  );

  const isDetailPage = location.pathname.includes("detail");

  return (
    <Box
      display={{
        display: "flex",
        justify: "space-between",
        align: "center",
      }}
      size={{
        width: "100%",
        height: "68px",
      }}
      border={{
        bottom: "1px",
        type: "solid",
        color: "gray10",
      }}
      other={{
        overflow: "hidden",
      }}
    >
      <Text
        typo={{
          size: "20px",
          weight: "700",
        }}
        margin={{
          left: "24px",
        }}
      >
        {settingTitle.title}
      </Text>

      {!isDetailPage && (
        <Box
          display={{
            display: "flex",
            align: "center",
          }}
        >
          <AdminButton
            category="link"
            theme="headerButton"
            margin={{
              right: "15px",
            }}
            target="_blank"
            link="/"
          >
            <Link
              link="/"
              target="_blank"
              typo={{
                size: "13px",
                weight: "700",
                color: "white01",
              }}
            >
              웹사이트 바로가기
            </Link>
          </AdminButton>
          <div
            className="profileImage"
            style={{
              width: "40px",
              height: "40px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginRight: "15px",
            }}
            onMouseDown={handleProfileClick}
          >
            <Image src={"/asset/img/admin/layout/profile.svg"} />
          </div>

          <ProfileHoverBox
            className="profileBox"
            isOpen={isOpen}
            onClose={handleOuterClick}
          />
        </Box>
      )}
    </Box>
  );
};

export default AdminHeader;
