import Box from "component/atoms/box";
import Button from "component/atoms/button";
import Image from "component/atoms/image";
import useUrl from "hook/util/useUrl";

const MoreButton = (props) => {
  const { id } = props;
  const { changeQuery } = useUrl();

  const handleDetailView = () => {
    changeQuery({
      comment: id,
    });
  };

  return (
    <Box
      position={{
        type: "absolute",
        bottom: "0",
        left: "0",
        right: "0",
      }}
      size={{
        width: "100%",
        height: "auto",
      }}
    >
      <Button
        position={{
          type: "relative",
          zIndex: "1",
        }}
        size={{
          width: "100%",
          height: "40px",
        }}
        typo={{
          size: "15px",
          weight: "700",
        }}
        border={{
          all: "1px",
          style: "solid",
          color: "black01",
        }}
        background={{
          color: "white01",
        }}
        onClick={handleDetailView}
      >
        자세히보기
      </Button>
      <Image
        position={{
          type: "absolute",
          bottom: "-10px",
          left: "0",
          zIndex: "0",
        }}
        size={{
          width: "100%",
        }}
        longMobile={{
          position: {
            bottom: "5px",
          },
        }}
        src={"/asset/img/story/blur.png"}
      />
    </Box>
  );
};

export default MoreButton;
