import { forwardRef } from "react";
import BoxStyle from "./box.style";
import PropTypes from "prop-types";

const themeStyle = {
  basic: {
    size: { width: "1200px" },

    pc: {
      size: {
        width: "100%",
      },
      padding: {
        all: "0 16px",
      },
    },
  },
  mainMargin: {
    margin: {
      all: "100px auto 0",
    },

    pc: {
      margin: {
        all: "80px auto 0",
      },
    },
  },
  full: {
    size: { width: "100%", height: "100%" },
  },
  storyGrid: {
    display: {
      display: "grid",
      gridTemplateColumns: "repeat(4, 1fr)",
      gap: "75px 40px",
    },
    margin: {
      top: "75px",
    },

    tablet: {
      display: {
        gridTemplateColumns: "repeat(2, 1fr)",
        gap: "46px 20px",
      },
      margin: {
        top: "40px",
      },
    },
    longMobile: {
      display: {
        gap: "38px 8px",
      },
    },
  },

  adminRight: {
    size: {
      width: "1022px",
    },
    display: {
      display: "flex",
      justify: "flex-end",
      align: "center",
    },
  },

  adminListBox: {
    display: {
      display: "flex",
      align: "center",
      justify: "space-between",
    },
    size: {
      width: "1024px",
      height: ($props) => $props.height || "63px",
    },
  },
};

const themeMerge = (theme) => {
  if (typeof theme === "string") {
    return themeStyle[theme];
  } else if (Array.isArray(theme)) {
    return theme.reduce((acc, cur) => {
      const updateTheme = themeStyle[cur];

      Object.entries(updateTheme).forEach(([key, value]) => {
        if (acc[key] === undefined) {
          acc[key] = { ...value };
        } else {
          acc[key] = { ...acc[key], ...value };
        }
      });

      return {
        ...acc,
      };
    }, {});
  }
};

const styleMerge = (style, theme) => {
  const updateStyle = { ...style };
  if (theme) {
    Object.entries(theme).forEach(([key, value]) => {
      const currentStyle = updateStyle[key] ?? {};
      updateStyle[key] = {
        ...value,
        ...currentStyle,
      };
    });
  }

  return updateStyle;
};

const Box = forwardRef((props, ref) => {
  const {
    as = "div",
    children,
    className,
    //
    id,
    colSpan,
    //
    theme,
    //
    isCustomScroll,
    //
    onSubmit,
    onClick,
    onChange,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const currentTheme = themeMerge(theme);

  const {
    display,
    margin,
    padding,
    border,
    position,
    background,
    transform,
    other,
    size,
    typo,
    hover,
    pc,
    mypage,
    tablet,
    longMobile,
    mobile,
    animation,
  } = styleMerge(props, currentTheme);

  return (
    <BoxStyle
      as={as}
      ref={ref}
      //
      className={className}
      id={id}
      colSpan={colSpan}
      //
      $display={display}
      $margin={margin}
      $padding={padding}
      $border={border}
      $position={position}
      $background={background}
      $other={other}
      $size={size}
      $transform={transform}
      $typography={typo}
      $hover={hover}
      $pc={pc}
      $mypage={mypage}
      $tablet={tablet}
      $longMobile={longMobile}
      $mobile={mobile}
      $animation={animation}
      $isCustomScroll={isCustomScroll}
      //
      onSubmit={onSubmit}
      onClick={onClick}
      onChange={onChange}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </BoxStyle>
  );
});

Box.propTypes = {
  as: PropTypes.oneOf([
    "div",
    "form",
    "section",
    "article",
    "header",
    "footer",
    "label",
  ]),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
    PropTypes.oneOf([null, undefined]),
  ]),
  //
  theme: PropTypes.oneOfType([
    PropTypes.oneOf(["basic", "full", "mainMargin"]),
    PropTypes.arrayOf(PropTypes.oneOf(["basic", "full", "mainMargin"])),
  ]),
  ...BoxStyle.propTypes,
};

export default Box;
