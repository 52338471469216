import { useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { blockUser, getAdminUserList } from "api/admin/user/userManage";
import { useNavigate } from "react-router-dom";

export const useAdminUserList = (
  grade,
  status,
  name,
  email,
  keyword,
  pageSize,
  currentPage
) => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    [
      "adminUserList",
      grade,
      status,
      name,
      email,
      keyword,
      pageSize,
      currentPage,
    ],
    () =>
      getAdminUserList(
        grade,
        status,
        name,
        email,
        keyword,
        pageSize,
        currentPage
      ),
    {
      onError: (error) => {
        if (error.response && error.response.status === 403) {
          alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
          navigate("/admin/login");
        }
      },
    }
  );
  return {
    data: data?.data,
    totalItems: data?.totalItems,
    totalPages: data?.totalPages,
    isLoading,
  };
};

export const useBlockUser = (onSuccessCallback, onErrorCallback) => {
  const queryClient = useQueryClient();
  const { mutate, data, isLoading } = useMutation(
    ({ memberId }) => blockUser(memberId),
    {
      onSuccess: () => {
        if (onSuccessCallback && typeof onSuccessCallback === "function") {
          onSuccessCallback();
        }
        queryClient.invalidateQueries("adminUserList");
      },
      onError: (error) => {
        if (onErrorCallback && typeof onErrorCallback === "function") {
          onErrorCallback(error);
        }
      },
    }
  );

  return {
    mutate,
    data,
    isLoading,
  };
};
