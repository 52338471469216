import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import Data from "./test-data.json";
import ServiceItem from "component/molecules/main/serviceItem";
import StoryCard from "component/organisms/common/storyCard";
import Box from "component/atoms/box";
import MainSlider from "component/organisms/main/mainSlider";
import ContentBox from "component/organisms/main/contentBox";
import Text from "component/atoms/text";
import StorySlider from "component/organisms/main/storySlider";
import Api from "component/molecules/common/api";
import FooterBanner from "component/organisms/main/footerBanner";
import Link from "component/atoms/link";
import useSpecial from "hook/api/main/special";
import useLatest from "hook/api/main/latest";
import useDeviceStore from "store/useDeviceStore";
import useMainBannerList from "hook/api/admin/banner/mainList";
import useMiddleBannerList from "hook/api/admin/banner/middleList";
import usePopup from "hook/api/main/popup";
import Popup from "component/organisms/common/popup";

const Main = () => {
  const { longMobile } = useDeviceStore();

  return (
    <>
      <Api
        api={useMainBannerList}
        params={{
          device: longMobile ? "mobile" : "pc",
        }}
      >
        {(data) => data && <MainSlider slide={data} />}
      </Api>

      <Box
        as={"section"}
        theme={["basic", "mainMargin"]}
        margin={{
          all: "100px auto 0",
        }}
        display={{
          display: "grid",
          gridTemplateColumns: "repeat(4, 1fr)",
          gap: "44px",
        }}
        pc={{
          display: {
            gap: "24px",
          },
        }}
        tablet={{
          display: {
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "12px",
          },
        }}
        mobile={{
          display: {
            gridTemplateColumns: "repeat(1, 1fr)",
          },
        }}
      >
        {Data.service.map((item, index) => (
          <ServiceItem key={index} {...item} />
        ))}
      </Box>
      <Box
        as={"section"}
        theme={["basic", "mainMargin"]}
        margin={{
          all: "100px auto 0",
        }}
      >
        <Box
          display={{
            display: "flex",
            align: "flex-end",
            wrap: "wrap",
          }}
          padding={{
            bottom: "12px",
          }}
          size={{
            width: "100%",
          }}
          border={{
            bottom: "1px",
            style: "solid",
            color: "#000",
          }}
          longMobile={{
            display: {
              justify: "space-between",
            },
          }}
        >
          <Text
            typo={{
              size: "24px",
              weight: "700",
              lineHeight: "1.25",
            }}
          >
            기획전
          </Text>
          <Link
            link={"/story?storyState=기획전&option=filter"}
            typo={{
              size: "14px",
              weight: "400",
              color: "#777",
            }}
            margin={{
              left: "8px",
            }}
          >
            전체보기
          </Link>
        </Box>
      </Box>

      <Api api={useSpecial}>
        {(data) => data && <StorySlider list={data} />}
      </Api>

      <Box
        as={"section"}
        theme={["basic", "mainMargin"]}
        margin={{
          all: "100px auto 0",
        }}
      >
        <Box
          display={{
            display: "flex",
            align: "flex-end",
          }}
          padding={{
            bottom: "12px",
          }}
          size={{
            width: "100%",
          }}
          border={{
            bottom: "1px",
            style: "solid",
            color: "#000",
          }}
          longMobile={{
            display: {
              justify: "space-between",
            },
          }}
        >
          <Text
            typo={{
              size: "24px",
              weight: "700",
              lineHeight: "1.25",
            }}
          >
            이야기 둘러보기
          </Text>

          <Link
            link={"/story"}
            typo={{
              size: "14px",
              weight: "400",
              color: "#777",
            }}
            margin={{
              left: "8px",
            }}
          >
            전체보기
          </Link>
        </Box>

        <Api
          api={useLatest}
          params={{
            page: 1,
            limit: 4,
          }}
        >
          {(data) =>
            data && (
              <Box theme={"storyGrid"}>
                {data.slice(0, 4).map((item, index) => (
                  <StoryCard key={index} {...item} />
                ))}
              </Box>
            )
          }
        </Api>
      </Box>
      <Box
        theme={["basic", "mainMargin"]}
        margin={{
          all: "100px auto 0",
        }}
        display={{
          display: "flex",
          justify: "space-between",
          wrap: "wrap",
        }}
      >
        {Data.cs.map((item, index) => (
          <ContentBox
            key={index}
            img={item.img}
            title={item.title}
            contents={item.contents}
            link={item.link}
          />
        ))}
      </Box>
      <Api
        api={useMiddleBannerList}
        params={{
          device: longMobile ? "mobile" : "pc",
        }}
      >
        {(data) => data && <FooterBanner slide={data} />}
      </Api>

      <Api api={usePopup}>
        {(data) => data.map((item, idx) => <Popup {...item} />)}
      </Api>
    </>
  );
};

export default Main;
