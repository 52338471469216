import HeaderButton from "component/organisms/admin/topMenu";
import React, { useState, useContext, useEffect } from "react";
import BottomMenu from "../bottomMenu";
import Link from "component/atoms/link";
import { useNavigate, useLocation } from "react-router-dom";
import { MenuContext } from "context";
import BottomMenuButton from "../bottomMenu/bottomMenuButton";
import useUrl from "hook/util/useUrl";
import useCheckEdit from "hook/util/useCheckEdit";
import Box from "component/atoms/box";

const HeaderMenu = (props) => {
  const { position } = props;
  // 메뉴 토글 상태 관리
  const [activeMenu, setActiveMenu] = useState(null);
  const [isHover, setIsHover] = useState({
    userDetail: false,
    storyDetail: false,
    dashboard: false,
    setup: false,
    user: false,
    story: false,
    banner: false,
    link: false,
    GA: false,
  });

  const handleMouseEnter = (menuName) => {
    setIsHover({
      userDetail: false,
      storyDetail: false,
      dashboard: false,
      setup: false,
      user: false,
      story: false,
      banner: false,
      link: false,
      GA: false,
      [menuName]: true,
    });
  };

  const handleMouseLeave = (menuName) => {
    setIsHover({
      userDetail: false,
      storyDetail: false,
      dashboard: false,
      setup: false,
      user: false,
      story: false,
      banner: false,
      link: false,
      GA: false,
      [menuName]: false,
    });
  };

  const {
    params: { id },
  } = useUrl();

  const checkEdit = useCheckEdit();

  // 메뉴를 클릭하면 해당하는 메뉴를 활성화하고, 다른 메뉴는 닫히도록 함
  const handleButtonClick = (menuName) => {
    if (menuName === "dashboard") {
      navigate("/admin/dashboard?menu=dashboard");
      setActiveMenu("dashboard");
    } else {
      // 각 상위 메뉴에 대한 기본 하위 메뉴 경로를 설정
      const defaultSubMenu = buttonList[menuName][0];
      const defaultPath = `/admin/${defaultSubMenu.domain}?menu=${menuName}&category=${defaultSubMenu.menuName}`;

      navigate(
        menuName === "userDetail"
          ? `/admin/${defaultSubMenu.domain}/${id}?menu=${menuName}&category=${defaultSubMenu.menuName}`
          : menuName === "storyDetail"
          ? `/admin/${defaultSubMenu.domain}/${id}?menu=${menuName}&category=${defaultSubMenu.menuName}`
          : defaultPath
      );
      setActiveMenu(menuName);
    }
  };

  const navigate = useNavigate();

  // 메뉴 클릭 시 context에 저장하여 각 페이지에 해당하는 컴포넌트가 렌더링 되도록 함
  const { setSelectedMenu } = useContext(MenuContext);

  // 선택된 하위 메뉴 항목을 추적하는 상태
  const [selectedBottomMenu, setSelectedBottomMenu] = useState(null);

  // 하단 메뉴 클릭 시 setSelectedMenu를 통해 context에 저장된 메뉴를 변경하고 해당하는 페이지로 이동
  const handleMenuClick = (menu, domain) => {
    setActiveMenu(menu);
    setSelectedMenu(menu);
    setSelectedBottomMenu(menu);
    if (window.location.pathname.includes("/user/detail")) {
      navigate(`/admin/${domain}?menu=userDetail&category=${menu}`);
    } else if (window.location.pathname.includes("/story/detail")) {
      navigate(`/admin/${domain}?menu=storyDetail&category=${menu}`);
    } else if (window.location.pathname.includes("/setup")) {
      navigate(`/admin/${domain}?menu=setup&category=${menu}`);
    } else {
      navigate(
        `/admin/${domain}?menu=${domain.split("/")[1]}&category=${menu}`
      );
    }
  };

  const buttonList = {
    setup: [
      { text: "단어 필터링", menuName: "word", domain: "setup" },
      { text: "닉네임 금지 단어", menuName: "nickname", domain: "setup" },
      { text: "개인정보 이용 동의", menuName: "acceptance", domain: "setup" },
      { text: "마케팅 수신 동의", menuName: "marketing", domain: "setup" },
      { text: "이용약관", menuName: "terms", domain: "setup" },
      { text: "개인정보 처리 방침", menuName: "personalInfo", domain: "setup" },
      { text: "이야기 이용약관", menuName: "storyTerm", domain: "setup" },
      { text: "푸터", menuName: "footer", domain: "setup" },
      {
        text: "뉴스레터/의뢰하기",
        menuName: "request-link",
        domain: "setup",
      },
    ],
    user: [
      { text: "회원 관리", menuName: "userManage", domain: "manage/user" },
      { text: "등업 관리", menuName: "gradeManage", domain: "manage/user" },
    ],
    userDetail: [
      {
        text: "기본 정보",
        menuName: "basicDetail",
        domain: "manage/user/detail",
      },
      {
        text: "코인 정보",
        menuName: "cheeringDetail",
        domain: "manage/user/detail",
      },
      {
        text: "아티스트 정보",
        menuName: "artistDetail",
        domain: "manage/user/detail",
      },
      {
        text: "포트폴리오",
        menuName: "portfolio",
        domain: "manage/user/detail",
      },
    ],
    story: [
      { text: "이야기 관리", menuName: "storyManage", domain: "manage/story" },
      { text: "창작완료 심사", menuName: "judging", domain: "manage/story" },
      { text: "메인 노출 관리", menuName: "exposed", domain: "manage/story" },
      {
        text: "응원 관리",
        menuName: "cheering",
        domain: "manage/story",
        margin: { top: "18px", bottom: "18px" },
      },
    ],
    storyDetail: [
      {
        text: "이야기 수정",
        menuName: "storyEdit",
        domain: "manage/story/detail",
      },
      {
        text: "응원 관리",
        menuName: "cheeringEdit",
        domain: "manage/story/detail",
      },
      {
        text: "매칭 관리",
        menuName: "matchingEdit",
        domain: "manage/story/detail",
      },
    ],
    banner: [
      {
        text: "메인 배너 관리",
        menuName: "mainBanner",
        domain: "manage/banner",
      },
      {
        text: "중간 배너 관리",
        menuName: "subBanner",
        domain: "manage/banner",
      },
      { text: "팝업 관리", menuName: "popup", domain: "manage/banner" },
    ],
  };

  const isLast = (index, array) => {
    return index === array.length - 1;
  };

  const location = useUrl();

  const getQueryParam = (cate) => {
    const param = new URLSearchParams(location.search);
    return param.get(cate);
  };

  const isUserDetailPage = location.pathname.includes("/user/detail");
  const isStoryDetailPage = location.pathname.includes("/story/detail");

  const categoryFromUrl = getQueryParam("category");
  const menuFromUrl = getQueryParam("menu");
  useEffect(() => {
    if (categoryFromUrl) {
      setSelectedMenu(categoryFromUrl);
      setActiveMenu(menuFromUrl);
    }
    if (menuFromUrl) {
      setActiveMenu(menuFromUrl);
    }
  }, [location]);

  return (
    <Box
      size={{
        width: "100%",
        height: "auto",
      }}
      display={{
        display: "flex",
        direction: "column",
      }}
      position={position}
    >
      {/* 회원 정보 수정 페이지 */}
      {isUserDetailPage ? (
        <>
          <HeaderButton
            src={"/asset/img/admin/layout/w_user.svg"}
            title="회원 정보 수정"
            arrow={"/asset/img/admin/layout/w_arrow.svg"}
            isActive={menuFromUrl === "userDetail"}
            onClick={() => checkEdit(handleButtonClick)("userDetail")}
          />
          <BottomMenu visible={menuFromUrl === "userDetail"}>
            {buttonList["userDetail"].map((item, index) => (
              <BottomMenuButton
                text={item.text}
                domain={item.domain}
                onClick={() =>
                  checkEdit(handleMenuClick)(
                    item.menuName,
                    `${item.domain}/${id}`
                  )
                }
                isActive={categoryFromUrl === item.menuName}
                margin={
                  isLast(index, buttonList["userDetail"])
                    ? { top: "18px", bottom: "18px" }
                    : { top: "18px" }
                }
              ></BottomMenuButton>
            ))}
          </BottomMenu>
        </>
      ) : isStoryDetailPage ? (
        <>
          <HeaderButton
            src={"/asset/img/admin/layout/w_story.svg"}
            title="이야기 관리"
            arrow={"/asset/img/admin/layout/w_arrow.svg"}
            isActive={menuFromUrl === "storyDetail"}
            onClick={() => checkEdit(handleButtonClick)("storyDetail")}
          />
          <BottomMenu visible={menuFromUrl === "storyDetail"}>
            {buttonList["storyDetail"].map((item, index) => (
              <BottomMenuButton
                text={item.text}
                domain={item.domain}
                onClick={() =>
                  checkEdit(handleMenuClick)(
                    item.menuName,
                    `${item.domain}/${id}`
                  )
                }
                isActive={categoryFromUrl === item.menuName}
                margin={
                  isLast(index, buttonList["storyDetail"])
                    ? { top: "18px", bottom: "18px" }
                    : { top: "18px" }
                }
              ></BottomMenuButton>
            ))}
          </BottomMenu>
        </>
      ) : (
        <>
          <HeaderButton
            src={
              window.location.pathname.startsWith("/admin/dashboard") ||
              isHover.dashboard
                ? "/asset/img/admin/layout/w_dashboard.svg"
                : "/asset/img/admin/layout/dashboard.svg"
            }
            title="대시보드"
            arrow={
              window.location.pathname.startsWith("/admin/dashboard") ||
              isHover.dashboard
                ? "/asset/img/admin/layout/w_arrow.svg"
                : "/asset/img/admin/layout/arrow.svg"
            }
            isActive={location.pathname === "/admin/dashboard"}
            onClick={() => checkEdit(handleButtonClick)("dashboard")}
            onMouseEnter={() => handleMouseEnter("dashboard")}
            onMouseLeave={() => handleMouseLeave("dashboard")}
          />

          {/* 환경설정 */}
          <HeaderButton
            src={
              menuFromUrl === "setup" || isHover.setup
                ? "/asset/img/admin/layout/w_setup.svg"
                : "/asset/img/admin/layout/setup.svg"
            }
            title="환경설정"
            arrow={
              menuFromUrl === "setup" || isHover.setup
                ? "/asset/img/admin/layout/w_arrow.svg"
                : "/asset/img/admin/layout/arrow.svg"
            }
            isActive={menuFromUrl === "setup"}
            onClick={() => checkEdit(handleButtonClick)("setup")}
            onMouseEnter={() => handleMouseEnter("setup")}
            onMouseLeave={() => handleMouseLeave("setup")}
          />
          <BottomMenu visible={menuFromUrl === "setup"}>
            {buttonList["setup"].map((item, index) => (
              <BottomMenuButton
                text={item.text}
                domain={item.domain}
                onClick={() =>
                  checkEdit(handleMenuClick)(item.menuName, item.domain)
                }
                isActive={categoryFromUrl === item.menuName}
                margin={
                  isLast(index, buttonList["setup"])
                    ? { top: "18px", bottom: "18px" }
                    : { top: "18px" }
                }
              ></BottomMenuButton>
            ))}
          </BottomMenu>

          {/* 회원 관리 */}
          <HeaderButton
            src={
              menuFromUrl === "user" || isHover.user
                ? "/asset/img/admin/layout/w_user.svg"
                : "/asset/img/admin/layout/user.svg"
            }
            title="회원 관리"
            arrow={
              menuFromUrl === "user" || isHover.user
                ? "/asset/img/admin/layout/w_arrow.svg"
                : "/asset/img/admin/layout/arrow.svg"
            }
            isActive={menuFromUrl === "user"}
            onMouseEnter={() => handleMouseEnter("user")}
            onMouseLeave={() => handleMouseLeave("user")}
            onClick={() => checkEdit(handleButtonClick)("user")}
          />
          <BottomMenu visible={menuFromUrl === "user"}>
            {buttonList["user"].map((item, index) => (
              <BottomMenuButton
                text={item.text}
                domain={item.domain}
                onClick={() =>
                  checkEdit(handleMenuClick)(item.menuName, item.domain)
                }
                isActive={categoryFromUrl === item.menuName}
                margin={
                  isLast(index, buttonList["user"])
                    ? { top: "18px", bottom: "18px" }
                    : { top: "18px" }
                }
              ></BottomMenuButton>
            ))}
          </BottomMenu>

          {/* 이야기 관리 */}
          <HeaderButton
            src={
              menuFromUrl === "story" || isHover.story
                ? "/asset/img/admin/layout/w_story.svg"
                : "/asset/img/admin/layout/story.svg"
            }
            title="이야기 관리"
            arrow={
              menuFromUrl === "story" || isHover.story
                ? "/asset/img/admin/layout/w_arrow.svg"
                : "/asset/img/admin/layout/arrow.svg"
            }
            isActive={menuFromUrl === "story"}
            onMouseEnter={() => handleMouseEnter("story")}
            onMouseLeave={() => handleMouseLeave("story")}
            onClick={() => checkEdit(handleButtonClick)("story")}
          />
          <BottomMenu visible={menuFromUrl === "story"}>
            {buttonList["story"].map((item, index) => (
              <BottomMenuButton
                text={item.text}
                onClick={() =>
                  checkEdit(handleMenuClick)(item.menuName, item.domain)
                }
                isActive={categoryFromUrl === item.menuName}
                margin={
                  isLast(index, buttonList["story"])
                    ? { top: "18px", bottom: "18px" }
                    : { top: "18px" }
                }
              ></BottomMenuButton>
            ))}
          </BottomMenu>

          {/* 배너・팝업 관리 */}
          <HeaderButton
            src={
              menuFromUrl === "banner" || isHover.banner
                ? "/asset/img/admin/layout/w_banner.svg"
                : "/asset/img/admin/layout/banner.svg"
            }
            title="배너・팝업 관리"
            arrow={
              menuFromUrl === "banner" || isHover.banner
                ? "/asset/img/admin/layout/w_arrow.svg"
                : "/asset/img/admin/layout/arrow.svg"
            }
            isActive={menuFromUrl === "banner"}
            onClick={() => checkEdit(handleButtonClick)("banner")}
            onMouseEnter={() => handleMouseEnter("banner")}
            onMouseLeave={() => handleMouseLeave("banner")}
          />
          <BottomMenu visible={menuFromUrl === "banner"}>
            {buttonList["banner"].map((item, index) => (
              <BottomMenuButton
                text={item.text}
                onClick={() =>
                  checkEdit(handleMenuClick)(item.menuName, item.domain)
                }
                isActive={categoryFromUrl === item.menuName}
                margin={
                  isLast(index, buttonList["banner"])
                    ? { top: "18px", bottom: "18px" }
                    : { top: "18px" }
                }
              ></BottomMenuButton>
            ))}
          </BottomMenu>

          {/* GA */}
          <Link
            link="https://analytics.google.com/analytics/web/#/p417971422/reports/intelligenthome"
            target="_blank"
            size={{
              width: "100%",
            }}
            onMouseEnter={() => handleMouseEnter("GA")}
            onMouseLeave={() => handleMouseLeave("GA")}
          >
            <HeaderButton
              src={
                isHover.GA
                  ? "/asset/img/admin/layout/w_ga.svg"
                  : "/asset/img/admin/layout/ga.svg"
              }
              title="GA"
              arrow={
                isHover.GA
                  ? "/asset/img/admin/layout/w_exit.svg"
                  : "/asset/img/admin/layout/exit.svg"
              }
            />
          </Link>
        </>
      )}
    </Box>
  );
};

export default HeaderMenu;
