import Box from "component/atoms/box";
import Blank from "component/molecules/common/blank";
import List from "component/molecules/common/list";
import SortFilterSelect from "component/molecules/detail/sortFilterSelect";
import LineTitle from "component/organisms/common/lineTitle";
import StoryCard from "component/organisms/common/storyCard";
import useCreation from "hook/api/mypage/creation";
import useUrl from "hook/util/useUrl";
import useSignedStore from "store/useSignedStore";

const option = [
  {
    label: "전체",
    value: "",
  },
  {
    label: "창작중",
    value: "창작중",
  },
  {
    label: "창작완료",
    value: "창작완료",
  },
  {
    label: "발매완료",
    value: "발매완료",
  },
  {
    label: "보류",
    value: "보류",
  },
];

const Create = (props) => {
  const { title } = props;
  const {
    params: { id },
    query: { page = 1, sort },
  } = useUrl();

  const { user } = useSignedStore();

  const memberId = id ?? user?.memberId;

  return (
    <>
      <LineTitle
        typo={{
          size: "18px",
        }}
        title={title}
      >
        <SortFilterSelect name="sort" options={option} />
      </LineTitle>

      <List
        api={useCreation}
        params={{
          id: memberId,
          page,
          state: sort,
          limit: 16,
        }}
      >
        {(list) =>
          list && (
            <>
              {list.length === 0 && <Blank title={"조회 결과가 없습니다."} />}
              {list.length !== 0 && (
                <Box theme={"storyGrid"}>
                  {list.map((item) => (
                    <StoryCard {...item} key={item.id} />
                  ))}
                </Box>
              )}
            </>
          )
        }
      </List>
    </>
  );
};

export default Create;
