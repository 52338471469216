import { logout } from "api/auth";
import useUrl from "hook/util/useUrl";
import { useMutation } from "react-query";
import useSignedStore from "store/useSignedStore";
import cookie from "react-cookies";

const useLogout = (id) => {
  const { changeLink } = useUrl();
  const { setSigned } = useSignedStore();

  // const logoutMutation = useMutation(logout, {
  //   onSuccess: () => {
  //     setSigned({
  //       isLogin: false,
  //     });
  //   },
  // });

  const submit = () => {
    // logoutMutation.mutate();
    cookie.remove("moomoostJwt", { path: "/" });
    setSigned({
      isLoading: false,
    });
    if (window.location.pathname.includes("/admin")) {
      changeLink(`/admin/login`);
      return;
    } else {
      changeLink(`/`);
    }
  };

  return submit;
};

export default useLogout;
