import { API_VERSION, SERVER_URL } from "api/config";
import { footerBanner, mainBanner } from "mock/datas/banner";
import { rest } from "msw";

const getMainBannerHandler = rest.get(
  `${SERVER_URL}/api/${API_VERSION}/banner/main`,
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        message: "success",
        data: mainBanner,
      })
    );
  }
);

const getFooterBannerHandler = rest.get(
  `${SERVER_URL}/api/${API_VERSION}/banner/footer`,
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        message: "success",
        data: footerBanner,
      })
    );
  }
);

const banner = [getMainBannerHandler, getFooterBannerHandler];

export default banner;
