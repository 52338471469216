import Box from "component/atoms/box";
import Text from "component/atoms/text";

import CONST from "const/index";
import { Link } from "react-router-dom";

const HeaderMenu = () => {
  return (
    <Box
      display={{
        display: "flex",
        justify: "center",
        align: "center",
        gap: "0 20px",
      }}
      size={{
        height: "100%",
      }}
    >
      {CONST.HEADER_MENU.map(({ link, text }, index) => (
        <Link to={link} key={text}>
          <Text
            typo={{
              size: "16px",
              weight: "500",
            }}
          >
            {text}
          </Text>
        </Link>
      ))}
    </Box>
  );
};

export default HeaderMenu;
