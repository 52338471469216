import { footer } from "api/main";
import { useQuery } from "react-query";

const useFooter = () => {
  const { data, isLoading, isSuccess, isError } = useQuery("footer", footer);

  return {
    data: data?.data,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useFooter;
