import { GET, POST, DELETE } from "api/config";

export const getAdminFilterNickname = async () =>
  await GET("/admin/filter/nickname");

export const createAdminFilterNickname = async (nickName) => {
  const data = {
    nickName: nickName,
  };
  await POST("/admin/filter/nickname", data);
};

export const deleteAdminFilterNickname = async (id) =>
  await DELETE(`/admin/filter/nickname/${id}`);
