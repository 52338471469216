import { replay } from "api/story";
import { ErrorContent } from "component/organisms/common/globalModal";
import useInput from "hook/util/useInput";
import useUrl from "hook/util/useUrl";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";

const useReplayForm = () => {
  const { value, onChange, reset } = useInput("");
  const {
    query: { comment },
  } = useUrl();
  const { setModal } = useModalStore();
  const queryClient = useQueryClient();
  const replayMutation = useMutation(replay, {
    onSuccess: () => {
      reset();
      queryClient.invalidateQueries("replyList");
      queryClient.invalidateQueries("selectComment");
    },
    onError: ({ response: { data } }) => {
      setModal({
        title: "댓글 작성",
        content: <ErrorContent message={data.message} />,
      });
    },
  });
  const isActive = value.trim() !== "";

  const submit = () => {
    isActive &&
      replayMutation.mutate({
        boardId: comment,
        data: {
          userComment: value,
        },
      });
  };

  return {
    value,
    isActive,
    onChange,
    submit,
  };
};

export default useReplayForm;
