import React, { useEffect, useState } from "react";
import Box from "component/atoms/box";
import Input from "component/atoms/input";
import RowTable from "component/atoms/rowSideTable";
import RowTableData from "component/atoms/rowTableData";
import RowTableHead from "component/atoms/rowTableHead";
import Text from "component/atoms/text";
import AdminButton from "component/molecules/admin/button/adminButton";
import AdminAlertModal from "../../modal/alertModal";
import useInput from "hook/util/useInput";
import {
  useAdminNewsLetterLink,
  useAdminRequestLink,
  useUpdateAdminNewsLetterLink,
  useUpdateAdminRequestLink,
} from "hook/api/admin/setup/requestLink";

const RequestLink = () => {
  const { data: data } = useAdminRequestLink();
  const { data: newsLetterData } = useAdminNewsLetterLink();
  const {
    value: requestLink,
    setValue: setRequestLink,
    onChange: handleRequestLink,
  } = useInput(data?.content);

  const {
    value: newsLetterLink,
    setValue: setNewsLetterLink,
    onChange: handleNewsLetterLink,
  } = useInput(newsLetterData?.content);

  // 저장 버튼 비활성화
  const [isDone, setIsDone] = useState(false);
  // 성공 모달, 실패 모달
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false); // 등록 실패

  const handleSuccess = () => {
    setSuccess(true);
  };

  const handleError = () => {
    setError(true);
  };

  const { mutate: updateData } = useUpdateAdminRequestLink(
    handleSuccess,
    handleError
  );

  const { mutate: updateNewsLetterData } = useUpdateAdminNewsLetterLink(
    handleSuccess,
    handleError
  );

  const handleSubmit = (e) => {
    if (!requestLink || !newsLetterLink) {
      alert("필수 입력 항목을 입력해주세요.");
      return;
    }
    e.preventDefault();
    if (window.confirm("링크를 등록하시겠습니까?")) {
      updateData({
        content: requestLink,
      });
      updateNewsLetterData({
        content: newsLetterLink,
      });
    }
  };

  useEffect(() => {
    if (data) {
      setRequestLink(data?.content);
    }
    if (newsLetterData) {
      setNewsLetterLink(newsLetterData?.content);
    }
  }, [data, newsLetterData]);

  useEffect(() => {
    // 모든 필드가 유효한지 확인
    const isAllFieldsFilled = requestLink && newsLetterLink;

    // 모든 필드가 채워져 있다면 isDone을 true로 설정
    setIsDone(isAllFieldsFilled);
  }, [requestLink, newsLetterLink]);

  return (
    <Box
      size={{
        width: "100%",
        height: "auto",
      }}
      margin={{
        top: "25px",
      }}
      other={{
        overflowX: "hidden",
      }}
    >
      {success && (
        <AdminAlertModal
          title={"뉴스레터/의뢰하기"}
          text="뉴스레터/의뢰하기 링크 등록이 완료되었습니다."
          btn_text="확인"
          visible={success}
          toggle={() => {
            setSuccess(false);
          }}
          onClick={() => {
            setSuccess(false);
          }}
        />
      )}
      {error && (
        <AdminAlertModal
          title={"뉴스레터/의뢰하기"}
          text="뉴스레터/의뢰하기 링크 등록에 실패하였습니다."
          btn_text="확인"
          visible={error}
          toggle={() => {
            setError(false);
          }}
          onClick={() => {
            setError(false);
          }}
        />
      )}
      <Text theme={"adminTitle"}>뉴스레터 / 의뢰하기</Text>
      <RowTable>
        <Box
          theme="adminListBox"
          padding={{
            left: "21px",
          }}
        >
          <Text
            typo={{
              size: "12px",
              weight: "700",
              color: "pink03",
            }}
          >
            * 은 필수 입력 항목입니다.
          </Text>
        </Box>

        <Box
          display={{
            width: "100%",
            height: "48px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="48px">
            뉴스레터 링크 입력
            <Text
              typo={{
                color: "pink03",
              }}
              margin={{
                left: "4px",
              }}
            >
              *
            </Text>
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
          >
            <Input
              theme={"adminInput"}
              size={{
                width: "395px",
                height: "32px",
              }}
              margin={{
                top: "8px",
              }}
              value={newsLetterLink}
              onChange={handleNewsLetterLink}
            />
          </RowTableData>
        </Box>
        <Box
          display={{
            width: "100%",
            height: "48px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="48px">
            의뢰하기 링크 입력
            <Text
              typo={{
                color: "pink03",
              }}
              margin={{
                left: "4px",
              }}
            >
              *
            </Text>
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
          >
            <Input
              theme={"adminInput"}
              size={{
                width: "395px",
                height: "32px",
              }}
              margin={{
                top: "8px",
              }}
              value={requestLink}
              onChange={handleRequestLink}
            />
          </RowTableData>
        </Box>
      </RowTable>
      <AdminButton
        category="button"
        theme={isDone ? ["mainButton"] : ["grayColor"]}
        margin={{
          top: "24px",
          left: "460px",
        }}
        size={{
          width: "104px",
          height: "40px",
        }}
        padding={{
          top: "12px",
          bottom: "12px",
        }}
        onClick={handleSubmit}
      >
        저장
      </AdminButton>
    </Box>
  );
};

export default RequestLink;
