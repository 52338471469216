import Text from "component/atoms/text";
import Box from "component/atoms/box";
import useUrl from "hook/util/useUrl";
import { useEffect, useState } from "react";
import CommonButton from "component/molecules/common/commonButton";

const CategoryView = (props) => {
  const { categoryTitle, categoryName, categoryList, defaultSelectList } =
    props;
  const [selectList, setSelectList] = useState([]);
  const { query, changeQuery } = useUrl();

  const currentCategory = query[categoryName];

  const handleSelect = (category) => {
    const index = selectList.indexOf(category);
    let newSelectList;

    if (index === -1) {
      newSelectList = [...selectList, category];
    } else {
      newSelectList = selectList.filter((item) => item !== category);
    }

    changeQuery({
      [categoryName]: newSelectList.join(","),
      page: 1,
    });
    setSelectList(newSelectList);
  };

  useEffect(() => {
    if (currentCategory) {
      const categoryList = currentCategory.split(",");

      setSelectList(categoryList);
    } else {
      setSelectList(defaultSelectList);
    }
  }, [currentCategory]);

  return (
    <Box
      display={{
        display: "flex",
        direction: "column",
      }}
      mobile={{
        size: "100%",
      }}
    >
      <Text
        typo={{
          size: "18px",
          weight: "700",
          lineHeight: "normal",
        }}
      >
        {categoryTitle}
      </Text>

      <Box
        display={{
          display: "grid",
          gridTemplateColumns: "repeat(5 , 1fr)",
          gap: "11px",
        }}
        margin={{
          top: "12px",
        }}
        pc={{
          display: {
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "8px",
          },
        }}
      >
        {categoryList.map((item, index) => (
          <CommonButton
            key={index}
            category={"button"}
            type={"button"}
            size={{
              height: "42px",
            }}
            typo={{
              size: "14px",
              weight: "700",
              lineHeight: "normal",
            }}
            isActive={selectList.includes(item)}
            onClick={() => handleSelect(item)}
          >
            {item}
          </CommonButton>
        ))}
      </Box>
    </Box>
  );
};

export default CategoryView;
