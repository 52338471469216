import { GET, POST, PUT, DELETE } from "api/config";

export const getStoryList = async (
  storyStatus = "",
  cheeringState = "",
  classifiCation = "",
  total = "",
  storyTitle = "",
  memberName = "",
  startDate = "",
  endDate = "",
  pageSize = "",
  currentPage = "1"
) =>
  await GET(
    `/admin/story/list?storyStatus=${storyStatus}&cheeringState=${cheeringState}&classifiCation=${classifiCation}&total=${total}&storyTitle=${storyTitle}&memberName=${memberName}&startDate=${startDate}&endDate=${endDate}&pageSize=${pageSize}&page=${currentPage}`
  );

export const deleteStory = async (storyId) => {
  const ids = storyId.join(",");
  await DELETE(`/admin/story/list?storyIds=${ids}`);
};

export const getStoryDetail = async (storyId) =>
  await GET(`/admin/story/list/detail/${storyId}`);

export const storyUpdate = async ({ storyId, data }) =>
  await PUT(`/admin/story/list/detail/${storyId}`, data);

export const storyCheering = async ({ storyId }) =>
  await GET(`/admin/story/list/detail/${storyId}/cheering`);

export const storyCheeringList = async ({
  storyId,
  startDate,
  endDate,
  memberName,
  memberEmail,
  total,
  page,
  pageSize,
}) =>
  await GET(
    `/admin/story/list/detail/cheering/${storyId}?${
      startDate ? `startDate=${startDate}` : ""
    }${endDate ? `&endDate=${endDate}` : ""}${
      memberName ? `&memberName=${memberName}` : ""
    }${memberEmail ? `&memberEmail=${memberEmail}` : ""}${
      total ? `&total=${total}` : ""
    }${page ? `&page=${page}` : ""}${pageSize ? `&pageSize=${pageSize}` : ""}`
  );

export const storyCheeringCancel = async ({ cheerId, coinId }) =>
  await POST(`/admin/story/list/detail/cheering/${cheerId}?coinIds=${coinId}`);

export const storyMatchStatus = async ({ storyId }) =>
  await GET(`/admin/story/list/detail/match/${storyId}`);
