const CONST = {
  MAIN_TITLE: process.env.REACT_APP_MAIN_TITLE,

  HEADER_MENU: [
    {
      link: "/story/write",
      text: "이야기 등록",
    },
    {
      link: "/story",
      text: "콘텐츠 탐색",
    },
  ],
};

export default CONST;
