import Box from "component/atoms/box";
import Button from "component/atoms/button";
import Image from "component/atoms/image";
import Text from "component/atoms/text";
import HeaderMenu from "component/molecules/layout/headerMenu";
import useToggle from "hook/util/useToggle";
import CommonButton from "component/molecules/common/commonButton";
import { RiMenuLine } from "react-icons/ri";
import { GrClose } from "react-icons/gr";
import Line from "component/atoms/line";
import Link from "component/atoms/link";
import useSignedStore from "store/useSignedStore";
import useUrl from "hook/util/useUrl";
import { useEffect } from "react";
import useLogout from "hook/api/auth/logout";

const Header = () => {
  const { pathname, search } = useUrl();
  const { toggle: isOpenMenu, handleToggle: toggleMenu } = useToggle(false);
  const { user } = useSignedStore();

  const handleLogout = useLogout();

  useEffect(() => {
    if (isOpenMenu) {
      toggleMenu();
    }
  }, [pathname, search]);

  return (
    <Box
      size={{
        width: "100%",
        height: "98px",
      }}
      mobile={{
        size: {
          height: "76px",
        },
      }}
      as={"header"}
    >
      <Box
        size={{
          width: "100%",
        }}
        border={{
          bottom: "2px",
          style: "solid",
          color: "black01",
        }}
        position={{
          type: "fixed",
          top: "0",
          left: "0",
          zIndex: "3",
        }}
        background={{
          color: "white01",
        }}
      >
        <Box
          display={{
            display: "flex",
            justify: "space-between",
            align: "center",
          }}
          size={{
            width: "1200PX",
            height: "98px",
          }}
          margin={{
            all: "0 auto",
          }}
          pc={{
            size: {
              width: "100%",
            },
            padding: {
              all: "15px 16px",
            },
          }}
        >
          <Link link={"/"} preventScrollReset={true}>
            <Image
              src={"/asset/img/common/logo.png"}
              alt={"무무스트"}
              size={{
                width: "70px",
                height: "66px",
              }}
              mobile={{
                size: {
                  width: "42px",
                },
              }}
            />
          </Link>

          <Box>
            <Button
              display={{
                display: "none",
              }}
              other={{
                cursor: "pointer",
              }}
              mobile={{
                display: {
                  display: "block",
                },
              }}
              onClick={toggleMenu}
            >
              <RiMenuLine size={24} />
            </Button>
            <Box
              display={{
                display: "flex",
                justify: "flex-end",
                align: "center",
                gap: "0 24px",
              }}
              mobile={{
                display: {
                  display: "none",
                },
              }}
            >
              <HeaderMenu />

              <Box
                position={{
                  type: "relative",
                }}
                display={{
                  display: "flex",
                  align: "center",
                  gap: "0 40px",
                }}
                size={{
                  width: "auto",
                }}
              >
                <Link
                  background={{
                    color: "transparent",
                  }}
                  link={"/story?option=filter"}
                  typo={{
                    size: "0",
                  }}
                >
                  <Image
                    src="/asset/img/common/search.png"
                    alt="검색 버튼"
                    size={{
                      width: "24px",
                      height: "24px",
                    }}
                  />
                </Link>

                <CommonButton
                  category={"link"}
                  size={{
                    width: "105px",
                    height: "40px",
                  }}
                  typo={{
                    weight: "900",
                    space: "2px",
                  }}
                  link={user ? "/mypage" : "/login"}
                >
                  {user ? "MYPAGE" : "LOGIN"}
                </CommonButton>
              </Box>
            </Box>
          </Box>

          <Box
            position={{
              type: "fixed",
              right: "0",
              left: "0",
              top: "0",
              bottom: "0",
              zIndex: "2",
            }}
            background={{
              color: "rgba(0,0,0,0.5)",
            }}
            display={{
              display: "flex",
              justify: "flex-end",
            }}
            other={{
              visibility: isOpenMenu ? "visible" : "hidden",
            }}
            transform={{
              transition: "all 0.3s ease-in-out",
            }}
            onClick={toggleMenu}
          >
            <Box
              display={{
                display: "flex",
                direction: "column",
                align: "flex-start",
                wrap: "wrap",
              }}
              size={{
                width: "100%",
                maxWidth: "232px",
                //
                height: "100%",
              }}
              background={{
                color: "white01",
              }}
              padding={{
                all: "65px 10px 16px",
              }}
              transform={{
                translateX: isOpenMenu ? "0px" : "100%",
                transition: "all 0.3s ease-in-out",
              }}
              onClick={(e) => e.stopPropagation()}
            >
              <Button
                position={{
                  type: "absolute",
                  top: "24px",
                  right: "24px",
                }}
                background={{
                  color: "transparent",
                }}
                onClick={toggleMenu}
              >
                <GrClose size={24} />
              </Button>

              {user ? (
                <Text
                  padding={{
                    all: "0 10px",
                  }}
                  typo={{
                    size: "14px",
                    weight: "700",
                    wordBreak: "break-all",
                    color: "pink02",
                  }}
                >
                  {user.username}님
                </Text>
              ) : (
                <Text
                  padding={{
                    all: "0 10px",
                  }}
                  typo={{
                    size: "14px",
                    weight: "700",
                    wordBreak: "break-all",
                  }}
                >
                  로그인 해주세요.
                </Text>
              )}

              {user ? (
                <Box
                  display={{
                    display: "flex",
                    align: "center",
                  }}
                  padding={{
                    all: "0 10px",
                  }}
                  margin={{
                    top: "5px",
                  }}
                >
                  <Button
                    typo={{
                      size: "14px",
                    }}
                    onClick={handleLogout}
                  >
                    로그아웃
                  </Button>

                  <Line
                    direction={"col"}
                    size={"10px"}
                    background={{ color: "black01" }}
                    margin={{
                      all: "0 10px",
                    }}
                  />

                  <Link
                    typo={{
                      size: "14px",
                    }}
                    link={"/mypage"}
                  >
                    프로필
                  </Link>
                </Box>
              ) : (
                <Box
                  display={{
                    display: "flex",
                    align: "center",
                  }}
                  padding={{
                    all: "0 10px",
                  }}
                  margin={{
                    top: "5px",
                  }}
                >
                  <Link
                    typo={{
                      size: "14px",
                    }}
                    link={"/login"}
                  >
                    로그인
                  </Link>

                  <Line
                    direction={"col"}
                    size={"10px"}
                    background={{ color: "black01" }}
                    margin={{
                      all: "0 10px",
                    }}
                  />

                  <Link
                    typo={{
                      size: "14px",
                    }}
                    link={"/signup"}
                  >
                    회원가입
                  </Link>
                </Box>
              )}

              <Line
                direction={"row"}
                size={"100%"}
                background={{ color: "gray02" }}
                margin={{ all: "15px 0" }}
              />

              <Link
                padding={{
                  all: "0 10px",
                }}
                link={"/story/write"}
                preventScrollReset={true}
                typo={{
                  size: "14px",
                  weight: "700",
                }}
              >
                이야기 등록
              </Link>
              <Link
                padding={{
                  all: "0 10px",
                }}
                margin={{
                  top: "18px",
                }}
                link={"/story"}
                preventScrollReset={true}
                typo={{
                  size: "14px",
                  weight: "700",
                }}
              >
                콘텐츠 탐색
              </Link>
              <Link
                padding={{
                  all: "0 10px",
                }}
                margin={{
                  top: "18px",
                }}
                link={"/story?option=filter"}
                preventScrollReset={true}
                typo={{
                  size: "14px",
                  weight: "700",
                }}
              >
                검색
              </Link>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Header;
