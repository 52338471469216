import Box from "component/atoms/box";
import Button from "component/atoms/button";
import Text from "component/atoms/text";
import Modal from "component/molecules/common/modal";
import React from "react";
import useModalStore from "store/useModalStore";

export const ErrorContent = ({ message }) => {
  return (
    <>
      <Text
        typo={{
          size: "18px",
          weight: "700",
        }}
      >
        요청이 실패하였습니다.
      </Text>
      {message ? (
        <Text
          typo={{
            size: "14px",
            color: "gray04",
            align: "center",
          }}
        >
          {message}
        </Text>
      ) : (
        <>
          <Text
            typo={{
              size: "14px",
              color: "gray04",
              align: "center",
            }}
          >
            잘못된 접근이거나 요청하신 페이지를 찾을 수 없습니다.
          </Text>
          <Text
            typo={{
              size: "14px",
              color: "gray04",
              align: "center",
            }}
          >
            다시 한번 시도해 주시길 바랍니다.
          </Text>
        </>
      )}
    </>
  );
};

export const RejectContent = ({ type, message, isApprove }) => {
  const artist = type === "artist";

  return (
    <>
      {/* <Text
        typo={{
          size: "18px",
          weight: "700",
        }}
      >
        {artist ? "아티스트" : "창작 완료"} 신청이 {isApprove ? "승인" : "보류"}
        되었습니다.
      </Text> */}
      {/* 
      <Text
        typo={{
          size: "14px",
          color: "gray04",
          align: "center",
        }}
      >
        자세한 사항은 아래의 내용을 확인해주세요.
      </Text> */}

      <Text
        size={{
          size: "100%",
          height: "170px",
          // maxWidth: "536px",
        }}
        other={{
          overflowY: "auto",
        }}
        margin={{
          top: "22px",
        }}
        padding={{
          all: "18px 20px",
        }}
        typo={{
          size: "14px",
          align: "left",
          whiteSpace: "pre-line",
          wordBreak: "break-all",
        }}
        // background={{
        //   color: "pink02",
        // }}
        border={{
          all: "1px",
        }}
      >
        {message}
      </Text>
    </>
  );
};

const GlobalModal = () => {
  const {
    title,
    content,
    info,
    noneInfo,
    isActive,
    resetModal,
    callback = () => {},
  } = useModalStore();

  const isView = title || content || info;

  const handleResetInfo = () => {
    resetModal();
    callback();
  };

  return (
    <Modal
      title={title}
      info={info}
      visible={isView}
      isActive={isActive}
      //
      onClose={handleResetInfo}
    >
      <Box
        size={{
          width: "100%",
          maxWidth: "600px",
        }}
        padding={{
          all: noneInfo ? "0 0 22px" : "56px 0 62px",
        }}
        typo={{
          align: "center",
          whiteSpace: "pre-line",
        }}
      >
        {content}
      </Box>

      <Button
        size={{
          width: "100%",
          height: "63px",
        }}
        border={{
          all: "1px",
          style: "solid",
          color: "black01",
        }}
        background={{
          color: isActive ? "white01" : "pink02",
        }}
        typo={{
          size: "18px",
          weight: "700",
        }}
        longMobile={{
          size: {
            width: "100%",
            height: "50px",
          },
          typo: {
            size: "14px",
          },
        }}
        onClick={handleResetInfo}
      >
        확인
      </Button>
    </Modal>
  );
};

export default GlobalModal;
