import { css } from "styled-components";

const editor = css`
  ._se_percentage {
    display: none !important;
  }

  ._se_image_alt {
    display: block !important;
  }

  .se-dialog-body > div > .se-dialog-form {
    display: block !important;
  }

  .se-dialog-image div .se-dialog-form:last-child {
    display: none !important;
  }

  .se-dialog-image .se-dialog-tabs {
    display: none !important;
  }

  .se-link-preview {
    display: none !important;
  }

  .se-component {
    figure {
      width: 100% !important;
    }
  }

  iframe {
    aspect-ratio: 679/381 !important;
  }

  .se-image-container {
    max-width: 100% !important;

    figure {
      max-width: 100% !important;

      img {
        max-width: 100% !important;
      }
    }
  }

  .commentList {
    .se-video-container {
      figure {
        max-width: 100% !important;

        iframe {
          max-width: 100% !important;
        }
      }
    }
  }

  .moreList {
    .se-video-container,
    .se-image-container {
      max-width: 100% !important;

      figure {
        width: 100% !important;
        max-width: 100% !important;

        img {
          max-width: 100% !important;
        }

        iframe {
          max-width: 100% !important;
        }
      }
    }
  }

  .se-video-container {
    height: 100% !important;
    figure {
      padding: 0 !important;
      height: 100% !important;
    }
  }
  .sun-editor {
    strong {
      font-weight: bold !important;
    }
    em {
      font-style: italic !important;
    }
    h1 {
      font-size: 2em;
      font-weight: 700;
    }
    h2 {
      font-size: 1.5em;
      font-weight: 700;
    }
    h3 {
      font-size: 1.17em;
      font-weight: 700;
    }
    h4 {
      font-size: 1em;
      font-weight: 700;
    }
    h5 {
      font-size: 0.83em;
      font-weight: 700;
    }
    h6 {
      font-size: 0.67em;
      font-weight: 700;
    }
    pre {
      display: block;
      padding: 8px;
      margin: 0 0 10px;
      font-family: monospace;
      color: #666;
      line-height: 1.45;
      background-color: #f9f9f9;
      border: 1px solid #e1e1e1;
      border-radius: 2px;
      white-space: pre-wrap !important;
      word-wrap: break-word;
      overflow: visible;
    }
    blockquote {
      display: block;
      font-family: inherit;
      font-size: inherit;
      color: #999;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding: 0 5px 0 20px;
      border: solid #b1b1b1;
      border-width: 0 0 0 5px;
    }
    body {
      line-height: inherit !important;
    }
    ol {
      list-style: decimal;
      padding-left: 40px;

      display: block;
      list-style-type: decimal;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }
    ul {
      list-style: inherit;
      padding-left: 40px;
    }
    table {
      display: table;
      table-layout: auto;
      font-size: inherit !important;
      text-indent: initial;
      border: 1px solid #ccc;
    }

    td {
      border: 1px solid #e1e1e1;
      padding: 0.3em 0.5em;
      background-clip: padding-box;
      line-height: 1.5;
    }
    .sun-editor-editable b {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      font-family: inherit;
      font-size: inherit;
      color: inherit;
    }
    sub {
      font-size: 0.75em;
      vertical-align: sub;
      line-height: 0;
    }
    sup {
      font-size: 0.75em;
      vertical-align: super;
      line-height: 0;
    }
    hr {
      border: 0;
      height: 1px;
      background-color: #ccc;
    }
    td {
      display: table-cell;
    }
    p {
      margin: 0 0 1em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
  }
  .editor-content {
    strong {
      font-weight: bold !important;
    }
    em {
      font-style: italic !important;
    }
    h1 {
      font-size: 2em;
      font-weight: 700;
    }
    h2 {
      font-size: 1.5em;
      font-weight: 700;
    }
    h3 {
      font-size: 1.17em;
      font-weight: 700;
    }
    h4 {
      font-size: 1em;
      font-weight: 700;
    }
    h5 {
      font-size: 0.83em;
      font-weight: 700;
    }
    h6 {
      font-size: 0.67em;
      font-weight: 700;
    }
    pre {
      display: block;
      padding: 8px;
      margin: 0 0 10px;
      font-family: monospace;
      color: #666;
      line-height: 1.45;
      background-color: #f9f9f9;
      border: 1px solid #e1e1e1;
      border-radius: 2px;
      white-space: pre-wrap !important;
      word-wrap: break-word;
      overflow: visible;
    }
    blockquote {
      display: block;
      font-family: inherit;
      font-size: inherit;
      color: #999;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0;
      margin-inline-end: 0;
      padding: 0 5px 0 20px;
      border: solid #b1b1b1;
      border-width: 0 0 0 5px;
    }
    body {
      line-height: inherit !important;
    }
    ol {
      list-style: decimal;
      padding-left: 40px;

      display: block;
      list-style-type: decimal;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
      padding-inline-start: 40px;
    }
    ul {
      list-style: inherit;
      padding-left: 40px;
    }
    table {
      display: table;
      table-layout: auto;
      font-size: inherit !important;
      text-indent: initial;
      border: 1px solid #ccc;
    }

    td {
      border: 1px solid #e1e1e1;
      padding: 0.3em 0.5em;
      background-clip: padding-box;
      line-height: 1.5;
    }
    .sun-editor-editable b {
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      font-family: inherit;
      font-size: inherit;
      color: inherit;
    }
    sub {
      font-size: 0.75em;
      vertical-align: sub;
      line-height: 0;
    }
    sup {
      font-size: 0.75em;
      vertical-align: super;
      line-height: 0;
    }
    hr {
      border: 0;
      height: 1px;
      background-color: #ccc;
    }
    td {
      display: table-cell;
    }
    p {
      margin: 0 0 1em;
      margin-block-start: 1em;
      margin-block-end: 1em;
      margin-inline-start: 0px;
      margin-inline-end: 0px;
    }
  }
`;

export default editor;
