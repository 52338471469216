import Box from "component/atoms/box";
import Button from "component/atoms/button";
import Image from "component/atoms/image";
import Link from "component/atoms/link";
import Line from "component/atoms/line";
import Thumbnail from "component/molecules/common/thumbnail";
import ProjectTitle from "component/molecules/main/projectTitle";
import ProjectUser from "component/molecules/main/projectUser";
import PersonalityList from "component/molecules/common/selectProjectList";
import IconList from "component/molecules/common/iconList";
import { memo } from "react";
import convertImage from "utils/convertImage";

const FadeSlider = (props) => {
  const { array, prevArray, handlePrevSlide, handleNextSlide } = props;

  return (
    <Box
      position={{
        type: "absolute",
        isCenterX: true,
        top: "65px",
      }}
      display={{
        display: "flex",
        justify: "space-between",
        gap: "20px",
      }}
      size={{
        width: "100%",
      }}
      tablet={{
        display: {
          display: "none",
        },
      }}
    >
      <Button
        position={{
          type: "relative",
        }}
        size={{
          width: "270px",
          aspectRatio: "2/1",
        }}
        // pc={{
        //   size: {
        //     height: "270px",
        //     width: "auto",
        //     aspectRatio: "0.7/1",
        //   },
        // }}
        onClick={handlePrevSlide}
      >
        <Box
          position={{
            type: "absolute",
            top: "0",
            left: "0",
            zIndex: "2",
          }}
          size={{
            width: "100%",
            height: "100%",
          }}
          background={{
            color: "rgba(255, 228, 246, 0.50)",
          }}
        />
        <Box
          position={{ type: "absolute", top: "0", left: "0", zIndex: "1" }}
          size={{
            width: "100%",
            height: "100%",
          }}
          animation="fadeOut"
          key={`${prevArray[0].storyId}-${prevArray[0].key}`}
        >
          <Thumbnail
            src={convertImage(prevArray[0].thumbnail)}
            status={prevArray[0].storyStatus}
            isFunding={prevArray[0].isFunding}
            funding={prevArray[0].funding}
          />
        </Box>

        <Box
          position={{ type: "absolute", top: "0", left: "0", zIndex: "1" }}
          size={{
            width: "100%",
            height: "100%",
          }}
          animation="fadeIn"
          key={`${array[0].storyId}-${array[0].key}`}
        >
          <Thumbnail
            src={convertImage(array[0].thumbnail)}
            status={array[0].storyStatus}
            isFunding={array[0].isFunding}
            funding={array[0].funding}
          />
        </Box>
      </Button>
      <Box
        position={{
          type: "relative",
        }}
        size={{
          width: "540px",
          height: "100%",
          aspectRatio: "2/1",
        }}
        display={{
          display: "flex",
        }}
      >
        <Link
          position={{
            type: "relative",
          }}
          display={{
            display: "flex",
          }}
          size={{
            width: "100%",
            height: "100%",
          }}
          link={`/story/${array[1].storyId}`}
        >
          <Box
            size={{
              width: "50%",
            }}
          >
            <Box
              position={{
                type: "absolute",
                top: "0",
                left: "0",
                zIndex: "1",
              }}
              size={{
                width: "50%",
                height: "100%",
              }}
              animation="fadeOut"
              key={`${prevArray[1].storyId}-${prevArray[1].key}`}
            >
              <Thumbnail
                src={convertImage(prevArray[1].thumbnail)}
                status={prevArray[1].storyStatus}
                isFunding={prevArray[1].isFunding}
                funding={prevArray[1].funding}
              />
            </Box>
            <Box
              position={{
                type: "absolute",
                top: "0",
                left: "0",
                zIndex: "1",
              }}
              size={{
                width: "50%",
                height: "100%",
              }}
              animation="fadeIn"
              key={`${array[1].storyId}-${array[1].key}`}
            >
              <Thumbnail
                src={convertImage(array[1].thumbnail)}
                status={array[1].storyStatus}
                isFunding={array[1].isFunding}
                funding={array[1].funding}
              />
            </Box>
          </Box>
          <Box
            position={{
              type: "relative",
            }}
            display={{
              display: "flex",
              direction: "column",
              justify: "space-between",
              align: "flex-start",
            }}
            size={{
              width: "50%",
              height: "100%",
            }}
            border={{
              all: "2px",
            }}
            padding={{
              all: "12px 20px",
            }}
          >
            {array[1].moomoostPick && (
              <Box
                position={{
                  type: "absolute",
                  bottom: "5px",
                  right: "-16px",
                  zIndex: "1",
                }}
              >
                <Image src={"/asset/img/story/pick.svg"} />
              </Box>
            )}
            <ProjectTitle
              title={array[1].storyTitle}
              id={array[1].storyId}
              liked={array[1].liked}
              showLike
            />

            <Line
              direction={"row"}
              size={"100%"}
              margin={{ all: "10px 0" }}
              background={{ color: "gray02" }}
            />

            <ProjectUser member={array[1].member} artist={array[1].artist} />

            <Line
              direction="row"
              size="100%"
              margin={{ all: "10px 0" }}
              background={{ color: "gray02" }}
            />

            <PersonalityList
              mood={array[1].storyMood}
              personality={array[1].storyPersonality.slice(0, 3)}
              category={array[1].storyCategory}
            />

            <Line
              direction={"row"}
              size={"100%"}
              margin={{ all: "10px 0 8px" }}
              background={{ color: "gray02" }}
            />

            <IconList
              size={20}
              icons={[
                { icon: "view", value: array[1].storyViewCount },
                { icon: "like", value: array[1].storyLikes },
              ]}
            />
          </Box>
        </Link>
      </Box>
      <Button
        position={{
          type: "relative",
        }}
        size={{
          width: "270px",
          aspectRatio: "2/1",
        }}
        // pc={{
        //   size: {
        //     height: "270px",
        //     width: "auto",
        //     aspectRatio: "0.7/1",
        //   },
        // }}
        onClick={handleNextSlide}
      >
        <Box
          position={{
            type: "absolute",
            top: "0",
            left: "0",
            zIndex: "2",
          }}
          size={{
            width: "100%",
            height: "100%",
          }}
          background={{
            color: "rgba(255, 228, 246, 0.50)",
          }}
        />
        <Box
          position={{ type: "absolute", top: "0", left: "0", zIndex: "1" }}
          size={{
            width: "100%",
            height: "100%",
          }}
          animation="fadeOut"
          key={`${prevArray[2].storyId}-${prevArray[2].key}`}
        >
          <Thumbnail
            src={convertImage(prevArray[2].thumbnail)}
            status={prevArray[2].storyStatus}
            isFunding={prevArray[2].isFunding}
            funding={prevArray[2].funding}
          />
        </Box>

        <Box
          position={{ type: "absolute", top: "0", left: "0", zIndex: "1" }}
          size={{
            width: "100%",
            height: "100%",
          }}
          animation="fadeIn"
          key={`${array[2].storyId}-${array[2].key}`}
        >
          <Thumbnail
            src={convertImage(array[2].thumbnail)}
            status={array[2].storyStatus}
            isFunding={array[2].isFunding}
            funding={array[2].funding}
          />
        </Box>
      </Button>
    </Box>
  );
};

export default memo(FadeSlider);
