import Box from "component/atoms/box";
import Button from "component/atoms/button";
import Image from "component/atoms/image";
import Link from "component/atoms/link";
import Text from "component/atoms/text";
import Blank from "component/molecules/common/blank";
import CommonButton from "component/molecules/common/commonButton";
import List from "component/molecules/common/list";
import Modal from "component/molecules/common/modal";
import LineTitle from "component/organisms/common/lineTitle";
import {
  useMatchingArtist,
  useMatchingAssist,
} from "hook/api/story/matchingArtist";
import useSelectStoryForm from "hook/api/story/select";
import useToggle from "hook/util/useToggle";
import useUrl from "hook/util/useUrl";
import { BiChevronLeft } from "react-icons/bi";
import convertImage from "utils/convertImage";
import Additional from "../modal/additional";
import Api from "component/molecules/common/api";
import { useAddition } from "hook/api/story/additional";

const Matching = () => {
  const {
    params: { id },
    query: { assist },
  } = useUrl();

  const { selectMessage, setSelectMessage, handleSubmit } =
    useSelectStoryForm(0);
  const { toggle, handleToggle } = useToggle();

  return (
    <>
      <List
        api={assist ? useMatchingAssist : useMatchingArtist}
        params={{
          id,
        }}
      >
        {(list) =>
          list && (
            <>
              <Box
                margin={{
                  top: "80px",
                }}
                longMobile={{
                  padding: {
                    all: "0 16px",
                  },
                }}
              >
                <LineTitle title="아티스트 매칭하기">
                  <Link
                    link={`/story/${id}`}
                    display={{
                      display: "flex",
                      align: "center",
                    }}
                    typo={{
                      size: "12px",
                      weight: "700",
                    }}
                  >
                    <BiChevronLeft size={15} />
                    돌아가기
                  </Link>
                </LineTitle>
              </Box>

              <Box
                display={{
                  display: "flex",
                }}
                size={{
                  width: "100%",
                  height: "757px",
                }}
                border={{
                  all: "1px",
                }}
                longMobile={{
                  display: {
                    direction: "column",
                  },
                  padding: {
                    all: "0 16px",
                  },
                  border: {
                    all: "0",
                  },
                }}
              >
                <Box
                  size={{
                    width: "50%",
                    height: "100%",
                  }}
                  display={{
                    display: "flex",
                    justify: list.length === 0 ? "center" : "flex-start",
                  }}
                  border={{
                    right: "1px",
                  }}
                  padding={{
                    all: `${assist ? "85px" : "81px"} ${
                      list.length >= 9 ? "2px" : "20px"
                    } 46px 0px`,
                  }}
                  position={{
                    type: "relative",
                  }}
                  longMobile={{
                    size: {
                      width: "100%",
                    },
                    padding: {
                      all: `${assist ? "65px" : "51px"} 0 0px`,
                    },
                    border: {
                      all: "1px",
                    },
                  }}
                >
                  <Box
                    size={{
                      width: "100%",
                    }}
                    position={{
                      type: "absolute",
                      top: "35px",
                      left: "0",
                    }}
                    padding={{
                      all: "0 20px",
                    }}
                    longMobile={{
                      position: {
                        top: "15px",
                      },
                    }}
                  >
                    <LineTitle
                      title={"매칭요청 아티스트"}
                      margin={{
                        bottom: "0",
                      }}
                      mobileTypo={{
                        size: "16px",
                      }}
                    >
                      {assist && (
                        <CommonButton
                          category={"button"}
                          size={{
                            width: "128px",
                            height: "38px",
                          }}
                          typo={{
                            size: "14px",
                            weight: "700",
                          }}
                          onClick={handleToggle}
                        >
                          모집 글 수정
                        </CommonButton>
                      )}
                    </LineTitle>
                  </Box>
                  {list.length === 0 && (
                    <Box
                      longMobile={{
                        margin: {
                          bottom: "20px",
                        },
                      }}
                    >
                      <Blank title={"현재 매칭 신청한 아티스트가 없습니다. "} />
                    </Box>
                  )}
                  {list.length !== 0 && (
                    <Box
                      size={{
                        width: "100%",
                        height: "630px",
                      }}
                      other={{
                        overflowY: "auto",
                      }}
                      isCustomScroll
                      longMobile={{
                        size: {
                          width: "100%",
                          height: "178px",
                        },
                      }}
                    >
                      {list.map(
                        (
                          { matchId, matchTitle, matchContent, member },
                          idx
                        ) => (
                          <Box
                            size={{
                              width: "100%",
                            }}
                            padding={{
                              left: "20px",
                              right: list.length > 8 ? "8px" : "0px",
                            }}
                            longMobile={{
                              padding: {
                                left: "20px",
                                right: "20px",
                              },
                            }}
                            key={matchId}
                          >
                            <Button
                              size={{
                                width: "100%",
                              }}
                              display={{
                                display: "flex",
                                align: "center",
                                justify: "space-between",
                              }}
                              padding={{
                                all: "10px 15px",
                              }}
                              border={{
                                bottom: "1px",
                                color: "gray02",
                              }}
                              background={{
                                color:
                                  idx === selectMessage ? "pink02" : "white01",
                              }}
                              key={id}
                              onClick={() => setSelectMessage(idx)}
                            >
                              <Image
                                src={convertImage(member[0].memberProfile)}
                                size={{
                                  width: "50px",
                                  height: "50px",
                                }}
                                border={{
                                  radius: "50%",
                                }}
                                longMobile={{
                                  size: {
                                    width: "35px",
                                    height: "35px",
                                  },
                                }}
                              />
                              <Box
                                display={{
                                  display: "flex",
                                  direction: "column",
                                  justify: "center",
                                }}
                                size={{
                                  width: "calc(100% - 50px - 28px - 128px)",
                                }}
                                longMobile={{
                                  size: {
                                    width: "calc(100% - 50px - 28px - 60px)",
                                  },
                                }}
                              >
                                <Text
                                  typo={{
                                    size: "14px",
                                    weight: "700",
                                    lineClamp: 1,
                                    wordBreak: "break-all",
                                    align: "left",
                                  }}
                                >
                                  {member[0].artistName}
                                </Text>
                                {/* <Text
                                typo={{
                                  size: "14px",
                                  weight: "400",
                                  lineClamp: 1,
                                  wordBreak: "break-all",
                                  align: "left",
                                }}
                              >
                                {music}
                              </Text> */}
                              </Box>

                              <CommonButton
                                category={"link"}
                                size={{
                                  width: "128px",
                                  height: "38px",
                                }}
                                typo={{
                                  size: "14px",
                                  weight: "700",
                                }}
                                longMobile={{
                                  size: {
                                    width: "60px",
                                  },
                                }}
                                link={`/mypage/${member[0].memberId}`}
                              >
                                프로필{" "}
                                <Text
                                  longMobile={{
                                    display: {
                                      display: "none",
                                    },
                                  }}
                                >
                                  보러가기
                                </Text>
                              </CommonButton>
                            </Button>
                          </Box>
                        )
                      )}
                    </Box>
                  )}
                </Box>
                <Box
                  size={{
                    width: "50%",
                    height: "100%",
                  }}
                  display={{
                    display: "flex",
                    direction: "column",
                    align: "center",
                    justify: list.length === 0 ? "flex-start" : "center",
                  }}
                  padding={{
                    all: list.length === 0 ? "85px 0 45px" : "35px 22px",
                  }}
                  longMobile={{
                    size: {
                      width: "100%",
                    },
                    border: {
                      all: "1px",
                    },
                    padding: {
                      all: "20px 15px",
                    },
                    margin: { top: "20px" },
                  }}
                >
                  {list.length === 0 && (
                    <Blank title={"현재 매칭 신청한 아티스트가 없습니다. "} />
                  )}
                  {list.length !== 0 && (
                    <>
                      <LineTitle
                        title={list[selectMessage].member[0].artistName}
                        margin={{
                          bottom: "16px",
                        }}
                        mobileTypo={{
                          size: "16px",
                        }}
                      />

                      <Text
                        size={{
                          width: "100%",
                        }}
                        typo={{
                          size: "14px",
                          weight: "700",
                        }}
                        margin={{
                          bottom: "5px",
                        }}
                      >
                        제목
                      </Text>
                      <Box
                        size={{
                          width: "100%",
                        }}
                        padding={{
                          all: "12px 16px",
                        }}
                        margin={{
                          bottom: "20px",
                        }}
                        border={{
                          all: "1px",
                        }}
                      >
                        <Text
                          typo={{
                            size: "14px",
                            weight: "400",
                          }}
                        >
                          {list[selectMessage].matchTitle}
                        </Text>
                      </Box>

                      <Text
                        size={{
                          width: "100%",
                        }}
                        typo={{
                          size: "14px",
                          weight: "700",
                        }}
                        margin={{
                          bottom: "5px",
                        }}
                      >
                        내용
                      </Text>
                      <Box
                        size={{
                          width: "100%",
                          height: "437px",
                        }}
                        padding={{
                          all: "12px 16px",
                        }}
                        margin={{
                          bottom: "20px",
                        }}
                        border={{
                          all: "1px",
                        }}
                        other={{
                          overflowY: "auto",
                        }}
                        longMobile={{
                          size: {
                            width: "100%",
                            height: "234px",
                          },
                        }}
                      >
                        <Text
                          typo={{
                            size: "14px",
                            weight: "400",
                            whiteSpace: "pre-line",
                            wordBreak: "break-all",
                          }}
                        >
                          {list[selectMessage].matchContent}
                        </Text>
                      </Box>

                      <CommonButton
                        category={"button"}
                        size={{
                          width: "100%",
                          height: "63px",
                        }}
                        typo={{
                          size: "18px",
                        }}
                        longMobile={{
                          size: {
                            width: "100%",
                            height: "50px",
                          },
                          typo: {
                            size: "14px",
                          },
                        }}
                        isActive
                        onClick={() =>
                          handleSubmit(
                            assist
                              ? list[selectMessage].assistId
                              : list[selectMessage].matchId
                          )
                        }
                      >
                        아티스트 매칭 확정 보내기
                      </CommonButton>
                    </>
                  )}
                </Box>
              </Box>

              {assist && (
                <Api api={useAddition} params={id}>
                  {(data) => (
                    <Modal
                      title={"멤버 모집 수정하기"}
                      width={"600px"}
                      visible={toggle}
                      isDisableBackgroundClose={true}
                      onClose={handleToggle}
                    >
                      <Additional
                        onClose={handleToggle}
                        key={toggle}
                        defaultValues={data}
                      />
                    </Modal>
                  )}
                </Api>
              )}
            </>
          )
        }
      </List>
    </>
  );
};

export default Matching;
