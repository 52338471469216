import { commentLike, storyLike } from "api/story";
import { ErrorContent } from "component/organisms/common/globalModal";
import useUrl from "hook/util/useUrl";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";

export const useLikeCommentForm = () => {
  const {
    query: { comment },
  } = useUrl();
  const queryClient = useQueryClient();
  const mutation = useMutation(commentLike, {
    onSuccess: () => {
      queryClient.invalidateQueries("selectComment");
    },
  });

  const submit = () => {
    mutation.mutate({
      commentId: comment,
    });
  };

  return submit;
};

export const useLikeStoryForm = () => {
  const {
    params: { id },
  } = useUrl();
  const { setModal } = useModalStore();
  const queryClient = useQueryClient();
  const mutation = useMutation(storyLike, {
    onSuccess: () => {
      queryClient.invalidateQueries(["story", id]);
      queryClient.invalidateQueries(["likeList"]);
    },
    onError: () => {
      setModal({
        title: "좋아요",
        content: <ErrorContent />,
      });
    },
  });

  const submit = (storyId) => {
    mutation.mutate({
      id: storyId ?? id,
    });
  };

  return submit;
};
