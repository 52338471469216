import { forwardRef } from "react";
import TextStyle from "./text.style";

import PropTypes from "prop-types";

const themeStyle = {
  adminTitle: {
    typo: {
      size: "16px",
      weight: "700",
      color: "black02",
    },
    margin: {
      bottom: "10px",
    },
  },
};

const themeMerge = (theme) => {
  if (typeof theme === "string") {
    return themeStyle[theme];
  } else if (Array.isArray(theme)) {
    return theme.reduce((acc, cur) => {
      const updateTheme = themeStyle[cur];

      Object.entries(updateTheme).forEach(([key, value]) => {
        if (acc[key] === undefined) {
          acc[key] = { ...value };
        } else {
          acc[key] = { ...acc[key], ...value };
        }
      });

      return {
        ...acc,
      };
    }, {});
  }
};

const styleMerge = (style, theme) => {
  const updateStyle = { ...style };
  if (theme) {
    Object.entries(theme).forEach(([key, value]) => {
      const currentStyle = updateStyle[key] ?? {};
      updateStyle[key] = {
        ...value,
        ...currentStyle,
      };
    });
  }

  return updateStyle;
};

const Text = forwardRef((props, ref) => {
  const {
    children,
    as = "p",
    //
    theme,
    //

    padding,
    border,
    display,
    background,
    position,
    other,
    pc,
    tablet,
    longMobile,
    mobile,
    hover,
  } = props;

  const currentTheme = themeMerge(theme);

  const { size, typo, margin } = styleMerge(props, currentTheme);

  return (
    <TextStyle
      ref={ref}
      as={as}
      $size={size}
      $typography={typo}
      $margin={margin}
      $padding={padding}
      $border={border}
      $other={other}
      $display={display}
      $background={background}
      $position={position}
      $pc={pc}
      $tablet={tablet}
      $longMobile={longMobile}
      $mobile={mobile}
      $hover={hover}
    >
      {children}
    </TextStyle>
  );
});

Text.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
    PropTypes.oneOf([null, undefined]),
  ]),
  as: PropTypes.oneOf(["p", "span", "h1", "h2", "h3", "h4", "h5", "h6"]),
  ...TextStyle.propTypes,
};

export default Text;
