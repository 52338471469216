import Box from "component/atoms/box";
import Image from "component/atoms/image";
import Text from "component/atoms/text";
import React, { useState, useEffect, useRef } from "react";

const AdminDropdown = (props) => {
  const { margin, size, category, init, onSelect, menuSize } = props;
  const [isOpen, setIsOpen] = useState(false);

  // 드롭다운 초기 선택값을 전체로 설정
  const [selected, setSelected] = useState(category[0]);

  useEffect(() => {
    setSelected(selected);
  }, [category]);

  const handleDropdownOpen = () => {
    setIsOpen(!isOpen);
  };

  const changeSelected = (category) => {
    setSelected(category);
    setIsOpen(false);
    onSelect(category); // 부모 컴포넌트로 선택된 카테고리 전달
  };

  // 외부 클릭을 감지하여 토글 닫히도록 처리
  const dropdownRef = useRef(null);

  // 외부 클릭 감지 함수
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    // 이벤트 리스너 등록
    document.addEventListener("mousedown", handleClickOutside);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (init) {
      setSelected(init);
    }
  }, [init]);

  return (
    <Box
      display={{
        display: "flex",
      }}
      margin={margin}
      ref={dropdownRef}
    >
      <Box
        size={size}
        display={{
          display: "flex",
          direction: "row",
          align: "center",
          justify: "space-between",
        }}
        //
        position={{
          type: "relative",
          zIndex: "2",
        }}
        //
        padding={{
          left: "8px",
          right: "8px",
        }}
        margin={{
          right: "4px",
        }}
        //
        border={{
          all: "1px",
          style: "solid",
          color: "gray10",
          radius: "3px",
        }}
        background={{
          color: "white",
        }}
        //
        other={{
          overflow: "hidden",
          cursor: "pointer",
        }}
        onClick={handleDropdownOpen}
      >
        <Text
          margin={{
            left: "2px",
          }}
          typo={{
            size: "12px",
            weight: 400,
            color: "black02",
          }}
        >
          {/* 선택된 카테고리 표시 */}
          {selected}
        </Text>
        <Image src="/asset/img/admin/dropdown/dropdown_low.svg" />
      </Box>
      {isOpen && (
        <Box
          // 드롭다운 메뉴 사이즈
          size={menuSize}
          //
          position={{
            type: "absolute",
            zIndex: "1",
          }}
          //
          margin={{
            top: `calc(${size.height} + 0px)`,
            right: "4px",
          }}
          padding={{
            bottom: "8px",
          }}
          //
          border={{
            all: "1px",
            style: "solid",
            color: "gray10",
            radius: "3px",
          }}
          background={{
            color: "white",
          }}
        >
          {category.map((category, index) => (
            <Box
              key={index}
              onClick={() => changeSelected(category)}
              size={{
                width: "100%",
                height: "auto",
              }}
              typo={{
                align: "left",
              }}
              padding={{
                left: "10px",
              }}
              other={{
                cursor: "pointer",
              }}
            >
              <Text
                typo={{
                  size: "12px",
                  weight: 400,
                  color: "black02",
                }}
                margin={{
                  top: "8px",
                }}
              >
                {category}
              </Text>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

AdminDropdown.propTypes = {
  ...Box.propTypes,
};

export default AdminDropdown;
