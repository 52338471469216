import { matchingArtist, matchingAssist } from "api/story";
import { useQuery } from "react-query";

export const useMatchingAssist = ({ id }) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["matchingAssist", id],
    () => matchingAssist(id)
  );

  const list = data?.data;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useMatchingArtist = ({ id }) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["matchingArtist", id],
    () => matchingArtist(id)
  );

  const list = data?.data;

  return {
    list,
    isLoading,
    isSuccess,
    isError,
  };
};
