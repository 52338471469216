import Button from "component/atoms/button";
import Box from "component/atoms/box";
import React from "react";
import Image from "component/atoms/image";

const AdminPagination = ({ totalPages, currentPage, onPageChange }) => {
  const pageNumbers = [];

  // 페이지 번호 생성
  for (let i = 1; i <= totalPages; i++) {
    pageNumbers.push(i);
  }

  return (
    <Box
      display={{
        display: "flex",
        align: "center",
        justify: "center",
      }}
      size={{
        width: "1024px",
      }}
      margin={{
        bottom: "75px",
      }}
    >
      <Button
        onClick={() => onPageChange(1)}
        disabled={currentPage === 1}
        margin={{
          right: "20px",
        }}
      >
        <Image src={"/asset/img/admin/pagination/first.svg"} />
      </Button>
      <Button
        onClick={() => {
          if (currentPage > 1) onPageChange(currentPage - 1);
        }}
        disabled={currentPage === 1}
        margin={{
          right: "35px",
        }}
      >
        <Image src={"/asset/img/admin/pagination/prev.svg"} />
      </Button>
      {pageNumbers.map((number) => (
        <Button
          key={number}
          onClick={() => onPageChange(number)}
          disabled={currentPage === number}
          typo={{
            size: "13px",
            weight: "400",
            color: currentPage === number ? "pink03" : "#8B8B8B",
          }}
          margin={{
            right: "35px",
          }}
        >
          {number}
        </Button>
      ))}
      <Button
        onClick={() => {
          if (currentPage < totalPages) onPageChange(currentPage + 1);
        }}
        disabled={currentPage === totalPages}
        margin={{
          right: "20px",
        }}
      >
        <Image src={"/asset/img/admin/pagination/next.svg"} />
      </Button>
      <Button
        onClick={() => onPageChange(totalPages)}
        disabled={currentPage === totalPages}
      >
        <Image src={"/asset/img/admin/pagination/last.svg"} />
      </Button>
    </Box>
  );
};

export default AdminPagination;
