import Button from "component/atoms/button";
import Link from "component/atoms/link";
import Text from "component/atoms/text";

const CATEGORY = {
  button: Button,
  link: Link,
};

const CommonButton = (props) => {
  const {
    category,
    size,
    typo,
    padding,
    //
    pc,
    tablet,
    longMobile,
    mobile,
    //
    isActive,
    isDisabled,
    children,
    ...option
  } = props;

  const CurrentCategory = CATEGORY[category];

  return (
    <CurrentCategory
      display={{
        display: "flex",
        justify: "center",
        align: "center",
      }}
      padding={{
        all: "5px",
        ...padding,
      }}
      border={{
        all: "1px",
        style: "solid",
        color: isDisabled ? "gray03" : "black01",
      }}
      background={{
        color: isDisabled ? "gray01" : isActive ? "pink02" : "white",
      }}
      typo={{
        size: "15px",
        weight: "700",
        color: isDisabled ? "gray03" : "black01",
        ...typo,
      }}
      size={size}
      //
      pc={pc}
      tablet={tablet}
      longMobile={longMobile}
      mobile={mobile}
      {...option}
    >
      {children}
    </CurrentCategory>
  );
};

export default CommonButton;
