import Box from "component/atoms/box";
import Line from "component/atoms/line";
import Text from "component/atoms/text";

const ProjectUser = (props) => {
  const { member, artist, assist } = props;

  return (
    <Text
      // display={{
      //   display: "flex",
      //   align: "center",
      // }}
      typo={{
        size: "12px",
        weight: "400",
        lineClamp: "1",
        wordBreak: "break-all",
      }}
      longMobile={{
        typo: {
          size: "10px",
          lineHeight: "13px",
        },
      }}
    >
      @{member?.memberName}
      {artist && (
        <>
          <Text
            as="span"
            typo={{
              lineHeight: "13px",
              wordBreak: "break-all",
              verticalAlign: "middle",
            }}
            size={{
              width: "1px",
              height: "13px",
            }}
            margin={{
              all: "0 10px",
            }}
            border={{
              left: "1px",
              color: "gray02",
            }}
          ></Text>
          @{artist.artistName}
        </>
      )}
      {assist && (
        <>
          <Text
            as="span"
            typo={{
              lineHeight: "13px",
              wordBreak: "break-all",
              verticalAlign: "middle",
            }}
            size={{
              width: "1px",
              height: "13px",
            }}
            margin={{
              all: "0 10px",
            }}
            border={{
              left: "1px",
              color: "gray02",
            }}
          ></Text>
          @{assist.assistName}
        </>
      )}
    </Text>
  );
};

export default ProjectUser;
