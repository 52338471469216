import { sendCode } from "api/auth";
import { ErrorContent } from "component/organisms/common/globalModal";
import { useFormContext } from "react-hook-form";
import { useMutation } from "react-query";
import useModalStore from "store/useModalStore";

const useSendCode = () => {
  const { watch, getValues, setValue } = useFormContext();
  const { setModal } = useModalStore();
  const sendCodeMutation = useMutation(sendCode, {
    onSuccess: () => {
      setValue("sendMail", true);
    },
    onError: ({ response: { data } }) => {
      setModal({
        title: "이메일 인증",
        content: <ErrorContent message={data.message} />,
      });
    },
  });

  const isActive = watch("email");

  const submit = () => {
    const email = getValues("email");

    sendCodeMutation.mutate({ email });
  };

  return {
    isActive,
    //
    submit,
  };
};

export default useSendCode;
