import Box from "component/atoms/box";
import Button from "component/atoms/button";
import Text from "component/atoms/text";
import CommonButton from "component/molecules/common/commonButton";
import LabelText from "component/molecules/common/labelText";
import InputField from "component/organisms/common/inputField";
import LineTitle from "component/organisms/common/lineTitle";
import ThumbnailField from "component/organisms/common/thumbnailField";
import useCheckCode from "hook/api/auth/checkCode";
import useSendCode from "hook/api/auth/sendCode";
import useToggle from "hook/util/useToggle";
import { useFormContext } from "react-hook-form";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

const BasicInfo = (props) => {
  const { required, isPassword, isPasswordConfirm } = props;
  const { submit, isActive: isActiveSendCode } = useSendCode();
  const { submit: checkCodeSubmit, isActive: isActiveCheckCode } =
    useCheckCode();

  const { setValue, watch } = useFormContext();
  const { toggle, handleToggle } = useToggle();

  const isArtist = watch("isArtist");
  const sendMail = watch("sendMail");

  const check = watch("check");

  const updateArtist = (isArtist) => {
    setValue("isArtist", isArtist);
  };

  return (
    <Box
      display={{
        display: "flex",
        direction: "column",
        justify: "center",
      }}
      size={{
        width: "100%",
      }}
      margin={{
        top: "70px",
        bottom: toggle ? "0px" : "50px",
      }}
      longMobile={{
        top: "0",
      }}
    >
      <Text
        typo={{
          size: "28px",
          weight: "700",
          align: "center",
        }}
        margin={{
          bottom: "58px",
        }}
        longMobile={{
          typo: {
            size: "20px",
          },
          margin: {
            bottom: "50px",
          },
        }}
      >
        회원 가입
      </Text>

      <LineTitle
        title={"기본 정보"}
        typo={{
          size: "24px",
          weight: "700",
        }}
        margin={{
          bottom: "20px",
        }}
        longMobile={{
          margin: {
            bottom: "55px",
          },
        }}
      >
        <>
          <Button
            display={{
              display: "none",
            }}
            longMobile={{
              display: {
                display: "flex",
              },
            }}
            type={"button"}
            onClick={handleToggle}
          >
            {!toggle ? <BsChevronUp /> : <BsChevronDown />}
          </Button>
          <Text
            typo={{
              size: "14px",
              weight: "700",
              color: "pink01",
            }}
            longMobile={{
              position: {
                type: "absolute",
                top: "calc(100% + 10px)",
                right: "0",
              },
              typo: {
                size: "12px",
              },
            }}
          >
            * 필수 입력 내용입니다.
          </Text>
        </>
      </LineTitle>
      <Box
        display={{
          display: toggle ? "none" : "block",
        }}
      >
        <Box
          margin={{
            bottom: "24px",
          }}
        >
          <InputField
            as={"input"}
            type={"text"}
            title={"이름(활동명)"}
            required={required["name"]}
            name={"name"}
            size={{
              width: "100%",
              height: "40px",
            }}
            typo={{
              size: "18px",
              weight: "700",
            }}
          />
        </Box>

        <Box
          margin={{
            bottom: "24px",
          }}
        >
          <InputField
            as={"textarea"}
            type={"text"}
            title={"간단 자기소개"}
            required={required["intro"]}
            name={"intro"}
            size={{
              width: "100%",
              height: "146px",
            }}
            typo={{
              size: "18px",
              weight: "700",
            }}
          />
        </Box>

        <Box
          display={{
            display: "flex",
            align: "flex-end",
          }}
          margin={{
            bottom: "24px",
          }}
        >
          <InputField
            as={"input"}
            type={"email"}
            title={"이메일"}
            required={required["email"]}
            readOnly={check}
            name={"email"}
            info={
              <Text
                size={{
                  width: "100%",
                }}
                typo={{
                  size: "14px",
                  weight: "400",
                  color: "gray04",
                  lineHeight: "normal",
                }}
              >
                실제 사용중인 이메일을 기입해주세요.
              </Text>
            }
            size={{
              width: "calc(100% - 130px)",
              height: "40px",
            }}
            typo={{
              size: "18px",
              weight: "700",
            }}
          />
          <CommonButton
            category={"button"}
            type={"button"}
            isActive={isActiveSendCode}
            isDisabled={check}
            size={{
              width: "130px",
              height: "40px",
            }}
            border={{
              top: "1px",
              right: "1px",
              bottom: "1px",
            }}
            typo={{
              size: "12px",
            }}
            onClick={() => !check && submit()}
          >
            인증번호 발송
          </CommonButton>
        </Box>

        {sendMail && (
          <Box
            display={{
              display: "flex",
              align: "flex-end",
            }}
            margin={{
              bottom: "24px",
            }}
          >
            <InputField
              as={"input"}
              title={"인증번호 입력"}
              required={required["code"]}
              name={"code"}
              readOnly={check}
              info={
                <Text
                  size={{
                    width: "100%",
                  }}
                  typo={{
                    size: "14px",
                    weight: "400",
                    color: "gray04",
                    lineHeight: "normal",
                  }}
                >
                  이메일로 전달된 인증번호를 입력해 주세요
                </Text>
              }
              size={{
                width: "calc(100% - 130px)",
                height: "40px",
              }}
              typo={{
                size: "18px",
                weight: "700",
              }}
            />
            <CommonButton
              category={"button"}
              type={"button"}
              isActive={isActiveCheckCode}
              isDisabled={check}
              size={{
                width: "130px",
                height: "40px",
              }}
              border={{
                top: "1px",
                right: "1px",
                bottom: "1px",
              }}
              typo={{
                size: "12px",
              }}
              onClick={() => !check && checkCodeSubmit()}
            >
              인증번호 확인
            </CommonButton>
          </Box>
        )}

        <Box
          margin={{
            bottom: "24px",
          }}
        >
          <InputField
            as={"input"}
            type={"password"}
            title={"비밀번호"}
            required={required["password"]}
            name={"password"}
            info={
              <Text
                size={{
                  width: "100%",
                }}
                typo={{
                  size: "14px",
                  weight: "400",
                  color: "gray04",
                  lineHeight: "normal",
                }}
              >
                영문, 숫자, 특수문자 포함 8자 이상
              </Text>
            }
            size={{
              width: "100%",
              height: "40px",
            }}
            typo={{
              size: "18px",
              weight: "700",
            }}
          />
          {!isPassword && (
            <Text
              margin={{
                top: "5px",
              }}
              typo={{
                size: "12px",
                color: "red01",
              }}
            >
              비밀번호는 영문, 숫자, 특수문자 포함 8자 이상을 입력해 주세요.
            </Text>
          )}
        </Box>

        <Box
          margin={{
            bottom: "24px",
          }}
        >
          <InputField
            as={"input"}
            type={"password"}
            title={"비밀번호 확인"}
            required={required["passwordConfirm"]}
            name={"passwordConfirm"}
            size={{
              width: "100%",
              height: "40px",
            }}
            typo={{
              size: "18px",
              weight: "700",
            }}
          />

          {!isPasswordConfirm && (
            <Text
              margin={{
                top: "5px",
              }}
              typo={{
                size: "12px",
                color: "red01",
              }}
            >
              비밀번호가 일치하지 않습니다.
            </Text>
          )}
        </Box>

        <Box
          margin={{
            bottom: "24px",
          }}
        >
          <ThumbnailField
            title={"프로필 사진"}
            required={required["profile"]}
            name={"profile"}
            size={{
              width: "270px",
              height: "270px",
            }}
            typo={{
              size: "18px",
              weight: "700",
            }}
            desc={
              <Text
                margin={{
                  top: "6px",
                }}
                typo={{
                  size: "12px",
                  weight: "400",
                  color: "gray04",
                }}
              >
                - 권장 이미지 : 1080px*1080px / jpg,png 파일
              </Text>
            }
          />
        </Box>

        <LabelText
          title={"SNS 계정"}
          typo={{
            size: "18px",
            weight: "700",
          }}
        />

        <Box
          display={{
            display: "grid",
            gap: "20px 40px",
            gridTemplateColumns: "repeat(2, 1fr)",
          }}
          margin={{
            bottom: "30px",
          }}
          longMobile={{
            display: {
              gridTemplateColumns: "repeat(1, 1fr)",
              gap: "12px",
            },
          }}
        >
          <InputField
            as={"input"}
            type={"text"}
            title={"인스타그램"}
            name={"instagram_link"}
            size={{
              width: "100%",
              height: "40px",
            }}
            typo={{
              size: "14px",
              weight: "700",
            }}
          />
          <InputField
            as={"input"}
            type={"text"}
            title={"유튜브"}
            name={"youtube_link"}
            size={{
              width: "100%",
              height: "40px",
            }}
            typo={{
              size: "14px",
              weight: "700",
            }}
          />
          <InputField
            as={"input"}
            type={"text"}
            title={"사운드 클라우드"}
            name={"soundcloud_link"}
            size={{
              width: "100%",
              height: "40px",
            }}
            typo={{
              size: "14px",
              weight: "700",
            }}
          />
          <InputField
            as={"input"}
            type={"text"}
            title={"기타"}
            name={"other_link"}
            size={{
              width: "100%",
              height: "40px",
            }}
            typo={{
              size: "14px",
              weight: "700",
            }}
          />
        </Box>

        <LabelText
          title={"아티스트 인가요?"}
          typo={{
            size: "18px",
            weight: "700",
          }}
        />

        <Box
          display={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "40px",
          }}
          longMobile={{
            display: {
              gap: "8px",
            },
          }}
        >
          <CommonButton
            category={"button"}
            type={"button"}
            size={{
              width: "100%",
              height: "42px",
            }}
            typo={{
              size: "14px",
              weight: "700",
            }}
            isActive={isArtist}
            onClick={() => updateArtist(true)}
          >
            네
          </CommonButton>
          <CommonButton
            category={"button"}
            type={"button"}
            size={{
              width: "100%",
              height: "42px",
            }}
            typo={{
              size: "14px",
              weight: "700",
            }}
            isActive={!isArtist}
            onClick={() => updateArtist(false)}
          >
            아니요
          </CommonButton>
        </Box>
      </Box>
    </Box>
  );
};

export default BasicInfo;
