import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import Control from "style/utils/core/control";
import display from "style/utils/styles/display";
import background from "style/utils/styles/background";
import border from "style/utils/styles/border";
import size from "style/utils/styles/size";
import margin from "style/utils/styles/margin";
import padding from "style/utils/styles/padding";
import typography from "style/utils/styles/typography";
import other from "style/utils/styles/other";
import position from "style/utils/styles/position";

const themeStyle = {
  adminMenu: css`
    &:hover {
      img {
        filter: brightness(0) invert(1);
      }
    }
  `,
};

const [props, style] = Control([
  position,
  display,
  background,
  border,
  size,
  margin,
  padding,
  typography,
  other,
]);

const LinkStyle = styled(Link)`
  ${(props) => style(props)}
  ${(props) => themeStyle[props.$themeStyle]}
`;

LinkStyle.propTypes = props;

export default LinkStyle;
