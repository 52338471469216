import Box from "component/atoms/box";
import Line from "component/atoms/line";
import Text from "component/atoms/text";
import IconList from "component/molecules/common/iconList";
import List from "component/molecules/common/list";
import CommentForm from "component/molecules/detail/commentForm";
import Content from "component/molecules/detail/content";
import NoticeIcons from "component/molecules/detail/noticeIcons";
import Reply from "component/molecules/detail/reply";
import Title from "component/molecules/detail/title";
import { useLikeCommentForm } from "hook/api/story/like";
import useReplyList from "hook/api/story/replyList";
import useToggle from "hook/util/useToggle";
import useUrl from "hook/util/useUrl";
import StoryList from "../storyList";
import useSignedStore from "store/useSignedStore";
import useModalStore from "store/useModalStore";
import { useEffect } from "react";
import { ErrorContent } from "component/organisms/common/globalModal";

const StorySelect = (props) => {
  const { select, isPass } = props;

  const {
    boardId,
    name,
    type,
    createdAt,
    storyboardContent,
    isLiked,
    memberId,
    commentCount,
    storyboardOpen,
    storyboardFixed,
    storyBoardLikes,
    number,
  } = select;

  const {
    params: { id },
    query: { comment, commentPage },
    changeLink,
  } = useUrl();
  const { user } = useSignedStore();
  const { setModal } = useModalStore();

  const { toggle: isComment, handleToggle: toggleIsComment } = useToggle();
  const likeComment = useLikeCommentForm();

  const isBlind = storyboardOpen === 1 && !isPass;

  const handleLikeStory = () => {
    if (user) {
      likeComment();
    } else {
      changeLink("/login");
      setModal({
        title: "좋아요",
        content: "로그인이 필요합니다.",
      });
    }
  };

  useEffect(() => {
    if (isBlind) {
      changeLink("/story");
      setModal({
        title: "무무스트",
        content: <ErrorContent />,
      });
    }
  }, [isBlind]);

  useEffect(() => {
    const target = document.querySelector(".detail-content");

    if (target) {
      const y = target.getBoundingClientRect().top + window.pageYOffset - 110;

      window.scrollTo({ top: y, behavior: "smooth" });
    }
  }, []);

  return (
    <>
      <Box
        size={{
          width: "100%",
        }}
        border={{
          all: "1px",
          style: "solid",
          color: "black01",
        }}
        padding={{
          all: "24px",
        }}
        position={{
          type: "relative",
        }}
        longMobile={{
          padding: {
            all: "8px 7px",
          },
        }}
      >
        <Title type={type} name={name} date={createdAt} memberId={memberId} />

        <Line
          direction={"row"}
          size={"100%"}
          background={{
            color: "gray02",
          }}
          margin={{
            all: "12px 0 16px",
          }}
        />

        <Box
          position={{
            type: "relative",
            zIndex: "0",
          }}
          size={{
            height: "auto",
          }}
          other={{
            overflowY: "hidden",
          }}
          longMobile={{
            padding: {
              all: "0 5px",
            },
          }}
          typo={{
            wordBreak: "break-all",
          }}
        >
          <Text
            margin={{
              bottom: "10px",
            }}
            typo={{
              size: "14px",
              weight: "700",
            }}
          >
            #{number}
          </Text>
          <Content
            content={storyboardContent}
            isDetail
            isLock={isBlind}
            id={boardId}
          />
        </Box>

        {storyboardFixed && <NoticeIcons />}

        <Line
          direction={"row"}
          size={"100%"}
          background={{
            color: "gray02",
          }}
          margin={{
            all: "48px 0 19px",
          }}
          longMobile={{
            margin: "20px 0 2px",
          }}
        />
        <IconList
          size={24}
          icons={[
            {
              icon: "comment",
              value: commentCount,
              isActive: isComment,
              onClick: toggleIsComment,
            },
            {
              icon: "like",
              value: storyBoardLikes,
              isActive: isLiked,
              onClick: handleLikeStory,
            },
          ]}
        />

        {isComment && (
          <List
            api={useReplyList}
            params={{
              storyId: id,
              boardId: comment,
              page: commentPage,
            }}
            pageName="commentPage"
          >
            {(list) => (
              <Box>
                <Line
                  direction="row"
                  size="100%"
                  background={{
                    color: "black01",
                  }}
                  margin={{
                    all: "24px 0 18px",
                  }}
                  longMobile={{
                    margin: { all: "10px 0 14px" },
                  }}
                />

                <>
                  {list.length === 0 && (
                    <Box
                      display={{
                        display: "flex",
                        justify: "center",
                        align: "center",
                      }}
                      padding={{
                        top: "22px",
                        bottom: "40px",
                      }}
                      margin={{
                        bottom: "18px",
                      }}
                      border={{
                        bottom: "1px",
                        color: "gray02",
                      }}
                    >
                      <Text
                        typo={{
                          size: "14px",
                          align: "center",
                          lineHeight: "20px",
                        }}
                      >
                        등록된 댓글이 없습니다. <br />첫 댓글을 남겨주세요!
                      </Text>
                    </Box>
                  )}
                  {list.length !== 0 &&
                    list.map((item, idx) => (
                      <Reply {...item} key={item.commentId} />
                    ))}
                </>

                <CommentForm />
              </Box>
            )}
          </List>
        )}
      </Box>

      <StoryList isPass={isPass} />
    </>
  );
};

export default StorySelect;
