import Box from "component/atoms/box";
import Button from "component/atoms/button";
import Input from "component/atoms/input";
import Text from "component/atoms/text";
import LabelText from "component/molecules/common/labelText";
import useReset from "hook/util/useReset";
import { useRef, useState } from "react";
import { useFormContext } from "react-hook-form";
import { BsPlusLg } from "react-icons/bs";
import { GrClose } from "react-icons/gr";
import useModalStore from "store/useModalStore";
import convertFileSize from "utils/convertFileSize";

const accept = "audio/mpeg,audio/wav,audio/x-m4a,.mp3,.wav,.m4a";

const ErrorContent = () => {
  return <Text>오디오 형식의 파일만 가능합니다.</Text>;
};

const FileField = (props) => {
  const {
    title,
    required,
    info,
    typo,
    //
    name,
    isReset,
  } = props;
  const [fileName, setFileName] = useState("");
  const [fileSize, setFileSize] = useState("");
  const { setValue } = useFormContext();
  const { setModal } = useModalStore();
  const fileRef = useRef();

  useReset(name, isReset);

  const handleFileChange = (e) => {
    const file = e.target.files[0];

    if (!file) return;

    const isAccept = accept.split(",").some((type) => {
      return file.type === type;
    });

    if (isAccept) {
      setFileName(file.name);
      setFileSize(file.size);
      setValue(name, file);
    } else {
      setModal({
        title: "음원 등록하기",
        content: <ErrorContent />,
      });
    }
  };

  const handleFileReset = () => {
    setFileName("");
    setFileSize("");
    setValue(name, null);
  };

  return (
    <>
      <LabelText title={title} required={required} info={info} typo={typo} />

      {fileName ? (
        <Box
          display={{
            display: "flex",
            align: "center",
            justify: "space-between",
          }}
          size={{
            width: "100%",
            height: "40px",
          }}
          border={{
            all: "1px",
            style: "solid",
            color: "black01",
          }}
          padding={{
            all: "9px",
          }}
        >
          <Text
            typo={{
              size: "12px",
              weight: "700",
              lineClamp: "1",
            }}
          >
            {fileName}
          </Text>
          <Box
            display={{
              display: "flex",
              align: "center",
              justify: "center",
            }}
          >
            <Text
              typo={{
                size: "14px",
                weight: "400",
                color: "gray04",
              }}
            >
              {convertFileSize(fileSize)}
            </Text>
            <Button
              display={{
                display: "flex",
                align: "center",
                justify: "center",
              }}
              size={{
                width: "20px",
                height: "20px",
              }}
              margin={{
                left: "13px",
              }}
              onClick={handleFileReset}
            >
              <GrClose />
            </Button>
          </Box>
        </Box>
      ) : (
        <Box
          as={"label"}
          display={{
            display: "flex",
            direction: "column",
            align: "center",
            justify: "center",
          }}
          size={{
            width: "100%",
            height: "90px",
          }}
          border={{
            all: "1px",
            style: "solid",
            color: "black01",
          }}
        >
          <Input
            as={"input"}
            type={"file"}
            accept={accept}
            hidden={true}
            ref={fileRef}
            onChange={handleFileChange}
          />
          <Text
            typo={{
              size: "12px",
              weight: "400",
              color: "gray04",
            }}
            margin={{
              bottom: "10px",
            }}
          >
            음원 파일을 선택하여 추가해주세요.
          </Text>
          <Box
            display={{
              display: "flex",
              align: "center",
              justify: "center",
            }}
            size={{
              width: "35px",
              height: " 35px",
            }}
            border={{
              all: "1px",
              radius: "50%",
            }}
            background={{
              color: "pink02",
            }}
          >
            <BsPlusLg size={25} />
          </Box>
        </Box>
      )}
    </>
  );
};

export default FileField;
