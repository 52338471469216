import Box from "component/atoms/box";
import Text from "component/atoms/text";
import Api from "component/molecules/common/api";
import CommonButton from "component/molecules/common/commonButton";
import Modal from "component/molecules/common/modal";
import LineTitle from "component/organisms/common/lineTitle";
import PortfolioForm from "component/organisms/common/portfolioForm";
import Content from "component/organisms/mypage/content";
import Profile from "component/organisms/mypage/profile";
import useArtistForm from "hook/api/auth/artist";
import useLogout from "hook/api/auth/logout";
import useMember from "hook/api/mypage/member";
import useUrl from "hook/util/useUrl";
import { useEffect } from "react";
import useModalStore from "store/useModalStore";
import useSignedStore from "store/useSignedStore";

const MyPage = () => {
  const {
    params: { id },
    changeLink,
  } = useUrl();

  const { form, isActive, toggle, handleToggle, submit } = useArtistForm();
  const { user } = useSignedStore();
  const { setModal } = useModalStore();

  const handleLogout = useLogout();

  const memberId = id ?? user?.memberId;

  useEffect(() => {
    if (!memberId) {
      setModal({
        title: "프로필",
        content: "잘못된 접근입니다.",
      });
      changeLink("/");
    }
  }, [memberId]);

  return (
    <Api
      api={useMember}
      params={{
        id: memberId,
      }}
    >
      {(data) => {
        const isArtist = data.authority === "아티스트";

        return (
          <>
            <Box
              theme={"basic"}
              position={{
                type: "relative",
              }}
              margin={{
                all: "60px auto 100px",
              }}
              longMobile={{
                margin: {
                  all: "28px auto 70px",
                },
              }}
            >
              <LineTitle
                typo={{
                  size: "28px",
                  weight: "700",
                }}
                longMobile={{
                  margin: {
                    bottom: "0",
                  },
                }}
                title={"프로필"}
              >
                {data.isYou && (
                  <Box
                    display={{
                      display: "flex",
                      align: "center",
                      gap: "5px",
                    }}
                  >
                    {!isArtist && !data.artistReg && (
                      <CommonButton
                        category={"button"}
                        size={{
                          width: "100px",
                          height: "28px",
                        }}
                        typo={{
                          size: "14px",
                          weight: "700",
                        }}
                        onClick={handleToggle}
                      >
                        아티스트 신청
                      </CommonButton>
                    )}
                    <CommonButton
                      category={"button"}
                      size={{
                        width: "72px",
                        height: "28px",
                      }}
                      typo={{
                        size: "14px",
                        weight: "700",
                      }}
                      onClick={handleLogout}
                    >
                      로그아웃
                    </CommonButton>
                  </Box>
                )}
              </LineTitle>
              <Profile {...data} isArtist={isArtist} />

              <Content
                isArtist={isArtist}
                isYou={data.isYou}
                memberId={memberId}
              />
            </Box>

            <Modal
              visible={toggle}
              onClose={handleToggle}
              title={`아티스트 신청`}
              info={
                <Text
                  size={{
                    width: "100%",
                  }}
                  typo={{
                    size: "14px",
                    weight: "400",
                  }}
                >
                  아티스트로 등업 신청을 합니다.
                  <br />
                  아래에 대표 포트폴리오 링크를 등록해주세요. 영업일 기준 7일
                  이내 심사가 완료됩니다.
                </Text>
              }
            >
              <PortfolioForm form={form} />

              <Box
                display={{
                  display: "flex",
                  gap: "10px",
                }}
              >
                <CommonButton
                  category={"button"}
                  size={{
                    width: "100%",
                    height: "64px",
                  }}
                  typo={{
                    size: "18px",
                    weight: "700",
                  }}
                  isActive={isActive}
                  isDisabled={!isActive}
                  onClick={submit}
                >
                  신청하기
                </CommonButton>
              </Box>
            </Modal>
          </>
        );
      }}
    </Api>
  );
};

export default MyPage;
