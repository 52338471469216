import React, { useEffect, useState } from "react";
import Box from "component/atoms/box";
import Input from "component/atoms/input";
import RowTable from "component/atoms/rowSideTable";
import RowTableData from "component/atoms/rowTableData";
import RowTableHead from "component/atoms/rowTableHead";
import Text from "component/atoms/text";
import AdminButton from "component/molecules/admin/button/adminButton";
import AdminAlertModal from "../../modal/alertModal";
import {
  useAdminFooter,
  useUpdateAdminFooter,
} from "hook/api/admin/setup/footer";
import useInput from "hook/util/useInput";

const FooterManage = () => {
  const { data: data } = useAdminFooter();
  const {
    value: representative,
    setValue: setRepresentative,
    onChange: handleRepresentative,
  } = useInput(data?.represenTative);

  const {
    value: address,
    setValue: setAddress,
    onChange: handleAddress,
  } = useInput(data?.address);

  // 문의
  const {
    value: contact,
    setValue: setContact,
    onChange: handleContact,
  } = useInput(data?.contact);

  // 사업자 번호
  const {
    value: companyNumber,
    setValue: setCompanyNumber,
    onChange: handleCompanyNumber,
  } = useInput(data?.companyNumber);

  // 통신판매업 신고
  const {
    value: reportNumber,
    setValue: setReportNumber,
    onChange: handleReportNumber,
  } = useInput(data?.reportNumber);

  // 저장 버튼 비활성화
  const [isDone, setIsDone] = useState(false);
  // 성공 모달, 실패 모달
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false); // 등록 실패

  const handleSuccess = () => {
    setSuccess(true);
  };

  const handleError = () => {
    setError(true);
  };

  const { mutate: updateData } = useUpdateAdminFooter(
    handleSuccess,
    handleError
  );

  const handleSubmit = (e) => {
    if (
      !representative ||
      !address ||
      !contact ||
      !companyNumber ||
      !reportNumber
    ) {
      alert("필수 입력 항목을 입력해주세요.");
      return;
    }
    e.preventDefault();
    window.confirm("푸터를 등록하시겠습니까?") &&
      updateData({
        represenTative: representative,
        address: address,
        contact: contact,
        companyNumber: companyNumber,
        reportNumber: reportNumber,
      });
  };

  useEffect(() => {
    if (data) {
      setRepresentative(data?.represenTative);
      setAddress(data?.address);
      setContact(data?.contact);
      setCompanyNumber(data?.companyNumber);
      setReportNumber(data?.reportNumber);
    }
  }, [data]);

  useEffect(() => {
    // 모든 필드가 유효한지 확인
    const isAllFieldsFilled =
      representative && address && contact && companyNumber && reportNumber;

    // 모든 필드가 채워져 있다면 isDone을 true로 설정
    setIsDone(isAllFieldsFilled);
  }, [representative, address, contact, companyNumber, reportNumber]);

  return (
    <Box
      size={{
        width: "100%",
        height: "auto",
      }}
      margin={{
        top: "25px",
      }}
      other={{
        overflowX: "hidden",
      }}
    >
      {success && (
        <AdminAlertModal
          title={"푸터"}
          text="푸터 등록이 완료되었습니다."
          btn_text="확인"
          visible={success}
          toggle={() => {
            setSuccess(false);
          }}
          onClick={() => {
            setSuccess(false);
          }}
        />
      )}
      {error && (
        <AdminAlertModal
          title={"푸터"}
          text="푸터 등록에 실패하였습니다."
          btn_text="확인"
          visible={error}
          toggle={() => {
            setError(false);
          }}
          onClick={() => {
            setError(false);
          }}
        />
      )}
      <Text theme={"adminTitle"}>푸터</Text>
      <RowTable>
        <Box
          theme="adminListBox"
          padding={{
            left: "21px",
          }}
        >
          <Text
            typo={{
              size: "12px",
              weight: "700",
              color: "pink03",
            }}
          >
            * 은 필수 입력 항목입니다.
          </Text>
        </Box>

        <Box
          display={{
            width: "100%",
            height: "48px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="48px">
            대표이사
            <Text
              typo={{
                color: "pink03",
              }}
              margin={{
                left: "4px",
              }}
            >
              *
            </Text>
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
          >
            <Input
              theme={"adminInput"}
              size={{
                width: "395px",
                height: "32px",
              }}
              margin={{
                top: "8px",
              }}
              value={representative}
              onChange={handleRepresentative}
            />
          </RowTableData>
        </Box>

        <Box
          display={{
            width: "100%",
            height: "48px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="48px">
            주소
            <Text
              typo={{
                color: "pink03",
              }}
              margin={{
                left: "4px",
              }}
            >
              *
            </Text>
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
          >
            <Input
              theme={"adminInput"}
              size={{
                width: "395px",
                height: "32px",
              }}
              margin={{
                top: "8px",
              }}
              value={address}
              onChange={handleAddress}
            />
          </RowTableData>
        </Box>

        <Box
          display={{
            width: "100%",
            height: "48px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="48px">
            사업자 번호
            <Text
              typo={{
                color: "pink03",
              }}
              margin={{
                left: "4px",
              }}
            >
              *
            </Text>
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
          >
            <Input
              theme={"adminInput"}
              size={{
                width: "395px",
                height: "32px",
              }}
              margin={{
                top: "8px",
              }}
              value={companyNumber}
              onChange={handleCompanyNumber}
            />
          </RowTableData>
        </Box>

        <Box
          display={{
            width: "100%",
            height: "48px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="48px">
            문의
            <Text
              typo={{
                color: "pink03",
              }}
              margin={{
                left: "4px",
              }}
            >
              *
            </Text>
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
          >
            <Input
              theme={"adminInput"}
              size={{
                width: "395px",
                height: "32px",
              }}
              margin={{
                top: "8px",
              }}
              value={contact}
              onChange={handleContact}
            />
          </RowTableData>
        </Box>

        <Box
          display={{
            width: "100%",
            height: "48px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="48px">
            통신판매업 신고
            <Text
              typo={{
                color: "pink03",
              }}
              margin={{
                left: "4px",
              }}
            >
              *
            </Text>
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
          >
            <Input
              theme={"adminInput"}
              size={{
                width: "395px",
                height: "32px",
              }}
              margin={{
                top: "8px",
              }}
              value={reportNumber}
              onChange={handleReportNumber}
            />
          </RowTableData>
        </Box>
      </RowTable>
      <AdminButton
        category="button"
        theme={isDone ? ["mainButton"] : ["grayColor"]}
        margin={{
          top: "24px",
          left: "460px",
        }}
        size={{
          width: "104px",
          height: "40px",
        }}
        padding={{
          top: "12px",
          bottom: "12px",
        }}
        onClick={handleSubmit}
      >
        저장
      </AdminButton>
    </Box>
  );
};

export default FooterManage;
