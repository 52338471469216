import { getStory, getStoryWrite, update, write } from "api/story";
import { ErrorContent } from "component/organisms/common/globalModal";
import useUrl from "hook/util/useUrl";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import useModalStore from "store/useModalStore";
import checkRequired from "utils/checkRequired";

const required = {
  storyCategory: true,
  storyMood: true,
  storyTitle: true,
  storySummary: true,
  thumbnail: true,
  storyPersonality: true,
  personaLike: true,
  personaDislike: true,
  personaName: true,
  isAgree: true,
};

const useWriteStoryForm = () => {
  const form = useForm({
    defaultValues: {
      storyCategory: "",
      storyMood: [],
      storyTitle: "",
      storySummary: "",
      thumbnail: null,
      storyPersonality: [],
      personaLike: "",
      personaDislike: "",
      personaName: "",
      isAgree: [],
      storyOpen: "0",
    },
  });

  const { watch, reset, handleSubmit } = form;
  const { setModal } = useModalStore();
  const {
    query: { storyId },
    changeLink,
  } = useUrl();

  const storyDetail = useMutation(getStory, {
    onSuccess: ({ data }) => {
      reset({
        ...data.story,
        storyOpen: data.story.storyOpen.toString(),
        thumbnail: data.story.storyThumbnail,
        isAgree: [true],
      });
    },
  });

  const writeMutation = useMutation(storyId ? update : write, {
    onSuccess: ({ data: { storyId } }) => {
      changeLink(`/story/${storyId}`);
    },
    onError: ({ response: { data } }) => {
      setModal({
        title: "이야기 등록",
        content: <ErrorContent message={data.message} />,
      });
    },
  });

  const isActive = useMemo(() => checkRequired(required, watch()), [watch()]);

  const submit = (data) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item) => {
          formData.append(key, item);
        });
      } else {
        formData.append(key, value);
      }
    });

    isActive &&
      writeMutation.mutate({
        id: storyId,
        data: formData,
      });
  };

  useEffect(() => {
    if (storyId) storyDetail.mutate(storyId);
  }, [storyId]);

  return { form, isActive, handleSubmit: handleSubmit(submit) };
};

export default useWriteStoryForm;
