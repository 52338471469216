import Box from "component/atoms/box";
import Checkboxes from "component/molecules/checkbox";
import LabelText from "component/molecules/common/labelText";
import useReset from "hook/util/useReset";
import { useController, useFormContext } from "react-hook-form";

const CheckboxField = (props) => {
  const {
    title,
    required,
    info,
    optionList,
    typo,
    size,
    //
    name,
    isReset,
  } = props;
  const { control, watch, setValue } = useFormContext();

  useReset(name, isReset);

  const { field } = useController({
    control,
    name,
  });

  const handleChangeCheckbox = (value) => {
    field.onChange(value);
  };

  return (
    <>
      <LabelText title={title} required={required} info={info} typo={typo} />
      <Box
        display={{
          display: "flex",
        }}
      >
        {optionList.map((option, idx) => (
          <Checkboxes
            key={idx}
            options={option}
            value={watch(name)}
            onChange={handleChangeCheckbox}
            booleanType
            //
            size={size}
            fontStyle={{
              typo: {
                size: "14px",
                weight: "400",
              },
              margin: {
                all: "0 20px 0 5px",
              },
            }}
          />
        ))}
      </Box>
    </>
  );
};

export default CheckboxField;
