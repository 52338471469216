import { creation } from "api/mypage";
import { useQuery } from "react-query";

const useCreation = ({ id, state, page }) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["creation", id, state, page],
    () => creation(id, state, page)
  );

  const list = data?.data;
  const maxPage = data?.totalPages;

  return {
    list,
    maxPage,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useCreation;
