import { useState } from "react";

const usePattern = () => {
  const [pattern] = useState({
    // new 회원가입 시에만 해당 패턴을 사용할 수 있도록 key name 변경
    newId: {
      value: /^[a-z0-9]{4,12}$/,
      message: "아이디 형식을 확인해주세요.\n (영문 소문자, 숫자 4~12자)",
    },
    password: {
      value:
        /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/,
      validate: (password) => {
        if (password.length < 8) return false;

        let regexArray = [
          /[a-zA-Z]/g,
          /[0-9]/g,
          /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g,
        ];

        let matchCount = regexArray.reduce(
          (count, regex) => (password.match(regex) ? count + 1 : count),
          0
        );

        return matchCount >= 3;
      },
      message:
        "비밀번호 형식을 확인해주세요.\n (영문, 숫자, 특수문자 포함 8자 이상)",
    },
    email: {
      value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9]+(\.[A-Za-z]{2,})+$/,
      message: "이메일 형식을 확인해주세요.",
    },
    call: {
      value: /^\d{2,3}-\d{3,4}-\d{4}$/,
      message: "전화번호 형식을 확인해주세요.",
    },
  });

  return pattern;
};

export default usePattern;
