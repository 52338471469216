import { DELETE, GET, POST, PUT } from "api/config";

export const write = async (info) => await POST("/story/write", info.data);

export const update = async (info) => await PUT(`/story/${info.id}`, info.data);

export const deleteStory = async (id) => await DELETE(`/story/${id}`);

export const comment = async ({ id, data }) =>
  await POST(`/story/${id}/board`, data);

export const updateComment = async ({ boardId, data }) => {
  return await PUT(`/story/${boardId}/board`, data);
};

export const deleteComment = async (boardId) =>
  await DELETE(`/story/${boardId}/board`);

export const matching = async (info) =>
  await POST(`/story/${info.id}/match`, info.data);

export const matchingArtist = async (id) => await GET(`/story/${id}/matches`);

export const matchingAssist = async (id) =>
  await GET(`/story/${id}/assist/matches`);

export const select = async (id) => await POST(`/story/${id}/artist/match`);

export const selectAssist = async (id) =>
  await POST(`/story/${id}/assist/match`);

export const getExhibition = async () => await GET(`/story/exhibition`);

export const getStoryList = async (parameters) =>
  await GET(`/story/content`, { params: parameters });

export const getStory = async (id) => await GET(`/story/${id}`);

export const getStoryWrite = async (id) => await GET(`/story/write/${id}`);

export const replay = async (info) =>
  await POST(`/story/${info.boardId}/comment`, info.data);

export const commentLike = async (data) =>
  await POST(`/story/board/${data.commentId}/like`);

export const storyInfo = async (info) =>
  await POST(`/story/${info.id}/form`, info.data);

export const storyInfoUpdate = async (info) =>
  await PUT(`/story/${info.id}/form`, info.data);

export const storyLike = async (data) => await POST(`/story/${data.id}/like`);

export const commentList = async (id, page) =>
  await GET(`/story/${id}/board?page=${page}`);

export const getComment = async ({ storyId, boardId }) =>
  await GET(`/story/${storyId}/board/${boardId}`);

export const replyList = async (storyId, boardId, page) =>
  await GET(`/story/${storyId}/board/${boardId}/comment?page=${page}`);

export const updateReply = async (info) =>
  await PUT(`/story/comment/${info.commentId}`, info.data);

export const deleteReply = async (info) =>
  await DELETE(`/story/comment/${info.commentId}`);

export const latest = async ({ storyId, boardId, page }) =>
  await GET(`/story/${storyId}/board/${boardId}/latest?page=${page}`);

export const finish = async (info) =>
  await POST(`/story/${info.id}/complete`, info.data);

export const goal = async (info) =>
  await POST(`/story/${info.id}/cheering`, info.data);

export const updateGoal = async (info) =>
  await PUT(`/story/cheering/${info.id}`, info.data);

export const support = async (info) =>
  await POST(`/story/${info.id}/${info.cheerId}/cheering`, info.data);

export const additional = async (info) =>
  await POST(`/story/${info.id}/employment`, info.data);

export const updateAdditional = async (info) =>
  await PUT(`/story/${info.id}/employment`, info.data);

export const getAdditional = async (id) => await GET(`/story/${id}/employment`);

export const additionalRequest = async (info) =>
  await POST(`/story/${info.id}/match/assist`, info.data);

export const editorImage = async (file) => await POST(`/story/upload`, file);
