import Lock from "component/molecules/detail/lock";
import MoreButton from "component/molecules/detail/moreButton";

const Content = (props) => {
  const { isLock, id, content, isDetail } = props;

  return isLock ? (
    <Lock />
  ) : (
    <>
      <div
        dangerouslySetInnerHTML={{ __html: content }}
        className="editor-content"
      />

      {!isDetail && <MoreButton id={id} />}
    </>
  );
};

export default Content;
