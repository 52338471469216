import React, { useEffect, useState } from "react";
import Box from "component/atoms/box";
import Image from "component/atoms/image";
import Input from "component/atoms/input";
import RowTable from "component/atoms/rowSideTable";
import RowTableData from "component/atoms/rowTableData";
import RowTableHead from "component/atoms/rowTableHead";
import Text from "component/atoms/text";
import AdminButton from "component/molecules/admin/button/adminButton";
import HoverBox from "component/molecules/admin/hoverBox";
import {
  useAdminFilterKeyword,
  useCreateAdminFilterKeyword,
  useDeleteAdminFilterKeyword,
} from "hook/api/admin/setup/wordFiltering";
import AdminAlertModal from "../../modal/alertModal";
import useEditStore from "store/useEditStore";

const WordFiltering = () => {
  const [inputText, setInputText] = useState("");

  // 변경사항 있을 때 경고창 띄우기
  const [isChanged, setIsChanged] = useState(false);

  // 데이터 가져오기
  const { data: word } = useAdminFilterKeyword();

  // 임시 데이터
  const [tempData, setTempData] = useState([]);
  // 등록 성공 모달, 실패 모달
  const [success, setSuccess] = useState(false);
  const [exist, setExist] = useState(false); // 409 에러
  const [error, setError] = useState(false); // 등록 실패

  // 삭제 성공 모달, 실패 모달
  const [successDelete, setSuccessDelete] = useState(false);
  const [errorDelete, setErrorDelete] = useState(false);

  const { updateEdit, clearEdit } = useEditStore();

  const handleSuccess = () => {
    setSuccess(true);
  };

  const handleExist = () => {
    setExist(true);
  };

  const handleError = () => {
    setError(true);
  };

  const handleSuccessDelete = () => {
    setSuccessDelete(true);
  };

  const handleErrorDelete = () => {
    setErrorDelete(true);
  };

  // 데이터 생성
  const { mutate: createWord } = useCreateAdminFilterKeyword(
    handleSuccess,
    handleExist,
    handleError
  );

  const handleInputChange = (e) => {
    setInputText(e.target.value);
  };

  const handleSubmit = (e) => {
    if (e.key === "Enter") {
      const trimmedInput = inputText.trim(); // 앞뒤 공백 제거

      if (trimmedInput !== "") {
        // 현재 입력된 단어가 tempData 또는 word 배열에 존재하는지 확인
        const isDuplicate =
          tempData.includes(trimmedInput) ||
          word.some((w) => w.keyword === trimmedInput);

        if (!isDuplicate) {
          setTempData([...tempData, trimmedInput]);
          updateEdit();
        } else {
          // 중복된 단어일 경우 알림
          setExist(true);
        }
        setInputText(""); // 입력 필드 초기화
      }
    }
  };

  const handleSave = () => {
    if (window.confirm("필터링 단어를 등록하시겠습니까?")) {
      tempData.forEach((word) => {
        createWord({ keyword: word });
      });
      setTempData([]);
      setSuccess(true);
      setIsChanged(false);
      clearEdit();
    }
  };

  // 데이터 삭제
  const { mutate: deleteWord } = useDeleteAdminFilterKeyword(
    handleSuccessDelete,
    handleErrorDelete
  );

  const handleDelete = (id) => {
    window.confirm("정말 삭제하시겠습니까?") && deleteWord(id);
  };

  const removeWord = (index) => {
    setTempData(tempData.filter((item, i) => i !== index));
    setIsChanged(true);
  };

  const [isHover, setIsHover] = useState(false);

  // 페이지 이동 시 경고창 띄우기
  // useEffect(() => {
  //   const handleBeforeUnload = (e) => {
  //     if (isChanged && tempData.length > 0) {
  //       e.preventDefault(); // 표준에 따라 기본 동작을 방지
  //       window.confirm("변경사항이 저장되지 않을 수 있습니다.");
  //       window.event.returnValue = ""; // Chrome에서는 returnValue 설정이 필요함
  //       e.returnValue = ""; // 대부분의 브라우저에서는 사용자 지정 메시지를 무시하고 기본 메시지를 표시
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [isChanged]);

  // useEffect(() => {
  //   const handleBeforeUnload = (e) => {
  //     if (tempData.length > 0) {
  //       const message = "작성중인 정보가 있습니다. 이동하시겠습니까?";
  //       e.preventDefault();
  //       e.returnValue = message;
  //       return message;
  //     }
  //   };

  //   window.addEventListener("beforeunload", handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener("beforeunload", handleBeforeUnload);
  //   };
  // }, [tempData]);

  return (
    <Box
      size={{
        width: "100%",
        height: "auto",
      }}
      margin={{
        top: "25px",
      }}
      other={{
        overflowX: "hidden",
      }}
    >
      {success && (
        <AdminAlertModal
          title={"단어 필터링"}
          text="단어 필터링 등록이 완료되었습니다."
          btn_text="확인"
          visible={success}
          toggle={() => {
            setSuccess(false);
          }}
          onClick={() => {
            setSuccess(false);
          }}
        />
      )}
      {exist && (
        <AdminAlertModal
          title={"단어 필터링"}
          text="이미 존재하는 단어입니다."
          btn_text="확인"
          visible={exist}
          toggle={() => {
            setExist(false);
          }}
          onClick={() => {
            setExist(false);
          }}
        />
      )}
      {error && (
        <AdminAlertModal
          title={"단어 필터링"}
          text="단어 필터링 등록에 실패하였습니다."
          btn_text="확인"
          visible={error}
          toggle={() => {
            setError(false);
          }}
          onClick={() => {
            setError(false);
          }}
        />
      )}
      {successDelete && (
        <AdminAlertModal
          title={"단어 필터링"}
          text="필터링 단어가 삭제되었습니다."
          btn_text="확인"
          visible={successDelete}
          toggle={() => {
            setSuccessDelete(false);
          }}
          onClick={() => {
            setSuccessDelete(false);
          }}
        />
      )}
      {errorDelete && (
        <AdminAlertModal
          title={"단어 필터링"}
          text="필터링 단어 삭제에 실패하였습니다."
          btn_text="확인"
          visible={errorDelete}
          toggle={() => {
            setErrorDelete(false);
          }}
          onClick={() => {
            setErrorDelete(false);
          }}
        />
      )}
      <Text theme={"adminTitle"}>단어 필터링</Text>
      <RowTable>
        <Box
          theme="adminListBox"
          padding={{
            left: "21px",
          }}
        >
          <Text
            typo={{
              size: "12px",
              weight: "700",
              color: "gray04",
            }}
          >
            * 입력된 단어가 포함된 내용은 게시할 수 없습니다.
          </Text>
        </Box>

        <Box
          display={{
            width: "100%",
            height: "52px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="52px">
            단어 추가
            <div
              style={{
                width: "15px",
                height: "15px",
              }}
              onMouseEnter={() => setIsHover(true)}
              onMouseLeave={() => setIsHover(false)}
            >
              <Image
                src={"/asset/img/admin/setup/question.svg"}
                margin={{
                  left: "5px",
                }}
              />
            </div>
            <HoverBox isHover={isHover}>
              <Text
                typo={{
                  size: "12px",
                  weight: "400",
                  align: "left",
                }}
              >
                입력된 단어가 포함된 내용을 게시할 수 없도록 필터링 단어를
                추가합니다. <br />- 단어 입력 후 엔터키를 눌러 단어를 추가할 수
                있습니다.
              </Text>
            </HoverBox>
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
          >
            <Input
              theme={"adminInput"}
              size={{
                width: "839px",
                height: "32px",
              }}
              margin={{
                top: "10px",
              }}
              value={inputText}
              onChange={handleInputChange}
              onKeyPress={handleSubmit}
            />
          </RowTableData>
        </Box>
        <Box
          display={{
            width: "100%",
            height: "249px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="249px">
            필터링 단어
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
              height: "249px",
            }}
          >
            {word?.map((item, index) => {
              return (
                <AdminButton
                  key={item.keywordId}
                  category="button"
                  theme={["wordButton"]}
                >
                  <Text>{item.keyword}</Text>
                  <Image
                    src={"/asset/img/admin/setup/x.svg"}
                    margin={{
                      left: "10px",
                    }}
                    onClick={() => {
                      handleDelete(item.keywordId);
                    }}
                  />
                </AdminButton>
              );
            })}
            {tempData?.map((item, index) => {
              return (
                <AdminButton
                  key={index}
                  category="button"
                  theme={["wordButton"]}
                >
                  <Text>{item}</Text>
                  <Image
                    src={"/asset/img/admin/setup/x.svg"}
                    margin={{
                      left: "10px",
                    }}
                    onClick={() => removeWord(index)}
                  />
                </AdminButton>
              );
            })}
          </RowTableData>
        </Box>
      </RowTable>
      <AdminButton
        category="button"
        theme={["mainButton"]}
        margin={{
          top: "24px",
          left: "460px",
        }}
        onClick={handleSave}
      >
        저장
      </AdminButton>
    </Box>
  );
};

export default WordFiltering;
