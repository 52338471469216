import { create } from "zustand";

export const useModalStore = create((set) => ({
  title: "",
  content: "",
  info: "",
  isActive: false,
  noneInfo: false,
  callback: () => {},

  setModal: ({ title, content, info, isActive, noneInfo, callback }) =>
    set({ title, content, info, isActive, noneInfo, callback }),
  resetModal: () =>
    set({ title: "", content: "", info: "", noneInfo: false, isActive: false }),
}));

export default useModalStore;
