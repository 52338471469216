import Box from "component/atoms/box";
import Input from "component/atoms/input";
import AdminRadio from "component/molecules/admin/adminRadio";
import RowTable from "component/atoms/rowSideTable";
import RowTableData from "component/atoms/rowTableData";
import RowTableHead from "component/atoms/rowTableHead";
import Text from "component/atoms/text";
import React, { useState, useEffect } from "react";
import AdminDropdown from "component/molecules/admin/adminDropdown";
import AdminButton from "component/molecules/admin/button/adminButton";
import Image from "component/atoms/image";
import TableData from "component/atoms/tableData";
import TableHead from "component/atoms/tableHead";
import Table from "component/atoms/table";
import AdminCheckbox from "component/molecules/adminCheckbox";
import CalendarInput from "component/molecules/common/calendarInput";
import useToggle from "hook/util/useToggle";
import AdminModal from "component/molecules/admin/adminModal";
import AdminInputModal from "../../modal/InputModal";
import AdminAlertModal from "../../modal/alertModal";
import {
  useAdminJudgingApprove,
  useAdminJudgingList,
  useAdminJudgingPending,
} from "hook/api/admin/story/judging";
import dayjs from "dayjs";
import AdminPagination from "component/molecules/admin/pagination";
import { blockUser } from "api/admin/user/gradeManage";
import ReactAudioPlayer from "react-audio-player";
import Link from "component/atoms/link";
import usePopup from "hook/util/usePopup";
import { getCurrentDateTime } from "utils/getCurrentDateTime";

const ContentsJudging = () => {
  const baseURL = process.env.REACT_APP_SERVER_URL;
  const category = ["전체", "이메일", "곡명"];
  const listCategory = [
    { label: "10개씩 보기", value: 10 },
    { label: "20개씩 보기", value: 20 },
    { label: "30개씩 보기", value: 30 },
    { label: "50개씩 보기", value: 50 },
  ];
  const labels = listCategory.map((category) => category.label);

  const [selected, setSelected] = useState(category[0]);
  // 검색어
  const [inputValue, setInputValue] = useState("");

  // 검색 분류 변경 시 상태 변경
  const changeSelected = (category) => {
    setSelected(category);
  };

  // 인풋창의 입력값을 카테고리에 따라 관리
  const handleInputChange = (e) => {
    e.preventDefault();
    setInputValue(e.target.value);
  };

  // 10개씩 보기 선택하면 value = 10 을 pageSize로 설정
  const changeSelectedList = (selectedLabel) => {
    const category = listCategory.find((cat) => cat.label === selectedLabel);
    if (category) {
      setPageSize(category.value);
      setCurrentPage(1);
    }
  };
  const currentDateTime = getCurrentDateTime();
  const [state, setState] = useState("전체");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const [pageSize, setPageSize] = useState(10);

  const [search, setSearch] = useState({
    state: "전체",
    startDate: "",
    endDate: "",
    musicName: "",
    memberEmail: "",
    keyword: "",
  });

  // 페이지네이션
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // data get
  const {
    data: storyList,
    totalItems: total,
    totalPages: totalPages,
  } = useAdminJudgingList(
    search.state,
    search.startDate,
    search.endDate,
    search.musicName,
    search.memberEmail,
    search.keyword,
    pageSize,
    currentPage
  );

  const handleSearch = () => {
    const newSearch = {
      state: state,
      startDate: startDate,
      endDate:
        startDate !== "" && endDate === ""
          ? dayjs(currentDateTime).format("YYYY-MM-DD")
          : endDate,
    };

    if (
      startDate === "" &&
      dayjs(newSearch.endDate).isAfter(dayjs(currentDateTime))
    ) {
      newSearch.startDate = dayjs(currentDateTime).format("YYYY-MM-DD");
    }
    if (
      startDate === "" &&
      dayjs(currentDateTime).isAfter(dayjs(newSearch.endDate))
    ) {
      alert("시작일이 종료일보다 늦습니다. 검색 기간을 다시 설정해주세요.");
      return;
    }

    if (dayjs(newSearch.startDate).isAfter(dayjs(newSearch.endDate))) {
      alert("시작일이 종료일보다 늦습니다. 검색 기간을 다시 설정해주세요.");
      return;
    }

    if (selected === "이메일") {
      newSearch.memberEmail = inputValue;
    } else if (selected === "곡명") {
      newSearch.musicName = inputValue;
    } else {
      newSearch.keyword = inputValue;
    }

    setSearch(newSearch);
    setCurrentPage(1);
  };

  // 체크박스를 선택하여 회원 차단
  const [selectedUser, setSelectedUser] = useState({});

  // 전체 데이터의 processed가 true일 때 전체 체크박스도 readOnly
  const isAllReadOnly = storyList?.every((item) => item.result !== 0);

  useEffect(() => {
    setSelectedUser({});
  }, [currentPage]);

  // 전체 선택 또는 해제 처리
  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setSelectedUser((prevSelectedUser) => {
      const newSelectedUser = {};
      if (checked) {
        storyList?.forEach((item) => {
          newSelectedUser[item.creationId] = item.result === 0;
        });
      }
      return newSelectedUser;
    });
  };

  // 개별 선택 처리
  const handleSelect = (e, creationId) => {
    const checked = e.target.checked;
    setSelectedUser((prevSelectedUser) => {
      const newSelectedUser = { ...prevSelectedUser };
      const currentItem = storyList?.find(
        (item) => item.creationId === creationId
      );

      if (currentItem && currentItem.result === 0) {
        newSelectedUser[creationId] = checked;
      }

      return newSelectedUser;
    });
  };

  const selectedUserIds = Object.entries(selectedUser)
    .filter(([_, checked]) => checked)
    .map(([creationId]) => creationId);

  const { toggle, handleToggle } = useToggle();

  // 알림 모달창 상태 관리
  const [visible, setVisible] = useState(false);

  // 보류 사유 데이터 관리
  const [creationId, setCreationId] = useState("");
  const [inputData, setInputData] = useState("");

  // 보류 버튼 클릭 시 보류 사유 입력 모달창 띄우기
  const handlePendingClick = (creationId) => {
    setCreationId(creationId);
    setInputData("");
    handleToggle();
  };

  const handlePendingInput = (e) => {
    setInputData(e.target.value);
  };

  const handleCancle = () => {
    handleToggle();
  };

  const handleSave = () => {
    pendingMusic({ creationId: creationId, rejectReason: inputData });
    setVisible(true);
    handleToggle();
  };

  // 승인 성공 모달, 실패 모달
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false); // 등록 실패

  // 보류 성공, 실패
  const [pendingSuccess, setPendingSuccess] = useState(false);
  const [pendingError, setPendingError] = useState(false);

  const handleSuccess = () => {
    setSuccess(true);
  };

  const handleError = () => {
    setError(true);
  };

  const handlePendingSuccess = () => {
    setPendingSuccess(true);
  };

  const handlePendingError = () => {
    setPendingError(true);
  };

  const { mutate: approveMusic } = useAdminJudgingApprove(
    handleSuccess,
    handleError
  );

  const { mutate: pendingMusic } = useAdminJudgingPending(
    handlePendingSuccess,
    handlePendingError
  );

  const handleApprove = () => {
    if (selectedUserIds.length === 0) {
      alert("선택된 곡이 없습니다.");
    } else {
      approveMusic({ creationId: selectedUserIds });
      setSelectedUser({});
    }
  };

  // 새 창으로 열기!
  const { handleOpenPopup } = usePopup({
    width: "1350px",
    height: window.innerHeight,
  });

  const openNewWindow = (userId) => {
    handleOpenPopup(
      `/admin/manage/user/detail/${userId}?menu=userDetail&category=basicDetail`
    );
  };

  const handleEachApprove = (creationId) => {
    approveMusic({ creationId: creationId });
  };

  return (
    <>
      <AdminInputModal
        toggle={toggle}
        handleToggle={handleToggle}
        cancle={handleCancle}
        save={handleSave}
        rejectReason={
          storyList?.find((music) => music.creationId === creationId)
            ?.rejectReason
        }
        onChange={handlePendingInput}
        value={inputData}
      />
      <AdminAlertModal
        title={"창작완료 심사"}
        text={
          success
            ? "창작완료 승인이 완료되었습니다."
            : "창작완료 보류가 완료되었습니다."
        }
        btn_text="확인"
        visible={success || visible}
        toggle={() => {
          setSuccess(false);
          setVisible(false);
        }}
      />
      <AdminAlertModal
        title={"창작완료 심사"}
        text="창작완료 승인이 완료되었습니다."
        btn_text="확인"
        visible={success}
        toggle={() => {
          setSuccess(false);
        }}
      />
      <Box
        size={{
          width: "100%",
          height: "auto",
        }}
        margin={{
          top: "25px",
        }}
        other={{
          overflowX: "hidden",
        }}
      >
        <Text theme={"adminTitle"}>창작완료 조회</Text>
        <RowTable>
          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="160px" height="48px" isLastChild={true}>
              상태 분류
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 160px)",
                height: "48px",
              }}
              isLastChild={true}
            >
              <Box
                size={{
                  width: "100%",
                  height: "100%",
                }}
                display={{
                  display: "flex",
                  justify: "flex-start",
                  align: "center",
                }}
                margin={{
                  left: "8px",
                }}
              >
                <AdminRadio
                  id="all"
                  name="status_category"
                  value="all"
                  htmlFor="all"
                  label="전체"
                  checked={state === "전체"}
                  onClick={() => {
                    setState("전체");
                  }}
                />
                <AdminRadio
                  id="waiting"
                  name="status_category"
                  value="waiting"
                  htmlFor="waiting"
                  label="승인대기"
                  onClick={() => {
                    setState("승인대기");
                  }}
                />
                <AdminRadio
                  id="complete"
                  name="status_category"
                  value="complete"
                  htmlFor="complete"
                  label="승인완료"
                  onClick={() => {
                    setState("승인완료");
                  }}
                />
                <AdminRadio
                  id="holding"
                  name="status_category"
                  value="holding"
                  htmlFor="holding"
                  label="승인보류"
                  onClick={() => {
                    setState("승인보류");
                  }}
                />
              </Box>
            </RowTableData>
          </Box>

          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="160px" height="48px">
              검색 기간
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 160px)",
                height: "48px",
              }}
            >
              <Box
                size={{
                  width: "100%",
                  height: "100%",
                }}
                display={{
                  display: "flex",
                  justify: "flex-start",
                  align: "center",
                }}
              >
                <CalendarInput
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                />
                <Text
                  margin={{
                    left: "10px",
                    right: "10px",
                  }}
                >
                  ~
                </Text>
                <CalendarInput
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                />
              </Box>
            </RowTableData>
          </Box>

          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="160px" height="48px">
              검색 분류
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 160px)",
              }}
              padding={{
                top: "8px",
                left: "8px",
              }}
            >
              <AdminDropdown
                size={{
                  width: "104px",
                  height: "32px",
                }}
                menuSize={{
                  width: "104px",
                  height: "auto",
                }}
                category={category}
                onSelect={changeSelected}
              />
              <Input
                theme={"adminInput"}
                size={{
                  width: "500px",
                  height: "32px",
                }}
                value={inputValue}
                onChange={handleInputChange}
              />
              {/* <Input as="radio" theme={"adminRadio"} name="search" value="id" /> */}
            </RowTableData>
          </Box>
        </RowTable>
        <AdminButton
          category="button"
          theme="mainButton"
          margin={{
            top: "24px",
            left: "456px",
          }}
          onClick={handleSearch}
        >
          검색
        </AdminButton>

        {/* 회원 목록 */}
        <Box
          size={{
            width: "100%",
            height: "auto",
          }}
          margin={{
            top: "40px",
          }}
        >
          <Text theme={"adminTitle"}>창작완료 신청 목록</Text>
          <Box
            size={{
              width: "1024px",
              height: "auto",
            }}
            border={{
              top: "1px",
              left: "1px",
              right: "1px",
              type: "solid",
              color: "gray10",
              radius: "3px 3px 0 0",
            }}
          >
            {/* 목록 상단 인원 수, 차단 버튼 */}
            <Box
              theme="adminListBox"
              size={{
                height: "44px",
              }}
              padding={{
                left: "16px",
              }}
            >
              <Text
                as="span"
                typo={{
                  size: "13px",
                  weight: "500",
                  color: "black02",
                }}
              >
                총{" "}
                <Text
                  as="span"
                  typo={{
                    color: "blue01",
                  }}
                >
                  {total}
                </Text>{" "}
                명
              </Text>
              <AdminDropdown
                margin={{
                  right: "10px",
                }}
                size={{
                  width: "110px",
                  height: "28px",
                }}
                menuSize={{
                  width: "110px",
                  height: "auto",
                }}
                category={labels}
                onSelect={changeSelectedList}
              />
            </Box>
            <Box
              theme="adminListBox"
              size={{
                height: "44px",
              }}
              padding={{
                left: "8px",
              }}
              border={{
                top: "1px",
                style: "solid",
                color: "gray10",
              }}
            >
              <AdminButton
                category="button"
                theme="listOptionButton"
                size={{
                  width: "63px",
                  height: "28px",
                }}
                padding={{
                  left: "6px",
                  right: "6px",
                }}
                onClick={handleApprove}
              >
                일괄 승인
              </AdminButton>
            </Box>
          </Box>
          {/* 회원 목록 리스트 */}
          <Table isAttached={true} margin={{ bottom: "40px" }}>
            <thead>
              <tr>
                <TableHead width="42px" padding="8px 0">
                  <AdminCheckbox
                    margin={{
                      left: "13px",
                    }}
                    value="all"
                    checked={storyList
                      ?.filter((item) => item.result === 0)
                      .every((item) => selectedUser[item.creationId])}
                    onClick={handleSelectAll}
                    readOnly={isAllReadOnly}
                  />
                </TableHead>
                <TableHead width="72px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    NO.
                  </Text>
                </TableHead>

                <TableHead width="161px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    이메일
                  </Text>
                </TableHead>

                <TableHead width="196px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    제출곡명
                  </Text>
                </TableHead>

                <TableHead width="236px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    제출 음원
                  </Text>
                </TableHead>

                <TableHead width="94px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    상태
                  </Text>
                </TableHead>

                <TableHead width="142px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    요청일시
                  </Text>
                </TableHead>
                <TableHead
                  width="calc(1024px - 42px - 72px - 161px - 196px - 236px - 94px - 142px)"
                  isLastChild
                >
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    심사
                  </Text>
                </TableHead>
              </tr>
            </thead>
            <tbody>
              {storyList?.map((data, index) => (
                <tr key={data.storyId}>
                  <TableData size={{ height: "63px" }}>
                    <AdminCheckbox
                      margin={{
                        left: "13px",
                      }}
                      checked={selectedUser[data.creationId]}
                      onClick={(e) => handleSelect(e, data.creationId)}
                      value={data.creationId}
                      readOnly={data.result !== 0 ? true : false}
                    />
                  </TableData>
                  <TableData>
                    {total - (currentPage - 1) * pageSize - index}
                  </TableData>
                  <TableData
                    onClick={() => openNewWindow(data.member.memberId)}
                    other={{
                      cursor: "pointer",
                    }}
                  >
                    <Text
                      size={{
                        width: "161px",
                      }}
                      typo={{
                        lineClamp: "1",
                        wordBreak: "break-all",
                        textDecoration: "underline",
                      }}
                      padding={{
                        left: "8px",
                        right: "8px",
                      }}
                    >
                      {data.member.memberEmail}
                    </Text>
                  </TableData>
                  <TableData>
                    <Text
                      size={{
                        width: "196px",
                        height: "100%",
                      }}
                      typo={{
                        lineClamp: "1",
                        wordBreak: "break-all",
                        align: "left",
                      }}
                      padding={{
                        left: "8px",
                        right: "8px",
                      }}
                    >
                      {data.musicName}
                    </Text>
                  </TableData>
                  <TableData>
                    <ReactAudioPlayer
                      style={{
                        width: "212px",
                        height: "25px",
                      }}
                      controls
                      src={`${baseURL}/${data.musicFile}`}
                    >
                      재생이 불가능하거나 지원하지 않는 브라우저입니다.
                    </ReactAudioPlayer>
                  </TableData>
                  <TableData>
                    {data.result === 0
                      ? "승인대기"
                      : data.result === 1
                      ? "승인완료"
                      : "승인보류"}
                  </TableData>
                  <TableData>
                    {dayjs(data.createdAt).format("YYYY-MM-DD HH:mm")}
                  </TableData>
                  <TableData isLastChild>
                    <Box
                      size={{
                        width: "100%",
                        height: "100%",
                      }}
                      position={{
                        type: "absolute",
                        left: "8px",
                        top: "4px",
                      }}
                    >
                      {data.result === 0 ? (
                        <>
                          <AdminButton
                            category="button"
                            theme="listButton"
                            onClick={() => {
                              handleEachApprove(data.creationId);
                            }}
                          >
                            승인
                          </AdminButton>
                          <AdminButton
                            category="button"
                            theme={["listButton", "whiteColor"]}
                            margin={{
                              top: "4px",
                              bottom: "5px",
                            }}
                            typo={{
                              color: "black02",
                              size: "13px",
                            }}
                            onClick={() => handlePendingClick(data.creationId)}
                          >
                            보류
                          </AdminButton>
                        </>
                      ) : data.result === 2 ? (
                        <AdminButton
                          category="button"
                          theme={["listButton", "whiteColor"]}
                          margin={{
                            top: "4px",
                            bottom: "5px",
                          }}
                          typo={{
                            color: "black02",
                            size: "13px",
                          }}
                          onClick={() => handlePendingClick(data.creationId)}
                        >
                          보류 사유
                        </AdminButton>
                      ) : null}
                    </Box>
                  </TableData>
                </tr>
              ))}
            </tbody>
          </Table>
        </Box>
        {total > 0 && (
          <AdminPagination
            totalPages={totalPages}
            currentPage={currentPage}
            onPageChange={handlePageChange}
          />
        )}
      </Box>
    </>
  );
};

export default ContentsJudging;
