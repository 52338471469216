import Box from "component/atoms/box";
import Image from "component/atoms/image";
import Input from "component/atoms/input";
import Text from "component/atoms/text";
import AdminButton from "component/molecules/admin/button/adminButton";
import React, { useState, useRef, useEffect } from "react";

const ImageInput = (props) => {
  const { text1, text2, image, onChange } = props;
  // 이미지 업로드 인풋 ref

  const [imagePreview, setImagePreview] = useState(image);
  const fileInputRef = useRef();

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.match("image.*")) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImagePreview(e.target.result);
      };
      reader.readAsDataURL(file);
      onChange(file);
    }
  };

  const handleFileClick = () => {
    fileInputRef.current.click();
  };

  useEffect(() => {
    if (!image) {
      setImagePreview(null);
    } else if (typeof image === "string") {
      setImagePreview(image);
    }
  }, [image]);

  return (
    <Box
      display={{
        display: "flex",
        direction: "column",
      }}
      typo={{
        align: "left",
      }}
    >
      <Box
        display={{
          display: "flex",
          justify: "center",
          align: "center",
        }}
        size={{
          width: "200px",
          height: "200px",
        }}
        border={{
          all: "1px",
          type: "solid",
          color: "gray10",
          radius: "3px",
        }}
        margin={{
          top: "8px",
        }}
        other={{
          cursor: "pointer",
          overflow: "hidden",
        }}
        onClick={handleFileClick}
        onChange={handleImageChange}
      >
        {!imagePreview ? (
          <Image src="/asset/img/admin/input/image_input.svg" />
        ) : (
          <Image
            src={imagePreview}
            alt="Image preview"
            size={{
              width: "198px",
              height: "198px",
            }}
            other={{
              overflow: "hidden",
              objectFit: "contain",
            }}
          />
        )}
        <Input
          theme={"adminInput"}
          ref={fileInputRef}
          type="file"
          display={{
            display: "none",
          }}
          size={{
            width: "200px",
            height: "200px",
          }}
        />
      </Box>
      <AdminButton
        category="button"
        size={{
          width: "136px",
          height: "28px",
        }}
        border={{
          all: "1px",
          type: "solid",
          color: "pink03",
          radius: "3px",
        }}
        background={{
          color: "white01",
        }}
        typo={{
          size: "12px",
          weight: "500",
          color: "pink03",
        }}
        margin={{
          top: "12px",
          bottom: "10px",
        }}
        onClick={handleFileClick}
      >
        이미지 업로드
      </AdminButton>
      <Text
        typo={{
          size: "12px",
          weight: "400",
          color: "black02",
        }}
      >
        {text1}
      </Text>
      <Text
        typo={{
          size: "12px",
          weight: "400",
          color: "black02",
        }}
      >
        {text2}
      </Text>
    </Box>
  );
};

export default ImageInput;
