import Box from "component/atoms/box";
import AdminEditor from "component/atoms/editor/adminEditor";
import RowTable from "component/atoms/rowSideTable";
import RowTableData from "component/atoms/rowTableData";
import RowTableHead from "component/atoms/rowTableHead";
import Text from "component/atoms/text";
import AdminButton from "component/molecules/admin/button/adminButton";
import React, { useState, useEffect } from "react";
import { useAdminTerms, useUpdateAdminTerms } from "hook/api/admin/setup/terms";
import AdminAlertModal from "../../modal/alertModal";
import useAdminEditorForm from "hook/api/admin/editor";
import {
  useAdminStoryTerm,
  useUpdateStoryTerm,
} from "hook/api/admin/setup/storyTerm";

const StoryTerm = () => {
  const {
    editorRef,
    content,
    //
    setContent,
  } = useAdminEditorForm();

  const { data: terms } = useAdminStoryTerm();

  // 등록 성공 모달, 실패 모달
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false); // 등록 실패

  const handleSuccess = () => {
    setSuccess(true);
  };

  const handleError = () => {
    setError(true);
  };

  const { mutate: updateTerms } = useUpdateStoryTerm(
    handleSuccess,
    handleError
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    window.confirm("이야기 이용약관을 등록하시겠습니까?") &&
      updateTerms({ content: content });
  };

  useEffect(() => {
    if (terms && editorRef.current) {
      editorRef.current.setContents(terms?.content);
      setContent(terms?.content);
    }
  }, [terms]);

  return (
    <Box
      size={{
        width: "100%",
        height: "348px",
      }}
      margin={{
        top: "25px",
      }}
    >
      {success && (
        <AdminAlertModal
          title={"이야기 이용약관"}
          text="이야기 이용약관 등록이 완료되었습니다."
          btn_text="확인"
          visible={success}
          toggle={() => {
            setSuccess(false);
          }}
          onClick={() => {
            setSuccess(false);
          }}
        />
      )}
      {error && (
        <AdminAlertModal
          title={"이야기 이용약관"}
          text="이야기 이용약관 등록에 실패하였습니다."
          btn_text="확인"
          visible={error}
          toggle={() => {
            setError(false);
          }}
          onClick={() => {
            setError(false);
          }}
        />
      )}
      <Text theme={"adminTitle"}>이야기 이용약관</Text>
      <RowTable>
        <Box
          display={{
            width: "100%",
            height: "52px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
          typo={{
            align: "left",
          }}
          other={{
            overflowX: "hidden",
          }}
        >
          <RowTableHead
            width="160px"
            height="348px"
            padding="11px 0px 323px 17px"
            isLastChild={true}
          >
            약관내용
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
            padding={{
              top: "10px",
              left: "10px",
            }}
            isLastChild={true}
          >
            <AdminEditor
              ref={editorRef}
              //
              setContent={setContent}
              // onChange={setContent}
            />
          </RowTableData>
        </Box>
      </RowTable>
      <AdminButton
        category="button"
        theme={["mainButton"]}
        margin={{
          top: "24px",
          left: "460px",
        }}
        onClick={handleSubmit}
      >
        저장
      </AdminButton>
    </Box>
  );
};

export default StoryTerm;
