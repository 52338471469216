import Box from "component/atoms/box";
import Button from "component/atoms/button";
import Link from "component/atoms/link";
import Text from "component/atoms/text";
import StoryInfo from "component/molecules/common/storyInfo";
import Thumbnail from "component/molecules/common/thumbnail";
import ProjectTitle from "component/molecules/main/projectTitle";
import ProjectUser from "component/molecules/main/projectUser";
import useUrl from "hook/util/useUrl";
import { AiOutlineRight } from "react-icons/ai";
import convertImage from "utils/convertImage";
import numberWithCommas from "utils/numberWithCommas";

const StorySupport = (props) => {
  const {
    storyId,
    thumbnail,
    storyTitle,
    storyStatus,
    coinValue,
    member,
    artist,
    moomoostPick,
    cheeringData,
  } = props;

  // 100000 - 1233 - 12312 =
  // 100000 - 99768 =

  const { changeQuery } = useUrl();

  return (
    <Button
      background={{
        color: "transparent",
      }}
    >
      <Box
        display={{
          display: "flex",
          direction: "column",
          gap: "12px",
        }}
      >
        <Thumbnail
          src={convertImage(thumbnail)}
          status={storyStatus}
          cheeringData={cheeringData}
          isPick={moomoostPick}
        />

        <StoryInfo
          infoList={[
            <ProjectTitle title={storyTitle} />,
            <ProjectUser member={member} artist={artist} />,
            <Box>
              <Text
                typo={{
                  size: "12px",
                  weight: "700",
                  align: "left",
                }}
                longMobile={{
                  typo: {
                    size: "10px",
                  },
                }}
              >
                {numberWithCommas(coinValue)} Coin
              </Text>
            </Box>,

            <Button
              display={{
                display: "flex",
                align: "center",
                justify: "flex-end",
              }}
              size={{
                width: "100%",
              }}
              longMobile={{
                typo: {
                  size: "10px",
                },
              }}
              onClick={() => changeQuery({ detail: storyId })}
            >
              상세내역
              <AiOutlineRight
                size={10}
                style={{
                  marginLeft: "5px",
                }}
              />
            </Button>,
          ]}
        />
      </Box>
    </Button>
  );
};

export default StorySupport;
