import { moomoocoin } from "api/mypage";
import { useQuery } from "react-query";

const useMooMooCoin = ({ state, page }) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["moomoocoin", state, page],
    () => moomoocoin(state, page)
  );

  const list = data?.data;
  const maxPage = data?.totalPages;

  return {
    list,
    maxPage,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useMooMooCoin;
