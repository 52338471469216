import { useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  getAdminFilterKeyword,
  createAdminFilterKeyword,
  deleteAdminFilterKeyword,
} from "api/admin/setup/wordFiltering";
import { useNavigate } from "react-router-dom";

export const useAdminFilterKeyword = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    "adminFilterKeyword",
    getAdminFilterKeyword,
    {
      onError: (error) => {
        if (error.response && error.response.status === 403) {
          alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
          navigate("/admin/login");
        }
      },
    }
  );
  return { data: data?.data, isLoading };
};

export const useCreateAdminFilterKeyword = (onSuccess, onError, onExist) => {
  const queryClient = useQueryClient();
  const { mutate, data, isLoading } = useMutation(
    ({ keyword }) => createAdminFilterKeyword(keyword),
    {
      onSuccess: () => {
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess();
        }
        queryClient.invalidateQueries("adminFilterKeyword");
      },
      onError: (error) => {
        if (error.response.status === 409) {
          if (onExist && typeof onExist === "function") {
            onExist(error);
          }
        } else {
          if (onError && typeof onError === "function") {
            onError(error);
          }
        }
      },
    }
  );

  return {
    mutate,
    data,
    isLoading,
  };
};

export const useDeleteAdminFilterKeyword = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const { mutate, data, isLoading } = useMutation(deleteAdminFilterKeyword, {
    onSuccess: () => {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
      queryClient.invalidateQueries("adminFilterKeyword");
    },
    onError: (error) => {
      if (onError && typeof onError === "function") {
        onError(error);
      }
    },
  });

  return {
    mutate,
    data,
    isLoading,
  };
};
