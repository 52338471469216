import React, { createContext, useState, useEffect, useContext } from "react";

export const MenuContext = React.createContext({
  selectedMenu: null,
  setSelectedMenu: () => {},
});

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState(() => {
    // 세션 스토리지에서 인증 데이터 불러오기
    const savedAuthData = sessionStorage.getItem("authData");
    return savedAuthData ? JSON.parse(savedAuthData) : null;
  });

  const login = (data) => {
    setAuthData(data);
    sessionStorage.setItem("authData", JSON.stringify(data)); // 세션 스토리지에 저장
  };

  return (
    <AuthContext.Provider value={{ authData, login }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
