import Box from "component/atoms/box";
import Text from "component/atoms/text";
import React from "react";
import CommonButton from "../commonButton";

const PersonalityList = (props) => {
  const { personality, mood, category } = props;

  return (
    <>
      <Box
        display={{
          display: "flex",
          gap: "20px",
        }}
        longMobile={{
          display: {
            wrap: "wrap",
            gap: "4px",
          },
          size: {
            width: "100%",
          },
        }}
      >
        <Box
          size={{
            width: "55px",
          }}
          longMobile={{
            size: {
              width: "100%",
            },
            display: {
              display: "flex",
            },
          }}
        >
          <Text
            typo={{
              size: "12px",
              weight: "700",
            }}
            longMobile={{
              size: {
                width: "46px",
              },
              typo: {
                size: "10px",
              },
            }}
          >
            카테고리
          </Text>
          <CommonButton
            category={"button"}
            size={{
              minWidth: "42px",
              height: "25px",
            }}
            typo={{
              size: "12px",
              weight: "400",
            }}
            longMobile={{
              size: {
                minWidth: "27px",
                height: "19px",
              },
              padding: {
                all: "3px",
              },
              typo: {
                size: "10px",
              },
            }}
          >
            {category}
          </CommonButton>
        </Box>
        <Box
          longMobile={{
            size: {
              width: "100%",
            },
            display: {
              display: "flex",
            },
          }}
        >
          <Text
            typo={{
              size: "12px",
              weight: "700",
            }}
            longMobile={{
              size: {
                width: "46px",
              },
              typo: {
                size: "10px",
              },
            }}
          >
            분위기
          </Text>

          <Box
            display={{
              display: "flex",
              gap: "4px",
            }}
          >
            {mood.map((item) => (
              <CommonButton
                category={"button"}
                size={{
                  minWidth: "42px",
                  height: "25px",
                }}
                typo={{
                  size: "12px",
                  weight: "400",
                }}
                longMobile={{
                  size: {
                    minWidth: "27px",
                    height: "19px",
                  },
                  padding: {
                    all: "3px",
                  },
                  typo: {
                    size: "10px",
                  },
                }}
                key={item}
              >
                {item}
              </CommonButton>
            ))}
          </Box>
        </Box>
      </Box>

      <Box
        margin={{
          top: "12px",
        }}
        longMobile={{
          display: {
            display: "flex",
          },
          size: { width: "100%" },
          margin: {
            top: "4px",
          },
        }}
      >
        <Text
          typo={{
            size: "12px",
            weight: "700",
          }}
          longMobile={{
            size: {
              width: "46px",
            },
            typo: {
              size: "10px",
            },
          }}
        >
          페르소나
        </Text>

        <Box
          display={{
            display: "flex",
            wrap: "wrap",
            gap: "4px",
          }}
          longMobile={{
            size: {
              width: "calc(100% - 46px)",
            },
          }}
        >
          {personality.slice(0, 3).map((item) => (
            <CommonButton
              category={"button"}
              size={{
                minWidth: "42px",
                height: "25px",
              }}
              typo={{
                size: "12px",
                weight: "400",
              }}
              longMobile={{
                size: {
                  minWidth: "27px",
                  height: "19px",
                },
                padding: {
                  all: "3px",
                },
                typo: {
                  size: "10px",
                },
              }}
              key={item}
            >
              {item}
            </CommonButton>
          ))}
        </Box>
      </Box>
    </>
  );
};

export default PersonalityList;
