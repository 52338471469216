import React from "react";
import { TableHeadWrapper } from "./tableHead.style";

const TableHead = (props) => {
  const { children, width, height, padding, isPink, isLastChild } = props;
  return (
    <TableHeadWrapper
      width={width}
      height={height}
      padding={padding}
      isPink={isPink}
      isLastChild={isLastChild}
    >
      {children}
    </TableHeadWrapper>
  );
};

export default TableHead;
