import React, { useState, useEffect } from "react";
import Table from "component/atoms/table";
import TableHead from "component/atoms/tableHead";
import TableData from "component/atoms/tableData";
import Box from "component/atoms/box";
import Text from "component/atoms/text";
import AdminButton from "component/molecules/admin/button/adminButton";
import {
  useAdminNewMember,
  useAdminArtist,
  useAdminCompletion,
  useAdminLatest,
  useGetToday,
} from "hook/api/admin/dashboard";
import Api from "component/molecules/common/api";
import Link from "component/atoms/link";
import dayjs from "dayjs";
import { set } from "react-hook-form";

const DashBoard = () => {
  const { data: newMember } = useAdminNewMember();
  const { data: artist } = useAdminArtist();
  const { data: completion } = useAdminCompletion();
  // total: 이야기 전체 게시물 수 (최신순으로 정렬)
  const { data: latest, totalItems: total } = useAdminLatest();

  const today = useGetToday();

  return (
    <Box
      size={{
        width: "100%",
        height: "auto",
      }}
      margin={{
        top: "30px",
      }}
      other={{
        overflowX: "hidden",
      }}
    >
      <Box theme={"adminRight"}>
        <Text
          typo={{
            size: "13px",
            weight: "400",
            color: "gray08",
          }}
          margin={{
            bottom: "10px",
          }}
        >
          {today?.data}
        </Text>
      </Box>
      <Table margin={{ bottom: "40px" }}>
        <thead>
          <tr>
            <TableHead
              width="calc(1024px / 3)"
              height="47px"
              padding="15px 0"
              isPink
            >
              <Text
                typo={{
                  color: "black01",
                }}
              >
                아티스트 요청
              </Text>
            </TableHead>
            <TableHead
              width="calc(1024px / 3)"
              height="47px"
              padding="15px 0"
              isPink
            >
              <Text
                typo={{
                  color: "black01",
                }}
              >
                창작 완료 요청
              </Text>
            </TableHead>

            <TableHead
              width="calc(1024px / 3)"
              height="47px"
              padding="15px 0"
              isPink
              isLastChild
            >
              <Text
                typo={{
                  color: "black01",
                }}
              >
                신규 회원
              </Text>
            </TableHead>
          </tr>
        </thead>
        <tbody>
          <tr>
            <TableData
              size={{ height: "52px" }}
              padding={{
                top: "18px",
                bottom: "18px",
              }}
            >
              <Box
                display={{
                  display: "flex",
                  justify: "center",
                  align: "center",
                }}
              >
                {/* 아티스트 등업관리 페이지로 이동하기*/}
                <Link
                  link={"/admin/manage/user?menu=user&category=gradeManage"}
                  typo={{
                    size: "16px",
                    weight: "700",
                    color: "pink02",
                  }}
                  border={{
                    bottom: "1px solid",
                    color: "pink02",
                  }}
                >
                  {artist?.regArtist}
                </Link>
                &nbsp;명
              </Box>
            </TableData>

            <TableData>
              <Box
                display={{
                  display: "flex",
                  justify: "center",
                  align: "center",
                }}
              >
                {/* 창작 완료 심사 페이지로 이동 */}
                <Link
                  link={"/admin/manage/story?menu=story&category=judging"}
                  typo={{
                    size: "16px",
                    weight: "700",
                    color: "pink02",
                  }}
                  border={{
                    bottom: "1px solid",
                    color: "pink02",
                  }}
                >
                  {completion?.count}
                </Link>
                &nbsp;개{" "}
              </Box>
            </TableData>
            <TableData isLastChild>
              <Box
                display={{
                  display: "flex",
                  justify: "center",
                  align: "center",
                }}
              >
                {/* 회원 관리 페이지로 이동 */}
                <Link
                  link={"/admin/manage/user?menu=user&category=userManage"}
                  typo={{
                    size: "16px",
                    weight: "700",
                    color: "pink02",
                  }}
                  border={{
                    bottom: "1px solid",
                    color: "pink02",
                  }}
                >
                  {newMember?.newMember}
                </Link>
                &nbsp;명
              </Box>
            </TableData>
          </tr>
        </tbody>
      </Table>

      <Text theme={"adminTitle"}>최근 게시물</Text>
      <Table>
        <thead>
          <tr>
            <TableHead width="72px" height="49px" padding="15px 0">
              NO.
            </TableHead>
            <TableHead width="660px">제목</TableHead>

            <TableHead width="152px">작성자</TableHead>
            <TableHead width="139px" isLastChild>
              등록일시
            </TableHead>
          </tr>
        </thead>
        <tbody>
          {latest?.map((item, index) => (
            <tr key={item.storyId}>
              <TableData
                size={{ height: "52px" }}
                padding={{
                  top: "18px",
                  bottom: "18px",
                }}
              >
                {total - index}
              </TableData>
              <TableData>
                <Link
                  link={`/story/${item.storyId}`}
                  target="_blank"
                  size={{
                    width: "70%",
                    height: "auto",
                  }}
                  margin={{
                    left: "14px",
                  }}
                  typo={{
                    align: "left",
                    lineClamp: 2,
                  }}
                >
                  <Text
                    typo={{
                      textDecoration: "underline",
                    }}
                  >
                    {item.storyTitle}
                  </Text>
                </Link>
              </TableData>
              <TableData>
                <Box
                  size={{
                    width: "152px",
                    height: "auto",
                  }}
                  margin={{
                    left: "14px",
                    right: "14px",
                  }}
                  typo={{
                    align: "center",
                    lineClamp: 2,
                  }}
                >
                  {item.memberName}
                </Box>
              </TableData>
              <TableData isLastChild>
                {dayjs(item.createdAt).format("YYYY-MM-DD HH:mm")}
              </TableData>
            </tr>
          ))}
        </tbody>
      </Table>

      <Box theme={"adminRight"}>
        <AdminButton
          category="link"
          link="/admin/manage/story?menu=story&category=storyManage"
          theme={["subButton", "whiteColor"]}
          margin={{
            top: "10px",
          }}
        >
          전체글 보기
        </AdminButton>
      </Box>
    </Box>
  );
};

export default DashBoard;
