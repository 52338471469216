import { comment, getComment, updateComment } from "api/story";
import { ErrorContent } from "component/organisms/common/globalModal";
import useToggle from "hook/util/useToggle";
import useUrl from "hook/util/useUrl";
import { useEffect, useMemo, useRef, useState } from "react";
import { set } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";

const editorId = "editor";

const required = {
  storyboardContent: true,
};

const useAdminEditorForm = () => {
  const editorRef = useRef(null);
  const [type, setType] = useState("0");
  const {
    toggle: isNotice,
    toggleOn: noticeOn,
    toggleOff: noticeOff,
    setToggle,
  } = useToggle();

  const {
    params,
    query: { id },
    changeLink,
  } = useUrl();
  const queryClient = useQueryClient();
  const [content, setContent] = useState("<p><br></p>");

  const isActive = content !== "<p><br></p>";

  // const updateEditor = (data) => {
  //   editorRef.current.setContents(data);
  // };

  // useEffect(() => {
  //   updateEditor(content);
  // }, [content]);

  return {
    editorRef,
    editorId,
    type,
    isNotice,
    content,
    isActive,
    setContent,
    noticeOn,
    noticeOff,
    setType,
  };
};

export default useAdminEditorForm;
