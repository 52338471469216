import { additionalRequest } from "api/story";
import Text from "component/atoms/text";
import { ErrorContent } from "component/organisms/common/globalModal";
import useUrl from "hook/util/useUrl";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";
import checkRequired from "utils/checkRequired";

const required = {
  matchTitle: true,
  matchContent: true,
};

const SuccessContent = ({ message }) => {
  return (
    <Text
      typo={{
        size: "18px",
        weight: "700",
        align: "center",
      }}
      longMobile={{
        typo: {
          size: "14px",
          weight: "700",
        },
      }}
    >
      {message}
    </Text>
  );
};

const useAdditionalRequestForm = () => {
  const form = useForm({
    defaultValues: {
      matchTitle: "",
      matchContent: "",
    },
  });
  const { watch, handleSubmit } = form;
  const {
    params: { id },
    changeLink,
  } = useUrl();
  const queryClient = useQueryClient();
  const { setModal } = useModalStore();
  const additionalMutation = useMutation(additionalRequest, {
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries(["story", id]);
      changeLink(`/story/${id}`);
      setModal({
        title: "추가 멤버 신청하기",
        content: <SuccessContent message={message} />,
      });
    },
    onError: () => {
      setModal({
        title: "추가 멤버 신청하기",
        content: <ErrorContent />,
      });
    },
  });

  const isActive = useMemo(() => checkRequired(required, watch()), [watch()]);

  const onSubmit = (data) => {
    isActive && additionalMutation.mutate({ data, id });
  };

  return {
    form,
    isActive,
    onSubmit: handleSubmit(onSubmit),
  };
};

export default useAdditionalRequestForm;
