import React, { useState } from "react";
import AdminModal from "component/molecules/admin/adminModal";
import AdminButton from "component/molecules/admin/button/adminButton";
import Text from "component/atoms/text";
import Box from "component/atoms/box";
import Input from "component/atoms/input";

const CoinModal = (props) => {
  const {
    toggle,
    handleToggle,
    onChange,
    save,
    cancle,
    value,
    onChangeInput,
    coinReason,
    category,
    coinValue,
    data,
  } = props;
  // const [value, setValue] = useState("");

  // const handleInput = (e) => {
  //   setValue(e.target.value);
  // };

  // const handleSave = () => {
  //   save(value);
  //   handleToggle();
  // };

  const isReadOnly = !!data;

  return (
    <AdminModal
      isCoin
      visible={toggle}
      onClose={handleToggle}
      title={
        category === "지급" && data
          ? "코인 지급 상세"
          : category === "회수" && data
          ? "코인 회수 상세"
          : category === "지급" && !data
          ? "코인 지급"
          : "코인 회수"
      }
      info={
        <Text
          size={{
            width: "100%",
          }}
          typo={{
            size: "14px",
            weight: "400",
            color: "black01",
            lineHight: "0px",
          }}
          margin={{
            top: "14px",
          }}
        >
          {category === "지급" ? "지급 코인" : "회수 코인"}
        </Text>
      }
    >
      <Box
        size={{
          width: "100%",
          height: "50px",
        }}
        display={{
          display: "flex",
          align: "center",
        }}
        margin={{
          top: "4px",
        }}
      >
        <Input
          as="input"
          type="number"
          value={coinValue}
          onChange={onChange}
          readOnly={isReadOnly}
          theme="adminInput"
          size={{
            width: "311px",
            height: "50px",
          }}
          padding={{ left: "15px" }}
        />
        <Text
          display={{
            display: "inline-block",
          }}
          size={{
            width: "auto",
            height: "auto",
          }}
          margin={{
            left: "8px",
          }}
          typo={{
            size: "14px",
            weight: "400",
            color: "black01",
          }}
        >
          Coin
        </Text>
      </Box>
      <Box>
        <Text
          margin={{
            top: "25px",
            bottom: "4px",
          }}
          typo={{
            size: "14px",
            weight: "400",
            color: "black01",
          }}
        >
          {category === "지급" ? "지급 사유" : "회수 사유"}
        </Text>
        <Input
          as="textarea"
          type="text"
          value={coinReason}
          onChange={onChangeInput}
          readOnly={isReadOnly}
          theme="adminInput"
          size={{
            width: "100%",
            height: "140px",
          }}
          padding={{
            top: "15px",
            left: "15px",
          }}
        />
      </Box>
      <Box
        display={{
          display: "flex",
        }}
        margin={{
          top: "10px",
        }}
      >
        {!data ? (
          <>
            <AdminButton
              category="button"
              theme="grayColor"
              size={{
                width: "176px",
                height: "40px",
              }}
              margin={{
                right: "12px",
              }}
              onClick={cancle}
            >
              취소
            </AdminButton>
            <AdminButton
              category="button"
              size={{
                width: "176px",
                height: "40px",
              }}
              onClick={save}
            >
              {category === "지급" ? "지급" : "회수"}
            </AdminButton>
          </>
        ) : (
          <AdminButton
            category="button"
            size={{
              width: "364px",
              height: "40px",
            }}
            onClick={handleToggle}
          >
            확인
          </AdminButton>
        )}
      </Box>
    </AdminModal>
  );
};

export default CoinModal;
