import Box from "component/atoms/box";
import Image from "component/atoms/image";
import React from "react";
import Status from "../status";
import Text from "component/atoms/text";
import dayjs from "dayjs";

const diffCheeringDate = (startDate, endDate) => {
  const start = dayjs(startDate);
  const end = dayjs(endDate);

  const diff = end.diff(start, "day");

  return diff;
};

const convertPercent = (percent) => {
  return percent > 100 ? 100 : percent;
};

const Thumbnail = (props) => {
  const {
    src,
    status,
    cheeringData,
    moomoostPick,
    //
    isDetail,
  } = props;

  return (
    <Box
      size={{
        width: "100%",
        height: isDetail ? "auto" : "100%",
        aspectRatio: "1/1",
      }}
      position={{
        type: isDetail ? "initial" : "relative",
      }}
      background={{
        color: "white01",
      }}
      border={{
        all: isDetail ? "0px" : "2px",
      }}
      typo={{
        size: "0",
      }}
    >
      {(status === "창작완료" || status === "발매완료") && (
        <Box
          position={{
            type: isDetail ? "initial" : "relative",
          }}
        >
          <Box
            position={{
              type: "absolute",
              top: "-50px",
              isCenterX: true,
              zIndex: "-1",
            }}
            size={{
              width: "93%",
            }}
            pc={{
              position: {
                top: "-40px",
              },
            }}
            longMobile={{
              position: {
                top: "-30px",
              },
            }}
          >
            <Image
              src={"/asset/img/story/lp.svg"}
              size={{
                width: "100%",
                height: "100%",
              }}
            />
          </Box>
        </Box>
      )}

      <Status type={status} size={isDetail ? "large" : "small"} />
      <Image
        src={src}
        size={{
          width: "100%",
          // height: "100%",
          aspectRatio: "1/1",
        }}
      />

      {moomoostPick && (
        <Box
          position={{
            type: "absolute",
            top: !isDetail && "5px",
            right: isDetail ? "15px" : "-16px",
            bottom: isDetail && "15px",
            zIndex: "1",
          }}
          longMobile={{
            size: {
              width: isDetail ? "65px" : "47px",
            },
            position: {
              top: !isDetail && "-10px",
              right: isDetail ? "15px" : "-8px",
              bottom: isDetail && "15px",
            },
          }}
        >
          <Image
            src={
              isDetail
                ? "/asset/img/story/detailPick.svg"
                : "/asset/img/story/pick.svg"
            }
            size={{
              width: "100%",
            }}
          />
        </Box>
      )}

      {cheeringData && cheeringData.cheeringStatus !== "응원대기" && (
        <Box
          position={{
            type: "absolute",
            bottom: "10px",
            left: "0",
            right: "0",
            zIndex: "1",
          }}
          padding={{
            all: "0 12px",
          }}
          longMobile={{
            padding: {
              all: "0 8px",
            },
          }}
        >
          <Box
            display={{
              display: "flex",
              justify: "space-between",
              align: "center",
            }}
            margin={{
              bottom: "5px",
            }}
          >
            <Box
              border={{
                all: "1px",
              }}
              padding={{
                all: "4px",
              }}
              background={{
                color: cheeringData.cheeringDday
                  ? "white01"
                  : cheeringData.cheeringStatus === "응원실패"
                  ? "gray02"
                  : "pink01",
              }}
            >
              <Text
                typo={{
                  size: "12px",
                  weight: "500",
                }}
                longMobile={{
                  typo: {
                    size: "10px",
                  },
                }}
              >
                {cheeringData.cheeringDday
                  ? `${cheeringData.cheeringDday}일 남음`
                  : cheeringData.cheeringStatus}
              </Text>
            </Box>
            <Text
              typo={{
                size: "14px",
                weight: "900",
                color: "pink02",
                textStroke: "0.7px black",
              }}
            >
              {cheeringData.cheeringPercent}%
            </Text>
          </Box>
          <Box
            position={{
              type: "relative",
            }}
            display={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Box
              size={{
                width: `${convertPercent(cheeringData.cheeringPercent)}%`,
                height: "5px",
              }}
              background={{
                color: "pink02",
              }}
              border={{
                all: "1px",
              }}
            />
            <Box
              size={{
                width: `${100 - convertPercent(cheeringData.cheeringPercent)}%`,
                height: "5px",
              }}
              background={{
                color: "gray01",
              }}
              border={{
                all: "1px",
              }}
            />
            <Box
              position={{
                type: "absolute",
                left: `calc(${convertPercent(
                  cheeringData.cheeringPercent
                )}% - 5px)`,
                isCenterY: true,
              }}
              size={{
                width: "10px",
                height: "10px",
              }}
              background={{
                color: "pink02",
              }}
              border={{
                all: "1px",
                radius: "50%",
              }}
            />
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default Thumbnail;
