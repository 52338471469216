import { GET, POST, DELETE } from "api/config";

export const getCheeringList = async (
  cheeringState = "",
  classification = "",
  startDate = "",
  endDate = "",
  memberName = "",
  storyTitle = "",
  total = "",
  pageSize = "",
  currentPage = "1"
) =>
  await GET(
    `/admin/story/cheering/list?cheeringState=${cheeringState}&classifiCation=${classification}&startDate=${startDate}&endDate=${endDate}&memberName=${memberName}&storyTitle=${storyTitle}&total=${total}&pageSize=${pageSize}&page=${currentPage}`
  );

export const deleteCheeringStory = async (storyId) => {
  const ids = storyId.join(",");
  await DELETE(`/admin/story/cheering/list?storyIds=${ids}`);
};
