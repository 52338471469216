import { GET, POST } from "api/config";

export const login = async ({ email, password }) =>
  await POST("/auth/login", {
    email,
    password,
  });

export const snsLogin = async ({ email, name, socialProvider }) =>
  await POST("/auth/login/sns", { email, name, socialProvider });

export const signup = async (data) => await POST("/auth/signup", data);

export const find = async ({ email }) =>
  await POST("/auth/tmp/pass", { email });

export const checkLoading = async () => await GET("/auth/islogin");

export const mycoin = async () => await GET("/auth/mycoin");

export const logout = async () => await POST("/auth/logout");

export const sendCode = async ({ email }) =>
  await POST("/auth/email/code", { email });

export const checkCode = async ({ email, code }) =>
  await POST("/auth/email/verify", { email, code });
