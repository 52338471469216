import { likeList } from "api/mypage";
import { useQuery } from "react-query";

const useLikeList = ({ state, page }) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["likeList", state, page],
    () => likeList(state, page)
  );

  const list = data?.data;
  const maxPage = data?.totalPages;

  return {
    list,
    maxPage,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useLikeList;
