import { marketing } from "api/main";
import { useQuery } from "react-query";

const useMarketing = () => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    "marketing",
    marketing
  );

  return {
    data: data?.data,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useMarketing;
