import Box from "component/atoms/box";
import Button from "component/atoms/button";
import LineTitle from "component/organisms/common/lineTitle";
import { GrClose } from "react-icons/gr";

const Modal = (props) => {
  const {
    children,
    title,
    width,
    info,
    visible,
    clearPadding,
    isActive,
    isNeedLine,
    isDisableBackgroundClose,
    //
    onClose,
  } = props;

  return (
    <Box
      position={{
        type: "fixed",
        top: "0",
        left: "0",
        right: "0",
        bottom: "0",
        zIndex: "999",
      }}
      display={{
        display: visible ? "flex" : "none",
        justify: "center",
        align: "center",
      }}
      background={{
        color: "rgba(0, 0, 0, 0.2)",
      }}
      mobile={{
        padding: {
          all: "16px",
        },
      }}
      // onClick={() => !isDisableBackgroundClose && onClose()}
    >
      <Box
        animation={"modalPopOpen"}
        position={{
          type: "relative",
        }}
        size={{
          minWidth: width ?? "633px",
        }}
        padding={{
          all: clearPadding ? "0px" : "35px",
        }}
        background={{
          color: isActive ? "pink02" : "white01",
        }}
        mobile={{
          size: {
            minWidth: "100%",
          },
        }}
        border={{
          all: "2px",
        }}
        longMobile={{
          padding: {
            all: "20px 15px",
          },
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <LineTitle
          margin={{
            bottom: "15px",
          }}
          mobileTypo={{
            size: "16px",
          }}
          title={title}
          isNeedLine={isNeedLine}
        >
          {info}
          <Button
            position={{
              type: "absolute",
              right: "0",
              top: "10px",
            }}
            background={{
              color: "transparent",
            }}
            typo={{
              size: "0",
            }}
            mobile={{
              position: {
                top: "5px",
              },
            }}
            onClick={onClose}
          >
            <GrClose size={16} />
          </Button>
        </LineTitle>
        {children}
      </Box>
    </Box>
  );
};

export default Modal;
