import Box from "component/atoms/box";
import Input from "component/atoms/input";
import RowTable from "component/atoms/rowSideTable";
import RowTableData from "component/atoms/rowTableData";
import RowTableHead from "component/atoms/rowTableHead";
import Text from "component/atoms/text";
import React, { useState } from "react";
import AdminDropdown from "component/molecules/admin/adminDropdown";
import AdminButton from "component/molecules/admin/button/adminButton";
import Image from "component/atoms/image";
import TableData from "component/atoms/tableData";
import TableHead from "component/atoms/tableHead";
import Table from "component/atoms/table";
import AdminCheckbox from "component/molecules/adminCheckbox";
import CalendarInput from "component/molecules/common/calendarInput";
import useToggle from "hook/util/useToggle";
import numberWithCommas from "utils/numberWithCommas";
import dayjs from "dayjs";
import useUrl from "hook/util/useUrl";
import { useAdminStoryCheeringCancel } from "hook/api/admin/story/storyManage";
import { useEffect } from "react";
import { getCurrentDateTime } from "utils/getCurrentDateTime";

const StoryCheeringEdit = (props) => {
  const { cheeringList, total, data } = props;
  const {
    query: { page = 1, total: totalName, memberName, memberEmail },
    changeQuery,
  } = useUrl();

  const category = ["전체", "이메일", "회원명"];
  const listCategory = [
    "10개씩 보기",
    "20개씩 보기",
    "30개씩 보기",
    "50개씩 보기",
  ];
  const listCategoryData = {
    "10개씩 보기": 10,
    "20개씩 보기": 20,
    "30개씩 보기": 30,
    "50개씩 보기": 50,
  };

  const cancelCheering = useAdminStoryCheeringCancel();

  const [selected, setSelected] = useState(category[0]);
  const [selectedSize, setSelectedSize] = useState(
    listCategoryData[listCategory[0]]
  );

  const changeSelected = (category) => {
    setSelected(category);
  };

  const changeSelectedSize = (category) => {
    changeQuery({ pageSize: listCategoryData[category] });
  };

  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [input, setInput] = useState("");

  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    setSelectedUser({});
  }, [page]);

  const currentDateTime = getCurrentDateTime();

  const handleSearch = () => {
    let newStartDate = startDate;
    let newEndDate =
      startDate !== "" && endDate === ""
        ? dayjs(currentDateTime).format("YYYY-MM-DD")
        : endDate;

    if (startDate === "" && dayjs(endDate).isAfter(dayjs(currentDateTime))) {
      newStartDate = dayjs(currentDateTime).format("YYYY-MM-DD");
    }
    if (startDate === "" && dayjs(currentDateTime).isAfter(dayjs(newEndDate))) {
      alert("시작일이 종료일보다 늦습니다. 검색 기간을 다시 설정해주세요.");
      return;
    }
    // 로컬 변수를 사용한 날짜 비교
    if (dayjs(newStartDate).isAfter(dayjs(newEndDate))) {
      alert("시작일이 종료일보다 늦습니다. 검색 기간을 다시 설정해주세요.");
      return;
    }

    if (dayjs(newStartDate).isAfter(dayjs(newEndDate))) {
      alert("시작일이 종료일보다 늦습니다. 검색 기간을 다시 설정해주세요.");
      return;
    }

    const query = {
      startDate: newStartDate,
      endDate: newEndDate,
      page: 1,
    };

    if (selected === "이메일") {
      query.memberEmail = input;
      query.memberName = "";
      query.total = "";
    } else if (selected === "회원명") {
      query.memberName = input;
      query.memberEmail = "";
      query.total = "";
    } else {
      query.total = input;
      query.memberName = "";
      query.memberEmail = "";
    }

    changeQuery(query);
  };

  const handleSelectAll = (e) => {
    const checked =
      Object.keys(selectedUser).length === cheeringList?.length &&
      Object.values(selectedUser).every((checked) => checked);

    setSelectedUser(() => {
      const newSelectedUser = {};
      if (!checked) {
        cheeringList.forEach((data) => {
          newSelectedUser[data.coinId] = true;
        });
      }
      return newSelectedUser;
    });
  };

  const handleSelect = (e, coinId) => {
    setSelectedUser((prevSelectedUser) => {
      const newSelectedUser = { ...prevSelectedUser };
      newSelectedUser[coinId] = !newSelectedUser[coinId];

      return newSelectedUser;
    });
  };

  // 이야기 제목 클릭 시 이야기 관리 상세 페이지로 이동
  const goToStoryDetail = (storyId) => {
    window.open(
      "/admin/manage/story/detail/" +
        storyId +
        "?menu=storyDetail&category=storyEdit",
      "_blank"
    );
  };

  const handleCheeringCancelAll = () => {
    if (window.confirm("응원을 취소하시겠습니까?")) {
      cancelCheering({
        cheerId: data.cheerId,
        coinId: Object.keys(selectedUser)
          .filter((coinId) => selectedUser[coinId])
          .join(","),
      });
    }
  };

  const handleCheeringCancel = (coinId) => {
    if (window.confirm("응원을 취소하시겠습니까?")) {
      cancelCheering({
        cheerId: data.cheerId,
        coinId,
      });
    }
  };

  const goToDetail = (userId) => {
    window.open(
      `/admin/manage/user/detail/${userId}?menu=userDetail&category=basicDetail`,
      "_blank"
    );
  };

  useEffect(() => {
    if (totalName) {
      setSelected("전체");
    } else if (memberName) {
      setSelected("회원명");
    } else if (memberEmail) {
      setSelected("이메일");
    }

    setInput(totalName || memberName || memberEmail || "");
  }, [totalName, memberName, memberEmail]);

  return (
    <>
      {/* 응원 진행 안 할 경우의 레이아웃 추가 필요*/}
      <Box
        size={{
          width: "100%",
          height: "auto",
        }}
        margin={{
          top: "25px",
        }}
      >
        <Text theme={"adminTitle"}>응원 상태</Text>
        <RowTable
          margin={{
            bottom: "55px",
          }}
        >
          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="160px" height="48px" isLastChild={true}>
              응원 일정
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 160px)",
                height: "48px",
              }}
              isLastChild={true}
            >
              <Text margin={{ top: "15px" }}>
                {data?.cheeringStart} ~ {data?.cheeringEnd}
              </Text>
            </RowTableData>
          </Box>
          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="160px" height="48px">
              진행 상태
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 160px)",
                height: "48px",
              }}
            >
              <Text margin={{ top: "15px" }}>응원중</Text>
            </RowTableData>
          </Box>
          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="160px" height="48px">
              달성률
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 160px)",
                height: "48px",
              }}
            >
              <Text margin={{ top: "15px" }}>{data.progressPercentage}%</Text>
            </RowTableData>
          </Box>
          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="160px" height="48px">
              목표 코인
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 160px)",
                height: "48px",
              }}
            >
              <Text margin={{ top: "15px" }}>
                {numberWithCommas(data?.targetAmount)} Coin
              </Text>
            </RowTableData>
          </Box>
          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="160px" height="48px">
              달성 코인
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 160px)",
                height: "48px",
              }}
            >
              <Text margin={{ top: "15px" }}>
                {numberWithCommas(data?.cheeringAmount)} Coin
              </Text>
            </RowTableData>
          </Box>
        </RowTable>

        <Text theme={"adminTitle"}>응원 조회</Text>
        <RowTable>
          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="160px" height="48px" isLastChild>
              응원 기간
            </RowTableHead>
            <RowTableData
              isLastChild
              size={{
                width: "calc(100% - 160px)",
                height: "48px",
              }}
            >
              <Box
                size={{
                  width: "100%",
                  height: "100%",
                }}
                display={{
                  display: "flex",
                  justify: "flex-start",
                  align: "center",
                }}
              >
                <CalendarInput
                  onChange={(date) => {
                    setStartDate(date);
                  }}
                />
                <Text
                  margin={{
                    left: "10px",
                    right: "10px",
                  }}
                >
                  ~
                </Text>
                <CalendarInput
                  onChange={(date) => {
                    setEndDate(date);
                  }}
                />
              </Box>
            </RowTableData>
          </Box>

          <Box
            display={{
              width: "100%",
              height: "48px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="160px" height="48px">
              검색 분류
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 160px)",
              }}
              padding={{
                top: "8px",
                left: "10px",
              }}
            >
              <AdminDropdown
                size={{
                  width: "104px",
                  height: "32px",
                }}
                menuSize={{
                  width: "104px",
                  height: "auto",
                }}
                init={selected}
                category={category}
                onSelect={changeSelected}
              />
              <Input
                theme={"adminInput"}
                size={{
                  width: "500px",
                  height: "32px",
                }}
                value={input}
                onChange={(e) => setInput(e.target.value)}
              />
            </RowTableData>
          </Box>
        </RowTable>
        <AdminButton
          category="button"
          theme="mainButton"
          margin={{
            top: "24px",
            left: "456px",
          }}
          onClick={handleSearch}
        >
          검색
        </AdminButton>

        {/* 회원 목록 */}
        <Box
          size={{
            width: "100%",
            height: "auto",
          }}
          margin={{
            top: "40px",
          }}
        >
          <Text theme={"adminTitle"}>응원 목록 </Text>
          <Box
            size={{
              width: "1024px",
              height: "auto",
            }}
            border={{
              top: "1px",
              left: "1px",
              right: "1px",
              type: "solid",
              color: "gray10",
              radius: "3px 3px 0 0",
            }}
          >
            {/* 목록 상단 인원 수, 차단 버튼 */}
            <Box
              theme="adminListBox"
              size={{
                height: "44px",
              }}
              padding={{
                left: "16px",
              }}
            >
              <Text
                as="span"
                typo={{
                  size: "13px",
                  weight: "500",
                  color: "black02",
                }}
              >
                총{" "}
                <Text
                  as="span"
                  typo={{
                    color: "blue01",
                  }}
                >
                  {numberWithCommas(total)}
                </Text>{" "}
                명
              </Text>
              <AdminDropdown
                margin={{
                  right: "10px",
                }}
                size={{
                  width: "110px",
                  height: "28px",
                }}
                menuSize={{
                  width: "110px",
                  height: "auto",
                }}
                category={listCategory}
                onSelect={changeSelectedSize}
              />
            </Box>
            <Box
              theme="adminListBox"
              size={{
                height: "44px",
              }}
              padding={{
                left: "8px",
              }}
              border={{
                top: "1px",
                style: "solid",
                color: "gray10",
              }}
            >
              <AdminButton
                category="button"
                theme="listOptionButton"
                size={{
                  width: "53px",
                  height: "28px",
                }}
                padding={{
                  left: "6px",
                  right: "6px",
                }}
                onClick={handleCheeringCancelAll}
              >
                <Image
                  src="/asset/img/admin/button/red_X.svg"
                  margin={{
                    right: "2px",
                  }}
                />
                취소
              </AdminButton>
            </Box>
          </Box>
          {/* 회원 목록 리스트 */}
          <Table isAttached={true} margin={{ bottom: "40px" }}>
            <thead>
              <tr>
                <TableHead width="42px" padding="8px 0">
                  <AdminCheckbox
                    margin={{
                      left: "13px",
                    }}
                    checked={
                      Object.keys(selectedUser).length ===
                        cheeringList?.length &&
                      Object.values(selectedUser).every((checked) => checked)
                    }
                    onClick={handleSelectAll}
                  />
                </TableHead>

                <TableHead width="92px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    NO.
                  </Text>
                </TableHead>

                <TableHead width="206px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    이메일
                  </Text>
                </TableHead>

                <TableHead width="156px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    회원명
                  </Text>
                </TableHead>

                <TableHead width="248px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    응원 코인
                  </Text>
                </TableHead>

                <TableHead width="163px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    응원 일시
                  </Text>
                </TableHead>

                <TableHead
                  width="calc(1024px - 42px - 92px - 206px - 156px - 248px - 163px)"
                  isLastChild
                >
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    관리
                  </Text>
                </TableHead>
              </tr>
            </thead>
            <tbody>
              {cheeringList?.map(
                (
                  { coinId, coinValue, createdAt, memberName, memberEmail },
                  idx
                ) => (
                  <tr key={coinId}>
                    <TableData size={{ height: "46px" }}>
                      <AdminCheckbox
                        margin={{
                          left: "13px",
                        }}
                        checked={selectedUser[coinId]}
                        onClick={(e) => handleSelect(e, coinId)}
                        value={coinId}
                      />
                    </TableData>
                    <TableData>{total - ((page - 1) * 10 + idx)}</TableData>
                    <TableData>
                      <Box
                        display={{
                          display: "inline-block",
                        }}
                        size={{
                          width: "206px",
                          height: "100%",
                        }}
                        padding={{
                          left: "14px",
                          right: "14px",
                        }}
                        typo={{
                          lineClamp: 1,
                          wordBreak: "break-all",
                          textDecoration: "underline",
                        }}
                        onClick={() => {
                          goToDetail(cheeringList[idx].memberId);
                        }}
                        other={{
                          cursor: "pointer",
                        }}
                      >
                        {memberEmail}
                      </Box>
                    </TableData>
                    <TableData
                      padding={{
                        all: "7px",
                      }}
                    >
                      <Text
                        size={{
                          width: "156px",
                        }}
                        typo={{
                          lineClamp: 1,
                          wordBreak: "break-all",
                        }}
                      >
                        {memberName}
                      </Text>
                    </TableData>
                    <TableData>
                      <Text
                        size={{
                          width: "248px",
                        }}
                        typo={{
                          lineClamp: 1,
                          wordBreak: "break-all",
                        }}
                        padding={{
                          left: "14px",
                          right: "14px",
                        }}
                      >
                        {numberWithCommas(coinValue)} Coin
                      </Text>
                    </TableData>
                    <TableData>
                      {dayjs(createdAt).format("YYYY-MM-DD HH:mm")}
                    </TableData>
                    <TableData isLastChild>
                      <AdminButton
                        category="button"
                        theme="whiteColor"
                        size={{
                          width: "72px",
                          height: "30px",
                        }}
                        typo={{
                          weight: "400",
                        }}
                        margin={{
                          left: "23px",
                        }}
                        onClick={() => handleCheeringCancel(coinId)}
                      >
                        취소
                      </AdminButton>
                    </TableData>
                  </tr>
                )
              )}
            </tbody>
          </Table>
        </Box>
      </Box>
    </>
  );
};

export default StoryCheeringEdit;
