import Box from "component/atoms/box";
import Button from "component/atoms/button";
import Image from "component/atoms/image";

const ICON = {
  comment: {
    img: "/asset/img/story/comment.svg",
    active: "/asset/img/story/comment_active.svg",
  },
  like: {
    img: "/asset/img/story/heart.svg",
    active: "/asset/img/story/heart_active.svg",
  },
  view: { img: "/asset/img/story/view.svg" },
};

const IconList = (props) => {
  const { icons, size = 15 } = props;

  return (
    <Box
      display={{
        display: "flex",
        gap: "8px",
      }}
    >
      {icons.map(({ icon, value, isActive, onClick }, idx) => {
        const Icon = ICON[icon];

        return (
          <Button
            background={{
              color: "transparent",
            }}
            display={{
              display: "flex",
              align: "center",
            }}
            typo={{
              size: "12px",
              weight: "400",
              color: isActive ? "pink02" : "black",
            }}
            key={icon}
            onClick={() => onClick && onClick()}
          >
            <Image
              src={isActive ? Icon.active : Icon.img}
              size={{
                width: `${size}px`,
                height: `${size}px`,
              }}
              margin={{
                right: "4px",
              }}
            />

            {value ?? 0}
          </Button>
        );
      })}
    </Box>
  );
};

export default IconList;
