import { create, update } from "api/portfolio";
import Text from "component/atoms/text";
import { ErrorContent } from "component/organisms/common/globalModal";
import dayjs from "dayjs";
import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";
import checkRequired from "utils/checkRequired";

const required = {
  name: true,
  link: true,
  portfolio_music: true,
};

const SuccessContent = () => {
  return (
    <Text
      typo={{
        size: "18px",
        weight: "700",
        align: "center",
      }}
    >
      포트폴리오 수정이 완료되었습니다.
    </Text>
  );
};

const useUpdatePortfolioForm = (handleToggle, formData) => {
  const form = useForm({
    defaultValues: {
      name: "",
      link: "",
      cover: "",
      portfolio_music: dayjs().format("YYYY-MM-DD"),
    },
  });
  const { setModal } = useModalStore();
  const queryClient = useQueryClient();
  const portfolioMutation = useMutation(update, {
    onSuccess: () => {
      handleToggle();
      form.reset();
      queryClient.invalidateQueries("portfolio");
      setModal({
        title: "포트폴리오 수정하기",
        content: <SuccessContent />,
      });
    },
    onError: () => {
      setModal({
        title: "포트폴리오 수정하기",
        content: <ErrorContent />,
      });
    },
  });
  const { watch, handleSubmit } = form;

  const isActive = useMemo(() => checkRequired(required, watch()), [watch()]);

  const onSubmit = (data) => {
    const formData = new FormData();

    formData.append("musicName", data.name);
    formData.append("musicLink", data.link);
    if (typeof data.cover !== "string") {
      formData.append("musicCoverFile", data.cover);
    }
    formData.append("releaseDate", data.portfolio_music);

    portfolioMutation.mutate({ id: data.id, data: formData });
  };

  useEffect(() => {
    if (formData) {
      form.setValue("name", formData.musicName);
      form.setValue("link", formData.musicLink);
      form.setValue("cover", formData.musicCover);
      form.setValue("portfolio_music", formData.releaseDate);
      form.setValue("id", formData.portfolioId);
    }
  }, [formData]);

  return { form, isActive, onSubmit: handleSubmit(onSubmit) };
};

export default useUpdatePortfolioForm;
