const { getComment } = require("api/story");
const { useQuery } = require("react-query");

const useSelectComment = ({ storyId, boardId }) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["selectComment", storyId, boardId],
    () => getComment({ storyId, boardId })
  );

  return {
    data: data?.data,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useSelectComment;
