import Box from "component/atoms/box";
import Text from "component/atoms/text";
import CalendarInput from "component/molecules/common/calendarInput";
import LabelText from "component/molecules/common/labelText";
import dayjs from "dayjs";
import { useEffect } from "react";
import { useFormContext } from "react-hook-form";

const convertCalendarName = (name) => {
  return [`${name}_start`, `${name}_end`];
};

const RangeCalendarField = (props) => {
  const {
    title,
    typo,
    required,
    info,
    size,
    //
    name,
  } = props;

  const [start, end] = convertCalendarName(name);
  const { setValue, watch } = useFormContext();

  const handleChange = (name) => (date) => {
    setValue(name, date);
  };

  const { [start]: startDate, [end]: endDate } = watch();

  useEffect(() => {
    if (startDate && endDate) {
      const startDay = dayjs(startDate);
      const endDay = dayjs(endDate);

      if (startDay.isAfter(endDay)) {
        setValue(end, startDay.format("YYYY-MM-DD"));
        setValue(start, endDay.format("YYYY-MM-DD"));
      }
    }
  }, [startDate, endDate]);

  return (
    <>
      {(title || info || required) && (
        <LabelText title={title} required={required} info={info} typo={typo} />
      )}
      <Box
        display={{
          display: "flex",
          justify: "flex-start",
          align: "center",
        }}
        size={size}
      >
        <CalendarInput
          size={{
            width: "calc((100% - 28px) / 2)",
            height: "30px",
          }}
          value={startDate}
          onChange={handleChange(start)}
        />
        <Text
          size={{
            width: "28px",
          }}
          // margin={{
          //   all: "0 10px",
          // }}
          typo={{
            size: "13px",
            align: "center",
          }}
        >
          ~
        </Text>
        <CalendarInput
          size={{
            width: "calc((100% - 28px) / 2)",
            height: "30px",
          }}
          value={endDate}
          onChange={handleChange(end)}
        />
      </Box>
    </>
  );
};

export default RangeCalendarField;
