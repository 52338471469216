import { API_VERSION, SERVER_URL } from "api/config";
import {
  StoryList,
  exhibitionStoryList,
  matchingArtist,
} from "mock/datas/story";
import { rest } from "msw";

const writeRequired = {
  category: true,
  mood: true,
  title: true,
  summary: true,
  thumbnail: true,
  personality: true,
  like: true,
  dislike: true,
  name: true,
};

const commentRequired = {
  id: true,
  content: true,
  type: true,
};

const matchingRequired = {
  title: true,
  content: true,
  id: true,
};

const selectRequired = {
  artistId: true,
  id: true,
};

const replyRequired = {
  id: true,
  comment: true,
  content: true,
};

const commentLikeRequired = {
  id: true,
  comment: true,
  like: true,
};

const likeRequired = {
  id: true,
  like: true,
};

const storyInfoRequired = {
  id: true,
  musicTitle: true,
  musicContent: true,
};

const storyFinishRequired = {
  id: true,
  soundTrackTitle: true,
  soundTrackFile: true,
};

const storyGoalRequired = {
  price: true,
  funding_start: true,
  funding_end: true,
  id: true,
};

const storySupportRequired = {
  coin: true,
};

const storyAdditionalRequired = {
  title: true,
  content: true,
  id: true,
};

const storyWriteHandler = rest.post(
  `${SERVER_URL}/api/${API_VERSION}/story/write`,
  (req, res, ctx) => {
    const body = req.body;

    Object.entries(writeRequired).forEach(([key, value]) => {
      if (value && !body[key]) {
        return res(
          ctx.status(400),
          ctx.json({
            message: `이야기  작성에 실패했습니다.\n잠시 후 다시 시도해주세요.`,
          })
        );
      }
    });

    return res(
      ctx.status(200),
      ctx.json({
        message: "success",
        data: {
          id: 1,
        },
      })
    );
  }
);

const storyCommentHandler = rest.post(
  `${SERVER_URL}/api/${API_VERSION}/story/comment`,
  (req, res, ctx) => {
    const body = req.body;

    Object.entries(commentRequired).forEach(([key, value]) => {
      if (value && !body[key]) {
        return res(
          ctx.status(400),
          ctx.json({
            message: `${key} is required`,
          })
        );
      }
    });

    return res(
      ctx.status(200),
      ctx.json({
        message: "success",
        data: {
          id: body.id,
        },
      })
    );
  }
);

const storyMatchingHandler = rest.post(
  `${SERVER_URL}/api/${API_VERSION}/story/matching`,
  (req, res, ctx) => {
    const body = req.body;

    Object.entries(matchingRequired).forEach(([key, value]) => {
      if (value && !body[key]) {
        return res(
          ctx.status(400),
          ctx.json({
            message: `${key} is required`,
          })
        );
      }
    });

    return res(
      ctx.status(200),
      ctx.json({
        message: "매칭이 신청되었습니다.",
      })
    );
  }
);

const storyMatchingArtistList = rest.get(
  `${SERVER_URL}/api/${API_VERSION}/story/:id/matching/artist`,
  (req, res, ctx) => {
    const id = req.params.id;

    return res(
      ctx.status(200),
      ctx.json({
        message: "success",
        data: {
          list: id === "4" ? [] : matchingArtist,
        },
      })
    );
  }
);

const storySelectHandler = rest.post(
  `${SERVER_URL}/api/${API_VERSION}/story/select`,
  (req, res, ctx) => {
    const body = req.body;

    Object.entries(selectRequired).forEach(([key, value]) => {
      if (value && !body[key]) {
        return res(
          ctx.status(400),
          ctx.json({
            message: `${key} is required`,
          })
        );
      }
    });

    return res(
      ctx.status(200),
      ctx.json({
        message: "매칭이 확정되었습니다.",
      })
    );
  }
);

const storyExhibitionHandler = rest.get(
  `${SERVER_URL}/api/${API_VERSION}/story/exhibition`,
  (req, res, ctx) => {
    return res(
      ctx.status(200),
      ctx.json({
        message: "success",
        data: {
          list: exhibitionStoryList,
        },
      })
    );
  }
);

const storyListHandler = rest.get(
  `${SERVER_URL}/api/${API_VERSION}/story`,
  (req, res, ctx) => {
    const query = req.url.searchParams;

    console.log("############ storyListHandler ############");
    console.log("limit : " + query.get("limit"));
    console.log("page : " + query.get("page"));
    console.log("category : " + query.get("category"));
    console.log("mood : " + query.get("mood"));
    console.log("sort : " + query.get("sort"));
    console.log("############ storyListHandler ############");

    const maxPage =
      StoryList.length % query.get("limit") === 0
        ? StoryList.length / query.get("limit")
        : Math.floor(StoryList.length / query.get("limit")) + 1;

    return res(
      ctx.status(200),
      ctx.json({
        message: "success",
        data: {
          maxPage,
          list: StoryList.slice(
            (query.get("page") - 1) * query.get("limit"),
            query.get("page") * query.get("limit")
          ),
        },
      })
    );
  }
);

const storyDetailHandler = rest.get(
  `${SERVER_URL}/api/${API_VERSION}/story/:id`,
  (req, res, ctx) => {
    const id = req.params.id;

    return res(
      ctx.status(200),
      ctx.json({
        message: "success",
        data: StoryList.find((story) => story.id.toString() === id),
      })
    );
  }
);

const storyWriteDetailHandler = rest.get(
  `${SERVER_URL}/api/${API_VERSION}/story/write/:id`,
  (req, res, ctx) => {
    const id = req.params.id;

    return res(
      ctx.status(200),
      ctx.json({
        message: "success",
        data: StoryList.find((story) => story.id.toString() === id),
      })
    );
  }
);

const storyReplayHandler = rest.post(
  `${SERVER_URL}/api/${API_VERSION}/story/replay`,
  (req, res, ctx) => {
    const body = req.body;

    Object.entries(replyRequired).forEach(([key, value]) => {
      if (value && !body[key]) {
        return res(
          ctx.status(400),
          ctx.json({
            message: `${key} is required`,
          })
        );
      }
    });

    return res(
      ctx.status(200),
      ctx.json({
        message: "success",
        data: {
          id: body.id,
        },
      })
    );
  }
);

const storyCommentLikeHandler = rest.post(
  `${SERVER_URL}/api/${API_VERSION}/story/comment/like`,
  (req, res, ctx) => {
    const body = req.body;

    Object.entries(commentLikeRequired).forEach(([key, value]) => {
      if (value && !body[key]) {
        return res(
          ctx.status(400),
          ctx.json({
            message: `${key} is required`,
          })
        );
      }
    });

    return res(
      ctx.status(200),
      ctx.json({
        message: "success",
      })
    );
  }
);

const storyLikeHandler = rest.post(
  `${SERVER_URL}/api/${API_VERSION}/story/like`,
  (req, res, ctx) => {
    const body = req.body;

    Object.entries(likeRequired).forEach(([key, value]) => {
      if (value && !body[key]) {
        return res(
          ctx.status(400),
          ctx.json({
            message: `${key} is required`,
          })
        );
      }
    });

    return res(
      ctx.status(200),
      ctx.json({
        message: "success",
      })
    );
  }
);

const storyInfoHandler = rest.post(
  `${SERVER_URL}/api/${API_VERSION}/story/info`,
  (req, res, ctx) => {
    const body = req.body;

    Object.entries(storyInfoRequired).forEach(([key, value]) => {
      if (value && !body[key]) {
        return res(
          ctx.status(400),
          ctx.json({
            message: `${key} is required`,
          })
        );
      }
    });

    return res(
      ctx.status(200),
      ctx.json({
        message: "success",
      })
    );
  }
);

const storyCommentListHandler = rest.get(
  `${SERVER_URL}/api/${API_VERSION}/story/:id/comment`,
  (req, res, ctx) => {
    const id = req.params.id;

    return res(
      ctx.status(200),
      ctx.json({
        message: "success",
        data: {
          maxPage: 2,
          list: StoryList.find((story) => story.id.toString() === id)
            .commentList,
        },
      })
    );
  }
);

const storyFinishHandler = rest.post(
  `${SERVER_URL}/api/${API_VERSION}/story/finish`,
  (req, res, ctx) => {
    const body = req.body;

    Object.entries(storyFinishRequired).forEach(([key, value]) => {
      if (value && !body[key]) {
        return res(
          ctx.status(400),
          ctx.json({
            message: `${key} is required`,
          })
        );
      }
    });

    return res(
      ctx.status(200),
      ctx.json({
        message: "success",
      })
    );
  }
);

const storyGoalHandler = rest.post(
  `${SERVER_URL}/api/${API_VERSION}/story/goal`,
  (req, res, ctx) => {
    const body = req.body;

    Object.entries(storyGoalRequired).forEach(([key, value]) => {
      if (value && !body[key]) {
        return res(
          ctx.status(400),
          ctx.json({
            message: `${key} is required`,
          })
        );
      }
    });

    return res(
      ctx.status(200),
      ctx.json({
        message: "success",
      })
    );
  }
);

const storySupportHandler = rest.post(
  `${SERVER_URL}/api/${API_VERSION}/story/support`,
  (req, res, ctx) => {
    const body = req.body;

    Object.entries(storySupportRequired).forEach(([key, value]) => {
      if (value && !body[key]) {
        return res(
          ctx.status(400),
          ctx.json({
            message: `이야기  작성에 실패했습니다.\n잠시 후 다시 시도해주세요.`,
          })
        );
      }
    });

    return res(
      ctx.status(200),
      ctx.json({
        message: "success",
      })
    );
  }
);

const storyAdditionalHandler = rest.post(
  `${SERVER_URL}/api/${API_VERSION}/story/additional`,
  (req, res, ctx) => {
    const body = req.body;

    Object.entries(storyAdditionalRequired).forEach(([key, value]) => {
      if (value && !body[key]) {
        return res(
          ctx.status(400),
          ctx.json({
            message: `이야기  작성에 실패했습니다.\n잠시 후 다시 시도해주세요.`,
          })
        );
      }
    });

    return res(
      ctx.status(200),
      ctx.json({
        message: "success",
      })
    );
  }
);

const storyAdditionalRequestHandler = rest.post(
  `${SERVER_URL}/api/${API_VERSION}/story/additional/artist`,
  (req, res, ctx) => {
    const body = req.body;

    Object.entries(storyAdditionalRequired).forEach(([key, value]) => {
      if (value && !body[key]) {
        return res(
          ctx.status(400),
          ctx.json({
            message: `이야기  작성에 실패했습니다.\n잠시 후 다시 시도해주세요.`,
          })
        );
      }
    });

    return res(
      ctx.status(200),
      ctx.json({
        message: "success",
      })
    );
  }
);

const story = [
  storyWriteHandler,
  storyCommentHandler,
  storyMatchingHandler,
  storyMatchingArtistList,
  storySelectHandler,
  storyExhibitionHandler,
  storyListHandler,
  storyCommentListHandler,
  //
  storyDetailHandler,
  storyWriteDetailHandler,
  storyReplayHandler,
  storyCommentLikeHandler,
  storyLikeHandler,
  storyInfoHandler,
  storyFinishHandler,
  storyGoalHandler,
  storySupportHandler,
  storyAdditionalHandler,
  storyAdditionalRequestHandler,
];

export default story;
