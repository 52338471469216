import { GET } from "api/config";

export const newMember = async () => await GET("/admin/dashboard/new/member");

export const artist = async () => await GET("/admin/dashboard/artist");

export const completion = async () => await GET("/admin/dashboard/completion");

export const latest = async () => await GET("/admin/dashboard/latest");

export const today = async () => await GET("/admin/today");
