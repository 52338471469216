import Box from "component/atoms/box";
import Text from "component/atoms/text";
import CommonButton from "component/molecules/common/commonButton";
import Modal from "component/molecules/common/modal";
import InputField from "component/organisms/common/inputField";
import RangeCalendarField from "component/organisms/common/rangeCalendarField";
import useGoalForm from "hook/api/story/goal";
import { FormProvider } from "react-hook-form";

const GoalModal = (props) => {
  const { toggle, handleToggle, funding } = props;
  const { form, isActive, onSubmit } = useGoalForm(handleToggle, funding);

  return (
    <Modal
      title={"응원하기 목표 설정하기"}
      visible={toggle}
      onClose={handleToggle}
    >
      <FormProvider {...form}>
        <RangeCalendarField
          title={"응원하기 일정"}
          name={"funding"}
          typo={{
            size: "14px",
            weight: "700",
          }}
          size={{
            width: "324px",
          }}
        />

        <Box
          display={{
            display: "flex",
            align: "flex-end",
            justify: "flex-start",
            gap: "10px",
          }}
          margin={{
            all: "20px 0",
          }}
        >
          <InputField
            title={"목표 응원 금액"}
            name={"price"}
            type={"number"}
            typo={{
              size: "14px",
              weight: "700",
            }}
            size={{
              width: "294px",
              height: "46px",
            }}
            longMobile={{
              size: {
                width: "236px",
                height: "40px",
              },
            }}
          />
          <Text
            typo={{
              size: "24px",
              weight: "700",
            }}
            margin={{
              bottom: "4px",
            }}
            longMobile={{
              typo: {
                size: "16px",
              },
            }}
          >
            Coin
          </Text>
        </Box>

        <CommonButton
          category={"button"}
          size={{
            width: "100%",
            height: "63px",
          }}
          typo={{
            size: "18px",
          }}
          longMobile={{
            size: {
              width: "100%",
              height: "50px",
            },
            typo: {
              size: "14px",
            },
          }}
          isActive={isActive}
          isDisabled={!isActive}
          onClick={onSubmit}
        >
          목표 저장하기
        </CommonButton>
      </FormProvider>
    </Modal>
  );
};

export default GoalModal;
