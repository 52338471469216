import { login } from "api/auth";
import { RejectContent } from "component/organisms/common/globalModal";
import useUrl from "hook/util/useUrl";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import useModalStore from "store/useModalStore";
import useSignedStore from "store/useSignedStore";
import checkRequired from "utils/checkRequired";
import cookie from "react-cookies";

const required = {
  email: true,
  password: true,
};

const useLoginForm = () => {
  const { prevUrl, changeLink, cameFromSameDomain } = useUrl();
  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
      error: "",
    },
  });

  const { setModal } = useModalStore();
  const { setSigned } = useSignedStore();

  const { watch, handleSubmit, register, setValue } = form;
  const checkLoginRequired = () => {
    const data = watch();
    const check = checkRequired(required, data);

    return check;
  };

  const isActive = useMemo(() => checkLoginRequired(), [watch()]);

  const loginMutation = useMutation(login, {
    onSuccess: ({ data, jwtProvider }) => {
      const userState = data.userState;

      if (userState === 0) {
        cookie.save("moomoostJwt", jwtProvider, {
          path: "/",
        });
        const prev = cameFromSameDomain(["/signup", "/find"]) ? prevUrl : "/";

        setSigned({ ...data, isLogin: true });
        window.location.href = prev;

        if (data.artistResult) {
          const isApprove = data.artistResult.result === "approve";
          // const title = "아티스트 신청";
          const content = data.artistResult.content;

          setModal({
            title: "알림",
            content: <RejectContent message={content} isApprove={isApprove} />,
            noneInfo: true,
          });
        }

        if (data.creationResult) {
          const isApprove = data.creationResult.result === "approve";
          // const title = "창작 완료 신청";
          const content = data.creationResult.content;

          setModal({
            title: "알림",
            content: <RejectContent message={content} isApprove={isApprove} />,
            noneInfo: true,
          });
        }
      } else if (userState === 1) {
        setModal({
          title: "로그인",
          content: "차단된 사용자 계정입니다.",
        });
      } else {
        setModal({
          title: "로그인",
          content: "탈퇴된 사용자 계정입니다.",
        });
      }
    },
    onError: ({ response: { data } }) => {
      if (data.type === "LOGIN_Inconsistency") {
        setValue("error", data.message);
      } else {
        setModal({
          title: "로그인",
          content: data.message,
        });
      }
    },
  });

  const submit = ({ email, password }) => {
    isActive && loginMutation.mutate({ email, password });
  };

  return {
    email: register("email"),
    password: register("password"),
    error: watch("error"),
    isActive,

    handleSubmit: handleSubmit(submit),
  };
};

export default useLoginForm;
