import { cheeringDetail } from "api/mypage";
import { useQuery } from "react-query";

const useCheeringDetail = ({ id }) => {
  const { data, isError, isLoading, isSuccess } = useQuery(
    ["cheeringDetail", id],
    () => cheeringDetail(id)
  );

  return { data: data?.data, isError, isLoading, isSuccess };
};

export default useCheeringDetail;
