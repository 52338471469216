import React, { forwardRef, useEffect, useRef } from "react";
// import SunEditor from "./lib";
import EditorContainer from "./editor.style";
import axios from "axios";
import useEditStore from "store/useEditStore";
import { SERVER_URL } from "api/config";
import convertImage from "utils/convertImage";

const AdminEditor = forwardRef((props, ref) => {
  const { content, setContent } = props;

  const { updateEdit, clearEdit } = useEditStore();

  const url = window.location.href.split("/")[4];

  const parseUrl = () => {
    if (window.location.includes("terms")) {
      return "termsuse";
    } else if (window.location.includes("personalInfo")) {
      return "privacy";
    } else if (window.location.includes("acceptance")) {
      return "termsjoin";
    } else if (window.location.includes("marketing")) {
      return "marketing";
    } else if (window.location.includes("storyTerm")) {
      return "termsstory";
    }
  };

  const onImageUploadBefore = (files, info, core, uploadHandler) => {
    try {
      const file = files && files[0];
      const formData = new FormData();
      formData.append("file", file);

      axios
        .post(`${SERVER_URL}/api/v1/story/upload`, formData)
        .then((data) => {
          uploadHandler({
            result: [
              {
                url: convertImage(data.data),
                name: file.name,
                size: file.size,
              },
            ],
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      alert("에러가 발생했습니다.");
      // uploadHandler(err.toString());
    }

    return undefined;
  };

  useEffect(() => {
    if (ref.current) return;
    const editor = window.SUNEDITOR.create(
      document.querySelector(".sample") || "sample",
      {
        width: "843px",
        height: "244px",
        lang: window.SUNEDITOR_LANG["ko"],

        buttonList: [
          [
            "font",
            "fontSize",
            "formatBlock",
            // "paragraphStyle",
            "bold",
            "underline",
            "italic",
            "strike",
            "subscript",
            "superscript",
            "fontColor",
            "hiliteColor",
            // "textStyle",
            "outdent",
            "indent",
            "align",
            "horizontalRule",
            "list",
            // "lineHeight",
            "table",
            "link",
            "image",
            "undo",
            "redo",
            "codeView",
          ],
          // ["paragraphStyle", "blockquote"],
          // ["bold", "underline", "italic", "strike", "subscript", "superscript"],
          // ["fontColor", "hiliteColor", "textStyle"],
          // ["removeFormat"],

          // ["outdent", "indent"],
          // ["align", "horizontalRule", "list", "lineHeight"],
          // [
          //   "table",
          //   "link",
          //   "image",
          //   // "video",
          //   // "audio"
          // ],
          // ["undo", "redo"],
          // ["fullScreen", "showBlocks", "codeView"],
          // ["preview", "print"],
          // ["save", "template"],
        ],
        font: [
          "굴림",
          "바탕",
          // "바탕체",
          "맑은고딕",
          "돋움",
          // "돋움체",
          "Arial",
          "Comic Sans MS",
          "Courier New",
          // "Impact",
          // "Georgia",
          // "tahoma",
          // "Trebuchet MS",
          // "Verdana",
        ],
        historyStackDelayTime: 0,
      }
    );

    editor.onImageUploadBefore = onImageUploadBefore;

    editor.onChange = (contents, core) => {
      setContent(contents);
    };

    ref.current = editor;
  }, [content]);

  return (
    <EditorContainer>
      <textarea className="sample"></textarea>
    </EditorContainer>
  );
});

export default React.memo(AdminEditor);
