import { commentList } from "api/story";
import { useInfiniteQuery, useQuery } from "react-query";

export const useCommentInfiniteList = ({ id }) => {
  const { data, fetchNextPage, isLoading, isSuccess, isError } =
    useInfiniteQuery(
      ["commentInfinite", id],
      ({ pageParam = 1 }) => commentList(id, pageParam),
      {
        getNextPageParam: ({ totalPages }, pageParam) => {
          return totalPages === pageParam.length
            ? undefined
            : pageParam.length + 1;
        },
        cacheTime: 0,
        staleTime: 0,
      }
    );

  let list = [];

  if (isSuccess) {
    list = data.pages.map((page) => page.data).flat();
  }

  return { list, isSuccess, isLoading, isError, fetchNextPage };
};

export const useCommentList = ({ id, page }) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["commentList", id, page],
    () => commentList(id, page),
    {
      cacheTime: 0,
      staleTime: 0,
    }
  );

  const list = data?.data;
  const maxPage = data?.data?.maxPage;

  return { list, maxPage, isSuccess, isLoading, isError };
};
