import { create } from "zustand";

const useSignedStore = create((set) => ({
  user: null,

  setSigned: (user) => {
    set({ user: user.isLogin ? user : null });
  },
}));

export default useSignedStore;
