import { useEffect, useState } from "react";

const useThumbnailSlider = (array = []) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(-1);

  const generateWindow = (index) => {
    const length = array.length;

    return [
      array.at((index + 3) % length),
      array.at((index + 2) % length),
      array.at((index + 1) % length),
      array.at(index),
    ];
  };

  const current = generateWindow(activeIndex);
  const prev = generateWindow(prevIndex);

  useEffect(() => {
    let interval;
    if (array.length > 4) {
      interval = setInterval(() => {
        setPrevIndex((prev) => (prev + 1) % array.length);
        setActiveIndex((prev) => (prev + 1) % array.length);
      }, 2000);
    }
    return () => clearInterval(interval);
  }, [array]);

  if (array.length === 0) return [[], []];
  return [current, prev];
};

export default useThumbnailSlider;
