import Text from "component/atoms/text";
import Box from "component/atoms/box";
import Line from "component/atoms/line";
import CommonButton from "component/molecules/common/commonButton";
import IconList from "component/molecules/common/iconList";
import Modal from "component/molecules/common/modal";
import useToggle from "hook/util/useToggle";
import Additional from "../modal/additional";
import Request from "../modal/request";
import Image from "component/atoms/image";
import useUrl from "hook/util/useUrl";
import { useLikeStoryForm } from "hook/api/story/like";
import ShareButton from "../shareButton";
import useSignedStore from "store/useSignedStore";
import useModalStore from "store/useModalStore";
import useDeleteStory from "hook/api/story/delete";
import Link from "component/atoms/link";

const StoryInfo = (props) => {
  const {
    storyCategory = [],
    storyMood = [],
    storyPersonality = [],
    member,
    artist,
    assist,
    storyTitle,
    storySummary,
    personaLike,
    personaDislike,
    personaName,
    storyViewCount,
    storyLikes,
    isCreator,
    isArtist,
    isArtistRecord,
    isMatchedArtist,
    isAssistRecord,
    isMatchedAssist,
    isMemberEmployment,
    isLiked,
    isEnd,
  } = props;
  const {
    params: { id },
    changeQuery,
    changeLink,
  } = useUrl();
  const { user } = useSignedStore();
  const { setModal } = useModalStore();
  const { toggle, handleToggle } = useToggle();
  const { toggle: deleteToggle, handleToggle: handleDeleteToggle } =
    useToggle();

  const handleMatchingToggle = () => {
    if (window.confirm("매칭 신청을 취소하시겠습니까?")) handleToggle();
  };

  const deleteStory = useDeleteStory();

  const isAddArtist = isCreator && !artist; // 아티스트 매칭하기
  const isAddedArtist = isCreator && artist; // 아티스트 매칭완료

  const isRequestArtist =
    !isCreator && isArtist && !isMatchedArtist && !artist && !isArtistRecord; // 아티스트 매칭 신청하기
  const isRequestedArtist =
    !isCreator &&
    isArtist &&
    !isMatchedArtist &&
    isArtistRecord &&
    !isMemberEmployment; // 아티스트 매칭 신청완료

  const isAddAssist = isArtist && isMatchedArtist && !isMemberEmployment; // 추가 멤버 모집하기
  const isMatchAssist =
    isArtist && isMatchedArtist && isMemberEmployment && !assist; // 추가 멤버 매칭
  const isAddedAssist =
    isArtist && isMatchedArtist && isMemberEmployment && assist; // 추가 멤버 모집완료

  const isAdditionalArtist =
    !isCreator &&
    isArtist &&
    artist &&
    !isMatchedArtist &&
    isMemberEmployment &&
    !isAssistRecord; // 아티스트이면서, 매칭된 아티스트가 아니면서, 추가 멤버 신청을 받으면서, 추가 멤버 신청하지 않은 경우
  const isAddedAdditionalArtist =
    isArtist &&
    artist &&
    !isMatchedArtist &&
    isMemberEmployment &&
    isAssistRecord; // 아티스트이면서, 매칭된 아티스트가 아니면서, 추가 멤버 신청을 받으면서, 추가 멤버 신청한 경우

  // ta2@co.kr / rlaxodud1!
  // tta2@co.kr / rlaxodud 입니다!

  const isActive =
    isAddArtist ||
    isRequestArtist ||
    isAdditionalArtist ||
    isAddAssist ||
    isMatchAssist;

  const isDisabled =
    isAddedArtist ||
    isRequestedArtist ||
    isAddedAdditionalArtist ||
    isAddedAssist;
  const isRequestButton = isActive || isDisabled;

  const likeStory = useLikeStoryForm();

  const handleMatching = () => {
    if (isAdditionalArtist) {
      changeQuery({
        type: "additional",
      });
    } else if (isMatchAssist) {
      changeQuery({
        type: "matching",
        assist: "assist",
      });
    } else if (isAddAssist || isRequestArtist) {
      handleToggle();
    } else if (isAddArtist) {
      changeQuery({
        type: "matching",
      });
    }
  };

  const handleLikeStory = () => {
    if (user) {
      likeStory();
    } else {
      changeLink("/login");
      setModal({
        title: "좋아요",
        content: "로그인이 필요합니다.",
      });
    }
  };

  return (
    <Box
      display={{
        display: "flex",
        direction: "column",
        justify: "space-between",
      }}
      size={{
        width: "100%",
        height: "100%",
      }}
      padding={{
        all: "24px",
      }}
      position={{
        type: "relative",
      }}
      longMobile={{
        padding: {
          all: "10px 7px",
        },
      }}
    >
      <Box
        display={{
          display: "flex",
          direction: "column",
          justify: "space-between",
        }}
      >
        <Box
          display={{
            display: "flex",
            align: "center",
          }}
          longMobile={{
            padding: { all: "0 5px" },
          }}
        >
          <Link
            typo={{
              size: "14px",
              weight: "500",
            }}
            longMobile={{
              typo: {
                size: "12px",
              },
            }}
            link={`/mypage/${member.memberId}`}
          >
            @{member.memberName}
          </Link>

          {artist && (
            <>
              <Line
                direction={"col"}
                size={"13px"}
                background={{ color: "black01" }}
                margin={{ all: "0 5px" }}
              />

              <Link
                typo={{
                  size: "14px",
                  weight: "500",
                }}
                longMobile={{
                  typo: {
                    size: "12px",
                  },
                }}
                link={`/mypage/${artist.memberId}`}
              >
                @{artist.artistName}
              </Link>
            </>
          )}

          {assist && (
            <>
              <Line
                direction={"col"}
                size={"13px"}
                background={{ color: "black01" }}
                margin={{ all: "0 5px" }}
              />

              <Link
                typo={{
                  size: "14px",
                  weight: "500",
                }}
                longMobile={{
                  typo: {
                    size: "12px",
                  },
                }}
                link={`/mypage/${assist.memberId}`}
              >
                @{assist.assistName}
              </Link>
            </>
          )}
        </Box>

        <Line
          direction="row"
          size="100%"
          background={{ color: "gray02" }}
          margin={{ all: "12px 0 24px" }}
          longMobile={{
            margin: { all: "10px 0" },
          }}
        />

        <Box
          longMobile={{
            padding: { all: "0 5px" },
          }}
        >
          <Text
            as={"h1"}
            typo={{
              size: "28px",
              weight: "700",
              lineHeight: "normal",
              lineClamp: 2,
              wordBreak: "break-all",
            }}
            longMobile={{
              typo: {
                size: "20px",
              },
            }}
          >
            {storyTitle}
          </Text>
          <Text
            typo={{
              size: "14px",
              weight: "400",
            }}
            longMobile={{
              typo: {
                size: "14px",
              },
            }}
          >
            {storySummary}
          </Text>
        </Box>

        <Line
          direction="row"
          size="100%"
          background={{ color: "gray02" }}
          margin={{ all: "24px 0 22px" }}
          longMobile={{
            margin: { all: "10px 0" },
          }}
        />

        <Box
          display={{
            display: "flex",
          }}
          tablet={{
            padding: { all: "0 5px" },
            display: {
              wrap: "wrap",
            },
          }}
        >
          <Box
            size={{
              width: "55px",
            }}
            margin={{
              right: "40px",
            }}
            display={{
              display: "flex",
              direction: "column",
              gap: "4px",
            }}
          >
            <Box
              display={{
                display: "flex",
                gap: "4px",
              }}
            >
              <Text
                typo={{
                  size: "14px",
                  weight: "500",
                }}
              >
                카테고리
              </Text>
            </Box>

            <Box
              display={{
                display: "flex",
                align: "center",
              }}
              size={{
                width: "fit-content",
                height: "24px",
              }}
              border={{
                all: "1px",
                style: "solid",
                color: "black01",
              }}
              padding={{
                all: "0 4px",
              }}
            >
              <Text
                typo={{
                  size: "12px",
                  weight: "400",
                }}
              >
                {storyCategory[0]}
              </Text>
            </Box>
          </Box>

          <Box
            display={{
              display: "flex",
              direction: "column",
              gap: "4px",
            }}
            size={{
              width: "110px",
            }}
            margin={{
              right: "40px",
            }}
          >
            <Box
              display={{
                display: "flex",
                gap: "4px",
              }}
            >
              <Text
                typo={{
                  size: "14px",
                  weight: "500",
                }}
              >
                분위기
              </Text>
            </Box>

            <Box
              display={{
                display: "flex",
                wrap: "wrap",
                gap: "4px",
              }}
            >
              {storyMood.map((item) => (
                <Box
                  display={{
                    display: "flex",
                    align: "center",
                  }}
                  size={{
                    width: "fit-content",
                    height: "24px",
                  }}
                  border={{
                    all: "1px",
                    style: "solid",
                    color: "black01",
                  }}
                  padding={{
                    all: "0 4px",
                  }}
                  key={item}
                >
                  <Text
                    typo={{
                      size: "12px",
                      weight: "400",
                    }}
                  >
                    {item}
                  </Text>
                </Box>
              ))}
            </Box>
          </Box>

          <Box
            display={{
              display: "flex",
              direction: "column",
              gap: "4px",
            }}
            size={{
              width: "calc(100% - 40px - 40px - 55px - 110px)",
            }}
            tablet={{
              size: {
                width: "100%",
              },
              margin: {
                top: "10px",
              },
            }}
          >
            <Box
              display={{
                display: "flex",
                gap: "4px",
              }}
            >
              <Text
                typo={{
                  size: "14px",
                  weight: "500",
                }}
              >
                페르소나
              </Text>
            </Box>

            <Box
              display={{
                display: "flex",
                gap: "4px",
                wrap: "wrap",
              }}
            >
              {storyPersonality.map((item) => (
                <Box
                  display={{
                    display: "flex",
                    align: "center",
                  }}
                  size={{
                    width: "fit-content",
                    height: "24px",
                  }}
                  border={{
                    all: "1px",
                    style: "solid",
                    color: "black01",
                  }}
                  padding={{
                    all: "0 4px",
                  }}
                  key={item}
                >
                  <Text
                    typo={{
                      size: "12px",
                      weight: "400",
                    }}
                  >
                    {item}
                  </Text>
                </Box>
              ))}
            </Box>
          </Box>
        </Box>

        <Line
          direction="row"
          size="100%"
          background={{ color: "gray02" }}
          margin={{ all: "22px 0 12px" }}
          longMobile={{
            margin: { all: "10px 0" },
          }}
        />

        <Box
          display={{
            display: "flex",
          }}
          longMobile={{
            padding: { all: "0 5px" },
          }}
        >
          <Text
            size={{
              width: "75px",
            }}
            typo={{
              size: "14px",
              weight: "700",
            }}
          >
            좋아하는 것 :
          </Text>
          <Text
            size={{
              width: "calc(100% - 75px)",
            }}
            padding={{
              left: "10px",
            }}
            typo={{
              size: "14px",
              wordBreak: "break-all",
            }}
          >
            {personaLike}
          </Text>
        </Box>

        <Line
          direction="row"
          size="100%"
          background={{ color: "gray02" }}
          margin={{ all: "12px 0" }}
          longMobile={{
            margin: { all: "10px 0" },
          }}
        />

        <Box
          display={{
            display: "flex",
          }}
          longMobile={{
            padding: { all: "0 5px" },
          }}
        >
          <Text
            size={{
              width: "75px",
            }}
            typo={{
              size: "14px",
              weight: "700",
            }}
          >
            싫어하는 것 :
          </Text>
          <Text
            size={{
              width: "calc(100% - 75px)",
            }}
            padding={{
              left: "10px",
            }}
            typo={{
              size: "14px",
              wordBreak: "break-all",
            }}
          >
            {personaDislike}
          </Text>
        </Box>

        <Line
          direction="row"
          size="100%"
          background={{ color: "gray02" }}
          margin={{ all: "12px 0" }}
          longMobile={{
            margin: { all: "10px 0" },
          }}
        />

        <Box
          display={{
            display: "flex",
          }}
          longMobile={{
            padding: { all: "0 5px" },
          }}
        >
          <Text
            size={{
              width: "88px",
            }}
            typo={{
              size: "14px",
              weight: "700",
            }}
          >
            페르소나 이름 :
          </Text>
          <Text
            size={{
              width: "calc(100% - 88px)",
            }}
            padding={{
              left: "10px",
            }}
            typo={{
              size: "14px",
              wordBreak: "break-all",
            }}
          >
            {personaName}
          </Text>
        </Box>
        <Line
          direction="row"
          size="100%"
          background={{ color: "gray02" }}
          margin={{ all: "12px 0" }}
          longMobile={{
            margin: { all: "10px 0" },
          }}
        />
      </Box>

      <Box
        display={{
          display: "flex",
          justify: "space-between",
        }}
        size={{
          width: "100%",
        }}
      >
        <Box
          display={{
            display: "flex",
            align: "flex-end",
          }}
        >
          <IconList
            size={18}
            icons={[
              { icon: "view", value: storyViewCount },
              { icon: "like", value: storyLikes },
            ]}
          />
        </Box>

        <Box
          position={{
            type: "relative",
          }}
          display={{
            display: "flex",
            gap: "5px",
          }}
          longMobile={{
            position: {
              type: "absolute",
              bottom: "-50px",
              right: "0",
            },
            size: { width: "100%" },
            display: {
              justify: "space-between",
            },
          }}
        >
          {isRequestButton && !assist ? (
            <CommonButton
              category={"button"}
              size={{ height: "40px" }}
              typo={{
                size: "14px",
              }}
              padding={{
                all: "0 11px",
              }}
              isActive={isActive}
              isDisabled={isEnd || isDisabled}
              onClick={() => !isEnd && handleMatching()}
            >
              {isAddArtist
                ? "아티스트 매칭하기"
                : isAddedArtist
                ? "아티스트 매칭완료"
                : isAddAssist
                ? "멤버 모집"
                : isRequestArtist
                ? "아티스트 매칭신청"
                : isRequestedArtist
                ? "아티스트 매칭신청완료"
                : isAdditionalArtist
                ? "멤버 신청"
                : isMatchAssist
                ? "멤버 모집"
                : isAddedAssist
                ? "멤버 모집 완료"
                : isAddedAdditionalArtist && "멤버 신청 완료"}
            </CommonButton>
          ) : (
            <div />
          )}
          <Box
            display={{
              display: "flex",
              gap: "5px",
            }}
          >
            {isCreator && (
              <>
                <CommonButton
                  category={"link"}
                  size={{ width: "46px", height: "40px" }}
                  link={`/story/write?storyId=${id}`}
                >
                  수정
                </CommonButton>
                <CommonButton
                  category={"button"}
                  size={{ width: "46px", height: "40px" }}
                  onClick={handleDeleteToggle}
                >
                  삭제
                </CommonButton>
              </>
            )}
            <CommonButton
              category={"button"}
              size={{ width: "40px", height: "40px" }}
              onClick={handleLikeStory}
            >
              <Image
                src={
                  isLiked
                    ? "/asset/img/story/heart_active.svg"
                    : "/asset/img/story/heart_unactive.svg"
                }
                size={{
                  width: "100%",
                }}
              />
            </CommonButton>
            <ShareButton title={storyTitle} />
          </Box>
        </Box>
      </Box>

      <Modal
        title={isAddAssist ? "멤버 모집" : "매칭 신청하기"}
        width={"600px"}
        visible={toggle}
        isDisableBackgroundClose
        onClose={handleToggle}
      >
        {isAddAssist ? (
          <Additional onClose={handleToggle} key={toggle} />
        ) : (
          <Request
            onClose={handleToggle}
            key={toggle}
            member={member}
            title={storyTitle}
          />
        )}
      </Modal>

      <Modal
        title={"이야기 삭제"}
        visible={deleteToggle}
        onClose={handleDeleteToggle}
      >
        <Box
          display={{
            display: "flex",
            direction: "column",
            align: "center",
          }}
          size={{
            width: "100%",
          }}
          margin={{
            all: "70px 0",
          }}
        >
          <Text
            typo={{
              size: "18px",
              weight: "700",
            }}
          >
            게시글을 삭제하시겠습니까?
          </Text>
          <Text
            typo={{
              size: "14px",
              color: "gray04",
            }}
          >
            게시글은 영구 삭제되어 복구 할 수 없습니다.
          </Text>
        </Box>
        <Box
          display={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            gap: "10px",
          }}
        >
          <CommonButton
            category={"button"}
            size={{
              width: "100%",
              height: "63px",
            }}
            typo={{
              size: "18px",
              weight: "700",
            }}
            onClick={handleDeleteToggle}
          >
            취소
          </CommonButton>
          <CommonButton
            category={"button"}
            size={{
              width: "100%",
              height: "63px",
            }}
            typo={{
              size: "18px",
              weight: "700",
            }}
            isActive
            onClick={deleteStory}
          >
            확인
          </CommonButton>
        </Box>
      </Modal>
    </Box>
  );
};

export default StoryInfo;
