import Box from "component/atoms/box";
import Button from "component/atoms/button";
import Image from "component/atoms/image";
import Text from "component/atoms/text";
import { useLikeStoryForm } from "hook/api/story/like";

const ProjectTitle = (props) => {
  const { title, showLike, liked, storyId, isLikeButton } = props;

  const likeStory = useLikeStoryForm();

  const handleLikeStory = (e) => {
    e.preventDefault();

    likeStory(storyId);
  };

  return (
    <Box
      display={{
        display: "flex",
        justify: "space-between",
      }}
      size={{
        width: "100%",
      }}
    >
      <Text
        typo={{
          size: "18px",
          weight: "700",
          lineClamp: "1",
          lineHeight: "25px",
          wordBreak: "break-all",
        }}
        longMobile={{
          typo: {
            lineHeight: "18px",
            size: "14px",
          },
        }}
      >
        {title}
      </Text>

      {showLike && (
        <Button
          background={{
            color: "white",
          }}
          size={{
            width: "25px",
            height: "25px",
          }}
          longMobile={{
            size: {
              width: "18px",
              height: "18px",
            },
          }}
          onClick={isLikeButton && handleLikeStory}
        >
          <Image
            src={
              liked
                ? "/asset/img/common/fullHeart.svg"
                : "/asset/img/common/blankHeart.svg"
            }
            size={{
              width: "100%",
            }}
          />
        </Button>
      )}
    </Box>
  );
};

export default ProjectTitle;
