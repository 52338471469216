import Box from "component/atoms/box";
import Image from "component/atoms/image";
import Text from "component/atoms/text";

const Blank = (props) => {
  const { title } = props;

  return (
    <Box
      display={{
        display: "flex",
        direction: "column",
        align: "center",
        justify: "center",
      }}
      margin={{
        top: "120px",
        bottom: "150px",
      }}
      longMobile={{
        margin: {
          top: "20px",
        },
      }}
    >
      <Image
        src={"/asset/img/common/blank.svg"}
        longMobile={{
          size: {
            width: "127px",
          },
        }}
      />
      <Text
        typo={{
          size: "20px",
          weight: "700",
          color: "gray02",
        }}
        longMobile={{
          typo: {
            size: "16px",
          },
        }}
      >
        {title}
      </Text>
    </Box>
  );
};

export default Blank;
