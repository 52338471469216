import Box from "component/atoms/box";
import RowTable from "component/atoms/rowSideTable";
import RowTableData from "component/atoms/rowTableData";
import RowTableHead from "component/atoms/rowTableHead";
import Text from "component/atoms/text";
import React, { useState } from "react";
import AdminButton from "component/molecules/admin/button/adminButton";
import Image from "component/atoms/image";
import useToggle from "hook/util/useToggle";
import { Link } from "react-router-dom";
import Modal from "component/molecules/common/modal";
import {
  useMatchingArtist,
  useMatchingAssist,
} from "hook/api/story/matchingArtist";
import convertImage from "utils/convertImage";
import LineTitle from "component/organisms/common/lineTitle";
import CommonButton from "component/molecules/common/commonButton";
import { BiChevronLeft } from "react-icons/bi";
import Api from "component/molecules/common/api";
import Additional from "component/organisms/detail/additional";
import useUrl from "hook/util/useUrl";
import Button from "component/atoms/button";
import useSelectStoryForm from "hook/api/story/select";
import Blank from "component/molecules/common/blank";
import { useAddition } from "hook/api/story/additional";
import List from "component/molecules/common/list";
import { GrClose } from "react-icons/gr";
import usePopup from "hook/util/usePopup";

const StoryMatchingEdit = (props) => {
  const { data } = props;
  const {
    params: { id },
    query: { assist },
  } = useUrl();

  const { toggle, handleToggle } = useToggle();
  const { toggle: toggleRequest, handleToggle: handleToggleRequest } =
    useToggle();

  const [type, setType] = useState("artist");

  const { selectMessage, setSelectMessage, handleSubmit } =
    useSelectStoryForm(0);

  const handleToggleType = (type) => {
    setType(type);
    handleToggle();
  };

  // 새 창으로 열기!
  const { handleOpenPopup } = usePopup({
    width: "1350px",
    height: window.innerHeight,
  });

  const openNewWindow = (memberId) => {
    handleOpenPopup(`/mypage/${memberId}`);
  };

  return (
    <Box
      size={{
        width: "100%",
        height: "auto",
      }}
      margin={{
        top: "25px",
      }}
      other={{
        overflowX: "hidden",
      }}
    >
      {!data.artistMatch && !data.assistMatch && (
        <>
          <Text theme={"adminTitle"}>아티스트 매칭</Text>
          <RowTable
            margin={{
              bottom: "55px",
            }}
          >
            <Box
              display={{
                width: "100%",
                height: "48px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="48px" isLastChild={true}>
                진행 상태
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                  height: "48px",
                }}
                isLastChild={true}
              >
                <Box
                  display={{
                    display: "flex",
                  }}
                >
                  <Text margin={{ top: "15px" }}>매칭 중</Text>
                  <Box
                    margin={{ left: "687px", top: "9px" }}
                    display={{
                      display: "flex",
                    }}
                  >
                    <AdminButton
                      category="button"
                      theme="whiteColor"
                      size={{
                        width: "100px",
                        height: "30px",
                      }}
                      typo={{
                        size: "13px",
                        weight: "400",
                        color: "black01",
                      }}
                      onClick={() => handleToggleType("artist")}
                    >
                      신청 아티스트
                    </AdminButton>
                  </Box>
                </Box>
              </RowTableData>
            </Box>
            <Box
              display={{
                width: "100%",
                height: "48px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="48px">
                창안자
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                  height: "48px",
                }}
              >
                <Text margin={{ top: "15px" }}>{data.member.memberEmail}</Text>
              </RowTableData>
            </Box>
            <Box
              display={{
                width: "100%",
                height: "48px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="48px">
                아티스트
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                  height: "48px",
                }}
              >
                <Text margin={{ top: "15px" }}>-</Text>
              </RowTableData>
            </Box>
          </RowTable>
        </>
      )}
      {data.artistMatch && (
        <>
          <Text theme={"adminTitle"}>아티스트 매칭</Text>
          <RowTable
            margin={{
              bottom: "55px",
            }}
          >
            <Box
              display={{
                width: "100%",
                height: "48px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="48px" isLastChild={true}>
                진행 상태
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                  height: "48px",
                }}
                isLastChild={true}
              >
                <Box
                  display={{
                    display: "flex",
                  }}
                >
                  <Text margin={{ top: "15px" }}>매칭 완료</Text>
                  <Box
                    margin={{ left: "687px", top: "9px" }}
                    display={{
                      display: "flex",
                    }}
                  >
                    <AdminButton
                      category="button"
                      theme="whiteColor"
                      size={{
                        width: "100px",
                        height: "30px",
                      }}
                      typo={{
                        size: "13px",
                        weight: "400",
                        color: "black01",
                      }}
                      onClick={() => handleToggleType("artist")}
                    >
                      신청 아티스트
                    </AdminButton>
                  </Box>
                </Box>
              </RowTableData>
            </Box>
            <Box
              display={{
                width: "100%",
                height: "48px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="48px">
                창안자
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                  height: "48px",
                }}
              >
                <Text margin={{ top: "15px" }}>{data.member.memberEmail}</Text>
              </RowTableData>
            </Box>
            <Box
              display={{
                width: "100%",
                height: "48px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="48px">
                아티스트
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                  height: "48px",
                }}
              >
                <Text margin={{ top: "15px" }}>{data.artist.memberEmail}</Text>
              </RowTableData>
            </Box>
          </RowTable>
        </>
      )}

      {data.assistMatch && (
        <>
          <Text theme={"adminTitle"}>멤버 매칭</Text>
          <RowTable
            margin={{
              bottom: "55px",
            }}
          >
            <Box
              display={{
                width: "100%",
                height: "48px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="48px" isLastChild={true}>
                진행 상태
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                  height: "48px",
                }}
                isLastChild={true}
              >
                <Box
                  display={{
                    display: "flex",
                  }}
                >
                  <Text margin={{ top: "15px" }}>매칭 완료</Text>
                  <Box
                    margin={{ left: "595px", top: "9px" }}
                    display={{
                      display: "flex",
                    }}
                  >
                    <AdminButton
                      category="button"
                      theme="whiteColor"
                      size={{
                        width: "100px",
                        height: "30px",
                      }}
                      typo={{
                        size: "13px",
                        weight: "400",
                        color: "black01",
                      }}
                      onClick={() => handleToggleType("assist")}
                    >
                      신청 아티스트
                    </AdminButton>
                    <AdminButton
                      category="button"
                      theme="whiteColor"
                      size={{
                        width: "87px",
                        height: "30px",
                      }}
                      typo={{
                        size: "13px",
                        weight: "400",
                        color: "black01",
                      }}
                      margin={{
                        left: "5px",
                        right: "10px",
                      }}
                      onClick={handleToggleRequest}
                    >
                      모집글 보기
                    </AdminButton>
                  </Box>
                </Box>
              </RowTableData>
            </Box>
            <Box
              display={{
                width: "100%",
                height: "48px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="48px">
                창안자
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                  height: "48px",
                }}
              >
                <Text margin={{ top: "15px" }}>{data.member.memberEmail}</Text>
              </RowTableData>
            </Box>
            <Box
              display={{
                width: "100%",
                height: "48px",
                display: "flex",
                direction: "row",
                align: "flex-start",
                justify: "flex-start",
              }}
            >
              <RowTableHead width="160px" height="48px">
                아티스트
              </RowTableHead>
              <RowTableData
                size={{
                  width: "calc(100% - 160px)",
                  height: "48px",
                }}
              >
                <Text margin={{ top: "15px" }}>{data.assist.memberEmail}</Text>
              </RowTableData>
            </Box>
          </RowTable>
        </>
      )}

      <Box
        position={{
          type: "fixed",
          top: "0",
          left: "0",
          right: "0",
          bottom: "0",
          zIndex: "999",
        }}
        display={{
          display: toggle ? "flex" : "none",
          justify: "center",
          align: "center",
        }}
        background={{
          color: "rgba(0, 0, 0, 0.2)",
        }}
        mobile={{
          padding: {
            all: "16px",
          },
        }}
        onClick={handleToggle}
      >
        <Box
          animation={"modalPopOpen"}
          position={{
            type: "relative",
          }}
          size={{
            minWidth: "1200px",
          }}
          background={{
            color: "white01",
          }}
          border={{
            all: "2px",
          }}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <List
            api={type === "assist" ? useMatchingAssist : useMatchingArtist}
            params={{
              id,
            }}
          >
            {(list) =>
              list && (
                <>
                  <Box
                    display={{
                      display: "flex",
                    }}
                    size={{
                      width: "100%",
                      height: "676px",
                    }}
                  >
                    <Box
                      size={{
                        width: "50%",
                        height: "100%",
                      }}
                      display={{
                        display: "flex",
                        direction: "column",
                        align: list.length === 0 ? "center" : "flex-start",
                      }}
                      border={{
                        right: "1px",
                      }}
                      padding={{
                        all: "35px 22px",
                      }}
                      position={{
                        type: "relative",
                      }}
                      longMobile={{
                        size: {
                          width: "100%",
                        },

                        border: {
                          all: "1px",
                        },
                      }}
                    >
                      <LineTitle
                        isNeedLine
                        title={
                          type === "assist"
                            ? "멤버 신청 아티스트"
                            : "아티스트 매칭하기"
                        }
                        margin={{
                          bottom: "0",
                        }}
                      />

                      {list.length === 0 && (
                        <Blank
                          title={"현재 매칭 신청한 아티스트가 없습니다. "}
                        />
                      )}
                      {list.length !== 0 && (
                        <Box
                          size={{
                            width: "100%",
                            height: "630px",
                          }}
                          other={{
                            overflowY: "auto",
                          }}
                          isCustomScroll
                          longMobile={{
                            size: {
                              width: "100%",
                              height: "178px",
                            },
                          }}
                        >
                          {list.map(
                            (
                              { matchId, matchTitle, matchContent, member },
                              idx
                            ) => (
                              <Box
                                size={{
                                  width: "100%",
                                }}
                                key={matchId}
                              >
                                <Button
                                  size={{
                                    width: "100%",
                                  }}
                                  display={{
                                    display: "flex",
                                    align: "center",
                                    justify: "space-between",
                                  }}
                                  padding={{
                                    all: "10px 15px",
                                  }}
                                  border={{
                                    bottom: "1px",
                                    color: "gray02",
                                  }}
                                  background={{
                                    color:
                                      idx === selectMessage
                                        ? "pink02"
                                        : "white01",
                                  }}
                                  key={id}
                                  onClick={() => setSelectMessage(idx)}
                                >
                                  <Image
                                    src={convertImage(member[0].memberProfile)}
                                    size={{
                                      width: "50px",
                                      height: "50px",
                                    }}
                                    border={{
                                      radius: "50%",
                                    }}
                                    longMobile={{
                                      size: {
                                        width: "35px",
                                        height: "35px",
                                      },
                                    }}
                                  />
                                  <Box
                                    display={{
                                      display: "flex",
                                      direction: "column",
                                      justify: "center",
                                    }}
                                    size={{
                                      width: "calc(100% - 50px - 28px - 128px)",
                                    }}
                                    longMobile={{
                                      size: {
                                        width:
                                          "calc(100% - 50px - 28px - 60px)",
                                      },
                                    }}
                                  >
                                    <Text
                                      typo={{
                                        size: "14px",
                                        weight: "700",
                                        lineClamp: 1,
                                        wordBreak: "break-all",
                                        align: "left",
                                      }}
                                    >
                                      {member[0].artistName}
                                    </Text>
                                    {/* <Text
                                typo={{
                                  size: "14px",
                                  weight: "400",
                                  lineClamp: 1,
                                  wordBreak: "break-all",
                                  align: "left",
                                }}
                              >
                                {music}
                              </Text> */}
                                  </Box>

                                  <CommonButton
                                    category={"button"}
                                    size={{
                                      width: "128px",
                                      height: "38px",
                                    }}
                                    typo={{
                                      size: "14px",
                                      weight: "700",
                                    }}
                                    longMobile={{
                                      size: {
                                        width: "60px",
                                      },
                                    }}
                                    link={`/mypage/${member[0].memberId}`}
                                    onClick={() => {
                                      const width = "1000px";
                                      const height = window.innerHeight;
                                      const left =
                                        window.screenX + window.innerWidth;
                                      const top = window.screenY;

                                      window.open(
                                        `/mypage/${member[0].memberId}`,
                                        "newwindow",
                                        `width=${width},height=${height},left=${left},top=${top}`
                                      );
                                    }}
                                  >
                                    프로필{" "}
                                    <Text
                                      longMobile={{
                                        display: {
                                          display: "none",
                                        },
                                      }}
                                    >
                                      보러가기
                                    </Text>
                                  </CommonButton>
                                </Button>
                              </Box>
                            )
                          )}
                        </Box>
                      )}
                    </Box>
                    <Box
                      size={{
                        width: "50%",
                        height: "100%",
                      }}
                      display={{
                        display: "flex",
                        direction: "column",
                        align: "center",
                        justify: "flex-start",
                      }}
                      padding={{
                        all: "35px 22px",
                      }}
                    >
                      {list.length === 0 && (
                        <Blank
                          title={"현재 매칭 신청한 아티스트가 없습니다. "}
                        />
                      )}
                      {list.length !== 0 && (
                        <>
                          <LineTitle
                            isNeedLine
                            title={list[selectMessage].member[0].artistName}
                            margin={{
                              bottom: "13px",
                            }}
                          >
                            <Button
                              background={{
                                color: "transparent",
                              }}
                              typo={{
                                size: "0",
                              }}
                              onClick={handleToggle}
                            >
                              <GrClose size={16} />
                            </Button>
                          </LineTitle>
                          <Text
                            size={{
                              width: "100%",
                            }}
                            typo={{
                              size: "14px",
                              weight: "700",
                            }}
                            margin={{
                              bottom: "5px",
                            }}
                          >
                            제목
                          </Text>
                          <Box
                            size={{
                              width: "100%",
                            }}
                            padding={{
                              all: "12px 16px",
                            }}
                            margin={{
                              bottom: "20px",
                            }}
                            border={{
                              all: "1px",
                            }}
                          >
                            <Text
                              typo={{
                                size: "12px",
                                weight: "400",
                              }}
                            >
                              {list[selectMessage].matchTitle}
                            </Text>
                          </Box>

                          <Text
                            size={{
                              width: "100%",
                            }}
                            typo={{
                              size: "14px",
                              weight: "700",
                            }}
                            margin={{
                              bottom: "5px",
                            }}
                          >
                            내용
                          </Text>
                          <Box
                            size={{
                              width: "100%",
                              height: "536px",
                            }}
                            padding={{
                              all: "12px 16px",
                            }}
                            margin={{
                              bottom: "20px",
                            }}
                            border={{
                              all: "1px",
                            }}
                            longMobile={{
                              size: {
                                width: "100%",
                                height: "234px",
                              },
                            }}
                          >
                            <Text
                              typo={{
                                size: "12px",
                                weight: "400",
                                whiteSpace: "pre-line",
                              }}
                            >
                              {list[selectMessage].matchContent}
                            </Text>
                          </Box>
                        </>
                      )}
                    </Box>
                  </Box>
                </>
              )
            }
          </List>
        </Box>
      </Box>

      <Modal
        title={"멤버 모집글 보기"}
        width={"600px"}
        visible={toggleRequest}
        isNeedLine
        onClose={handleToggleRequest}
      >
        <Api api={useAddition} params={id}>
          {(data) =>
            data && (
              <Box
                position={{
                  type: "relative",
                }}
                longMobile={{
                  size: {
                    width: "100%",
                  },
                  padding: {
                    all: "51px 0 0px",
                  },
                  border: {
                    all: "1px",
                  },
                }}
              >
                <Text
                  typo={{
                    size: "14px",
                    weight: "700",
                  }}
                  margin={{
                    bottom: "8px",
                  }}
                >
                  제목
                </Text>
                <Box
                  padding={{
                    all: "12px 16px",
                  }}
                  border={{
                    all: "1px",
                  }}
                  background={{
                    color: "pink01",
                  }}
                  margin={{
                    bottom: "20px",
                  }}
                >
                  <Text
                    typo={{
                      size: "12px",
                      weight: "400",
                    }}
                  >
                    {data.employTitle}
                  </Text>
                </Box>

                <Text
                  typo={{
                    size: "14px",
                    weight: "700",
                  }}
                  margin={{
                    bottom: "8px",
                  }}
                >
                  내용
                </Text>
                <Box
                  size={{
                    width: "100%",
                    height: "448px",
                  }}
                  padding={{
                    all: "12px 16px",
                  }}
                  border={{
                    all: "1px",
                  }}
                  background={{
                    color: "pink01",
                  }}
                  other={{
                    overflowY: "auto",
                  }}
                >
                  <Text
                    typo={{
                      size: "12px",
                      weight: "400",
                      whiteSpace: "pre-line",
                      wordBreak: "break-all",
                    }}
                  >
                    {data.employContent}
                  </Text>
                </Box>
              </Box>
            )
          }
        </Api>
      </Modal>
    </Box>
  );
};

export default StoryMatchingEdit;
