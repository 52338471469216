import Box from "component/atoms/box";
import Input from "component/atoms/input";
import Text from "component/atoms/text";
import CommonButton from "component/molecules/common/commonButton";
import useReplayForm from "hook/api/story/reply";

const CommentForm = () => {
  const { value, isActive, onChange, submit } = useReplayForm();

  return (
    <Box
      typo={{
        size: "0",
      }}
    >
      <Input
        as={"textarea"}
        size={{
          width: "100%",
          height: "88px",
        }}
        border={{
          top: "1",
          left: "1px",
          right: "1px",
        }}
        maxLength={255}
        placeholder={"댓글을 입력해 주세요."}
        value={value}
        onChange={onChange}
      />
      <Box
        display={{
          display: "flex",
          justify: "flex-end",
        }}
        size={{
          width: "100%",
          height: "40px",
        }}
      >
        <Box
          display={{
            display: "flex",
            align: "center",
          }}
          border={{
            top: "1px",
            bottom: "1px",
            left: "1px",
          }}
          size={{
            width: "calc(100% - 165px)",
            height: "100%",
          }}
          longMobile={{
            display: {
              display: "none",
            },
          }}
          padding={{
            left: "10px",
          }}
        >
          <Text
            typo={{
              size: "14px",
              color: "gray03",
            }}
          >
            {value.length} / 255
          </Text>
        </Box>
        <CommonButton
          category={"button"}
          size={{
            width: "165px",
            height: "100%",
          }}
          longMobile={{
            size: {
              width: "100%",
            },
          }}
          isActive={isActive}
          isDisabled={!isActive}
          onClick={submit}
        >
          등록하기
        </CommonButton>
      </Box>
    </Box>
  );
};

export default CommentForm;
