import Box from "component/atoms/box";
import Text from "component/atoms/text";

const SIZE = {
  small: {
    size: {
      minWidth: "42px",
      height: "24px",
    },
  },
  large: {
    size: {
      minWidth: "80px",
      height: "40px",
    },
  },
};

const TYPO = {
  small: {
    size: "12px",
    weight: "700",
    lineHeight: "22px",
  },
  large: {
    size: "15px",
    weight: "700",
    lineHeight: "38px",
  },
};

const ACTIVE = ["창작완료", "발매완료"];
const SECRET = ["비밀글"];

const Status = (props) => {
  const { size, type } = props;

  const currentSize = SIZE[size];
  const currentTypo = TYPO[size];

  const isActive = ACTIVE.includes(type);
  const isSecret = SECRET.includes(type);

  return (
    <Box
      display={{
        display: "flex",
        justify: "center",
        align: "center",
      }}
      position={{
        type: "absolute",
        top: "12px",
        left: "12px",
      }}
      border={{
        all: "1px",
        style: "solid",
        color: "black01",
      }}
      padding={{
        all: "4px",
      }}
      background={{
        color: isActive ? "pink01" : isSecret ? "black01" : "white01",
      }}
      longMobile={{
        size: {
          minWidth: "auto",
        },
        position: {
          top: "8px",
          left: "8px",
        },
      }}
      {...currentSize}
    >
      <Text
        typo={{
          ...currentTypo,
          color: isSecret ? "white01" : "black01",
        }}
        longMobile={{
          typo: {
            size: "10px",
            lineHeight: "10px",
          },
        }}
      >
        {type}
      </Text>
    </Box>
  );
};

export default Status;
