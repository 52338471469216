import React, { useState } from "react";
import { MenuContext } from "context";

export const MenuProvider = ({ children }) => {
  const [selectedMenu, setSelectedMenu] = useState("dashboard");

  return (
    <MenuContext.Provider value={{ selectedMenu, setSelectedMenu }}>
      {children}
    </MenuContext.Provider>
  );
};
