import React, { useContext } from "react";
import { MenuContext } from "context";
import CheeringManagement from "component/organisms/admin/story/cheeringManage";
import ExposedContents from "component/organisms/admin/story/exposeToMain";
import ContentsJudging from "component/organisms/admin/story/judging";
import StoryManagement from "component/organisms/admin/story/storyManage";
import Box from "component/atoms/box";

const ManageStory = () => {
  const { selectedMenu } = useContext(MenuContext);

  const renderContent = () => {
    switch (selectedMenu) {
      case "storyManage":
        return <StoryManagement />;
      case "judging":
        return <ContentsJudging />;
      case "exposed":
        return <ExposedContents />;
      case "cheering":
        return <CheeringManagement />;
      default:
        return <StoryManagement />;
    }
  };

  return (
    <Box
      size={{
        width: "100%",
        height: "auto",
      }}
      margin={{
        top: "25px",
      }}
    >
      {renderContent()}
    </Box>
  );
};

export default ManageStory;
