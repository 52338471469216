import Header from "component/organisms/layout/header";
import { BasicContainer } from "./basic.style";
import Footer from "component/organisms/layout/footer";
import useSignedStore from "store/useSignedStore";
import useCheckLogin from "hook/api/auth/check";
import { useEffect, useState } from "react";
import * as ChannelService from "@channel.io/channel-web-sdk-loader";

ChannelService.loadScript();

const BasicTemplate = (props) => {
  const { setSigned } = useSignedStore();
  const [loading, setLoading] = useState(true);
  const { data, isSuccess, isLoading } = useCheckLogin();

  useEffect(() => {
    ChannelService.boot({
      pluginKey: "0ffe8b5c-b1b0-49a0-8090-9ef71c7d43a5", // fill your plugin key
    });
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (isSuccess) {
        setSigned(data);
      } else {
        setSigned(null);
      }
      setLoading(false);
    }
  }, [isSuccess, isLoading, data]);

  if (loading) return null;

  return (
    <BasicContainer>
      <Header />
      {props.children}
      <Footer />
    </BasicContainer>
  );
};

export default BasicTemplate;
