import Box from "component/atoms/box";
import Line from "component/atoms/line";
import Text from "component/atoms/text";
import Api from "component/molecules/common/api";
import CommonButton from "component/molecules/common/commonButton";
import Modal from "component/molecules/common/modal";
import InputField from "component/organisms/common/inputField";
import useMyCoin from "hook/api/auth/mycoin";
import useSupportForm from "hook/api/story/support";
import { FormProvider } from "react-hook-form";
import numberWithCommas from "utils/numberWithCommas";

const SupportModal = (props) => {
  const { toggle, handleToggle, funding } = props;
  const { form, isActive, onSubmit } = useSupportForm(handleToggle, funding);

  const { coin } = form.watch();

  return (
    <Modal
      visible={toggle}
      onClose={handleToggle}
      title={"무무코인으로 응원하기"}
      info={
        <Text
          size={{
            width: "100%",
          }}
          typo={{
            size: "14px",
            weight: "400",
            color: "gray04",
          }}
        >
          좋아하는 이야기가 좋은 음악이 될 수 있도록 응원해주세요!
        </Text>
      }
    >
      <Api api={useMyCoin}>
        {(data) =>
          data && (
            <FormProvider {...form}>
              <Box
                padding={{
                  top: "5px",
                }}
              >
                <Text
                  typo={{
                    size: "14px",
                    weight: "700",
                  }}
                >
                  보유 무무코인
                </Text>

                <Text
                  typo={{
                    size: "24px",
                    weight: "700",
                  }}
                >
                  {numberWithCommas(data.coinsHeld)} Coin
                </Text>
              </Box>

              <Line
                direction="row"
                size={"100%"}
                margin={{
                  all: "20px 0",
                }}
                background={{
                  color: "gray02",
                }}
              />

              <Box
                display={{
                  display: "flex",
                  align: "flex-end",
                  justify: "flex-start",
                  gap: "10px",
                }}
                margin={{
                  bottom: "20px",
                }}
              >
                <InputField
                  title={"사용할 무무코인"}
                  type={"number"}
                  name={"coin"}
                  typo={{
                    size: "14px",
                    weight: "700",
                  }}
                  size={{
                    width: "294px",
                    height: "46px",
                  }}
                  longMobile={{
                    size: {
                      width: "236px",
                      height: "40px",
                    },
                  }}
                />
                <Text
                  typo={{
                    size: "24px",
                    weight: "700",
                  }}
                  margin={{
                    bottom: "4px",
                  }}
                  longMobile={{
                    typo: {
                      size: "16px",
                    },
                  }}
                >
                  Coin
                </Text>
              </Box>

              <CommonButton
                category={"button"}
                size={{
                  width: "100%",
                  height: "63px",
                }}
                typo={{
                  size: "18px",
                }}
                longMobile={{
                  size: {
                    width: "100%",
                    height: "50px",
                  },
                  typo: {
                    size: "14px",
                  },
                }}
                isActive={coin <= data.coinsHeld && isActive}
                isDisabled={coin > data.coinsHeld || !isActive}
                onClick={() => coin <= data.coinsHeld && onSubmit()}
              >
                응원하기
              </CommonButton>
            </FormProvider>
          )
        }
      </Api>
    </Modal>
  );
};

export default SupportModal;
