import Box from "component/atoms/box";
import Text from "component/atoms/text";
import React, { useState, useRef, useEffect } from "react";
import AdminButton from "component/molecules/admin/button/adminButton";
import Image from "component/atoms/image";
import TableData from "component/atoms/tableData";
import TableHead from "component/atoms/tableHead";
import Table from "component/atoms/table";
import AdminCheckbox from "component/molecules/adminCheckbox";
import AdminAlertModal from "../../modal/alertModal";
import RowTableHead from "component/atoms/rowTableHead";
import RowTableData from "component/atoms/rowTableData";
import RowTable from "component/atoms/rowSideTable";
import Input from "component/atoms/input";
import AdminRadio from "component/molecules/admin/adminRadio";
import ImageInput from "../../imageInput";
import AdminDropdown from "component/molecules/admin/adminDropdown";
import CalendarInput from "component/molecules/common/calendarInput";
import AdminTimePicker from "component/molecules/admin/timePicker";
import Api from "component/molecules/common/api";
import {
  useAdminCreatePopup,
  useAdminDeletePopup,
  useAdminPopup,
  useAdminPopupDetail,
  useAdminUpdatePopup,
} from "hook/api/admin/banner";
import dayjs from "dayjs";
import AdminPagination from "component/molecules/admin/pagination";
import convertImage from "utils/convertImage";
import { useForm } from "react-hook-form";
import { getCurrentDateTime } from "utils/getCurrentDateTime";

const PopupManage = (props) => {
  const [loading, setLoading] = useState(true);
  const category = ["전체", "창안자 이름", "제목"];
  const listCategory = [
    { label: "5개씩 보기", value: 5 },
    { label: "10개씩 보기", value: 10 },
    { label: "20개씩 보기", value: 20 },
    { label: "30개씩 보기", value: 30 },
  ];
  const labels = listCategory.map((category) => category.label);

  const [selected, setSelected] = useState(category[0]);

  // 10개씩 보기 선택하면 value = 10 을 pageSize로 설정
  const changeSelectedList = (selectedLabel) => {
    const category = listCategory.find((cat) => cat.label === selectedLabel);
    if (category) {
      setPageSize(category.value);
      setCurrentPage(1);
    }
  };

  // 검색어 상태 관리
  const [search, setSearch] = useState("");

  // 검색어 임시 저장
  const [inputValue, setInputValue] = useState("");

  const [pageSize, setPageSize] = useState(5);

  // 페이지네이션
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // 체크 박스 상태 관리
  const [checked, setChecked] = useState({});

  const {
    data: data,
    totalItems: total,
    totalPages: totalPages,
  } = useAdminPopup(search, currentPage, pageSize);

  // 수정 버튼 클릭 시 popupId를 보내기 위한 상태 관리
  const [popupId, setPopupId] = useState(null);

  // 검색 버튼 클릭 핸들러
  const handleSearch = () => {
    setSearch(inputValue); // 사용자가 입력한 검색어를 실제 검색어 상태로 설정
    setCurrentPage(1); // 검색 시 페이지네이션 초기화
  };

  // 인풋 변경 핸들러
  const handleInputChange = (e) => {
    setInputValue(e.target.value); // 입력된 값을 inputValue 상태에 저장
  };

  // imageInput

  const { image } = props;

  const { watch, setValue } = useForm({
    defaultValues: {
      ...image,
      popupThumbnail: image?.popupThumbnail,
    },
  });

  const { thumbnail } = watch();

  const handleProfile = (file) => {
    setValue("thumbnail", file);
    setPopupThumbnail(file);
  };

  // 저장 알림 모달창 상태 관리
  const [visible, setVisible] = useState(false);
  // 수정 페이지에서 저장 버튼 클릭 시
  const [save, setSave] = useState(false);
  // 순서변경 버튼 상태 관리
  const [edit, setEdit] = useState(false);
  // 배너 수정 상태 관리
  const [bannerEdit, setBannerEdit] = useState(false);

  const baseUrl = process.env.REACT_APP_SERVER_URL;
  const currentDateTime = getCurrentDateTime();
  const [popupUse, setPopupUse] = useState(1);
  const [popupTitle, setPopupTitle] = useState("");

  const [linkUse, setLinkUse] = useState(1);
  const [popupLink, setPopupLink] = useState("");
  const [newWindow, setNewWindow] = useState(0);
  const [bottomText, setBottomText] = useState(1);
  const [exposure, setExposure] = useState(1);
  const [startDate, setStartDate] = useState(currentDateTime);
  const [endDate, setEndDate] = useState(currentDateTime);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [successDelete, setSuccessDelete] = useState(false);
  const [errorDelete, setErrorDelete] = useState(false);

  const handleSuccess = () => {
    setSuccess(true);
  };

  const handleError = () => {
    setError(true);
  };

  const handleDeleteSuccess = () => {
    setSuccessDelete(true);
  };

  const handleDeleteError = () => {
    setErrorDelete(true);
  };

  // 팝업 등록
  const { mutate: createPopup } = useAdminCreatePopup(
    handleSuccess,
    handleError
  );

  // 삭제
  const { mutate: deletePopup } = useAdminDeletePopup(
    handleDeleteSuccess,
    handleDeleteError
  );

  const { data: detailData } = useAdminPopupDetail(popupId);

  const { mutate: updatePopup } = useAdminUpdatePopup(
    popupId,
    handleSuccess,
    handleError
  );

  const [popupThumbnail, setPopupThumbnail] = useState(
    detailData?.popupThumbnail
  );

  // 순서 변경 저장 버튼 클릭 시
  const handleSave = () => {
    setVisible(true);
    setEdit(!edit);
  };

  // 배너 등록 저장 버튼 클릭 시
  const handleBannerSave = () => {
    const formData = new FormData();
    formData.append("popupUse", popupUse);
    formData.append("popupTitle", popupTitle);

    formData.append("popupThumbnail", popupThumbnail);

    formData.append("linkUse", linkUse);
    formData.append("popupLink", popupLink);
    formData.append("newWindows", newWindow);
    formData.append("bottomText", bottomText);
    formData.append("expoSure", exposure);

    if (popupTitle === "") {
      alert("제목을 입력해주세요.");
      return;
    }
    if (popupThumbnail === "") {
      alert("이미지를 등록해주세요.");
      return;
    }
    if (linkUse === 1 && popupLink === "") {
      alert("링크를 입력해주세요.");
      return;
    }
    // 노출 기간 설정 시에만 시작일, 종료일을 dayjs로 변환하여 전송
    if (exposure === 1) {
      if (
        dayjs(startDate).format("YYYYMMDDHHmm") >=
        dayjs(endDate).format("YYYYMMDDHHmm")
      ) {
        alert(
          "노출 시작일이 노출 종료일보다 늦거나 같을 수 없습니다. 다시 설정해주세요."
        );
        return;
      }

      // if (exposure === 0) {
      //   formData.append("startDate", "");
      //   formData.append("endDate", "");
      // }
      formData.append("startDate", dayjs(startDate).format("YYYYMMDDHHmm"));
      formData.append("endDate", dayjs(endDate).format("YYYYMMDDHHmm"));
    } else if (exposure === 0) {
      formData.append("startDate", "");
      formData.append("endDate", "");
    }

    // popupUse가 1이고 현재 시간이 endDate 이후라면 경고 메시지 표시
    if (
      popupUse === 1 &&
      exposure === 1 &&
      currentDateTime >= dayjs(endDate).format("YYYYMMDDHHmm")
    ) {
      alert(
        "노출 종료일이 현재 시간 이전이라 사용 안함 처리됩니다. 다시 설정해주세요."
      );
      return;
    }

    if (isEditMode) {
      updatePopup(formData);
    } else {
      createPopup(formData);
    }
    setBannerEdit(!bannerEdit);
    setSave(true);
    setCurrentPage(1);
    !isEditMode && setPopupId(null);
    setPopupId(null);
    setEdit(!edit);
    setPopupUse(1);
    setPopupTitle("");
    setPopupThumbnail("");
    setLinkUse(1);
    setPopupLink("");
    setNewWindow(0);
    setBottomText(1);
    setExposure(1);
    setStartDate(currentDateTime);
    setEndDate(currentDateTime);
  };

  const resetForm = () => {
    setPopupId(null);
    setEdit(!edit);
    setPopupUse(1);
    setPopupTitle("");
    setPopupThumbnail("");
    setLinkUse(1);
    setPopupLink("");
    setNewWindow(0);
    setBottomText(1);
    setExposure(1);
    setStartDate(currentDateTime);
    setEndDate(currentDateTime);
  };

  const handleEdit = () => {
    setEdit(!edit);
  };

  // 체크박스를 선택하여 일괄 승인
  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    setSelectedUser({});
  }, [currentPage]);

  // 전체 선택 또는 해제 처리
  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setSelectedUser((prevSelectedUser) => {
      const newSelectedUser = {};
      if (checked) {
        data?.forEach((data) => {
          newSelectedUser[data.popupId] = true;
        });
      }
      return newSelectedUser;
    });
  };

  // 개별 선택 처리
  const handleSelect = (e, popupId) => {
    const checked = e.target.checked;
    setSelectedUser((prevSelectedUser) => {
      const newSelectedUser = { ...prevSelectedUser };
      newSelectedUser[popupId] = checked;
      return newSelectedUser;
    });
  };

  const selectedUserIds = Object.entries(selectedUser)
    .filter(([_, checked]) => checked)
    .map(([popupId]) => popupId);

  const handleDelete = () => {
    if (selectedUserIds.length === 0) {
      alert("삭제할 팝업을 선택해주세요.");
      return;
    } else {
      window.confirm("선택된 팝업을 삭제하시겠습니까?") &&
        deletePopup({ popupId: selectedUserIds }) &&
        setSelectedUser({});
      setCurrentPage(1);
    }
  };

  // 개별 삭제 버튼 클릭 시
  const handleButtonDelete = (popupId) => {
    window.confirm("선택된 팝업을 삭제하시겠습니까?") &&
      deletePopup({ popupId: popupId });
  };

  // 수정일 때와 등록일 때 구분
  const [isEditMode, setIsEditMode] = useState(false);
  // 수정 버튼 클릭 시 게시물 Id를 통해 게시물 상세 정보를 가져옴
  const handleBannerEdit = (popupId) => {
    setPopupId(popupId);
    setIsEditMode(true);
    setBannerEdit(!bannerEdit);
  };

  const handleButtonEdit = () => {
    setIsEditMode(false);
    setBannerEdit(!bannerEdit);
  };

  const handleChangeTime = (value, setValue) => (HHmm) => {
    const date = value.slice(0, 8);

    setValue(date + HHmm);
  };

  const handleChangeDate = (value, setValue) => (YYYYMMDD) => {
    const time = value.slice(8, 12);

    setValue(YYYYMMDD + time);
  };

  useEffect(() => {
    const currentDateTime = getCurrentDateTime();
    if (isEditMode && detailData) {
      setPopupUse(detailData?.popupUse);
      setPopupTitle(detailData?.popupTitle);
      setPopupThumbnail(detailData?.popupThumbnail);
      setLinkUse(detailData?.linkUse);
      setPopupLink(detailData?.popupLink);
      setNewWindow(detailData?.newWindows);
      setBottomText(detailData?.bottomText);
      setExposure(detailData?.expoSure);
      setStartDate(detailData?.startDate);
      setEndDate(detailData?.endDate);
    } else {
      setPopupUse(1);
      setPopupTitle("");
      setPopupThumbnail("");
      setLinkUse(1);
      setPopupLink("");
      setNewWindow(0);
      setBottomText(1);
      setExposure(1);
      setStartDate(currentDateTime);
      setEndDate(currentDateTime);
      setChecked({});
    }
    setLoading(false);
  }, [detailData, isEditMode]);

  const handleChangeExposure = (exposureValue) => {
    setExposure(exposureValue);
  };

  const [prevExposure, setPrevExposure] = useState(exposure);
  useEffect(() => {
    // 노출 기간 설정이 '미설정'(0)에서 '설정'(1)으로 변경되었을 경우
    if (prevExposure === 0 && exposure === 1) {
      const currentDateTime = getCurrentDateTime();

      setStartDate(currentDateTime);
      setEndDate(currentDateTime);
    }

    // 현재 노출 기간 설정 상태를 이전 상태로 업데이트
    setPrevExposure(exposure);
  }, [exposure, prevExposure]);

  // 저장 버튼 비활성화
  const [isDone, setIsDone] = useState(false);

  useEffect(() => {
    // title과 bannerImage가 유효해야 하며,
    // linkUse가 "1"일 경우에만 bannerLink도 유효해야 함
    if (detailData && isEditMode) {
      if (
        (detailData.popupTitle &&
          detailData.popupThumbnail &&
          (linkUse === 0 || (linkUse === 1 && popupLink))) ||
        detailData.linkUse === 0 ||
        (detailData.linkUse === 1 && popupLink)
      ) {
        setIsDone(true);
      } else {
        setIsDone(false);
      }
    } else if (!isEditMode) {
      if (
        popupTitle &&
        popupThumbnail &&
        (linkUse === 0 || (linkUse === 1 && popupLink))
      ) {
        setIsDone(true);
      } else {
        setIsDone(false);
      }
    }
  }, [popupTitle, popupThumbnail, linkUse, popupLink]);

  return (
    !loading && (
      <>
        <AdminAlertModal
          title={
            visible
              ? "메인 배너 순서 변경"
              : successDelete
              ? "팝업 삭제"
              : "팝업 등록"
          }
          text={
            visible
              ? "메인 배너 순서 변경이 완료되었습니다."
              : successDelete
              ? "팝업 삭제가 완료되었습니다."
              : "팝업 등록이 완료되었습니다."
          }
          btn_text="확인"
          visible={visible || successDelete || save}
          toggle={() => {
            setVisible(false);
            setSuccessDelete(false);
            setSave(false);
          }}
        />
        {bannerEdit ? (
          // 팝업 등록 페이지
          <Box
            size={{
              width: "100%",
              height: "auto",
            }}
            margin={{
              top: "25px",
            }}
            other={{
              overflowX: "hidden",
            }}
          >
            <Box>
              <Text theme={"adminTitle"}>팝업 등록</Text>
              <RowTable key={data.popupId}>
                <Box
                  theme="adminListBox"
                  padding={{
                    left: "21px",
                  }}
                >
                  <Text
                    typo={{
                      size: "12px",
                      weight: "700",
                      color: "pink03",
                    }}
                  >
                    * 은 필수 입력 항목입니다.
                  </Text>
                </Box>
                <Box
                  display={{
                    width: "100%",
                    height: "52px",
                    display: "flex",
                    direction: "row",
                    align: "flex-start",
                    justify: "flex-start",
                  }}
                >
                  <RowTableHead width="160px" height="48px">
                    사용여부&nbsp;
                    <Text
                      typo={{
                        size: "13px",
                        weight: "700",
                        color: "pink03",
                      }}
                    >
                      *
                    </Text>
                  </RowTableHead>
                  <RowTableData
                    size={{
                      width: "calc(100% - 160px)",
                    }}
                  >
                    <Box
                      display={{
                        display: "flex",
                      }}
                      margin={{
                        top: "12px",
                      }}
                    >
                      <AdminRadio
                        id="use_used"
                        name="use_status"
                        htmlFor="use_used"
                        label="사용함"
                        value="1"
                        checked={popupUse === 1}
                        onClick={() => setPopupUse(1)}
                      />
                      <AdminRadio
                        id="use_unused"
                        name="use_status"
                        value="0"
                        htmlFor="use_unused"
                        label="사용 안함"
                        checked={popupUse === 0}
                        onClick={() => setPopupUse(0)}
                      />
                    </Box>
                  </RowTableData>
                </Box>
                <Box
                  display={{
                    width: "100%",
                    height: "52px",
                    display: "flex",
                    direction: "row",
                    align: "flex-start",
                    justify: "flex-start",
                  }}
                >
                  <RowTableHead width="160px" height="48px">
                    제목&nbsp;
                    <Text
                      typo={{
                        size: "13px",
                        weight: "700",
                        color: "pink03",
                      }}
                    >
                      *
                    </Text>
                  </RowTableHead>
                  <RowTableData
                    size={{
                      width: "calc(100% - 160px)",
                    }}
                  >
                    <Input
                      theme={"adminInput"}
                      size={{
                        width: "395px",
                        height: "32px",
                      }}
                      margin={{
                        top: "8px",
                      }}
                      value={popupTitle}
                      onChange={(e) => setPopupTitle(e.target.value)}
                    />
                  </RowTableData>
                </Box>
                <Box
                  display={{
                    display: "flex",
                    direction: "row",
                    align: "flex-start",
                    justify: "flex-start",
                  }}
                >
                  <RowTableHead width="160px" height="304px">
                    <Box
                      display={{
                        display: "flex",
                      }}
                      size={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      이미지 등록&nbsp;
                      <Text
                        typo={{
                          size: "13px",
                          weight: "700",
                          color: "pink03",
                        }}
                      >
                        *
                      </Text>
                    </Box>
                  </RowTableHead>
                  <RowTableData
                    size={{
                      width: "calc(100% - 160px)",
                      height: "304px",
                    }}
                  >
                    <ImageInput
                      text1="- 권장 이미지 : 700px*700px / 10mb 이하 / jpg , png 파일"
                      text2="- 1장의 이미지만 등록 가능합니다."
                      image={
                        detailData
                          ? convertImage(detailData.popupThumbnail)
                          : popupThumbnail
                      }
                      onChange={handleProfile}
                    />
                  </RowTableData>
                </Box>
                <Box
                  display={{
                    width: "100%",
                    height: "249px",
                    display: "flex",
                    direction: "row",
                    align: "flex-start",
                    justify: "flex-start",
                  }}
                >
                  <RowTableHead width="160px" height="105px">
                    <Box
                      display={{
                        display: "flex",
                      }}
                      size={{
                        width: "100%",
                        height: "100%",
                      }}
                    >
                      링크&nbsp;
                      <Text
                        typo={{
                          size: "13px",
                          weight: "700",
                          color: "pink03",
                        }}
                      >
                        *
                      </Text>
                    </Box>
                  </RowTableHead>
                  <RowTableData
                    size={{
                      width: "calc(100% - 160px)",
                      height: "105px",
                    }}
                    padding={{
                      top: "8px",
                      left: "10px",
                      bottom: "10px",
                    }}
                  >
                    <Box>
                      <Box
                        display={{
                          display: "flex",
                        }}
                      >
                        <AdminRadio
                          id="link_used"
                          name="link_status"
                          htmlFor="link_used"
                          label="링크 사용"
                          checked={
                            detailData
                              ? detailData.linkUse === 1
                              : linkUse === 1
                          }
                          onClick={() => setLinkUse(1)}
                        />
                        <AdminRadio
                          id="link_unused"
                          name="link_status"
                          htmlFor="link_unused"
                          label="사용 안함"
                          checked={
                            detailData
                              ? detailData.linkUse === 0
                              : linkUse === 0
                          }
                          onClick={() => [
                            setLinkUse(0),
                            setPopupLink(""),
                            setNewWindow(0),
                          ]}
                        />
                      </Box>
                      <Input
                        theme={"adminInput"}
                        size={{
                          width: "395px",
                          height: "32px",
                        }}
                        margin={{
                          top: "8px",
                        }}
                        typo={{
                          size: "12px",
                        }}
                        background={{
                          color: linkUse === 1 ? "white" : "#F3F3F3",
                        }}
                        placeholder={
                          linkUse === 1
                            ? "팝업 클릭 시 이동할 링크를 입력해주세요."
                            : "링크 미설정 상태입니다."
                        }
                        value={popupLink}
                        onChange={(e) => setPopupLink(e.target.value)}
                        readOnly={linkUse === 0}
                      />
                      <Box
                        size={{
                          width: "90px",
                          height: "auto",
                        }}
                        display={{
                          display: "flex",
                          align: "center",
                          justify: "flex-start",
                        }}
                        margin={{
                          top: "9px",
                        }}
                      >
                        <AdminCheckbox
                          onClick={(e) => {
                            if (e.target.checked) {
                              setNewWindow(1); // 체크박스가 체크되면 newWindows를 1로 설정
                            } else {
                              setNewWindow(0); // 체크박스가 체크 해제되면 newWindows를 0으로 설정
                            }
                          }}
                          checked={newWindow === 1 ? true : false}
                          readOnly={linkUse === 0}
                        >
                          <Text
                            typo={{
                              size: "12px",
                              weight: "400",
                              color: "black02",
                            }}
                            margin={{
                              left: "3px",
                            }}
                          >
                            새창에서 열기
                          </Text>
                        </AdminCheckbox>
                      </Box>
                    </Box>
                  </RowTableData>
                </Box>
                <Box
                  display={{
                    display: "flex",
                    direction: "row",
                    align: "flex-start",
                    justify: "flex-start",
                  }}
                >
                  <RowTableHead width="160px" height="48px">
                    하단문구&nbsp;
                    <Text
                      typo={{
                        size: "13px",
                        weight: "700",
                        color: "pink03",
                      }}
                    >
                      *
                    </Text>
                  </RowTableHead>
                  <RowTableData
                    size={{
                      width: "calc(100% - 160px)",
                    }}
                  >
                    <Box
                      display={{
                        display: "flex",
                      }}
                      margin={{
                        top: "12px",
                      }}
                    >
                      <AdminRadio
                        id="bottom_used"
                        name="bottom_status"
                        htmlFor="bottom_used"
                        label="오늘 하루 열지 않기"
                        onClick={() => setBottomText(1)}
                        value={bottomText}
                        checked={bottomText === 1}
                      />
                      <AdminRadio
                        id="bottom_unused"
                        name="bottom_status"
                        htmlFor="bottom_unused"
                        label="일주일간 열지 않기"
                        onClick={() => setBottomText(0)}
                        value={bottomText}
                        checked={bottomText === 0}
                      />
                    </Box>
                  </RowTableData>
                </Box>
                <Box
                  display={{
                    display: "flex",
                    direction: "row",
                    align: "flex-start",
                    justify: "flex-start",
                  }}
                >
                  <RowTableHead width="160px" height="85px">
                    노출 기간&nbsp;
                    <Text
                      typo={{
                        size: "13px",
                        weight: "700",
                        color: "pink03",
                      }}
                    >
                      *
                    </Text>
                  </RowTableHead>
                  <RowTableData
                    size={{
                      width: "calc(100% - 160px)",
                    }}
                  >
                    <Box
                      display={{
                        display: "flex",
                      }}
                      margin={{
                        top: "12px",
                      }}
                    >
                      <AdminRadio
                        id="exposure_used"
                        name="exposure_status"
                        value="exposure_used"
                        htmlFor="exposure_used"
                        label="노출 기간 설정"
                        onClick={() => handleChangeExposure(1)}
                        checked={exposure === 1}
                      />
                      <AdminRadio
                        id="exposure_unused"
                        name="exposure_status"
                        value="exposure_unused"
                        htmlFor="exposure_unused"
                        label="노출 기간 미설정"
                        onClick={() => handleChangeExposure(0)}
                        checked={exposure === 0}
                      />
                    </Box>
                    {exposure === 1 && (
                      <Box
                        size={{
                          width: "100%",
                          height: "100%",
                        }}
                        display={{
                          display: "flex",
                          justify: "flex-start",
                          align: "center",
                        }}
                        margin={{
                          top: "10px",
                        }}
                      >
                        {/* 시작일 */}
                        <CalendarInput
                          value={startDate}
                          onChange={(date) => {
                            handleChangeDate(
                              startDate,
                              setStartDate
                            )(dayjs(date).format("YYYYMMDD"));
                          }}
                        />
                        <AdminTimePicker
                          value={startDate}
                          onChange={handleChangeTime(startDate, setStartDate)}
                        />
                        <Text
                          margin={{
                            left: "10px",
                            right: "10px",
                          }}
                        >
                          ~
                        </Text>
                        {/* 마감일 */}
                        <CalendarInput
                          value={endDate}
                          onChange={(date) => {
                            handleChangeDate(
                              endDate,
                              setEndDate
                            )(dayjs(date).format("YYYYMMDD"));
                          }}
                        />
                        <AdminTimePicker
                          value={endDate}
                          onChange={handleChangeTime(endDate, setEndDate)}
                        />
                      </Box>
                    )}
                  </RowTableData>
                </Box>
              </RowTable>
              <Box
                size={{
                  width: "100%",
                  height: "auto",
                }}
                display={{
                  display: "flex",
                }}
                margin={{
                  top: "24px",
                  left: "402px",
                }}
              >
                <AdminButton
                  category="button"
                  theme={["mainButton", "grayColor"]}
                  onClick={() => [setBannerEdit(!bannerEdit), resetForm()]}
                >
                  취소
                </AdminButton>
                <AdminButton
                  category="button"
                  theme={isDone ? ["mainButton"] : ["grayColor"]}
                  margin={{
                    left: "10px",
                  }}
                  size={{
                    width: "104px",
                    height: "40px",
                  }}
                  padding={{
                    top: "12px",
                    bottom: "12px",
                  }}
                  onClick={handleBannerSave}
                >
                  저장
                </AdminButton>
              </Box>{" "}
            </Box>
          </Box>
        ) : (
          <Box
            size={{
              width: "100%",
              height: "auto",
            }}
            margin={{
              top: "25px",
            }}
            other={{
              overflowX: "hidden",
            }}
          >
            <Text theme={"adminTitle"}>팝업 조회</Text>
            <Box
              display={{
                display: "flex",
              }}
            >
              <RowTable>
                <Box
                  display={{
                    width: "100%",
                    height: "49px",
                    display: "flex",
                    direction: "row",
                    align: "flex-start",
                    justify: "flex-start",
                  }}
                >
                  <RowTableHead width="160px" height="48px" isLastChild>
                    검색 분류
                  </RowTableHead>
                  <RowTableData
                    size={{
                      width: "calc(100% - 160px)",
                    }}
                    isLastChild
                  >
                    <Input
                      theme={"adminInput"}
                      size={{
                        width: "104px",
                        height: "32px",
                      }}
                      margin={{
                        top: "8px",
                        right: "4px",
                      }}
                      typo={{
                        size: "12px",
                      }}
                      background={{
                        color: "#F3F3F3",
                      }}
                      placeholder="제목"
                      readOnly
                    />
                    <Input
                      type="text"
                      value={inputValue}
                      onChange={handleInputChange}
                      theme={"adminInput"}
                      size={{
                        width: "500px",
                        height: "32px",
                      }}
                      margin={{
                        top: "8px",
                      }}
                    />
                  </RowTableData>
                </Box>
              </RowTable>
            </Box>
            <AdminButton
              onClick={handleSearch}
              category="button"
              theme="mainButton"
              margin={{
                top: "24px",
                left: "456px",
              }}
            >
              검색
            </AdminButton>
            {/* 팝업 목록 */}
            <Box
              size={{
                width: "100%",
                height: "auto",
              }}
              margin={{
                top: "40px",
              }}
            >
              <Text theme={"adminTitle"}>팝업 목록</Text>
              <Box
                size={{
                  width: "1024px",
                  height: "auto",
                }}
                border={{
                  top: "1px",
                  left: "1px",
                  right: "1px",
                  type: "solid",
                  color: "gray10",
                  radius: "3px 3px 0 0",
                }}
              >
                <Box
                  theme="adminListBox"
                  size={{
                    height: "44px",
                  }}
                  padding={{
                    left: "16px",
                  }}
                >
                  <Text
                    as="span"
                    typo={{
                      size: "13px",
                      weight: "500",
                      color: "black02",
                    }}
                  >
                    총{" "}
                    <Text
                      as="span"
                      typo={{
                        color: "blue01",
                      }}
                    >
                      {total}
                    </Text>{" "}
                    개
                  </Text>
                  <AdminDropdown
                    margin={{
                      right: "10px",
                    }}
                    size={{
                      width: "110px",
                      height: "28px",
                    }}
                    menuSize={{
                      width: "110px",
                      height: "auto",
                    }}
                    category={labels}
                    onSelect={changeSelectedList}
                  />
                </Box>
                <Box
                  theme="adminListBox"
                  size={{
                    height: "44px",
                  }}
                  padding={{
                    left: "8px",
                  }}
                  border={{
                    top: "1px",
                    style: "solid",
                    color: "gray10",
                  }}
                >
                  <AdminButton
                    category="button"
                    theme="listOptionButton"
                    size={{
                      width: "53px",
                      height: "28px",
                    }}
                    padding={{
                      left: "6px",
                      right: "6px",
                    }}
                    onClick={handleDelete}
                  >
                    <Image
                      src="/asset/img/admin/button/red_X.svg"
                      margin={{
                        right: "2px",
                      }}
                    />
                    삭제
                  </AdminButton>
                  <AdminButton
                    category="button"
                    size={{
                      width: "67px",
                      height: "28px",
                    }}
                    padding={{
                      left: "6px",
                      right: "6px",
                    }}
                    typo={{
                      size: "12px",
                      weight: "400",
                      color: "white01",
                    }}
                    margin={{
                      right: "12px",
                    }}
                    onClick={() => handleButtonEdit()}
                  >
                    팝업 등록
                  </AdminButton>
                </Box>
              </Box>
              {/* 팝업 목록 리스트 */}
              <Table isAttached={true} margin={{ bottom: "40px" }}>
                <thead>
                  <tr>
                    <TableHead width="42px" padding="8px 0">
                      <AdminCheckbox
                        margin={{
                          left: "13px",
                        }}
                        readOnly={data?.length === 0}
                        checked={
                          Object.keys(selectedUser).length === data?.length &&
                          Object.values(selectedUser).every(
                            (checked) => checked
                          )
                        }
                        onClick={handleSelectAll}
                      />
                    </TableHead>

                    <TableHead width="55px">
                      <Text
                        typo={{
                          color: "black02",
                        }}
                      >
                        NO.
                      </Text>
                    </TableHead>

                    <TableHead width="62px">
                      <Text
                        typo={{
                          color: "black02",
                        }}
                      >
                        상태
                      </Text>
                    </TableHead>

                    <TableHead width="184px">
                      <Text
                        typo={{
                          color: "black02",
                        }}
                      >
                        팝업 이미지
                      </Text>
                    </TableHead>

                    <TableHead width="186px">
                      <Text
                        typo={{
                          color: "black02",
                        }}
                      >
                        제목
                      </Text>
                    </TableHead>

                    <TableHead width="150px">
                      <Text
                        typo={{
                          color: "black02",
                        }}
                      >
                        시작일
                      </Text>
                    </TableHead>

                    <TableHead width="150px">
                      <Text
                        typo={{
                          color: "black02",
                        }}
                      >
                        종료일
                      </Text>
                    </TableHead>

                    <TableHead
                      width="calc(1024px - 42px - 55px - 62px - 184px - 186px - 150px -150px)"
                      isLastChild
                    >
                      <Text
                        typo={{
                          color: "black02",
                        }}
                      >
                        관리
                      </Text>
                    </TableHead>
                  </tr>
                </thead>
                <tbody>
                  {data?.map((data, index) => (
                    <tr key={data.popupId}>
                      <TableData size={{ height: "178px" }}>
                        <AdminCheckbox
                          margin={{
                            left: "13px",
                          }}
                          checked={selectedUser[data.popupId]}
                          onClick={(e) => handleSelect(e, data.popupId)}
                          value={data.popupId}
                        />
                      </TableData>
                      <TableData>
                        {total - (currentPage - 1) * pageSize - index}
                      </TableData>
                      <TableData>
                        {data.popupUse === 0 ? "사용안함" : "사용함"}
                      </TableData>
                      <TableData>
                        <Image
                          src={`${baseUrl}/${data.popupThumbnail}`}
                          size={{
                            width: "160px",
                            height: "160px",
                          }}
                          margin={{
                            top: "7px",
                          }}
                        />
                      </TableData>
                      <TableData>
                        <Text
                          typo={{
                            lineClamp: 2,
                            wordBreak: "break-all",
                          }}
                          padding={{
                            left: "8px",
                            right: "8px",
                          }}
                        >
                          {data.popupTitle}
                        </Text>
                      </TableData>
                      <TableData>
                        {data.expoSure === 1
                          ? dayjs(data.startDate).format("YYYY-MM-DD HH:mm")
                          : "-"}
                      </TableData>
                      <TableData>
                        {data.expoSure === 1
                          ? dayjs(data.endDate).format("YYYY-MM-DD HH:mm")
                          : "-"}
                      </TableData>
                      <TableData isLastChild>
                        <Box
                          display={{
                            display: "flex",
                          }}
                        >
                          <AdminButton
                            key={data.popupId}
                            category="button"
                            theme="whiteColor"
                            size={{
                              width: "72px",
                              height: "30px",
                            }}
                            typo={{
                              weight: "400",
                            }}
                            margin={{
                              left: "20px",
                            }}
                            onClick={() => handleBannerEdit(data.popupId)}
                          >
                            수정
                          </AdminButton>
                          <AdminButton
                            category="button"
                            theme="whiteColor"
                            size={{
                              width: "72px",
                              height: "30px",
                            }}
                            typo={{
                              weight: "400",
                            }}
                            margin={{
                              left: "10px",
                              right: "20px",
                            }}
                            onClick={() => [handleButtonDelete([data.popupId])]}
                          >
                            삭제
                          </AdminButton>
                        </Box>
                      </TableData>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Box>
            {total > 0 && (
              <AdminPagination
                totalPages={totalPages}
                currentPage={currentPage}
                onPageChange={handlePageChange}
              />
            )}
          </Box>
        )}
      </>
    )
  );
};

export default PopupManage;
