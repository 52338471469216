import { cheering } from "api/mypage";
import { useQuery } from "react-query";

const useCheering = ({ state, page }) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["cheering", state, page],
    () => cheering(state, page)
  );

  const list = data?.data;
  const maxPage = data?.totalPages;

  return {
    list,
    maxPage,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useCheering;
