import React, { useContext } from "react";
import { MenuContext } from "context";
import Box from "component/atoms/box";
import WordFiltering from "component/organisms/admin/setup/wordFiltering";
import NicknameFiltering from "component/organisms/admin/setup/nicknameFiltering";
import MarketingAgree from "component/organisms/admin/setup/marketing";
import SignUpTerms from "component/organisms/admin/setup/terms";
import PersonalInfoPolicy from "component/organisms/admin/setup/personalInfo";
import FooterManage from "component/organisms/admin/setup/footer";
import PersonalInfoAcceptance from "component/organisms/admin/setup/acceptance";
import StoryTerm from "component/organisms/admin/setup/storyTerm";
import RequestLink from "component/organisms/admin/setup/requestLink";

const AdminSetUp = () => {
  const { selectedMenu } = useContext(MenuContext);

  const renderContent = () => {
    switch (selectedMenu) {
      case "word":
        return <WordFiltering />;
      case "nickname":
        return <NicknameFiltering />;
      case "acceptance":
        return <PersonalInfoAcceptance />;
      case "terms":
        return <SignUpTerms />;
      case "personalInfo":
        return <PersonalInfoPolicy />;
      case "marketing":
        return <MarketingAgree />;
      case "footer":
        return <FooterManage />;
      case "storyTerm":
        return <StoryTerm />;
      case "request-link":
        return <RequestLink />;
      default:
        return <WordFiltering />;
    }
  };

  return (
    <Box
      size={{
        width: "100%",
        height: "auto",
      }}
      margin={{
        top: "25px",
      }}
    >
      {renderContent()}
    </Box>
  );
};

export default AdminSetUp;
