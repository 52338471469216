import Box from "component/atoms/box";
import Editor from "component/atoms/editor";
import Button from "component/atoms/button";
import Image from "component/atoms/image";
import Line from "component/atoms/line";
import Text from "component/atoms/text";
import CommonButton from "component/molecules/common/commonButton";
import LineTitle from "component/organisms/common/lineTitle";
import useCommentStoryForm from "hook/api/story/comment";
import useExternalToggle from "hook/util/useExternalToggle";
import LineBreak from "component/atoms/lineBreak";

const info =
  "비공개 프로젝트는 일반글을 올려도 다른 사용자가 볼 수 없습니다. \n\n- 일반글 : 이 글을 모든 사용자가 볼 수 있습니다. 댓글은 창안자와 아티스트만 가능합니다.\n- 비밀글 : 창안자와 아티스트만 이 글을 볼 수 있습니다. \n- 임시저장글 : 작성한 나만 이 글을 볼 수 있습니다.";

const StoryComment = () => {
  const {
    editorRef,
    type,
    isNotice,
    isActive,
    content,
    //
    setContent,
    noticeOn,
    noticeOff,
    setType,
    submit,
  } = useCommentStoryForm();

  // console.log(content);

  const [toggle, handleToggle] = useExternalToggle({
    className: ".info",
    max: 1,
  });

  return (
    <>
      <Box
        theme={"basic"}
        margin={{
          all: "64px auto 100px",
        }}
        longMobile={{
          margin: {
            all: "30px auto 70px",
          },
        }}
      >
        <LineTitle
          title="이야기 작성"
          longMobile={{
            margin: {
              bottom: "20px",
            },
          }}
        />
        <Text
          typo={{
            size: "26px",
            weight: "700",
          }}
          longMobile={{
            typo: {
              size: "16px",
            },
          }}
        >
          자유롭게 대화를 나눠보세요.
        </Text>
        <Text
          margin={{
            top: "8px",
            bottom: "32px",
          }}
          typo={{
            lineHeight: "1.63",
            color: "gray04",
          }}
          longMobile={{
            typo: {
              size: "14px",
            },
            margin: {
              top: "8px",
              bottom: "20px",
            },
          }}
        >
          창작, 영감을 나눠보세요.
          <LineBreak
            longMobile={{
              display: {
                display: "none",
              },
            }}
          />{" "}
          비방, 비하, 욕설 등 폭력적인 글은 삭제 대상이 될 수 있습니다.
        </Text>

        <Editor
          ref={editorRef}
          //
          content={content}
          setContent={setContent}
        />

        <Line
          direction="row"
          size="100%"
          margin={{
            all: "30px 0",
          }}
          background={{
            color: "black01",
          }}
          longMobile={{
            display: { display: "none" },
          }}
        />

        <Box
          display={{
            display: "inline-flex",
            justify: "space-between",
            align: "center",
          }}
          position={{
            type: "relative",
          }}
          longMobile={{
            margin: {
              top: "30px",
            },
          }}
        >
          <Text
            typo={{
              size: "18px",
              weight: "700",
              lineHeight: "1.223",
            }}
            longMobile={{
              typo: {
                size: "16px",
              },
            }}
          >
            새 글을 누구에게 보여줄까요?
          </Text>

          <Button
            display={{
              display: "flex",
              align: "center",
              justify: "center",
            }}
            size={{
              width: "18px",
              height: "18px",
            }}
            background={{
              color: "pink02",
            }}
            border={{
              all: "1px",
              radius: "50%",
            }}
            margin={{
              left: "5px",
            }}
            typo={{
              lineHeight: "16px",
              weight: "700",
              align: "center",
            }}
            onClick={handleToggle}
          >
            ?
          </Button>

          <Box
            display={{
              display: toggle ? "block" : "none",
            }}
            size={{
              width: "467px",
            }}
            position={{
              type: "absolute",
              top: "100%",
              left: "calc(100% - 18px)",
            }}
            padding={{
              all: "15px",
            }}
            background={{
              color: "white",
            }}
            border={{
              all: "1px",
              color: "gray02",
            }}
            className={"info"}
            longMobile={{
              size: {
                width: "235px",
              },
              position: {
                left: "94px",
              },
            }}
          >
            <Text
              typo={{
                size: "12px",
                whiteSpace: "pre-line",
              }}
              longMobile={{
                typo: {
                  size: "10px",
                },
              }}
            >
              {info}
            </Text>
          </Box>
        </Box>

        <Box
          display={{
            display: "flex",
            gap: "10px",
          }}
          margin={{
            top: "10px",
            bottom: "30px",
          }}
        >
          <CommonButton
            category={"button"}
            type={"button"}
            size={{
              width: "108px",
              height: "40px",
            }}
            isActive={type === "0"}
            onClick={() => setType("0")}
          >
            <Image
              src={"/asset/img/story/unlock.svg"}
              size={{
                width: "20px",
                height: "20px",
              }}
            />
            일반글
          </CommonButton>

          <CommonButton
            category={"button"}
            type={"button"}
            size={{
              width: "108px",
              height: "40px",
            }}
            isActive={type === "1"}
            onClick={() => setType("1")}
          >
            <Image
              src={"/asset/img/story/lock.svg"}
              size={{
                width: "20px",
                height: "20px",
              }}
            />
            비밀글
          </CommonButton>

          <CommonButton
            category={"button"}
            type={"button"}
            size={{
              width: "108px",
              height: "40px",
            }}
            isActive={type === "2"}
            onClick={() => setType("2")}
          >
            임시저장글
          </CommonButton>
        </Box>

        <Text
          typo={{
            size: "18px",
            weight: "700",
            lineHeight: "1.223",
          }}
          longMobile={{
            typo: {
              size: "16px",
            },
          }}
        >
          고정 게시글로 등록 할까요?
        </Text>

        <Box
          display={{
            display: "flex",
            gap: "10px",
          }}
          margin={{
            top: "10px",
            bottom: "60px",
          }}
        >
          <CommonButton
            category={"button"}
            type={"button"}
            size={{
              width: "108px",
              height: "40px",
            }}
            isActive={isNotice}
            onClick={noticeOn}
          >
            네
          </CommonButton>

          <CommonButton
            category={"button"}
            type={"button"}
            size={{
              width: "108px",
              height: "40px",
            }}
            isActive={!isNotice}
            onClick={noticeOff}
          >
            아니요
          </CommonButton>
        </Box>

        <CommonButton
          category={"button"}
          size={{
            width: "100%",
            height: "85px",
          }}
          typo={{
            size: "18px",
          }}
          longMobile={{
            size: {
              height: "48px",
            },
            typo: {
              size: "14px",
            },
          }}
          isActive={isActive}
          isDisabled={!isActive}
          onClick={submit}
        >
          이야기 작성하기
        </CommonButton>
      </Box>
    </>
  );
};

export default StoryComment;
