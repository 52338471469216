import { adminFind } from "api/admin/auth";
import useUrl from "hook/util/useUrl";
import { useMutation, useQueryClient } from "react-query";

export const useAdminFind = (onSuccess, onError) => {
  const changeLink = useUrl();
  const mutation = useMutation((email) => adminFind({ email }), {
    onSuccess: () => {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
      changeLink("/admin/login");
    },
    onError: (error) => {
      if (error.response.status === 403) {
        alert("관리자 계정이 아닙니다.");
      }
      if (error.response.status === 404) {
        alert("존재하지 않는 회원이거나 이메일이 정확하지 않습니다.");
      }
      if (error.response.status === 500) {
        alert("서버 오류입니다. 잠시 후 다시 시도해주세요.");
      }
    },
  });
  return mutation;
};
