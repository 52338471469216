import {
  createBrowserRouter,
  Outlet,
  ScrollRestoration,
  useNavigate,
} from "react-router-dom";
import CONST from "../const";
import Main from "page/main";
import Detail from "page/detail";
import NotFound from "page/notfound";
import Login from "page/login";
import SignUp from "page/signup";
import Story from "page/story";
import Exhibition from "page/exhibition";
import Find from "page/find";
import MyPage from "page/mypage";
import StoryWrite from "page/storyWrite";
import StoryComment from "page/storyComment";
import Observer from "component/atoms/observer";
import Terms from "page/terms";
import BasicTemplate from "component/templates/basic";
import ScrollToTop from "component/atoms/scrollTop";
import AdminLogin from "page/admin/login";
import DashBoard from "page/admin/dashBoard";
import AdminLayout from "component/templates/admin";
import AdminSetUp from "page/admin/setUp";
import ManageUser from "page/admin/user";
import ManageBanner from "page/admin/banner";
import ManageStory from "page/admin/story";
import ManageUserDetail from "page/admin/userDetail";
import ManageStoryDetail from "page/admin/storyDetail";
import { MenuProvider } from "provider";
import { AuthProvider } from "context";
import useUrl from "hook/util/useUrl";

const getQueries = (list, url) => {
  const queries = {};
  for (const key of list) {
    const value = url.searchParams.get(key);
    if (value) {
      queries[key] = value;
    }
  }

  return queries;
};

const createRouter = () => {
  return createBrowserRouter([
    {
      path: "",
      element: (
        <BasicTemplate>
          <ScrollToTop />
          <Observer />
          <Outlet />
        </BasicTemplate>
      ),
      children: [
        {
          path: "/",
          title: CONST.TITLE,
          element: <Main />,
        },
        {
          path: "/story",
          element: <Story />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(
              [
                "filter",
                "keyword",
                "category",
                "mood",
                "status",
                "storyState",
                "page",
                "option",
                "commentPage",
              ],
              url
            );
          },
        },
        {
          path: "/story/write",
          element: <StoryWrite />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["storyId"], url);
          },
        },
        {
          path: "/story/:id",
          title: "story detail",
          element: <Detail />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["comment", "page", "type"], url);
          },
        },
        {
          path: "/story/:id/comment",
          element: <StoryComment />,
        },
        {
          path: "/login",
          title: "login",
          element: <Login />,
        },
        {
          path: "/signup",
          title: "signup",
          element: <SignUp />,
        },
        {
          path: "/exhibition",
          title: "exhibition",
          element: <Exhibition />,
        },
        {
          path: "/mypage",
          title: "mypage",
          element: <MyPage />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["page", "category", "detail", "sort"], url);
          },
        },
        {
          path: "/mypage/:id",
          title: "mypage",
          element: <MyPage />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["page", "category", "detail"], url);
          },
        },
        {
          path: "/find",
          title: "find",
          element: <Find />,
        },
        {
          path: "/terms",
          title: "terms",
          element: <Terms />,
          loader: ({ request }) => {
            const url = new URL(request.url);
            return getQueries(["category"], url);
          },
        },
      ],
    },
    {
      path: "/admin",
      element: (
        <MenuProvider>
          <AuthProvider>
            <AdminLayout>
              <Outlet />
            </AdminLayout>
          </AuthProvider>
        </MenuProvider>
      ),

      children: [
        {
          path: "login",
          title: "admin login",
          element: <AdminLogin />,
          // loader: ({ request }) => {
          //   const url = new URL(request.url);
          //   return getQueries(["category"], url);
          // },
        },
        {
          path: "dashboard",
          title: "dashboard",
          element: <DashBoard />,
          // loader: ({ request }) => {
          //   const url = new URL(request.url);
          //   return getQueries(["category"], url);
          // },
        },
        {
          path: "setup",
          title: "setup",
          element: <AdminSetUp />,
          // loader: ({ request }) => {
          //   const url = new URL(request.url);
          //   return getQueries(["category"], url);
          // },
        },
        {
          path: "manage/user",
          title: "user management",
          element: <ManageUser />,
          // loader: ({ request }) => {
          //   const url = new URL(request.url);
          //   return getQueries(["category"], url);
          // },
        },
        {
          path: "manage/user/detail/:id",
          title: "user management detail",
          element: <ManageUserDetail />,
          // loader: ({ request }) => {
          //   const url = new URL(request.url);
          //   return getQueries(["category"], url);
          // },
        },
        {
          path: "manage/story",
          title: "story management",
          element: <ManageStory />,
          // loader: ({ request }) => {
          //   const url = new URL(request.url);
          //   return getQueries(["category"], url);
          // },
        },
        {
          path: "manage/story/detail/:id",
          title: "story management detail",
          element: <ManageStoryDetail />,
          loader: ({ request }) => {
            const url = new URL(request.url);

            return getQueries(
              [
                "page",
                "startDate",
                "endDate",
                "memberName",
                "memberEmail",
                "total",
                "menu",
                "category",
                "pageSize",
              ],
              url
            );
          },
        },
        {
          path: "manage/banner",
          title: "banner management",
          element: <ManageBanner />,
          // loader: ({ request }) => {
          //   const url = new URL(request.url);
          //   return getQueries(["category"], url);
          // },
        },
      ],
    },
    {
      // 404 page
      path: "*",
      title: "404",
      element: (
        <BasicTemplate>
          <NotFound />
        </BasicTemplate>
      ),
    },
  ]);
};

export const Router = createRouter();
