import styled from "styled-components";

export const TableHeadWrapper = styled.th`
  width: ${(props) => props.width};
  height: ${({ height }) => height || "32px"};
  padding: ${({ padding }) => padding || "12px 17px"};
  display: flex;
  align-items: center;
  font-size: 13px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black02};
  text-align: center;

  border-top: ${({ isLastChild, theme }) =>
    isLastChild ? "none" : `1px solid ${theme.colors.gray10}`};

  border-right: ${({ theme }) => `1px solid ${theme.colors.gray10}`};

  background-color: ${({ isPink, theme }) =>
    isPink ? theme.colors.pink01 : theme.colors.gray09};
`;
