import { story } from "api/main";
import { useQuery } from "react-query";

const useLatest = () => {
  const { data, isLoading, isError, isSuccess } = useQuery(["latest"], story);

  return {
    data: data?.data,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useLatest;
