import Box from "component/atoms/box";
import Line from "component/atoms/line";
import ArrowButton from "component/molecules/common/arrowButton";
import Title from "component/molecules/detail/title";
import useUrl from "hook/util/useUrl";
import Text from "component/atoms/text";
import NoticeIcons from "component/molecules/detail/noticeIcons";
import { BsChevronRight } from "react-icons/bs";
import LineTitle from "component/organisms/common/lineTitle";
import Link from "component/atoms/link";
import Api from "component/molecules/common/api";
import useLatestStory from "hook/api/story/latest";
import { useState } from "react";
import Content from "component/molecules/detail/content";

const StoryList = (props) => {
  const { isPass } = props;
  const {
    params,
    query: { comment, page = 1 },
    changeQuery,
  } = useUrl();

  return (
    <Api
      api={useLatestStory}
      params={{
        storyId: params.id,
        boardId: comment,
        page,
      }}
    >
      {(data) =>
        data && (
          <>
            {data.data.length === 0 && (
              <Link
                display={{
                  display: "flex",
                  align: "center",
                }}
                margin={{ all: "0 auto" }}
                link={`/story/${params.id}#${comment}`}
              >
                <Text
                  typo={{
                    size: "14px",
                  }}
                >
                  목록보기
                </Text>
                <BsChevronRight size={15} />
              </Link>
            )}
            {data.data.length !== 0 && (
              <Box
                longMobile={{
                  margin: {
                    top: "50px",
                  },
                }}
              >
                <LineTitle title={"게시글 보기"}>
                  <Link
                    display={{
                      display: "flex",

                      align: "center",
                    }}
                    link={`/story/${params.id}#${comment}`}
                  >
                    <Text
                      typo={{
                        size: "14px",
                      }}
                    >
                      목록보기
                    </Text>
                    <BsChevronRight size={15} />
                  </Link>
                </LineTitle>

                <Box
                  display={{
                    display: "grid",
                    gridTemplateColumns: "repeat(4, 1fr)",
                    gap: "40px",
                  }}
                  tablet={{
                    display: {
                      gridTemplateColumns: "repeat(2, 1fr)",
                      gap: "20px",
                    },
                  }}
                  longMobile={{
                    display: {
                      gap: "10px 8px",
                    },
                  }}
                >
                  {data.data.map(
                    ({
                      boardId,
                      memberId,
                      name,
                      number,
                      type,
                      storyboardContent,
                      storyboardFixed,
                      storyboardOpen,
                      createdAt,
                    }) => {
                      const isBlind =
                        (storyboardOpen === 1 || storyboardOpen === 2) &&
                        !isPass;

                      return (
                        <Link
                          className="moreList"
                          size={{
                            width: "100%",
                            aspectRatio: "1/1",
                          }}
                          border={{
                            all: "1px",
                          }}
                          padding={{
                            all: "18px 8px 23px",
                          }}
                          position={{
                            type: "relative",
                          }}
                          longMobile={{
                            padding: {
                              all: "10px 7px 35px",
                            },
                          }}
                          disabled={storyboardOpen === 1}
                          link={`/story/${params.id}?page=${page}&comment=${boardId}`}
                          key={boardId}
                        >
                          <Box
                            margin={{
                              all: "0 16px",
                            }}
                            longMobile={{
                              margin: {
                                all: "0 2px",
                              },
                            }}
                          >
                            <Title
                              name={name}
                              type={type}
                              date={createdAt}
                              isDetail
                              memberId={memberId}
                            />
                          </Box>

                          <Line
                            direction={"row"}
                            size={"100%"}
                            margin={{ all: "24px 0" }}
                            background={{ color: "gray02" }}
                            longMobile={{
                              margin: { all: "6px 0 10px" },
                            }}
                          />

                          <Box
                            size={{
                              width: "100%",
                              height: "calc(100% - 90px)",
                            }}
                            padding={{
                              all: "0 16px",
                            }}
                            other={{
                              overflowX: "hidden",
                              overflowY: "hidden",
                            }}
                            longMobile={{
                              size: {
                                height: "80px",
                              },
                              padding: {
                                all: "0 4px",
                              },
                            }}
                            typo={{
                              wordBreak: "break-all !important",
                              size: "14px",
                            }}
                            className={"commentList"}
                          >
                            {!isBlind && (
                              <Text
                                margin={{
                                  bottom: "10px",
                                }}
                                typo={{
                                  size: "14px",
                                  weight: "700",
                                }}
                              >
                                #{number}
                              </Text>
                            )}
                            <Content
                              content={storyboardContent}
                              isDetail
                              isLock={isBlind}
                              id={comment}
                            />
                          </Box>

                          {storyboardFixed && <NoticeIcons />}
                        </Link>
                      );
                    }
                  )}
                </Box>

                {data.totalItems > 4 && (
                  <Box
                    display={{
                      display: "flex",
                      justify: "center",
                      gap: "40px",
                    }}
                    margin={{
                      top: "30px",
                    }}
                    longMobile={{
                      display: {
                        gap: "8px",
                      },
                      margin: {
                        top: "15px",
                      },
                    }}
                  >
                    <ArrowButton
                      type={"left"}
                      size={28}
                      onClick={() =>
                        Number(page) - 1 >= 1 &&
                        changeQuery({ page: Number(page) - 1 })
                      }
                    />
                    <ArrowButton
                      type={"right"}
                      size={28}
                      onClick={() =>
                        Number(page) + 1 <= data.totalPages &&
                        changeQuery({ page: Number(page) + 1 })
                      }
                    />
                  </Box>
                )}
              </Box>
            )}
          </>
        )
      }
    </Api>
  );
};

export default StoryList;
