import { GET, PUT } from "api/config";

export const getRequestLink = async () => await GET("/admin/request/link");

export const getNewsLetterLink = async () =>
  await GET("/admin/newsletter/link");

export const updateRequestLink = async (data) =>
  await PUT("/admin/request/link", data);

export const updateNewsLetterLink = async (data) =>
  await PUT("/admin/newsletter/link", data);
