import { GET, POST, PUT } from "api/config";

export const getJudgingList = async (
  state = "",
  startDate = "",
  endDate = "",
  musicName = "",
  memberEmail = "",
  total = "",
  pageSize = "",
  currentPage = "1"
) =>
  await GET(
    `/admin/story/list/creation?state=${state}&startDate=${startDate}&endDate=${endDate}&musicName=${musicName}&memberEmail=${memberEmail}&total=${total}&pageSize=${pageSize}&page=${currentPage}`
  );

export const approveMusic = async (creationId) => {
  await POST(`/admin/story/list/creation/approve?creationIds=${creationId}`);
};

export const pendingMusic = async (creationId, rejectReason) => {
  const data = {
    rejectReason: rejectReason,
  };
  await POST(`/admin/story/list/creation/hold/${creationId}`, data);
};
