import { replyList } from "api/story";
import { useQuery } from "react-query";

const useReplyList = ({ storyId, boardId, page = 1 }) => {
  const { data, isSuccess, isLoading, isError } = useQuery(
    ["replyList", storyId, boardId, page],
    () => replyList(storyId, boardId, page)
  );

  const list = data?.data;
  // const maxPage = data?.totalPages;

  return { list, isSuccess, isLoading, isError };
};

export default useReplyList;
