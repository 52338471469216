import Box from "component/atoms/box";
import Button from "component/atoms/button";
import Image from "component/atoms/image";
import LineBreak from "component/atoms/lineBreak";
import Text from "component/atoms/text";
import Api from "component/molecules/common/api";
import CommonButton from "component/molecules/common/commonButton";
import LabelText from "component/molecules/common/labelText";
import CheckboxField from "component/organisms/common/checkboxField";
import InputField from "component/organisms/common/inputField";
import LineTitle from "component/organisms/common/lineTitle";
import ThumbnailField from "component/organisms/common/thumbnailField";
import Select from "component/organisms/storyWrite/select";
import useTermsStory from "hook/api/main/termsStory";
import useWriteStoryForm from "hook/api/story/write";
import useCheckUser from "hook/util/useCheckUser";
import useToggle from "hook/util/useToggle";
import useUrl from "hook/util/useUrl";
import { FormProvider } from "react-hook-form";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import useDeviceStore from "store/useDeviceStore";
import convertImage from "utils/convertImage";

const StoryWrite = () => {
  const { form, isActive, handleSubmit } = useWriteStoryForm();
  const { toggle: toggleStep1, handleToggle: handleStep1 } = useToggle();
  const { toggle: toggleStep2, handleToggle: handleStep2 } = useToggle();
  const { toggle: toggleStep3, handleToggle: handleStep3 } = useToggle();
  const {
    query: { storyId },
  } = useUrl();

  useCheckUser();

  const storyTitle = form.watch("storyTitle");
  const storySummary = form.watch("storySummary");
  const storyPersonaLike = form.watch("personaLike");
  const storyPersonaDislike = form.watch("personaDislike");
  const storyPersonaName = form.watch("personaName");

  const storyOpen = form.watch("storyOpen");
  const thumbnail = form.watch("storyThumbnail");

  const updateStoryOpen = (value) => {
    form.setValue("storyOpen", value);
  };

  const { mobile } = useDeviceStore();

  return (
    <>
      <FormProvider {...form}>
        <Box
          margin={{
            all: "64px auto 100px",
          }}
          mobile={{
            margin: {
              all: "24px auto 100px",
            },
          }}
          theme={"basic"}
        >
          <LineTitle
            title={"이야기 등록"}
            typo={{
              size: "28px",
            }}
          />

          <Box
            padding={{
              all: "20px 24px",
            }}
            margin={{
              bottom: "70px",
            }}
            border={{
              all: "1px",
            }}
            other={{
              boxShadow: "4px 4px",
            }}
          >
            <Text
              typo={{
                size: "24px",
                weight: "700",
              }}
              longMobile={{
                typo: {
                  size: "16px",
                },
              }}
            >
              이야기 과정 안내
            </Text>
            <Text
              typo={{
                size: "14px",
              }}
              margin={{
                top: "5px",
              }}
            >
              이야기를 등록하면 아티스트와 매칭 후 창작 활동이 시작됩니다.
            </Text>
          </Box>

          <Button
            display={{
              display: "none",
              justify: "space-between",
              align: "center",
            }}
            size={{
              width: "100%",
            }}
            background={{
              color: "transparent",
            }}
            mobile={{
              display: {
                display: "flex",
              },
            }}
            onClick={handleStep1}
          >
            <Text
              typo={{
                size: "20px",
                weight: "700",
                fontFamily: "Spoqa Han Sans Neo",
              }}
            >
              STEP 1.
            </Text>
            {!toggleStep1 ? <BsChevronUp /> : <BsChevronDown />}
          </Button>
          <Box
            size={{
              width: "100%",
              height: !mobile || !toggleStep1 ? "auto" : "0px",
            }}
            other={{
              overflowY: "hidden",
            }}
          >
            <LineTitle
              margin={{
                bottom: "0",
              }}
              title={
                <Text
                  as={"span"}
                  mobile={{
                    typo: {
                      weight: "400",
                    },
                  }}
                >
                  <Text
                    as={"span"}
                    margin={{
                      right: "5px",
                    }}
                    mobile={{
                      display: {
                        display: "none",
                      },
                    }}
                  >
                    STEP 1.{" "}
                  </Text>
                  이야기 설문
                </Text>
              }
            />

            <Box
              margin={{
                top: "30px",
              }}
              longMobile={{
                margin: {
                  top: "20px",
                },
              }}
            >
              <LabelText
                display={{
                  justify: "flex-start",
                }}
                title={"1. 카테고리"}
                typo={{
                  size: "18px",
                }}
                info={
                  <>
                    <Text
                      display={{
                        display: "flex",
                        align: "center",
                      }}
                      as={"span"}
                      typo={{
                        size: "12px",
                        color: "pink02",
                      }}
                      margin={{
                        left: "5px",
                      }}
                    >
                      *최대 1개
                    </Text>
                    <Text
                      as={"span"}
                      display={{
                        display: "block",
                      }}
                      size={{
                        width: "100%",
                      }}
                      typo={{
                        size: "16px",
                        color: "gray04",
                        weight: "400",
                        lineHeight: "1.38",
                      }}
                      padding={{
                        top: "4px",
                        bottom: "8px",
                      }}
                      mobile={{
                        typo: {
                          size: "14px",
                        },
                      }}
                    >
                      내 이야기를 가장 잘 나타내는 카테고리를 선택해주세요.
                    </Text>
                  </>
                }
              />

              <Select name="storyCategory" length={10} />
            </Box>

            <Box
              margin={{
                top: "30px",
              }}
              longMobile={{
                margin: {
                  top: "50px",
                },
              }}
            >
              <LabelText
                display={{
                  justify: "flex-start",
                }}
                title={"2. 분위기"}
                typo={{
                  size: "18px",
                }}
                info={
                  <>
                    <Text
                      display={{
                        display: "flex",
                        align: "center",
                      }}
                      as={"span"}
                      typo={{
                        size: "12px",
                        color: "pink02",
                      }}
                      margin={{
                        left: "5px",
                      }}
                    >
                      *최대 2개
                    </Text>
                    <Text
                      as={"span"}
                      display={{
                        display: "block",
                      }}
                      size={{
                        width: "100%",
                      }}
                      typo={{
                        size: "16px",
                        color: "gray04",
                        weight: "400",
                        lineHeight: "1.38",
                      }}
                      padding={{
                        top: "4px",
                        bottom: "8px",
                      }}
                      mobile={{
                        typo: {
                          size: "14px",
                        },
                      }}
                    >
                      내 이야기는 어떤 분위기의 음악이 어울릴까요?
                    </Text>
                  </>
                }
              />
              <Select name="storyMood" length={10} />
            </Box>

            <Box
              margin={{
                top: "30px",
              }}
              longMobile={{
                margin: {
                  top: "50px",
                },
              }}
            >
              <InputField
                as={"input"}
                type={"text"}
                title={"3. 제목"}
                name={"storyTitle"}
                size={{
                  width: "100%",
                  height: "40px",
                }}
                typo={{
                  size: "18px",
                }}
                maxLength={40}
                info={
                  <Box
                    display={{
                      display: "flex",
                      justify: "space-between",
                    }}
                    size={{
                      width: "100%",
                    }}
                  >
                    <Text
                      as={"span"}
                      display={{
                        display: "block",
                      }}
                      typo={{
                        size: "14px",
                        color: "gray04",
                        weight: "400",
                        lineHeight: "1.38",
                      }}
                      padding={{
                        top: "4px",
                        bottom: "8px",
                      }}
                      mobile={{
                        typo: {
                          size: "14px",
                        },
                      }}
                    >
                      사람들이 관심을 가질 수 있도록 제목을 정해주세요.
                    </Text>
                    <Text
                      typo={{
                        size: "14px",
                        color: "gray04",
                        weight: "400",
                        lineHeight: "1.38",
                      }}
                      padding={{
                        top: "4px",
                        bottom: "8px",
                      }}
                      mobile={{
                        position: {
                          type: "absolute",
                          top: "100px",
                          right: "0",
                        },
                        typo: {
                          size: "14px",
                        },
                      }}
                    >
                      ({storyTitle.length}/40)
                    </Text>
                  </Box>
                }
              />
            </Box>

            <Box
              margin={{
                top: "30px",
              }}
              longMobile={{
                margin: {
                  top: "50px",
                },
              }}
            >
              <InputField
                as={"input"}
                type={"text"}
                title={"4. 한 줄 요약"}
                name={"storySummary"}
                size={{
                  width: "100%",
                  height: "40px",
                }}
                typo={{
                  size: "18px",
                }}
                maxLength={45}
                info={
                  <Box
                    display={{
                      display: "flex",
                      justify: "space-between",
                    }}
                    size={{
                      width: "100%",
                    }}
                  >
                    <Text
                      as={"span"}
                      display={{
                        display: "block",
                      }}
                      typo={{
                        size: "14px",
                        color: "gray04",
                        weight: "400",
                        lineHeight: "1.38",
                      }}
                      padding={{
                        top: "4px",
                        bottom: "8px",
                      }}
                      mobile={{
                        typo: {
                          size: "14px",
                        },
                      }}
                    >
                      어떤 이야기인지 설명할 수 있는 짧은 글을 적어주세요.
                    </Text>
                    <Text
                      typo={{
                        size: "14px",
                        color: "gray04",
                        weight: "400",
                        lineHeight: "1.38",
                      }}
                      padding={{
                        top: "4px",
                        bottom: "8px",
                      }}
                      mobile={{
                        position: {
                          type: "absolute",
                          top: "100px",
                          right: "0",
                        },
                        typo: {
                          size: "14px",
                        },
                      }}
                    >
                      ({storySummary.length}/45)
                    </Text>
                  </Box>
                }
              />
            </Box>

            <Box
              margin={{
                top: "30px",
                bottom: "70px",
              }}
            >
              <ThumbnailField
                title={"5. 프로젝트 섬네일"}
                name={"thumbnail"}
                thumbnail={convertImage(thumbnail, true)}
                size={{
                  width: "270px",
                  height: "270px",
                  aspectRatio: "1/1",
                }}
                typo={{
                  size: "18px",
                }}
                info={
                  <Text
                    as={"span"}
                    display={{
                      display: "block",
                    }}
                    size={{
                      width: "100%",
                    }}
                    typo={{
                      size: "14px",
                      color: "gray04",
                      weight: "400",
                      lineHeight: "1.38",
                    }}
                    padding={{
                      top: "4px",
                      bottom: "8px",
                    }}
                    mobile={{
                      typo: {
                        size: "14px",
                      },
                    }}
                  >
                    프로젝트를 잘 보여주는 정사각형 비율의 섬네일을
                    업로드해주세요.
                  </Text>
                }
                desc={
                  <>
                    <Text
                      margin={{
                        top: "6px",
                      }}
                      typo={{
                        size: "12px",
                        color: "gray04",
                      }}
                      mobile={{
                        display: {
                          display: "none",
                        },
                      }}
                    >
                      - 권장 이미지: 1080px*1080px / jpg,png 파일
                    </Text>
                    <Text
                      display={{
                        display: "none",
                      }}
                      margin={{
                        bottom: "6px",
                      }}
                      typo={{
                        size: "10px",
                        color: "gray04",
                      }}
                      mobile={{
                        display: {
                          display: "block",
                        },
                      }}
                    >
                      권장 이미지
                      <br />: 1080px*1080px / jpg,png 파일
                    </Text>
                  </>
                }
              />
            </Box>
          </Box>

          <Button
            display={{
              display: "none",
              justify: "space-between",
              align: "center",
            }}
            size={{
              width: "100%",
            }}
            background={{
              color: "transparent",
            }}
            mobile={{
              display: {
                display: "flex",
              },
            }}
            onClick={handleStep2}
          >
            <Text
              typo={{
                size: "20px",
                weight: "700",
                fontFamily: "Spoqa Han Sans Neo",
              }}
            >
              STEP 2.
            </Text>
            {!toggleStep2 ? <BsChevronUp /> : <BsChevronDown />}
          </Button>
          <Box
            size={{
              width: "100%",
              height: !mobile || !toggleStep2 ? "auto" : "0px",
            }}
            other={{
              overflowY: "hidden",
            }}
          >
            <LineTitle
              margin={{
                bottom: "0",
              }}
              title={
                <Text
                  as={"span"}
                  mobile={{
                    typo: {
                      weight: "400",
                    },
                  }}
                >
                  <Text
                    as={"span"}
                    margin={{
                      right: "5px",
                    }}
                    mobile={{
                      display: {
                        display: "none",
                      },
                    }}
                  >
                    STEP 2.
                  </Text>
                  내 이야기에 생명을 불어넣을
                  <LineBreak
                    display={{
                      display: "none",
                    }}
                    mobile={{
                      display: {
                        display: "inline-block",
                      },
                    }}
                  />
                  '페르소나' 설정
                </Text>
              }
            >
              <Text
                typo={{
                  size: "14px",
                  weight: "400",
                  lineHeight: "1.5",
                }}
              >
                ‘페르소나’는 이야기 속 가상의 인물입니다. 이야기의 주인공이 어떤
                성격일지 상상해보세요. 그리고 그 주인공의 입장에서 내가 하고
                싶은 말을 누군가에게 편지 쓰듯 적어보는 거예요.
              </Text>
            </LineTitle>

            <Box
              margin={{
                top: "30px",
              }}
              size={{
                maxWidth: "968px",
              }}
              longMobile={{
                margin: {
                  top: "20px",
                },
              }}
            >
              <LabelText
                display={{
                  justify: "flex-start",
                }}
                title={"1. 성격"}
                typo={{
                  size: "18px",
                }}
                info={
                  <Text
                    as={"span"}
                    display={{
                      display: "block",
                    }}
                    size={{
                      width: "100%",
                    }}
                    typo={{
                      size: "14px",
                      color: "gray04",
                      weight: "400",
                      lineHeight: "1.38",
                    }}
                    padding={{
                      top: "4px",
                      bottom: "8px",
                    }}
                    mobile={{
                      typo: {
                        size: "14px",
                      },
                    }}
                  >
                    페르소나를 표현할 수 있는 말을 선택해보세요.
                  </Text>
                }
              />

              <Select name="storyPersonality" length={8} />
            </Box>

            <Box
              margin={{
                top: "30px",
              }}
              longMobile={{
                margin: {
                  top: "50px",
                },
              }}
            >
              <InputField
                as={"input"}
                type={"text"}
                title={"2. 좋아하는 것"}
                name={"personaLike"}
                size={{
                  width: "100%",
                  height: "40px",
                }}
                typo={{
                  size: "18px",
                }}
                maxLength={65}
                info={
                  <Box
                    display={{
                      display: "flex",
                      justify: "space-between",
                    }}
                    size={{
                      width: "100%",
                    }}
                  >
                    <Text
                      as={"span"}
                      display={{
                        display: "block",
                      }}
                      typo={{
                        size: "14px",
                        color: "gray04",
                        weight: "400",
                        lineHeight: "1.38",
                      }}
                      padding={{
                        top: "4px",
                        bottom: "8px",
                      }}
                      mobile={{
                        typo: {
                          size: "14px",
                        },
                      }}
                    >
                      페르소나는 어떤 것들을 좋아하나요?
                    </Text>
                    <Text
                      typo={{
                        size: "14px",
                        color: "gray04",
                        weight: "400",
                        lineHeight: "1.38",
                      }}
                      padding={{
                        top: "4px",
                        bottom: "8px",
                      }}
                      mobile={{
                        position: {
                          type: "absolute",
                          top: "100px",
                          right: "0",
                        },
                        typo: {
                          size: "14px",
                        },
                      }}
                    >
                      ({storyPersonaLike.length}/65)
                    </Text>
                  </Box>
                }
              />
            </Box>

            <Box
              margin={{
                top: "30px",
              }}
              longMobile={{
                margin: {
                  top: "50px",
                },
              }}
            >
              <InputField
                as={"input"}
                type={"text"}
                title={"3. 싫어하는 것"}
                name={"personaDislike"}
                size={{
                  width: "100%",
                  height: "40px",
                }}
                typo={{
                  size: "18px",
                }}
                maxLength={65}
                info={
                  <Box
                    display={{
                      display: "flex",
                      justify: "space-between",
                    }}
                    size={{
                      width: "100%",
                    }}
                  >
                    <Text
                      as={"span"}
                      display={{
                        display: "block",
                      }}
                      typo={{
                        size: "14px",
                        color: "gray04",
                        weight: "400",
                        lineHeight: "1.38",
                      }}
                      padding={{
                        top: "4px",
                        bottom: "8px",
                      }}
                      mobile={{
                        typo: {
                          size: "14px",
                        },
                      }}
                    >
                      페르소나는 어떤 것들을 싫어하나요?
                    </Text>

                    <Text
                      typo={{
                        size: "14px",
                        color: "gray04",
                        weight: "400",
                        lineHeight: "1.38",
                      }}
                      padding={{
                        top: "4px",
                        bottom: "8px",
                      }}
                      mobile={{
                        position: {
                          type: "absolute",
                          top: "100px",
                          right: "0",
                        },
                        typo: {
                          size: "14px",
                        },
                      }}
                    >
                      ({storyPersonaDislike.length}/65)
                    </Text>
                  </Box>
                }
              />
            </Box>

            <Box
              margin={{
                top: "30px",
                bottom: "70px",
              }}
              longMobile={{
                margin: {
                  top: "50px",
                  bottom: "70px",
                },
              }}
            >
              <InputField
                as={"input"}
                type={"text"}
                title={"4. 페르소나 이름"}
                name={"personaName"}
                size={{
                  width: "100%",
                  height: "40px",
                }}
                typo={{
                  size: "18px",
                }}
                maxLength={65}
                info={
                  <Box
                    display={{
                      display: "flex",
                      justify: "space-between",
                    }}
                    size={{
                      width: "100%",
                    }}
                  >
                    <Text
                      as={"span"}
                      display={{
                        display: "block",
                      }}
                      typo={{
                        size: "14px",
                        color: "gray04",
                        weight: "400",
                        lineHeight: "1.38",
                      }}
                      padding={{
                        top: "4px",
                        bottom: "8px",
                      }}
                      mobile={{
                        typo: {
                          size: "14px",
                        },
                      }}
                    >
                      본명 또는 필명도 좋아요. 이야기 속 페르소나의 이름을 지어
                      불러 주는 순간, 캐릭터는 생명을 부여받는 답니다.
                    </Text>
                    <Text
                      typo={{
                        size: "14px",
                        color: "gray04",
                        weight: "400",
                        lineHeight: "1.38",
                      }}
                      padding={{
                        top: "4px",
                        bottom: "8px",
                      }}
                      mobile={{
                        position: {
                          type: "absolute",
                          top: "120px",
                          right: "0",
                        },
                        typo: {
                          size: "14px",
                        },
                      }}
                    >
                      ({storyPersonaName.length}/65)
                    </Text>
                  </Box>
                }
              />
            </Box>
          </Box>

          <Button
            display={{
              display: "none",
              justify: "space-between",
              align: "center",
            }}
            size={{
              width: "100%",
            }}
            background={{
              color: "transparent",
            }}
            mobile={{
              display: {
                display: "flex",
              },
            }}
            onClick={handleStep3}
          >
            <Text
              typo={{
                size: "20px",
                weight: "700",
                fontFamily: "Spoqa Han Sans Neo",
              }}
            >
              STEP 3.
            </Text>
            {!toggleStep3 ? <BsChevronUp /> : <BsChevronDown />}
          </Button>
          <Box
            size={{
              width: "100%",
              height: !mobile || !toggleStep3 ? "auto" : "0px",
            }}
            other={{
              overflowY: "hidden",
            }}
          >
            <LineTitle
              margin={{
                bottom: "30px",
              }}
              title={
                <Text
                  as={"span"}
                  mobile={{
                    typo: {
                      weight: "400",
                    },
                  }}
                >
                  <Text
                    as={"span"}
                    margin={{
                      right: "5px",
                    }}
                    mobile={{
                      display: {
                        display: "none",
                      },
                    }}
                  >
                    STEP 3.{" "}
                  </Text>
                  프로젝트 설정
                </Text>
              }
            >
              <Text
                size={{
                  width: "100%",
                }}
                typo={{
                  size: "14px",
                }}
              >
                프로젝트의 공개/비공개 여부를 선택해주세요.
              </Text>
            </LineTitle>

            <LabelText
              title={"1. 프로젝트 공개 여부"}
              typo={{
                size: "18px",
                weight: "700",
              }}
            />
            <Box
              display={{
                display: "flex",
                gap: "10px",
              }}
              margin={{
                bottom: "30px",
              }}
              longMobile={{
                margin: {
                  bottom: "20px",
                },
              }}
            >
              <CommonButton
                category={"button"}
                type={"button"}
                size={{
                  width: "91px",
                  height: "40px",
                }}
                typo={{
                  size: "14px",
                  weight: "700",
                }}
                isActive={storyOpen === "0"}
                onClick={() => updateStoryOpen("0")}
              >
                <Image src={"/asset/img/story/unlock.svg"} />
                공개
              </CommonButton>
              <CommonButton
                category={"button"}
                type={"button"}
                size={{
                  width: "104px",
                  height: "40px",
                }}
                typo={{
                  size: "14px",
                  weight: "700",
                }}
                isActive={storyOpen === "1"}
                onClick={() => updateStoryOpen("1")}
              >
                <Image src={"/asset/img/story/lock.svg"} />
                비공개
              </CommonButton>
            </Box>

            <Api api={useTermsStory}>
              {(data) =>
                !storyId && (
                  <CheckboxField
                    name={"isAgree"}
                    title={"2. 약관동의"}
                    typo={{
                      size: "18px",
                      weight: "700",
                    }}
                    optionList={[
                      [
                        {
                          text: "동의합니다.",
                          value: true,
                        },
                      ],
                    ]}
                    size={{
                      width: "20px",
                      height: "20px",
                    }}
                    info={
                      <Box
                        size={{
                          width: "100%",
                          height: "160px",
                        }}
                        margin={{
                          top: "16px",
                        }}
                        padding={{
                          all: "16px",
                        }}
                        other={{
                          overflowY: "scroll",
                        }}
                        border={{
                          all: "1px",
                          style: "solid",
                          color: "black01",
                        }}
                        longMobile={{
                          margin: {
                            top: "5px",
                          },
                          padding: {
                            all: "12px",
                          },
                        }}
                      >
                        {/* <Text
                          typo={{
                            // size: "14px",
                            // color: "gray04",
                            weight: "400",
                            whiteSpace: "pre-line",
                            lineHeight: "1.86",
                          }}
                        > */}
                        <div
                          dangerouslySetInnerHTML={{
                            __html: data?.content,
                          }}
                          className="editor-content"
                        />
                        {/* </Text> */}
                      </Box>
                    }
                  />
                )
              }
            </Api>
          </Box>

          <Box
            margin={{
              top: "70px",
            }}
            display={{
              display: "flex",
              justify: "center",
            }}
          >
            <CommonButton
              category={"button"}
              size={{
                width: "100%",
                height: "85px",
              }}
              typo={{
                size: "18px",
              }}
              mobile={{
                size: {
                  width: "100%",
                  height: "48px",
                },
                typo: {
                  size: "14px",
                },
              }}
              isDisabled={!isActive}
              isActive={isActive}
              onClick={handleSubmit}
            >
              이야기 {storyId ? "수정" : "작성"}하기
            </CommonButton>
          </Box>
        </Box>
      </FormProvider>
    </>
  );
};

export default StoryWrite;
