import PropTypes from "prop-types";
import Input from "component/atoms/input";
import Image from "component/atoms/image";
import Box from "component/atoms/box";
import { useEffect, useState } from "react";
import useUrl from "hook/util/useUrl";
import Option from "component/atoms/option";
import Select from "component/atoms/select";
import Line from "component/atoms/line";
import Button from "component/atoms/button";

const SearchBar = (props) => {
  const { keyword } = props;

  const [search, setSearch] = useState("");
  const [filter, setFilter] = useState("all");
  const { changeQuery } = useUrl();

  const handleChange = (e) => {
    if (e.target.value.length > 20) return;

    setSearch(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();

    changeQuery({
      keyword: search,
      filter,
      page: 1,
    });
    // setSearch("");
  };

  useEffect(() => {
    setSearch(keyword);
  }, [keyword]);

  return (
    <Box
      as="form"
      display={{
        display: "flex",
        align: "center",
      }}
      size={{
        width: "100%",
        height: "50px",
      }}
      position={{
        type: "relative",
      }}
      border={{
        all: "1px",
        style: "solid",
        color: "#000",
      }}
      onSubmit={handleSearch}
    >
      <Select
        size={{
          width: "106px",
          height: "100%",
        }}
        padding={{
          all: "0 12px",
        }}
        border={{
          all: "0px",
        }}
        typo={{
          size: "14px",
        }}
        value={filter}
        onChange={(e) => setFilter(e.target.value)}
      >
        <Option value={"all"}>전체</Option>
        <Option value={"artist"}>아티스트</Option>
        <Option value={"title"}>제목</Option>
        <Option value={"writer"}>창안자</Option>
      </Select>
      <Line direction={"col"} size={"20px"} background={{ color: "black01" }} />
      <Input
        size={{
          width: "calc(100% - 106px - 16px)",
          height: "100%",
        }}
        padding={{
          left: "8px",
        }}
        border={{
          all: "0px",
        }}
        background={{
          color: "transparent",
        }}
        typo={{
          size: "14px",
        }}
        placeholder={"키워드를 입력해주세요."}
        value={search}
        onChange={handleChange}
      />
      <Button
        size={{
          width: "auto",
          height: "100%",
        }}
        display={{
          display: "flex",
          justify: "center",
          align: "center",
        }}
        type={"submit"}
        padding={{
          all: "0 18px",
        }}
      >
        <Image
          src="/asset/img/common/search.png"
          alt="검색 버튼"
          size={{
            width: "24px",
            height: "24px",
          }}
          other={{
            cursor: "pointer",
          }}
        />
      </Button>
    </Box>
  );
};

SearchBar.propTypes = {
  height: PropTypes.string,
};

export default SearchBar;
