import React, { useContext } from "react";
import { MenuContext } from "context";
import MainBannerManage from "component/organisms/admin/banner/mainBanner";
import SubBannerManage from "component/organisms/admin/banner/subBanner";
import PopupManage from "component/organisms/admin/banner/popup";
import Box from "component/atoms/box";

const ManageBanner = () => {
  const { selectedMenu } = useContext(MenuContext);

  const renderContent = () => {
    switch (selectedMenu) {
      case "mainBanner":
        return <MainBannerManage />;
      case "subBanner":
        return <SubBannerManage />;
      case "popup":
        return <PopupManage />;
      default:
        return <MainBannerManage />;
    }
  };

  return (
    <Box
      size={{
        width: "100%",
        height: "auto",
      }}
      margin={{
        top: "25px",
      }}
    >
      {renderContent()}
    </Box>
  );
};

export default ManageBanner;
