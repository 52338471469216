import { useEffect } from "react";
import useEditStore from "store/useEditStore";

const useCheckEdit = () => {
  const { isEdit, clearEdit } = useEditStore();

  const checkEdit =
    (callback) =>
    (...link) => {
      if (isEdit) {
        if (!window.confirm("작성중인 정보가 있습니다. 이동하시겠습니까?")) {
          return;
        }
      }

      callback(...link);
      clearEdit();
    };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      e.preventDefault(); // 표준에 따라 기본 동작을 방지
      window.confirm("작성중인 정보가 있습니다. 이동하시겠습니까?");
      window.event.returnValue = ""; // Chrome에서는 returnValue 설정이 필요함
      e.returnValue = ""; // 대부분의 브라우저에서는 사용자 지정 메시지를 무시하고 기본 메시지를 표시
      clearEdit();
    };

    if (isEdit) {
      window.addEventListener("beforeunload", handleBeforeUnload);
    }

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, [isEdit]);

  return checkEdit;
};

export default useCheckEdit;
