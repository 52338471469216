import Box from "component/atoms/box";
import Text from "component/atoms/text";
import SearchBar from "component/molecules/common/searchBar";
import SortFilterSelect from "component/molecules/detail/sortFilterSelect";
import StoryCard from "component/organisms/common/storyCard";
import Data from "./data.json";
import CategoryView from "component/organisms/common/categoryView";
import LineTitle from "component/organisms/common/lineTitle";
import List from "component/molecules/common/list";
import useStoryList from "hook/api/story/list";
import useUrl from "hook/util/useUrl";
import { useEffect } from "react";
import useToggle from "hook/util/useToggle";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import Button from "component/atoms/button";
import useDeviceStore from "store/useDeviceStore";

const convertStoryState = (storyState) => {
  storyState = storyState.replace("이야기", "1");
  storyState = storyState.replace("기획전", "2");

  return storyState;
};

const Story = () => {
  const {
    query: {
      page = 1,
      keyword = "",
      filter,
      category,
      mood,
      status,
      state,
      option,
      storyState = "",
    },
    hash,
  } = useUrl();

  const { toggle, handleToggle } = useToggle(!option);
  const { longMobile } = useDeviceStore();

  useEffect(() => {
    if (hash) {
      const id = hash.replace("#", "");
      const target = document.getElementById(id);
      if (target) {
        const yOffset = -110;

        const y =
          target.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [hash, page]);

  return (
    <>
      <Box
        as={"section"}
        theme={"basic"}
        margin={{
          all: "64px auto 100px",
        }}
      >
        <LineTitle title={"창작 콘텐츠 검색"} direction={"row"}>
          <Button onClick={handleToggle}>
            {!toggle ? <BsChevronUp size={20} /> : <BsChevronDown size={20} />}
          </Button>
        </LineTitle>
        {!toggle && (
          <>
            <Text
              typo={{
                size: "18px",
                weight: "700",
              }}
              margin={{
                bottom: "12px",
              }}
              longMobile={{
                typo: {
                  size: "16px",
                  weight: "700",
                },
              }}
            >
              키워드 검색
            </Text>
            <SearchBar height="38px" fontSize="15px" keyword={keyword} />
            <Box
              display={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "30px 40px",
              }}
              margin={{
                top: "32px",
                bottom: "77px",
              }}
              longMobile={{
                display: {
                  gridTemplateColumns: "repeat(1, 1fr)",
                  gap: "24px",
                },
              }}
            >
              <CategoryView
                categoryTitle="카테고리"
                categoryName="category"
                categoryList={Data.categoryList}
                defaultSelectList={[]}
              />
              <CategoryView
                categoryTitle="분위기"
                categoryName="mood"
                categoryList={Data.moodList}
                defaultSelectList={[]}
              />

              <CategoryView
                categoryTitle="등록상태"
                categoryName="status"
                categoryList={Data.statusList}
                defaultSelectList={[]}
              />

              <CategoryView
                categoryTitle="콘텐츠 유형"
                categoryName="storyState"
                categoryList={Data.storyState}
                defaultSelectList={[]}
              />
            </Box>
          </>
        )}
        <LineTitle id={"list"} title={"창작 콘텐츠 탐색"}>
          <Box id={"list"} />
          <SortFilterSelect name="state" options={Data.sortFilterOptionList} />
        </LineTitle>

        <List
          api={useStoryList}
          params={{
            page,
            keyword,
            filter,
            category,
            mood,
            status,
            state,
            storyState: convertStoryState(storyState),
            limit: longMobile ? 10 : 16,
          }}
        >
          {(list) =>
            list && (
              <Box
                theme={"storyGrid"}
                margin={{
                  top: "65px",
                }}
                pc={{
                  margin: {
                    top: "40px",
                  },
                }}
              >
                {list.map((item) => (
                  <StoryCard {...item} key={item.storyId} />
                ))}
              </Box>
            )
          }
        </List>
      </Box>
    </>
  );
};

export default Story;
