import Box from "component/atoms/box";
import Button from "component/atoms/button";
import Image from "component/atoms/image";
import Link from "component/atoms/link";
import dayjs from "dayjs";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import useDeviceStore from "store/useDeviceStore";

import convertImage from "utils/convertImage";

const addHttpsUsingRegex = (url) => {
  if (/^www\./.test(url)) {
    return "https://" + url;
  }
  return url;
};

const Popup = ({
  position,
  popupThumbnail,
  popupId,
  bottomText,
  popupLink,
  newWindows,
  linkUse,
}) => {
  const [isShow, setIsShow] = useState(false);
  const { mobile } = useDeviceStore();

  const handleClosePopupPeriod = () => {
    const curr = dayjs();

    if (bottomText === 1) {
      const nextDay = curr.add(1, "day").format("YYYY-MM-DD");
      localStorage.setItem(popupId, nextDay);
    } else {
      const nextWeek = curr.add(7, "day").format("YYYY-MM-DD");
      localStorage.setItem(popupId, nextWeek);
    }

    handleClosePopup();
  };

  const handleClosePopup = () => {
    setIsShow(false);
  };

  useEffect(() => {
    const showInfo = localStorage.getItem(popupId);

    if (showInfo) {
      const curr = dayjs();
      const isShow = dayjs(showInfo).isAfter(curr);

      setIsShow(!isShow);
    } else {
      setIsShow(!showInfo);
    }
  }, [popupId]);

  return (
    <Box
      display={{
        display: isShow ? "block" : "none",
      }}
      position={{
        type: "fixed",
        zIndex: "2",
        top: "50%",
        left: "50%",
        isCenter: true,
      }}
      border={{
        all: "1px",
        color: "#dfdfdf",
      }}
      typo={{
        size: "0",
      }}
    >
      <Link
        link={linkUse === 1 ? addHttpsUsingRegex(popupLink) : "/"}
        target={newWindows === 1 ? "_blank" : "_self"}
        size={{
          width: "100%",
        }}
      >
        <Image
          src={convertImage(popupThumbnail)}
          alt={"popup"}
          size={{
            width: mobile ? "calc(100vw - 24px)" : "500px",
            aspectRatio: "1/1",
          }}
          background={{
            color: "white",
          }}
        />
      </Link>
      <Box
        display={{
          display: "grid",
          gridTemplateColumns: "1fr 1fr",
          gap: "1px",
        }}
        border={{
          top: "1px",
          color: "#DFDFDF",
        }}
        background={{
          color: "#dfdfdf",
        }}
      >
        <Button
          size={{
            width: "100%",
            height: "40px",
          }}
          background={{
            color: "#fff",
          }}
          typo={{
            size: "12px",
          }}
          onClick={handleClosePopupPeriod}
        >
          {bottomText === 1 ? "오늘 하루 열지 않기" : "일주일간 열지 않기"}
        </Button>
        <Button
          size={{
            width: "100%",
            height: "40px",
          }}
          background={{
            color: "#fff",
          }}
          typo={{
            size: "12px",
          }}
          onClick={handleClosePopup}
        >
          닫기
        </Button>
      </Box>
    </Box>
  );
};

export default Popup;
