import React from "react";
import { TableWrapper } from "./table.style";
const Table = (props) => {
  const { children, margin, isAttached, ref } = props;

  return (
    <TableWrapper
      ref={ref}
      $margin={margin}
      isAttached={isAttached}
      $border={{
        all: "1px",
        style: "solid",
        color: "gray10",
        radius: isAttached ? "0 0 3px 3px" : "3px",
      }}
    >
      {children}
    </TableWrapper>
  );
};

export default Table;
