import React, { useContext } from "react";
import { MenuContext } from "context";
import { useParams } from "react-router-dom";
import UserPortfolio from "component/organisms/admin/userDetail/portfolio";
import UserBasicDetail from "component/organisms/admin/userDetail/basicDetail";
import UserCheeringDetail from "component/organisms/admin/userDetail/cheeringDetail";
import UserArtistDetail from "component/organisms/admin/userDetail/artistDetail";
import Box from "component/atoms/box";

const ManageUserDetail = () => {
  const { id } = useParams();
  const { selectedMenu } = useContext(MenuContext);

  const renderContent = () => {
    switch (selectedMenu) {
      case "basicDetail":
        return <UserBasicDetail />;
      case "cheeringDetail":
        return <UserCheeringDetail />;
      case "artistDetail":
        return <UserArtistDetail />;
      case "portfolio":
        return <UserPortfolio />;
      default:
        return <UserBasicDetail />;
    }
  };
  return (
    <Box
      size={{
        width: "100%",
        height: "auto",
      }}
      margin={{
        top: "25px",
      }}
    >
      {renderContent()}
    </Box>
  );
};

export default ManageUserDetail;
