import { popup } from "api/main";
import { useQuery } from "react-query";

const usePopup = () => {
  const { data, isLoading, isSuccess, isError } = useQuery("popup", popup);

  return {
    data: data?.data,
    isLoading,
    isSuccess,
    isError,
  };
};

export default usePopup;
