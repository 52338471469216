import Input from "component/atoms/input";
import RowTable from "component/atoms/rowSideTable";
import RowTableData from "component/atoms/rowTableData";
import RowTableHead from "component/atoms/rowTableHead";
import Text from "component/atoms/text";
import React, { useEffect, useState } from "react";
import ImageInput from "../../imageInput";
import AdminRadio from "component/molecules/admin/adminRadio";
import AdminCheckbox from "component/molecules/adminCheckbox";
import AdminButton from "component/molecules/admin/button/adminButton";
import AdminAlertModal from "../../modal/alertModal";
import Select from "component/atoms/select";
import Option from "component/atoms/option";
import Box from "component/atoms/box";
import { useUpdateUserDetail, useUserDetail } from "hook/api/admin/userDetail";

const UserArtistDetail = () => {
  const url = new URL(window.location.href);
  const pathSegments = url.pathname.split("/");
  const memberId = pathSegments[pathSegments.length - 1];

  const { data: data } = useUserDetail(memberId);

  const handleSuccess = () => {
    setVisible(true);
  };

  const handleError = () => {
    alert("회원 정보 수정에 실패하였습니다.");
  };

  // 아티스트 정보 수정
  const { mutate } = useUpdateUserDetail(memberId, handleSuccess, handleError);
  // 저장 알림 모달창 상태 관리
  const [visible, setVisible] = useState(false);
  // 저장 버튼 클릭 시
  const [save, setSave] = useState(false);
  // 회원 정보 수정 상태 관리
  const [userInfoEdit, setUserInfoEdit] = useState(false);

  // 회원 등급 select
  const [userType, setUserType] = useState("일반회원");

  const handleTypeChange = (e) => {
    setUserType(e.target.value);
  };

  // const [artistName, setArtistName] = useState(data?.artistName);
  const [artistIntro, setArtistIntro] = useState(data?.artistIntro);
  const [memberPhone, setMemberPhone] = useState(data?.memberPhone);

  // 회원 정보 저장 버튼 클릭 시
  const handleInfoSave = () => {
    const formData = new FormData();
    if (window.confirm("아티스트 정보를 수정하시겠습니까?")) {
      // formData.append("artistName", artistName);
      formData.append("artistIntro", artistIntro);
      formData.append("memberPhone", memberPhone);
      mutate(formData);
    } else {
      return;
    }
  };

  const handleInfoEdit = () => {
    setUserInfoEdit(!userInfoEdit);
  };

  useEffect(() => {
    if (data) {
      // setArtistName(data.artistName);
      setArtistIntro(data.artistIntro);
      setMemberPhone(data.memberPhone);
    }
  }, [data]);

  return (
    <>
      <AdminAlertModal
        title={"아티스트 정보 수정"}
        text="아티스트 정보 수정이 완료되었습니다."
        btn_text="확인"
        visible={visible}
        toggle={() => {
          setVisible(false);
        }}
      />
      <Box
        size={{
          width: "100%",
          height: "auto",
        }}
        margin={{
          top: "25px",
        }}
        other={{
          overflowX: "hidden",
        }}
      >
        <Text theme={"adminTitle"}>아티스트 정보 수정</Text>
        <RowTable>
          <Box
            theme="adminListBox"
            padding={{
              left: "21px",
            }}
          >
            <Text
              typo={{
                size: "12px",
                weight: "700",
                color: "pink03",
              }}
            >
              * 은 필수 입력 항목입니다.
            </Text>
          </Box>
          <Box
            display={{
              width: "100%",
              height: "52px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            {/* <RowTableHead width="160px" height="48px">
              아티스트 활동명
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 160px)",
              }}
            >
              <Input
                theme={"adminInput"}
                size={{
                  width: "240px",
                  height: "32px",
                }}
                margin={{
                  top: "8px",
                }}
                value={artistName}
                onChange={(e) => setArtistName(e.target.value)}
                readOnly={data?.authority !== "ROLE_ARTIST" ? true : false}
                placeholder={
                  data?.authority !== "ROLE_ARTIST"
                    ? "아티스트 회원이 아닙니다."
                    : ""
                }
              />
            </RowTableData> */}
          </Box>
          <Box
            display={{
              width: "100%",
              height: "52px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="160px" height="48px">
              연락처
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 160px)",
              }}
            >
              <Input
                theme={"adminInput"}
                size={{
                  width: "240px",
                  height: "32px",
                }}
                margin={{
                  top: "8px",
                }}
                value={memberPhone}
                onChange={(e) => setMemberPhone(e.target.value)}
                readOnly={data?.authority !== "ROLE_ARTIST" ? true : false}
                placeholder={
                  data?.authority !== "ROLE_ARTIST"
                    ? "아티스트 회원이 아닙니다."
                    : ""
                }
              />
            </RowTableData>
          </Box>
          <Box
            display={{
              width: "100%",
              height: "52px",
              display: "flex",
              direction: "row",
              align: "flex-start",
              justify: "flex-start",
            }}
          >
            <RowTableHead width="160px" height="224px">
              <Box
                size={{
                  height: "100%",
                }}
              >
                아티스트 소개
              </Box>
            </RowTableHead>
            <RowTableData
              size={{
                width: "calc(100% - 160px)",
              }}
            >
              <Input
                as={"textarea"}
                theme={"adminInput"}
                size={{
                  width: "839px",
                  height: "200px",
                }}
                margin={{
                  top: "12px",
                }}
                value={artistIntro}
                onChange={(e) => setArtistIntro(e.target.value)}
                readOnly={data?.authority !== "ROLE_ARTIST" ? true : false}
                placeholder={
                  data?.authority !== "ROLE_ARTIST"
                    ? "아티스트 회원이 아닙니다."
                    : ""
                }
              />
            </RowTableData>
          </Box>
        </RowTable>
        <Box
          size={{
            width: "100%",
            height: "auto",
          }}
          display={{
            display: "flex",
          }}
          margin={{
            top: "24px",
            left: "484px",
          }}
        >
          <AdminButton
            category="button"
            theme={["mainButton"]}
            margin={{
              left: "10px",
            }}
            onClick={handleInfoSave}
          >
            저장
          </AdminButton>
        </Box>
      </Box>
    </>
  );
};

export default UserArtistDetail;
