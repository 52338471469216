import { create } from "zustand";

const useEditStore = create((set) => ({
  isEdit: false,

  updateEdit: () => set({ isEdit: true }),
  clearEdit: () => set({ isEdit: false }),
}));

export default useEditStore;
