import Box from "component/atoms/box";
import Text from "component/atoms/text";
import Input from "component/atoms/input";
import useLoginForm from "hook/api/auth/login";
import CommonButton from "component/molecules/common/commonButton";
import Line from "component/atoms/line";
import Link from "component/atoms/link";
import { BsChatFill } from "react-icons/bs";
import { SiNaver } from "react-icons/si";
import KakaoLogin from "component/atoms/kakao/KakaoLogin";
import GoogleLogin from "component/atoms/google/GoogleLogin";
import Image from "component/atoms/image";
import axios from "axios";
import useSns from "hook/api/auth/sns";
import NaverLogin from "react-login-by-naver";

const Login = () => {
  const { email, password, error, isActive, handleSubmit } = useLoginForm({
    defaultValues: {
      id: "",
      password: "",
    },
  });

  const snsMutation = useSns();

  const handleKakaoLogin = async ({ access_token }) => {
    const {
      data: { kakao_account },
    } = await axios.get("https://kapi.kakao.com/v2/user/me", {
      headers: {
        Authorization: `Bearer ${access_token}`,
      },
    });

    const {
      email,
      profile: { nickname },
    } = kakao_account;

    snsMutation({
      email,
      name: nickname,
      socialProvider: "S001",
    });
  };

  const handleNaverLogin = ({ email, name }) => {
    snsMutation({
      email,
      name: name,
      socialProvider: "S002",
    });
  };

  const handleGoogleLogin = async ({ access_token }) => {
    const {
      data: { email, name },
    } = await axios.get(
      `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${access_token}`
    );

    snsMutation({
      email,
      name: name,
      socialProvider: "S003",
    });
  };

  return (
    <>
      <Box
        as={"section"}
        size={{
          height: "calc(100vh - (98px + 60px))",
        }}
        display={{
          display: "flex",
          justify: "center",
          align: "center",
        }}
        padding={{
          bottom: "calc((98px + 60px)/2)",
        }}
        longMobile={{
          size: {
            height: "auto",
          },
          padding: {
            all: "0 18px 73px",
          },
        }}
      >
        <Box
          display={{
            display: "flex",
            direction: "column",
          }}
          size={{
            width: "580px",
          }}
          margin={{
            top: "70px",
          }}
          longMobile={{
            top: "0px",
          }}
        >
          <Text
            typo={{
              size: "28px",
              weight: "700",
              align: "center",
            }}
            longMobile={{
              typo: {
                size: "20px",
              },
            }}
          >
            로그인
          </Text>

          <Box
            margin={{
              top: "40px",
            }}
            longMobile={{
              margin: {
                top: "20px",
              },
            }}
          >
            <Text
              typo={{
                size: "18px",
                weight: "700",
              }}
              margin={{
                bottom: "8px",
              }}
              longMobile={{
                typo: {
                  size: "16px",
                },
              }}
            >
              이메일
            </Text>
            <Input
              size={{
                width: "100%",
                height: "50px",
              }}
              border={{
                all: "1px",
                style: "solid",
                color: "black01",
              }}
              longMobile={{
                size: {
                  width: "100%",
                  height: "40px",
                },
              }}
              {...email}
            />
          </Box>
          <Box
            position={{
              type: "relative",
            }}
            margin={{
              top: "20px",
              bottom: "40px",
            }}
            longMobile={{
              margin: {
                top: "20px",
                bottom: "30px",
              },
            }}
          >
            <Text
              typo={{
                size: "18px",
                weight: "700",
              }}
              margin={{
                bottom: "8px",
              }}
              longMobile={{
                typo: {
                  size: "16px",
                },
              }}
            >
              비밀번호
            </Text>
            <Input
              size={{
                width: "100%",
                height: "50px",
              }}
              border={{
                all: "1px",
                style: "solid",
                color: "black01",
              }}
              type="password"
              longMobile={{
                size: {
                  width: "100%",
                  height: "40px",
                },
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleSubmit();
                }
              }}
              {...password}
            />
            <Text
              position={{
                type: "absolute",
                top: "calc(100% + 10px)",
              }}
              typo={{
                size: "12px",
                weight: "400",
                color: "red01",
              }}
            >
              {error && error}
            </Text>
          </Box>

          <CommonButton
            category={"button"}
            type={"button"}
            size={{
              width: "100%",
              height: "50px",
            }}
            isDisabled={!isActive}
            isActive={isActive}
            typo={{
              size: "18px",
              weight: "700",
            }}
            longMobile={{
              typo: {
                size: "16px",
              },
            }}
            onClick={handleSubmit}
          >
            로그인
          </CommonButton>

          <Box
            display={{
              display: "flex",
              justify: "center",
              align: "center",
            }}
            margin={{
              top: "30px",
            }}
          >
            <Link
              link="/find"
              typo={{
                size: "12px",
                weight: "400",
                color: "gray04",
              }}
            >
              아이디/비밀번호 찾기
            </Link>

            <Line
              direction="col"
              size="15px"
              margin={{
                all: "0 10px",
              }}
              background={{
                color: "gray04",
              }}
            />

            <Link
              link="/signup"
              typo={{
                size: "12px",
                weight: "400",
                color: "gray04",
              }}
            >
              회원가입
            </Link>
          </Box>

          <Box
            display={{
              display: "flex",
              justify: "center",
              align: "center",
              gap: "10px",
            }}
            margin={{
              top: "50px",
              bottom: "24px",
            }}
            longMobile={{
              margin: {
                top: "40px",
                bottom: "13px",
              },
            }}
          >
            <Line
              direction={"row"}
              size={"calc((100% - 65px - 20px) / 2)"}
              background={{
                color: "gray02",
              }}
            />
            <Text
              typo={{
                size: "14px",
                color: "gray04",
              }}
            >
              간편로그인
            </Text>
            <Line
              direction={"row"}
              size={"calc((100% - 65px - 20px) / 2)"}
              background={{
                color: "gray02",
              }}
            />
          </Box>
          <KakaoLogin
            token={"e2f51737f27eb13bd8bee293b2eaed49"}
            success={handleKakaoLogin}
            render={() => (
              <Box
                position={{
                  type: "relative",
                }}
                display={{
                  display: "flex",
                  justify: "center",
                  align: "center",
                  gap: "10px",
                }}
                size={{
                  width: "100%",
                  height: "48px",
                }}
                background={{
                  color: "kakao01",
                }}
                typo={{
                  size: "15px",
                  weight: "700",
                }}
                other={{
                  cursor: "pointer",
                }}
              >
                <Box
                  position={{
                    type: "absolute",
                    left: "24px",
                    isCenterY: true,
                  }}
                  typo={{
                    size: "0",
                  }}
                >
                  <BsChatFill size={18} />
                </Box>
                <Text>카카오톡 로그인</Text>
              </Box>
            )}
          />
          <NaverLogin
            clientId="TerUBS1An7q4h4ObJlVx"
            callbackUrl={`${window.location.origin}/login`}
            onSuccess={handleNaverLogin}
            render={(props) => (
              <Box
                position={{
                  type: "relative",
                }}
                display={{
                  display: "flex",
                  justify: "center",
                  align: "center",
                  gap: "20px",
                }}
                size={{
                  width: "100%",
                  height: "48px",
                }}
                background={{
                  color: "naver01",
                }}
                margin={{
                  top: "10px",
                }}
                typo={{
                  size: "15px",
                  weight: "700",
                  color: "white01",
                }}
                other={{
                  cursor: "pointer",
                }}
                onClick={props.onClick}
              >
                <Box
                  position={{
                    type: "absolute",
                    left: "24px",
                    isCenterY: true,
                  }}
                  typo={{
                    size: "0",
                  }}
                >
                  <Image
                    size={{
                      width: "20px",
                      height: "20px",
                    }}
                    src={"/asset/img/login/naver.svg"}
                  />
                </Box>
                <Text
                  typo={{
                    color: "white01",
                  }}
                >
                  네이버 로그인
                </Text>
              </Box>
            )}
          />

          <GoogleLogin
            onSuccess={handleGoogleLogin}
            token={
              "291305455127-71t8pvu9c7tk1dib3lif2crbgt5m2ee9.apps.googleusercontent.com"
            }
            render={() => (
              <Box
                position={{
                  type: "relative",
                }}
                display={{
                  display: "flex",
                  justify: "center",
                  align: "center",
                  gap: "20px",
                }}
                size={{
                  width: "100%",
                  height: "48px",
                }}
                background={{
                  color: "white01",
                }}
                border={{
                  all: "1px",
                  color: "gray02",
                }}
                margin={{
                  top: "10px",
                }}
                other={{
                  cursor: "pointer",
                }}
              >
                <Box
                  position={{
                    type: "absolute",
                    left: "22px",
                    isCenterY: true,
                  }}
                  typo={{
                    size: "0",
                  }}
                >
                  <Image
                    size={{
                      width: "20px",
                      height: "20px",
                    }}
                    src={"/asset/img/login/google.svg"}
                  />
                </Box>
                <Text
                  typo={{
                    size: "15px",
                    weight: "700",
                  }}
                >
                  구글 로그인
                </Text>
              </Box>
            )}
          />
        </Box>
      </Box>
    </>
  );
};

export default Login;
