import Box from "component/atoms/box";
import Input from "component/atoms/input";
import AdminRadio from "component/molecules/admin/adminRadio";
import RowTable from "component/atoms/rowSideTable";
import RowTableData from "component/atoms/rowTableData";
import RowTableHead from "component/atoms/rowTableHead";
import Text from "component/atoms/text";
import React, { useEffect, useState } from "react";
import AdminDropdown from "component/molecules/admin/adminDropdown";
import AdminButton from "component/molecules/admin/button/adminButton";
import Image from "component/atoms/image";
import TableData from "component/atoms/tableData";
import TableHead from "component/atoms/tableHead";
import Table from "component/atoms/table";
import AdminCheckbox from "component/molecules/adminCheckbox";
import useUrl from "hook/util/useUrl";
import { useAdminUserList, useBlockUser } from "hook/api/admin/user/userManage";
import dayjs from "dayjs";
import AdminPagination from "component/molecules/admin/pagination";
import { blockUser } from "api/admin/user/userManage";
import { useUserDetail } from "hook/api/admin/userDetail";
import { member } from "api/mypage";
import AdminAlertModal from "../../modal/alertModal";
import { set } from "react-hook-form";
import usePopup from "hook/util/usePopup";

const UserManagement = () => {
  const category = ["전체", "이메일", "회원명"];
  const listCategory = [
    { label: "10개씩 보기", value: 10 },
    { label: "20개씩 보기", value: 20 },
    { label: "30개씩 보기", value: 30 },
    { label: "50개씩 보기", value: 50 },
  ];

  const labels = listCategory.map((category) => category.label);

  // 검색 분류의 초기값을 '전체'로 설정
  const [selected, setSelected] = useState(category[0]);
  // 검색어
  const [inputValue, setInputValue] = useState("");

  // 검색 분류 변경 시 상태 변경
  const changeSelected = (category) => {
    setSelected(category);
  };

  // 인풋창의 입력값을 카테고리에 따라 관리
  const handleInputChange = (e) => {
    e.preventDefault();
    setInputValue(e.target.value);
  };

  // 10개씩 보기 선택하면 value = 10 을 pageSize로 설정
  const changeSelectedList = (selectedLabel) => {
    const category = listCategory.find((cat) => cat.label === selectedLabel);
    if (category) {
      setPageSize(category.value);
      setCurrentPage(1);
    }
  };

  // 라디오 버튼을 클릭하거나 검색 분류를 설정하면 해당 값으로 상태 변경
  const [grade, setGrade] = useState("total");
  const [status, setStatus] = useState("total");
  const [pageSize, setPageSize] = useState(10);

  // 검색 버튼을 클릭하면 선택된 값에 따라 검색이 됨
  // 초기 값은 전체 게시물, 10개씩 보기
  const [search, setSearch] = useState({
    grade: "total",
    status: "",
    name: "",
    email: "",
    keyword: "",
  });

  // 페이지네이션
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // 데이터를 가져오고 검색 필터, 페이지네이션 적용
  const {
    data: userList,
    totalItems: total,
    totalPages: totalPages,
  } = useAdminUserList(
    search.grade,
    search.status,
    search.name,
    search.email,
    search.keyword,
    pageSize,
    currentPage
  );

  // 검색 버튼을 클릭하면 검색 필터를 적용
  const handleSearch = () => {
    const newSearch = {
      grade: grade,
      status: status,
    };

    // 선택된 카테고리에 따라 입력값을 다른 변수에 할당
    if (selected === "이메일") {
      newSearch.email = inputValue;
    } else if (selected === "회원명") {
      newSearch.name = inputValue;
    } else if (selected === "전체") {
      newSearch.keyword = inputValue;
    }

    setSearch(newSearch);
    setCurrentPage(1);
  };

  // 새 창으로 열기!
  const { handleOpenPopup } = usePopup({
    width: "1350px",
    height: window.innerHeight,
  });

  const openNewWindow = (userId) => {
    handleOpenPopup(
      `/admin/manage/user/detail/${userId}?menu=userDetail&category=basicDetail`
    );
  };

  // 체크박스를 선택하여 회원 차단
  const [selectedUser, setSelectedUser] = useState({});

  // 전체 데이터의 processed가 true일 때 전체 체크박스도 readOnly
  const isAllReadOnly = userList?.every((item) => item.userState !== 0);

  useEffect(() => {
    setSelectedUser({});
  }, [currentPage]);

  // 전체 선택 또는 해제 처리
  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setSelectedUser((prevSelectedUser) => {
      const newSelectedUser = {};
      if (checked) {
        userList?.forEach((item) => {
          newSelectedUser[item.memberId] = item.userState === 0;
        });
      }
      return newSelectedUser;
    });
  };

  // 개별 선택 처리
  const handleSelect = (e, memberId) => {
    const checked = e.target.checked;
    setSelectedUser((prevSelectedUser) => {
      const newSelectedUser = { ...prevSelectedUser };
      const currentItem = userList?.find((item) => item.memberId === memberId);

      if (currentItem && currentItem.userState === 0) {
        newSelectedUser[memberId] = checked;
      }

      return newSelectedUser;
    });
  };

  // 차단 성공, 실패 모달
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const handleSuccess = () => {
    setSuccess(true);
  };

  const handleError = () => {
    setError(true);
  };

  const selectedUserIds = Object.entries(selectedUser)
    .filter(([_, checked]) => checked)
    .map(([memberId]) => memberId);

  const { mutate: blockUser } = useBlockUser(handleSuccess, handleError);

  const blockSelectedUser = () => {
    if (selectedUserIds.length === 0) {
      alert("차단할 회원을 선택해주세요.");
      return;
    } else {
      window.confirm("선택한 회원을 차단하시겠습니까?") &&
        blockUser({ memberId: selectedUserIds });
      setSelectedUser({});
    }
  };

  return (
    <Box
      size={{
        width: "100%",
        height: "auto",
      }}
      margin={{
        top: "25px",
      }}
    >
      <AdminAlertModal
        title={"회원 관리"}
        text={
          success
            ? "회원 차단에 성공하였습니다."
            : "회원 차단에 실패하였습니다."
        }
        btn_text="확인"
        visible={success || error}
        toggle={() => {
          setSuccess(false) || setError(false);
        }}
        onClick={() => {
          setSuccess(false) || setError(false);
        }}
      />
      <Text theme={"adminTitle"}>회원 조회</Text>
      <RowTable>
        <Box
          display={{
            width: "100%",
            height: "48px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="48px" isLastChild={true}>
            회원 분류
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
              height: "48px",
            }}
            isLastChild={true}
          >
            <Box
              size={{
                width: "100%",
                height: "100%",
              }}
              display={{
                display: "flex",
                justify: "flex-start",
                align: "center",
              }}
              margin={{
                left: "8px",
              }}
            >
              <AdminRadio
                id="all"
                name="user_category"
                value={grade}
                htmlFor="all"
                label="전체"
                checked={grade === "total"}
                onClick={() => setGrade("total")}
              />
              <AdminRadio
                id="creator"
                name="user_category"
                value="creator"
                htmlFor="creator"
                label="창안자"
                checked={grade === "ROLE_USER"}
                onClick={() => setGrade("ROLE_USER")}
              />
              <AdminRadio
                id="artist"
                name="user_category"
                value="artist"
                htmlFor="artist"
                label="아티스트"
                checked={grade === "ROLE_ARTIST"}
                onClick={() => setGrade("ROLE_ARTIST")}
              />
              <AdminRadio
                id="admin"
                name="user_category"
                value="admin"
                htmlFor="admin"
                label="관리자"
                checked={grade === "ROLE_ADMIN"}
                onClick={() => setGrade("ROLE_ADMIN")}
              />
            </Box>
          </RowTableData>
        </Box>

        <Box
          display={{
            width: "100%",
            height: "48px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="48px">
            회원 상태
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
              height: "48px",
            }}
          >
            <Box
              size={{
                width: "100%",
                height: "100%",
              }}
              display={{
                display: "flex",
                justify: "flex-start",
                align: "center",
              }}
              margin={{
                left: "8px",
              }}
            >
              <AdminRadio
                id="all_status"
                name="user_status"
                value="all_status"
                htmlFor="all_status"
                label="전체"
                checked={status === "total"}
                onClick={() => setStatus("total")}
              />
              <AdminRadio
                id="normal"
                name="user_status"
                value="normal"
                htmlFor="normal"
                label="정상"
                checked={status === "0"}
                onClick={() => setStatus("0")}
              />
              <AdminRadio
                id="block"
                name="user_status"
                value="block"
                htmlFor="block"
                label="차단"
                checked={status === "1"}
                onClick={() => setStatus("1")}
              />
              <AdminRadio
                id="sign_out"
                name="user_status"
                value="sign_out"
                htmlFor="sign_out"
                label="탈퇴"
                checked={status === "2"}
                onClick={() => setStatus("2")}
              />
            </Box>
          </RowTableData>
        </Box>

        <Box
          display={{
            width: "100%",
            height: "48px",
            display: "flex",
            direction: "row",
            align: "flex-start",
            justify: "flex-start",
          }}
        >
          <RowTableHead width="160px" height="48px">
            검색 분류
          </RowTableHead>
          <RowTableData
            size={{
              width: "calc(100% - 160px)",
            }}
            padding={{
              top: "8px",
              left: "8px",
            }}
          >
            <AdminDropdown
              size={{
                width: "104px",
                height: "32px",
              }}
              menuSize={{
                width: "104px",
                height: "auto",
              }}
              category={category}
              onSelect={changeSelected}
            />
            <Input
              theme={"adminInput"}
              size={{
                width: "500px",
                height: "32px",
              }}
              value={inputValue}
              onChange={handleInputChange}
            />
            {/* <Input as="radio" theme={"adminRadio"} name="search" value="id" /> */}
          </RowTableData>
        </Box>
      </RowTable>
      <AdminButton
        category="button"
        theme="mainButton"
        margin={{
          top: "24px",
          left: "456px",
        }}
        onClick={handleSearch}
      >
        검색
      </AdminButton>

      {/* 회원 목록 */}
      <Box
        size={{
          width: "100%",
          height: "auto",
        }}
        margin={{
          top: "40px",
        }}
      >
        <Text theme={"adminTitle"}>회원 목록</Text>
        <Box
          size={{
            width: "1024px",
            height: "auto",
          }}
          border={{
            top: "1px",
            left: "1px",
            right: "1px",
            type: "solid",
            color: "gray10",
            radius: "3px 3px 0 0",
          }}
        >
          {/* 목록 상단 인원 수, 차단 버튼 */}
          <Box
            theme="adminListBox"
            size={{
              height: "44px",
            }}
            padding={{
              left: "16px",
            }}
          >
            <Text
              as="span"
              typo={{
                size: "13px",
                weight: "500",
                color: "black02",
              }}
            >
              총{" "}
              <Text
                as="span"
                typo={{
                  color: "blue01",
                }}
              >
                {total}
              </Text>{" "}
              명
            </Text>
            <AdminDropdown
              margin={{
                right: "10px",
              }}
              size={{
                width: "110px",
                height: "28px",
              }}
              menuSize={{
                width: "110px",
                height: "auto",
              }}
              category={labels}
              onSelect={changeSelectedList}
            />
          </Box>
          <Box
            theme="adminListBox"
            size={{
              height: "44px",
            }}
            padding={{
              left: "8px",
            }}
            border={{
              top: "1px",
              style: "solid",
              color: "gray10",
            }}
          >
            <AdminButton
              category="button"
              theme="listOptionButton"
              size={{
                width: "53px",
                height: "28px",
              }}
              padding={{
                left: "6px",
                right: "6px",
              }}
              onClick={blockSelectedUser}
            >
              <Image
                src="/asset/img/admin/button/red_X.svg"
                margin={{
                  right: "1px",
                }}
              />
              차단
            </AdminButton>
          </Box>
        </Box>
        {/* 회원 목록 리스트 */}
        <Table isAttached={true} margin={{ bottom: "40px" }}>
          <thead>
            <tr>
              <TableHead width="41px" padding="8px 0">
                <AdminCheckbox
                  margin={{
                    left: "13px",
                  }}
                  value="all"
                  checked={userList
                    ?.filter((item) => item.userState === 0)
                    .every((item) => selectedUser[item.memberId])}
                  onClick={handleSelectAll}
                  readOnly={isAllReadOnly}
                />
              </TableHead>
              <TableHead width="92px">
                <Text
                  typo={{
                    color: "black02",
                  }}
                >
                  NO.
                </Text>
              </TableHead>

              <TableHead width="314px">
                <Text
                  typo={{
                    color: "black02",
                  }}
                >
                  이메일
                </Text>
              </TableHead>

              <TableHead width="136px">
                <Text
                  typo={{
                    color: "black02",
                  }}
                >
                  회원명
                </Text>
              </TableHead>

              <TableHead width="149px">
                <Text
                  typo={{
                    color: "black02",
                  }}
                >
                  회원 분류
                </Text>
              </TableHead>

              <TableHead width="129px">
                <Text
                  typo={{
                    color: "black02",
                  }}
                >
                  회원 상태
                </Text>
              </TableHead>

              <TableHead
                width="calc(1024px - 41px - 92px - 314px - 136px - 149px - 129px)"
                isLastChild
              >
                <Text
                  typo={{
                    color: "black02",
                  }}
                >
                  가입일
                </Text>
              </TableHead>
            </tr>
          </thead>
          <tbody>
            {userList?.map((data, index) => (
              <tr key={data.memberId}>
                <TableData size={{ height: "46px" }}>
                  <AdminCheckbox
                    margin={{
                      left: "13px",
                    }}
                    checked={selectedUser[data.memberId]}
                    onClick={(e) => handleSelect(e, data.memberId)}
                    value={data.memberId}
                    readOnly={data.userState !== 0 ? true : false}
                  />
                </TableData>
                <TableData>
                  {total - (currentPage - 1) * pageSize - index}
                </TableData>
                <TableData
                  onClick={() => {
                    openNewWindow(data.memberId);
                  }}
                  other={{ cursor: "pointer" }}
                >
                  <Text
                    typo={{
                      lineClamp: "1",
                      wordBreak: "break-all",
                      textDecoration: "underline",
                    }}
                    size={{
                      width: "314px",
                    }}
                    padding={{
                      left: "8px",
                      right: "8px",
                    }}
                  >
                    {data.memberEmail}
                  </Text>
                </TableData>
                <TableData>
                  <Text
                    size={{
                      width: "136px",
                    }}
                    typo={{
                      lineClamp: "1",
                      wordBreak: "break-all",
                    }}
                    padding={{
                      left: "8px",
                      right: "8px",
                    }}
                  >
                    {data.memberName}
                  </Text>
                </TableData>
                <TableData>
                  {data.authorityName === "ROLE_USER" ||
                  data.authorityName === "ROLE_USER_SNS"
                    ? "창안자"
                    : data.authorityName === "ROLE_ARTIST"
                    ? "아티스트"
                    : "관리자"}
                </TableData>
                <TableData>
                  {data.userState === 0
                    ? "정상"
                    : data.userState === 1
                    ? "차단"
                    : "탈퇴"}
                </TableData>
                <TableData isLastChild>
                  {dayjs(data.createdAt).format("YYYY-MM-DD HH:mm")}
                </TableData>
              </tr>
            ))}
          </tbody>
        </Table>
      </Box>
      {total > 0 && (
        <AdminPagination
          totalPages={totalPages}
          currentPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </Box>
  );
};

export default UserManagement;
