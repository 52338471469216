import Box from "component/atoms/box";
import Text from "component/atoms/text";
import Api from "component/molecules/common/api";
import Blank from "component/molecules/common/blank";
import List from "component/molecules/common/list";
import SortFilterSelect from "component/molecules/detail/sortFilterSelect";
import StoryCoin from "component/molecules/mypage/storyCoin";
import LineTitle from "component/organisms/common/lineTitle";
import useMyCoin from "hook/api/auth/mycoin";
import useMooMooCoin from "hook/api/mypage/moomoocoin";
import useStoryList from "hook/api/story/list";
import useUrl from "hook/util/useUrl";
import numberWithCommas from "utils/numberWithCommas";

const option = [
  {
    label: "전체",
    value: "전체",
  },
  {
    label: "적립",
    value: "적립",
  },
  {
    label: "사용",
    value: "사용",
  },
];

const Coin = (props) => {
  const { title } = props;
  const {
    query: { page = 1, sort = "전체" },
  } = useUrl();

  return (
    <>
      <LineTitle
        typo={{
          size: "18px",
        }}
        margin={{
          bottom: "0",
        }}
        title={"코인 보유 내역"}
      />
      <Box
        padding={{
          all: "35px 0",
        }}
        margin={{
          bottom: "65px",
        }}
        border={{
          bottom: "1px",
        }}
        longMobile={{
          padding: {
            all: "20px 0",
          },
          margin: {
            bottom: "48px",
          },
        }}
      >
        <Text
          typo={{
            size: "18px",
            weight: "700",
          }}
          longMobile={{
            typo: { size: "16px" },
          }}
        >
          현재 보유 코인
        </Text>
        <Api api={useMyCoin}>
          {(data) =>
            data && (
              <Text
                typo={{
                  size: "24px",
                  weight: "700",
                }}
                longMobile={{
                  typo: { size: "20px" },
                }}
              >
                {numberWithCommas(data.coinsHeld)} Coin
              </Text>
            )
          }
        </Api>
      </Box>

      <LineTitle
        typo={{
          size: "18px",
        }}
        margin={{
          bottom: "0",
        }}
        title={title}
      >
        <SortFilterSelect name="sort" options={option} />
      </LineTitle>

      <List
        api={useMooMooCoin}
        params={{
          page,
          state: sort,
          limit: 16,
        }}
      >
        {(list) =>
          list && (
            <>
              <Box
                display={{
                  display: "flex",
                  align: "center",
                }}
                size={{
                  width: "100%",
                  height: "68px",
                }}
                border={{
                  bottom: "1px",
                  color: "gray02",
                }}
                longMobile={{
                  display: {
                    display: "none",
                  },
                }}
              >
                <Text
                  size={{
                    width: "66px",
                  }}
                  typo={{
                    size: "14px",
                    weight: "700",
                  }}
                  padding={{
                    all: "0 20px",
                  }}
                >
                  분류
                </Text>
                <Text
                  size={{
                    width: "185px",
                  }}
                  typo={{
                    size: "14px",
                    weight: "700",
                  }}
                  padding={{
                    all: "0 20px",
                  }}
                >
                  코인
                </Text>
                <Text
                  size={{
                    width: "calc(100% - 66px - 185px - 114px)",
                  }}
                  typo={{
                    size: "14px",
                    weight: "700",
                  }}
                  padding={{
                    all: "0 20px",
                  }}
                >
                  적립내용
                </Text>
                <Text
                  size={{
                    width: "114px",
                  }}
                  typo={{
                    size: "14px",
                    weight: "700",
                  }}
                  padding={{
                    all: "0 20px",
                  }}
                >
                  일시
                </Text>
              </Box>
              {list.length === 0 && <Blank title={"조회 결과가 없습니다."} />}
              {list.length !== 0 && list.map((item) => <StoryCoin {...item} />)}
            </>
          )
        }
      </List>
    </>
  );
};

export default Coin;
