import {
  createPopup,
  mainBanner,
  mobileMainBanner,
  subBanner,
  mobileSubBanner,
  popup,
  updatePopup,
  deletePopup,
  createMainBanner,
  popupDetail,
  updateMainBanner,
  deleteMainBanner,
  createMobileMainBanner,
  updateMobileMainBanner,
  deleteMobileMainBanner,
  deleteSubBanner,
  updateSubBanner,
  createSubBanner,
  deleteMobileSubBanner,
  updateMobileSubBanner,
  createMobileSubBanner,
  changeOrder,
  changeSubOrder,
} from "api/admin/banner";
import useUrl from "hook/util/useUrl";
import { useMutation, useQueryClient } from "react-query";
import { useQuery } from "react-query";
import { ErrorContent } from "component/organisms/common/globalModal";
import useModalStore from "store/useModalStore";
import { useNavigate } from "react-router-dom";

export const useAdminMainBanner = () => {
  const navigate = useNavigate();
  const { data, isLoading, isSuccess, isError, refetch } = useQuery(
    "adminMainBanner",
    mainBanner,
    {
      onError: (error) => {
        if (error.response && error.response.status === 403) {
          alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
          navigate("/admin/login");
        }
      },
    }
  );

  return {
    data: data?.data,
    totalData: data?.totalData,
    isLoading,
    isSuccess,
    isError,
    refetch,
  };
};

export const useAdminCreateMainBanner = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => createMainBanner(data), {
    onSuccess: () => {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
      queryClient.invalidateQueries("adminMainBanner");
    },
    onError: () => {
      if (onError && typeof onError === "function") {
        onError();
      }
    },
  });

  return {
    mutate: mutation.mutate,
  };
};

export const useAdminUpdateMainBanner = (bannerId, onSuccess, onError) => {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => updateMainBanner(bannerId, data), {
    onSuccess: () => {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
      queryClient.invalidateQueries("adminMainBanner");
    },
    onError: () => {
      if (onError && typeof onError === "function") {
        onError();
      }
    },
  });

  return {
    mutate: mutation.mutate,
  };
};

export const useAdminDeleteMainBanner = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(({ bannerId }) => deleteMainBanner(bannerId), {
    onSuccess: () => {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
      queryClient.invalidateQueries("adminMainBanner");
    },
    onError: (error) => {
      if (onError && typeof onError === "function") {
        onError(error);
      }
    },
  });

  return {
    mutate: mutate,
  };
};

export const useAdminMobileMainBanner = () => {
  const navigate = useNavigate();
  const { data, isLoading, isSuccess, isError, refetch } = useQuery(
    "adminMobileMainBanner",
    mobileMainBanner,
    {
      onError: (error) => {
        if (error.response && error.response.status === 403) {
          alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
          navigate("/admin/login");
        }
      },
    }
  );

  return {
    data: data?.data,
    totalData: data?.totalData,
    isLoading,
    isSuccess,
    isError,
    refetch,
  };
};

export const useAdminCreateMobileMainBanner = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => createMobileMainBanner(data), {
    onSuccess: () => {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
      queryClient.invalidateQueries("adminMobileMainBanner");
    },
    onError: () => {
      if (onError && typeof onError === "function") {
        onError();
      }
    },
  });

  return {
    mutate: mutation.mutate,
  };
};

export const useAdminUpdateMobileMainBanner = (
  bannerId,
  onSuccess,
  onError
) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data) => updateMobileMainBanner(bannerId, data),
    {
      onSuccess: () => {
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess();
        }
        queryClient.invalidateQueries("adminMobileMainBanner");
      },
      onError: () => {
        if (onError && typeof onError === "function") {
          onError();
        }
      },
    }
  );

  return {
    mutate: mutation.mutate,
  };
};

export const useAdminDeleteMobileMainBanner = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    ({ bannerId }) => deleteMobileMainBanner(bannerId),
    {
      onSuccess: () => {
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess();
        }
        queryClient.invalidateQueries("adminMobileMainBanner");
      },
      onError: (error) => {
        if (onError && typeof onError === "function") {
          onError(error);
        }
      },
    }
  );

  return {
    mutate: mutate,
  };
};

export const useAdminSubBanner = () => {
  const navigate = useNavigate();
  const { data, isLoading, isSuccess, isError, refetch } = useQuery(
    "adminSubBanner",
    subBanner,
    {
      onError: (error) => {
        if (error.response && error.response.status === 403) {
          alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
          navigate("/admin/login");
        }
      },
    }
  );

  return {
    data: data?.data,
    totalData: data?.totalData,
    isLoading,
    isSuccess,
    isError,
    refetch,
  };
};

export const useAdminCreateSubBanner = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => createSubBanner(data), {
    onSuccess: () => {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
      queryClient.invalidateQueries("adminSubBanner");
    },
    onError: () => {
      if (onError && typeof onError === "function") {
        onError();
      }
    },
  });

  return {
    mutate: mutation.mutate,
  };
};

export const useAdminUpdateSubBanner = (bannerId, onSuccess, onError) => {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => updateSubBanner(bannerId, data), {
    onSuccess: () => {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
      queryClient.invalidateQueries("adminSubBanner");
    },
    onError: () => {
      if (onError && typeof onError === "function") {
        onError();
      }
    },
  });

  return {
    mutate: mutation.mutate,
  };
};

export const useAdminDeleteSubBanner = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(({ bannerId }) => deleteSubBanner(bannerId), {
    onSuccess: () => {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
      queryClient.invalidateQueries("adminSubBanner");
    },
    onError: (error) => {
      if (onError && typeof onError === "function") {
        onError(error);
      }
    },
  });

  return {
    mutate: mutate,
  };
};

export const useAdminMobileSubBanner = () => {
  const navigate = useNavigate();
  const { data, isLoading, isSuccess, isError, refetch } = useQuery(
    "adminMobileSubBanner",
    mobileSubBanner,
    {
      onError: (error) => {
        if (error.response && error.response.status === 403) {
          alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
          navigate("/admin/login");
        }
      },
    }
  );

  return {
    data: data?.data,
    totalData: data?.totalData,
    isLoading,
    isSuccess,
    isError,
    refetch,
  };
};

export const useAdminCreateMobileSubBanner = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => createMobileSubBanner(data), {
    onSuccess: () => {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
      queryClient.invalidateQueries("adminMobileSubBanner");
    },
    onError: () => {
      if (onError && typeof onError === "function") {
        onError();
      }
    },
  });

  return {
    mutate: mutation.mutate,
  };
};

export const useAdminUpdateMobileSubBanner = (bannerId, onSuccess, onError) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data) => updateMobileSubBanner(bannerId, data),
    {
      onSuccess: () => {
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess();
        }
        queryClient.invalidateQueries("adminMobileSubBanner");
      },
      onError: () => {
        if (onError && typeof onError === "function") {
          onError();
        }
      },
    }
  );

  return {
    mutate: mutation.mutate,
  };
};

export const useAdminDeleteMobileSubBanner = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    ({ bannerId }) => deleteMobileSubBanner(bannerId),
    {
      onSuccess: () => {
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess();
        }
        queryClient.invalidateQueries("adminMobileSubBanner");
      },
      onError: (error) => {
        if (onError && typeof onError === "function") {
          onError(error);
        }
      },
    }
  );

  return {
    mutate: mutate,
  };
};

export const useAdminPopup = (title, page, pageSize) => {
  const navigate = useNavigate();
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["adminPopup", title, page, pageSize],
    () => popup(title, page, pageSize),
    {
      onError: (error) => {
        if (error.response && error.response.status === 403) {
          alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
          navigate("/admin/login");
        }
      },
    }
  );

  return {
    data: data?.data,
    totalItems: data?.totalItems,
    totalPages: data?.totalPages,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useAdminCreatePopup = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => createPopup(data), {
    onSuccess: () => {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
      queryClient.invalidateQueries("adminPopup");
    },
    onError: () => {
      if (onError && typeof onError === "function") {
        onError();
      }
    },
  });

  return {
    mutate: mutation.mutate,
  };
};

export const useAdminPopupDetail = (popupId) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["adminPopupDetail", popupId],
    () => popupDetail(popupId)
  );

  return {
    data: data?.data,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useAdminDeletePopup = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(({ popupId }) => deletePopup(popupId), {
    onSuccess: () => {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
      queryClient.invalidateQueries("adminPopup");
    },
    onError: (error) => {
      if (onError && typeof onError === "function") {
        onError(error);
      }
    },
  });

  return {
    mutate: mutate,
  };
};

export const useAdminUpdatePopup = (popupId, onSuccess, onError) => {
  const queryClient = useQueryClient();
  const mutation = useMutation((data) => updatePopup(popupId, data), {
    onSuccess: () => {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
      queryClient.invalidateQueries("adminPopup");
    },
    onError: (error) => {
      if (onError && typeof onError === "function") {
        onError(error);
      }
    },
  });
  return {
    mutate: mutation.mutate,
  };
};

// 순서 변경
export const useMainBannerOrder = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation((data, device) => changeOrder(data, device), {
    onSuccess: () => {
      queryClient.invalidateQueries("adminMainBanner");
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
    },
    onError: () => {
      if (onError && typeof onError === "function") {
        onError();
      }
    },
  });
  return { mutate };
};

export const useSubBannerOrder = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(
    (data, device) => changeSubOrder(data, device),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("adminSubBanner");
        if (onSuccess) {
          onSuccess();
        }
      },
      onError: () => {
        if (onError) {
          onError();
        }
      },
    }
  );
  return { mutate };
};
