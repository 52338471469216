import Box from "component/atoms/box";
import Line from "component/atoms/line";
import Link from "component/atoms/link";
import Text from "component/atoms/text";
import StoryInfo from "component/molecules/common/storyInfo";
import Thumbnail from "component/molecules/common/thumbnail";
import ProjectTitle from "component/molecules/main/projectTitle";
import ProjectUser from "component/molecules/main/projectUser";
import useUrl from "hook/util/useUrl";
import convertImage from "utils/convertImage";

const StoryCard = (props) => {
  const {
    storyId,
    thumbnail,
    storyTitle,
    storyCategory = [],
    storyStatus,
    cheeringData,
    moomoostPick,
    member,
    artist,
    assist,
    storyMood = [],
    liked,
    //
    isLikeButton,
  } = props;

  const {
    query: { page = 1 },
  } = useUrl();

  return (
    <Link link={`/story/${storyId}?page=${page}`} preventScrollReset={true}>
      <Box
        display={{
          display: "flex",
          direction: "column",
          gap: "12px",
        }}
      >
        <Thumbnail
          src={convertImage(thumbnail)}
          status={storyStatus}
          cheeringData={cheeringData}
          moomoostPick={moomoostPick}
        />

        <StoryInfo
          infoList={[
            <ProjectTitle
              title={storyTitle}
              showLike
              liked={liked}
              storyId={storyId}
              isLikeButton={isLikeButton}
            />,
            <Box
              display={{
                display: "flex",
                align: "center",
              }}
              typo={{
                lineClamp: "1",
                wordBreak: "break-all",
              }}
            >
              <Text
                as={"span"}
                typo={{
                  size: "12px",
                  weight: "400",
                }}
              >
                {storyCategory[0]}
              </Text>
              <>
                <Text
                  as="span"
                  size={{
                    width: "1px",
                    height: "13px",
                  }}
                  margin={{
                    all: "0 5px",
                  }}
                  border={{
                    left: "1px",
                    color: "black01",
                  }}
                  typo={{
                    lineHeight: "1px",
                    verticalAlign: "middle",
                  }}
                ></Text>
                {storyMood.map((item, idx) => (
                  <Text
                    as={"span"}
                    typo={{
                      size: "12px",
                      weight: "400",
                    }}
                    margin={{
                      right: "4px",
                    }}
                    key={item}
                  >
                    {item} {idx !== storyMood.length - 1 && ","}
                  </Text>
                ))}
              </>
            </Box>,
            <ProjectUser member={member} artist={artist} assist={assist} />,
          ]}
        />
      </Box>
    </Link>
  );
};

export default StoryCard;
