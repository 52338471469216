import { BLANK } from "utils/constants";
import { useCallback, useEffect } from "react";

const getDomainFromURL = () => {
  const url = new URL(window.location.href);
  return `${url.protocol}`;
};

const usePopup = ({ submit, width, height }) => {
  const handleOpenPopup = (link) => {
    const left = window.screenX + window.outerWidth;
    const top = window.screenY;
    window.open(
      `${getDomainFromURL()}${link}`,
      "Loading...",
      `width=${width},height=${height},left=${left},top=${top}`
    );
  };

  //   const handleSubmit = useCallback(
  //     (message) => {
  //       if (message.origin !== window.location.origin) {
  //         return;
  //       }

  //       if (message.data !== null && message.data !== BLANK) {
  //         submit(message.data);
  //       }
  //     },
  //     [submit]
  //   );

  //   useEffect(() => {
  //     window.addEventListener("message", handleSubmit);

  //     return () => {
  //       window.removeEventListener("message", handleSubmit);
  //     };
  //   }, [handleSubmit]);

  return { handleOpenPopup };
};

export default usePopup;
