import { myStory } from "api/mypage";
import { useQuery } from "react-query";

const useMyStory = ({ id, state, page }) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["myStory", id, state, page],
    () => myStory(id, state, page)
  );

  const list = data?.data;
  const maxPage = data?.totalPages;

  return {
    list,
    maxPage,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useMyStory;
