import Box from "component/atoms/box";
import Button from "component/atoms/button";
import LineBreak from "component/atoms/lineBreak";
import Link from "component/atoms/link";
import Text from "component/atoms/text";
import CommonButton from "component/molecules/common/commonButton";
import InputField from "component/organisms/common/inputField";
import LineTitle from "component/organisms/common/lineTitle";
import ThumbnailField from "component/organisms/common/thumbnailField";
import useUpdateProfileForm from "hook/api/auth/update";
import useToggle from "hook/util/useToggle";
import useUrl from "hook/util/useUrl";
import { useEffect } from "react";
import { FormProvider } from "react-hook-form";
import { BiChevronLeft } from "react-icons/bi";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import useDeviceStore from "store/useDeviceStore";
import useModalStore from "store/useModalStore";
import convertImage from "utils/convertImage";

const Edit = (props) => {
  const { isArtist, isYou } = props;

  const { form, isActive, submit } = useUpdateProfileForm();
  const { longMobile } = useDeviceStore();
  const { toggle: toggleStep1, handleToggle: handleToggleStep1 } = useToggle();
  const { toggle: toggleStep2, handleToggle: handleToggleStep2 } = useToggle();
  const { changeLink } = useUrl();
  const { setModal } = useModalStore();

  const thumbnail = form.watch("thumbnail");
  const password = form.watch("password");

  const sns = form.watch("socialProvider");

  useEffect(() => {
    if (!isYou) {
      setModal({
        title: "프로필",
        content: "잘못된 접근입니다.",
      });
      changeLink("/");
    }
  }, [isYou]);

  return (
    <>
      <LineTitle
        title="내정보 수정하기"
        typo={{
          size: "18px",
        }}
      >
        <Link
          link={`/mypage?page=1&category=story`}
          display={{
            display: "flex",
            align: "center",
          }}
          typo={{
            size: "12px",
            weight: "700",
          }}
        >
          <BiChevronLeft size={15} />
          돌아가기
        </Link>
      </LineTitle>

      <Box>
        <LineTitle title="기본 정보">
          <Text
            display={{
              display: "flex",
              align: "center",
            }}
            typo={{
              size: "14px",
              weight: "700",
              color: "pink02",
            }}
            longMobile={{
              display: {
                display: !toggleStep1 ? "flex" : "none",
              },
              position: {
                type: "absolute",
                top: "calc(100% + 10px)",
                right: "0",
              },
              typo: {
                size: "12px",
              },
            }}
          >
            * 필수 입력 내용입니다.
          </Text>

          <Button
            display={{
              display: "none",
            }}
            longMobile={{
              display: {
                display: "block",
              },
            }}
            onClick={handleToggleStep1}
          >
            {!toggleStep1 ? <BsChevronUp /> : <BsChevronDown />}
          </Button>
        </LineTitle>

        {(!toggleStep1 || !longMobile) && (
          <FormProvider {...form}>
            <Box
              margin={{
                bottom: "20px",
              }}
            >
              <InputField
                title={"이름(활동명)"}
                required
                name={"name"}
                as={"input"}
                size={{
                  width: "100%",
                  height: "42px",
                }}
              />
            </Box>
            <Box
              margin={{
                bottom: "20px",
              }}
            >
              <InputField
                title={"간단 자기소개"}
                required
                name={"intro"}
                as={"textarea"}
                size={{
                  width: "100%",
                  height: "146px",
                }}
              />
            </Box>

            <Box
              margin={{
                bottom: "20px",
              }}
            >
              <InputField
                title={"이메일"}
                required
                info={
                  <Text
                    typo={{
                      size: "14px",
                      weight: "400",
                      color: "gray04",
                    }}
                  >
                    실제 사용중인 이메일을 기입해주세요.
                  </Text>
                }
                name={"email"}
                as={"input"}
                readOnly
                size={{
                  width: "100%",
                  height: "42px",
                }}
              />
            </Box>

            <Box
              margin={{
                bottom: "20px",
              }}
            >
              <InputField
                title={"비밀번호"}
                required={password}
                readOnly={sns}
                info={
                  <Text
                    typo={{
                      size: "14px",
                      weight: "400",
                      color: "gray04",
                    }}
                  >
                    영문, 숫자, 특수문자 포함 8자 이상
                  </Text>
                }
                name={"password"}
                type={"password"}
                as={"input"}
                size={{
                  width: "100%",
                  height: "42px",
                }}
              />
            </Box>

            <Box
              margin={{
                bottom: "20px",
              }}
            >
              <InputField
                title={"비밀번호 확인"}
                required={password}
                readOnly={sns}
                name={"passwordConfirm"}
                type={"password"}
                as={"input"}
                size={{
                  width: "100%",
                  height: "42px",
                }}
              />
            </Box>

            <ThumbnailField
              title={"프로필사진"}
              name={"profile"}
              thumbnail={convertImage(thumbnail)}
              size={{
                width: "270px",
                height: "270px",
                aspectRatio: "1/1",
              }}
              desc={
                <Text
                  typo={{
                    size: "12px",
                    weight: "400",
                    color: "gray04",
                  }}
                  margin={{
                    top: "6px",
                  }}
                >
                  <Text
                    as={"span"}
                    display={{
                      display: "inline-block",
                      align: "center",
                    }}
                    longMobile={{
                      display: {
                        display: "none",
                      },
                    }}
                  >
                    -
                  </Text>
                  권장 이미지{" "}
                  <LineBreak
                    display={{
                      display: "none",
                    }}
                    longMobile={{
                      display: {
                        display: "block",
                      },
                    }}
                  />
                  : 1080px*1080px / jpg,png 파일
                </Text>
              }
            />

            <Text
              typo={{
                size: "18px",
                weight: "700",
              }}
              margin={{
                top: "20px",
                bottom: "10px",
              }}
              longMobile={{
                typo: {
                  size: "16px",
                },
              }}
            >
              SNS 계정
            </Text>
            <Box
              display={{
                display: "flex",
                align: "center",
                gap: "40px",
              }}
              size={{
                width: "100%",
              }}
              margin={{
                bottom: "20px",
              }}
              longMobile={{
                display: {
                  direction: "column",
                  gap: "12px",
                },
                margin: {
                  bottom: "12px",
                },
              }}
            >
              <InputField
                title={"인스타그램"}
                name={"instagramLink"}
                as={"input"}
                size={{
                  width: "100%",
                  height: "42px",
                }}
                typo={{
                  size: "14px",
                  weight: "700",
                }}
              />
              <InputField
                title={"유튜브"}
                name={"youtubeLink"}
                as={"input"}
                size={{
                  width: "100%",
                  height: "42px",
                }}
                typo={{
                  size: "14px",
                  weight: "700",
                }}
              />
            </Box>

            <Box
              display={{
                display: "flex",
                align: "center",
                gap: "40px",
              }}
              size={{
                width: "100%",
              }}
              margin={{
                bottom: "60px",
              }}
              longMobile={{
                display: {
                  direction: "column",
                  gap: "12px",
                },
                margin: {
                  bottom: "50px",
                },
              }}
            >
              <InputField
                title={"사운드 클라우드"}
                name={"soundCloudLink"}
                as={"input"}
                size={{
                  width: "100%",
                  height: "42px",
                }}
                typo={{
                  size: "14px",
                  weight: "700",
                }}
              />
              <InputField
                title={"기타"}
                name={"otherSocialLink"}
                as={"input"}
                size={{
                  width: "100%",
                  height: "42px",
                }}
                typo={{
                  size: "14px",
                  weight: "700",
                }}
              />
            </Box>
          </FormProvider>
        )}

        {isArtist && (
          <>
            <LineTitle title="아티스트 정보">
              <Button
                display={{
                  display: "none",
                }}
                longMobile={{
                  display: {
                    display: "block",
                  },
                }}
                onClick={handleToggleStep2}
              >
                {!toggleStep2 ? <BsChevronUp /> : <BsChevronDown />}
              </Button>
            </LineTitle>
            {(!toggleStep2 || !longMobile) && (
              <FormProvider {...form}>
                {/* <Box
                  margin={{
                    bottom: "24px",
                  }}
                >
                  <InputField
                    name="artistName"
                    title={"아티스트로 활동한 활동명"}
                    type={"text"}
                    size={{
                      width: "100%",
                      height: "40px",
                    }}
                    typo={{
                      size: "18px",
                      weight: "700",
                    }}
                  />
                </Box> */}

                <Box
                  margin={{
                    bottom: "24px",
                  }}
                >
                  <InputField
                    name={"memberPhone"}
                    title={"연락처"}
                    type={"number"}
                    size={{
                      width: "30%",
                      height: "40px",
                    }}
                    typo={{
                      size: "18px",
                      weight: "700",
                    }}
                  />
                </Box>

                <Box
                  margin={{
                    bottom: "24px",
                  }}
                >
                  <InputField
                    as={"textarea"}
                    type={"text"}
                    title={"아티스트 소개(200자 이내)"}
                    name={"artistIntro"}
                    size={{
                      width: "100%",
                      height: "80px",
                    }}
                    typo={{
                      size: "18px",
                      weight: "700",
                    }}
                    maxLength={200}
                  />
                </Box>
              </FormProvider>
            )}
          </>
        )}

        <Box
          display={{
            display: "flex",
            align: "center",
            gap: "40px",
          }}
          longMobile={{
            display: {
              gap: "9px",
            },
          }}
        >
          <CommonButton
            category={"link"}
            size={{
              width: "100%",
              height: "50px",
            }}
            typo={{
              size: "18px",
              weight: "700",
            }}
          >
            취소
          </CommonButton>
          <CommonButton
            category={"button"}
            size={{
              width: "100%",
              height: "50px",
            }}
            typo={{
              size: "18px",
              weight: "700",
            }}
            isActive={isActive}
            isDisabled={!isActive}
            onClick={submit}
          >
            저장
          </CommonButton>
        </Box>
      </Box>
    </>
  );
};

export default Edit;
