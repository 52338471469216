import { termsUse } from "api/main";
import { useQuery } from "react-query";

const useTermsUse = () => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    "termsUse",
    termsUse
  );

  return {
    data: data?.data,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useTermsUse;
