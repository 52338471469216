import { POST } from "api/config";

export const adminLogin = async ({ email, password }) =>
  await POST("/auth/login", {
    email,
    password,
  });

export const adminFind = async ({ email }) =>
  await POST("/auth/tmp/pass/admin", { email });
