import React, { useEffect } from "react";
import StoryInfo from "component/organisms/detail/storyInfo";
import Box from "component/atoms/box";
import useUrl from "hook/util/useUrl";
import Thumbnail from "component/molecules/common/thumbnail";
import LineTitle from "component/organisms/common/lineTitle";
import CommonButton from "component/molecules/common/commonButton";
import Funding from "component/organisms/detail/funding";
import StoryContentInfo from "component/organisms/detail/storyContentInfo";
import Api from "component/molecules/common/api";
import { useStory } from "hook/api/story/detail";
import StoryContent from "component/organisms/detail/storyContent";
import useDeviceStore from "store/useDeviceStore";
import List from "component/molecules/common/list";
import {
  useCommentList,
  useCommentInfiniteList,
} from "hook/api/story/commentList";
import Matching from "component/organisms/detail/matching";
import useToggle from "hook/util/useToggle";
import FinishModal from "component/organisms/detail/modal/finish";
import Additional from "component/organisms/detail/additional";
import convertImage from "utils/convertImage";
import NotFound from "page/notfound";

const Detail = () => {
  const {
    params,
    query: { page = 1, type, comment },
    hash,
  } = useUrl();
  const { toggle, handleToggle } = useToggle();

  const { mobile, longMobile } = useDeviceStore();

  const isMobile = mobile || longMobile;
  const api = useCommentInfiniteList;

  const isMatching = type === "matching";
  const isAdditional = type === "additional";

  useEffect(() => {
    if (hash) {
      const id = hash.replace("#", "");
      const target = document.getElementById(id);
      if (target) {
        const yOffset = -110;

        const y =
          target.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
      }
    }
  }, [hash]);

  return (
    <>
      <Api
        api={useStory}
        params={{
          id: params.id,
        }}
      >
        {(data) => {
          // data.flag.isCreator = true;
          return (
            <>
              {!data && <NotFound />}
              {data && (
                <Box
                  theme={"basic"}
                  margin={{
                    all: "64px auto 100px",
                  }}
                  longMobile={{
                    padding: {
                      all: "0",
                    },
                    margin: {
                      all: "0 auto 100px",
                    },
                  }}
                >
                  <Box
                    display={{
                      display: "flex",
                      justify: "space-between",
                      gap: "40px",
                    }}
                    longMobile={{
                      display: {
                        direction: "column",
                        gap: "20px",
                      },
                    }}
                  >
                    <Box
                      position={{
                        type: "relative",
                      }}
                      display={{
                        display: "flex",
                        align: "center",
                      }}
                      size={{
                        width: "50%",
                        aspectRatio: 1,
                      }}
                      border={{
                        all: "1px",
                        style: "solid",
                        color: "black01",
                      }}
                      other={{
                        overflow: "hidden",
                      }}
                      background={{
                        color: "white01",
                      }}
                      longMobile={{
                        size: {
                          width: "100%",
                        },
                        border: {
                          all: "0",
                        },
                      }}
                    >
                      <Thumbnail
                        src={convertImage(data.story.storyThumbnail)}
                        status={data.story.storyStatus}
                        moomoostPick={data.story.moomoostPick}
                        isDetail
                      />
                    </Box>

                    <Box
                      size={{
                        width: "50%",
                      }}
                      border={{
                        all: "1px",
                        style: "solid",
                        color: "black01",
                      }}
                      longMobile={{
                        size: {
                          width: "calc(100% - 32px)",
                        },
                        margin: {
                          all: "0 16px",
                        },
                      }}
                    >
                      <StoryInfo
                        {...data.story}
                        {...data.flag}
                        isEnd={data.story.storyStatus === "창작완료"}
                      />
                    </Box>
                  </Box>

                  {isAdditional && <Additional />}
                  {isMatching && <Matching />}
                  {!(isAdditional || isMatching) && (
                    <>
                      <Box
                        margin={{
                          top: "80px",
                        }}
                        longMobile={{
                          padding: {
                            all: "0 16px",
                          },
                        }}
                      >
                        <LineTitle title="콘텐츠 정보">
                          {data.flag.isCreator &&
                            data.story.storyStatus === "창작중" &&
                            data.story.requiredForm && (
                              <CommonButton
                                category={"button"}
                                size={{
                                  width: "100px",
                                  height: "40px",
                                }}
                                typo={{
                                  size: "14px",
                                }}
                                isActive
                                onClick={handleToggle}
                              >
                                창작 완료하기
                              </CommonButton>
                            )}
                        </LineTitle>
                      </Box>
                      <Box
                        display={{
                          display: "grid",
                          gridTemplateColumns: "repeat(2, 1fr)",
                          gap: "40px",
                        }}
                        longMobile={{
                          display: {
                            gridTemplateColumns: "repeat(1, 1fr)",
                            gap: "20px",
                          },
                          padding: {
                            all: "0 16px",
                          },
                        }}
                      >
                        <StoryContentInfo
                          title={data.story.storyTitle}
                          requiredForm={data.story.requiredForm}
                          artist={data.story.artist}
                          isCreator={
                            data.flag.isCreator ||
                            data.flag.isMatchedArtist ||
                            data.flag.isMatchedAssist
                          }
                        />

                        <Funding
                          funding={data.story.cheering}
                          isCreator={
                            data.flag.isCreator ||
                            data.flag.isMatchedArtist ||
                            data.flag.isMatchedAssist
                          }
                          isEnd={data.story.storyStatus === "창작완료"}
                        />
                      </Box>

                      <div className="detail-content" />
                      <List
                        api={api}
                        params={{
                          id: params.id,
                          page,
                        }}
                        isInfinite={true}
                        key={api}
                      >
                        {(list) =>
                          list && (
                            <StoryContent
                              isCreator={data.flag.isCreator}
                              isMatchedArtist={data.flag.isMatchedArtist}
                              isMatchedAssist={data.flag.isMatchedAssist}
                              commentList={list}
                              requiredForm={data.story.requiredForm}
                              key={comment}
                            />
                          )
                        }
                      </List>
                    </>
                  )}
                </Box>
              )}
            </>
          );
        }}
      </Api>

      <FinishModal
        toggle={toggle}
        //
        handleToggle={handleToggle}
      />
    </>
  );
};

export default Detail;
