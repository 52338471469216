export const mainBanner = {
  mobile: [
    {
      id: 1,
      image: "https://via.placeholder.com/300x300",
      link: "",
    },
    {
      id: 2,
      image: "https://via.placeholder.com/300x300",
      link: "",
    },
    {
      id: 3,
      image: "https://via.placeholder.com/300x300",
      link: "",
    },
  ],
  pc: [
    {
      id: 1,
      image: "https://via.placeholder.com/300x150",
      link: "https://www.naver.com",
    },
    {
      id: 2,
      image: "https://via.placeholder.com/300x150",
      link: "https://www.naver.com",
    },
    {
      id: 3,
      image: "https://via.placeholder.com/300x150",
      link: "https://www.naver.com",
    },
  ],
};

export const footerBanner = {
  mobile: {
    id: 1,
    image: "https://via.placeholder.com/300x300",
    link: "https://www.naver.com",
  },

  pc: {
    id: 2,
    image: "https://via.placeholder.com/300x150",
    link: "",
  },
};
