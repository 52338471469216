import React, { useEffect } from "react";
import { useRef } from "react";

const GoogleLogin = ({ token, onSuccess, onFailure, render }) => {
  const loginRef = useRef();

  useEffect(() => {
    loadGoogleScript();

    return () => {
      removeGoogleScript();
    };
  }, [token, onSuccess, onFailure]);

  const loadGoogleScript = () => {
    if (window.gapi) return; // Return if script is already present

    const script = document.createElement("script");
    script.src = "https://accounts.google.com/gsi/client";
    script.async = true;
    script.defer = true;
    script.onload = initializeGoogleSignIn;
    document.body.appendChild(script);
  };

  const removeGoogleScript = () => {
    // Useful if component unmounts and you want to clean up.
    const script = document.querySelector(
      "script[src='https://accounts.google.com/gsi/client']"
    );
    if (script) {
      document.body.removeChild(script);
    }
  };

  const handleCredentialResponse = (response) => {
    if (response) {
      onSuccess(response);
    } else {
      onFailure("Failed to get credential");
    }
  };

  const initializeGoogleSignIn = () => {
    const login = window.google.accounts.oauth2.initTokenClient({
      client_id: token,
      scope: "profile email",
      callback: handleCredentialResponse,
    });

    loginRef.current = login;
  };

  return (
    <>
      <div
        onClick={() => loginRef.current?.requestAccessToken()}
        class="g_id_signin"
        data-type="standard"
        data-size="large"
        data-text="구글 로그인"
        data-logo-alignment="center"
        data-width="400"
      >
        {render()}
      </div>
    </>
  );
};

export default GoogleLogin;
