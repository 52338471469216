import { matching } from "api/story";
import Text from "component/atoms/text";
import { ErrorContent } from "component/organisms/common/globalModal";
import useUrl from "hook/util/useUrl";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";
import checkRequired from "utils/checkRequired";

const Content = ({ message }) => {
  return (
    <>
      <Text
        typo={{
          size: "18px",
          weight: "700",
          align: "center",
        }}
        longMobile={{
          typo: {
            size: "14px",
            weight: "700",
          },
        }}
      >
        {message}
      </Text>

      <Text
        typo={{
          size: "14px",
          color: "gray04",
          align: "center",
        }}
        longMobile={{
          typo: {
            size: "12px",
            color: "gray04",
          },
        }}
      >
        자세한 사항은 [ 프로필 &gt; 매칭 신청 리스트 ] 에서 <br />
        확인해주세요.
      </Text>
    </>
  );
};

const required = {
  title: true,
  content: true,
};

const useMatchingStoryForm = (close) => {
  const form = useForm({
    defaultValues: {
      title: "",
      content: "",
    },
  });
  const { watch, handleSubmit } = form;
  const { setModal } = useModalStore();
  const { params } = useUrl();
  const queryClient = useQueryClient();
  const matchingMutation = useMutation(matching, {
    onSuccess: ({ message }) => {
      queryClient.invalidateQueries(["story", params.id]);
      close();
      setModal({
        title: "매칭 신청하기",
        content: <Content message={message} />,
      });
    },
    onError: ({ response: { data } }) => {
      setModal({ title: "매칭 신청하기", content: <ErrorContent /> });
    },
  });

  const isActive = useMemo(() => checkRequired(required, watch()), [watch()]);

  const submit = (data) => {
    isActive &&
      matchingMutation.mutate({
        id: params.id,
        data: {
          matchTitle: data.title,
          matchContent: data.content,
        },
      });
  };

  return {
    form,
    isActive,
    submit: handleSubmit(submit),
  };
};

export default useMatchingStoryForm;
