import React, { useEffect } from "react";
import SideNav from "page/admin/sideNav";
import AdminHeader from "component/molecules/layout/adminHeader";
import useUrl from "hook/util/useUrl";
import { useAuth } from "context";
import { Cookies } from "react-cookie";

const AdminLayout = (props) => {
  const { authData } = useAuth();
  const cookies = new Cookies();

  const isLoginPage =
    useUrl().pathname.includes("login") ||
    window.location.pathname === "/admin";

  useEffect(() => {
    if (window.location.pathname === "/admin") {
      if (!cookies.get("moomoostJwt")) {
        window.location.href = "/admin/login";
      } else {
        window.location.href = "/admin/dashboard";
      }
    }
  }, [isLoginPage]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        height: "100%",
      }}
    >
      {!isLoginPage && <SideNav />}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "calc(100% - 260px)",
        }}
      >
        {!isLoginPage && <AdminHeader />}
        <div
          style={{
            marginLeft: "25px",
          }}
        >
          {props.children}
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
