import React, { useState } from "react";
import AdminModal from "component/molecules/admin/adminModal";
import AdminButton from "component/molecules/admin/button/adminButton";
import Text from "component/atoms/text";
import Box from "component/atoms/box";
import Input from "component/atoms/input";

const AdminInputModal = (props) => {
  const { toggle, handleToggle, onChange, save, cancle, value, rejectReason } =
    props;
  // const [value, setValue] = useState("");

  // const handleInput = (e) => {
  //   setValue(e.target.value);
  // };

  // const handleSave = () => {
  //   save(value);
  //   handleToggle();
  // };

  const isReadOnly = !!rejectReason;

  return (
    <AdminModal
      visible={toggle}
      onClose={handleToggle}
      title={"보류 사유"}
      info={
        !rejectReason && (
          <Text
            size={{
              width: "100%",
            }}
            typo={{
              size: "14px",
              weight: "400",
              color: "black01",
            }}
            margin={{
              top: "5px",
            }}
          >
            보류 사유를 입력해주세요.
          </Text>
        )
      }
    >
      <Input
        as="textarea"
        type="text"
        value={rejectReason || value}
        onChange={onChange}
        readOnly={isReadOnly}
        theme="adminInput"
        size={{
          width: "362px",
          height: "171px",
        }}
        padding={{
          top: "15px",
          left: "15px",
        }}
      />
      <Box
        display={{
          display: "flex",
        }}
        margin={{
          top: "10px",
        }}
      >
        {!rejectReason ? (
          <>
            <AdminButton
              category="button"
              theme="grayColor"
              size={{
                width: "176px",
                height: "40px",
              }}
              margin={{
                right: "12px",
              }}
              onClick={cancle}
            >
              취소
            </AdminButton>
            <AdminButton
              category="button"
              size={{
                width: "176px",
                height: "40px",
              }}
              onClick={save}
            >
              저장
            </AdminButton>
          </>
        ) : (
          <AdminButton
            category="button"
            size={{
              width: "364px",
              height: "40px",
            }}
            onClick={handleToggle}
          >
            확인
          </AdminButton>
        )}
      </Box>
    </AdminModal>
  );
};

export default AdminInputModal;
