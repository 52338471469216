import React, { useContext } from "react";
import { MenuContext } from "context";
import Box from "component/atoms/box";
import { useParams } from "react-router-dom";
import StoryDetailEdit from "component/organisms/admin/storyDetail/storyEdit";
import StoryCheeringEdit from "component/organisms/admin/storyDetail/cheeringEdit";
import StoryMatchingEdit from "component/organisms/admin/storyDetail/matchingEdit";
import Api from "component/molecules/common/api";
import {
  useAdminStoryCheering,
  useAdminStoryCheeringList,
  useAdminStoryDetail,
  useAdminStoryMatchStatus,
} from "hook/api/admin/story/storyManage";
import useUrl from "hook/util/useUrl";
import AdminPagination from "component/molecules/admin/pagination";
import Blank from "component/molecules/common/blank";
import Text from "component/atoms/text";
import RowTable from "component/atoms/rowSideTable";
import RowTableHead from "component/atoms/rowTableHead";
import RowTableData from "component/atoms/rowTableData";

const ManageStoryDetail = () => {
  const {
    params: { id },
    query: {
      page = 1,
      startDate,
      endDate,
      memberName,
      memberEmail,
      total,
      pageSize,
    },
    changeQuery,
  } = useUrl();
  const { selectedMenu } = useContext(MenuContext);

  return (
    <Box
      size={{
        width: "100%",
        height: "auto",
      }}
      margin={{
        top: "25px",
      }}
    >
      {selectedMenu === "storyEdit" && (
        <Api
          api={useAdminStoryDetail}
          params={{
            storyId: id,
          }}
        >
          {(data) => data && <StoryDetailEdit data={data} />}
        </Api>
      )}
      {selectedMenu === "cheeringEdit" && (
        <Api
          api={useAdminStoryCheering}
          params={{
            storyId: id,
          }}
        >
          {(data) =>
            data ? (
              <Api
                api={useAdminStoryCheeringList}
                params={{
                  storyId: data.cheerId,
                  startDate,
                  endDate,
                  memberName,
                  memberEmail,
                  total,
                  page,
                  pageSize,
                }}
              >
                {(list) =>
                  list && (
                    <>
                      <StoryCheeringEdit
                        cheeringList={list.data}
                        data={data}
                        total={list.total}
                      />
                      {total > 0 && (
                        <AdminPagination
                          totalPages={list.maxPage}
                          currentPage={Number(page)}
                          onPageChange={(page) => {
                            changeQuery({ page });
                          }}
                        />
                      )}
                    </>
                  )
                }
              </Api>
            ) : (
              <Box
                size={{
                  width: "100%",
                  height: "auto",
                }}
                margin={{
                  top: "25px",
                }}
              >
                <Text theme={"adminTitle"}>응원 상태</Text>
                <RowTable
                  margin={{
                    bottom: "55px",
                  }}
                >
                  <Box
                    display={{
                      width: "100%",
                      height: "48px",
                      display: "flex",
                      direction: "row",
                      align: "flex-start",
                      justify: "flex-start",
                    }}
                  >
                    <RowTableHead
                      width="160px"
                      height="48px"
                      isLastChild={true}
                    >
                      응원 일정
                    </RowTableHead>
                    <RowTableData
                      size={{
                        width: "calc(100% - 160px)",
                        height: "48px",
                      }}
                      isLastChild={true}
                    >
                      <Text margin={{ top: "15px" }}>-</Text>
                    </RowTableData>
                  </Box>
                  <Box
                    display={{
                      width: "100%",
                      height: "48px",
                      display: "flex",
                      direction: "row",
                      align: "flex-start",
                      justify: "flex-start",
                    }}
                  >
                    <RowTableHead width="160px" height="48px">
                      진행 상태
                    </RowTableHead>
                    <RowTableData
                      size={{
                        width: "calc(100% - 160px)",
                        height: "48px",
                      }}
                    >
                      <Text margin={{ top: "15px" }}>-</Text>
                    </RowTableData>
                  </Box>
                  <Box
                    display={{
                      width: "100%",
                      height: "48px",
                      display: "flex",
                      direction: "row",
                      align: "flex-start",
                      justify: "flex-start",
                    }}
                  >
                    <RowTableHead width="160px" height="48px">
                      달성률
                    </RowTableHead>
                    <RowTableData
                      size={{
                        width: "calc(100% - 160px)",
                        height: "48px",
                      }}
                    >
                      <Text margin={{ top: "15px" }}>-</Text>
                    </RowTableData>
                  </Box>
                  <Box
                    display={{
                      width: "100%",
                      height: "48px",
                      display: "flex",
                      direction: "row",
                      align: "flex-start",
                      justify: "flex-start",
                    }}
                  >
                    <RowTableHead width="160px" height="48px">
                      목표 코인
                    </RowTableHead>
                    <RowTableData
                      size={{
                        width: "calc(100% - 160px)",
                        height: "48px",
                      }}
                    >
                      <Text margin={{ top: "15px" }}>-</Text>
                    </RowTableData>
                  </Box>
                  <Box
                    display={{
                      width: "100%",
                      height: "48px",
                      display: "flex",
                      direction: "row",
                      align: "flex-start",
                      justify: "flex-start",
                    }}
                  >
                    <RowTableHead width="160px" height="48px">
                      달성 코인
                    </RowTableHead>
                    <RowTableData
                      size={{
                        width: "calc(100% - 160px)",
                        height: "48px",
                      }}
                    >
                      <Text margin={{ top: "15px" }}>-</Text>
                    </RowTableData>
                  </Box>
                </RowTable>
              </Box>
            )
          }
        </Api>
      )}
      {selectedMenu === "matchingEdit" && (
        <Api
          api={useAdminStoryMatchStatus}
          params={{
            storyId: id,
          }}
        >
          {(data) => data && <StoryMatchingEdit data={data} />}
        </Api>
      )}
    </Box>
  );
};

export default ManageStoryDetail;
