import { useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  getAdminAcceptance,
  updateAdminAcceptance,
} from "api/admin/setup/acceptance";
import { useNavigate } from "react-router-dom";

export const useAdminAcceptance = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery("adminAcceptance", getAdminAcceptance, {
    onError: (error) => {
      if (error.response && error.response.status === 403) {
        alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
        navigate("/admin/login");
      }
    },
  });
  return { data: data?.data, isLoading };
};

export const useUpdateAdminAcceptance = (
  onSuccessCallback,
  onErrorCallback
) => {
  const queryClient = useQueryClient();
  const { mutate, data, isLoading } = useMutation(updateAdminAcceptance, {
    onSuccess: () => {
      if (onSuccessCallback && typeof onSuccessCallback === "function") {
        onSuccessCallback();
      }
      queryClient.invalidateQueries("adminAcceptance");
    },
    onError: (error) => {
      if (onErrorCallback && typeof onErrorCallback === "function") {
        onErrorCallback(error);
      }
    },
  });

  return {
    mutate,
    data,
    isLoading,
  };
};
