import { GET, POST, PUT } from "api/config";

export const getGradeList = async (
  state = "",
  startDate = "",
  endDate = "",
  total = "",
  memberEmail = "",
  memberName = "",
  portfolioName = "",
  pageSize = "",
  currentPage = "1"
) =>
  await GET(
    `/admin/up/request/members?state=${state}&startDate=${startDate}&endDate=${endDate}&total=${total}&memberEmail=${memberEmail}&memberName=${memberName}&portfolioName=${portfolioName}&pageSize=${pageSize}&page=${currentPage}`
  );

// 등업 승인
export const approve = async (memberId) => {
  const ids = memberId.join(",");
  await POST(`/admin/up/request/members/approve?memberIds=${ids}`);
};

// 등업 보류
export const pending = async (memberId, rejectReason) => {
  const data = {
    rejectReason: rejectReason,
  };
  await POST(`/admin/up/request/members/hold/${memberId}`, data);
};

// 회원 차단
export const blockUser = async (memberId) => {
  const ids = memberId.join(",");
  await PUT(`/admin/members?memberIds=${ids}`);
};
