import React, { useRef, useEffect } from "react";
import Box from "component/atoms/box";
import AdminButton from "component/molecules/admin/button/adminButton";
import Text from "component/atoms/text";
import { useAuth } from "context";
import useLogout from "hook/api/auth/logout";

const ProfileHoverBox = (props) => {
  const { isOpen, onClose } = props;

  const { authData } = useAuth();

  const logout = useLogout();

  const handleLogout = () => {
    if (window.confirm("로그아웃 하시겠습니까?")) {
      logout();
      sessionStorage.removeItem("authData");
    }
  };

  const boxRef = useRef(null);

  useEffect(() => {
    // 외부 클릭 감지 함수
    const handleClickOutside = (event) => {
      if (boxRef.current && !boxRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [boxRef, onClose]);

  return (
    <Box
      ref={boxRef}
      size={{
        width: "165px",
        height: "119px",
      }}
      display={{
        display: isOpen ? "block" : "none",
        justify: "center",
        align: "center",
      }}
      position={{
        type: "absolute",
        zIndex: "6",
      }}
      margin={{
        left: "18px",
        top: "170px",
      }}
      padding={{ top: "15px", left: "10px", right: "10px", bottom: "12px" }}
      background={{
        color: "white01",
      }}
      border={{
        all: "1px",
        type: "solid",
        color: "gray10",
        radius: "3px",
      }}
      other={{
        boxShadow: "0px 0px 3px 0px rgba(0, 0, 0, 0.15)",
      }}
    >
      <Text
        typo={{
          size: "10px",
          color: "black02",
          weight: "400",
        }}
        margin={{
          left: "5px",
        }}
      >
        관리자
      </Text>
      <Text
        typo={{
          size: "13px",
          color: "black02",
          weight: "700",
        }}
        margin={{
          bottom: "16px",
          left: "5px",
        }}
      >
        {authData?.username}님
      </Text>
      <Box
        size={{
          width: "100%",
          height: "1px",
        }}
        background={{
          color: "gray10",
        }}
        margin={{
          bottom: "10px",
        }}
      />
      <AdminButton
        category="button"
        size={{
          width: "144px",
          height: "32px",
        }}
        typo={{
          size: "13px",
          weight: "700",
          color: "pink03",
        }}
        background={{
          color: "white01",
        }}
        border={{
          all: "1px",
          type: "solid",
          color: "#DFDFDF",
          radius: "3px",
        }}
        onClick={handleLogout}
      >
        로그아웃
      </AdminButton>
    </Box>
  );
};

export default ProfileHoverBox;
