import { DELETE, GET, POST, PUT } from "api/config";

export const mainBanner = async () => await GET("/admin/pc/main/banner");

// 메인 배너 생성
export const createMainBanner = async (data) =>
  await POST("/admin/pc/main/banner", data);

export const updateMainBanner = async (id, data) =>
  await PUT(`/admin/pc/main/banner/${id}`, data);

export const deleteMainBanner = async (id) => {
  const ids = id.join(",");
  await DELETE(`/admin/pc/main/banner?bannerIds=${ids}`);
};

export const changeOrder = async ({ data, device }) => {
  await PUT(`/admin/${device}/main/banner/order`, data);
};

export const mobileMainBanner = async () =>
  await GET("/admin/mobile/main/banner");

export const createMobileMainBanner = async (data) =>
  await POST("/admin/mobile/main/banner", data);

export const updateMobileMainBanner = async (id, data) =>
  await PUT(`/admin/mobile/main/banner/${id}`, data);

export const deleteMobileMainBanner = async (id) => {
  const ids = id.join(",");
  await DELETE(`/admin/mobile/main/banner?bannerIds=${ids}`);
};

export const changeSubOrder = async ({ data, device }) => {
  await PUT(`/admin/${device}/middle/banner/order`, data);
};

export const subBanner = async () => await GET("/admin/pc/middle/banner");

export const createSubBanner = async (data) =>
  await POST("/admin/pc/middle/banner", data);

export const updateSubBanner = async (id, data) =>
  await PUT(`/admin/pc/middle/banner/${id}`, data);

export const deleteSubBanner = async (id) => {
  const ids = id.join(",");
  await DELETE(`/admin/pc/middle/banner?bannerIds=${ids}`);
};

export const mobileSubBanner = async () =>
  await GET("/admin/mobile/middle/banner");

export const createMobileSubBanner = async (data) =>
  await POST("/admin/mobile/middle/banner", data);

export const updateMobileSubBanner = async (id, data) =>
  await PUT(`/admin/mobile/middle/banner/${id}`, data);

export const deleteMobileSubBanner = async (id) => {
  const ids = id.join(",");
  await DELETE(`/admin/mobile/middle/banner?bannerIds=${ids}`);
};

// 팝업
export const popup = async (title = "", page = "", pageSize = "") =>
  await GET(`/admin/popup?title=${title}&page=${page}&size=${pageSize}`);

// 팝업 생성
export const createPopup = async (data) => await POST("/admin/popup", data);

// 팝업 상세
export const popupDetail = async (popupId) =>
  await GET(`/admin/popup/${popupId}`);

// 팝업 수정
export const updatePopup = async (popupId, data) =>
  await PUT(`/admin/popup/${popupId}`, data);

// 팝업 삭제
export const deletePopup = async (id) => {
  const ids = id.join(",");
  await DELETE(`/admin/popup?ids=${ids}`);
};

export const getMainBanner = async (device) =>
  await GET(`/admin/${device}/main/banner`);

export const getMiddleBanner = async (device) =>
  await GET(`/admin/${device}/middle/banner`);
