import Box from "component/atoms/box";
import Text from "component/atoms/text";
import React, { useState, useEffect } from "react";
import AdminButton from "component/molecules/admin/button/adminButton";
import Image from "component/atoms/image";
import TableData from "component/atoms/tableData";
import TableHead from "component/atoms/tableHead";
import Table from "component/atoms/table";
import AdminCheckbox from "component/molecules/adminCheckbox";
import useToggle from "hook/util/useToggle";
import Link from "component/atoms/link";
import AdminAlertModal from "../../modal/alertModal";
import useSpecial from "hook/api/main/special";
import {
  useAdminSpecialList,
  useAdminTourList,
  useUpdateAdminSpecialList,
  useUpdateAdminSpecialOrder,
  useUpdateAdminTourList,
  useUpdateAdminTourOrder,
} from "hook/api/admin/story/exposeToMain";
import {
  DragDropContext,
  Droppable,
  Draggable,
  DropResult,
} from "@hello-pangea/dnd";
import useEditStore from "store/useEditStore";

const ExposedContents = () => {
  const { toggle, handleToggle } = useToggle();
  // 기획전 || 이야기 둘러보기 버튼 상태 관리
  const [selected, setSelected] = useState("exhibition");

  // 기획전 || 이야기 둘러보기 버튼 클릭 시
  const handleSelect = (button) => {
    if (
      orderedList.length > 0 &&
      !window.confirm("변경 사항을 저장하지 않고 이동하시겠습니까?")
    ) {
      return;
    }

    clearEdit();
    setSelected(button);
    setOrderedList([]);
    setSelectedUser({});
    setEdit(false);

    if (selected === "exhibition") {
      refetchSpecialList(); // 기획전 데이터 새로고침
    } else {
      refetchTourList(); // 이야기 둘러보기 데이터 새로고침
    }
  };

  // 기획전 || 이야기 둘러보기 버튼 클릭 시 테마 전환
  const getButtonTheme = (button) => {
    if (selected === button) {
      return ["subButton"];
    } else {
      return ["subButton", "whiteColor400"];
    }
  };

  const {
    data: specialList,
    totalItems: total,
    refetch: refetchSpecialList,
  } = useAdminSpecialList();

  const {
    data: tourList,
    totalItems: tourTotal,
    refetch: refetchTourList,
  } = useAdminTourList();

  // 저장 알림 모달창 상태 관리
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(false);
  const [tourSuccess, setTourSuccess] = useState(false);
  const [tourError, setTourError] = useState(false);
  // 순서변경 버튼 상태 관리
  const [edit, setEdit] = useState(false);

  const handleSave = () => {
    setVisible(true);
    setEdit(!edit);
  };

  const handleEdit = () => {
    setEdit(!edit);
  };

  const handleEditCancel = () => {
    if (orderedList.length > 0) {
      if (window.confirm("순서 변경을 취소하시겠습니까?")) {
        setEdit(!edit);
        setOrderedList([]);
        setSelectedUser({});
        clearEdit();

        if (selected === "exhibition") {
          refetchSpecialList(); // 기획전 데이터 새로고침
        } else {
          refetchTourList(); // 이야기 둘러보기 데이터 새로고침
        }
      }
    } else {
      setEdit(!edit);
    }
  };

  const handleSuccess = () => {
    setVisible(true);
  };

  const handleError = () => {
    setError(true);
  };

  const handleTourSuccess = () => {
    setTourSuccess(true);
  };

  const handleTourError = () => {
    setTourError(true);
  };

  const { mutate: changeOrder } = useUpdateAdminSpecialOrder(
    handleSuccess,
    handleError
  );

  const { mutate: changeTourOrder } = useUpdateAdminTourOrder(
    handleTourSuccess,
    handleTourError
  );

  // 순서 변경 하다가 페이지 이동 혹은 카테고리 클릭 시 변경 사항 저장 안내
  const { updateEdit, clearEdit } = useEditStore();
  // 드래그 앤 드롭
  const [orderedList, setOrderedList] = useState([]);
  const onDragEnd = ({ draggableId, destination, source }) => {
    if (!destination) return;
    if (destination.index === source.index) return;

    const list = selected === "exhibition" ? specialList : tourList;

    // 인덱스를 사용하여 이동할 항목 찾기
    const item = list[source.index];
    if (!item) return;

    // 리스트에서 항목을 삭제하고 새 위치에 삽입
    list.splice(source.index, 1);
    list.splice(destination.index, 0, item);

    setOrderedList(list);
    updateEdit();
  };

  const handleSaveOrder = () => {
    const list = selected === "exhibition" ? specialList : tourList;
    const orderedList = list.map((data) => data.storyId);
    if (selected === "exhibition") {
      changeOrder({ special: orderedList });
    } else {
      changeTourOrder({ tour: orderedList });
    }
    setEdit(!edit);
    setOrderedList([]);
    clearEdit();
  };

  // 체크박스를 선택하여 일괄 승인
  const [selectedUser, setSelectedUser] = useState({});

  useEffect(() => {
    setSelectedUser({});
  }, []);

  // 전체 선택 또는 해제 처리
  const handleSelectAll = (e) => {
    const checked = e.target.checked;
    setSelectedUser((prevSelectedUser) => {
      const newSelectedUser = {};
      if (checked) {
        if (selected === "exhibition") {
          specialList.forEach((data) => {
            newSelectedUser[data.storyId] = true;
          });
        } else {
          tourList.forEach((data) => {
            newSelectedUser[data.storyId] = true;
          });
        }
      }
      return newSelectedUser;
    });
  };

  // 개별 선택 처리
  const handleSelectCheckBox = (e, storyId) => {
    const checked = e.target.checked;
    setSelectedUser((prevSelectedUser) => {
      const newSelectedUser = { ...prevSelectedUser };
      newSelectedUser[storyId] = checked;
      return newSelectedUser;
    });
  };

  // 차단 성공, 실패 모달
  const [successDelete, setSuccessDelete] = useState(false);
  const [errorDelete, setErrorDelete] = useState(false);

  const handleSuccessDelete = () => {
    setSuccessDelete(true);
  };

  const handleErrorDelete = () => {
    setErrorDelete(true);
  };

  const selectedUserIds = Object.entries(selectedUser)
    .filter(([_, checked]) => checked)
    .map(([storyId]) => storyId);

  const { mutate: deleteStory } = useUpdateAdminSpecialList(
    handleSuccessDelete,
    handleErrorDelete
  );

  const { mutate: deleteTour } = useUpdateAdminTourList(
    handleSuccessDelete,
    handleErrorDelete
  );

  const handleDelete = () => {
    // 게시물 최소 1개 남겨서 서버 에러 막기
    const currentList = selected === "exhibition" ? specialList : tourList;

    // 선택된 게시물이 없거나, 모든 게시물이 선택된 경우
    if (selectedUserIds.length === currentList.length) {
      alert("최소 1개의 이야기는 남겨야 합니다.");
      return;
    }

    if (selectedUserIds.length === 0) {
      alert("삭제할 이야기를 선택해주세요.");
      return;
    }

    // 나머지 삭제 로직
    if (window.confirm("선택한 이야기를 진열하지 않겠습니까?")) {
      if (selected === "exhibition") {
        deleteStory(selectedUserIds);
      } else {
        deleteTour(selectedUserIds);
      }
      setSelectedUser({});
    }
  };

  const [isDragging, setIsDragging] = useState(false);

  const [draggingItemId, setDraggingItemId] = useState(null);

  const handleMouseDown = (e, itemId) => {
    if (edit) {
      setDraggingItemId(itemId); // 드래그 중인 아이템 ID 설정
      setIsDragging(true);
    }
  };

  const handleMouseUp = () => {
    // if (edit) {
    //   setDraggingItemId(null); // 드래그 중인 아이템 ID 해제
    //   setIsDragging(false);
    // }
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      // 드래그 중인 항목을 처리하는 로직
    }
  };

  useEffect(() => {
    setDraggingItemId(null); // 드래그 중인 아이템 ID 해제
    setIsDragging(false);
  }, [JSON.stringify(specialList)]);

  return (
    <>
      <AdminAlertModal
        title={"메인 노출 관리"}
        text={
          error
            ? "기획전 노출 순서 변경에 실패했습니다."
            : tourError
            ? "이야기 둘러보기 노출 순서 변경에 실패했습니다."
            : tourSuccess
            ? "이야기 둘러보기 노출 순서 변경에 성공했습니다."
            : successDelete
            ? "선택한 이야기가 목록에서 성공적으로 삭제되었습니다."
            : errorDelete
            ? "선택한 이야기 삭제에 실패했습니다."
            : visible
            ? "기획전 노출 순서 변경에 성공했습니다."
            : "이야기 둘러보기 노출 순서 변경에 성공했습니다."
        }
        btn_text="확인"
        visible={
          visible ||
          error ||
          successDelete ||
          errorDelete ||
          tourSuccess ||
          tourError
        }
        toggle={() => {
          setVisible(false);
          setError(false);
          setSuccessDelete(false);
          setErrorDelete(false);
          setTourSuccess(false);
          setTourError(false);
        }}
      />
      <Box
        size={{
          width: "100%",
          height: "auto",
        }}
        margin={{
          top: "25px",
        }}
        other={{
          overflowX: "hidden",
        }}
      >
        <Text theme={"adminTitle"}>메인 노출 영역</Text>
        <Box
          display={{
            display: "flex",
          }}
        >
          <AdminButton
            category="button"
            theme={getButtonTheme("exhibition")}
            onClick={() => handleSelect("exhibition")}
          >
            기획전
          </AdminButton>
          <AdminButton
            category="button"
            theme={getButtonTheme("story")}
            onClick={() => handleSelect("story")}
            margin={{
              left: "10px",
            }}
          >
            이야기 둘러보기
          </AdminButton>
        </Box>
        {/* 회원 목록 */}
        <Box
          size={{
            width: "100%",
            height: "auto",
          }}
          margin={{
            top: "40px",
          }}
        >
          <Text theme={"adminTitle"}>메인 노출 목록</Text>
          <Box
            size={{
              width: "1024px",
              height: "auto",
            }}
            border={{
              top: "1px",
              left: "1px",
              right: "1px",
              type: "solid",
              color: "gray10",
              radius: "3px 3px 0 0",
            }}
          >
            {/* 목록 상단 인원 수, 차단 버튼 */}
            <Box
              theme="adminListBox"
              size={{
                height: "44px",
              }}
              padding={{
                left: "16px",
              }}
            >
              <Text
                as="span"
                typo={{
                  size: "13px",
                  weight: "500",
                  color: "black02",
                }}
              >
                총{" "}
                <Text
                  as="span"
                  typo={{
                    color: "blue01",
                  }}
                >
                  {selected === "exhibition" ? total : tourTotal}
                </Text>{" "}
                개
              </Text>
            </Box>
            <Box
              theme="adminListBox"
              size={{
                height: "44px",
              }}
              padding={{
                left: "8px",
              }}
              border={{
                top: "1px",
                style: "solid",
                color: "gray10",
              }}
            >
              <AdminButton
                category="button"
                theme="listOptionButton"
                size={{
                  width: "78px",
                  height: "28px",
                }}
                padding={{
                  left: "4px",
                  right: "6px",
                }}
                onClick={handleDelete}
              >
                <Image
                  src="/asset/img/admin/button/red_X.svg"
                  margin={{
                    right: "5px",
                  }}
                />
                진열안함
              </AdminButton>
            </Box>
          </Box>
          {/* 회원 목록 리스트 */}
          <Table isAttached={true} margin={{ bottom: "40px" }}>
            <thead>
              <tr>
                <TableHead width="42px" padding="8px 0">
                  <AdminCheckbox
                    margin={{
                      left: "13px",
                    }}
                    value="all"
                    readOnly={
                      specialList?.length === 0 || tourList?.length === 0
                    }
                    checked={
                      selected === "exhibition"
                        ? Object.keys(selectedUser).length ===
                            specialList?.length &&
                          Object.values(selectedUser).every(
                            (checked) => checked
                          )
                        : Object.keys(selectedUser).length ===
                            tourList?.length &&
                          Object.values(selectedUser).every(
                            (checked) => checked
                          )
                    }
                    onClick={handleSelectAll}
                  />
                </TableHead>
                <TableHead width="72px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    진열순서
                  </Text>
                </TableHead>

                <TableHead width="121px">
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    창안자 이름
                  </Text>
                </TableHead>

                <TableHead
                  width="calc(1024px - 42px - 72px - 121px)"
                  isLastChild
                >
                  <Text
                    typo={{
                      color: "black02",
                    }}
                  >
                    제목
                  </Text>
                </TableHead>
              </tr>
            </thead>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable-1">
                {(provided, snapshot) => (
                  <tbody
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    style={{
                      maxHeight: "472px",
                      display: "block",
                      overflowY: "auto",
                      overflowX: "hidden",
                    }}
                  >
                    {selected === "exhibition"
                      ? specialList?.map((data, index) => (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}
                            isDragDisabled={!edit}
                          >
                            {(provided, snapshot) => (
                              <tr
                                style={{
                                  display: "table",
                                  width: "1024px",
                                  tableLayout: "fixed",
                                }}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                {...provided.placeholder}
                              >
                                <TableData
                                  // onMouseDown={(e) =>
                                  //   handleMouseDown(e, data.storyId)
                                  // }
                                  // onMouseUp={handleMouseUp}
                                  onClick={() => setIsDragging(true)}
                                  size={{ width: "42px", height: "63px" }}
                                  border={{
                                    left:
                                      draggingItemId === data.storyId
                                        ? "0.1px"
                                        : "0px",
                                    bottom:
                                      draggingItemId === data.storyId
                                        ? "0.1px"
                                        : "0px",
                                    top: "1px",
                                    right: "1px",
                                    style: "solid",
                                    color: "gray10",
                                  }}
                                >
                                  <AdminCheckbox
                                    margin={{
                                      left: "13px",
                                    }}
                                    checked={selectedUser[data.storyId]}
                                    onClick={(e) =>
                                      handleSelectCheckBox(e, data.storyId)
                                    }
                                    value={data.storyId}
                                  />
                                </TableData>
                                <TableData
                                  // onMouseDown={(e) =>
                                  //   handleMouseDown(e, data.storyId)
                                  // }
                                  // onMouseUp={handleMouseUp}
                                  // onMouseMove={(e) =>
                                  //   handleMouseDown(e, data.storyId)
                                  // }
                                  size={{ width: "72px" }}
                                  border={{
                                    left:
                                      draggingItemId === data.storyId
                                        ? "0.1px"
                                        : "0px",
                                    bottom:
                                      draggingItemId === data.storyId
                                        ? "0.1px"
                                        : "0px",
                                    top: "1px",
                                    right: "1px",
                                    style: "solid",
                                    color: "gray10",
                                  }}
                                >
                                  {index + 1}
                                </TableData>
                                <TableData
                                  // onMouseDown={(e) =>
                                  //   handleMouseDown(e, data.storyId)
                                  // }
                                  // onMouseUp={handleMouseUp}
                                  size={{ width: "121px" }}
                                  border={{
                                    left:
                                      draggingItemId === data.storyId
                                        ? "0.1px"
                                        : "0px",
                                    bottom:
                                      draggingItemId === data.storyId
                                        ? "0.1px"
                                        : "0px",
                                    right: "1px",
                                    top: "1px",
                                    style: "solid",
                                    color: "gray10",
                                  }}
                                >
                                  <Text
                                    size={{
                                      width: "121px",
                                    }}
                                    typo={{
                                      lineClamp: 1,
                                      wordBreak: "break-all",
                                    }}
                                    padding={{
                                      left: "8px",
                                      right: "8px",
                                    }}
                                  >
                                    {data.memberName}
                                  </Text>
                                </TableData>

                                <TableData
                                  // onMouseDown={(e) =>
                                  //   handleMouseDown(e, data.storyId)
                                  // }
                                  // onMouseUp={handleMouseUp}
                                  size={{
                                    width: "calc(1024px - 42px - 72px - 121px)",
                                  }}
                                  isLastChild
                                  border={{
                                    left:
                                      draggingItemId === data.storyId
                                        ? "0.1px"
                                        : "0px",
                                    bottom:
                                      draggingItemId === data.storyId
                                        ? "0.1px"
                                        : "0px",
                                    top: "1px",
                                    style: "solid",
                                    color: "gray10",
                                  }}
                                  other={{
                                    cursor: edit ? "grab" : "default",
                                  }}
                                >
                                  {data.storyOpen === 1 && (
                                    <Image
                                      src="/asset/img/admin/lock.svg"
                                      margin={{
                                        left: "-15px",
                                        top: "1px",
                                      }}
                                      position={{
                                        type: "absolute",
                                      }}
                                    />
                                  )}
                                  <Link
                                    link={`/story/${data.storyId}`}
                                    target="_blank"
                                    size={{
                                      width: "70%",
                                      height: "auto",
                                    }}
                                    margin={{
                                      left: "14px",
                                    }}
                                    typo={{
                                      align: "left",
                                      lineClamp: 2,
                                    }}
                                  >
                                    {data.storyTitle}
                                  </Link>
                                </TableData>
                              </tr>
                            )}
                          </Draggable>
                        ))
                      : tourList?.map((data, index) => (
                          <Draggable
                            key={index}
                            draggableId={index.toString()}
                            index={index}
                            isDragDisabled={!edit}
                          >
                            {(provided, snapshot) => (
                              <tr
                                style={{
                                  display: "table",
                                  width: "1024px",
                                  tableLayout: "fixed",
                                }}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                {...provided.placeholder}
                              >
                                <TableData
                                  size={{ width: "42px", height: "63px" }}
                                  border={{
                                    top: "1px",
                                    right: "1px",
                                    style: "solid",
                                    color: "gray10",
                                  }}
                                >
                                  <AdminCheckbox
                                    margin={{
                                      left: "13px",
                                    }}
                                    checked={selectedUser[data.storyId]}
                                    onClick={(e) =>
                                      handleSelectCheckBox(e, data.storyId)
                                    }
                                    value={data.storyId}
                                  />
                                </TableData>
                                <TableData
                                  size={{ width: "72px" }}
                                  border={{
                                    top: "1px",
                                    right: "1px",
                                    style: "solid",
                                    color: "gray10",
                                  }}
                                >
                                  {index + 1}
                                </TableData>
                                <TableData
                                  size={{ width: "121px", height: "63px" }}
                                  border={{
                                    top: "1px",
                                    right: "1px",
                                    style: "solid",
                                    color: "gray10",
                                  }}
                                >
                                  <Text
                                    size={{
                                      width: "121px",
                                    }}
                                    typo={{
                                      lineClamp: 1,
                                      wordBreak: "break-all",
                                    }}
                                    padding={{
                                      left: "8px",
                                      right: "8px",
                                    }}
                                  >
                                    {data.memberName}
                                  </Text>
                                </TableData>

                                <TableData
                                  size={{
                                    width: "calc(1024px - 42px - 72px - 121px)",
                                  }}
                                  border={{
                                    top: "1px",
                                    style: "solid",
                                    color: "gray10",
                                  }}
                                  isLastChild
                                >
                                  <Link
                                    link={`/story/${data.storyId}`}
                                    target="_blank"
                                    size={{
                                      width: "70%",
                                      height: "auto",
                                    }}
                                    margin={{
                                      left: "14px",
                                    }}
                                    typo={{
                                      align: "left",
                                      lineClamp: 2,
                                    }}
                                  >
                                    {data.storyTitle}
                                  </Link>
                                </TableData>
                              </tr>
                            )}
                          </Draggable>
                        ))}
                    {provided.placeholder}
                  </tbody>
                )}
              </Droppable>
            </DragDropContext>
          </Table>
        </Box>
        <Box theme="adminRight" margin={{ top: "-25px" }}>
          {!edit && (
            <AdminButton
              category="button"
              theme={["subButton", "whiteColor"]}
              onClick={handleEdit}
            >
              순서변경
            </AdminButton>
          )}

          {edit && (
            <Box
              display={{
                display: "flex",
              }}
            >
              <AdminButton
                category="button"
                theme={["mainButton", "grayColor"]}
                margin={{
                  right: "10px",
                }}
                onClick={handleEditCancel}
              >
                취소
              </AdminButton>
              <AdminButton
                category="button"
                theme={["mainButton"]}
                onClick={handleSaveOrder}
              >
                저장
              </AdminButton>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default ExposedContents;
