import React, { useEffect, useRef, useState } from "react";
import Box from "component/atoms/box";
import Image from "component/atoms/image";
import Slider, { nextSlide, prevSlide, slideTo } from "component/atoms/slider";
import Button from "component/atoms/button";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import Link from "component/atoms/link";
import convertImage from "utils/convertImage";
import useDeviceStore from "store/useDeviceStore";

function addHttpsUsingRegex(url) {
  if (/^www\./.test(url)) {
    return "https://" + url;
  }
  return url;
}

const MainSlider = (props) => {
  const { slide } = props;
  const sliderRef = useRef(null);
  const [activeIndex, setActiveIndex] = useState(0);
  const { longMobile } = useDeviceStore();

  const handleSlideChange = (index) => {
    slideTo(sliderRef)(index);
  };

  const settings = {
    autoplay: true,
    loop: true,
  };

  const selectSlide = slide[activeIndex];
  const ButtonBox = selectSlide?.bannerLink ? Link : Box;

  useEffect(() => {
    setActiveIndex(0);
  }, [longMobile]);

  return (
    slide[activeIndex] && (
      <Box
        as={"section"}
        theme={"basic"}
        size={{
          height: "440px",
          aspectRatio: "1200/440",
        }}
        position={{
          type: "relative",
        }}
        margin={{
          all: "0 auto",
        }}
        display={{
          display: "flex",
        }}
        pc={{
          padding: {
            all: 0,
          },
        }}
        longMobile={{
          size: {
            aspectRatio: "1/1",
          },
        }}
      >
        <Slider ref={sliderRef} setActiveIndex={setActiveIndex} {...settings}>
          {slide.map(({ title, bannerImage }) => (
            <Slider.Slide key={title}>
              <Image
                src={convertImage(bannerImage)}
                alt="슬라이더"
                size={{
                  width: "100%",
                  height: "100%",
                }}
              />
            </Slider.Slide>
          ))}
        </Slider>

        <Box
          position={{
            type: "absolute",
            top: "0",
            bottom: "0",
            left: "0",
            right: "0",
            zIndex: "1",
          }}
          other={{
            opacity: "0",
          }}
          transform={{
            transition: "opacity 0.3s",
          }}
          hover={{
            other: {
              opacity: "1",
            },
          }}
          mobile={{
            other: {
              opacity: "1",
            },
          }}
        >
          <ButtonBox
            size={{
              width: "100%",
              height: "100%",
            }}
            display={{
              display: "flex",
              align: "center",
              justify: "space-between",
            }}
            padding={{
              all: "0 15px",
            }}
            link={addHttpsUsingRegex(selectSlide.bannerLink)}
            target={selectSlide.newWindows === 1 ? "_blank" : "_self"}
          >
            {slide.length !== 1 && (
              <>
                <Button
                  display={{
                    display: "flex",
                    justify: "center",
                    align: "center",
                  }}
                  size={{
                    width: "28px",
                    height: "28px",
                  }}
                  border={{
                    all: "1px",
                  }}
                  background={{
                    color: "opacityWhite",
                  }}
                  onClick={prevSlide(sliderRef)}
                >
                  <BiChevronLeft size={28} />
                </Button>
                <Button
                  display={{
                    display: "flex",
                    justify: "center",
                    align: "center",
                  }}
                  size={{
                    width: "28px",
                    height: "28px",
                  }}
                  border={{
                    all: "1px",
                  }}
                  background={{
                    color: "opacityWhite",
                  }}
                  onClick={nextSlide(sliderRef)}
                >
                  <BiChevronRight size={28} />
                </Button>
              </>
            )}
          </ButtonBox>
        </Box>

        {slide.length !== 1 && (
          <Box
            display={{
              display: "flex",
              gap: "10px",
            }}
            position={{
              type: "absolute",
              isCenterX: true,
              bottom: "20px",
              zIndex: "1",
            }}
          >
            {slide.map((_, idx) => (
              <Button
                size={{
                  width: "10px",
                  height: "10px",
                }}
                border={{
                  all: "1px",
                  radius: "50%",
                }}
                background={{
                  color: activeIndex === idx ? "pink02" : "white",
                }}
                onClick={() => handleSlideChange(idx)}
              />
            ))}
          </Box>
        )}
      </Box>
    )
  );
};

export default MainSlider;
