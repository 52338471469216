import Button from "component/atoms/button";
import Link from "component/atoms/link";
import { forwardRef } from "react";

const CATEGORY = {
  button: Button,
  link: Link,
};

const themeStyle = {
  whiteColor: {
    background: {
      color: "white01",
    },
    border: {
      all: "1px",
      style: "solid",
      color: "gray02",
    },
    typo: {
      color: "black01",
    },
  },
  whiteColor400: {
    background: {
      color: "white01",
    },
    border: {
      all: "1px",
      style: "solid",
      color: "gray02",
    },
    typo: {
      color: "black01",
      weight: "400",
    },
  },
  grayColor: {
    background: {
      color: "gray02",
    },
  },
  mainButton: {
    size: {
      width: "104px",
      height: "40px",
    },
    padding: {
      top: "12px",
      bottom: "12px",
    },
  },
  subButton: {
    size: {
      width: "116px",
      height: "40px",
    },
    padding: {
      top: "12px",
      bottom: "12px",
    },
  },
  longButton: {
    size: {
      width: "362px",
      height: "40px",
    },
    padding: {
      top: "12px",
      bottom: "12px",
    },
  },
  headerButton: {
    size: {
      width: "128px",
      height: "32px",
    },
    typo: {
      weight: "400",
    },
    padding: {
      top: "8px",
      bottom: "8px",
    },
  },
  listButton: {
    size: {
      width: "65px",
      height: "25px",
    },
    typo: {
      weight: "400",
    },
    padding: {
      top: "5px",
      bottom: "5px",
    },
  },
  wordButton: {
    size: {
      width: "auto",
      height: "30px",
    },
    typo: {
      size: "13px",
      weight: "400",
      color: "black02",
      align: "center",
    },
    border: {
      all: "1px",
      style: "solid",
      color: "black01",
      radius: "3px",
    },
    padding: {
      top: "5px",
      bottom: "5px",
      left: "10px",
      right: "5px",
    },
    margin: {
      right: "14px",
      top: "10px",
    },
    background: {
      color: "white01",
    },
  },
  listOptionButton: {
    border: {
      all: "1px",
      style: "solid",
      color: "#BBBBBB",
      radius: "3px",
    },
    typo: {
      size: "12px",
      weight: "500",
      color: "black01",
    },
    background: {
      color: "white01",
    },
  },
};

const themeMerge = (theme) => {
  if (typeof theme === "string") {
    return themeStyle[theme];
  } else if (Array.isArray(theme)) {
    return theme.reduce((acc, cur) => {
      const updateTheme = themeStyle[cur];

      Object.entries(updateTheme).forEach(([key, value]) => {
        if (acc[key] === undefined) {
          acc[key] = { ...value };
        } else {
          acc[key] = { ...acc[key], ...value };
        }
      });

      return {
        ...acc,
      };
    }, {});
  }
};

const styleMerge = (style, theme) => {
  const updateStyle = { ...style };
  if (theme) {
    Object.entries(theme).forEach(([key, value]) => {
      const currentStyle = updateStyle[key] ?? {};
      updateStyle[key] = {
        ...value,
        ...currentStyle,
      };
    });
  }

  return updateStyle;
};

const AdminButton = forwardRef((props, ref) => {
  const {
    category,
    //
    theme,
    //
    isWhite,
    isGray,
    children,
    ...option
  } = props;

  const CurrentCategory = CATEGORY[category];

  const currentTheme = themeMerge(theme);

  const { padding, margin, size, typo, border, background, hover } = styleMerge(
    props,
    currentTheme
  );

  return (
    <CurrentCategory
      display={{
        display: "flex",
        justify: "center",
        align: "center",
      }}
      padding={padding}
      margin={margin}
      border={{
        radius: "3px",
        ...border,
      }}
      background={{
        color: "pink03",
        ...background,
      }}
      typo={{
        size: "13px",
        weight: "700",
        color: "white01",
        ...typo,
      }}
      size={size}
      hover={hover}
      {...option}
    >
      {children}
    </CurrentCategory>
  );
});

export default AdminButton;
