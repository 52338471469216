import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  deleteStory,
  getStoryDetail,
  getStoryList,
  storyCheering,
  storyCheeringCancel,
  storyCheeringList,
  storyMatchStatus,
  storyUpdate,
} from "api/admin/story/storyManage";
import { useNavigate } from "react-router-dom";

export const useAdminStoryList = (
  storyStatus,
  cheeringState,
  classifiCation,
  total,
  storyTitle,
  memberName,
  startDate,
  endDate,
  pageSize,
  currentPage
) => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    [
      "adminStoryList",
      storyStatus,
      cheeringState,
      classifiCation,
      total,
      storyTitle,
      memberName,
      startDate,
      endDate,
      pageSize,
      currentPage,
    ],
    () =>
      getStoryList(
        storyStatus,
        cheeringState,
        classifiCation,
        total,
        storyTitle,
        memberName,
        startDate,
        endDate,
        pageSize,
        currentPage
      ),
    {
      onError: (error) => {
        if (error.response && error.response.status === 403) {
          alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
          navigate("/admin/login");
        }
      },
    }
  );

  return {
    data: data?.data,
    totalItems: data?.totalItems,
    totalPages: data?.totalPages,
    isLoading,
  };
};

export const useAdminStoryDetail = ({ storyId }) => {
  const navigate = useNavigate();
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["adminStoryDetail", storyId],
    () => getStoryDetail(storyId)
  );

  return {
    data: data?.data,
    isLoading,
    isError,
    isSuccess,
  };
};

export const useAdminStoryUpdate = (storyId, callback) => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation((data) => storyUpdate(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("adminStoryDetail");
      callback();
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        alert(error.response.data.message);
      } else {
        alert("오류가 발생했습니다. 다시 시도해주세요.");
      }
    },
  });

  const submit = (data) => {
    const formData = new FormData();

    for (const key in data) {
      formData.append(key, data[key]);
    }

    mutate({ storyId, data: formData });
  };

  return submit;
};

export const useAdminStoryCheering = ({ storyId }) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["adminStoryCheering", storyId],
    () => storyCheering({ storyId })
  );

  return {
    data: data?.data,
    isLoading,
    isError,
    isSuccess,
  };
};

export const useAdminStoryCheeringList = ({
  storyId,
  startDate,
  endDate,
  memberName,
  memberEmail,
  total,
  page,
  pageSize,
}) => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    [
      "adminStoryCheeringList",
      storyId,
      startDate,
      endDate,
      memberName,
      memberEmail,
      total,
      page,
      pageSize,
    ],
    () =>
      storyCheeringList({
        storyId,
        startDate,
        endDate,
        memberName,
        memberEmail,
        total,
        page,
        pageSize,
      })
  );

  return {
    data: {
      data: data?.data,
      maxPage: data?.totalPages,
      total: data?.totalItems,
    },
    isLoading,
    isError,
    isSuccess,
  };
};

export const useAdminStoryCheeringCancel = () => {
  const queryClient = useQueryClient();
  const { mutate } = useMutation(storyCheeringCancel, {
    onSuccess: () => {
      queryClient.invalidateQueries("adminStoryCheeringList");
    },
    onError: (error) => {
      if (error.response && error.response.status === 400) {
        alert(error.response.data.message);
      } else {
        alert("오류가 발생했습니다. 다시 시도해주세요.");
      }
    },
  });

  const submit = ({ cheerId, coinId }) => {
    mutate({ cheerId, coinId });
  };

  return submit;
};

export const useAdminStoryMatchStatus = ({ storyId }) => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    ["adminStoryMatchStatus", storyId],
    () => storyMatchStatus({ storyId })
  );

  return {
    data: data?.data,
    isLoading,
    isSuccess,
    isError,
  };
};

// 이야기 삭제
export const useDeleteStory = (onSuccessCallback, onErrorCallback) => {
  const queryClient = useQueryClient();
  const { mutate, data, isLoading } = useMutation(
    ({ storyId }) => deleteStory(storyId),
    {
      onSuccess: () => {
        if (onSuccessCallback && typeof onSuccessCallback === "function") {
          onSuccessCallback();
        }
        queryClient.invalidateQueries("adminStoryList");
      },
      onError: (error) => {
        if (onErrorCallback && typeof onErrorCallback === "function") {
          onErrorCallback(error);
        }
      },
    }
  );

  return {
    mutate,
    data,
    isLoading,
  };
};
