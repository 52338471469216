import Button from "component/atoms/button";
import AdminButton from "component/molecules/admin/button/adminButton";
import React from "react";
import margin from "style/utils/styles/margin";

const BottomMenuButton = ({ margin, text, onClick, isActive }) => {
  return (
    <Button
      display={{ display: "flex" }}
      size={{ width: "100%", height: "16px" }}
      typo={{
        weight: isActive ? "700" : "400",
        color: "pink03",
        align: "left",
      }}
      background={{ color: "transparent" }}
      padding={{ left: "69px" }}
      margin={margin}
      hover={{
        typo: {
          weight: "700",
        },
      }}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};

export default BottomMenuButton;
