import Box from "component/atoms/box";
import Text from "component/atoms/text";
import LabelText from "component/molecules/common/labelText";
import InputField from "component/organisms/common/inputField";
import LineTitle from "component/organisms/common/lineTitle";
import SignupForm from "../signupForm";
import useToggle from "hook/util/useToggle";
import Button from "component/atoms/button";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";

const ArtistInfo = () => {
  const { toggle, handleToggle } = useToggle();

  return (
    <Box
      size={{
        width: "100%",
      }}
      margin={{
        bottom: toggle ? "0px" : "50px",
      }}
    >
      <LineTitle
        title={"아티스트 정보"}
        typo={{
          size: "24px",
          weight: "700",
        }}
        margin={{
          bottom: "20px",
        }}
        longMobile={{
          margin: {
            bottom: "55px",
          },
        }}
      >
        <>
          <Button
            display={{
              display: "none",
            }}
            longMobile={{
              display: {
                display: "flex",
              },
            }}
            type={"button"}
            onClick={handleToggle}
          >
            {!toggle ? <BsChevronUp /> : <BsChevronDown />}
          </Button>
          <Text
            typo={{
              size: "14px",
              weight: "700",
              color: "pink01",
            }}
            longMobile={{
              position: {
                type: "absolute",
                top: "calc(100% + 10px)",
                right: "0",
              },
              typo: {
                size: "12px",
              },
            }}
          >
            * 필수 입력 내용입니다.
          </Text>
        </>
      </LineTitle>

      <Box
        display={{
          display: toggle ? "none" : "block",
        }}
      >
        <LabelText
          title={"디스코 그래피 (대표곡 2곡)"}
          typo={{
            size: "18px",
            weight: "700",
          }}
        />

        <SignupForm step={1} required />

        <SignupForm step={2} />

        {/* <Box
          margin={{
            bottom: "24px",
          }}
        >
          <InputField
            name="artist_name"
            title={"아티스트로 활동한 활동명"}
            type={"text"}
            size={{
              width: "100%",
              height: "40px",
            }}
            typo={{
              size: "18px",
              weight: "700",
            }}
          />
        </Box> */}

        <Box
          margin={{
            bottom: "24px",
          }}
        >
          <InputField
            name={"phone"}
            title={"연락처"}
            type={"number"}
            size={{
              width: "30%",
              height: "40px",
            }}
            typo={{
              size: "18px",
              weight: "700",
            }}
          />
        </Box>

        <Box
          margin={{
            bottom: "24px",
          }}
        >
          <InputField
            as={"textarea"}
            type={"text"}
            title={"아티스트 소개(200자 이내)"}
            name={"artist_intro"}
            size={{
              width: "100%",
              height: "80px",
            }}
            typo={{
              size: "18px",
              weight: "700",
            }}
            maxLength={200}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default ArtistInfo;
