import Box from "component/atoms/box";
import Line from "component/atoms/line";
import Link from "component/atoms/link";
import Text from "component/atoms/text";
import Api from "component/molecules/common/api";
import CommonButton from "component/molecules/common/commonButton";
import Modal from "component/molecules/common/modal";
import ProjectUser from "component/molecules/main/projectUser";
import LineTitle from "component/organisms/common/lineTitle";
import useCheeringCancel from "hook/api/mypage/cheeringCancel";
import useCheeringDetail from "hook/api/mypage/cheeringDetail";
import useToggle from "hook/util/useToggle";
import useUrl from "hook/util/useUrl";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";
import numberWithCommas from "utils/numberWithCommas";

const History = () => {
  const {
    query: { page = 1, detail },
  } = useUrl();

  const { toggle, handleToggle } = useToggle();
  const handleCancel = useCheeringCancel(handleToggle);

  return (
    <Api
      api={useCheeringDetail}
      params={{
        id: detail,
      }}
    >
      {(data) =>
        data && (
          <Box>
            <LineTitle title="상세내역">
              <Link
                link={`/mypage?page=${page}&category=support`}
                display={{
                  display: "flex",
                  align: "center",
                }}
                typo={{
                  size: "12px",
                  weight: "700",
                }}
              >
                <BiChevronLeft size={15} />
                돌아가기
              </Link>
            </LineTitle>

            <Box
              border={{
                all: "1px",
              }}
              padding={{
                all: "24px",
              }}
            >
              <Box
                display={{
                  display: "flex",
                  justify: "space-between",
                }}
              >
                <Box>
                  <Text
                    typo={{
                      size: "24px",
                      weight: "700",
                    }}
                  >
                    {data.storyTitle}
                  </Text>
                  <ProjectUser member={data.member} artist={data.artist} />
                </Box>
                <Box>
                  <CommonButton
                    category={"button"}
                    size={{
                      width: "72px",
                      height: "40px",
                    }}
                    onClick={handleToggle}
                  >
                    취소하기
                  </CommonButton>
                </Box>
              </Box>
              <Line
                direction="row"
                size={"100%"}
                background={{
                  color: "gray02",
                }}
                margin={{
                  top: "10px",
                  bottom: "20px",
                }}
              />
              <Box
                display={{
                  display: "flex",
                  justify: "space-between",
                  align: "flex-end",
                }}
              >
                <Text
                  typo={{
                    size: "24px",
                    weight: "700",
                  }}
                >
                  {numberWithCommas(data.coinValue)} Coin
                </Text>
                <Link
                  display={{
                    display: "flex",
                    align: "center",
                  }}
                  typo={{
                    size: "12px",
                    weight: "400",
                  }}
                  link={`/story/${detail}`}
                >
                  콘텐츠 자세히 보기
                  <BiChevronRight size={15} />
                </Link>
              </Box>
            </Box>

            <Modal
              visible={toggle}
              title={"응원 내역"}
              //
              onClose={handleToggle}
            >
              <Text
                margin={{
                  all: "60px 0",
                }}
                typo={{
                  size: "18px",
                  weight: "700",
                  align: "center",
                }}
              >
                응원을 취소하시겠습니까?
              </Text>

              <Box
                display={{
                  display: "flex",
                  justify: "space-between",
                  align: "center",
                  gap: "10px",
                }}
              >
                <CommonButton
                  size={{
                    width: "50%",
                    height: "63px",
                  }}
                  typo={{
                    size: "18px",
                  }}
                  category={"button"}
                  onClick={handleToggle}
                >
                  취소
                </CommonButton>
                <CommonButton
                  size={{
                    width: "50%",
                    height: "63px",
                  }}
                  typo={{
                    size: "18px",
                  }}
                  category={"button"}
                  isActive
                  onClick={() => handleCancel(data.storyId)}
                >
                  응원 취소
                </CommonButton>
              </Box>
            </Modal>
          </Box>
        )
      }
    </Api>
  );
};

export default History;
