import PropTypes from "prop-types";
import LinkStyle from "./link.style";
import useUrl from "hook/util/useUrl";

const Link = (props) => {
  const {
    className,
    //
    display,
    background,
    border,
    size,
    margin,
    padding,
    typo,
    pc,
    tablet,
    mobile,
    longMobile,
    hover,
    other,
    position,
    //
    themeStyle,
    //
    children,
    //
    link,
    target,
    disabled,
    preventScrollReset,
    onMouseEnter,
    onMouseLeave,
  } = props;

  const { pathname, search } = useUrl();

  return (
    <LinkStyle
      $position={position}
      $display={display}
      $background={background}
      $border={border}
      $size={size}
      $margin={margin}
      $padding={padding}
      $typography={typo}
      $other={other}
      $pc={pc}
      $tablet={tablet}
      $mobile={mobile}
      $longMobile={longMobile}
      $hover={hover}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      //
      $themeStyle={themeStyle}
      //
      to={disabled ? pathname + search : link}
      target={target}
      preventScrollReset={true}
      className={className}
    >
      {children}
    </LinkStyle>
  );
};

Link.propTypes = {
  link: PropTypes.string.isRequired,
  target: PropTypes.string,
  //
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
    PropTypes.string,
    PropTypes.oneOf([null, undefined]),
  ]),
  //
  ...LinkStyle.propTypes,
};

export default Link;
