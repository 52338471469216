import { deleteComment } from "api/story";
import { ErrorContent } from "component/organisms/common/globalModal";
import useUrl from "hook/util/useUrl";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";

const useDeleteComment = () => {
  const {
    params,
    query: { page },
    changeLink,
  } = useUrl();
  const queryClient = useQueryClient();
  const { setModal } = useModalStore();
  const deleteMutation = useMutation(deleteComment, {
    onSuccess: () => {
      queryClient.invalidateQueries("commentList");
      queryClient.invalidateQueries("commentInfinite");

      changeLink(`/story/${params.id}?page=${page}`);
    },
    onError: () => {
      setModal({
        title: "게시글 삭제",
        content: <ErrorContent />,
      });
    },
  });

  const submit = (id) => {
    deleteMutation.mutate(id);
  };

  return submit;
};

export default useDeleteComment;
