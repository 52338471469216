import { special } from "api/main";
import { useQuery } from "react-query";

const useSpecial = () => {
  const { data, isLoading, isError, isSuccess } = useQuery(
    ["special"],
    special
  );

  return {
    data: data?.data,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useSpecial;
