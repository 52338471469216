import Box from "component/atoms/box";
import Image from "component/atoms/image";
import Link from "component/atoms/link";
import Text from "component/atoms/text";
import convertDate from "utils/convertDate";

const Title = (props) => {
  const { type, name, date, isDetail, memberId } = props;

  return (
    <Box
      display={{
        display: "flex",
        justify: "space-between",
      }}
      longMobile={{
        padding: {
          all: "0 5px",
        },
      }}
    >
      <Link
        display={{
          display: "flex",
          align: "center",
          gap: "5px",
        }}
        link={`/mypage/${memberId}`}
      >
        <Image
          size={{
            width: "30px",
            height: "30px",
          }}
          src={`/asset/img/story/${type}.png`}
        />
        <Text
          typo={{
            size: "14px",
            weight: "500",
            lineClamp: "1",
          }}
        >
          {name}
        </Text>
      </Link>

      <Text
        display={{
          display: "flex",
          align: "center",
        }}
        typo={{
          size: "14px",
          weight: "400",
        }}
        longMobile={
          isDetail && {
            position: {
              type: "absolute",
              bottom: "10px",
              right: "10px",
            },
            typo: {
              size: "10px",
            },
          }
        }
      >
        {convertDate(date)}
      </Text>
    </Box>
  );
};

export default Title;
