import { API_VERSION, SERVER_URL } from "api/config";
import USER from "mock/datas/auth";
import { rest } from "msw";

const signupRequired = {
  name: true,
  intro: true,
  email: true,
  password: true,
  passwordConfirm: true,
  profile: false,
  isArtist: false,
  isPrivacy: true,
  isMarketing: false,
};

const signupArtistRequired = {
  first_music: true,
  // first_sound: true,
  second_music: true,
  // second_sound: true,
};

const loginHandler = rest.post(
  `${SERVER_URL}/api/${API_VERSION}/auth/login`,
  (req, res, ctx) => {
    const { email, password } = req.body;

    const user = USER.find(
      (user) => user.userId === email && user.password === password
    );

    if (!user) {
      return res(
        ctx.status(400),
        ctx.json({
          message:
            "가입된 회원아이디가 아니거나 비밀번호가 틀립니다.\n비밀번호는 대소문자를 구분합니다.",
        })
      );
    } else {
      return res(
        ctx.status(200),
        ctx.json({
          message: "로그인에 성공하였습니다.",
          data: user,
        })
      );
    }
  }
);

const signupHandler = rest.post(
  `${SERVER_URL}/api/${API_VERSION}/auth/signup`,
  (req, res, ctx) => {
    const {
      name,
      intro,
      email,
      password,
      profile,
      isArtist,
      first_music,
      first_link,
      first_file,
      second_music,
      second_link,
      second_file,
      isMarketing,
    } = req.body;

    Object.entries(signupRequired).forEach(([key, value]) => {
      if (value && !req.body[key]) {
        return res(
          ctx.status(400),
          ctx.json({
            message: `${key} is required`,
          })
        );
      }
    });

    if (isArtist) {
      Object.entries(signupArtistRequired).forEach(([key, value]) => {
        if (value && !req.body[key]) {
          return res(
            ctx.status(400),
            ctx.json({
              message: `${key} is required`,
            })
          );
        }
      });
    }
    return res(
      ctx.status(200),
      ctx.json({
        message: "회원가입에 성공하였습니다.",
        data: {
          name,
          intro,
          email,
          password,
          isArtist,
        },
      })
    );
  }
);

const findHandler = rest.post(
  `${SERVER_URL}/api/${API_VERSION}/auth/find`,
  (req, res, ctx) => {
    const { email } = req.body;

    const user = USER.find((user) => user.email === email);

    if (!user) {
      return res(
        ctx.status(400),
        ctx.json({
          message: "존재하지 않는 회원입니다.",
        })
      );
    } else {
      return res(
        ctx.status(200),
        ctx.json({
          message:
            "메일로 회원아이디와 비밀번호를 인증할 수 있는 메일이 발송 되었습니다. 메일을 확인하여 주십시오.",
          data: user,
        })
      );
    }
  }
);

const auth = [loginHandler, signupHandler, findHandler];

export default auth;
