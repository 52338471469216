import Box from "component/atoms/box";
import Text from "component/atoms/text";

const LabelText = (props) => {
  const {
    required,
    title,
    info,
    //
    display,
    typo,
  } = props;

  return (
    <Box
      position={{
        type: "relative",
      }}
      display={{
        display: "flex",
        wrap: "wrap",
        justify: "space-between",
        ...display,
      }}
      margin={{
        bottom: "8px",
      }}
      longMobile={{
        display: {
          justify: "flex-start",
          wrap: "wrap",
        },
      }}
    >
      <Text
        typo={{
          size: "16px",
          weight: "700",
          ...typo,
        }}
        longMobile={{
          typo: {
            size: "14px",
          },
        }}
      >
        {required && (
          <Text
            as={"span"}
            typo={{
              color: "pink01",
              fontFamily: "Spoqa Han Sans Neo",
            }}
            margin={{
              right: "3px",
            }}
          >
            *
          </Text>
        )}
        {title}
      </Text>
      {info && info}
    </Box>
  );
};

export default LabelText;
