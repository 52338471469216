import { useFormContext } from "react-hook-form";
import useModalStore from "store/useModalStore";

const list = {
  storyCategory: [
    "#사랑",
    "#우정",
    "#학교",
    "#가족",
    "#직장",
    "#웨딩",
    "#학문",
    "#환경",
    "#동식물",
    "#건강",
    "#음악",
    "#음식",
    "#꿈",
    "#가치관",
    "#일상",
    "#단체",
    "#BGM",
    "#여행",
    "#관계",
    "#기타",
  ],
  storyMood: [
    "잔잔한",
    "리드미컬",
    "신나는",
    "강렬한",
    "빠른",
    "무거운",
    "어두운",
    "재미있는",
    "힙한비트",
    "몽환적인",
    "춤추는",
    "나른한",
    "클래식한",
    "밝은",
    "느린",
    "부드러운",
    "편안한",
    "귀여운",
    "슬픈",
    "기타",
  ],
  storyPersonality: [
    "조용한",
    "이성적인",
    "혼자좋아",
    "신중한",
    "세심한",
    "부지런한",
    "쿨한",
    "까칠한",
    "활발한",
    "이상적인",
    "사람좋아",
    "자유로운",
    "대범한",
    "여유로운",
    "웃긴",
    "기타",
  ],
};

const formFunction = {
  storyCategory: (setValue, currentCategory) => (category) => {
    let value = category;

    if (currentCategory === category) {
      value = "";
    }

    setValue("storyCategory", value);
  },

  storyMood: (setValue, currentMood, setError) => (mood) => {
    let value = currentMood;

    if (currentMood.includes(mood)) {
      value = currentMood.filter((item) => item !== mood);
    } else {
      if (currentMood.length < 2) {
        value = [...currentMood, mood];
      } else {
        setError({
          title: "이야기 등록",
          content: "분위기는 2개까지 선택할 수 있습니다.",
        });
      }
    }

    setValue("storyMood", value);
  },

  storyPersonality: (setValue, currentPersonality) => (personality) => {
    let value = personality;

    if (currentPersonality.includes(personality)) {
      value = currentPersonality.filter((item) => item !== personality);
    } else {
      value = [...currentPersonality, personality];
    }

    setValue("storyPersonality", value);
  },
};

const useSelectForm = (name) => {
  const { setValue, watch } = useFormContext();
  const { setModal } = useModalStore();

  const currentList = watch(name);

  return {
    list: list[name],
    currentList,
    handleUpdate: formFunction[name](setValue, currentList, setModal),
  };
};

export default useSelectForm;
