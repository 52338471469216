import { getStory, getStoryWrite } from "api/story";
import { useQuery } from "react-query";

export const useStory = (props) => {
  const { id } = props;

  const { data, isSuccess, isLoading, isError } = useQuery(["story", id], () =>
    getStory(id)
  );

  return { data: data?.data, isSuccess, isLoading, isError };
};
