import { adminLogin } from "api/admin/auth";
import useUrl from "hook/util/useUrl";
import { useMutation } from "react-query";
import { useContext } from "react";
import { useAuth } from "context";
import cookie from "react-cookies";

const useAdminLogin = () => {
  const { changeLink } = useUrl();
  const { login } = useAuth();
  const mutation = useMutation((data) => adminLogin(data), {
    onSuccess: ({ data, jwtProvider }) => {
      const response = data;
      if (response.roles.name.includes("ADMIN")) {
        cookie.save("moomoostJwt", jwtProvider, {
          path: "/",
        });

        login(response);
        window.location.href = "/admin/dashboard";
      } else {
        alert("관리자 계정이 아닙니다. 관리자 계정으로 로그인해주세요.");
      }
    },
    onError: (error) => {
      if (error.response.status === 401) {
        alert("아이디 또는 비밀번호가 일치하지 않습니다.");
      } else if (error.response.status === 500) {
        alert("서버에 오류가 발생했습니다.");
      } else {
        alert("알 수 없는 오류가 발생했습니다.");
      }
    },
  });
  return mutation;
};

export default useAdminLogin;
