import { checkLoading } from "api/auth";
import { useQuery } from "react-query";

const useCheckLogin = () => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    "checkLogin",
    checkLoading
  );

  return { data: data, isSuccess, isLoading, isError };
};

export default useCheckLogin;
