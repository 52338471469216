import Box from "component/atoms/box";
import LineTitle from "component/organisms/common/lineTitle";
import CommonButton from "component/molecules/common/commonButton";
import StoryComment from "component/organisms/detail/storyComment";
import Blank from "component/molecules/common/blank";
import useUrl from "hook/util/useUrl";
import { useEffect } from "react";
import StorySelect from "../storySelect";
import Api from "component/molecules/common/api";
import useSelectComment from "hook/api/story/selectComment";
import useDeleteComment from "hook/api/story/deleteComment";

const StoryContent = (props) => {
  const {
    isCreator,
    isMatchedArtist,
    isMatchedAssist,
    commentList,
    requiredForm,
  } = props;
  const {
    params: { id },
    query: { comment },
  } = useUrl();

  const deleteComment = useDeleteComment();

  const selectList = commentList.find(
    ({ boardId }) => boardId.toString() === comment
  );

  const isPass = isCreator || isMatchedArtist || isMatchedAssist;

  return (
    <>
      <Box
        margin={{
          top: "80px",
        }}
        longMobile={{
          padding: {
            all: "0 16px",
          },
        }}
      >
        <LineTitle title="창작자 게시글">
          {!selectList && (isCreator || isMatchedArtist || isMatchedAssist) && (
            <CommonButton
              category={"link"}
              size={{
                width: "104px",
                height: "40px",
              }}
              typo={{
                size: "14px",
              }}
              link={`comment`}
            >
              새글 작성하기
            </CommonButton>
          )}
          {selectList?.isWrited && (
            <Box
              display={{
                display: "flex",
                gap: "5px",
              }}
            >
              <CommonButton
                category={"link"}
                size={{
                  width: "46px",
                  height: "40px",
                }}
                typo={{
                  size: "14px",
                }}
                link={`comment?id=${comment}`}
              >
                수정
              </CommonButton>
              <CommonButton
                category={"button"}
                size={{
                  width: "46px",
                  height: "40px",
                }}
                typo={{
                  size: "14px",
                }}
                onClick={() => deleteComment(comment)}
              >
                삭제
              </CommonButton>
            </Box>
          )}
        </LineTitle>
      </Box>

      <Box
        display={{
          display: "grid",
          gridTemplateColumns:
            comment || commentList.length === 0
              ? "repeat(1,1fr)"
              : "repeat(2, 1fr)",
          gap: "40px",
          wrap: "wrap",
        }}
        longMobile={{
          display: {
            gridTemplateColumns: "repeat(1,1fr)",
            gap: "20px",
          },
          padding: {
            all: "0 16px",
          },
        }}
      >
        {comment ? (
          <Api
            api={useSelectComment}
            params={{
              storyId: id,
              boardId: comment,
            }}
          >
            {(data) => data && <StorySelect select={data} isPass={isPass} />}
          </Api>
        ) : (
          <>
            {commentList.length === 0 && (
              <Blank title={"등록된 게시글이 없습니다."} />
            )}
            {commentList.map((item, index) => (
              <StoryComment
                key={item?.boardId}
                comment={item}
                isPass={isPass}
              />
            ))}
          </>
        )}
      </Box>
    </>
  );
};

export default StoryContent;
