import { support } from "api/story";
import Text from "component/atoms/text";
import { ErrorContent } from "component/organisms/common/globalModal";
import useToggle from "hook/util/useToggle";
import useUrl from "hook/util/useUrl";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";
import checkRequired from "utils/checkRequired";

const required = {
  coin: true,
};

const SuccessContent = () => {
  return (
    <>
      <Text
        typo={{
          size: "18px",
          weight: "700",
          align: "center",
        }}
      >
        응원하기가 완료되었습니다.
      </Text>
      <Text
        typo={{
          size: "14px",
          weight: "400",
          align: "center",
          color: "gray04",
        }}
      >
        자세한 사항은 [ 프로필 &gt; 응원하기 ] 에서 확인해주세요.
      </Text>
    </>
  );
};

const SuccessInfo = () => {
  return (
    <Text
      size={{
        width: "100%",
      }}
      typo={{
        size: "14px",
        weight: "400",
        color: "gray04",
      }}
      longMobile={{
        typo: {
          size: "12px",
        },
      }}
    >
      좋아하는 이야기가 좋은 음악이 될 수 있도록 응원해주세요!
    </Text>
  );
};

const useSupportForm = (handleToggle, funding) => {
  const form = useForm({
    defaultValues: {
      coin: "",
    },
  });
  const { watch, handleSubmit } = form;
  const { setModal } = useModalStore();
  const {
    params: { id },
  } = useUrl();
  const queryClient = useQueryClient();
  const supportMutation = useMutation(support, {
    onSuccess: () => {
      queryClient.invalidateQueries(["story", id]);
      queryClient.invalidateQueries("mycoin");
      handleToggle();
      setModal({
        title: "무무코인으로 응원하기",
        content: <SuccessContent />,
        info: <SuccessInfo />,
      });
    },
    onError: () => {
      setModal({
        title: "무무코인으로 응원하기",
        content: <ErrorContent />,
      });
    },
  });

  const isActive = useMemo(() => checkRequired(required, watch()), [watch()]);

  const onSubmit = (data) => {
    isActive &&
      supportMutation.mutate({
        data: {
          useCoin: data.coin,
        },
        id,
        cheerId: funding?.cheerId,
      });
  };

  return {
    form,
    isActive,
    onSubmit: handleSubmit(onSubmit),
  };
};

export default useSupportForm;
