import { finish } from "api/story";
import Text from "component/atoms/text";
import { ErrorContent } from "component/organisms/common/globalModal";
import useUrl from "hook/util/useUrl";
import { useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";
import checkRequired from "utils/checkRequired";

const required = {
  musicName: true,
  musicFile: true,
};

const SuccessContent = () => {
  return (
    <>
      <Text
        typo={{
          size: "18px",
          weight: "700",
          align: "center",
        }}
      >
        창작 완료 신청이 완료되었습니다.
      </Text>
      <Text
        margin={{
          top: "6px",
        }}
        typo={{
          size: "14px",
          weight: "400",
          align: "center",
        }}
      >
        영업일 기준 7일 이내에 심사가 완료될 예정입니다.
      </Text>
    </>
  );
};

const useFinishedForm = (handleToggle) => {
  const form = useForm({
    defaultValues: {
      musicName: "",
      musicFile: null,
    },
  });
  const {
    params: { id },
  } = useUrl();

  const { watch, handleSubmit } = form;
  const { setModal } = useModalStore();
  const queryClient = useQueryClient();

  const finishMutation = useMutation(finish, {
    onSuccess: () => {
      handleToggle();
      setModal({
        title: "창작 완료 신청하기",
        content: <SuccessContent />,
        isActive: true,
      });
      queryClient.invalidateQueries(["story", id]);
    },
    onError: (error) => {
      setModal({
        title: "창작 완료 신청하기",
        content: <ErrorContent />,
      });
    },
  });

  const isActive = useMemo(() => checkRequired(required, watch()), [watch()]);

  const onSubmit = (data) => {
    const formData = new FormData();

    Object.entries(data).forEach(([key, value]) => {
      formData.append(key, value);
    });

    isActive &&
      finishMutation.mutate({
        id,
        data: formData,
      });
  };

  return {
    form,
    isActive,
    isLoading: finishMutation.isLoading,
    //
    onSubmit: handleSubmit(onSubmit),
  };
};

export default useFinishedForm;
