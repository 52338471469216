import Box from "component/atoms/box";
import Text from "component/atoms/text";
import Line from "component/atoms/line";
import CommonButton from "component/molecules/common/commonButton";
import useToggle from "hook/util/useToggle";
import useInfoUpdate from "hook/api/story/info";
import Modal from "component/molecules/common/modal";
import InputField from "component/organisms/common/inputField";
import { FormProvider } from "react-hook-form";
import Button from "component/atoms/button";
import Image from "component/atoms/image";
import { useEffect } from "react";

const StoryContentInfo = (props) => {
  const { title, requiredForm, artist, isCreator } = props;
  const { toggle, handleToggle, toggleOff } = useToggle();
  const { toggle: toggleInfo, handleToggle: handleToggleInfo } = useToggle();
  const { form, isActive, handleSubmit } = useInfoUpdate(
    toggleOff,
    requiredForm
  );

  const isRequired = !requiredForm && isCreator && artist;

  const musicIntro = form.watch("musicIntro");

  useEffect(() => {
    if (toggle && requiredForm) {
      form.reset({
        musicName: requiredForm.musicName,
        musicIntro: requiredForm.musicIntro,
        teamName: requiredForm.teamName,
      });
    }
  }, [requiredForm, toggle]);

  return (
    <Box
      position={{
        type: "relative",
      }}
      display={{
        display: "flex",
        wrap: "wrap",
        align: "flex-start",
      }}
      size={{
        width: "100%",
      }}
      padding={{
        all: "20px 24px",
      }}
      border={{
        all: "1px",
      }}
      longMobile={{
        size: {
          width: "100%",
        },
        padding: {
          all: "7px 12px",
        },
      }}
    >
      {isRequired && (
        <Button
          position={{
            type: "absolute",
            top: "-15px",
            left: "-15px",
          }}
          size={{
            width: "30px",
            height: "30px",
          }}
          onClick={handleToggleInfo}
        >
          <Image
            src={"/asset/img/story/info.svg"}
            size={{
              width: "100%",
              height: "100%",
            }}
          />

          {toggleInfo && (
            <Box
              position={{
                type: "absolute",
                top: "calc(100% + 5px)",
                left: "12px",
              }}
              size={{
                width: "320px",
              }}
              background={{
                color: "white",
              }}
              padding={{
                all: "15px",
              }}
              border={{
                all: "1px",
                style: "solid",
                color: "black01",
              }}
            >
              <Text
                typo={{
                  size: "12px",
                  weight: "400",
                  align: "left",
                  color: "black02",
                }}
              >
                필수 양식을 등록해주세요.
                <br />
                창작물이 완료되기 전까지, 콘텐츠 정보를 등록해주세요.
              </Text>
            </Box>
          )}
        </Button>
      )}
      <Box
        size={{
          width: "100%",
        }}
        display={{
          display: "flex",
          justify: "space-between",
          align: "center",
        }}
        border={{
          bottom: "1px",
          color: "gray02",
        }}
        padding={{
          bottom: "15px",
        }}
        margin={{
          bottom: "15px",
        }}
        tablet={{
          padding: {
            bottom: "10px",
          },
          margin: {
            bottom: "10px",
          },
        }}
      >
        <Text
          size={{
            width: "calc(100% - 158px)",
          }}
          typo={{
            size: "20px",
            weight: "700",
            wordBreak: "break-all",
            lineClamp: 1,
          }}
          longMobile={{
            size: {
              width: "calc(100% - 80px)",
            },
            typo: {
              size: "16px",
            },
          }}
        >
          {title}
        </Text>

        <CommonButton
          display={{
            display: isCreator && artist ? "block" : "none",
          }}
          size={{
            width: "148px",
          }}
          category={"button"}
          padding={{
            all: "11px",
          }}
          typo={{
            size: "14px",
            weight: "700",
          }}
          tablet={{
            size: {
              width: "80px",
            },
          }}
          onClick={handleToggle}
        >
          <Text
            as="span"
            display={{
              display: "block",
            }}
            tablet={{
              display: {
                display: "none",
              },
            }}
          >
            콘텐츠 정보 {isRequired ? "등록" : "수정"}하기
          </Text>
          <Text
            as="span"
            display={{
              display: "none",
            }}
            tablet={{
              display: {
                display: "block",
              },
            }}
          >
            정보 {isRequired ? "등록" : "수정"}
          </Text>
        </CommonButton>
      </Box>

      {requiredForm ? (
        <>
          <Text
            as={"span"}
            size={{
              width: "auto",
            }}
            typo={{
              size: "14px",
              weight: "700",
            }}
          >
            곡명 :
          </Text>
          {requiredForm && (
            <Text
              as={"span"}
              size={{
                width: "calc(100% - 33px)",
              }}
              typo={{
                size: "14px",
              }}
              padding={{
                left: "10px",
              }}
            >
              {requiredForm.musicName}
            </Text>
          )}

          <Line
            direction="row"
            size={"100%"}
            background={{ color: "gray02" }}
            margin={{ all: "15px 0" }}
            longMobile={{
              margin: {
                all: "12px 0",
              },
            }}
          />
          <Text
            as={"span"}
            size={{
              width: "auto",
            }}
            typo={{
              size: "14px",
              weight: "700",
            }}
          >
            곡소개 :
          </Text>
          {requiredForm && (
            <Text
              as={"span"}
              size={{
                width: "calc(100% - 46px)",
              }}
              typo={{
                size: "14px",
                whiteSpace: "pre-line",
              }}
              padding={{
                left: "10px",
              }}
            >
              {requiredForm.musicIntro}
            </Text>
          )}

          <Line
            direction="row"
            size={"100%"}
            background={{ color: "gray02" }}
            margin={{ all: "15px 0" }}
            longMobile={{
              margin: {
                all: "12px 0",
              },
            }}
          />
          <Text
            as={"span"}
            size={{
              width: "auto",
            }}
            typo={{
              size: "14px",
              weight: "700",
            }}
          >
            팀명 :
          </Text>
          {requiredForm && (
            <Text
              as={"span"}
              size={{
                width: "calc(100% - 104px)",
              }}
              typo={{
                size: "14px",
              }}
              padding={{
                left: "10px",
              }}
            >
              {requiredForm.teamName}
            </Text>
          )}
        </>
      ) : (
        <Text
          size={{ width: "100%" }}
          typo={{
            size: "18px",
            weight: "700",
            align: "center",
          }}
          padding={{
            all: "66px 0",
          }}
          tablet={{
            typo: {
              size: "14px",
            },
            padding: {
              all: "49px 0",
            },
          }}
        >
          {artist
            ? "아티스트와 콘텐츠를 만드는 중"
            : "아티스트와의 만남을 기다리는 중"}
        </Text>
      )}

      <Modal
        title={`콘텐츠 정보 ${requiredForm ? "수정" : "등록"}하기`}
        visible={toggle}
        onClose={handleToggle}
      >
        <FormProvider {...form}>
          <Box
            size={{
              width: "600px",
            }}
            longMobile={{
              size: {
                width: "100%",
              },
            }}
          >
            <InputField
              as={"input"}
              type={"text"}
              title={"곡명"}
              name={"musicName"}
              typo={{
                size: "14px",
              }}
              size={{
                width: "100%",
                height: "38px",
              }}
              placeholder={"곡명을 입력해주세요."}
            />

            <Box
              position={{
                type: "relative",
              }}
              size={{
                width: "100%",
              }}
              margin={{
                top: "20px",
              }}
            >
              <InputField
                as={"input"}
                type={"text"}
                title={"곡 소개"}
                name={"musicIntro"}
                typo={{
                  size: "14px",
                }}
                size={{
                  width: "100%",
                  height: "38px",
                }}
                placeholder={"곡 소개를 적어주세요."}
                maxLength={300}
              />
              <Text
                position={{
                  type: "absolute",
                  right: "0",
                  top: "0",
                }}
                typo={{
                  size: "14px",
                  color: "#777",
                }}
              >
                ({musicIntro.length}/300)
              </Text>
            </Box>

            <Box
              size={{
                width: "100%",
              }}
              margin={{
                top: "20px",
                bottom: "20px",
              }}
            >
              <InputField
                as={"input"}
                type={"text"}
                title={"팀명"}
                name={"teamName"}
                typo={{
                  size: "14px",
                }}
                size={{
                  width: "100%",
                  height: "38px",
                }}
                placeholder={"팀 이름을 적어주세요."}
              />
            </Box>

            <CommonButton
              category={"button"}
              size={{
                width: "100%",
                height: "63px",
              }}
              isActive={isActive}
              isDisabled={!isActive}
              onClick={handleSubmit}
            >
              필수 양식 저장하기
            </CommonButton>
          </Box>
        </FormProvider>
      </Modal>
    </Box>
  );
};

export default StoryContentInfo;
