import {
  changeOrder,
  changeTourOrder,
  exceptSpecial,
  exceptTour,
  getSpecialList,
  getTourList,
} from "api/admin/story/exposeToMain";
import { useQuery, useQueryClient, useMutation } from "react-query";
import { useNavigate } from "react-router-dom";

export const useAdminSpecialList = () => {
  const navigate = useNavigate();
  const { data, isLoading, refetch } = useQuery(
    ["adminSpecialList"],
    () => getSpecialList(),
    {
      onError: (error) => {
        if (error.response && error.response.status === 403) {
          alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
          navigate("/admin/login");
        }
      },
    }
  );

  return {
    data: data?.data,
    totalItems: data?.totalItems,
    isLoading,
    refetch,
  };
};

export const useUpdateAdminSpecialList = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const { mutate, data } = useMutation((storyId) => exceptSpecial(storyId), {
    onSuccess: () => {
      queryClient.invalidateQueries("adminSpecialList");
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
    },
    onError: () => {
      if (onError && typeof onError === "function") {
        onError();
      }
    },
  });
  return { mutate, data };
};

export const useAdminTourList = () => {
  const { data, isLoading, refetch } = useQuery(["adminTourList"], () =>
    getTourList()
  );

  return {
    data: data?.data,
    totalItems: data?.totalItems,
    isLoading,
    refetch,
  };
};

export const useUpdateAdminTourList = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const { mutate, data } = useMutation((storyId) => exceptTour(storyId), {
    onSuccess: () => {
      queryClient.invalidateQueries("adminTourList");
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
    },
    onError: () => {
      if (onError && typeof onError === "function") {
        onError();
      }
    },
  });
  return { mutate, data };
};

export const useUpdateAdminSpecialOrder = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const { mutate, data } = useMutation((data) => changeOrder(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("adminSpecialList");
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
    },
    onError: () => {
      if (onError && typeof onError === "function") {
        onError();
      }
    },
  });
  return { mutate, data };
};

export const useUpdateAdminTourOrder = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const { mutate, data } = useMutation((data) => changeTourOrder(data), {
    onSuccess: () => {
      queryClient.invalidateQueries("adminTourList");
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
    },
    onError: () => {
      if (onError && typeof onError === "function") {
        onError();
      }
    },
  });
  return { mutate, data };
};
