import styled from "styled-components";

export const BasicContainer = styled.main`
  width: 100%;
  height: 100%;
`;

export const BasicContent = styled.section`
  width: 1200px;
  height: 100%;
  margin: 0 auto;
`;
