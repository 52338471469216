import Box from "component/atoms/box";
import Text from "component/atoms/text";
import InputField from "component/organisms/common/inputField";
import SingleCalendarField from "component/organisms/common/singleCalendarField";
import ThumbnailField from "component/organisms/common/thumbnailField";

const stepName = {
  1: "first",
  2: "second",
};

const SignupForm = (props) => {
  const { step, required } = props;

  const currentName = stepName[step];

  return (
    <>
      <Box
        margin={{
          bottom: "24px",
        }}
      >
        <InputField
          as={"input"}
          type={"text"}
          title={`${step}-1 곡명`}
          required={required}
          name={`${currentName}Music`}
          typo={{
            size: "14px",
            weight: "700",
          }}
          size={{
            width: "100%",
            height: "40px",
          }}
        />
      </Box>

      <Box
        margin={{
          bottom: "24px",
        }}
      >
        <InputField
          as={"input"}
          type={"text"}
          title={`${step}-2 음원 등록하기`}
          required={required}
          name={`${currentName}Link`}
          typo={{
            size: "14px",
            weight: "700",
          }}
          size={{
            width: "100%",
            height: "40px",
          }}
          info={
            <Text
              size={{
                width: "100%",
              }}
              typo={{
                size: "12px",
                weight: "400",
                color: "gray04",
                lineHeight: "normal",
              }}
            >
              음원이 담긴 링크를 등록해주세요. 음원은 심사 용도 외에 사용되지
              않습니다.
            </Text>
          }
        />
      </Box>

      <Box
        margin={{
          bottom: "24px",
        }}
      >
        <ThumbnailField
          type={"text"}
          title={`${step}-3 음원 커버 등록하기`}
          name={`${currentName}MusicCover`}
          size={{
            width: "270px",
            height: "270px",
          }}
          info={
            <Text
              size={{
                width: "100%",
              }}
              typo={{
                size: "12px",
                weight: "400",
                color: "gray04",
                lineHeight: "normal",
              }}
            >
              해당 곡의 느낌을 잘 알릴 수 있는 커버를 등록해주세요.
            </Text>
          }
          desc={
            <>
              <Text
                margin={{
                  top: "6px",
                }}
                typo={{
                  size: "12px",
                  color: "gray04",
                }}
                mobile={{
                  display: {
                    display: "none",
                  },
                }}
              >
                - 권장 이미지: 1080px*1080px / jpg,png 파일
              </Text>
              <Text
                display={{
                  display: "none",
                }}
                margin={{
                  bottom: "6px",
                }}
                typo={{
                  size: "10px",
                  color: "gray04",
                }}
                mobile={{
                  display: {
                    display: "block",
                  },
                }}
              >
                권장 이미지
                <br />: 1080px*1080px / jpg,png 파일
              </Text>
            </>
          }
          typo={{
            size: "14px",
            weight: "700",
          }}
        />
      </Box>

      <Box
        margin={{
          bottom: "24px",
        }}
      >
        <SingleCalendarField
          title={`${step}-4 발매일 등록하기`}
          name={`${currentName}ReleaseDate`}
          required={required}
          typo={{
            size: "14px",
            weight: "700",
          }}
          size={{
            width: "270px",
          }}
        />
      </Box>
    </>
  );
};

export default SignupForm;
