import { additional, getAdditional, updateAdditional } from "api/story";
import Text from "component/atoms/text";
import { ErrorContent } from "component/organisms/common/globalModal";
import useUrl from "hook/util/useUrl";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";
import checkRequired from "utils/checkRequired";

export const useAddition = (id) => {
  const { data, isSuccess, isError, isLoading } = useQuery(
    ["additional", id],
    () => getAdditional(id)
  );

  return {
    data: data?.data,
    isSuccess,
    isError,
    isLoading,
  };
};

const required = {
  employTitle: true,
  employContent: true,
};

const SuccessContent = ({ message }) => {
  return (
    <Text
      typo={{
        size: "18px",
        weight: "700",
        align: "center",
      }}
      longMobile={{
        typo: {
          size: "14px",
          weight: "700",
        },
      }}
    >
      {message}
    </Text>
  );
};

export const useAdditionForm = (close, defaultValues) => {
  const form = useForm({
    defaultValues,
  });
  const isUpdate = defaultValues;
  const { setModal } = useModalStore();
  const { watch, handleSubmit } = form;
  const { params } = useUrl();
  const queryClient = useQueryClient();
  const additionalMutation = useMutation(
    isUpdate ? updateAdditional : additional,
    {
      onSuccess: ({ message }) => {
        queryClient.invalidateQueries(["story", params.id]);
        queryClient.invalidateQueries(["additional"]);
        close();
        setModal({
          title: "멤버 모집",
          content: <SuccessContent message={message} />,
        });
      },
      onError: ({ response: { data } }) => {
        setModal({ title: "매칭 신청하기", content: <ErrorContent /> });
      },
    }
  );

  const isActive = useMemo(() => checkRequired(required, watch()), [watch()]);

  const onSubmit = (data) => {
    isActive &&
      additionalMutation.mutate({
        data,
        id: isUpdate ? data.employId : params.id,
      });
  };

  return { form, isActive, onSubmit: handleSubmit(onSubmit) };
};
