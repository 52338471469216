import { GET, POST, DELETE } from "api/config";

export const getAdminFilterKeyword = async () =>
  await GET("/admin/filter/keyword");

export const createAdminFilterKeyword = async (keyword) => {
  const data = {
    keyword: keyword,
  };
  await POST("/admin/filter/keyword", data);
};

export const deleteAdminFilterKeyword = async (id) =>
  await DELETE(`/admin/filter/keyword/${id}`);
