import React, { useState } from "react";
import Box from "component/atoms/box";
import Input from "component/atoms/input";
import { useRef } from "react";
import { useEffect } from "react";

const AdminCheckbox = (props) => {
  const { margin, children, checked, onChange, onClick, block, readOnly } =
    props;

  const inputRef = useRef(null);

  const handleCheckboxClick = () => {
    inputRef.current.click();
  };

  return (
    <Box
      other={{
        cursor: readOnly ? "default" : "pointer",
      }}
      onClick={handleCheckboxClick}
    >
      <Box
        display={{
          display: "flex",
          justify: "center",
          align: "center",
          float: "left",
        }}
        size={{
          width: "14px",
          height: "14px",
        }}
        margin={margin}
        background={{
          color: readOnly ? "#F3F3F3" : "white",
        }}
        border={{
          all: "1px",
          style: "solid",
          color: "gray10",
          radius: "3px",
        }}
        other={{
          overflow: "hidden",
          boxSizing: "border-box",
          cursor: readOnly ? "default" : "pointer",
        }}
      >
        {checked && !readOnly && (
          <Box
            size={{
              width: "13px",
              height: "13px",
            }}
            background={{
              color: "pink03",
            }}
            border={{
              radius: "3px",
            }}
            other={{
              boxSizing: "border-box",
            }}
          ></Box>
        )}
        <Input
          ref={inputRef}
          hidden
          size={{
            width: "14px",
            height: "14px",
          }}
          type="checkbox"
          checked={checked}
          onClick={onClick}
          readOnly={readOnly}
        />
      </Box>
      <Box
        display={{
          float: "right",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AdminCheckbox;
