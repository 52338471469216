import Button from "component/atoms/button";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

const TYPE = {
  right: BiChevronRight,
  left: BiChevronLeft,
};

const ArrowButton = (props) => {
  const { type, size, onClick } = props;

  const CurrentIcon = TYPE[type];

  return (
    <Button
      display={{
        display: "flex",
        justify: "center",
        align: "center",
      }}
      size={{
        width: `${size}px`,
        height: `${size}px`,
      }}
      border={{
        all: "1px",
      }}
      background={{
        color: "opacityWhite",
      }}
      onClick={onClick}
    >
      <CurrentIcon size={size} />
    </Button>
  );
};

/*
      <Box
        position={{
          type: "absolute",
          isCenterY: true,
          left: "15px",
          zIndex: "1",
        }}
      >
        <ArrowButton type={"left"} size={28} onClick={prevSlide(sliderRef)} />
      </Box>

      <Box
        position={{
          type: "absolute",
          isCenterY: true,
          right: "15px",
          zIndex: "1",
        }}
      >
        <ArrowButton type={"right"} size={28} onClick={nextSlide(sliderRef)} />
      </Box>
*/

export default ArrowButton;
