import { comment, getComment, updateComment } from "api/story";
import { ErrorContent } from "component/organisms/common/globalModal";
import useToggle from "hook/util/useToggle";
import useUrl from "hook/util/useUrl";
import { useEffect, useMemo, useRef, useState } from "react";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";

const editorId = "editor";

const required = {
  storyboardContent: true,
};

const useCommentStoryForm = () => {
  const editorRef = useRef(null);
  const [type, setType] = useState("0");
  const {
    toggle: isNotice,
    toggleOn: noticeOn,
    toggleOff: noticeOff,
    setToggle,
  } = useToggle();
  const { setModal } = useModalStore();
  const {
    params,
    query: { id },
    changeLink,
  } = useUrl();
  const queryClient = useQueryClient();
  const [content, setContent] = useState("<p><br></p>");

  const commentDetail = useMutation(getComment, {
    onSuccess: ({ data }) => {
      editorRef.current.setContents(data.storyboardContent);
      setContent(data.storyboardContent);
      setType(data.storyboardOpen.toString());
      setToggle(data.storyboardFixed);
    },
  });

  const commentMutation = useMutation(id ? updateComment : comment, {
    onSuccess: () => {
      changeLink(`/story/${params.id}`);
      queryClient.invalidateQueries("commentList");
      queryClient.invalidateQueries("commentInfinite");
    },
    onError: ({ response: { data } }) => {
      setModal({
        title: "게시글 작성",
        content: <ErrorContent message={data.message} />,
      });
    },
  });

  const isActive = content !== "<p><br></p>";

  const submit = () => {
    isActive &&
      commentMutation.mutate({
        id: params.id,
        boardId: id,
        data: {
          storyboardContent: content,
          storyboardOpen: type,
          storyboardFixed: isNotice ? 1 : 0,
        },
      });
  };

  useEffect(() => {
    if (id) {
      commentDetail.mutate({ storyId: params.id, boardId: id });
    }
  }, [id]);

  return {
    editorRef,
    editorId,
    type,
    isNotice,
    content,
    isActive,
    setContent,
    noticeOn,
    noticeOff,
    setType,
    submit,
  };
};

export default useCommentStoryForm;
