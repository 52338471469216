import Box from "component/atoms/box";
import Input from "component/atoms/input";
import LabelText from "../../../molecules/common/labelText";
import { useFormContext } from "react-hook-form";
import useReset from "hook/util/useReset";
import Text from "component/atoms/text";
import { Fragment } from "react";

const InputField = (props) => {
  const {
    as,
    type,
    title,
    size,
    typo,
    longMobile,
    required,
    info,
    placeholder,
    //
    name,
    division,
    isReset,
    readOnly,
    //
    maxLength,
  } = props;
  const { register } = useFormContext();
  useReset(name, isReset);

  const isMulti = Array.isArray(name);

  return (
    <Box
      display={{
        display: "block",
      }}
      as={"label"}
      size={{
        width: size.width,
      }}
      mobile={{
        size: {
          width: "100%",
        },
      }}
    >
      {(title || info || required) && (
        <LabelText title={title} required={required} info={info} typo={typo} />
      )}

      {isMulti ? (
        name.map((item, idx) => (
          <Fragment key={idx}>
            <Input
              as={as}
              type={type}
              size={size}
              readOnly={readOnly}
              maxLength={maxLength}
              border={{
                all: "1px",
                style: "solid",
                color: "black01",
              }}
              background={{
                color: readOnly ? "gray02" : "white",
              }}
              {...register(item, {
                required,
              })}
            />
            {division && idx !== name.length - 1 && (
              <Text
                as={"span"}
                display={{
                  display: "inline-block",
                }}
                typo={{
                  align: "center",
                }}
                size={{
                  width: `calc((100% - calc(${size.width} * ${
                    name.length
                  })) / ${name.length - 1})`,
                }}
              >
                {division}
              </Text>
            )}
          </Fragment>
        ))
      ) : (
        <Input
          as={as}
          type={type}
          readOnly={readOnly}
          maxLength={maxLength}
          size={{ ...size, width: "100%" }}
          border={{
            all: "1px",
            style: "solid",
            color: "black01",
          }}
          background={{
            color: readOnly ? "gray02" : "white",
          }}
          longMobile={longMobile}
          placeholder={placeholder}
          {...register(name, {
            required,
          })}
        />
      )}
    </Box>
  );
};

export default InputField;
