import { useEffect } from "react";
import useModalStore from "store/useModalStore";
import useSignedStore from "store/useSignedStore";
import useUrl from "./useUrl";

const useCheckUser = (link = "/login") => {
  const { user } = useSignedStore();
  const { setModal } = useModalStore();
  const { changeLink } = useUrl();

  useEffect(() => {
    if (!user) {
      setModal({
        title: "로그인",
        content: "로그인이 필요합니다.",
      });
      changeLink(link);
    }
  }, [user, link]);
};

export default useCheckUser;
