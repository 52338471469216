import { useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  cancleCheering,
  deletePortfolio,
  getUserCheering,
  getUserCoin,
  getUserDetail,
  getUserPortfolio,
  giveCoin,
  revokeCoin,
  updateUserDetail,
} from "api/admin/userDetail";
import { useNavigate } from "react-router-dom";
import useUrl from "hook/util/useUrl";

export const useUserDetail = (memberId) => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    ["userDetail", memberId],
    () => getUserDetail(memberId),
    {
      onError: (error) => {
        if (error.response && error.response.status === 403) {
          alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
          navigate("/admin/login");
        }
      },
    }
  );
  return {
    data: data?.data,
    isLoading,
  };
};

export const useUpdateUserDetail = (memberId, onSuccess, onError) => {
  const queryClient = useQueryClient();
  const mutation = useMutation(
    (data) => updateUserDetail(memberId, data), // 여기서 memberId를 전달
    {
      onSuccess: () => {
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess();
        }
        queryClient.invalidateQueries("userDetail");
      },
      onError: (error) => {
        if (error.response && error.response.status === 400) {
          alert(error.response.data.message);
        } else {
          alert("회원 정보 수정에 실패했습니다.");
        }
      },
    }
  );

  return {
    mutate: mutation.mutate,
  };
};

export const useUserCoin = (memberId) => {
  const { data, isLoading } = useQuery(["userCoin", memberId], () =>
    getUserCoin(memberId)
  );
  return {
    coinSum: data?.coinSum,
    isLoading,
  };
};

export const useGiveCoin = (onSuccessCallback, onErrorCallback) => {
  const queryClient = useQueryClient();
  const navigate = useUrl();
  const { mutate, data, isLoading } = useMutation(
    ({ memberId, coinsToAward, coinReason }) =>
      giveCoin(memberId, coinsToAward, coinReason),
    {
      onSuccess: () => {
        if (onSuccessCallback && typeof onSuccessCallback === "function") {
          onSuccessCallback();
        }
        queryClient.invalidateQueries("userCheering");
        queryClient.invalidateQueries("userCoin");
      },
      onError: (error) => {
        if (onErrorCallback && typeof onErrorCallback === "function") {
          if (error.response && error.response.status === 403) {
            alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
            navigate("/admin/login");
          } else {
            onErrorCallback(error);
          }
        }
      },
    }
  );

  return {
    mutate,
    data,
    isLoading,
  };
};

export const useRevokeCoin = (onSuccessCallback, onErrorCallback) => {
  const queryClient = useQueryClient();
  const navigate = useUrl();
  const { mutate, data, isLoading } = useMutation(
    ({ memberId, coinsToAward, coinReason }) =>
      revokeCoin(memberId, coinsToAward, coinReason),
    {
      onSuccess: () => {
        if (onSuccessCallback && typeof onSuccessCallback === "function") {
          onSuccessCallback();
        }
        queryClient.invalidateQueries("userCheering");
        queryClient.invalidateQueries("userCoin");
      },
      onError: (error) => {
        if (error.response && error.response.status === 403) {
          alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
          navigate("/admin/login");
        } else {
          onErrorCallback(error);
        }
      },
    }
  );

  return {
    mutate,
    data,
    isLoading,
  };
};

export const useUserCheering = (
  memberId,
  reason,
  startDate,
  endDate,
  coinCategory,
  processed,
  currentPage,
  pageSize
) => {
  const { data, isLoading } = useQuery(
    [
      "userCheering",
      memberId,
      reason,
      startDate,
      endDate,
      coinCategory,
      processed,
      currentPage,
      pageSize,
    ],
    () =>
      getUserCheering(
        memberId,
        reason,
        startDate,
        endDate,
        coinCategory,
        processed,
        currentPage,
        pageSize
      )
  );
  return {
    data: data?.data,
    totalItems: data?.totalItems,
    totalPages: data?.totalPages,
    isLoading,
  };
};

export const useUserPortfolio = (
  memberId,
  musicName,
  musicLink,
  startDate,
  endDate,
  total,
  pageSize,
  currentPage
) => {
  const { data, isLoading } = useQuery(
    [
      "userPortfolio",
      memberId,
      musicName,
      musicLink,
      startDate,
      endDate,
      total,
      pageSize,
      currentPage,
    ],
    () =>
      getUserPortfolio(
        memberId,
        musicName,
        musicLink,
        startDate,
        endDate,
        total,
        pageSize,
        currentPage
      )
  );
  return {
    data: data?.data,
    totalItems: data?.totalItems,
    totalPages: data?.totalPages,
    isLoading,
  };
};

// 응원 취소
export const useCancleCheering = (onSuccessCallback, onErrorCallback) => {
  const queryClient = useQueryClient();
  const { mutate, data, isLoading } = useMutation(
    ({ coinIds }) => cancleCheering(coinIds),
    {
      onSuccess: () => {
        if (onSuccessCallback && typeof onSuccessCallback === "function") {
          onSuccessCallback();
        }
        queryClient.invalidateQueries("userCheering");
      },
      onError: (error) => {
        if (onErrorCallback && typeof onErrorCallback === "function") {
          onErrorCallback(error);
        }
      },
    }
  );

  return {
    mutate,
    data,
    isLoading,
  };
};

// 포트폴리오 삭제
export const useDeletePortfolio = (onSuccessCallback, onErrorCallback) => {
  const queryClient = useQueryClient();
  const { mutate, data, isLoading } = useMutation(
    ({ portfolioIds }) => deletePortfolio(portfolioIds),
    {
      onSuccess: () => {
        if (onSuccessCallback && typeof onSuccessCallback === "function") {
          onSuccessCallback();
        }
        queryClient.invalidateQueries("userPortfolio");
      },
      onError: (error) => {
        if (onErrorCallback && typeof onErrorCallback === "function") {
          onErrorCallback(error);
        }
      },
    }
  );

  return {
    mutate,
    data,
    isLoading,
  };
};
