import React, { useContext } from "react";
import { MenuContext } from "context";
import UserManagement from "component/organisms/admin/user/userManage";
import GradeMangement from "component/organisms/admin/user/gradeManage";
import Box from "component/atoms/box";

const ManageUser = () => {
  const { selectedMenu } = useContext(MenuContext);

  const renderContent = () => {
    switch (selectedMenu) {
      case "userManage":
        return <UserManagement />;
      case "gradeManage":
        return <GradeMangement />;
      default:
        return <UserManagement />;
    }
  };

  return (
    <Box
      size={{
        width: "100%",
        height: "auto",
      }}
      margin={{
        top: "25px",
      }}
      other={{
        overflowX: "hidden",
      }}
    >
      {renderContent()}
    </Box>
  );
};

export default ManageUser;
