import Box from "component/atoms/box";
import { FormProvider } from "react-hook-form";
import InputField from "../inputField";
import ThumbnailField from "../thumbnailField";
import Text from "component/atoms/text";
import SingleCalendarField from "../singleCalendarField";

const PortfolioForm = (props) => {
  const { form } = props;

  return (
    <FormProvider {...form}>
      <Box>
        <InputField
          as={"input"}
          type={"text"}
          title={"곡명"}
          placeholder={"제목을 입력해주세요."}
          name={"name"}
          required={true}
          size={{
            width: "100%",
            height: "40px",
          }}
          typo={{
            size: "14px",
          }}
        />
      </Box>

      <Box
        margin={{
          top: "20px",
        }}
      >
        <InputField
          as={"input"}
          type={"text"}
          title={"음원 등록하기"}
          placeholder={"음원 링크를 입력해주세요."}
          name={"link"}
          required={true}
          size={{
            width: "100%",
            height: "40px",
          }}
          typo={{
            size: "14px",
          }}
        />
      </Box>

      <Box
        margin={{
          top: "20px",
        }}
      >
        <ThumbnailField
          title={"앨범 커버 등록하기"}
          name={"cover"}
          info={
            <Text
              size={{
                width: "100%",
              }}
              typo={{
                size: "12px",
                weight: "400",
                color: "gray04",
              }}
              display={{
                display: "block",
              }}
              longMobile={{
                display: {
                  display: "none",
                },
              }}
            >
              해당 곡의 느낌을 잘 알릴 수 있는 커버를 등록해주세요. <br />
              권장 이미지 : 1080px * 1080px / jgp, png 파일
            </Text>
          }
          desc={
            <Text
              display={{
                display: "none",
              }}
              margin={{
                bottom: "6px",
              }}
              typo={{
                size: "10px",
                color: "gray04",
              }}
              mobile={{
                display: {
                  display: "block",
                },
              }}
            >
              권장 이미지
              <br />: 1080px*1080px / jpg,png 파일
            </Text>
          }
          typo={{
            size: "14px",
          }}
          size={{
            width: "150px",
            height: "150px",
            aspectRatio: "1/1",
          }}
          mobileLayout
        />
      </Box>

      <Box
        margin={{
          top: "20px",
          bottom: "35px",
        }}
      >
        <SingleCalendarField
          title={"발매일 등록하기"}
          name={"portfolio_music"}
          required
          size={{
            width: "270px",
          }}
          typo={{
            size: "14px",
          }}
        />
      </Box>
    </FormProvider>
  );
};

export default PortfolioForm;
