import Box from "component/atoms/box";
import { useRef, useState } from "react";
import Button from "component/atoms/button";
import Thumbnail from "component/molecules/common/thumbnail";
import Line from "component/atoms/line";
import PersonalityList from "component/molecules/common/selectProjectList";
import ProjectTitle from "component/molecules/main/projectTitle";
import ProjectUser from "component/molecules/main/projectUser";
import Slider, { nextSlide, prevSlide, slideTo } from "component/atoms/slider";
import ArrowButton from "component/molecules/common/arrowButton";
import useMobileStore from "store/useDeviceStore";
import FadeSlider from "../fadeSlider";
import convertImage from "utils/convertImage";
import Link from "component/atoms/link";

const convertList = (list) => {
  if (list.length === 1) {
    return [
      ...list.map((data) => ({ ...data, key: 1 })),
      ...list.map((data) => ({ ...data, key: 2 })),
      ...list.map((data) => ({ ...data, key: 3 })),
      ...list.map((data) => ({ ...data, key: 4 })),
    ];
  } else if (list.length === 2) {
    return [
      ...list.map((data) => ({ ...data, key: 1 })),
      ...list.map((data) => ({ ...data, key: 2 })),
    ];
  } else {
    return [...list];
  }
};

const StorySlider = (props) => {
  const { list } = props;

  const slideList = convertList(list);

  const [activeIndex, setActiveIndex] = useState(0);
  const [prevIndex, setPrevIndex] = useState(-1);
  const slideRef = useRef(null);
  const { mobile, longMobile, pc } = useMobileStore();

  const generateWindow = (index) => {
    const length = slideList.length;

    return [
      slideList.at(index - 1),
      slideList.at(index),
      slideList.at((index + 1) % length),
    ];
  };

  const array = generateWindow(activeIndex);
  const prevArray = generateWindow(prevIndex);

  const handleNextSlide = () => {
    if (mobile || longMobile) {
      nextSlide(slideRef)();
    } else {
      setActiveIndex((prev) => {
        setPrevIndex(prev);
        return (prev + 1) % slideList.length;
      });
    }
  };

  const handlePrevSlide = () => {
    if (mobile || longMobile) {
      prevSlide(slideRef)();
    } else {
      setActiveIndex((prev) => {
        setPrevIndex(prev);
        return prev === 0 ? slideList.length - 1 : prev - 1;
      });
    }
  };

  const handleSlideChange = (index) => {
    if (mobile || longMobile) {
      slideTo(slideRef)(index);
    } else {
      setActiveIndex((prev) => {
        setPrevIndex(prev);
        return index;
      });
    }
  };

  return (
    <Box
      position={{
        type: "relative",
      }}
      display={{
        display: "flex",
        align: "flex-end",
        justify: "center",
      }}
      other={{
        overflowX: "hidden",
      }}
      size={{
        width: "100%",
        maxWidth: "1200px",
        height: "calc(270px + 28px + 15px + 65px)",
      }}
      margin={{
        all: "15px auto 0",
      }}
      tablet={{
        display: {
          direction: "column",
          align: "center",
          gap: "15px",
        },
        size: {
          width: "100%",
          height: "auto",
        },
        margin: {
          top: "40px",
        },
        other: {
          overflowX: "visible",
        },
      }}
    >
      <FadeSlider
        array={array}
        prevArray={prevArray}
        handlePrevSlide={handlePrevSlide}
        handleNextSlide={handleNextSlide}
        activeIndex={activeIndex}
        prevIndex={prevIndex}
      />

      <Box
        display={{
          display: "none",
        }}
        size={{
          width: "100%",
        }}
        margin={{
          all: "0 auto",
        }}
        tablet={{
          display: {
            display: "flex",
            justify: "center",
            align: "center",
          },
        }}
      >
        <Slider
          ref={slideRef}
          loop={true}
          centeredSlides={true}
          slidesPerView={1.1}
          spaceBetween={"8px"}
          setActiveIndex={setActiveIndex}
        >
          {slideList.map((item, idx) => (
            <Slider.Slide key={idx}>
              <Link
                position={{
                  type: "relative",
                }}
                size={{
                  width: "100%",
                  aspectRatio: "2/1",
                }}
                display={{
                  display: "flex",
                }}
                link={`/story/${item.storyId}`}
              >
                <Box
                  size={{
                    width: "50%",
                  }}
                >
                  <Box
                    position={{
                      type: "absolute",
                      top: "0",
                      left: "0",
                      zIndex: "1",
                    }}
                    size={{
                      width: "50%",
                      height: "100%",
                    }}
                    animation="fadeIn"
                    key={item.storyId}
                  >
                    <Thumbnail
                      src={convertImage(item.thumbnail)}
                      status={item.storyStatus}
                      isFunding={item.isFunding}
                      funding={item.funding}
                      isPick={item.moomoostPick}
                    />
                  </Box>
                </Box>
                <Box
                  position={{
                    type: "relative",
                  }}
                  display={{
                    display: "flex",
                    direction: "column",
                    justify: "flex-start",
                    align: "flex-start",
                  }}
                  size={{
                    width: "50%",
                  }}
                  border={{
                    all: "2px",
                  }}
                  padding={{
                    all: "10px 15px",
                  }}
                  longMobile={{
                    padding: {
                      all: "10px",
                    },
                  }}
                >
                  <ProjectTitle
                    title={item.storyTitle}
                    id={item.storyId}
                    liked={item.liked}
                    showLike
                  />

                  <Line
                    direction="row"
                    size="100%"
                    margin={{ all: "5px 0" }}
                    background={{ color: "gray02" }}
                  />

                  <ProjectUser member={item.member} artist={item.artist} />

                  <Line
                    direction="row"
                    size="100%"
                    margin={{ all: "5px 0" }}
                    background={{ color: "gray02" }}
                  />

                  <PersonalityList
                    mood={item.storyMood}
                    personality={item.storyPersonality.slice(0, 3)}
                    category={item.storyCategory}
                  />
                </Box>
              </Link>
            </Slider.Slide>
          ))}
        </Slider>
      </Box>

      <Box
        display={{
          display: "flex",
          justify: "center",
          align: "center",
          gap: "10px",
        }}
      >
        <ArrowButton type={"left"} size={28} onClick={handlePrevSlide} />

        {slideList.map((_, idx) => (
          <Button
            size={{
              width: "10px",
              height: "10px",
            }}
            border={{
              all: "1px",
              radius: "50%",
            }}
            background={{
              color: activeIndex === idx ? "pink02" : "white",
            }}
            key={idx}
            onClick={() => handleSlideChange(idx)}
          />
        ))}

        <ArrowButton type={"right"} size={28} onClick={handleNextSlide} />
      </Box>
    </Box>
  );
};

export default StorySlider;
