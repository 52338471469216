import styled from "styled-components";

const EditorContainer = styled.div`
  .se-toolbar-sticky {
    position: relative !important;
  }

  .se-toolbar-sticky .se-toolbar {
    position: fixed !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    z-index: 2 !important;
  }

  .se-toolbar {
    z-index: 2 !important;
    text-align: left;
  }
  .se-toolbar-sticky-dummy {
    display: none !important;
  }

  .se-wrapper-inner {
    text-align: left;
  }
  .se-resizing-bar {
    display: none !important;
  }
  .sun-editor-editable b,
  .sun-editor-editable strong {
    font-weight: bold !important;
  }

  .sun-editor-editable i,
  .sun-editor-editable em {
    font-style: italic !important;
  }
`;

export default EditorContainer;
