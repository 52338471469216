import Box from "component/atoms/box";
import Line from "component/atoms/line";
import NoticeIcons from "component/molecules/detail/noticeIcons";
import Title from "component/molecules/detail/title";
import Content from "../../../molecules/detail/content";
import Text from "component/atoms/text";

const StoryComment = (props) => {
  const {
    comment: {
      boardId,
      memberId,
      name,
      type,
      createdAt,
      storyboardContent,
      storyboardOpen,
      storyboardFixed,
      number,
    },
    isPass,
  } = props;

  return (
    <>
      <Box
        id={boardId}
        size={{
          width: "100%",
        }}
        border={{
          all: "1px",
          style: "solid",
          color: "black01",
        }}
        padding={{
          all: "24px",
        }}
        position={{
          type: "relative",
        }}
        other={{
          overflowX: "hidden",
        }}
        longMobile={{
          padding: {
            all: "8px 7px",
          },
        }}
      >
        <Title type={type} name={name} date={createdAt} memberId={memberId} />

        <Line
          direction={"row"}
          size={"100%"}
          background={{
            color: "gray02",
          }}
          margin={{
            all: "12px 0 16px",
          }}
        />

        <Box
          position={{
            type: "relative",
            zIndex: "0",
          }}
          size={{
            height: "300px",
          }}
          other={{
            overflowY: "hidden",
          }}
          longMobile={{
            padding: {
              all: "0 5px",
            },
          }}
          typo={{
            wordBreak: "break-all",
          }}
          className="commentList"
        >
          {!((storyboardOpen === 1 || storyboardOpen === 2) && !isPass) && (
            <Text
              margin={{
                bottom: "10px",
              }}
              typo={{
                size: "14px",
                weight: "700",
              }}
            >
              #{number}
            </Text>
          )}
          <Content
            content={storyboardContent}
            isLock={(storyboardOpen === 1 || storyboardOpen === 2) && !isPass}
            id={boardId}
          />
        </Box>

        {storyboardFixed && <NoticeIcons />}
      </Box>
    </>
  );
};

export default StoryComment;
