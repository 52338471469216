import Box from "component/atoms/box";
import Text from "component/atoms/text";
import convertDate from "utils/convertDate";
import numberWithCommas from "utils/numberWithCommas";

const COIN_TYPE = {
  사용: "-",
  적립: "+",
  회수: "-",
  지급: "+",
};

const StoryCoin = (props) => {
  const { coinId, coinCategory, coinReason, coinValue, storyTitle, createdAt } =
    props;
  const coinType = COIN_TYPE[coinCategory];

  return (
    <Box
      display={{
        display: "flex",
        wrap: "wrap",
        align: "center",
      }}
      size={{
        width: "100%",
        height: "68px",
      }}
      border={{
        bottom: "1px",
        color: "gray02",
      }}
      longMobile={{
        size: {
          width: "100%",
        },
        padding: {
          all: "15px 0",
        },
      }}
    >
      <Text
        size={{
          width: "66px",
        }}
        typo={{
          size: "14px",
          weight: "400",
        }}
        padding={{
          all: "0 20px",
        }}
        longMobile={{
          size: {
            width: "50%",
          },
          typo: {
            align: "left",
            weight: "700",
          },
          padding: {
            all: "0",
          },
        }}
      >
        {coinCategory}
      </Text>
      <Text
        size={{
          width: "185px",
        }}
        typo={{
          size: "14px",
          weight: "700",
        }}
        padding={{
          all: "0 20px",
        }}
        longMobile={{
          size: {
            width: "50%",
          },
          typo: {
            align: "right",
          },
          padding: {
            all: "0",
          },
        }}
      >
        {coinType} {numberWithCommas(coinValue)}
      </Text>
      <Box
        display={{
          display: "flex",
          direction: "column",
          justify: "center",
        }}
        size={{
          width: "calc(100% - 66px - 185px - 114px)",
          height: "68px",
        }}
        padding={{
          all: "0 20px",
        }}
        longMobile={{
          size: {
            width: "100%",
          },

          padding: {
            all: "0",
          },
          margin: {
            all: "8px 0",
          },
        }}
      >
        <Text
          typo={{
            size: "14px",
            weight: "400",
          }}
          longMobile={{
            typo: {
              size: "12px",
              align: "left",
            },
          }}
        >
          {coinReason}
        </Text>
        {storyTitle && (
          <Text
            typo={{
              size: "14px",
              weight: "400",
              color: "gray04",
            }}
            longMobile={{
              typo: {
                size: "12px",
                align: "left",
              },
            }}
          >
            {storyTitle}
          </Text>
        )}
      </Box>
      <Text
        size={{
          width: "114px",
        }}
        typo={{
          size: "14px",
          weight: "400",
        }}
        padding={{
          all: "0 20px",
        }}
        longMobile={{
          size: {
            width: "100%",
          },
          typo: {
            size: "12px",
            align: "left",
            color: "gray04",
          },
          padding: {
            all: "0",
          },
        }}
      >
        {convertDate(createdAt)}
      </Text>
    </Box>
  );
};

export default StoryCoin;
