import { create } from "api/portfolio";
import Text from "component/atoms/text";
import { ErrorContent } from "component/organisms/common/globalModal";
import dayjs from "dayjs";
import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";
import checkRequired from "utils/checkRequired";

const required = {
  name: true,
  link: true,
  portfolio_music: true,
};

const SuccessContent = () => {
  return (
    <Text
      typo={{
        size: "18px",
        weight: "700",
        align: "center",
      }}
      tablet={{
        typo: {
          size: "14px",
        },
      }}
    >
      포트폴리오 등록이 완료되었습니다.
    </Text>
  );
};

const useCreatePortfolioForm = (handleToggle) => {
  const form = useForm({
    defaultValues: {
      name: "",
      link: "",
      cover: "",
      portfolio_music: dayjs().format("YYYY-MM-DD"),
    },
  });
  const { setModal } = useModalStore();
  const queryClient = useQueryClient();
  const portfolioMutation = useMutation(create, {
    onSuccess: () => {
      handleToggle();
      form.reset();
      queryClient.invalidateQueries("portfolio");
      setModal({
        title: "포트폴리오 등록하기",
        content: <SuccessContent />,
      });
    },
    onError: () => {
      setModal({
        title: "포트폴리오 등록하기",
        content: <ErrorContent />,
      });
    },
  });
  const { watch, handleSubmit } = form;

  const isActive = useMemo(() => checkRequired(required, watch()), [watch()]);

  const onSubmit = (data) => {
    const formData = new FormData();

    formData.append("musicName", data.name);
    formData.append("musicLink", data.link);
    formData.append("musicCoverFile", data.cover);
    formData.append("releaseDate", data.portfolio_music);

    portfolioMutation.mutate(formData);
  };

  return { form, isActive, onSubmit: handleSubmit(onSubmit) };
};

export default useCreatePortfolioForm;
