import Box from "component/atoms/box";
import MainSlide from "component/organisms/main/mainSlider";
import LineTitle from "component/organisms/common/lineTitle";
import StoryCard from "component/organisms/common/storyCard";

import Data from "./test-data.json";
import SearchBar from "component/molecules/common/searchBar";

const Exhibition = () => {
  return (
    <>
      <MainSlide />

      <Box
        as={"section"}
        theme={"basic"}
        margin={{
          all: "100px auto 100px",
        }}
      >
        <LineTitle title={"창작 콘텐츠 탐색"} direction={"row"}>
          <SearchBar height="38px" fontSize="15px" />
        </LineTitle>

        <Box
          display={{
            display: "flex",
            direction: "row",
            justify: "flex-start",
            wrap: "wrap",
            gap: "88px 40px",
          }}
          margin={{
            top: "65px",
          }}
          //
        >
          {Data.story.map((item, index) => (
            <StoryCard
              key={index}
              id={item.id}
              state={item.state}
              img={item.img}
              title={item.title}
              cate={item.cate}
              user={item.user}
              atmosphere={item.atmosphere}
              artist={item.artist}
            />
          ))}
          {Data.story.map((item, index) => (
            <StoryCard
              key={index}
              id={item.id}
              state={item.state}
              img={item.img}
              title={item.title}
              cate={item.cate}
              user={item.user}
              atmosphere={item.atmosphere}
              artist={item.artist}
            />
          ))}
          {Data.story.slice(0, 2).map((item, index) => (
            <StoryCard
              key={index}
              id={item.id}
              state={item.state}
              img={item.img}
              title={item.title}
              cate={item.cate}
              user={item.user}
              atmosphere={item.atmosphere}
              artist={item.artist}
            />
          ))}
        </Box>
      </Box>
    </>
  );
};

export default Exhibition;
