import Box from "component/atoms/box";
import Image from "component/atoms/image";
import Text from "component/atoms/text";
import Api from "component/molecules/common/api";
import useFooter from "hook/api/main/footer";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <Api api={useFooter}>
      {(data) => (
        <Box
          as={"footer"}
          background={{
            color: "black01",
          }}
          position={{
            type: "relative",
          }}
        >
          <Box
            display={{
              display: "flex",
              justify: "space-between",
              wrap: "wrap",
            }}
            size={{
              width: "1200px",
            }}
            margin={{
              all: "0 auto",
            }}
            padding={{
              all: "40px 0",
            }}
            pc={{
              size: {
                width: "100%",
              },
              padding: {
                all: "40px 16px",
              },
            }}
            tablet={{
              display: {
                direction: "column",
              },
            }}
          >
            <Text
              typo={{
                color: "white01",
                size: "14px",
                weight: "400",
                lineHeight: "1.57",
              }}
              mobile={{
                typo: {
                  size: "12px",
                  lineHeight: "1.67",
                },
              }}
            >
              (주)무궁무진스튜디오
              <br />
              <br />
              대표이사 : {data.represenTative}
              <br />
              주소 : {data.address}{" "}
              <Text
                as={"span"}
                typo={{
                  color: "white01",
                }}
                tablet={{
                  display: { display: "none" },
                }}
              >
                | 문의 : {data.contact}
              </Text>
              <br />
              사업자번호 : {data.companyNumber}{" "}
              <Text
                as={"span"}
                typo={{
                  color: "white01",
                }}
                tablet={{
                  display: { display: "none" },
                }}
              >
                | 통신판매업 신고 : {data.reportNumber}
              </Text>
              <Text
                display={{
                  display: "none",
                }}
                tablet={{
                  display: { display: "block" },
                  typo: {
                    lineHeight: "1.67",
                    color: "white01",
                  },
                }}
              >
                통신판매업 신고 : {data.reportNumber}
              </Text>
              <Text
                display={{
                  display: "none",
                }}
                tablet={{
                  display: { display: "block" },
                  typo: {
                    lineHeight: "1.67",
                    color: "white01",
                  },
                }}
              >
                문의 : {data.contact}
              </Text>
            </Text>

            <Box
              display={{
                display: "flex",
                direction: "column",
                justify: "flex-start",
              }}
            >
              <Box
                display={{
                  display: "flex",
                  justify: "flex-end",
                  gap: "16px",
                }}
                tablet={{
                  position: {
                    type: "absolute",
                    top: "40px",
                    right: "16px",
                  },
                }}
              >
                <Link
                  to={
                    "https://www.youtube.com/%EB%AC%B4%EA%B6%81%EB%AC%B4%EC%A7%84%EC%8A%A4%ED%8A%9C%EB%94%94%EC%98%A4"
                  }
                  target={"_blank"}
                >
                  <Image
                    size={{
                      width: "20px",
                      height: "20px",
                    }}
                    src="/asset/img/sns/ic-youtube.png"
                    alt="instagram"
                  />
                </Link>
                <Link
                  to={"https://www.instagram.com/moomoostudio_/"}
                  target={"_blank"}
                >
                  <Image
                    size={{
                      width: "20px",
                      height: "20px",
                    }}
                    src="/asset/img/sns/ic-sns-instagram.png"
                    alt="youtube"
                  />
                </Link>
              </Box>

              <Box
                display={{
                  display: "flex",
                  justify: "flex-end",
                  align: "center",
                  gap: "8px",
                }}
                margin={{
                  top: "28px",
                }}
                tablet={{
                  display: {
                    justify: "flex-start",
                  },
                  margin: {
                    top: "26px",
                  },
                }}
              >
                <Link
                  to={
                    "https://moomoost.notion.site/dfff0f560b1e4018aa565480cb11e1e3"
                  }
                  target={"_blank"}
                >
                  <Text
                    typo={{
                      color: "white01",
                      size: "14px",
                      weight: "400",
                      lineHeight: "1",
                    }}
                    mobile={{
                      typo: {
                        size: "12px",
                        lineHeight: "1.67",
                      },
                    }}
                  >
                    회사소개
                  </Text>
                </Link>

                <Box
                  size={{
                    width: "1px",
                    height: "12px",
                  }}
                  background={{
                    color: "white01",
                  }}
                />

                <Link to={"/terms?category=use"} target={"_blank"}>
                  <Text
                    typo={{
                      color: "white01",
                      size: "14px",
                      weight: "400",
                      lineHeight: "1",
                    }}
                    mobile={{
                      typo: {
                        size: "12px",
                        lineHeight: "1.67",
                      },
                    }}
                  >
                    이용약관
                  </Text>
                </Link>

                <Box
                  size={{
                    width: "1px",
                    height: "12px",
                  }}
                  background={{
                    color: "white01",
                  }}
                />

                <Link to={"/terms?category=privacy"} target={"_blank"}>
                  <Text
                    typo={{
                      color: "white01",
                      size: "14px",
                      weight: "400",
                      lineHeight: "1",
                    }}
                    mobile={{
                      typo: {
                        size: "12px",
                        lineHeight: "1.67",
                      },
                    }}
                  >
                    개인정보처리방침
                  </Text>
                </Link>
              </Box>
              <Text
                typo={{
                  color: "white01",
                  size: "14px",
                  weight: "400",
                  lineHeight: "1",
                  align: "right",
                }}
                margin={{
                  top: "10px",
                }}
                tablet={{
                  typo: {
                    align: "left",
                  },
                }}
                mobile={{
                  typo: {
                    size: "12px",
                    lineHeight: "1.67",
                  },
                }}
              >
                © moomoost. All Rights Reserved.
              </Text>
            </Box>
          </Box>
        </Box>
      )}
    </Api>
  );
};

export default Footer;
