import { GET, PUT } from "api/config";

export const getAdminUserList = async (
  authorityName = "",
  userState = "",
  userName = "",
  userEmail = "",
  total = "",
  pageSize = "",
  currentPage = "1"
) =>
  await GET(
    `/admin/members?authorityName=${authorityName}&userState=${userState}&userName=${userName}&userEmail=${userEmail}&total=${total}&pageSize=${pageSize}&page=${currentPage}`
  );

// 회원 차단
export const blockUser = async (memberId) => {
  const ids = memberId.join(",");
  await PUT(`/admin/members?memberIds=${ids}`);
};
