import Box from "component/atoms/box";
import Button from "component/atoms/button";
import Image from "component/atoms/image";
import Line from "component/atoms/line";
import Text from "component/atoms/text";
import CommonButton from "component/molecules/common/commonButton";
import useExternalToggle from "hook/util/useExternalToggle";
import { AiOutlineShareAlt } from "react-icons/ai";
import shareSns from "utils/shareSns";

const ShareButton = (props) => {
  const { title } = props;

  const [toggle, handleToggle] = useExternalToggle({
    className: ".shareButton",
    max: 1,
  });

  return (
    <Box
      position={{
        type: "relative",
      }}
    >
      <CommonButton
        category={"button"}
        size={{ width: "40px", height: "40px" }}
        onClick={handleToggle}
      >
        <AiOutlineShareAlt size={22} />
      </CommonButton>

      {toggle && (
        <Box
          className={"shareButton"}
          size={{
            width: "343px",
          }}
          position={{
            type: "absolute",
            right: "0",
            top: "calc(100% + 10px)",
            zIndex: "1",
          }}
          background={{
            color: "white",
          }}
          border={{
            all: "1px",
          }}
          padding={{
            all: "15px",
          }}
        >
          <Box
            display={{
              display: "flex",
              justify: "center",
              gap: "40px",
            }}
            margin={{
              all: "15px 0 30px",
            }}
          >
            <Button onClick={() => shareSns.shareToKakaoTalk({ title })}>
              <Image
                src={"/asset/img/story/kakao.svg"}
                size={{
                  width: "50px",
                  height: "50px",
                }}
              />
              <Text
                typo={{
                  size: "12px",
                  color: "gray04",
                }}
              >
                카카오톡
              </Text>
            </Button>
            <Button onClick={() => shareSns.shareToFacebook({ title })}>
              <Image
                src={"/asset/img/story/facebook.svg"}
                size={{
                  width: "50px",
                  height: "50px",
                }}
              />
              <Text
                typo={{
                  size: "12px",
                  color: "gray04",
                }}
              >
                페이스북
              </Text>
            </Button>
            <Button onClick={() => shareSns.shareToTwitter({ title })}>
              <Image
                src={"/asset/img/story/twitter.svg"}
                size={{
                  width: "50px",
                  height: "50px",
                }}
              />
              <Text
                typo={{
                  size: "12px",
                  color: "gray04",
                }}
              >
                트위터
              </Text>
            </Button>
          </Box>
          <Button
            display={{
              display: "flex",
              justify: "space-between",
              align: "center",
            }}
            size={{
              width: "100%",
              height: "42px",
            }}
            border={{
              all: "1px",
            }}
            padding={{
              all: "12px 10px",
            }}
            onClick={() => shareSns.copyLink()}
          >
            <Text
              size={{
                width: "calc(100% - 70px - 13px)",
              }}
              typo={{
                size: "14px",
                weight: "700",
                align: "left",
                lineClamp: "1",
                wordBreak: "break-all",
              }}
            >
              {window.location.href}
            </Text>

            <Line
              direction="col"
              size="100%"
              background={{ color: "gray02" }}
              margin={{ all: "0 12px" }}
            />

            <Text
              typo={{
                size: "14px",
                weight: "700",
              }}
            >
              URL 복사
            </Text>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default ShareButton;
