import { deleteReply } from "api/story";
import { ErrorContent } from "component/organisms/common/globalModal";
import useUrl from "hook/util/useUrl";
import { useMutation, useQueryClient } from "react-query";
import useModalStore from "store/useModalStore";

const useDeleteReply = () => {
  const queryClient = useQueryClient();
  const { setModal } = useModalStore();
  const {
    params: { id },
    query: { comment },
  } = useUrl();
  const deleteMutation = useMutation(deleteReply, {
    onSuccess: () => {
      queryClient.invalidateQueries("replyList");
      queryClient.invalidateQueries("selectComment");
    },
    onError: () => {
      setModal({
        title: "댓글 삭제",
        content: <ErrorContent />,
      });
    },
  });

  const submit = (replyId) => {
    deleteMutation.mutate({
      storyId: id,
      boardId: comment,
      commentId: replyId,
    });
  };

  return submit;
};

export default useDeleteReply;
