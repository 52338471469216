import React, { forwardRef, useEffect } from "react";
// import SunEditor from "./lib";
import EditorContainer from "./editor.style";
import axios from "axios";
import { SERVER_URL } from "api/config";
import convertImage from "utils/convertImage";

const Editor = forwardRef((props, ref) => {
  const { content, setContent } = props;

  const onImageUploadBefore = (files, info, core, uploadHandler) => {
    try {
      const file = files && files[0];
      const formData = new FormData();
      formData.append("file", file);

      axios
        .post(`${SERVER_URL}/api/v1/story/upload`, formData)
        .then((data) => {
          uploadHandler({
            result: [
              {
                url: convertImage(data.data),
                name: file.name,
                size: file.size,
              },
            ],
          });
        })
        .catch((err) => {
          console.error(err);
        });
    } catch (err) {
      alert("에러가 발생했습니다.");
      // uploadHandler(err.toString());
    }

    return undefined;
  };

  useEffect(() => {
    if (ref.current) return;
    const editor = window.SUNEDITOR.create(
      document.querySelector(".sample") || "sample",
      {
        width: "100%",
        height: 470,
        lang: window.SUNEDITOR_LANG["ko"],
        buttonList: [
          ["font", "fontSize", "formatBlock"],
          ["blockquote"],
          ["bold", "underline", "italic", "strike", "subscript", "superscript"],
          ["fontColor", "hiliteColor"],
          // ["removeFormat"],
          "/", // Line break
          ["outdent", "indent"],
          ["align", "horizontalRule", "list"],
          [
            "table",
            "link",
            "image",
            "video",
            // "audio"
          ],
          ["undo", "redo", "codeView"],
          // ["fullScreen", "showBlocks", "codeView"],
          // ["preview", "print"],
          // ["save", "template"],
        ],
        historyStackDelayTime: 0,
      }
    );

    editor.onImageUploadBefore = onImageUploadBefore;

    editor.onChange = (contents, core) => {
      setContent(contents);
    };

    ref.current = editor;
  }, []);

  return (
    <EditorContainer>
      <textarea className="sample"></textarea>
    </EditorContainer>
  );
});

export default React.memo(Editor);
