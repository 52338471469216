const theme = {
  colors: {
    pink01: "#ffe4f6",
    pink02: "#FFBBE7",
    black01: "#000000",
    black02: "#333333",
    white01: "#ffffff",
    red01: "#F00",
    gray01: "#eeeeee",
    gray02: "#cccccc",
    gray03: "#aaaaaa",
    gray04: "#777777",
    gray05: "#555555",
    gray06: "#666666",

    // 어드민 색상 추가
    // 어드민 헤더 배경
    pink03: "#FF84BF",
    gray07: "#EDEDED",
    gray08: "#747474",
    // 어드민 테이블 배경
    gray09: "#F8F9FB",
    // 어드민 테이블 border
    gray10: "#D9DEE3",
    // 어드민 인풋 border
    gray11: "#CFCFCF",

    // 어드민 블루
    blue01: "#007AEA",

    naver01: "#1AD641",
    kakao01: "#FAE100",
    opacityWhite: "rgba(255, 255, 255, 0.5)",
  },
  sizes: {
    xs: "11px",
    sm: "14px",
    md: "15px",
    lg: "18px",
    xl: "24px",
    xxl: "26px",
  },
  weights: {},
  responsive: {
    mobile: 640,
    longMobile: 769,
    tablet: 1023,
    mypage: 1028,
    pc: 1290,
  },
};

export default theme;

// head / body
// table border
