import { newsLetterLink, requestLink } from "api/main";
import { useQuery } from "react-query";

export const useRequestLink = () => {
  const { data, isLoading, isSuccess, isError } = useQuery("requestLink", () =>
    requestLink()
  );

  return {
    data: data?.data,
    isLoading,
    isSuccess,
    isError,
  };
};

export const useNewsLetterLink = () => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    "newsLetterLink",
    () => newsLetterLink()
  );

  return {
    data: data?.data,
    isLoading,
    isSuccess,
    isError,
  };
};
