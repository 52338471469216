import Box from "component/atoms/box";
import Select from "component/atoms/select";
import dayjs from "dayjs";
import React, { useState } from "react";

const AdminTimePicker = (props) => {
  const { value, onChange } = props;

  const hour = dayjs(value).format("HH");
  const minute = dayjs(value).format("mm");

  const handleChangeTime = (value, type) => {
    if (type === "hour") {
      onChange(value + minute);
    } else {
      onChange(hour + value);
    }
  };

  // 시간/분 단위까지 기간 설정을 할 경우 쓰이는 컴포넌트입니다.

  // 시간과 분을 생성하는 함수
  const generateOptions = (limit) => {
    const options = [];
    for (let i = 0; i < limit; i++) {
      const value = String(i).padStart(2, "0");
      options.push(
        <option key={i} value={value}>
          {value}
        </option>
      );
    }
    return options;
  };
  return (
    <Box>
      <Select
        value={hour}
        onChange={(e) => handleChangeTime(e.target.value, "hour")}
        margin={{ left: "15px", right: "5px" }}
        size={{
          width: "53px",
          height: "32px",
        }}
        padding={{
          left: "8px",
        }}
        border={{
          all: "1px",
          style: "solid",
          color: "gray10",
          radius: "3px",
        }}
        typo={{
          size: "13px",
          weight: "400",
          color: "black02",
        }}
      >
        {generateOptions(24)}
      </Select>
      시
      <Select
        value={minute}
        onChange={(e) => handleChangeTime(e.target.value, "minute")}
        margin={{ left: "10px", right: "5px" }}
        size={{
          width: "53px",
          height: "32px",
        }}
        padding={{
          left: "8px",
        }}
        border={{
          all: "1px",
          style: "solid",
          color: "gray10",
          radius: "3px",
        }}
        typo={{
          size: "13px",
          weight: "400",
          color: "black02",
        }}
      >
        {generateOptions(60)}
      </Select>
      분
    </Box>
  );
};

export default AdminTimePicker;
