import Box from "component/atoms/box";
import Link from "component/atoms/link";
import Text from "component/atoms/text";
import Api from "component/molecules/common/api";
import CommonButton from "component/molecules/common/commonButton";
import InputField from "component/organisms/common/inputField";
import LineTitle from "component/organisms/common/lineTitle";
import { useAddition } from "hook/api/story/additional";
import useAdditionalRequestForm from "hook/api/story/additionalRequest";
import useUrl from "hook/util/useUrl";
import { FormProvider, useForm } from "react-hook-form";
import { BiChevronLeft } from "react-icons/bi";

const Additional = () => {
  const {
    params: { id },
  } = useUrl();

  const { form, isActive, onSubmit } = useAdditionalRequestForm();

  return (
    <Api api={useAddition} params={id}>
      {(data) => (
        <>
          <Box
            margin={{
              top: "80px",
            }}
            longMobile={{
              padding: {
                all: "0 16px",
              },
            }}
          >
            <LineTitle title="멤버 신청">
              <Link
                link={`/story/${id}`}
                display={{
                  display: "flex",
                  align: "center",
                }}
                typo={{
                  size: "12px",
                  weight: "700",
                }}
              >
                <BiChevronLeft size={15} />
                돌아가기
              </Link>
            </LineTitle>
          </Box>

          <Box
            display={{
              display: "flex",
            }}
            border={{
              all: "1px",
            }}
            longMobile={{
              display: {
                direction: "column",
              },
              padding: {
                all: "0 16px",
              },
              border: {
                all: "0",
              },
            }}
          >
            <Box
              size={{
                width: "50%",
                height: "100%",
              }}
              border={{
                right: "1px",
              }}
              padding={{
                all: "35px 20px 20px",
              }}
              position={{
                type: "relative",
              }}
              longMobile={{
                size: {
                  width: "100%",
                },
                padding: {
                  all: "51px 0 0px",
                },
                border: {
                  all: "1px",
                },
              }}
            >
              <LineTitle
                title={"멤버 모집 글 "}
                margin={{
                  bottom: "16px",
                }}
              />

              <Text
                typo={{
                  size: "14px",
                  weight: "700",
                }}
                margin={{
                  bottom: "8px",
                }}
              >
                제목
              </Text>
              <Box
                padding={{
                  all: "12px 16px",
                }}
                border={{
                  all: "1px",
                }}
                background={{
                  color: "pink01",
                }}
                margin={{
                  bottom: "20px",
                }}
              >
                <Text
                  typo={{
                    size: "14px",
                    weight: "400",
                  }}
                >
                  {data.employTitle}
                </Text>
              </Box>

              <Text
                typo={{
                  size: "14px",
                  weight: "700",
                }}
                margin={{
                  bottom: "8px",
                }}
              >
                내용
              </Text>
              <Box
                size={{
                  width: "100%",
                  height: "535px",
                }}
                padding={{
                  all: "12px 16px",
                }}
                border={{
                  all: "1px",
                }}
                background={{
                  color: "pink01",
                }}
                other={{
                  overflowY: "auto",
                }}
              >
                <Text
                  typo={{
                    size: "14px",
                    weight: "400",
                    whiteSpace: "pre-line",
                    wordBreak: "break-all",
                  }}
                >
                  {data.employContent}
                </Text>
              </Box>
            </Box>
            <Box
              size={{
                width: "50%",
                height: "100%",
              }}
              padding={{
                all: "35px 20px 20px",
              }}
              position={{
                type: "relative",
              }}
              longMobile={{
                size: {
                  width: "100%",
                },
                padding: {
                  all: "51px 0 0px",
                },
                border: {
                  all: "1px",
                },
              }}
            >
              <FormProvider {...form}>
                <LineTitle
                  title={"멤버 신청"}
                  margin={{
                    bottom: "16px",
                  }}
                />

                <Box
                  margin={{
                    bottom: "20px",
                  }}
                >
                  <InputField
                    as={"input"}
                    type={"text"}
                    title={"제목"}
                    name={"matchTitle"}
                    size={{
                      width: "100%",
                      height: "40px",
                    }}
                    typo={{
                      size: "14px",
                      weight: "700",
                    }}
                    placeholder={"제목을 입력해주세요."}
                  />
                </Box>

                <Box
                  margin={{
                    bottom: "20px",
                  }}
                >
                  <InputField
                    as={"textarea"}
                    type={"text"}
                    title={"내용"}
                    name={"matchContent"}
                    size={{
                      width: "100%",
                      height: "437px",
                    }}
                    typo={{
                      size: "14px",
                      weight: "700",
                    }}
                    // placeholder={"멤버를 모집하는 글을 작성해주세요."}
                  />
                </Box>

                <CommonButton
                  category={"button"}
                  size={{
                    width: "100%",
                    height: "63px",
                  }}
                  isActive={isActive}
                  isDisabled={!isActive}
                  onClick={onSubmit}
                >
                  멤버 신청 보내기
                </CommonButton>
              </FormProvider>
            </Box>
          </Box>
        </>
      )}
    </Api>
  );
};

export default Additional;
