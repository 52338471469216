import { useState } from "react";
import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  getAdminPersonalInfo,
  updateAdminPersonalInfo,
} from "api/admin/setup/personalInfo";
import { useNavigate } from "react-router-dom";

export const useAdminPersonalInfo = () => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    "adminPersonalInfo",
    getAdminPersonalInfo,
    {
      onError: (error) => {
        if (error.response && error.response.status === 403) {
          alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
          navigate("/admin/login");
        }
      },
    }
  );
  return { data: data?.data, isLoading };
};

export const useUpdatePersonalInfo = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const { mutate, data, isLoading } = useMutation(updateAdminPersonalInfo, {
    onSuccess: () => {
      if (onSuccess && typeof onSuccess === "function") {
        onSuccess();
      }
      queryClient.invalidateQueries("adminPersonalInfo");
    },
    onError: (error) => {
      if (onError && typeof onError === "function") {
        onError(error);
      }
    },
  });

  return {
    mutate,
    data,
    isLoading,
  };
};
