import Box from "component/atoms/box";
import CommonButton from "../commonButton";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import { BiCalendar } from "react-icons/bi";
import weekOfYear from "dayjs/plugin/weekOfYear";
import isoWeek from "dayjs/plugin/isoWeek";
import "dayjs/locale/ko";
import locale from "antd/es/date-picker/locale/ko_KR";
import { useEffect, useRef, useState } from "react";
import Image from "component/atoms/image";
import AdminButton from "component/molecules/admin/button/adminButton";

dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.locale("ko");

const CalendarInput = (props) => {
  const { size, value, onChange } = props;
  const calendarRef = useRef();
  const [selectDate, setSelectDate] = useState(dayjs(value));

  // 어드민에서 재사용 하기 위해 추가
  const isAdminPage = window.location.pathname.includes("admin");

  const handleClickDate = () => {
    calendarRef.current.children[1].click();
  };

  const handleSelectDate = (data) => {
    if (!data) {
      setSelectDate("");
      return;
    }
    setSelectDate(data);
    onChange(data.format("YYYY-MM-DD"));
  };

  useEffect(() => {
    setSelectDate(dayjs(value));
  }, [value]);

  return (
    <Box
      display={{
        display: "flex",
        justify: "space-between",
        align: "center",
      }}
      ref={calendarRef}
      size={size}
    >
      {isAdminPage ? (
        <AdminButton
          category={"button"}
          size={{
            width: "118px",
            height: "32px",
          }}
          typo={{
            size: "13px",
            weight: "400",
            color: "black02",
          }}
          border={{
            all: "1px",
            style: "solid",
            color: "gray10",
            radius: "3px",
          }}
          background={{
            color: "white01",
          }}
          onClick={handleClickDate}
        >
          {selectDate.format("YYYY-MM-DD")}
        </AdminButton>
      ) : (
        <CommonButton
          category={"button"}
          type={"button"}
          size={{
            width: "calc(100% - 33px)",
            height: "100%",
          }}
          typo={{
            size: "12px",
            weight: "400",
          }}
          onClick={handleClickDate}
        >
          {selectDate.format("YYYY-MM-DD")}
        </CommonButton>
      )}
      <DatePicker
        picker={"date"}
        value={selectDate}
        locale={locale}
        className="calendar"
        suffixIcon={
          isAdminPage ? (
            <Image src="/asset/img/admin/calender/calender.svg" />
          ) : (
            <BiCalendar color={"black"} size={24} />
          )
        }
        onChange={handleSelectDate}
      />
    </Box>
  );
};

export default CalendarInput;
