import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  deleteCheeringStory,
  getCheeringList,
} from "api/admin/story/cheeringManage";
import { useNavigate } from "react-router-dom";

export const useAdminCheeringList = (
  cheeringState,
  classification,
  startDate,
  endDate,
  memberName,
  storyTitle,
  total,
  pageSize,
  currentPage
) => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    [
      "adminCheeringList",
      cheeringState,
      classification,
      startDate,
      endDate,
      memberName,
      storyTitle,
      total,
      pageSize,
      currentPage,
    ],
    () =>
      getCheeringList(
        cheeringState,
        classification,
        startDate,
        endDate,
        memberName,
        storyTitle,
        total,
        pageSize,
        currentPage
      ),
    {
      onError: (error) => {
        if (error.response && error.response.status === 403) {
          alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
          navigate("/admin/login");
        }
      },
    }
  );
  return {
    data: data?.data,
    totalItems: data?.totalItems,
    totalPages: data?.totalPages,
    isLoading,
  };
};

export const useDeleteCheeringStory = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const { mutate, data } = useMutation(
    ({ storyId }) => deleteCheeringStory(storyId),
    {
      onSuccess: () => {
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess();
        }
        queryClient.invalidateQueries("adminCheeringList");
      },
      onError: (error) => {
        if (onError && typeof onError === "function") {
          onError(error);
        }
      },
    }
  );
  return { mutate, data };
};
