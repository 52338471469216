import styled from "styled-components";
import Control from "style/utils/core/control";
import margin from "style/utils/styles/margin";
import size from "style/utils/styles/size";

const [propTypes, generateStyle] = Control([margin, size]);

export const TableWrapper = styled.div`
  width: 100%;
  max-width: 1024px;
  height: auto;

  display: flex;
  flex-direction: column;

  border: 1px solid ${({ theme }) => theme.colors.gray10};
  border-radius: 3px;
  //
  ${(props) => generateStyle(props)}
`;

TableWrapper.propTypes = propTypes;
