import { GET, POST, PUT } from "api/config";

export const getSpecialList = async () => await GET(`/admin/display/special`);

export const exceptSpecial = async (storyId) => {
  await PUT(`/admin/no/display/special?storyIds=${storyId}`);
};

export const getTourList = async () => await GET(`/admin/display/tour`);

export const exceptTour = async (storyId) => {
  await PUT(`/admin/no/display/tour?storyIds=${storyId}`);
};

export const changeOrder = async (data) => {
  await PUT(`/admin/display/special`, data);
};

export const changeTourOrder = async (data) => {
  await PUT(`/admin/display/tour`, data);
};
