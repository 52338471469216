import styled from "styled-components";

export const SideNavWrapper = styled.div`
  width: 260px;
  height: auto;
  min-height: 1080px;

  display: flex;
  flex-direction: column;
  align-items: center;

  position: relative;

  background-color: ${({ theme }) => theme.colors.pink01};
`;
