import { member } from "api/mypage";
import { useQuery } from "react-query";

const useMember = ({ id }) => {
  const { data, isLoading, isError, isSuccess } = useQuery(["member", id], () =>
    member(id)
  );

  return {
    data: data?.data,
    isLoading,
    isError,
    isSuccess,
  };
};

export default useMember;
