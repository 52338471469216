import { termsStory } from "api/main";
import { useQuery } from "react-query";

const useTermsStory = () => {
  const { data, isLoading, isSuccess, isError } = useQuery(
    "termsStory",
    termsStory
  );

  return {
    data: data?.data,
    isLoading,
    isSuccess,
    isError,
  };
};

export default useTermsStory;
