import { getStoryList } from "api/story";
import { useQuery } from "react-query";

const useStoryList = (props) => {
  const { page, keyword, filter, category, mood, status, storyState, state } =
    props;

  const parameters = {
    page,
    keyword,
    filter,
    category,
    mood,
    status,
    storyState,
    state,
  };

  const { data, isSuccess, isLoading, isError } = useQuery(
    ["story", page, keyword, filter, storyState, category, mood, status, state],
    () => getStoryList(parameters)
  );

  const list = data?.data;
  const maxPage = data?.totalPages;

  return { list, maxPage, isSuccess, isLoading, isError };
};

export default useStoryList;
