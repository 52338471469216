import Control from "style/utils/core/control";
import padding from "style/utils/styles/padding";
import size from "style/utils/styles/size";
import styled from "styled-components";
import border from "style/utils/styles/border";
import other from "style/utils/styles/other";

const [propTypes, generateStyle] = Control([size, padding, other, border]);

export const TableDataWrapper = styled.td`
  font-size: 13px;
  font-weight: 400;
  text-overflow: ellipsis;
  overflow: hidden;
  position: relative;
  color: ${({ theme }) => theme.colors.black02};
  text-align: center;
  vertical-align: middle;

  border-top: ${({ isDrop, theme }) =>
    isDrop ? "none" : `1px solid ${theme.colors.gray10}`};
  border-right: ${({ isLastChild, theme }) =>
    isLastChild ? "none" : `1px solid ${theme.colors.gray10}`};
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.colors.white01};

  ${(props) => generateStyle(props)}
`;

TableDataWrapper.propTypes = propTypes;
