import Box from "component/atoms/box";
import Image from "component/atoms/image";
import Text from "component/atoms/text";
import CommonButton from "component/molecules/common/commonButton";
import InputField from "component/organisms/common/inputField";
import useMatchingStoryForm from "hook/api/story/matching";
import { FormProvider } from "react-hook-form";

const Request = (props) => {
  const { title, member, onClose } = props;
  const { form, isActive, submit } = useMatchingStoryForm(onClose);

  return (
    <FormProvider {...form}>
      <Box
        padding={{
          bottom: "15px",
        }}
        border={{
          bottom: "1px",
        }}
      >
        <Box
          display={{
            display: "flex",
          }}
        >
          <Image
            src={"/asset/img/story/writer.png"}
            size={{
              width: "15px",
              height: "15px",
            }}
          />
          <Text
            display={{
              display: "flex",
              align: "center",
            }}
            typo={{
              size: "12px",
              weight: "400",
            }}
            margin={{
              left: "5px",
            }}
          >
            {member.memberName}
          </Text>
        </Box>
        <Text
          typo={{
            size: "12px",
            weight: "400",
          }}
          padding={{
            left: "20px",
          }}
        >
          선택한 프로젝트 : {title}
        </Text>
      </Box>

      <Box
        margin={{
          top: "15px",
        }}
        longMobile={{
          margin: {
            top: "12px",
          },
        }}
      >
        <InputField
          as={"input"}
          type={"text"}
          title={"제목"}
          name={"title"}
          size={{
            width: "100%",
            height: "40px",
          }}
          typo={{
            size: "14px",
          }}
          placeholder={"제목을 입력해주세요."}
        />
      </Box>

      <Box
        margin={{
          top: "20px",
          bottom: "20px",
        }}
        longMobile={{
          margin: {
            top: "12px",
            bottom: "20px",
          },
        }}
      >
        <InputField
          as={"textarea"}
          type={"text"}
          title={"내용"}
          name={"content"}
          size={{
            width: "100%",
            height: "365px",
          }}
          typo={{
            size: "14px",
          }}
          longMobile={{
            size: {
              width: "100%",
              height: "300px",
            },
          }}
          placeholder={"창안자님께 전달할 내용을 입력해주세요."}
        />
      </Box>

      <CommonButton
        category={"button"}
        size={{
          width: "100%",
          height: "63px",
        }}
        typo={{
          size: "18px",
        }}
        isActive={isActive}
        isDisabled={!isActive}
        longMobile={{
          size: {
            width: "100%",
            height: "50px",
          },
          typo: {
            size: "14px",
          },
        }}
        onClick={submit}
      >
        매칭 신청 보내기
      </CommonButton>
    </FormProvider>
  );
};

export default Request;
