import Box from "component/atoms/box";
import Text from "component/atoms/text";
import { RiLockLine } from "react-icons/ri";

const Lock = () => {
  return (
    <Box
      size={{
        width: "100%",
        height: "100%",
      }}
      display={{
        display: "flex",
        direction: "column",
        align: "center",
        justify: "center",
      }}
    >
      <RiLockLine size={30} color={"#cccccc"} />

      <Text
        margin={{
          top: "4px",
        }}
        typo={{
          color: "gray02",
          align: "center",
        }}
        longMobile={{
          typo: {
            size: "14px",
          },
        }}
      >
        프로젝트 창안자와 아티스트만 볼 수 있는 글입니다.
      </Text>
    </Box>
  );
};

export default Lock;
