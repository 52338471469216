import Box from "component/atoms/box";
import CommonButton from "component/molecules/common/commonButton";
import InputField from "component/organisms/common/inputField";
import { useAdditionForm } from "hook/api/story/additional";
import { FormProvider } from "react-hook-form";

const Additional = (props) => {
  const { onClose, defaultValues } = props;
  const { form, isActive, onSubmit } = useAdditionForm(onClose, defaultValues);
  const isUpdate = !!defaultValues;

  return (
    <FormProvider {...form}>
      <Box
        margin={{
          bottom: "20px",
        }}
      >
        <InputField
          as={"input"}
          type={"text"}
          title={"제목"}
          name={"employTitle"}
          size={{
            width: "100%",
            height: "40px",
          }}
          typo={{
            size: "14px",
          }}
          placeholder={"제목을 입력해주세요."}
        />
      </Box>

      <Box
        margin={{
          bottom: "20px",
        }}
      >
        <InputField
          as={"textarea"}
          type={"text"}
          title={"내용"}
          name={"employContent"}
          size={{
            width: "100%",
            height: "365px",
          }}
          typo={{
            size: "14px",
          }}
          longMobile={{
            size: {
              width: "100%",
              height: "300px",
            },
          }}
          placeholder={"멤버를 모집하는 글을 작성해주세요."}
        />
      </Box>

      {isUpdate ? (
        <Box
          display={{
            display: "grid",
            gridTemplateColumns: "repeat(2, 1fr)",
            gap: "10px",
          }}
        >
          <CommonButton
            category={"button"}
            size={{
              width: "100%",
              height: "63px",
            }}
            typo={{
              size: "18px",
            }}
            longMobile={{
              typo: {
                size: "14px",
              },
              size: {
                width: "100%",
                height: "50px",
              },
            }}
            onClick={onClose}
          >
            취소
          </CommonButton>
          <CommonButton
            category={"button"}
            size={{
              width: "100%",
              height: "63px",
            }}
            typo={{
              size: "18px",
            }}
            longMobile={{
              typo: {
                size: "14px",
              },
              size: {
                width: "100%",
                height: "50px",
              },
            }}
            isActive={isActive}
            isDisable={!isActive}
            onClick={onSubmit}
          >
            수정하기
          </CommonButton>
        </Box>
      ) : (
        <CommonButton
          category={"button"}
          size={{
            width: "100%",
            height: "63px",
          }}
          typo={{
            size: "18px",
          }}
          longMobile={{
            typo: {
              size: "14px",
            },
            size: {
              width: "100%",
              height: "50px",
            },
          }}
          isActive={isActive}
          isDisable={!isActive}
          onClick={onSubmit}
        >
          멤버 모집글 작성하기
        </CommonButton>
      )}
    </FormProvider>
  );
};

export default Additional;
