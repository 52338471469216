import Box from "component/atoms/box";
import Blank from "component/molecules/common/blank";
import List from "component/molecules/common/list";
import LineTitle from "component/organisms/common/lineTitle";
import StorySupport from "component/organisms/common/storySupport";
import useCheering from "hook/api/mypage/cheering";
import useUrl from "hook/util/useUrl";

const Support = (props) => {
  const { title } = props;
  const {
    query: { page = 1 },
  } = useUrl();

  return (
    <>
      <LineTitle
        typo={{
          size: "18px",
        }}
        title={title}
      />

      <List
        api={useCheering}
        params={{
          page,
          limit: 16,
        }}
      >
        {(list) =>
          list && (
            <>
              {list.length === 0 && <Blank title={"조회 결과가 없습니다."} />}
              {list.length !== 0 && (
                <Box theme={"storyGrid"}>
                  {list.map((item) => (
                    <StorySupport {...item} key={item.id} />
                  ))}
                </Box>
              )}
            </>
          )
        }
      </List>
    </>
  );
};

export default Support;
