import Box from "component/atoms/box";
import CommonButton from "component/molecules/common/commonButton";
import useSelectForm from "hook/util/useSelectForm";

const Select = (props) => {
  const { name, length } = props;
  const { list, currentList, handleUpdate } = useSelectForm(name);

  return (
    <Box
      display={{
        display: "grid",
        gridTemplateColumns: `repeat(${length}, 1fr)`,
        gap: "10px",
      }}
      margin={{
        top: "8px",
      }}
      longMobile={{
        display: {
          gridTemplateColumns: "repeat(5, 1fr)",
        },
      }}
      mobile={{
        display: {
          gridTemplateColumns: "repeat(4, 1fr)",
        },
      }}
    >
      {list.map((item) => {
        const isActive = currentList.includes(item);

        return (
          <CommonButton
            key={item}
            category={"button"}
            type={"button"}
            size={{
              width: "100%",
              height: "40px",
            }}
            onClick={() => handleUpdate(item)}
            isActive={isActive}
          >
            {item}
          </CommonButton>
        );
      })}
    </Box>
  );
};

export default Select;
