const USER = [
  {
    id: 1,
    userId: "qwe",
    name: "김철수",
    email: "test@test.com",
    password: "qwe",
    token: "1234",
  },
];

export default USER;
