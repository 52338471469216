import styled from "styled-components";

export const TableHeadWrapper = styled.th`
  width: ${(props) => props.width};
  height: ${({ height }) => height || "32px"};
  padding: ${({ padding }) => padding || "8px 0"};

  font-size: 13px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.black02};
  text-align: center;
  box-sizing: border-box;
  border-right: ${({ isLastChild, theme }) =>
    isLastChild ? "none" : `1px solid ${theme.colors.gray10}`};

  background-color: ${({ isPink, theme }) =>
    isPink ? theme.colors.pink01 : theme.colors.gray09};
`;
