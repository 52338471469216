import Box from "component/atoms/box";
import Line from "component/atoms/line";
import { Fragment } from "react";

const StoryInfo = (props) => {
  const { infoList } = props;

  return (
    <Box
      display={{
        display: "flex",
        direction: "column",
      }}
      padding={{
        all: "12px",
      }}
      other={{
        overflow: "hidden",
      }}
      border={{
        all: "2px",
        style: "solid",
        color: "black01",
      }}
      background={{
        color: "white01",
      }}
    >
      {infoList.map((info, idx) => (
        <Fragment key={idx}>
          {idx !== 0 && (
            <Line
              direction={"row"}
              size={"100%"}
              background={{ color: "gray02" }}
              margin={{ all: "8px 0" }}
              longMobile={{
                margin: {
                  all: "5px 0",
                },
              }}
            />
          )}
          {info}
        </Fragment>
      ))}
    </Box>
  );
};

export default StoryInfo;
