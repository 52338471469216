import { GET, POST, PUT, DELETE } from "api/config";

export const getUserDetail = async (memberId) =>
  await GET(`/admin/members/info/${memberId}`);

export const updateUserDetail = async (memberId, data) => {
  await PUT(`/admin/members/info/${memberId}`, data);
};

export const getUserCoin = async (memberId) =>
  await GET(`/admin/members/coin/${memberId}`);

export const giveCoin = async (memberId, coinsToAward, coinReason) => {
  const data = {
    coinsToAward: coinsToAward,
    coinReason: coinReason,
  };
  await POST(`/admin/give/${memberId}`, data);
};

export const revokeCoin = async (memberId, coinsToAward, coinReason) => {
  const data = {
    coinsToAward: coinsToAward,
    coinReason: coinReason,
  };
  await POST(`/admin/revoke/${memberId}`, data);
};

export const getUserCheering = async (
  memberId,
  reason = "",
  startDate = "",
  endDate = "",
  coinCategory = "",
  processed = "",
  currentPage = "1",
  pageSize = "10"
) =>
  await GET(
    `/admin/members/cheering/${memberId}?reason=${reason}&startDate=${startDate}&endDate=${endDate}&coinCategory=${coinCategory}&processed=${processed}&page=${currentPage}&pageSize=${pageSize}`
  );

export const getUserPortfolio = async (
  memberId,
  musicName = "",
  musicLink = "",
  startDate = "",
  endDate = "",
  total = "",
  pageSize = "10",
  currentPage = "1"
) =>
  await GET(
    `/admin/members/portfolio/${memberId}?musicName=${musicName}&musicLink=${musicLink}&startDate=${startDate}&endDate=${endDate}&total=${total}&pageSize=${pageSize}&page=${currentPage}`
  );

export const cancleCheering = async (coinIds) => {
  const ids = coinIds.join(",");
  await POST(`/admin/members/cheering?coinIds=${ids}`);
};

export const deletePortfolio = async (portfolioIds) => {
  const ids = portfolioIds.join(",");
  await DELETE(`/admin/members/portfolio?portfolioIds=${ids}`);
};
