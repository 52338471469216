import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  approveMusic,
  getJudgingList,
  pendingMusic,
} from "api/admin/story/judging";
import { useNavigate } from "react-router-dom";

export const useAdminJudgingList = (
  state,
  startDate,
  endDate,
  musicName,
  memberEmail,
  total,
  pageSize,
  currentPage
) => {
  const navigate = useNavigate();
  const { data, isLoading } = useQuery(
    [
      "adminJudgingList",
      state,
      startDate,
      endDate,
      musicName,
      memberEmail,
      total,
      pageSize,
      currentPage,
    ],
    () =>
      getJudgingList(
        state,
        startDate,
        endDate,
        musicName,
        memberEmail,
        total,
        pageSize,
        currentPage
      ),
    {
      onError: (error) => {
        if (error.response && error.response.status === 403) {
          alert("접근 권한이 없습니다. 관리자 계정으로 로그인해주세요.");
          navigate("/admin/login");
        }
      },
    }
  );
  return {
    data: data?.data,
    totalItems: data?.totalItems,
    totalPages: data?.totalPages,
    isLoading,
  };
};

export const useAdminJudgingApprove = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const { mutate, data } = useMutation(
    ({ creationId }) => approveMusic(creationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("adminJudgingList");
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess();
        }
      },
      onError: (error) => {
        if (onError && typeof onError === "function") {
          onError(error);
        }
      },
    }
  );

  return {
    mutate,
    data,
  };
};

export const useAdminJudgingPending = (onSuccess, onError) => {
  const queryClient = useQueryClient();
  const { mutate, data } = useMutation(
    ({ creationId, rejectReason }) => pendingMusic(creationId, rejectReason),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("adminJudgingList");
        if (onSuccess && typeof onSuccess === "function") {
          onSuccess();
        }
      },
      onError: (error) => {
        if (onError && typeof onError === "function") {
          onError(error);
        }
      },
    }
  );

  return {
    mutate,
    data,
  };
};
